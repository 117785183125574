import styled from 'threads5/styles/styled';

const ChevronRightBold = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.76061 3.47949L9.29094 7.00982L5.76061 10.5402L4.69995 9.47949L7.16962 7.00982L4.69995 4.54015L5.76061 3.47949Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default styled(ChevronRightBold)({});
