import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

// components
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

const UseCaseTemplateScripts = dynamic(
  () => {
    return import('src/templates/UseCaseTemplate/UseCaseTemplateScripts');
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: TemplateFieldShape.isRequired,
  locale: PropTypes.string,
  children: PropTypes.element,
};

const UseCaseTemplate = ({ fields, locale, ...props }) => {
  const { route } = useRouter();

  const metaData = {
    metaTitle: _get(fields, 'metaTitle', fields['meta-title']),
    metaDescription: _get(
      fields,
      'metaDescription',
      fields['meta-description'],
    ),
  };

  return (
    <TemplateWrapper
      meta-title={metaData?.metaTitle}
      meta-description={metaData?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      noIndex={fields?.metaNoindex}
      locale={locale}
    >
      <div data-testid={ContentfulTemplates.USE_CASE}>
        <CmsComponentSwitch components={fields?.components} />
        {props.children}
      </div>
      <UseCaseTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
UseCaseTemplate.propTypes = propTypes;

export default UseCaseTemplate;
