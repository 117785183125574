import * as React from 'react';
import { Box, Stack, Column } from 'src/components-v2/Layout';
import { IPaginatedCard } from '../Paginator';

interface IStatsProps {
  cards: Array<IPaginatedCard> | Array<React.ReactElement<unknown>>;
  cardRef?: React.Ref<HTMLDivElement>;
}

const StatsCards: React.FC<IStatsProps> = ({ cards, cardRef }) => {
  return (
    <Column xsOffset={1} xs={17} smOffset={0} sm={24} lgOffset={2} lg={20}>
      <Stack direction='row'>
        {cards?.map((card, i) => {
          return (
            <Stack
              key={`stat-card-${i}`}
              ref={cardRef}
              sx={{
                width: cards.length === 3 ? '33.33%' : '50%',
                textAlign: 'left',
                minWidth: {
                  xs: '100%',
                  sm: '0',
                },
                py: { xs: '1.6rem', sm: '4.8rem', md: '8.0rem' },
                px: { xs: '1.6rem', sm: '2.5rem', md: '4.0rem' },
                borderTop: '1px solid #949494',
                borderLeft: i !== 0 ? '1px solid #949494' : 'none',
                ...card.sx,
              }}
            >
              <Box
                sx={{
                  margin:
                    cards.length === 2
                      ? { xs: '0', sm: '0 auto', md: '0' }
                      : '0',
                }}
              >
                {card.content}
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Column>
  );
};

export default StatsCards;
