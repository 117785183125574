export const metaData = {
  metaTitle: 'Transactions API - retrieve transaction data',
  metaDescription:
    "Plaid's Transactions API allows companies to retrieve clean, categorised transaction data, using secure Open Banking integrations.",
};

export const productHeaderData = {
  'hero-one-poster-url':
    '/assets/img/products/standalone/transactions/hero-transactions-1-poster-us-en.png',
  'hero-one-poster-fallback-url':
    '/assets/img/products/standalone/transactions/hero-transactions-1-fallback-us-en.png',
  'hero-one-video-url':
    '/assets/img/products/standalone/transactions/hero-transactions-1-us-en.webm',
  'hero-one-video-fallback-url':
    '/assets/img/products/standalone/transactions/hero-transactions-1-us-en.mp4',
  'hero-one-video-width': null,
  'hero-one-video-height': null,
  'hero-two-poster-url':
    '/assets/img/products/standalone/transactions/hero-transactions-2-poster-us-en.png',
  'hero-two-poster-fallback-url':
    '/assets/img/products/standalone/transactions/hero-transactions-2-fallback-us-en.png',
  'hero-two-video-url':
    '/assets/img/products/standalone/transactions/hero-transactions-2-us-en.webm',
  'hero-two-video-fallback-url':
    '/assets/img/products/standalone/transactions/hero-transactions-2-us-en.mp4',
  'hero-two-video-width': null,
  'hero-two-video-height': null,
  label: 'Transactions',
  description: 'Access detailed transaction history',
  'top-cta-text': 'Get started',
  'top-cta-url': 'http://dashboard.plaid.com/signup',
  'bottom-cta-text': 'View API docs',
  'bottom-cta-url': '/docs/transactions',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'product features',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/products/standalone/transactions/feature-transactions-1.png',
      'feature-title': 'Comprehensive data',
      'feature-body':
        'Retrieve up to two years of transaction data, including merchant and category information',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/products/standalone/transactions/feature-transactions-2.png',
      'feature-title': 'Continuous transaction updates',
      'feature-body':
        'Stay up-to-date by receiving notifications via a webhook whenever there are new transactions associated with linked accounts',
      'wider-feature': true,
    },
    {
      'feature-img': '/assets/img/products/features-open-banking-psd2.png',
      'feature-title': 'Open Banking/PSD2 integrations',
      'feature-body':
        'Plaid’s integrations are Open Banking and PSD2 compliant, delivering secure and reliable access to the UK and European financial ecosystems',
      'wider-feature': true,
    },
  ],
};

export const interactiveData = {
  'title-text': 'Make a Transactions request',
  'description-text':
    'The request includes start and end dates, a specific date range to pull transactions data from. Use the Transactions endpoint to submit a POST request',
  'code-title': 'Retrieve Transactions request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/transactions/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String,\n  "start_date": "2018-01-01",\n  "end_date": "2018-02-01",\n  "options": {\n    "count": 250,\n    "offset": 100\n  }\n}\'\n',
  'post-run-title-text': 'Transactions data',
  'post-run-description-text':
    'The <strong>/transactions/get</strong> endpoint provides standardised transactions for credit and deposit accounts from thousands of financial institutions',
  'post-run-code-title': 'Retrieve Transactions response',
  'post-run-code-lang': 'json',
  'post-run-code-body':
    '\nhttp code 200\n{\n "accounts": [{Object}],\n "transactions": [{\n    "account_id": "vokyE5Rn6vHKqDLRXEn5fne7LwbKPLIXGK98d",\n    "amount": 2307.21,\n    "iso_currency_code": "GBP",\n    "unofficial_currency_code": null,\n    "category": [\n      "Shops",\n      "Computers and Electronics"\n    ],\n    "category_id": "19013000",\n    "date": "2017-01-03",\n    "authorized_date": "2017-01-27",\n    "location": {\n     "address": "null",\n     "city": "null",\n     "region": "null",\n     "postal_code": "null",\n     "country": "null",\n     "lat": null,\n     "lon": null,\n     "store_number": "1235"\n    },\n    "name": "Apple Store",\n    "payment_meta": Object,\n    "payment_channel": "in store",\n    "pending": false,\n    "pending_transaction_id": null,\n    "account_owner": null,\n    "transaction_id": "lPNjeW1nR6CDn5okmGQ6hEpMo4lLNoSrzqDje",\n    "transaction_code": null,\n    "transaction_type": "place"\n   }, {\n    "account_id": "XA96y1wW3xS7wKyEdbRzFkpZov6x1ohxMXwep",\n    "amount": 78.5,\n    "iso_currency_code": "GBP",\n    "unofficial_currency_code": null,\n    "category": [\n      "Food and Drink",\n      "Restaurants"\n    ],\n    "category_id": "13005000",\n    "date": "2017-01-29",\n    "authorized_date": "2017-01-28",\n    "location": {\n      "address": "null",\n      "city": "null",\n      "region": "null",\n      "postal_code": "null",\n      "country": "null",\n      "lat": null,\n      "lon": null,\n      "store_number": "null"\n    },\n    "name": "Pret A Manger",\n    "payment_meta": Object,\n    "payment_channel": "in store",\n    "pending": false,\n    "pending_transaction_id": null,\n    "account_owner": null,\n    "transaction_id": "4WPD9vV5A1cogJwyQ5kVFB3vPEmpXPS3qvjXQ",\n    "transaction_code": null,\n    "transaction_type": "place"\n  }],\n  "item": {Object},\n  "total_transactions": Number,\n  "request_id": "45QSn"\n}\n',
  properties: [
    {
      title: 'amount',
      description:
        'Settled value in specified currency. Debits are positive; credits are negative',
      'code-line-start': 6,
      'code-highlight-length': 1,
    },
    {
      title: 'category',
      description:
        'See a list of all transaction categories by posting to the /categories/get endpoint',
      'code-line-start': 9,
      'code-highlight-length': 4,
    },
    {
      title: 'date',
      description:
        'For pending transactions, the date the transaction occurred is returned',
      'code-line-start': 14,
      'code-highlight-length': 1,
    },
    {
      title: 'name',
      description: 'The merchant name or transaction description',
      'code-line-start': 26,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/transactions',
  'bottom-cta-text': 'View API docs',
  testId: 'uk-products-transactions',
};

export const showcaseData = {
  type: 'dual-image',
  title: 'Continuous stream of transaction updates',
  description:
    'Stay up-to-date by receiving notifications via a webhook whenever there are new transactions associated with linked accounts',
  'bg-color': 'yellow200',
  'img-brushtroke':
    '/assets/img/accents/showcase-brushstroke-dual-image-yellow.png',
  'img-illustration':
    '/assets/img/products/standalone/transactions/showcase-transactions-art.png',
  images: [
    {
      'img-src':
        '/assets/img/products/standalone/transactions/showcase-transactions-art-code-snippet@2x.png',
      'alt-text': 'Webhook code snippet',
    },
    {
      'img-src':
        '/assets/img/products/standalone/transactions/uk-showcase-transactions-art-transaction-history.png',
      'alt-text': 'Transactions history',
      'has-shadow': true,
    },
  ],
};

export const featureSectionData = {
  'feature-section-title': [
    {
      label: 'Use Cases',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/use-cases/pf-intl.png',
      title: 'Personal finances',
      'header-class': 'h5',
      content:
        'Help users improve their financial health with categorised transactions',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/personal-finances',
    },
    {
      img: '/assets/img/use-cases/bf-112.png',
      title: 'Business finances',
      'header-class': 'h5',
      content: 'Make it easy for everyone to manage business finances',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/business-finances',
    },
    {
      img: '/assets/img/use-cases/ls-112.png',
      title: 'Lending',
      'header-class': 'h5',
      content:
        'A faster, more secure way to assess a borrower’s bank transactions, income and expenses',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/lending',
    },
    {
      img: '/assets/img/use-cases/bbs-112.png',
      title: 'Banking and wealth',
      'header-class': 'h5',
      content:
        'Make it easy for people to interact with their bank accounts and investments',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/banking-and-wealth',
    },
  ],
};

export const quoteData = {
  quote:
    'We could have spent years building a data solution that wouldn’t have come close to what Plaid provides.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/drop-logo.svg',
  'logo-alt': 'Drop logo',
  cite: 'Daniel Whiffing, Technical Lead at Drop',
  'quote-link': '/documents/Drop-Case-Study.pdf',
  'quote-link-text': 'See the full case study',
  'with-background': false,
  dark: false,
  'bg-color': 'bg-white',
};

export const footerExtensionData = {
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/transactions',
};
