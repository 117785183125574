import React, { FC, useState, useEffect, useRef } from 'react';
import { useScroll } from '@react-spring/web';
import { useRouter } from 'next/router';

// components
import { Link } from 'src/components/Inputs';
import {
  Box,
  Container,
  Column,
  Row,
  Section,
  Stack,
} from 'src/components-v2/Layout';
import { Button } from 'src/components-v2/Inputs';
import { HamburgerMenu, NavList } from 'src/sections/Navigation';
import PlaidLogo from 'src/components-v2/SVGs/PlaidLogo';
import { BlogLogo } from 'src/components/Blog/Logo';

// contexts
import { usePageLocale } from 'src/contexts';

// data
import { navData } from './nav-data';

interface MainMenuProps {
  skipLinkAnchor?: string;
}

const MainMenu: FC<MainMenuProps> = ({ skipLinkAnchor = '#main-content' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const prevScrollY = useRef(0);
  const scrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';
  prevScrollY.current = scrollY;

  const { asPath } = useRouter();
  const onBlogPage = asPath.includes('blog');
  const locale = usePageLocale();

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflowY = 'visible';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = 'initial';
    }
  }, [isOpen]);

  useScroll({
    onChange: ({ value }) => {
      setScrollY(Math.floor(value.scrollY));
    },
  });

  return (
    <Section
      component='nav'
      sx={{
        position: 'sticky',
        top: '0',
        width: '100%',
        backgroundColor: 'white',
        transition: 'transform 400ms',
        transform:
          scrollDirection === 'down' && scrollY > 80
            ? // 85 is header height (80px) + box-shadow (5px)
              'translateY(-85px)'
            : 'translateY(0)',
        boxShadow:
          scrollDirection === 'down' || scrollY <= 5
            ? 'none'
            : '0px 2px 8px 0px rgba(17, 17, 18, 0.08), 0px 0px 2px 0px rgba(17, 17, 18, 0.02)',
        zIndex: 2147483647,
      }}
    >
      <Container
        sx={{
          p: {
            xs: '2.2rem 0.8rem 2rem',
            sm: '1.8rem 4rem 1.4rem',
            lg: '1.225rem 4rem',
          },
          overflow: { xs: 'hidden', lg: 'visible' },
        }}
      >
        <Row>
          <Column xs={24}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Button // button for accessibility to skip the nav
                variant='text'
                hasAnimatedCaret={false}
                sx={{
                  position: 'absolute',
                  top: '100px',
                  transform: 'scale(0)', // hide unfocused button and remove click area
                  '&:focus': { transform: 'none', color: 'black' },
                  display: { xs: 'none', lg: 'inline-block' },
                }}
                href={skipLinkAnchor}
              >
                Skip to main content
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: { xl: 1 },
                  flexBasis: { xl: 0 },
                  alignItems: 'center',
                }}
              >
                <Link href='/' tabIndex={0}>
                  <a aria-label='Go to Plaid homepage'>
                    <PlaidLogo
                      sx={{
                        display: 'block',
                        height: { xs: '24px', sm: '40px' },
                      }}
                    />
                  </a>
                </Link>
                {onBlogPage && <BlogLogo />}
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                  ml: { lg: '7rem', xl: '0' },
                }}
              >
                <NavList locale={locale} isMobile={false} />
              </Box>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: { xs: 'none', lg: 'flex' },
                  gap: '16px',
                  flexGrow: { xl: 1 },
                  flexBasis: { xl: 0 },
                  justifyContent: 'end',
                }}
              >
                <Button
                  variant='text'
                  hasAnimatedCaret={false}
                  sx={{ color: 'black', py: '1.1rem' }}
                  href='https://dashboard.plaid.com/signin/'
                >
                  Log in
                </Button>
                <Button
                  variant='outlined'
                  sx={{ py: '1.1rem' }}
                  href='https://dashboard.plaid.com/contact'
                  trackingId={navData[locale]?.ctaTrackingIds?.getAPIkeys || ''}
                >
                  Contact sales
                </Button>
              </Stack>
              <Box
                component='button'
                role='button'
                aria-label='Main menu'
                aria-expanded={isOpen}
                onClick={handleButtonClick}
                sx={{
                  display: { xs: 'flex', lg: 'none' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '18px', sm: '20px' },
                  height: { xs: '18px', sm: '20px' },
                }}
              >
                <HamburgerMenu isOpen={isOpen} />
              </Box>
            </Stack>
          </Column>
        </Row>
      </Container>

      {/* Mobile dropdown menu */}
      <Container
        sx={{
          px: { xs: '0' },
          display: { xs: 'block', lg: 'none' },
          overflowX: 'hidden',
        }}
      >
        <Row>
          <Column xs={24}>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                display: 'grid',
                gridTemplateRows: isOpen ? '1fr' : '0fr',
                transition:
                  'grid-template-rows 0.4s cubic-bezier(0.2, 1.2, 0.32, 1)',
                willChange: 'grid-template-rows',
              }}
            >
              <Box
                sx={{
                  overflowY: 'hidden',
                  backgroundColor: 'white',
                }}
              >
                <Box
                  sx={{
                    overflowY: 'auto',
                    height: {
                      xs: 'calc(100vh - 59px)',
                      sm: 'calc(100vh - 67px)',
                    },
                  }}
                >
                  <NavList locale={locale} isMobile={true} />
                  <Stack
                    sx={{
                      px: {
                        xs: '0.8rem',
                        sm: '4rem',
                      },
                      gap: '16px',
                      pt: { xs: '2.4rem', sm: '4rem' },
                      borderTop: { xs: 1 },
                      borderTopColor: { xs: 'black400' },
                      pb: '10rem',
                    }}
                  >
                    <Button
                      variant='outlined'
                      hasAnimatedCaret={false}
                      href='https://dashboard.plaid.com/signin/'
                    >
                      Log in
                    </Button>
                    <Button
                      isCaretActive={false}
                      href='https://dashboard.plaid.com/contact'
                      trackingId={
                        navData[locale]?.ctaTrackingIds?.getAPIkeys || ''
                      }
                      sx={{
                        color: 'white !important', // occasionally global styles overrides the button color
                        // no random colors on button on mobile nav
                        '&:hover, :focus': {
                          backgroundColor: 'blue900',
                          borderColor: 'blue900',
                        },
                        '&:active': {
                          backgroundColor: 'blue1000',
                          borderColor: 'blue1000',
                        },
                      }}
                    >
                      Contact sales
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default MainMenu;
