export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Privacy Request Form: Access/Delete and Other Data Protection Rights',
      'meta-description': 'Exercise your data protection rights',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Privacy Request Form: Access/Delete and Other Data Protection Rights',
    introHeader: 'Introduction',
    intro: `<p>Please complete the form below to submit your privacy request. Please note that, in order to process your request, you will be required to provide additional information necessary to verify your identity. The information that you provide below will be used solely for the purpose of fulfilling your request.</p>
      <p>To learn more about and/or exercise any other data protection rights that may be applicable to you as an End User, please refer to our <a href="https://plaid.com/legal/#end-user-privacy-policy">End User Privacy Policy</a> under the section entitled “Your Data Protection Rights” or email us at <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. If you are not an End User (you have not used Plaid’s products or services and/or connected an application through Plaid), please refer to our Privacy Statement for more information about your rights or email us at <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. If you are a Candidate or Employee of Plaid please refer to our Candidate Privacy Notice or Personnel Privacy Notice for information or email us at <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>.</p>
      <p>You should not use this form if you are having trouble connecting to a particular app. For further assistance with your connection to an app, please contact the app directly. You can find general information about connecting financial accounts on our <a href="https://support-my.plaid.com/hc/en-us/categories/4405983222679">consumer help center</a>.</p>`,
    formHeader: 'Form',
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    email: {
      label: 'Email',
      error: 'invalid email address',
    },
    phone: {
      label: 'Phone',
    },
    location: {
      label: 'Location',
      locations: [
        {
          label: 'United States - California',
          value: 'United States - California',
        },
        {
          label: 'United States - Not in California',
          value: 'United States - Not in California',
        },
        {
          label: 'United Kingdom',
          value: 'United Kingdom',
        },
        {
          label: 'European Economic Area (EEA)',
          value: 'European Economic Area (EEA)',
        },
        {
          label: 'Canada',
          value: 'Canada',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    requestType: {
      label: 'Select request type',
      options: [
        {
          label: 'Delete / erase my stored data',
          value: 'Delete/Erase',
        },
        {
          label: 'Provide a copy of my stored data',
          value: 'Access',
        },
        {
          label: 'Other rights request',
          value: 'Other',
        },
      ],
    },
    financialInstitutions: {
      label: 'Your financial institution(s)',
      placeholder:
        'Please provide any relevant details related to your request, including a list of your financial institution(s). Do not include your password(s) or full account number(s) for any of your financial institution account(s).',
    },
    submitText: 'Submit',
    formSuccessMessage:
      'Your request has been received. We will do our best to respond promptly. Please refresh this page if you would like to submit another request using this form.',
    formErrorMessage:
      'There was an error with sending your request. Please try again or contact us directly at ',
    disclaimerText:
      "By submitting this form, I confirm that I have read and understood Plaid's <a href='/legal/#privacy-statement'>privacy policies</a>.",
    additionalInfoHeader: 'Request Types: Additional Information',
    additionalInfoBullets: [
      'This form is related to the data Plaid has collected and stored about you. Plaid is unable to assist you with modifying, accessing or deleting any personal data held by the third party apps you use, therefore we suggest you contact the apps directly in regards to any personal information those apps may have collected.',
      'We have  developed an online portal at my.plaid.com, where some users with financial accounts in the US will have the ability to manage the financial data they’ve shared using Plaid. If you’re interested in learning more, we encourage you to visit my.plaid.com and create an account.',
      'Deletion of data is not reversible, and if requested, this will permanently delete your personal data from Plaid’s systems which may impact your ability to use products or services powered by Plaid. Please note, however, that if you use Plaid in the future to connect one or more of your financial accounts to an app, then Plaid will once again obtain personal data about you related to that new connection.',
      'Plaid’s compliance with your request is subject to some limitations and exceptions as provided by law.  For example, as permitted, Plaid retains certain information about you for security and compliance purposes.',
    ],
  },
  es: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Formulario de solicitud de privacidad: Acceso/supresión y otros derechos de protección de datos',
      'meta-description': 'Ejercer sus derechos de protección de datos', // Google Translate based on en version
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Formulario de solicitud de privacidad: Acceso/supresión y otros derechos de protección de datos',
    introHeader: 'Introducción',
    intro: `<p>Rellene el siguiente formulario para enviar su solicitud de privacidad. Tenga en cuenta que, para poder tratar su solicitud, se le pedirá que proporcione la información adicional necesaria para verificar su identidad. La información que nos facilite a continuación se utilizará únicamente con el fin de atender su solicitud.</p>
    <p>Para obtener más información o ejercer cualquier otro derecho de protección de datos que pueda serle aplicable como Usuario final, consulte nuestra Política de privacidad para usuarios finales en la sección titulada “Sus derechos de protección de datos” o envíenos un correo electrónico a <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si usted no es un Usuario final (no ha utilizado los productos o servicios de Plaid o no ha conectado una aplicación a través de Plaid), consulte nuestra Declaración de privacidad para obtener más información sobre sus derechos o envíenos un correo electrónico a <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si usted es un Candidato o Empleado de Plaid, por favor consulte nuestro Aviso de privacidad del candidato o el Aviso de privacidad del personal para obtener información o envíenos un correo electrónico a <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>. </p>
    <p>No debe utilizar este formulario si tiene problemas para conectarse a una aplicación concreta. Si necesita más ayuda para conectarse a una aplicación, póngase en contacto directamente con ella. Puede encontrar información general sobre la conexión de cuentas financieras en nuestro centro de ayuda al consumidor. </p>`,
    formHeader: 'Formulario',
    firstName: {
      label: 'Nombre',
    },
    lastName: {
      label: 'Apellidos',
    },
    email: {
      label: 'Correo electrónico',
    },
    phone: {
      label: 'Teléfono',
    },
    requestType: {
      label: 'Seleccione el tipo de solicitud',
      options: [
        {
          label: 'Borrar/eliminar datos almacenados sobre mí',
          value: 'Supresión/eliminación',
        },
        {
          label: 'Acceder a una copia de mis datos',
          value: 'Acceso',
        },
        {
          label: 'Solicitud de otros derechos de protección de datos',
          value: 'Other',
        },
      ],
    },
    financialInstitutions: {
      label: 'Su(s) institución(es) financiera(es)',
      placeholder:
        'Proporcione cualquier información relevante relacionada con su solicitud, incluida una lista de sus entidades financieras y el nombre de usuario para cada una de ellas. No revele las contraseñas de ninguna de sus cuentas en entidades financieras.',
    },
    submitText: 'Enviar', // Google Translate based on en version
    disclaimerText:
      "Al enviar este formulario, confirmo que he leído y comprendido las <a href='/legal/#declaraci%C3%B3n-de-privacidad'>políticas de privacidad</a> de Plaid.", // Google Translate based on en version
    additionalInfoHeader: 'Tipos de solicitud: Información adicional',
    additionalInfoBullets: [
      'Este formulario está relacionado con los datos que Plaid ha recopilado y almacenado sobre usted. Plaid no puede ayudarle a modificar, acceder o eliminar los datos personales que posean las aplicaciones de terceros que utilice, por lo que le sugerimos que se ponga en contacto directamente con las aplicaciones en relación con cualquier información personal que dichas aplicaciones puedan haber recopilado.',
      'Hemos desarrollado un portal en línea en my.plaid.com donde algunos usuarios con cuentas financieras en EE. UU. tendrán la posibilidad de gestionar los datos financieros que han compartido utilizando Plaid. Si está interesado en saber más, le animamos a que visite my.plaid.com y cree una cuenta.',
      'La eliminación de datos no es reversible, y si se solicita, esto eliminará permanentemente sus datos personales de los sistemas de Plaid, lo que puede afectar a su capacidad para utilizar productos o servicios de Plaid. Sin embargo, tenga en cuenta que si en el futuro utiliza Plaid para conectar una o varias de sus cuentas financieras a una aplicación, Plaid volverá a obtener datos personales sobre usted relacionados con esa nueva conexión.',
      'El cumplimiento de su solicitud por parte de Plaid está sujeto a algunas limitaciones y excepciones previstas por la ley. Por ejemplo, según lo permitido, Plaid conserva determinada información sobre usted por motivos de seguridad y cumplimiento de la normativa.',
    ],
  },
  fr: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Formulaire de demande en matière de confidentialité : Droits d’accès/de suppression et autres droits en matière de protection des données',
      'meta-description': 'Exercez vos droits de protection des données', // Google Translate based on en version
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Formulaire de demande en matière de confidentialité : Droits d’accès/de suppression et autres droits en matière de protection des données',
    introHeader: 'Introduction',
    intro: `Veuillez compléter le formulaire ci-dessous pour soumettre votre demande en matière de confidentialité. Veuillez noter que pour pouvoir traiter votre demande, vous devrez fournir des informations complémentaires qui sont nécessaires pour vérifier votre identité. Les informations que vous fournissez ci-dessous seront uniquement utilisées aux fins de répondre à votre demande.
    Pour en savoir plus et/ou pour exercer d’autres droits en matière de protection des données pouvant s’appliquer à vous en tant qu’Utilisateur final, veuillez consulter notre Politique de confidentialité de l’Utilisateur final dans la section intitulée « Vos droits en matière de protection des données » ou veuillez nous envoyer un e-mail à l’adresse <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si vous n’êtes pas un Utilisateur final (vous n’avez pas utilisé de produits ou de services de Plaid et/ou vous ne vous êtes pas connecté(e) à une application via Plaid), veuillez consulter notre Déclaration de confidentialité pour plus d’informations sur vos droits ou veuillez envoyer un e-mail à l’adresse <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si vous êtes un(e) Candidat(e) ou un(e) Employé(e) de Plaid, veuillez consulter notre Avis de confidentialité pour les candidats ou notre Avis de confidentialité du personnel pour plus d’informations ou veuillez nous envoyer un e-mail à l’adresse <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>. 
    Vous ne devez pas utiliser ce formulaire si vous rencontrez des difficultés pour vous connecter à une application particulière. Pour obtenir de l’aide concernant votre connexion à une application, veuillez contacter directement l’application. Vous pouvez trouver des informations générales sur la connexion des comptes financiers sur notre centre d’aide aux consommateurs. `,
    formHeader: 'Formulaire',
    firstName: {
      label: 'Prénom',
    },
    lastName: {
      label: 'Nom',
    },
    email: {
      label: 'E-mail',
    },
    phone: {
      label: 'Téléphone',
    },
    requestType: {
      label: 'Sélectionnez votre type de demande',
      options: [
        {
          label: 'Supprimer/Effacer les données stockées me concernant',
          value: 'Suppression / Effacement',
        },
        {
          label: 'Accéder à une copie de mes données',
          value: 'Accès',
        },
        {
          label:
            'Autre demande en matière de droits relatifs à la protection des données',
          value: 'Other',
        },
      ],
    },
    financialInstitutions: {
      label: 'Votre (vos) institution(s) financière(s)',
      placeholder:
        'Veuillez fournir tout détail pertinent lié à votre demande, y compris une liste de vos institutions financières et le nom d’utilisateur pour chaque institution financière identifiée. Merci de ne PAS inclure votre mot de passe pour vos comptes détenus auprès de ces institutions financières.',
    },
    submitText: 'Soumettre', // Google Translate based on en version
    disclaimerText:
      "En soumettant ce formulaire, je confirme avoir lu et compris les   <a href='/legal/#notice-d-information-sur-le-traitement-des-donn%C3%A9es-personnelles'>politiques de confidentialité</a> de Plaid.Déclaration de confidentialité de Plaid.",
    additionalInfoHeader: 'Types de demande : Informations supplémentaires',
    additionalInfoBullets: [
      'Ce formulaire est lié aux données que Plaid a collectées et stockées à votre sujet. Plaid n’est pas en mesure de vous aider à modifier, accéder ou supprimer les données à caractère personnel détenues par les applications tierces que vous utilisez, nous vous suggérons donc de contacter directement les applications en ce qui concerne les informations personnelles que ces applications peuvent avoir collectées.',
      'Nous avons créé un portail en ligne à l’adresse my.plaid.com, où certains utilisateurs ayant des comptes financiers aux États-Unis auront la possibilité de gérer les données financières qu’ils ont partagées à l’aide de Plaid. Si vous souhaitez en savoir plus, nous vous invitons à consulter my.plaid.com et à créer un compte.',
      'La suppression des données n’est pas réversible et, sur demande, cela supprimera définitivement vos données à caractère personnel des systèmes de Plaid, ce qui peut avoir un impact sur votre capacité à utiliser les produits ou services fournis par Plaid. Veuillez noter, cependant, que si vous utilisez Plaid à l’avenir pour connecter un ou plusieurs de vos comptes financiers à une application, Plaid obtiendra à nouveau des données à caractère personnel vous concernant dans le cadre de cette nouvelle connexion.',
      'La conformité de Plaid à votre demande est soumise à certaines limitations et exceptions prévues par la loi. Par exemple, dans le cadre de ce qui est autorisé, Plaid conserve certaines informations vous concernant à des fins de sécurité et de conformité.',
    ],
  },
  nl: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Verzoek tot inzage/gegevenswissing',
      'meta-description': 'Oefen uw rechten op gegevensbescherming uit', // Google Translate based on en version
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader: 'Verzoek tot inzage/gegevenswissing',
    introHeader: 'Voorwoord',
    intro: `<p>In bepaalde rechtsgebieden en onder voorbehoud van beperkingen en uitsluitingen ingevolge de wet kunt u aanspraak maken op bepaalde rechten en keuzes ten aanzien van de gegevens die we over u verzamelen en hoe die worden gebruikt. Voor het uitoefenen van uw recht op inzage of gegevenswissing, naargelang van toepassing, kunt u onderstaand formulier invullen of contact met ons opnemen via <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. NB Voor de verwerking van uw verzoek wordt u verzocht aanvullende gegevens te verstrekken zodat uw identiteit kan worden geverifieerd. De gegevens die u hiervoor verstrekt, worden uitsluitend gebruikt om aan uw verzoek te kunnen voldoen.</p>
      <p>Klik <a href="#additional-info" data-smooth-scroll data-offset="80">hier</a> voor meer informatie over de in onderstaand formulier genoemde Soorten Verzoek. Voor aanvullende informatie over en/of het uitoefenen van andere gegevensbeschermingsrechten die op u als eindgebruiker van toepassing kunnen zijn, verwijzen wij u graag naar ons <a href="https://plaid.com/legal/#privacybeleid-voor-eindgebruikers">Privacybeleid voor eindgebruikers</a> dat u kunt vinden onder “Uw gegevensbeschermingsrechten”. U kunt ook een e-mail sturen naar <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. NB Als u een kandidaat bent voor een vacature, vindt u meer informatie in onze <a href="https://plaid.com/legal/#candidate-privacy-notice">Privacyverklaring voor kandidaten</a> en stuurt u eventuele verzoeken betreffende uitoefening van uw gegevensbeschermingsrechten per e-mail naar <a href="mailto:people-privacy@plaid.com">people-privacy@plaid.com</a>, naargelang van toepassing.</p>`,
    formHeader: 'Formulier',
    firstName: {
      label: 'Voornaam',
    },
    lastName: {
      label: 'Achternaam',
    },
    email: {
      label: 'E-mailadres',
    },
    phone: {
      label: 'Telefoon',
    },
    requestType: {
      label: 'Selecteer het type verzoek',
      options: [
        {
          label: 'Gegevenswissing',
          value: 'Gegevenswissing',
        },
        {
          label: 'Inzage',
          value: 'Inzage',
        },
      ],
    },
    financialInstitutions: {
      label: 'Uw financiële instelling(en)',
      placeholder:
        'Geef a.u.b. relevante details betreffende uw verzoek, met inbegrip van een lijst van de financiële instelling(en) en gebruikersnaam voor elk van de opgegeven instellingen. Vermeld onder geen beding uw wachtwoord(en) voor uw accounts bij financiële instellingen.',
    },
    submitText: 'Verzenden', // Google Translate based on en version
    disclaimerText:
      "Door dit formulier in te dienen, bevestig ik dat ik Plaid&#39;s <a href='/legal/#privacybeleid-voor-eindgebruikers'>Privacyverklaring</a> heb gelezen en begrepen.",
    additionalInfoHeader: 'Soorten verzoek: Aanvullende informatie',
    accessHeader: 'Inzage',
    additionalInfoBullets: [
      'This form is related to the data Plaid has collected and stored about you. Plaid is unable to assist you with modifying, accessing or deleting any personal data held by the third party apps you use, therefore we suggest you contact the apps directly in regards to any personal information those apps may have collected.',
      'We have  developed an online portal at my.plaid.com, where some users with financial accounts in the US will have the ability to manage the financial data they’ve shared using Plaid. If you’re interested in learning more, we encourage you to visit my.plaid.com and create an account.',
      'Deletion of data is not reversible, and if requested, this will permanently delete your personal data from Plaid’s systems which may impact your ability to use products or services powered by Plaid. Please note, however, that if you use Plaid in the future to connect one or more of your financial accounts to an app, then Plaid will once again obtain personal data about you related to that new connection.',
      'Plaid’s compliance with your request is subject to some limitations and exceptions as provided by law.  For example, as permitted, Plaid retains certain information about you for security and compliance purposes.',
    ],
    accessIntro:
      'Als u ervoor kiest om uw recht op <strong>inzage van uw gegevens</strong> uit te oefenen, naargelang van toepassing, wijzen wij u op het volgende:',
    accessBullets: [
      'U kunt dit recht uitoefenen om te vragen om inzage in of ontvangst van een kopie van de persoonsgegevens die Plaid van u bewaart (of die ten behoeve van Plaid worden bewaard). Aangezien Plaid u niet kan helpen met inzage in de persoonsgegevens die zijn opgeslagen door de apps die u gebruikt, raden wij u aan rechtstreeks contact op te nemen met de betreffende apps.',
      'Wij werken momenteel aan de ontwikkeling van een onlineportaal op my.plaid.com, waar bepaalde gebruikers met financiële accounts in de VS de financiële gegevens kunnen beheren die ze bij het gebruik van Plaid hebben verstrekt. Als u daar meer over wilt weten, ga dan naar <a href="https://my.plaid.com/">my.plaid.com</a> en maak daar een account aan.',
      'Dit formulier is <u>niet</u> bestemd voor melding van problemen met koppelingen naar bepaalde apps. Voor meer hulp met koppelingen naar apps wordt u verzocht om rechtstreeks contact te zoeken met de betrokken app of gaat u naar <a href="http://my.plaid.com/help">my.plaid.com/help</a> voor aanvullende informatie.',
    ],
    deleteHeader: 'Gegevenswissing',
    deleteIntro:
      'Als u ervoor kiest om uw recht op <strong>gegevenswissing</strong> uit te oefenen, naargelang van toepassing, wijzen wij u op het volgende:',
    deleteBullets: [
      'Deze actie is niet omkeerbaar en resulteert in de definitieve verwijdering van uw persoonsgegevens uit de systemen van Plaid. Wij wijzen u er echter op dat als u Plaid in de toekomst gebruikt om een of meer van uw financiële accounts te koppelen aan een app, Plaid op dat moment wederom persoonsgegevens van u verkrijgt, namelijk met betrekking tot die nieuwe koppeling.',
      'Ingevolge hetgeen is toegestaan onder toepasselijke wetgeving bewaart Plaid bepaalde gegevens van u in het kader van beveiligingsvereisten en om toepasselijke wet- en regelgeving na te komen.',
      'Met het uitoefenen van dit recht worden de persoonsgegevens niet van uw apps gewist. Voor verzoeken om verwijdering van persoonsgegevens die eventueel op die apps zijn opgeslagen, dient u rechtstreeks contact op te nemen met de betreffende apps.',
      'Deze actie kan leiden tot verlies van gegevens die van belang zijn voor de apps die u gebruikt en kan zelfs nadelige gevolgen hebben voor de mogelijkheid om van die apps gebruik te maken. Wij raden u dan ook aan om voordat u verdergaat eerst contact te zoeken met die apps als u vragen heeft over hoe de verwijdering van uw persoonsgegevens uw gebruik van de betreffende app zou kunnen beïnvloeden.',
    ],
  },
};
