import { Box } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';

const CmsLeftSectionalHeaderContent = ({ fields, sys }) => {
  return (
    <Box
      sx={{
        textAlign: 'left',
        '& .eyebrow-heading': {
          mb: { xs: '1.8rem', md: '3.2rem' },
        },
        '& .decorative-text': {
          mb: { xs: '1.8rem', md: '3.2rem' },
        },
        '& h1:has(+ p:not(:empty)), & h1:has(+ .button-group:not(:empty))': {
          mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
        },
        '& h2:has(+ p:not(:empty)), & h2:has(+ .button-group:not(:empty))': {
          mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
        },
        '& p': {
          mb: '0.8rem',
        },
        '& p:nth-last-of-type(1):empty': {
          display: 'none',
        },
        '& .button-group': {
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'wrap',
          gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
        },
      }}
    >
      <RichTextSwitch
        sys={sys}
        content={fields?.leftCenteredBody}
        nodes={{
          h1: {
            variant: 'h1',
            component: 'h1',
            fieldId: 'leftCenteredBody',
          },
          h2: {
            variant: 'h1',
            component: 'h2',
            fieldId: 'leftCenteredBody',
          },
          h6: {
            variant: 'h6',
            component: 'h3',
            className: 'eyebrow-heading',
            fieldId: 'leftCenteredBody',
          },
          p: {
            variant: 'p',
            fieldId: 'leftCenteredBody',
          },
        }}
      />
    </Box>
  );
};

export default CmsLeftSectionalHeaderContent;
