/*
  // animates the width of an element
  const wideBoi = () => {
    const node = document.querySelector('#narrow-boi');
    return (progress) => {
      node.style.width = progress * 100 + '%';
    };
  }

  // apply the animation via JS
  animate({
    draw: wideBoi()
    duration: 2000, // optional
    start: () => { console.log('starting animation'); } // optional
    done: () => { console.log('animation done'); } // optional
    complete: () => { console.log('animation complete, even if it failed'); } // optional
  });
*/

const swing = (timeFraction: number): number =>
  0.5 - Math.cos(timeFraction * Math.PI) / 2;

const linear = (timeFraction: number): number => timeFraction;

// based off of https://github.com/jquery/jquery/blob/a684e6ba836f7c553968d7d026ed7941e1a612d8/src/effects/Tween.js
const ANIMATIONS = {
  swing,
  linear,
};

enum Easing {
  linear = 'linear',
  swing = 'swing',
}

interface Animate {
  timing?: Easing;
  draw(progress: number): void; // 0 is start 1 is end
  duration?: number;
  start?(): void;
  done?(): void;
  complete?(): void;
}

const animate = ({
  timing = Easing.swing,
  draw,
  duration = 400,
  start,
  done,
  complete,
}: Animate): Promise<void> => {
  const animation = new Promise<void>((resolve, reject) => {
    const startTime = performance.now();
    try {
      if (start) {
        start();
      }
      requestAnimationFrame(function step(time) {
        // timeFraction goes from 0 to 1
        let timeFraction = (time - startTime) / duration;
        if (timeFraction > 1) timeFraction = 1;

        // calculate the current animation state
        const progress = ANIMATIONS[timing](timeFraction);

        draw(progress); // draw it

        if (timeFraction < 1) {
          requestAnimationFrame(step);
        } else {
          if (done) {
            done();
          }
          resolve(); //
          if (complete) {
            complete();
          }
        }
      });
    } catch (e) {
      reject(e);
      if (complete) {
        complete();
      }
    }
  });

  return animation;
};

export default animate;
