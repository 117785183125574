export const imageCardStyles = {
  '& img': {
    border: 'solid 1px',
    borderColor: 'transparent',
    borderRadius: '1.6rem',
    mb: { xs: '2.4rem', sm: '1.6rem' },
  },
  '& h3, p': {
    mb: { xs: '0.8rem', sm: '1.6rem' },
  },
  '& p:nth-last-of-type(1):empty': {
    display: 'none',
  },
};

export const getStatCardStyles = (cards) => {
  return {
    '& h2': {
      mb: { xs: '1.2rem', sm: '2.0rem', md: '2.8rem' },
    },
    '& p': {
      width:
        cards?.length === 2 ? { sm: '200px', md: '350px', lg: '500px' } : null,
      overflowWrap: cards?.length === 2 ? 'break-word' : null,
    },
    '& img': {
      mb: { xs: '1.2rem', sm: '2.0rem', md: '2.8rem' },
    },
    '& p:nth-last-of-type(1):empty': {
      display: 'none',
    },
  };
};
