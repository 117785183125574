/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import cx from 'classnames';
import Icon from './Icon';

export interface IntroSectionItemProps {
  icon?: string;
  label: string;
  description: string | Array<string>;
  descriptionSize: string;
  paddingBottom?: boolean;
  paddingTop?: boolean;
}

const IntroSectionItem: FC<IntroSectionItemProps> = ({
  description,
  descriptionSize,
  icon = '',
  label,
  paddingBottom,
  paddingTop,
}) => (
  <div
    className={cx('grid-x', 'intro-section__item', {
      'intro-section__item--remove-top-padding': !paddingTop,
      'intro-section__item--remove-bottom-padding': !paddingBottom,
    })}
    data-testid='intro-section-item'
  >
    <div className='cell small-12 medium-3'>
      <h6 className='intro-section__label'>
        {icon && <Icon type={icon} />} {label}
      </h6>
    </div>
    <div className='cell small-12 medium-8 medium-offset-1'>
      {Array.isArray(description) ? (
        <div
          className='intro-section__multiline-desc'
          data-testid='introsection-multiline-desc'
        >
          {description.map((desc, i) => (
            <p key={`description-${i}`}>{desc}</p>
          ))}
        </div>
      ) : (
        <h4
          className={cx('regular', 'intro-section__text', {
            [descriptionSize]: descriptionSize,
          })}
        >
          {description}
        </h4>
      )}
    </div>
  </div>
);

export default IntroSectionItem;
