import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { Button, Link } from 'src/components/Inputs';
import ArrowRightBlack from 'src/components/SVGs/Navbar/ArrowRightBlack';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';
import InfoIcon from 'src/components/SVGs/Icons/InfoIcon';
import Image from 'src/components/Image';

const propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  link: PropTypes.object,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
      alt: PropTypes.string,
      price: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      classes: PropTypes.string,
      hasInfoDropdown: PropTypes.bool,
      infoText: PropTypes.string,
      ctaLink: PropTypes.string.isRequired,
      ctaText: PropTypes.string.isRequired,
      buttonTrackingId: PropTypes.string,
      hasHeader: PropTypes.bool,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          feature: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          hasFeature: PropTypes.bool,
        }),
      ).isRequired,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          feature: PropTypes.string.isRequired,
          hasFeature: PropTypes.bool,
        }),
      ).isRequired,
      hideServicesMobile: PropTypes.bool,
    }).isRequired,
  ).isRequired,
};

const PricingTable = ({
  title,
  header,
  tableData,
  link: { text = '', ...linkProps } = {},
}) => (
  <div
    className={cx('background-page-wrapper pricing-table', {
      'pricing-table--with-link': linkProps,
    })}
  >
    <div className='grid-container section-container'>
      <div className='grid-x'>
        <div className='cell medium-7 medium-offset-3 large-12 large-offset-0'>
          <h6 className='section-title mb1'>{title}</h6>
          <h2 className='section-header'>{header}</h2>
          {text && (
            <Link href={linkProps.href}>
              <a {...linkProps}>
                <span className='pricing-table-link feature-section-link-text'>
                  {text}{' '}
                  <span className='arrow-right'>
                    <ArrowRightBlack />
                  </span>
                </span>
              </a>
            </Link>
          )}
        </div>
      </div>

      <div className='grid-x'>
        <div className='cell medium-9 medium-offset-3 large-12 large-offset-0'>
          <div className='items-table-layout'>
            <div className='grid-x'>
              {tableData.map((tableItem, i) => {
                const columnWidth = Math.ceil(12 / tableData.length);
                return (
                  <div
                    className={cx(`cell large-${columnWidth}`)}
                    key={`tableItem-${i}`}
                  >
                    <div className={cx('items-table', tableItem.classes)}>
                      <div className={`items-table-img ${tableItem.label}-img`}>
                        <Image
                          src={tableItem.img}
                          aria-hidden='true'
                          alt={tableItem.alt}
                        />
                      </div>
                      <h6 className='items-table-label'>{tableItem.label}</h6>
                      <h2 className='items-table-price'>{tableItem.price}</h2>
                      <p className='items-table-description'>
                        {tableItem.description}
                        {tableItem.hasInfoDropdown && (
                          <>
                            {' '}
                            <button
                              className='info-dropdown'
                              type='button'
                              data-toggle={`items-info-dropdown-${tableItem.label}`}
                              aria-labelledby={`items-info-dropdown-${tableItem.label}`}
                            >
                              <span className='info-icon'>
                                <InfoIcon />
                              </span>
                            </button>
                          </>
                        )}
                      </p>

                      <div
                        className='dropdown-pane info-dropdown-pane'
                        id={`items-info-dropdown-${tableItem.label}`}
                        data-dropdown
                        data-position='top'
                        data-alignment='center'
                        data-v-offset='10'
                        data-allow-overlap='true'
                        data-hover='true'
                      >
                        <p>{tableItem.infoText}</p>
                      </div>

                      <div className='items-table-cta-section'>
                        <Button
                          href={tableItem.ctaLink}
                          className='button-cta signup-attach-utm'
                          fullWidth
                          trackingId={tableItem.buttonTrackingId}
                        >
                          {tableItem.ctaText}
                        </Button>
                      </div>

                      <div className='items-table-list'>
                        {tableItem.hasHeader && (
                          <h6 className='items-table-list-header'>products</h6>
                        )}
                        {tableItem.products.map((productItem, i) => (
                          <div
                            key={`productItem-${i}`}
                            className={cx('items-table-list-item', {
                              'has-feature': productItem.hasFeature,
                            })}
                          >
                            {productItem.hasFeature ? (
                              <>
                                <span className='check-icon'>
                                  <Checkmark />
                                </span>
                                <p className='items-table-list-item-text'>
                                  <Link href={productItem.url}>
                                    <a>{productItem.feature}</a>
                                  </Link>
                                </p>
                              </>
                            ) : (
                              <>
                                <span className='minus-icon'>−</span>
                                <span className='items-table-list-item-text'>
                                  {productItem.feature}
                                </span>
                              </>
                            )}
                          </div>
                        ))}
                      </div>

                      <div
                        className={cx(
                          'items-table-list pricing-table-list-services',
                          {
                            'show-for-large': tableItem.hideServicesMobile,
                          },
                        )}
                      >
                        {tableItem.hasHeader && (
                          <h6 className='items-table-list-header'>services</h6>
                        )}
                        {tableItem.services.map((serviceItem, i) => (
                          <div
                            key={`service-${i}`}
                            className={cx('items-table-list-item', {
                              'has-feature': serviceItem.hasFeature,
                            })}
                          >
                            {serviceItem.hasFeature ? (
                              <>
                                <span className='check-icon'>
                                  <Checkmark />
                                </span>
                                <span className='items-table-list-item-text'>
                                  {serviceItem.feature}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className='minus-icon'>−</span>
                                <span className='items-table-list-item-text'>
                                  {serviceItem.feature}
                                </span>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PricingTable.propTypes = propTypes;
export default PricingTable;
