export const metaData = {
  'meta-title': 'Payments - financial data solutions',
  'meta-description':
    'Initiate payments within your app. Plaid is the easiest way for people to connect bank accounts to an app.',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  label: true,
  'label-text': 'Payments',
  header: 'Initiate payments within your app',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '#contactForm',
  'secondary-cta': false,
  'secondary-cta-text': null,
  'tertiary-cta':
    'Or, <a class="signup-attach-utm" href="//dashboard.plaid.com/signup">get started</a>',
  video: true,
  'video-width': 1138.2,
  'video-height': 528,
  'video-poster-image': '/assets/img/hero/consumer-payments-hero.png',
  'video-fallback-image':
    '/assets/img/hero/consumer-payments-hero-fallback.png',
  'video-formats': [
    {
      url: '/assets/video/consumer-payments-hero.webm',
      type: 'webm',
    },
    {
      url: '/assets/video/consumer-payments-hero.mp4',
      type: 'mp4',
    },
  ],
};

export const introSectionData = {
  'problem-statement':
    'Sending payments via direct bank transfer typically requires your users to switch between your application and their bank’s, manually enter account information, and in some cases, pay additional fees.',
  'solution-statement':
    'Plaid makes it easier for your users to initiate payments within your application’s flow. These in-app payments allow your users to fund their accounts, make online payments, and pay bills in a seamless and secure manner, and at a low cost.',
};

export const featureSectionData = {
  'feature-section-title': [
    {
      label: 'Products and benefits',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/products/income-112.png',
      title:
        'Initiate payments for account funding, online payments, and bill pay',
      'header-class': 'h5',
      content:
        '<a href="/uk/products/payment-initiation">Payment Initiation</a> enables your users to make real-time payments without having to leave your app',
      'no-link': true,
    },
    {
      img: '/assets/img/products/identity-112.png',
      title: 'Verify identities to reduce fraud',
      'header-class': 'h5',
      content:
        '<a href="/uk/products/identity">Identity</a> confirms users\' name with what\'s on file at the bank',
      'no-link': true,
    },
  ],
};

export const simpleCarouselData = {
  'carousel-title': 'How It Works',
  'carousel-slides': [
    {
      header: 'Initiate a payment',
      description:
        'Customer confirms the amount and payee information that has been pre-populated by you.',
      'bg-color': 'bg-green',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-01.png',
    },
    {
      header: 'Select a bank',
      description:
        'Your customer selects the bank from which to initiate the payment. Plaid supports most banks and neobanks in the UK.',
      'bg-color': 'bg-pink',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-02.png',
    },
    {
      header: 'Continue to bank redirect',
      description:
        'Your customer is redirected to their bank to authenticate their account using Open Banking APIs.',
      'bg-color': 'bg-yellow',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-03.png',
    },
    {
      header: 'Authenticate bank account',
      description:
        'Your customer authenticates and completes the payment transfer. Funds are instantly pushed into your account.',
      'bg-color': 'bg-blue',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-04.png',
    },
    {
      header: 'Confirm payment transfer',
      description:
        'With the customer’s explicit consent, Plaid <a href="https://plaid.com/docs/#onevent-callback">returns the status</a> of the payment, and your customer seamlessly returns to your application. You can always check a payment status in the developer dashboard.',
      'bg-color': 'bg-purple',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-05.png',
    },
  ],
};

export const ctaData = {
  'marketo-key': 2007,
  title: 'Questions about how Plaid can fit into your business?',
  'with-subtext': true,
  subtext: 'Tell us how we can help and we’ll get in touch shortly.',
  'subtext-class': 'h4 regular',
  'include-phone': true,
};
