import React from 'react';
import PropTypes from 'prop-types';

import { stripStyleAttr } from 'src/components/job/utils';

const propTypes = {
  additional: PropTypes.string.isRequired,
};
const JobAdditional = ({ additional }) => {
  // additional from Lever includes raw HTML
  const additionalWithMarkup = { __html: stripStyleAttr(additional) };
  return (
    <div className='job-post__additional'>
      <div dangerouslySetInnerHTML={additionalWithMarkup}></div>
    </div>
  );
};

JobAdditional.propTypes = propTypes;
export default JobAdditional;
