import React, { FC } from 'react';
import LazyLoad from 'react-lazyload';

// constants
import { LAZY_OFFSET } from 'src/lib/constants';

interface Props {
  isLazy?: boolean;
  children: React.ReactNode;
  offset?: number;
  scrollContainer?: string | React.ReactNode;
  height?: string | number;
}

const Lazy: FC<Props> = ({
  children,
  isLazy = true,
  offset = LAZY_OFFSET,
  scrollContainer,
  height = null,
}) => {
  return isLazy ? (
    <LazyLoad
      once
      offset={offset}
      height={height || '100%'}
      scrollContainer={scrollContainer}
    >
      {children}
    </LazyLoad>
  ) : (
    <>{children}</>
  );
};

export default Lazy;
