import React from 'react';
import PropTypes from 'prop-types';
import styles from './SocialProof.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SocialProofText = ({ children }) => (
  <p className={styles.text} data-testid='social-proof-text'>
    {children}
  </p>
);

SocialProofText.propTypes = propTypes;
export default SocialProofText;
