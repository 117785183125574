import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Image from 'src/components/Image';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

// styles
import styles from './Quote--cms.module.scss';

const propTypes = {
  bgColor: PropTypes.string,
  cite: PropTypes.string.isRequired,
  className: PropTypes.string,
  cta: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  disableDefaultDesign: PropTypes.bool,
  isDark: PropTypes.bool,
  logo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }),
  quote: PropTypes.string.isRequired,
  withBackground: PropTypes.bool,
  heading: PropTypes.string,
};

const QuoteCms = ({
  bgColor = '',
  className = '',
  cite,
  cta = { href: '', text: '' },
  disableDefaultDesign = false,
  isDark = false, // supersedes other bg treatments due to cascade in CSS
  logo = {},
  quote,
  withBackground = false,
  heading = '',
}) => {
  const hasLogo = logo && logo.src;
  const hasCta = cta && cta.href && cta.text;
  const useDefaultDesign =
    !disableDefaultDesign && !withBackground && !isDark && !bgColor;
  return (
    <div
      className={cx(
        styles.quoteSection,
        {
          [styles.withBackground]: withBackground,
          [styles.isDark]: isDark,
          [`${styles[bgColor]}`]: bgColor,
          [styles.withHeading]: heading.length > 0,
        },
        className,
      )}
      data-testid='quote-section'
    >
      {useDefaultDesign && (
        <div data-testid='default-design' className={styles.defaultDesign} />
      )}
      <div className={cx('grid-container', styles.container)}>
        <div className='grid-x align-center'>
          <div className='cell medium-10'>
            {heading && <h4 className={styles.heading}>{heading}</h4>}
            <blockquote className={styles.blockquote}>{quote}</blockquote>
            <div className={styles.divider}>
              <span>
                {hasLogo && (
                  <div className={styles.logoContainer}>
                    <Image
                      className={styles.logoImage}
                      src={logo.src}
                      alt={logo.alt}
                      width={64}
                      heigth={64}
                      data-testid='quote-cms-logo-img'
                    />
                  </div>
                )}
              </span>
            </div>
            <cite
              className={styles.cite}
              dangerouslySetInnerHTML={{ __html: cite }}
            />
            {hasCta && (
              <Link href={cta.href}>
                <a className={styles.ctaLink}>
                  {cta.text} <ArrowRightSingleSmall />
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

QuoteCms.propTypes = propTypes;

export default QuoteCms;
