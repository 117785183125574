/* eslint-disable @typescript-eslint/naming-convention */
import Threads5Theme from 'threads5/themes/Threads5';
import createTheme from 'threads5/themes/create-theme';

const breakpoints = {
  xs: 0, // mobile
  sm: 640, // tablet
  md: 1024, // md screen
  lg: 1280, // lg screen
  xl: 1440, // xl screen
  xxl: 1730, // xxl screen
};

// NOTE: do your best not to use these colors anymore
export const legacyDotComColors = {
  white: '#ffffff',
  White: '#ffffff',
  black100: '#fafafa',
  black200: '#f6f6f6',
  black300: '#ededed',
  black400: '#dcdcdc',
  black500: '#c5c5c5',
  black600: '#949494',
  black700: '#767676',
  black800: '#555555',
  black900: '#383838',
  black1000: '#111111',
  black: '#111111',
  Black: '#111111',
  gray: '#f6f6f6',
  Gray: '#f6f6f6',
  transparent: 'rgba(0, 0, 0, 0)',

  'dark-gray': 'rgb(75, 75, 75)',

  // Colorful colors
  blue200: '#d4f9ff',
  blue400: '#b1eefc',
  blue600: '#63daff',
  blue700: '#37b0f4',
  blue800: '#0a85ea',
  blue900: '#0072cf',
  blue1000: '#0061b3',

  gold: '#ba7d17',

  green200: '#d0fce4',
  green400: '#abffdb',
  green600: '#5befbd',
  green800: '#23d09c',
  green900: '#1fa077',
  green1000: '#1e8262',

  yellow200: '#fefbb8',
  yellow400: '#fbf1a0',
  yellow600: '#fce76b',
  yellow800: '#f2d211',
  yellow900: '#e6ba23',
  yellow1000: '#dba81b',

  red200: '#ffd7dc',
  red400: '#ffaab9',
  red600: '#ff7885',
  red800: '#f44e66',
  red900: '#d83d57',
  red1000: '#b72743',

  purple200: '#e1e1ff',
  purple400: '#c6befc',
  purple600: '#9986f7',
  purple800: '#7646ec',
  purple900: '#5c2ec7',
  purple1000: '#4726a3',
};

// these rebrand colors do not yet exist in threads5
const rebrandColors = {
  'base/black/100': '#f9fafb',
  'base/black/200': '#eaeff0',
  'base/black/300': '#dee3e5',
  'base/black/400': '#c8cdce',
  'base/black/500': '#929596',
  'base/black/600': '#747677',
  'base/black/700': '#595b5c',
  'base/black/800': '#373839',
  'base/black/900': '#232424',
  'base/black/1000': '#111112',
  'base/blue/100': '#f7faff',
  'base/blue/200': '#e5effe',
  'base/blue/300': '#d3e4fd',
  'base/blue/400': '#b0cefc',
  'base/blue/500': '#5294f8',
  'base/blue/600': '#156ef5',
  'base/blue/700': '#0a54c4',
  'base/blue/800': '#06347a',
  'base/blue/900': '#04224f',
  'base/blue/1000': '#021127',
  'base/green/100': '#f0fdf8',
  'base/green/200': '#cdf7e7',
  'base/green/300': '#a9f1d5',
  'base/green/400': '#57e4ab',
  'base/green/500': '#21a96f',
  'base/green/600': '#1b8657',
  'base/green/700': '#156843',
  'base/green/800': '#0d4029',
  'base/green/900': '#07291a',
  'base/green/1000': '#03140d',
  'base/lime/100': '#f7fff0',
  'base/lime/200': '#e8fed7',
  'base/lime/300': '#d2fbb6',
  'base/lime/400': '#7cf042',
  'base/lime/450': '#46da10',
  'base/lime/500': '#29a20b',
  'base/lime/600': '#188108',
  'base/lime/700': '#0b5c05',
  'base/lime/800': '#034903',
  'base/lime/900': '#013703',
  'base/lime/1000': '#002402',
  'base/minted/100': '#effffa',
  'base/minted/200': '#d8fef3',
  'base/minted/300': '#b6fbe9',
  'base/minted/400': '#42f0cd',
  'base/minted/450': '#10d1b7',
  'base/minted/500': '#0ba299',
  'base/minted/600': '#088181',
  'base/minted/700': '#05565c',
  'base/minted/800': '#034049',
  'base/minted/900': '#012e37',
  'base/minted/1000': '#001d24',
  'base/pink/100': '#fcf0ff',
  'base/pink/200': '#f9dbff',
  'base/pink/300': '#f5c2ff',
  'base/pink/400': '#ee99ff',
  'base/pink/450': '#e574fb',
  'base/pink/500': '#d45aed',
  'base/pink/600': '#bb33d7',
  'base/pink/700': '#8f29a3',
  'base/pink/800': '#671877',
  'base/pink/900': '#3f0849',
  'base/pink/1000': '#2a0132',
  'base/purple/100': '#f5f5ff',
  'base/purple/200': '#e6e6ff',
  'base/purple/300': '#ccccff',
  'base/purple/400': '#a3a3ff',
  'base/purple/450': '#8b8bf9',
  'base/purple/500': '#7373ed',
  'base/purple/600': '#5e5ed9',
  'base/purple/700': '#4444a7',
  'base/purple/800': '#2b2b82',
  'base/purple/900': '#1c1c69',
  'base/purple/1000': '#0d0d3f',
  'base/red/100': '#fff0f0',
  'base/red/200': '#ffd9d9',
  'base/red/300': '#ffc2c2',
  'base/red/400': '#ff9999',
  'base/red/450': '#fb7474',
  'base/red/500': '#ed5a5a',
  'base/red/600': '#ce3b3b',
  'base/red/700': '#a32929',
  'base/red/800': '#771818',
  'base/red/900': '#490808',
  'base/red/1000': '#320101',
  'base/sky/100': '#e5fcff',
  'base/sky/200': '#ccf8ff',
  'base/sky/300': '#9aedfe',
  'base/sky/400': '#51d2fb',
  'base/sky/450': '#0dadf2',
  'base/sky/500': '#0e94d8',
  'base/sky/600': '#0b7bbc',
  'base/sky/700': '#07578d',
  'base/sky/800': '#043c67',
  'base/sky/900': '#02294b',
  'base/sky/1000': '#00172e',
  'base/white': '#ffffff',
  'base/yellow/100': '#fffbf0',
  'base/yellow/200': '#fff6d8',
  'base/yellow/300': '#ffeda3',
  'base/yellow/400': '#fbde51',
  'base/yellow/450': '#ebcb00',
  'base/yellow/500': '#a39300',
  'base/yellow/600': '#807700',
  'base/yellow/700': '#615e00',
  'base/yellow/800': '#4d4d00',
  'base/yellow/900': '#363800',
  'base/yellow/1000': '#212400',
};

const rebrandLight = {
  'background/contrast-high': rebrandColors['base/white'],
  'background/contrast-low': rebrandColors['base/black/200'],
  'background/contrast-medium': rebrandColors['base/black/100'],
  'background/elevation-0': rebrandColors['base/black/100'],
  'background/elevation-1': rebrandColors['base/white'],
  'background/elevation-2': rebrandColors['base/white'],
  'background/elevation-3': rebrandColors['base/white'],
  'background/elevation-4': rebrandColors['base/white'],
  'background/interactive/disabled/contrast-high':
    rebrandColors['base/black/500'],
  'background/interactive/disabled/contrast-low':
    rebrandColors['base/black/200'],
  'background/interactive/negative/hovered/contrast-high':
    rebrandColors['base/red/700'],
  'background/interactive/negative/hovered/contrast-low':
    'rgba(206, 59, 59, 0.08)',
  'background/interactive/negative/pressed/contrast-high':
    rebrandColors['base/red/700'],
  'background/interactive/negative/pressed/contrast-low':
    'rgba(206, 69, 83, 0.16)',
  'background/interactive/negative/resting/contrast-high':
    rebrandColors['base/red/700'],
  'background/interactive/negative/resting/contrast-low':
    rebrandColors['base/red/600'],
  'background/interactive/neutral/hovered/contrast-high':
    'rgba(17, 17, 18, 0.84)',
  'background/interactive/neutral/hovered/contrast-low':
    'rgba(116, 118, 119, 0.08)',
  'background/interactive/neutral/pressed/contrast-high':
    'rgba(17, 17, 18, 0.84)',
  'background/interactive/neutral/pressed/contrast-low':
    'rgba(116, 118, 119, 0.16)',
  'background/interactive/neutral/resting/contrast-high':
    rebrandColors['base/black/1000'],
  'background/interactive/neutral/resting/contrast-low':
    rebrandColors['base/black/300'],
  'background/interactive/neutral/resting/contrast-medium':
    rebrandColors['base/black/600'],
  'background/interactive/prominent/hovered/contrast-high':
    rebrandColors['base/blue/700'],
  'background/interactive/prominent/hovered/contrast-low':
    'rgba(21, 110, 245, 0.08)',
  'background/interactive/prominent/pressed/contrast-high':
    rebrandColors['base/blue/700'],
  'background/interactive/prominent/pressed/contrast-low':
    'rgba(21, 110, 245, 0.16)',
  'background/interactive/prominent/resting/contrast-high':
    rebrandColors['base/blue/700'],
  'background/interactive/prominent/resting/contrast-low':
    rebrandColors['base/blue/600'],
  'background/inverse': rebrandColors['base/black/1000'],
  'background/overlay-1': 'rgba(17, 17, 18, 0.16)',
  'background/status-info': rebrandColors['base/purple/200'],
  'background/status-negative': rebrandColors['base/red/200'],
  'background/status-neutral': rebrandColors['base/black/200'],
  'background/status-positive': rebrandColors['base/green/200'],
  'background/status-prominent': rebrandColors['base/blue/200'],
  'background/status-warning': rebrandColors['base/yellow/200'],
  'gradient/background/stark/benjamin-end': rebrandColors['base/lime/200'],
  'gradient/background/stark/benjamin-mid': rebrandColors['base/minted/200'],
  'gradient/background/stark/benjamin-start': rebrandColors['base/sky/200'],
  'gradient/background/stark/elizabeth-end': rebrandColors['base/sky/200'],
  'gradient/background/stark/elizabeth-mid': rebrandColors['base/blue/200'],
  'gradient/background/stark/elizabeth-start': rebrandColors['base/purple/200'],
  'gradient/background/stark/euro-end': rebrandColors['base/yellow/200'],
  'gradient/background/stark/euro-mid': rebrandColors['base/pink/200'],
  'gradient/background/stark/euro-start': rebrandColors['base/purple/200'],
  'gradient/background/stark/lincoln-end': rebrandColors['base/yellow/200'],
  'gradient/background/stark/lincoln-mid': rebrandColors['base/lime/200'],
  'gradient/background/stark/lincoln-start': rebrandColors['base/minted/200'],
  'gradient/background/stark/viola-end': rebrandColors['base/sky/200'],
  'gradient/background/stark/viola-mid': rebrandColors['base/purple/200'],
  'gradient/background/stark/viola-start': rebrandColors['base/pink/200'],
  'gradient/background/subtle/benjamin-end': rebrandColors['base/lime/100'],
  'gradient/background/subtle/benjamin-mid': rebrandColors['base/minted/100'],
  'gradient/background/subtle/benjamin-start': rebrandColors['base/sky/100'],
  'gradient/background/subtle/elizabeth-end': rebrandColors['base/sky/100'],
  'gradient/background/subtle/elizabeth-mid': rebrandColors['base/blue/100'],
  'gradient/background/subtle/elizabeth-start':
    rebrandColors['base/purple/100'],
  'gradient/background/subtle/euro-end': rebrandColors['base/yellow/100'],
  'gradient/background/subtle/euro-mid': rebrandColors['base/pink/100'],
  'gradient/background/subtle/euro-start': rebrandColors['base/purple/100'],
  'gradient/background/subtle/lincoln-end': rebrandColors['base/yellow/100'],
  'gradient/background/subtle/lincoln-mid': rebrandColors['base/lime/100'],
  'gradient/background/subtle/lincoln-start': rebrandColors['base/minted/100'],
  'gradient/background/subtle/viola-end': rebrandColors['base/sky/100'],
  'gradient/background/subtle/viola-mid': rebrandColors['base/purple/100'],
  'gradient/background/subtle/viola-start': rebrandColors['base/pink/100'],
  'gradient/background/vibrant/benjamin-end': rebrandColors['base/sky/450'],
  'gradient/background/vibrant/benjamin-start':
    rebrandColors['base/minted/400'],
  'gradient/background/vibrant/elizabeth-end': rebrandColors['base/purple/450'],
  'gradient/background/vibrant/elizabeth-start': rebrandColors['base/sky/400'],
  'gradient/background/vibrant/euro-end': rebrandColors['base/pink/450'],
  'gradient/background/vibrant/euro-start': rebrandColors['base/red/400'],
  'gradient/background/vibrant/lincoln-end': rebrandColors['base/lime/400'],
  'gradient/background/vibrant/lincoln-start': rebrandColors['base/minted/450'],
  'gradient/background/vibrant/viola-end': rebrandColors['base/purple/450'],
  'gradient/background/vibrant/viola-start': rebrandColors['base/pink/400'],
  'gradient/graphic/stark/benjamin-end': rebrandColors['base/minted/600'],
  'gradient/graphic/stark/benjamin-start': rebrandColors['base/sky/600'],
  'gradient/graphic/stark/elizabeth-end': rebrandColors['base/blue/600'],
  'gradient/graphic/stark/elizabeth-start': rebrandColors['base/purple/600'],
  'gradient/graphic/stark/euro-end': rebrandColors['base/red/600'],
  'gradient/graphic/stark/euro-start': rebrandColors['base/pink/600'],
  'gradient/graphic/stark/lincoln-end': rebrandColors['base/lime/600'],
  'gradient/graphic/stark/lincoln-start': rebrandColors['base/minted/600'],
  'gradient/graphic/stark/viola-end': rebrandColors['base/purple/600'],
  'gradient/graphic/stark/viola-start': rebrandColors['base/sky/600'],
  'gradient/graphic/subtle/benjamin-end': rebrandColors['base/minted/500'],
  'gradient/graphic/subtle/benjamin-start': rebrandColors['base/sky/500'],
  'gradient/graphic/subtle/elizabeth-end': rebrandColors['base/blue/500'],
  'gradient/graphic/subtle/elizabeth-start': rebrandColors['base/purple/500'],
  'gradient/graphic/subtle/euro-end': rebrandColors['base/red/500'],
  'gradient/graphic/subtle/euro-start': rebrandColors['base/pink/500'],
  'gradient/graphic/subtle/lincoln-end': rebrandColors['base/lime/500'],
  'gradient/graphic/subtle/lincoln-start': rebrandColors['base/minted/500'],
  'gradient/graphic/subtle/viola-end': rebrandColors['base/purple/500'],
  'gradient/graphic/subtle/viola-start': rebrandColors['base/sky/500'],
  'graphic/contrast-high': rebrandColors['base/black/700'],
  'graphic/contrast-highest': rebrandColors['base/black/900'],
  'graphic/contrast-low': rebrandColors['base/black/200'],
  'graphic/contrast-lowest': rebrandColors['base/black/100'],
  'graphic/contrast-medium': rebrandColors['base/black/300'],
  'graphic/elevation-1': rebrandColors['base/white'],
  'graphic/elevation-semitransparent': 'rgba(255, 255, 255, 0.64)',
  'graphic/interactive/disabled/high': rebrandColors['base/black/500'],
  'graphic/interactive/disabled/low': rebrandColors['base/black/300'],
  'graphic/interactive/negative/hovered/high': rebrandColors['base/red/700'],
  'graphic/interactive/negative/hovered/low': 'rgba(206, 59, 59, 0.08)',
  'graphic/interactive/negative/pressed/high': rebrandColors['base/red/700'],
  'graphic/interactive/negative/pressed/low': 'rgba(206, 59, 59, 0.16)',
  'graphic/interactive/negative/resting/high': rebrandColors['base/red/700'],
  'graphic/interactive/negative/resting/low': rebrandColors['base/red/600'],
  'graphic/interactive/neutral/focused/high': rebrandColors['base/black/900'],
  'graphic/interactive/neutral/hovered/contrast-high': 'rgba(17, 17, 18, 0.84)',
  'graphic/interactive/neutral/hovered/contrast-low':
    'rgba(116, 118, 119, 0.08)',
  'graphic/interactive/neutral/hovered/contrast-medium':
    rebrandColors['base/black/600'],
  'graphic/interactive/neutral/pressed/contrast-high': 'rgba(17, 17, 18, 0.84)',
  'graphic/interactive/neutral/pressed/contrast-low':
    'rgba(116, 118, 119, 0.16)',
  'graphic/interactive/neutral/resting/contrast-low':
    rebrandColors['base/black/300'],
  'graphic/interactive/neutral/resting/contrast-medium':
    rebrandColors['base/black/600'],
  'graphic/interactive/neutral/resting/high': rebrandColors['base/black/1000'],
  'graphic/interactive/prominent/hovered/contrast-high':
    rebrandColors['base/blue/700'],
  'graphic/interactive/prominent/hovered/contrast-low':
    'rgba(21, 110, 245, 0.08)',
  'graphic/interactive/prominent/pressed/contrast-high':
    rebrandColors['base/blue/800'],
  'graphic/interactive/prominent/pressed/contrast-low':
    'rgba(21, 110, 245, 0.16)',
  'graphic/interactive/prominent/resting/contrast-high':
    rebrandColors['base/blue/700'],
  'graphic/interactive/prominent/resting/contrast-low':
    rebrandColors['base/blue/600'],
  'graphic/inverse': rebrandColors['base/white'],
  'graphic/stark/blue': rebrandColors['base/blue/400'],
  'graphic/stark/green': rebrandColors['base/green/400'],
  'graphic/stark/lime': rebrandColors['base/lime/400'],
  'graphic/stark/minted': rebrandColors['base/minted/400'],
  'graphic/stark/pink': rebrandColors['base/pink/400'],
  'graphic/stark/purple': rebrandColors['base/purple/400'],
  'graphic/stark/red': rebrandColors['base/red/400'],
  'graphic/stark/sky': rebrandColors['base/sky/400'],
  'graphic/stark/yellow': rebrandColors['base/yellow/400'],
  'graphic/status-info': rebrandColors['base/purple/600'],
  'graphic/status-negative': rebrandColors['base/red/600'],
  'graphic/status-neutral': rebrandColors['base/black/600'],
  'graphic/status-positive': rebrandColors['base/green/600'],
  'graphic/status-prominent': rebrandColors['base/blue/600'],
  'graphic/status-warning': rebrandColors['base/yellow/600'],
  'graphic/subtle/blue': rebrandColors['base/blue/300'],
  'graphic/subtle/green': rebrandColors['base/green/300'],
  'graphic/subtle/lime': rebrandColors['base/lime/300'],
  'graphic/subtle/magenta': rebrandColors['base/pink/300'],
  'graphic/subtle/minted': rebrandColors['base/minted/300'],
  'graphic/subtle/purple': rebrandColors['base/purple/300'],
  'graphic/subtle/red': rebrandColors['base/red/300'],
  'graphic/subtle/sky': rebrandColors['base/sky/300'],
  'graphic/subtle/yellow': rebrandColors['base/yellow/300'],
  'text/contrast-high': rebrandColors['base/black/900'],
  'text/contrast-low': rebrandColors['base/black/700'],
  'text/contrast-max': rebrandColors['base/black/1000'],
  'text/contrast-medium': rebrandColors['base/black/800'],
  'text/contrast-min': rebrandColors['base/black/600'],
  'text/interactive/disabled': rebrandColors['base/black/500'],
  'text/interactive/neutral/hovered': 'rgba(17, 17, 18, 0.84)',
  'text/interactive/neutral/pressed': 'rgba(17, 17, 18, 0.84)',
  'text/interactive/neutral/resting-contrast-high':
    rebrandColors['base/black/1000'],
  'text/interactive/neutral/resting-contrast-low':
    rebrandColors['base/black/600'],
  'text/interactive/prominent/hovered': rebrandColors['base/blue/800'],
  'text/interactive/prominent/pressed': rebrandColors['base/blue/800'],
  'text/interactive/prominent/resting': rebrandColors['base/blue/700'],
  'text/inverse': rebrandColors['base/white'],
  'text/status-info': rebrandColors['base/purple/700'],
  'text/status-negative': rebrandColors['base/red/700'],
  'text/status-neutral': rebrandColors['base/black/700'],
  'text/status-positive': rebrandColors['base/green/700'],
  'text/status-prominent': rebrandColors['base/blue/700'],
  'text/status-warning': rebrandColors['base/yellow/700'],
};

const rebrandDark = {
  'background/contrast-high': rebrandColors['base/black/1000'],
  'background/contrast-low': rebrandColors['base/black/800'],
  'background/contrast-medium': rebrandColors['base/black/900'],
  'background/elevation-0': rebrandColors['base/black/1000'],
  'background/elevation-1': 'rgba(36, 36, 37, 1.00)',
  'background/elevation-2': 'rgba(46, 46, 46, 1.00)',
  'background/elevation-3': 'rgba(55, 55, 56, 1.00)',
  'background/elevation-4': 'rgba(65, 65, 65, 1.00)',
  'background/interactive/disabled/contrast-high':
    rebrandColors['base/black/600'],
  'background/interactive/disabled/contrast-low':
    rebrandColors['base/black/800'],
  'background/interactive/negative/hovered/contrast-high':
    rebrandColors['base/red/400'],
  'background/interactive/negative/hovered/contrast-low':
    'rgba(255, 153, 153, 0.12)',
  'background/interactive/negative/pressed/contrast-high':
    rebrandColors['base/red/400'],
  'background/interactive/negative/pressed/contrast-low':
    'rgba(255, 153, 153, 0.24)',
  'background/interactive/negative/resting/contrast-high':
    rebrandColors['base/red/400'],
  'background/interactive/negative/resting/contrast-low':
    rebrandColors['base/red/500'],
  'background/interactive/neutral/hovered/contrast-high':
    'rgba(255, 255, 255, 0.84)',
  'background/interactive/neutral/hovered/contrast-low':
    'rgba(146, 149, 150, 0.12)',
  'background/interactive/neutral/pressed/contrast-high':
    'rgba(255, 255, 255, 0.84)',
  'background/interactive/neutral/pressed/contrast-low':
    'rgba(146, 149, 150, 0.24)',
  'background/interactive/neutral/resting/contrast-high':
    rebrandColors['base/white'],
  'background/interactive/neutral/resting/contrast-low':
    rebrandColors['base/black/800'],
  'background/interactive/neutral/resting/contrast-medium':
    rebrandColors['base/black/500'],
  'background/interactive/prominent/hovered/contrast-high':
    rebrandColors['base/blue/400'],
  'background/interactive/prominent/hovered/contrast-low':
    'rgba(176, 206, 252, 0.12)',
  'background/interactive/prominent/pressed/contrast-high':
    rebrandColors['base/blue/400'],
  'background/interactive/prominent/pressed/contrast-low':
    'rgba(176, 206, 252, 0.24)',
  'background/interactive/prominent/resting/contrast-high':
    rebrandColors['base/blue/400'],
  'background/interactive/prominent/resting/contrast-low':
    rebrandColors['base/blue/500'],
  'background/inverse': rebrandColors['base/white'],
  'background/overlay-1': 'rgba(17, 17, 18, 0.80)',
  'background/status-info': rebrandColors['base/purple/800'],
  'background/status-negative': rebrandColors['base/red/800'],
  'background/status-neutral': rebrandColors['base/black/800'],
  'background/status-positive': rebrandColors['base/green/800'],
  'background/status-prominent': rebrandColors['base/blue/800'],
  'background/status-warning': rebrandColors['base/yellow/800'],
  'gradient/background/stark/benjamin-end': rebrandColors['base/lime/800'],
  'gradient/background/stark/benjamin-mid': rebrandColors['base/minted/800'],
  'gradient/background/stark/benjamin-start': rebrandColors['base/sky/800'],
  'gradient/background/stark/elizabeth-end': rebrandColors['base/sky/800'],
  'gradient/background/stark/elizabeth-mid': rebrandColors['base/blue/800'],
  'gradient/background/stark/elizabeth-start': rebrandColors['base/purple/800'],
  'gradient/background/stark/euro-end': rebrandColors['base/yellow/800'],
  'gradient/background/stark/euro-mid': rebrandColors['base/pink/800'],
  'gradient/background/stark/euro-start': rebrandColors['base/purple/800'],
  'gradient/background/stark/lincoln-end': rebrandColors['base/yellow/800'],
  'gradient/background/stark/lincoln-mid': rebrandColors['base/lime/800'],
  'gradient/background/stark/lincoln-start': rebrandColors['base/minted/800'],
  'gradient/background/stark/viola-end': rebrandColors['base/sky/800'],
  'gradient/background/stark/viola-mid': rebrandColors['base/purple/800'],
  'gradient/background/stark/viola-start': rebrandColors['base/pink/800'],
  'gradient/background/subtle/benjamin-end': rebrandColors['base/lime/900'],
  'gradient/background/subtle/benjamin-mid': rebrandColors['base/minted/900'],
  'gradient/background/subtle/benjamin-start': rebrandColors['base/sky/900'],
  'gradient/background/subtle/elizabeth-end': rebrandColors['base/sky/900'],
  'gradient/background/subtle/elizabeth-mid': rebrandColors['base/blue/900'],
  'gradient/background/subtle/elizabeth-start':
    rebrandColors['base/purple/900'],
  'gradient/background/subtle/euro-end': rebrandColors['base/yellow/900'],
  'gradient/background/subtle/euro-mid': rebrandColors['base/pink/900'],
  'gradient/background/subtle/euro-start': rebrandColors['base/purple/900'],
  'gradient/background/subtle/lincoln-end': rebrandColors['base/yellow/900'],
  'gradient/background/subtle/lincoln-mid': rebrandColors['base/lime/900'],
  'gradient/background/subtle/lincoln-start': rebrandColors['base/minted/900'],
  'gradient/background/subtle/viola-end': rebrandColors['base/sky/900'],
  'gradient/background/subtle/viola-mid': rebrandColors['base/purple/900'],
  'gradient/background/subtle/viola-start': rebrandColors['base/pink/900'],
  'gradient/background/vibrant/benjamin-end': rebrandColors['base/sky/700'],
  'gradient/background/vibrant/benjamin-start':
    rebrandColors['base/minted/600'],
  'gradient/background/vibrant/elizabeth-end': rebrandColors['base/purple/700'],
  'gradient/background/vibrant/elizabeth-start': rebrandColors['base/sky/600'],
  'gradient/background/vibrant/euro-end': rebrandColors['base/pink/700'],
  'gradient/background/vibrant/euro-start': rebrandColors['base/red/600'],
  'gradient/background/vibrant/lincoln-end': rebrandColors['base/lime/600'],
  'gradient/background/vibrant/lincoln-start': rebrandColors['base/minted/700'],
  'gradient/background/vibrant/viola-end': rebrandColors['base/purple/700'],
  'gradient/background/vibrant/viola-start': rebrandColors['base/pink/600'],
  'gradient/graphic/stark/benjamin-end': rebrandColors['base/minted/400'],
  'gradient/graphic/stark/benjamin-start': rebrandColors['base/sky/400'],
  'gradient/graphic/stark/elizabeth-end': rebrandColors['base/blue/400'],
  'gradient/graphic/stark/elizabeth-start': rebrandColors['base/purple/400'],
  'gradient/graphic/stark/euro-end': rebrandColors['base/red/400'],
  'gradient/graphic/stark/euro-start': rebrandColors['base/pink/400'],
  'gradient/graphic/stark/lincoln-end': rebrandColors['base/lime/400'],
  'gradient/graphic/stark/lincoln-start': rebrandColors['base/minted/400'],
  'gradient/graphic/stark/viola-end': rebrandColors['base/purple/400'],
  'gradient/graphic/stark/viola-start': rebrandColors['base/sky/400'],
  'gradient/graphic/subtle/benjamin-end': rebrandColors['base/minted/450'],
  'gradient/graphic/subtle/benjamin-start': rebrandColors['base/sky/450'],
  'gradient/graphic/subtle/elizabeth-end': rebrandColors['base/blue/500'],
  'gradient/graphic/subtle/elizabeth-start': rebrandColors['base/purple/450'],
  'gradient/graphic/subtle/euro-end': rebrandColors['base/red/450'],
  'gradient/graphic/subtle/euro-start': rebrandColors['base/pink/450'],
  'gradient/graphic/subtle/lincoln-end': rebrandColors['base/lime/450'],
  'gradient/graphic/subtle/lincoln-start': rebrandColors['base/minted/450'],
  'gradient/graphic/subtle/viola-end': rebrandColors['base/purple/450'],
  'gradient/graphic/subtle/viola-start': rebrandColors['base/sky/450'],
  'graphic/contrast-high': rebrandColors['base/black/500'],
  'graphic/contrast-highest': rebrandColors['base/black/100'],
  'graphic/contrast-low': rebrandColors['base/black/800'],
  'graphic/contrast-lowest': rebrandColors['base/black/900'],
  'graphic/contrast-medium': rebrandColors['base/black/700'],
  'graphic/elevation-1': rebrandColors['base/black/900'],
  'graphic/elevation-semitransparent': 'rgba(255, 255, 255, 0.12)',
  'graphic/interactive/disabled/high': rebrandColors['base/black/600'],
  'graphic/interactive/disabled/low': rebrandColors['base/black/400'],
  'graphic/interactive/negative/hovered/high': rebrandColors['base/red/400'],
  'graphic/interactive/negative/hovered/low': 'rgba(255, 153, 153, 0.12)',
  'graphic/interactive/negative/pressed/high': rebrandColors['base/red/400'],
  'graphic/interactive/negative/pressed/low': 'rgba(255, 153, 153, 0.24)',
  'graphic/interactive/negative/resting/high': rebrandColors['base/red/400'],
  'graphic/interactive/negative/resting/low': rebrandColors['base/red/500'],
  'graphic/interactive/neutral/focused/high': rebrandColors['base/black/100'],
  'graphic/interactive/neutral/hovered/contrast-high':
    'rgba(255, 255, 255, 0.84)',
  'graphic/interactive/neutral/hovered/contrast-low':
    'rgba(146, 149, 150, 0.12)',
  'graphic/interactive/neutral/hovered/contrast-medium':
    rebrandColors['base/black/500'],
  'graphic/interactive/neutral/pressed/contrast-high':
    'rgba(255, 255, 255, 0.84)',
  'graphic/interactive/neutral/pressed/contrast-low':
    'rgba(146, 149, 150, 0.24)',
  'graphic/interactive/neutral/resting/contrast-low':
    rebrandColors['base/black/800'],
  'graphic/interactive/neutral/resting/contrast-medium':
    rebrandColors['base/black/500'],
  'graphic/interactive/neutral/resting/high': rebrandColors['base/white'],
  'graphic/interactive/prominent/hovered/contrast-high':
    rebrandColors['base/blue/400'],
  'graphic/interactive/prominent/hovered/contrast-low':
    'rgba(176, 206, 252, 0.12)',
  'graphic/interactive/prominent/pressed/contrast-high':
    rebrandColors['base/blue/400'],
  'graphic/interactive/prominent/pressed/contrast-low':
    'rgba(176, 206, 252, 0.24)',
  'graphic/interactive/prominent/resting/contrast-high':
    rebrandColors['base/blue/400'],
  'graphic/interactive/prominent/resting/contrast-low':
    rebrandColors['base/blue/500'],
  'graphic/inverse': rebrandColors['base/black/1000'],
  'graphic/stark/blue': rebrandColors['base/blue/600'],
  'graphic/stark/green': rebrandColors['base/green/600'],
  'graphic/stark/lime': rebrandColors['base/lime/600'],
  'graphic/stark/minted': rebrandColors['base/minted/600'],
  'graphic/stark/pink': rebrandColors['base/pink/600'],
  'graphic/stark/purple': rebrandColors['base/purple/600'],
  'graphic/stark/red': rebrandColors['base/red/600'],
  'graphic/stark/sky': rebrandColors['base/sky/600'],
  'graphic/stark/yellow': rebrandColors['base/yellow/600'],
  'graphic/status-info': rebrandColors['base/purple/500'],
  'graphic/status-negative': rebrandColors['base/red/500'],
  'graphic/status-neutral': rebrandColors['base/black/500'],
  'graphic/status-positive': rebrandColors['base/green/500'],
  'graphic/status-prominent': rebrandColors['base/blue/500'],
  'graphic/status-warning': rebrandColors['base/yellow/500'],
  'graphic/subtle/blue': rebrandColors['base/blue/700'],
  'graphic/subtle/green': rebrandColors['base/green/700'],
  'graphic/subtle/lime': rebrandColors['base/lime/700'],
  'graphic/subtle/magenta': rebrandColors['base/pink/700'],
  'graphic/subtle/minted': rebrandColors['base/minted/700'],
  'graphic/subtle/purple': rebrandColors['base/purple/700'],
  'graphic/subtle/red': rebrandColors['base/red/700'],
  'graphic/subtle/sky': rebrandColors['base/sky/700'],
  'graphic/subtle/yellow': rebrandColors['base/yellow/700'],
  'text/contrast-high': rebrandColors['base/black/200'],
  'text/contrast-low': rebrandColors['base/black/400'],
  'text/contrast-max': rebrandColors['base/white'],
  'text/contrast-medium': rebrandColors['base/black/300'],
  'text/contrast-min': rebrandColors['base/black/500'],
  'text/interactive/disabled': rebrandColors['base/black/600'],
  'text/interactive/neutral/hovered': 'rgba(255, 255, 255, 0.84)',
  'text/interactive/neutral/pressed': 'rgba(255, 255, 255, 0.84)',
  'text/interactive/neutral/resting-contrast-high': rebrandColors['base/white'],
  'text/interactive/neutral/resting-contrast-low':
    rebrandColors['base/black/500'],
  'text/interactive/prominent/hovered': rebrandColors['base/blue/400'],
  'text/interactive/prominent/pressed': rebrandColors['base/blue/400'],
  'text/interactive/prominent/resting': rebrandColors['base/blue/500'],
  'text/inverse': rebrandColors['base/black/1000'],
  'text/status-info': rebrandColors['base/purple/400'],
  'text/status-negative': rebrandColors['base/red/400'],
  'text/status-neutral': rebrandColors['base/black/400'],
  'text/status-positive': rebrandColors['base/green/400'],
  'text/status-prominent': rebrandColors['base/blue/400'],
  'text/status-warning': rebrandColors['base/yellow/400'],
};

export const gradientsRebrandLight = {
  'gradient/graphic/stark/benjamin': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/stark/benjamin-start']} 0%, ${rebrandLight['gradient/graphic/stark/benjamin-end']} 100%)`,
  'gradient/graphic/stark/elizabeth': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/stark/elizabeth-start']} 0%, ${rebrandLight['gradient/graphic/stark/elizabeth-end']} 100%)`,
  'gradient/graphic/stark/euro': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/stark/euro-start']} 0%, ${rebrandLight['gradient/graphic/stark/euro-end']} 100%)`,
  'gradient/graphic/stark/lincoln': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/stark/lincoln-start']} 0%, ${rebrandLight['gradient/graphic/stark/lincoln-end']} 100%)`,
  'gradient/graphic/stark/viola': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/stark/viola-start']} 0%, ${rebrandLight['gradient/graphic/stark/viola-end']} 100%)`,
  'gradient/graphic/subtle/benjamin': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/subtle/benjamin-start']} 0%, ${rebrandLight['gradient/graphic/subtle/benjamin-end']} 100%)`,
  'gradient/graphic/subtle/elizabeth': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/subtle/elizabeth-start']} 0%, ${rebrandLight['gradient/graphic/subtle/elizabeth-end']} 100%)`,
  'gradient/graphic/subtle/euro': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/subtle/euro-start']} 0%, ${rebrandLight['gradient/graphic/subtle/euro-end']} 100%)`,
  'gradient/graphic/subtle/lincoln': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/subtle/lincoln-start']} 0%, ${rebrandLight['gradient/graphic/subtle/lincoln-end']} 100%)`,
  'gradient/graphic/subtle/viola': `linear-gradient(to bottom left, ${rebrandLight['gradient/graphic/subtle/viola-start']} 0%, ${rebrandLight['gradient/graphic/subtle/viola-end']} 100%)`,
  'gradient/background/stark/benjamin': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/stark/benjamin-start']} 0%, ${rebrandLight['gradient/background/stark/benjamin-mid']} 50%, ${rebrandLight['gradient/background/stark/benjamin-end']} 100%)`,
  'gradient/background/stark/elizabeth': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/stark/elizabeth-start']} 0%, ${rebrandLight['gradient/background/stark/elizabeth-mid']} 50%, ${rebrandLight['gradient/background/stark/elizabeth-end']} 100%)`,
  'gradient/background/stark/euro': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/stark/euro-start']} 0%, ${rebrandLight['gradient/background/stark/euro-mid']} 50%, ${rebrandLight['gradient/background/stark/euro-end']} 100%)`,
  'gradient/background/stark/lincoln': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/stark/lincoln-start']} 0%, ${rebrandLight['gradient/background/stark/lincoln-mid']} 50%, ${rebrandLight['gradient/background/stark/lincoln-end']} 100%)`,
  'gradient/background/stark/viola': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/stark/viola-start']} 0%, ${rebrandLight['gradient/background/stark/viola-mid']} 50%, ${rebrandLight['gradient/background/stark/viola-end']} 100%)`,
  'gradient/background/subtle/benjamin': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/subtle/benjamin-start']} 0%, ${rebrandLight['gradient/background/subtle/benjamin-mid']} 50%, ${rebrandLight['gradient/background/subtle/benjamin-end']} 100%)`,
  'gradient/background/subtle/elizabeth': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/subtle/elizabeth-start']} 0%, ${rebrandLight['gradient/background/subtle/elizabeth-mid']} 50%, ${rebrandLight['gradient/background/subtle/elizabeth-end']} 100%)`,
  'gradient/background/subtle/euro': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/subtle/euro-start']} 0%, ${rebrandLight['gradient/background/subtle/euro-mid']} 50%, ${rebrandLight['gradient/background/subtle/euro-end']} 100%)`,
  'gradient/background/subtle/lincoln': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/subtle/lincoln-start']} 0%, ${rebrandLight['gradient/background/subtle/lincoln-mid']} 50%, ${rebrandLight['gradient/background/subtle/lincoln-end']} 100%)`,
  'gradient/background/subtle/viola': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/subtle/viola-start']} 0%, ${rebrandLight['gradient/background/subtle/viola-mid']} 50%, ${rebrandLight['gradient/background/subtle/viola-end']} 100%)`,
  'gradient/background/vibrant/benjamin': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/vibrant/benjamin-start']} 0%, ${rebrandLight['gradient/background/vibrant/benjamin-end']} 100%)`,
  'gradient/background/vibrant/elizabeth': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/vibrant/elizabeth-start']} 0%, ${rebrandLight['gradient/background/vibrant/elizabeth-end']} 100%)`,
  'gradient/background/vibrant/euro': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/vibrant/euro-start']} 0%, ${rebrandLight['gradient/background/vibrant/euro-end']} 100%)`,
  'gradient/background/vibrant/lincoln': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/vibrant/lincoln-start']} 0%, ${rebrandLight['gradient/background/vibrant/lincoln-end']} 100%)`,
  'gradient/background/vibrant/viola': `linear-gradient(to bottom left, ${rebrandLight['gradient/background/vibrant/viola-start']} 0%, ${rebrandLight['gradient/background/vibrant/viola-end']} 100%)`,
};

export const gradientsRebrandDark = {
  'gradient/graphic/stark/benjamin': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/stark/benjamin-start']} 0%, ${rebrandDark['gradient/graphic/stark/benjamin-end']} 100%)`,
  'gradient/graphic/stark/elizabeth': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/stark/elizabeth-start']} 0%, ${rebrandDark['gradient/graphic/stark/elizabeth-end']} 100%)`,
  'gradient/graphic/stark/euro': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/stark/euro-start']} 0%, ${rebrandDark['gradient/graphic/stark/euro-end']} 100%)`,
  'gradient/graphic/stark/lincoln': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/stark/lincoln-start']} 0%, ${rebrandDark['gradient/graphic/stark/lincoln-end']} 100%)`,
  'gradient/graphic/stark/viola': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/stark/viola-start']} 0%, ${rebrandDark['gradient/graphic/stark/viola-end']} 100%)`,
  'gradient/graphic/subtle/benjamin': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/subtle/benjamin-start']} 0%, ${rebrandDark['gradient/graphic/subtle/benjamin-end']} 100%)`,
  'gradient/graphic/subtle/elizabeth': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/subtle/elizabeth-start']} 0%, ${rebrandDark['gradient/graphic/subtle/elizabeth-end']} 100%)`,
  'gradient/graphic/subtle/euro': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/subtle/euro-start']} 0%, ${rebrandDark['gradient/graphic/subtle/euro-end']} 100%)`,
  'gradient/graphic/subtle/lincoln': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/subtle/lincoln-start']} 0%, ${rebrandDark['gradient/graphic/subtle/lincoln-end']} 100%)`,
  'gradient/graphic/subtle/viola': `linear-gradient(to bottom left, ${rebrandDark['gradient/graphic/subtle/viola-start']} 0%, ${rebrandDark['gradient/graphic/subtle/viola-end']} 100%)`,
  'gradient/background/stark/benjamin': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/stark/benjamin-start']} 0%, ${rebrandDark['gradient/background/stark/benjamin-mid']} 50%, ${rebrandDark['gradient/background/stark/benjamin-end']} 100%)`,
  'gradient/background/stark/elizabeth': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/stark/elizabeth-start']} 0%, ${rebrandDark['gradient/background/stark/elizabeth-mid']} 50%, ${rebrandDark['gradient/background/stark/elizabeth-end']} 100%)`,
  'gradient/background/stark/euro': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/stark/euro-start']} 0%, ${rebrandDark['gradient/background/stark/euro-mid']} 50%, ${rebrandDark['gradient/background/stark/euro-end']} 100%)`,
  'gradient/background/stark/lincoln': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/stark/lincoln-start']} 0%, ${rebrandDark['gradient/background/stark/lincoln-mid']} 50%, ${rebrandDark['gradient/background/stark/lincoln-end']} 100%)`,
  'gradient/background/stark/viola': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/stark/viola-start']} 0%, ${rebrandDark['gradient/background/stark/viola-mid']} 50%, ${rebrandDark['gradient/background/stark/viola-end']} 100%)`,
  'gradient/background/subtle/benjamin': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/subtle/benjamin-start']} 0%, ${rebrandDark['gradient/background/subtle/benjamin-mid']} 50%, ${rebrandDark['gradient/background/subtle/benjamin-end']} 100%)`,
  'gradient/background/subtle/elizabeth': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/subtle/elizabeth-start']} 0%, ${rebrandDark['gradient/background/subtle/elizabeth-mid']} 50%, ${rebrandDark['gradient/background/subtle/elizabeth-end']} 100%)`,
  'gradient/background/subtle/euro': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/subtle/euro-start']} 0%, ${rebrandDark['gradient/background/subtle/euro-mid']} 50%, ${rebrandDark['gradient/background/subtle/euro-end']} 100%)`,
  'gradient/background/subtle/lincoln': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/subtle/lincoln-start']} 0%, ${rebrandDark['gradient/background/subtle/lincoln-mid']} 50%, ${rebrandDark['gradient/background/subtle/lincoln-end']} 100%)`,
  'gradient/background/subtle/viola': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/subtle/viola-start']} 0%, ${rebrandDark['gradient/background/subtle/viola-mid']} 50%, ${rebrandDark['gradient/background/subtle/viola-end']} 100%)`,
  'gradient/background/vibrant/benjamin': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/vibrant/benjamin-start']} 0%, ${rebrandDark['gradient/background/vibrant/benjamin-end']} 100%)`,
  'gradient/background/vibrant/elizabeth': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/vibrant/elizabeth-start']} 0%, ${rebrandDark['gradient/background/vibrant/elizabeth-end']} 100%)`,
  'gradient/background/vibrant/euro': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/vibrant/euro-start']} 0%, ${rebrandDark['gradient/background/vibrant/euro-end']} 100%)`,
  'gradient/background/vibrant/lincoln': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/vibrant/lincoln-start']} 0%, ${rebrandDark['gradient/background/vibrant/lincoln-end']} 100%)`,
  'gradient/background/vibrant/viola': `linear-gradient(to bottom left, ${rebrandDark['gradient/background/vibrant/viola-start']} 0%, ${rebrandDark['gradient/background/vibrant/viola-end']} 100%)`,
};

export const componentsLight = {
  // NOTE: these tokens are unstable and are subject to change when/if design surprises us with something *special*
  'web2/text/eyebrow': legacyDotComColors.black700, // TODO: this should eventually use ...black/700
  'web3/text/eyebrow': rebrandColors['base/sky/1000'],
  'web3/text/heading': rebrandColors['base/sky/1000'],
  'web2/text/paragraph': legacyDotComColors.black800, // TODO: this should eventually use ...black/800
  'web3/text/paragraph': rebrandColors['base/sky/1000'],
  'web3/text/contrast-max': rebrandColors['base/sky/1000'],
  'web3/component/button/secondary/border-resting':
    rebrandColors['base/pink/800'],
  'web3/component/button/secondary/box-shadow': `0px 0px 5px 1px ${rebrandColors['base/purple/500']}`,
  'web3/component/button/tertiary/gradient/background-resting': `linear-gradient(48deg, #8358EF 0%, ${rebrandColors['base/blue/600']} 100%)`,
  'web3/component/button/tertiary/gradient/background-interacted': `linear-gradient(48deg, #00819A 0%, ${rebrandColors['base/minted/600']} 100%)`,
  'web3/component/button/tertiary/box-shadow': `0px 0px 5px 0px ${rebrandColors['base/minted/450']}`,
  'web3/component/button/tertiary/graphic-resting':
    rebrandColors['base/sky/200'],
  'web3/component/button/tertiary/graphic-interacted':
    rebrandColors['base/white'],
  'web3/component/button/text/color': rebrandColors['base/white'],
  'web3/component/button/primary/background-resting': `linear-gradient(77deg, ${rebrandColors['base/black/1000']} 0%, ${rebrandColors['base/black/1000']} 100%)`,
  'web3/component/button/primary/background-hovered': `linear-gradient(77deg, ${rebrandColors['base/black/1000']} 0%, ${rebrandColors['base/black/800']} 100%)`,
  'web3/component/icon-button/border-disabled': rebrandColors['base/black/600'],
  'web3/component/icon-button/background-resting': `${gradientsRebrandLight['gradient/background/stark/elizabeth']} padding-box, linear-gradient(to right, #8358EF 0%, #156EF5 100%) border-box`,
  'web3/component/icon-button/background-interacted': `${gradientsRebrandLight['gradient/background/subtle/elizabeth']} padding-box, linear-gradient(to right, #8358EF 0%, #156EF5 100%) border-box`,
  'web3/component/icon-button/box-shadow': `0px 0px 14px #8358EF`,
  'web3/component/icon-button/svg-fill': `#00819A`,
  'web3/component/shadow': `radial-gradient(at 40% 20%, ${rebrandColors['base/lime/450']} 0px, transparent 50%), radial-gradient(at 80% 0%, ${rebrandColors['base/minted/450']} 0px, transparent 50%), radial-gradient(at 0% 50%, ${rebrandColors['base/sky/450']} 0px, transparent 50%), radial-gradient(at 80% 50%, ${rebrandColors['base/purple/450']} 0px, transparent 50%), radial-gradient(at 0% 100%, ${rebrandColors['base/pink/450']} 0px, transparent 50%), radial-gradient(at 80% 100%, ${rebrandColors['base/red/450']} 0px, transparent 50%), radial-gradient(at 0% 0%, ${rebrandColors['base/yellow/450']} 0px, transparent 50%)`,
};

export const componentsDark = {
  // NOTE: these tokens are unstable and are subject to change when/if design surprises us with something *special*
  'web2/text/eyebrow': legacyDotComColors.black400, // TODO: this should eventually use ...black/400
  'web3/text/eyebrow': rebrandColors['base/white'],
  'web3/text/heading': rebrandColors['base/white'],
  'web2/text/paragraph': legacyDotComColors.black300, // TODO: this should eventually use ...black/300
  'web3/text/paragraph': rebrandColors['base/white'],
  'web3/text/contrast-max': rebrandColors['base/white'],
  'web3/component/button/secondary/border-resting':
    rebrandColors['base/pink/200'],
  'web3/component/button/secondary/box-shadow': `0px 0px 5px 1px ${rebrandColors['base/purple/500']}`,
  'web3/component/button/tertiary/gradient/background-resting': `linear-gradient(33deg, ${rebrandColors['base/minted/400']} 0%, ${rebrandColors['base/sky/400']} 100%)`,
  'web3/component/button/tertiary/gradient/background-interacted': `linear-gradient(33deg, #56DDF7 0%, ${rebrandColors['base/purple/450']} 100%)`,
  'web3/component/button/tertiary/box-shadow': `0px 0px 5px 0px ${rebrandColors['base/purple/500']}`,
  'web3/component/button/tertiary/graphic-resting':
    rebrandColors['base/sky/800'],
  'web3/component/button/tertiary/graphic-interacted':
    rebrandColors['base/black/1000'],
  'web3/component/button/primary/background-resting': `linear-gradient(77deg, ${rebrandColors['base/white']} 0%, ${rebrandColors['base/white']} 100%)`,
  'web3/component/button/primary/background-hovered': `linear-gradient(77deg, ${rebrandColors['base/white']} 0%, ${rebrandColors['base/black/200']} 100%)`,
  'web3/component/button/text/color': rebrandColors['base/black/1000'],
  'web3/component/icon-button/border-disabled': rebrandColors['base/black/400'],
  'web3/component/icon-button/background-resting': `linear-gradient(${rebrandColors['base/sky/800']}, ${rebrandColors['base/sky/800']}) padding-box, linear-gradient(to right, #42F0CE 0%, #10B1F3 100%) border-box`,
  'web3/component/icon-button/background-interacted': `linear-gradient(48deg, #00819A 0%, #088181 100%) padding-box, linear-gradient(to right, #CDF5FD 0%, #E8FED7 100%) border-box`,
  'web3/component/icon-button/box-shadow': `0px 0px 14px #10D1B7`,
  'web3/component/icon-button/svg-fill': `#CDF5FD`,
  'web3/component/shadow': `radial-gradient(at 40% 20%, ${rebrandColors['base/lime/450']} 0px, transparent 50%), radial-gradient(at 80% 0%, ${rebrandColors['base/minted/450']} 0px, transparent 50%), radial-gradient(at 0% 50%, ${rebrandColors['base/sky/450']} 0px, transparent 50%), radial-gradient(at 80% 50%, ${rebrandColors['base/purple/450']} 0px, transparent 50%), radial-gradient(at 0% 100%, ${rebrandColors['base/pink/450']} 0px, transparent 50%), radial-gradient(at 80% 100%, ${rebrandColors['base/red/450']} 0px, transparent 50%), radial-gradient(at 0% 0%, ${rebrandColors['base/yellow/450']} 0px, transparent 50%)`,
};

export const colors = {
  ...legacyDotComColors,
  ...Threads5Theme.palette,
  ...rebrandColors, // TODO: test that TS isn't mad about this otherwise it needs to be committed to threads5 first
  ...rebrandLight,
  ...gradientsRebrandLight,
  ...componentsLight,
};

// TODO: need to type all these new tokens :sweatin:
export const getDesignTokens = (mode: string) => {
  return {
    ...Threads5Theme,
    palette: {
      ...colors,
      ...(mode === 'light' ? gradientsRebrandLight : gradientsRebrandDark),
      ...(mode === 'light' ? rebrandLight : rebrandDark),
      ...(mode === 'light' ? componentsLight : componentsDark),
      mode: mode,
    },
    typography: {
      ...Threads5Theme.typography,
    },
    breakpoints: {
      values: {
        ...breakpoints,
      },
    },
  };
};

// Threads Fonts
export const fontFamily = {
  sans: 'Cern, Helvetica, Arial, sans-serif',
  mono: 'Inconsolata, Consolas, Courier, monospace',
};

const theme = createTheme({
  ...Threads5Theme,
  palette: {
    ...colors,
  },
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
});

export default theme;
