import React, { cloneElement, isValidElement, useState } from 'react';
import { string } from 'yup';
import cx from 'classnames';

// components
import {
  SelectWithController,
  TextArea,
  TextInput,
} from 'src/components/Inputs';
import { Btn as NewButton } from 'src/components-v2/Inputs';
import { MarketoForm, GdprFields } from 'src/components/Forms';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { Grid, Column } from 'src/components/Layout/Grid';
import { formCtaAdapter } from 'src/components/FormCta';

import { FormWrapper } from './FormWrapper';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getRequiredMessage, mutateHardCodedLinks } from 'src/lib/utils';

// types
import { Cta } from 'src/components/cta.types';

import styles from './CmsFormWithAssetHero.module.scss';

import { Locales } from 'src/lib/constants';

import * as enUsForm from 'src/store/form';
import * as enEuForm from 'src/store/en-eu/form';
import * as enGbForm from 'src/store/en-gb/form';

const formPerLocale = {
  [Locales.EN_US]: enUsForm,
  [Locales.EN_EU]: enEuForm,
  [Locales.EN_GB]: enGbForm,
};
const CmsFormWithAssetHero = ({ fields, sys }): React.ReactElement => {
  const locale = usePageLocale();

  const backgroundColor = fields?.backgroundColor || 'white';
  const isDarkMode = backgroundColor === ('black' || 'black1000');
  const formWrapperProps = {
    body: fields?.leftColumn,
    backgroundColor,
    asset: fields?.asset?.fields,
  };

  // form
  const {
    id = 'contactForm',
    'include-phone': includePhone = false,
    'action-text': actionText = 'Contact sales',
    emailLabelOverride = 'Company email',
    companyLabelOverride = 'Company name',
    'marketo-key': marketoKey,
    includeCompany = false,
    includeJobTitle = false,
    includeCommentsField = false,
    includeDefaultFields = true,
    includeNames = true,
    includePxFieldset = false,
    children = null,
    disclaimer = '',
    trackingId = 'MARKETO_FORM',
    postSubmitContent = null,
    hasOnPagePostSubmitContent = false,
    isBodyForm = false,
    shouldForward = true,
    marketoFormBtnUrl,
    redirectOnSubmit,
    redirectUrl,
    formType,
  }: Cta = formCtaAdapter({
    fields,
    template: 'Hero Form',
  });
  const { formData } = mutateHardCodedLinks({
    data: formPerLocale[locale] || formPerLocale[Locales.EN_US],
    locale,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const shouldRenderPostSubmitContent =
    hasOnPagePostSubmitContent && isFormSubmitted;

  // inject children with props for use in form
  const childrenWithProps = ({ control, errors, register, setValue }) => {
    return React.Children.map(children, (child) => {
      // checking if child is invalid for safety, also avoids a typescript error
      if (!isValidElement(child)) {
        return child;
      }
      const props = {
        register: register,
        required: child.props.errorMessage,
        errorMessage: errors[child.props.name]?.message,
        control: control,
        setValue: setValue,
        isDarkMode,
      };
      // Note: this is will be deprecated with SITE-2278
      return cloneElement(child, props);
    });
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
  };

  const formProps = {
    actionText,
    companyLabelOverride,
    emailLabelOverride,
    children,
    includeDefaultFields,
    includePhone,
    isDarkMode,
    marketoKey,
    trackingId,
    locale,
    formType,
    shouldForward,
    redirectUrl:
      // we don't pass a url if the form should stay on the page
      // otherwise we try to pass the redirect url from gated content
      // or the marketo form button so that the user gets a predictable experience
      hasOnPagePostSubmitContent
        ? undefined
        : redirectOnSubmit
        ? redirectUrl
        : marketoFormBtnUrl,

    onSubmit: handleSubmit,
    render: ({ control, register, errors, setValue }) => {
      return (
        <>
          {includeDefaultFields && (
            <>
              {includeNames && (
                <div className='grid-container full'>
                  <div className='grid-x form-group'>
                    <div className='cell small-12 medium-6'>
                      <TextInput
                        className={cx(
                          styles.inputGroup,
                          isDarkMode && styles.inputGroup_dark,
                        )}
                        labelClassName={styles.label}
                        inputClassName={styles.input}
                        label={formData.firstName.label}
                        id={formData.firstName.name}
                        name={formData.firstName.name}
                        describedby={formData.firstName.describedby}
                        isDarkMode={isDarkMode}
                        errorMessage={
                          errors?.[formData.firstName.name]?.message
                        }
                        register={register}
                        required={getRequiredMessage(formData.firstName.label)}
                        autoComplete={formData.firstName.autoComplete}
                      />
                    </div>

                    <div className='cell small-12 medium-6'>
                      <TextInput
                        className={cx(
                          styles.inputGroup,
                          isDarkMode && styles.inputGroup_dark,
                        )}
                        labelClassName={styles.label}
                        inputClassName={styles.input}
                        label={formData.lastName.label}
                        id={formData.lastName.name}
                        name={formData.lastName.name}
                        describedby={formData.lastName.describedby}
                        isDarkMode={isDarkMode}
                        errorMessage={errors?.[formData.lastName.name]?.message}
                        register={register}
                        required={getRequiredMessage(formData.lastName.label)}
                        autoComplete={formData.lastName.autoComplete}
                      />
                    </div>
                  </div>
                </div>
              )}

              <TextInput
                className={cx(
                  styles.inputGroup,
                  isDarkMode && styles.inputGroup_dark,
                )}
                labelClassName={styles.label}
                inputClassName={styles.input}
                label={emailLabelOverride}
                id={formData.email.name}
                name={formData.email.name}
                describedby={formData.email.describedby}
                isDarkMode={isDarkMode}
                errorMessage={errors?.Email?.message}
                register={register}
                pattern={{
                  value: string().email().tests[0].OPTIONS.params.regex,
                  message: formData.email.invalidMessage,
                }}
                required={getRequiredMessage(formData.email.label)}
                autoComplete={formData.email.autoComplete}
              />

              {includeCompany && (
                <TextInput
                  className={cx(
                    styles.inputGroup,
                    isDarkMode && styles.inputGroup_dark,
                  )}
                  labelClassName={styles.label}
                  inputClassName={styles.input}
                  label={companyLabelOverride}
                  id={formData.company.name}
                  name={formData.company.name}
                  describedby={formData.company.describedby}
                  isDarkMode={isDarkMode}
                  errorMessage={errors?.[formData.company.name]?.message}
                  register={register}
                  required={getRequiredMessage(formData.company.label)}
                  autoComplete={formData.company.autoComplete}
                />
              )}

              {/*
                TODO: Threads phone input. Currently bugged:
                https://github.plaid.com/pages/plaid/threads/?path=/story/inputs-phone-input--basic-example
              */}

              {includePhone && (
                <TextInput
                  className={cx(
                    styles.inputGroup,
                    isDarkMode && styles.inputGroup_dark,
                  )}
                  labelClassName={styles.label}
                  inputClassName={styles.input}
                  label={formData.phone.label}
                  id={formData.phone.name}
                  name={formData.phone.name}
                  describedby={formData.phone.describedby}
                  isDarkMode={isDarkMode}
                  errorMessage={errors?.[formData.phone.name]?.message}
                  register={register}
                  autoComplete={formData.phone.autoComplete}
                />
              )}

              {includePxFieldset && (
                <fieldset>
                  <SelectWithController
                    className={cx(
                      styles.selectGroup,
                      isDarkMode && styles.selectGroup_dark,
                    )}
                    controlClassName={styles.selectControl}
                    inputClassName={styles.selectInput}
                    labelClassName={styles.selectLabel}
                    {...formData.categoryInput}
                    errorMessage={
                      errors?.[formData.categoryInput?.name]?.message
                    }
                    required={getRequiredMessage(formData.categoryInput?.label)}
                    isDarkMode={isDarkMode}
                    control={control}
                    register={register}
                    setValue={setValue}
                  />

                  <TextInput
                    className={cx(
                      styles.inputGroup,
                      isDarkMode && styles.inputGroup_dark,
                    )}
                    labelClassName={styles.label}
                    inputClassName={styles.input}
                    {...formData.coreProviderInput}
                    errorMessage={
                      errors?.[formData.coreProviderInput?.name]?.message
                    }
                    required={getRequiredMessage(
                      formData.coreProviderInput?.label,
                    )}
                    isDarkMode={isDarkMode}
                    register={register}
                  />

                  <TextInput
                    className={cx(
                      styles.inputGroup,
                      isDarkMode && styles.inputGroup_dark,
                    )}
                    labelClassName={styles.label}
                    inputClassName={styles.input}
                    {...formData.bankingProviderInput}
                    errorMessage={
                      errors?.[formData.bankingProviderInput?.name]?.message
                    }
                    required={getRequiredMessage(
                      formData.bankingProviderInput?.label,
                    )}
                    isDarkMode={isDarkMode}
                    register={register}
                  />
                </fieldset>
              )}

              {includeJobTitle && (
                <TextInput
                  className={cx(
                    styles.inputGroup,
                    isDarkMode && styles.inputGroup_dark,
                  )}
                  labelClassName={styles.label}
                  inputClassName={styles.input}
                  label={formData.jobTitle?.label}
                  id={formData.jobTitle?.name}
                  name={formData.jobTitle?.name}
                  describedby={formData.jobTitle?.describedby}
                  errorMessage={errors?.[formData.jobTitle?.name]?.message}
                  register={register}
                  required={getRequiredMessage(formData.jobTitle?.label)}
                  autoComplete={formData.jobTitle?.autoComplete}
                  isDarkMode={isDarkMode}
                />
              )}

              {includeCommentsField && (
                <TextArea
                  className={cx(
                    styles.textAreaGroup,
                    isDarkMode && styles.textAreaGroup_dark,
                  )}
                  {...formData.comments}
                  isDarkMode={isDarkMode}
                  errorMessage={errors?.[formData.comments?.name]?.message}
                  required={getRequiredMessage(formData.comments?.label)}
                  register={register}
                />
              )}
            </>
          )}

          {childrenWithProps({
            control,
            register,
            errors,
            setValue,
          })}

          <GdprFields
            className={cx(
              styles.selectGroup,
              isDarkMode && styles.selectGroup_dark,
            )}
            controlClassName={styles.selectControl}
            inputClassName={styles.selectInput}
            labelClassName={styles.selectLabel}
            consentCheckboxClassName={styles.checkboxConteiner}
            control={control}
            errors={errors}
            isDarkMode={isDarkMode}
            register={register}
            setValue={setValue}
          />

          <Grid noPadding noMarginRightMedium noMarginRightLarge useThreadsGrid>
            <Column medium={isBodyForm ? 6 : 12} allowOverflow>
              <NewButton
                className={styles.button}
                type='submit'
                value={actionText}
                backgroundColor={backgroundColor}
              >
                {actionText}
              </NewButton>
            </Column>
          </Grid>

          {disclaimer ? (
            typeof disclaimer === 'string' ? (
              <p
                data-testid='disclaimer'
                className={cx(styles['contact-form__disclaimer'], {
                  [styles['contact-form__disclaimer_dark']]: isDarkMode,
                })}
                dangerouslySetInnerHTML={{
                  __html: disclaimer,
                }}
              />
            ) : (
              <RichTextRenderer
                content={disclaimer}
                className={cx(styles['contact-form__disclaimer'], {
                  [styles['contact-form__disclaimer_dark']]: isDarkMode,
                })}
              />
            )
          ) : // Don't use fallback disclaimer text on Body Form CTA instances.
          !isBodyForm ? (
            <p
              data-testid='disclaimer'
              className={cx(styles['contact-form__disclaimer'], {
                [styles['contact-form__disclaimer_dark']]: isDarkMode,
              })}
              dangerouslySetInnerHTML={{
                __html: formData.disclaimer,
              }}
            />
          ) : (
            ''
          )}
        </>
      );
    },
  };

  return (
    <FormWrapper id={id} sys={sys} {...formWrapperProps}>
      <>
        {shouldRenderPostSubmitContent ? (
          postSubmitContent
        ) : (
          <MarketoForm {...formProps} />
        )}
      </>
    </FormWrapper>
  );
};

export default CmsFormWithAssetHero;
