import * as React from 'react';
import { CtaBanner } from 'src/sections';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Btn as Button } from 'src/components-v2/Inputs';
import { targetMap } from 'src/lib/constants';

const CmsCtaBanner = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const bgImage = fields?.backgroundImage?.fields?.file?.url;

  return (
    <CtaBanner
      sx={{
        backgroundColor: bgColor,
        ...fields?.styles,
      }}
      sys={sys}
      bgImage={bgImage}
    >
      <RichTextSwitch
        sys={sys}
        content={fields?.body}
        nodes={{
          h2: {
            variant: 'h3',
            component: 'h2',
            fieldId: 'body',
          },
        }}
      />
      <Button
        variant={fields?.cta?.fields?.appearance?.toLowerCase() || 'primary'}
        href={fields?.cta?.fields?.url || ''}
        target={targetMap[fields?.cta?.fields?.target] || '_self'}
        trackingId={fields?.cta?.fields?.trackingId || ''}
        sys={fields?.cta?.sys}
      >
        {fields?.cta?.fields?.text}
      </Button>
    </CtaBanner>
  );
};

export default CmsCtaBanner;
