import React from 'react';
import Image from 'src/components/Image';
import { string, bool, arrayOf, shape } from 'prop-types';

import {
  CredibilityQuote,
  CredibilityStat,
  CredibilityCtaLogo,
} from 'src/components/Credibility';

const propTypes = {
  item: shape({
    'image-src-medium-up': string.isRequired,
    'bg-color': string.isRequired,
    'cta-link': string,
    'cta-text': string,
    'logo-src-small': shape({
      src: string.isRequired,
      alt: string,
    }).isRequired,
    'quote-byline': string,
    'quote-headline': string,
    'quote-text': string,
    'show-quote-logo-asset': bool,
    stats: arrayOf(
      shape({
        data: string,
        text: string,
      }),
    ),
  }),
};

const CredibilityItem = ({ item, ...props }) => (
  <div className={`credibility__item credibility__item--${item['bg-color']}`}>
    <div className='grid-container'>
      <div className='grid-x align-center'>
        <div className='cell hide-for-small-only medium-3 medium-offset-1 large-6 large-offset-0 credibility__section credibility__section--media'>
          <Image
            className='credibility__media'
            src={item['image-src-medium-up']}
          />
        </div>
        <div className='cell small-12 medium-7 medium-offset-1 large-6 large-offset-0 credibility__section credibility__section--content'>
          {item['quote-text'] && <CredibilityQuote {...item} />}
          {item.stats &&
            item.stats.map((m, i) => (
              <CredibilityStat
                {...m}
                key={`credibility-stat-${i}`}
                index={i}
                isDoubleStat={item.stats.length === 2}
                isLast={item.stats.length === i + 1}
                {...props}
                {...item}
              />
            ))}
          <CredibilityCtaLogo {...item} />
        </div>
      </div>
    </div>
  </div>
);

CredibilityItem.propTypes = propTypes;

export default CredibilityItem;
