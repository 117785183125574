import {
  ImageEntryShape,
  CtaEntryShape,
  BackgroundColorShape,
} from 'src/lib/ts-types';
import { Showcase as ShowcaseProps } from 'src/components/Surfaces/Showcase/Showcase.types';
import { Entry } from 'contentful';
/* eslint-disable import/prefer-default-export */

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

interface AdapterProps {
  metaTitle: string;
  excerpt: string;
  label?: string;
  cta: Entry<CtaEntryShape>;
  image: Entry<ImageEntryShape>;
  backgroundColor: Entry<BackgroundColorShape>;
}

export const blogPostExcerptAdapter = ({
  metaTitle,
  excerpt,
  label = '',
  cta,
  image,
  backgroundColor,
}: AdapterProps): ShowcaseProps => {
  return {
    bgColor: backgroundColor?.fields.color,
    title: metaTitle,
    description: excerpt,
    label,
    hasCta: !!(cta?.fields?.url && cta?.fields?.text),
    cta: {
      href: cta?.fields?.url,
      text: cta?.fields?.text,
    },
    image: {
      src: image?.fields?.file?.url,
      alt: image?.fields?.title,
    },
  };
};
