import React, { FC } from 'react';

// components
import { TextInput } from 'src/components/Inputs';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

import { FormSectionTextInputProps } from './FormSectionTextInput.types';

const FormSectionTextInput: FC<FormSectionTextInputProps> = ({
  data,
  errors,
  register,
  pattern = {},
  required,
}) => {
  return (
    <TextInput
      label={data.label}
      id={data.name}
      name={data.name}
      describedby={data.describedby}
      errorMessage={errors?.[data.name]?.message}
      register={register}
      {...(required && { required: getRequiredMessage(data.label) })}
      autoComplete={data.autoComplete}
      {...(pattern && { pattern: { ...pattern } })}
    />
  );
};

export default FormSectionTextInput;
