/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import _clamp from 'lodash/clamp';
import { ButtonTypes } from 'src/lib/constants';
import { nanoid } from 'nanoid';

const getBackgroundImagesObj = (backgroundImages) => {
  return backgroundImages.reduce(
    (obj, item, i) => {
      obj['background-image'] += `url(${_get(
        _get(item, 'fields.image'),
        'fields.file.url',
      )})`;
      obj['background-position'] +=
        _get(item, 'fields.backgroundPosition') || 'initial';
      obj['background-repeat'] +=
        _get(item, 'fields.backgroundRepeat') || 'initial';
      obj['background-size'] +=
        _get(item, 'fields.backgroundSize') || 'initial';
      if (i !== backgroundImages.length - 1) {
        obj['background-image'] += ',';
        obj['background-position'] += ',';
        obj['background-repeat'] += ',';
        obj['background-size'] += ',';
      }
      return obj;
    },
    {
      'background-image': '',
      'background-position': '',
      'background-repeat': '',
      'background-size': '',
    },
  );
};

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for product data content type to the view
 *
 * @param {Object} args
 * @param {string} args.type
 * @param {string} args.title
 * @param {string} args.description
 * @param {string} args.codeTitle
 * @param {string} args.codeLang
 * @param {string} args.codeBody
 * @param {string} args.postRunTitle
 * @param {string} args.postRunDescription
 * @param {string} args.postRunCodeTitle
 * @param {string} args.postRunCodeLang
 * @param {string} args.postRunCodeBody
 * @param {Object[]} args.properties
 * @param {Object[]} args.ctaButtons
 * @param {Object[]} args.backgroundImages
 * @param {boolean} args.flipped
 * @returns {Object}
 */

const calcCodeContainerHeight = ({ codeBody, heightWithoutCode }) => {
  const lineHeight = 23.81; // height of each line
  const lineCount = (codeBody?.match(/\n/g) || []).length + 1;
  return _clamp(heightWithoutCode + lineHeight * lineCount, 278, 624);
};

export const productDataAdapter = ({
  type,
  title,
  description,
  codeTitle,
  codeLang,
  codeBody,
  postRunTitle = '',
  postRunDescription = '',
  postRunCodeTitle = '',
  postRunCodeBody = '',
  postRunCodeLang = '',
  properties,
  ctaButtons = [],
  backgroundImages = [],
  flipped = false,
}) => {
  const primaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );

  const propertiesArr = properties.map((item) => {
    return {
      title: _get(item, 'fields.name'),
      description: _get(item, 'fields.description'),
      'code-line-start': _get(item, 'fields.codeLineStart'),
      'code-highlight-length': _get(item, 'fields.codeHighlightLength'),
    };
  });
  const pageClass = `product-data-container-${nanoid()}`;

  const codeContainerHeight = calcCodeContainerHeight({
    codeBody,
    heightWithoutCode: 82,
  });

  const codeContainerPostRunHeight = calcCodeContainerHeight({
    codeBody: postRunCodeBody,
    heightWithoutCode: 58,
  });

  let style = `
    @media screen and (min-width: 640px) {
      .${pageClass} .interactive-data .code-container {
        height: ${codeContainerHeight}px;
      }
      .${pageClass} .interactive-data .code-container.code-container--stay-tall,
      .${pageClass} .interactive-data .code-container--post-run-mobile {
        height: ${codeContainerPostRunHeight}px;
      }
    }
  `;

  if (backgroundImages.length > 0) {
    const backgroundImagesObj = getBackgroundImagesObj(backgroundImages);

    style += `
      @media screen and (min-width: 1024px) {
        .${pageClass} .product-data {
          background-image: ${backgroundImagesObj['background-image']};
          background-position: ${backgroundImagesObj['background-position']};
          background-repeat: ${backgroundImagesObj['background-repeat']};
          background-size: ${backgroundImagesObj['background-size']};
        }
      }
    `;
  }

  return {
    'bottom-cta-url': _get(primaryCta, 'fields.url'),
    'bottom-cta-text': _get(primaryCta, 'fields.text'),
    'secondary-cta-url': _get(secondaryCta, 'fields.url'),
    'secondary-cta-text': _get(secondaryCta, 'fields.text'),
    'title-text': title,
    'description-text': description,
    'code-title': codeTitle,
    'code-body': codeBody,
    'code-lang': codeLang,
    properties: propertiesArr,
    pageClass,
    style,
    ...(type === 'static'
      ? {
          flipped,
        }
      : {
          'post-run-title-text': postRunTitle,
          'post-run-description-text': postRunDescription,
          'post-run-code-title': postRunCodeTitle,
          'post-run-code-body': postRunCodeBody,
          'post-run-code-lang': postRunCodeLang,
        }),
    stayTallHeight: codeContainerPostRunHeight,
  };
};
