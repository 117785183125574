/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { Sys, Asset } from 'contentful';
import { ImageShape } from 'src/lib/ts-types';

interface CtaFields {
  text: string;
  type: string;
  url: string;
}

interface Cta {
  fields: CtaFields;
  sys: Sys;
}

interface QuoteWithImageCmsData {
  attribution: string;
  cta: Cta;
  headline: string;
  label: string;
  image: Asset;
  logo: Asset;
  quote: string;
}

interface QuoteWithImageComponentProps {
  'cta-link': string;
  'cta-text': string;
  image: ImageShape;
  'logo-src-small': ImageShape;
  'quote-byline': string;
  'quote-headline': string;
  'quote-label': string;
  'quote-text': string;
  'show-quote-logo-asset': boolean;
}

/**
 * Maps Contentful response for Quote With Image content type to the component's expected props
 */
export const credibilityQuoteWithImageAdapter = ({
  attribution,
  cta,
  headline,
  label,
  image,
  logo,
  quote,
}: QuoteWithImageCmsData): QuoteWithImageComponentProps => ({
  'cta-link': _get(cta, 'fields.url'),
  'cta-text': _get(cta, 'fields.text'),
  image: {
    src: _get(image, 'fields.file.url'),
    alt: _get(image, 'fields.description', 'headshot of quotee'),
  },
  'logo-src-small': {
    src: _get(logo, 'fields.file.url'),
    alt: _get(logo, 'fields.description', 'logo of customer'),
  },
  'quote-byline': attribution,
  'quote-headline': headline || '',
  'quote-label': label || '',
  'quote-text': quote,
  'show-quote-logo-asset': true,
});
