import PropTypes from 'prop-types';
export interface Property {
  title: string;
  description: string;
  'code-line-start': number;
  'code-highlight-length': number;
}

export type HandleEvent = ({
  e,
  currentElement,
  parentContainer,
  device,
}) => void;

export const PropertiesShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  'code-line-start': PropTypes.number.isRequired,
  'code-highlight-length': PropTypes.number.isRequired,
});
