import React from 'react';
import dynamic from 'next/dynamic';
import Script from 'next/script';

import { TemplateWrapper } from 'src/templates';
import { InstitutionPageProps } from './';
import {
  PageHeaderCms as PageHeader,
  Breadcrumb,
} from 'src/components/headers/';
import FooterExtension from 'src/components/footer-extension';
import { SupportingText } from 'src/components/Layout';
import {
  FeaturesSection,
  FeaturesSectionItem,
} from 'src/components/features/index';
import { ProductsCodeDemo } from 'src/components/Surfaces';
import { IntroSectionCms } from 'src/components/Layout/IntroSection';
import { Link } from 'src/components/Inputs';
import styles from './InstitutionTemplate.module.scss';
import cx from 'classnames';

const InstitutionTemplateScripts = dynamic(
  () => import('src/templates/InstitutionTemplate/InstitutionTemplateScripts'),
  {
    ssr: false,
  },
);

const InstitutionTemplate = ({
  metaDescription,
  metaTitle,
  pageSlug,
  pageHeaderProps,
  footerExtensionProps,
  supportingTextProps,
  featuresPlaidIntegrationProps,
  featuresInstitutionMetadataProps,
  allEndpointDemoData,
  introSectionProps,
  institutionName,
  internalLinksProps,
}: InstitutionPageProps): JSX.Element => {
  const { products: codeDemoProducts } = featuresInstitutionMetadataProps;
  return (
    <TemplateWrapper
      meta-title={metaTitle}
      meta-description={metaDescription}
      hasFooterCta={false}
      noIndex={false}
      className='institution-page'
    >
      <Script
        id='institutions-breadcrumb-schema'
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Financial Institutions',
                item: 'https://plaid.com/institutions/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: `${institutionName}`,
                item: `https://plaid.com/institutions/${pageSlug}/`,
              },
            ],
          }),
        }}
      />
      <PageHeader
        {...pageHeaderProps}
        breadcrumb={
          <Breadcrumb>
            <Link href={'/institutions/'}>
              <a>Financial Institutions</a>
            </Link>
            <span>{institutionName}</span>
          </Breadcrumb>
        }
      />
      <FeaturesSection
        hasThreeColumns={featuresInstitutionMetadataProps.hasThreeColumns}
        classes='background background--color-black100'
      >
        {featuresInstitutionMetadataProps.accountTypes.length > 0 && (
          <FeaturesSectionItem
            hasSeparator={true}
            hasSimpleContent={true}
            hasThreeColumns={featuresInstitutionMetadataProps.hasThreeColumns}
            isSingleRow={true}
            title='Account type'
            content={
              <ul>
                {featuresInstitutionMetadataProps.accountTypes.map(
                  (accountType) => {
                    return <li>{accountType}</li>;
                  },
                )}
              </ul>
            }
          />
        )}
        <FeaturesSectionItem
          hasSeparator={true}
          hasSimpleContent={true}
          hasThreeColumns={featuresInstitutionMetadataProps.hasThreeColumns}
          isSingleRow={true}
          title='Supported Plaid products'
          content={
            <ul>
              {featuresInstitutionMetadataProps.products.map((product) => {
                return <li key={product}>{product}</li>;
              })}
            </ul>
          }
        />
        <FeaturesSectionItem
          hasSeparator={true}
          hasSimpleContent={true}
          hasThreeColumns={featuresInstitutionMetadataProps.hasThreeColumns}
          isSingleRow={true}
          title='Geography'
          content={
            <ul>
              <li>{featuresInstitutionMetadataProps.country}</li>
            </ul>
          }
        />
      </FeaturesSection>

      <IntroSectionCms {...introSectionProps} />

      <FeaturesSection
        hasThreeColumns={featuresPlaidIntegrationProps.hasThreeColumns}
      >
        {featuresPlaidIntegrationProps.items.map((item, idx) => (
          <FeaturesSectionItem
            key={`feature-item-${idx}`}
            {...item}
            hasThreeColumns={featuresPlaidIntegrationProps.hasThreeColumns}
            isSingleRow={true}
            content={<p>{item.content}</p>}
          />
        ))}
      </FeaturesSection>
      <ProductsCodeDemo
        data={allEndpointDemoData}
        products={codeDemoProducts}
      />
      <SupportingText {...supportingTextProps} />
      <FeaturesSection
        hasThreeColumns={true}
        label='ADDITIONAL SUPPORTED INSTITUTIONS'
        classes='background background--color-black100'
      >
        {internalLinksProps?.map((item) => {
          return (
            <div className='cell small-12 medium-4' key={item.title}>
              <h4 className={styles.internalLinkHeading}>
                <Link href={item.href}>
                  <a>{item.title}</a>
                </Link>
              </h4>
            </div>
          );
        })}
        <div className={cx('cell small-12 medium-6', styles.internalLinksCta)}>
          <FeaturesSectionItem
            isSingleRow={true}
            cta={{ href: '/institutions/', text: 'See all Institutions' }}
            title=''
            content=''
          />
        </div>
      </FeaturesSection>
      <FooterExtension {...footerExtensionProps} />
      <InstitutionTemplateScripts />
    </TemplateWrapper>
  );
};

export default InstitutionTemplate;
