import React, { FC, ReactNode } from 'react';
import { RichTextSwitch } from 'src/containers';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import {
  Column,
  Section,
  Container,
  Row,
  Stack,
  Box,
} from 'src/components-v2/Layout';
import { RichTextContent, Sys } from 'contentful';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { CmsAssetProps } from 'src/components-v2/Media/CmsMediaSwitch/CmsMediaSwitch.types';

export interface FormWrapperProps {
  children: ReactNode;
  backgroundColor?: string;
  body?: RichTextContent | null;
  asset?: CmsAssetProps | null;
  styles?: SxProps<Theme>;
  sys?: Sys;
  id: string;
}

export const FormWrapper: FC<FormWrapperProps> = ({
  children,
  sys = '',
  backgroundColor,
  body,
  asset,
  id,
}) => {
  const isBackgroundColorBlack = backgroundColor === ('black' || 'black1000');

  const textColorStyle = {
    color: isBackgroundColorBlack ? 'white' : 'inherit',
  };

  const contentNodes = {
    h1: {
      component: 'h1',
      variant: 'h1',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
        fontSize: '4.0rem',
      },
    },
    h2: {
      component: 'h2',
      variant: 'h2',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
    h3: {
      component: 'h3',
      variant: 'h3',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
    h6: {
      component: 'h6',
      variant: 'h6',
      className: 'eyebrow-heading',
      fieldId: 'leftColumn',
      sx: {
        color: isBackgroundColorBlack ? 'white' : 'var(--black800)',
        fontSize: { lg: '1.2rem' },
        letterSpacing: { lg: '1.25px' },
      },
    },
    p: {
      component: 'p',
      variant: 'p2',
      fieldId: 'leftColumn',
      sx: {
        ...textColorStyle,
      },
    },
  };

  return (
    <Section
      id={id}
      sx={{
        backgroundColor,
      }}
    >
      <Container>
        <Row direction={{ lg: 'row-reverse' }}>
          <Column
            xs={22}
            xsOffset={1}
            sm={22}
            smOffset={1}
            lg={13}
            lgOffset={1}
            sx={{
              p: '0',
              mb: { xs: '3.2rem', sm: '4.8rem', lg: '0' },
            }}
          >
            <Box
              sx={{
                mr: {
                  lg: '-4rem',
                  xxl: 'calc(((100vw - 1730px) / -2) - 40px)',
                },
                minHeight: { lg: '100%' },
                aspectRatio: { lg: '925 / 863' },
                overflow: 'hidden',
                borderRadius: { xs: '0.8rem', sm: '0.8rem', lg: '0' },
                borderTopLeftRadius: { lg: '1.2rem' },
                borderBottomLeftRadius: { lg: '1.2rem' },
              }}
            >
              {asset && (
                <CmsMediaSwitch
                  asset={asset}
                  queryString={formImageQueryString}
                  sx={{
                    display: 'block',
                    width: '100%',
                    height: { xs: 'auto', lg: '100%' },
                    objectFit: 'cover',
                  }}
                />
              )}
            </Box>
          </Column>

          <Column
            xs={22}
            xsOffset={1}
            sm={22}
            smOffset={1}
            lg={8}
            lgOffset={2}
            sx={{ p: '0' }}
          >
            <Stack
              sx={{
                pt: {
                  lg: '10rem',
                },
                pb: {
                  lg: '6rem',
                },
                '& input': {
                  borderRadius: '8px',
                },
                '& i + span': {
                  fontSize: '1.4rem',
                },
                '& fieldset': {
                  '& div:has(input)': {
                    borderRadius: '8px',
                  },
                },
                '& .button-group': {
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
                },
                // for decorative text
                '& p:has(.decorative-text), h1:has(.decorative-text)': {
                  mb: { xs: 1, md: 2 },
                  // for icons
                  '& svg': {
                    mt: { xs: '0.2rem', md: '0.4rem' },
                    zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
                  },
                },
              }}
            >
              <Box
                sx={{
                  mb: {
                    xs: '1.6rem',
                    lg: '1.6rem',
                  },
                  '& .eyebrow-heading, .decorative-text': {
                    mb: '1.6rem',
                    fontSize: '1.2rem',
                  },
                  '& h1': {
                    mb: '1.6rem',
                    fontSize: {
                      xs: '4.0rem',
                      sm: '4.0rem',
                      md: '4.0rem',
                      lg: '4.0rem',
                    },
                    lineHeight: { lg: '120%' },
                    maxWidth: { sm: '556px', lg: '537px' },
                  },
                  '& h2': {
                    mb: '1.6rem',
                    maxWidth: { sm: '556px', lg: '537px' },
                  },
                  '& h3': {
                    mb: '1.6rem',
                    maxWidth: { sm: '556px', lg: '537px' },
                  },
                  '& p': {
                    mb: '1.6rem',
                    maxWidth: { sm: '556px', lg: '468px' },
                  },
                  '& p:nth-last-of-type(1):empty': {
                    display: 'none',
                  },
                }}
              >
                <RichTextSwitch sys={sys} content={body} nodes={contentNodes} />
              </Box>

              {children}
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const BASE = '?fm=webp&q=60';
const formImageQueryString = {
  xs: `${BASE}&w=800`,
  sm: `${BASE}&w=1234`,
  md: `${BASE}&w=1902`,
  lg: `${BASE}&w=2430`,
};
