/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useRef } from 'react';
import cx from 'classnames';

// components
import { Link } from 'src/components/Inputs';
import SubmenuColumn from './SubmenuColumn';
import ArrowDownBlack from 'src/components/SVGs/Navbar/ArrowDownBlack';

// types
import { NavbarMenuItemProps } from './types';

// constants
const MEGA_MENU_MAX_GRID_COLUMNS = 11;

const NavbarMenuItem: React.FC<NavbarMenuItemProps> = ({
  label = '',
  megaMenuClass = '',
  menuLinks = [],
  submenu = {},
  submenuClass = '',
  subtitle = '',
  subtitleColSize = 3,
  url = '',
  trackingId = '',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(null);
  const handleKeyUp = ({ key }) => {
    if (isExpanded && (key === 'Escape' || key === 'Esc')) {
      setIsExpanded(false);
    }
  };
  const handleBlur = ({ currentTarget, relatedTarget }) => {
    if (!currentTarget.contains(relatedTarget)) {
      setIsExpanded(false);
    }
  };

  const handleTriggerClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {label === 'pricing' ? (
        <ul>
          <li>
            <Link href={url} trackingId={trackingId}>
              <a className='Navbar__mega-menu-item'>
                <span className='Navbar__mega-menu-item-title'>{label}</span>
              </a>
            </Link>
          </li>
        </ul>
      ) : (
        <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
          ref={ref}
          className={cx(
            'Navbar__menu-item',
            submenu.columns && 'Navbar__menu-item--is-megamenu-trigger',
            isExpanded && 'Navbar__menu-item--active',
          )}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
        >
          {submenu.columns ? (
            <>
              <button
                className='Navbar__menu-item-anchor'
                onClick={handleTriggerClick}
                type='button'
                aria-expanded={isExpanded}
              >
                {label}{' '}
                <span aria-hidden='true' className='Navbar__menu-item-arrow'>
                  <ArrowDownBlack />
                </span>
              </button>
              <div
                className={cx('Navbar__mega-menu', megaMenuClass)}
                aria-hidden={!isExpanded}
              >
                <div className='grid-container Navbar__mega-menu-content'>
                  <div className='grid-x'>
                    <div className={cx('cell', `small-${subtitleColSize}`)}>
                      <h4 className='Navbar__mega-menu-title'>{label}</h4>
                      <h4 className='Navbar__mega-menu-subtitle regular'>
                        {subtitle}
                      </h4>
                      {menuLinks && (
                        <ul>
                          {menuLinks.map((item, i) => (
                            <React.Fragment key={`menu-link-${i}`}>
                              <li>
                                <Link
                                  href={item.url}
                                  trackingId={item?.trackingId}
                                >
                                  <a className='Navbar__mega-menu-item'>
                                    <span className='Navbar__mega-menu-item-title'>
                                      {item.name}
                                    </span>
                                  </a>
                                </Link>
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div
                      className={
                        submenuClass ||
                        `cell small-${
                          MEGA_MENU_MAX_GRID_COLUMNS - subtitleColSize
                        } small-offset-1`
                      }
                    >
                      <div className='grid-x'>
                        {submenu.columns &&
                          submenu.columns.map((column, columnIndex) => (
                            <SubmenuColumn
                              {...column}
                              key={`submenu-column-${columnIndex}`}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Link href={url}>
              <a className='Navbar__menu-item-anchor'>{label}</a>
            </Link>
          )}
        </li>
      )}
    </>
  );
};

export default NavbarMenuItem;
