export const metaData = {
  metaTitle: 'Placid',
  metaDescription:
    'Many payments companies force customers to choose between speed and cost. With Plaid, Placid Express can offer both—with fraud protection thrown in for good measure.',
};

export const pageHeaderData = {
  classes:
    'background background--color-pink200 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-multi-blue-white@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-text': 'Customer story: Placid Express',
  header: 'Fighting friction, fees, and fraud',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Placid-Express-Case-Study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-placid-express@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-placid-express@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Enable ACH payments,<br />prevent NSF/overdraft fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Payments',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Too often, consumers who want to make international payments are forced to choose between speed and affordability. Placid Express was determined to offer both.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Placid Express’s customers can connect their US bank accounts and start making affordable ACH payments in a matter of seconds—with a healthy dose of fraud protection thrown in for good measure.',
};

export const content1Data = {
  heading:
    'Founded in 1996, Placid Express enables its customers to securely, affordably and efficiently send money to friends and family abroad.',
  paragraph: [
    'They service eight countries in South and Southeast Asia, including India, the Philippines and Vietnam. Consumers can also utilize its in-network direct deposit services to many countries in Europe.',
    'When Placid Express introduced a digital version of its money transfer service in 2014, it faced unprecedented challenges. Top-notch fraud verification took on new importance, for starters. Moreover, many remittance companies in the digital space force consumers to choose between speed and price—a tradeoff Placid Express didn’t want to make.',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-pink200 supporting-text--constrained',
  'two-up': true,
  stat: true,
  stat2: '80%',
  text2:
    'Since integrating with Plaid, Placid Express has increased its instant approval rates for ACH-funded transfers by more than 80 percent.',
  stat3: '10%',
  text3:
    'Placid Express users who opt for Plaid-powered instant verification return at a higher rate (95%) than those who do not (70%).',
};

export const content2Data = {
  title: 'A payments problem',
  paragraph: [
    'Unlike cash-based payments, where finality of funds and associated money transfers are immediate, electronically funded transfers are not so simple.',
    'For credit or debit card payments, a transfer takes mere minutes, but there are high fees associated with the service. Conversely, consumers who elect to use ACH pay almost no fees but face transfer times of three to five days given the nature of the micro-deposits used to authenticate their accounts.',
    'Placid Express quickly realized it needed to offer consumers a better solution: a fast transfer option with built-in security features, minus the high fees. To that end, Placid Express built its own advanced fraud detection platform to intelligently approve transactions on the fly, regardless of payment method.',
    'With this model, it increased early approvals of ACH-funded transfers from 40 to 62 percent—a meaningful jump, but there was still room for improvement.',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-pink-3@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-pink-4@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Placid Express’ onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Placid Express’ users can start making ACH payments.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img:
        '/assets/img/customer-stories/diagram-step-onboards-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'A Plaid solution',
    paragraph: [
      'With a customized suite of Plaid products, Placid Express can now provide consumers with a quick and secure experience without burdensome fees. Plaid also proved much less expensive and easier to implement than other options.',
      '“Once we signed with Plaid, we definitely had the edge on the competition,” said Ahsan Choudhury, CTO of Placid Express. “Now we don’t have to wait three days to connect customer bank accounts. That makes our customers happy, and we are able to retain them very effectively.”',
      'With Plaid’s Auth and Identity products, Placid Express can now quickly and accurately confirm bank details and verify ownership of accounts. Identity complements their “Know Your Customer” procedures by providing a bank account holder’s information matched against the customer’s details in real time.',
      'Identity helps Placid Express determine when secondary security features like text message verification should be triggered. Additionally, Placid Express uses Balance to reduce non-sufficient funds fees by getting real-time balance checks on users’ accounts before sending payment.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-placid-express@2x.png',
      alt: 'Headshot of Ahsan Choudhury, CTO of Placid Express',
    },
    quote: [
      {
        copy:
          'With Plaid, we no longer have to wait three days to connect customer bank accounts. That makes our customers happy.',
        source: 'Ahsan Choudhury, CTO, Placid Express',
      },
    ],
  },
  {
    title: 'Saving time and money',
    paragraph: [
      'Since integrating with Plaid, Placid Express has increased its instant approval rates for ACH-funded transfers by more than 80 percent.',
      'For many customers, that means faster money transfers with much lower fees than previously offered. For Placid Express, it results in a more satisfying user experience and almost zero percent ACH chargeback rate among customers whose information was validated using Plaid Identity.',
      'Placid Express’s implementation of Identity has also engendered a 50 percent reduction in customer service queries related to micro-deposit verification and a 60 percent drop in requests from customers to fast-track transfers because it now can proactively speed up remittances that have a Plaid-linked bank account.',
      'As a result, Placid Express saves on support resources, and customers are happier. In fact, Placid Express users who opt for the instant verification service have a higher return rate that those who do not (95 percent versus 70 percent).',
    ],
  },
  {
    title: 'A passion for product',
    paragraph: [
      'With these new capabilities, Placid Express will continue to expand its online transfer business and innovate service without compromising security or customer experience.',
      'Furthermore, Placid Express has been thrilled with Plaid’s usability. Choudhury appreciates how easy it was to integrate Plaid technology and the high caliber of Plaid’s ongoing support.',
      '<strong>“We love Plaid’s superb customer service and amazing level of communication,” Choudhury says. “To this day, I receive immediate and detailed responses to any questions I have. The folks at Plaid really seem to love what they do. This level of excitement and passion for the products aligns very well with our own culture and ideas.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Carvana',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-carvana@2x.png',
        alt: 'Carvana - read full story',
      },
      excerpt: 'Accelerating the future of auto payments',
      url: '/customer-stories/carvana',
    },
    {
      name: 'Shift',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-shift@2x.png',
        alt: 'Shift - read full story',
      },
      excerpt: 'Shifting the used car landscape',
      url: '/customer-stories/shift',
    },
    {
      name: 'Alto',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-alto@2x.png',
        alt: 'Alto - read full story',
      },
      excerpt: 'A pharmacy for the digital age',
      url: '/customer-stories/alto',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Placid-Express-Case-Study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
