import React from 'react';
import Image from './Image';
import { Video } from './Video';

const HomepagePhone = () => {
  return (
    <>
      <div className='what-accent-1'>
        <Image
          src='/assets/img/accents/homepage-howitworks-brushstroke-left.png'
          alt=''
          isLazy={true}
        />
      </div>
      <Image
        className='simple-carousel-phone-img'
        src='/assets/img/phones/screen1.png'
        alt='Plaid Link'
        height='625'
        width='304'
        isLazy={true}
      />

      <Video
        isLazy={true}
        className='phone-video'
        width={267}
        height={578}
        poster='/assets/img/backgrounds/institution-list@2x.jpg'
        formats={[
          {
            src: '/assets/video/how-it-works.webm',
            type: 'video/webm',
          },
          {
            src: '/assets/video/how-it-works.mp4',
            type: 'video/mp4',
          },
        ]}
      />
    </>
  );
};

export default HomepagePhone;
