/* eslint-disable import/prefer-default-export */
import { Sys } from 'contentful';
import { AccountTableSectionProps } from './AccountTableSection';
import { AccountTableListProps } from './AccountTableList';

interface Table {
  fields: TableFields;
  sys: Sys;
}

interface TableFields {
  accountTableSmall?: boolean;
  columns: Column[];
  header?: string;
}

interface Column {
  fields: {
    items: Item[];
    header?: string;
  };
  sys: Sys;
}

interface Item {
  fields: {
    hasFeature?: boolean;
    itemName: string;
    tooltip?: string;
  };
  sys: Sys;
}

interface AccountTableSectionData {
  accountTypeDescription: string;
  accountTypeHeader: string;
  mainAccountTable: Table;
  secondaryAccountTables: Table[];
}

export const accountTableAdapter = ({
  accountTableSmall,
  columns,
  header,
}: TableFields): AccountTableListProps => ({
  accountTableSmall,
  columns: columns.map(({ fields }) => ({
    header: fields.header,
    rows: fields.items.map((item) => ({
      hasFeature: item.fields.hasFeature,
      itemName: item.fields.itemName,
      tooltip: item.fields.tooltip,
    })),
  })),
  header,
});

export const accountTableSectionAdapter = ({
  accountTypeDescription,
  accountTypeHeader,
  mainAccountTable,
  secondaryAccountTables,
}: AccountTableSectionData): AccountTableSectionProps => {
  return {
    accountTypeDescription,
    accountTypeHeader,
    mainAccountTable: accountTableAdapter({ ...mainAccountTable.fields }),
    secondaryAccountTables: secondaryAccountTables.map(({ fields }) => ({
      ...accountTableAdapter({ ...fields }),
    })),
  };
};
