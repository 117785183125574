import React from 'react';

const SidebarDevelopersIcon = () => {
  return (
    <svg height={15} width={18} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5.25h-15C.81.25.25.81.25 1.5v12c0 .69.56 1.25 1.25 1.25h15c.69 0 1.25-.56 1.25-1.25v-12c0-.69-.56-1.25-1.25-1.25zm-.25 3H4.75v-1.5h11.5zm-13-1.5v1.5h-1.5v-1.5zm-1.5 11.5v-8.5h14.5v8.5zm2.78-6.78L7.06 9l-2.53 2.53-1.06-1.06L4.94 9 3.47 7.53zM9 10.25h5v1.5H9z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default SidebarDevelopersIcon;
