import { FC, useEffect, useRef, useState } from 'react';
import { useScroll } from '@react-spring/web';
import cx from 'classnames';
import _get from 'lodash/get';

import { CmsThemeContextProvider } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { useDeviceSize } from 'src/hooks';
import { Box, Container, Stack, Row, Column } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';
import { Btn } from 'src/components-v2/Inputs';
import { Locales } from 'src/lib/constants';
import { PlaidLogo } from 'src/components-v2/SVGs';
import { Icon } from 'src/components-v2/DataDisplay';

import { NavList } from './NavList';
import { Dialog } from './Dialog';

import { navData } from './data';
import { calculateContrastRatio, getBackgroundColor, hexToRgb } from './utils';
import { BASE_THEMES_COLORS } from './consts';

import { colors } from '../theme';

import styles from './MainNavigationBar.module.scss';

type MainNavigationBarProps = {
  fields?: any;
  defaultBackgroundColor?: string;
  skipLinkAnchor?: string;
};

export const MainNavigationBar: FC<MainNavigationBarProps> = ({
  fields,
  defaultBackgroundColor,
  skipLinkAnchor = '#main-content',
}) => {
  // COMMON
  const { items, ctaTrackingIds } = navData[Locales.EN_US];
  const { isMainNavNew } = useDeviceSize();
  const backgroundColor = _get(
    fields,
    'backgroundColor.fields.color',
    defaultBackgroundColor,
  );

  const animatedLayerRef = useRef<HTMLDivElement>(null);

  //FIRST VISIT
  const [isFirstVisit, setIsFirstVisit] = useState<-1 | 0 | 1>(-1);

  useEffect(() => {
    const hasVisited = JSON.parse(
      localStorage.getItem('hasVisited') ?? 'false',
    );

    if (!hasVisited) {
      setIsFirstVisit(1);
      localStorage.setItem('hasVisited', JSON.stringify(true));
    } else {
      setIsFirstVisit(0);
    }
  }, []);

  // SCROLL
  const [theme, setTheme] = useState<keyof typeof BASE_THEMES_COLORS>(() => {
    const color = hexToRgb(colors[backgroundColor]);

    const contrastRatio1 = calculateContrastRatio(
      color,
      BASE_THEMES_COLORS.light,
    );

    const contrastRatio2 = calculateContrastRatio(
      color,
      BASE_THEMES_COLORS.dark,
    );

    return contrastRatio1 < contrastRatio2 ? 'light' : 'dark';
  });
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
  const prevScrollY = useRef(0);

  useScroll({
    onChange: ({ value: { scrollY } }) => {
      // show/hide menu bar
      if (scrollY > 100) {
        setScrollDirection(scrollY > prevScrollY.current ? 'down' : 'up');
        prevScrollY.current = scrollY;
      } else {
        setScrollDirection('up');
        prevScrollY.current = 0;
      }
    },
  });

  useScroll({
    onChange: () => {
      const color = getBackgroundColor(document?.elementFromPoint(0, 60));

      const contrastRatio1 = calculateContrastRatio(
        color,
        BASE_THEMES_COLORS.light,
      );

      const contrastRatio2 = calculateContrastRatio(
        color,
        BASE_THEMES_COLORS.dark,
      );

      setTheme(contrastRatio1 < contrastRatio2 ? 'light' : 'dark');
    },
  });

  // MODAL
  const [isOpen, setOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (typeof document === 'undefined') return;

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = 'initial';
    } else {
      document.body.style.overflowY = 'visible';
      document.body.style.height = '100%';
    }
  }, [isOpen]);

  const handleOpenDialog = () => {
    if (dialogRef.current) {
      setOpen(true);
      dialogRef.current.showModal();
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <CmsThemeContextProvider cmsTheme='web 2.0'>
      <Box
        className={cx(styles.Root, styles[`Root_${theme}`], {
          [styles.Root_hidden]: isFirstVisit === -1,
          [styles.Root_visible]: isFirstVisit === 0,
          [styles['Root_animated-appearence']]: isFirstVisit === 1,
        })}
        component='nav'
        sx={{
          position: 'fixed',
          width: '100%',
          top: 0,
          pt: '24px',
          zIndex: 99999999,
        }}
      >
        <Box ref={animatedLayerRef} className={styles.AnimatedLayer} />

        <Container
          className={cx(styles.Root__container, {
            [styles.Root__container_hide]:
              !isMainNavNew && scrollDirection === 'down',
          })}
        >
          <Row sx={{ my: 0 }}>
            <Column xs={24} lgOffset={2} lg={20} sx={{ py: 0 }}>
              <Box className={styles.Root__bar}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  height={{ xs: '56px', sm: '60px', md: '71px' }}
                >
                  <Link href={skipLinkAnchor}>
                    <a
                      className={styles['Root__link-to-content']}
                      role='link'
                      tabIndex={0}
                    >
                      Skip to main content
                    </a>
                  </Link>

                  {isMainNavNew ? (
                    <>
                      <NavList
                        items={items}
                        sx={{ height: '100%' }}
                        animatedLayerRef={animatedLayerRef}
                      >
                        <Link href='/'>
                          <a
                            className={styles.Logo}
                            aria-label='Go to Plaid homepage'
                            role='link'
                            tabIndex={0}
                          >
                            <PlaidLogo
                              sx={{
                                display: 'block',
                                height: { xs: '24px', sm: '28px' },
                              }}
                            />
                          </a>
                        </Link>
                      </NavList>

                      <Box className={styles.Root__toolkit}>
                        <Btn
                          className={cx(styles.Button, styles.Button_secondary)}
                          variant='secondary'
                          href='https://dashboard.plaid.com/signin/'
                          tabIndex={0}
                        >
                          Log in
                        </Btn>

                        <Btn
                          className={cx(styles.Button, styles.Button_primary)}
                          variant='primary'
                          iconName='ChevronRight'
                          iconSize={16}
                          defaultGap='0.6rem'
                          href='https://dashboard.plaid.com/contact'
                          trackingId={ctaTrackingIds.getAPIkeys}
                          tabIndex={0}
                        >
                          Contact sales
                        </Btn>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Link key={'qwer-56'} href='/'>
                        <a
                          className={styles.Logo}
                          aria-label='Go to Plaid homepage'
                          role='link'
                          tabIndex={0}
                        >
                          <PlaidLogo
                            sx={{
                              display: 'block',
                              height: { xs: '24px', sm: '28px' },
                            }}
                          />
                        </a>
                      </Link>

                      <Box
                        className={styles.Burger}
                        component='button'
                        role='button'
                        aria-label='Main menu'
                        aria-expanded={isOpen}
                        sx={{
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          p: '8px',
                        }}
                        onClick={handleOpenDialog}
                        tabIndex={0}
                      >
                        <Icon icon='Menu' sx={{ width: 22, height: 22 }} />
                      </Box>
                    </>
                  )}
                </Stack>
              </Box>
            </Column>
          </Row>
        </Container>

        <Dialog
          className={styles.Root__dialog}
          ref={dialogRef}
          items={items}
          onClose={handleCloseDialog}
          trackingIds={ctaTrackingIds}
        />
      </Box>
    </CmsThemeContextProvider>
  );
};
