import { Server } from 'http';
import {
  ServerCodeLanguage,
  ExampleLanguage,
  IOSCodeLanguage,
  AndroidCodeLanguage,
  ReactNativeiOSCodeLanguage,
  SelectOption,
  FrontendCodeLanguage,
  ClientLanguage,
  ClientLanguageDashboard,
  ServerLanguageDashboard,
} from '../types';

export const LANGUAGE_OPTIONS: Array<SelectOption<
  ServerCodeLanguage | ExampleLanguage
>> = [
  {
    label: 'Curl',
    value: 'bash',
  },
  {
    label: 'Node',
    value: 'node',
  },
  {
    label: 'Python',
    value: 'python',
  },
  {
    label: 'Ruby',
    value: 'ruby',
  },
  {
    label: 'Java',
    value: 'java',
  },
  {
    label: 'Go',
    value: 'go',
  },
];

export const SERVER_LANGUAGES: Array<ServerCodeLanguage | ExampleLanguage> = [
  'bash',
  'node',
  'java',
  'ruby',
  'python',
  'go',
];

export const IOS_LANGUAGE_OPTIONS: Array<SelectOption<IOSCodeLanguage>> = [
  {
    label: 'Swift',
    value: 'swift',
  },
  {
    label: 'Objective-C',
    value: 'objectivec',
  },
];

export const ANDROID_LANGUAGE_OPTIONS: Array<SelectOption<
  AndroidCodeLanguage
>> = [
  {
    label: 'Kotlin',
    value: 'kotlin',
  },
  {
    label: 'Java',
    value: 'java',
  },
];

export const FRONTEND_LANGUAGE_OPTIONS: Array<SelectOption<
  FrontendCodeLanguage
>> = [
  {
    label: 'JavaScript',
    value: 'javascript',
  },
  {
    label: 'React',
    value: 'tsx',
  },
];

export const REACT_NATIVE_IOS_LANGUAGE_OPTIONS: Array<SelectOption<
  ReactNativeiOSCodeLanguage
>> = [{ label: 'Objective-C', value: 'objectivec' }];

export const IOS_LANGUAGES: Array<IOSCodeLanguage> = ['objectivec', 'swift'];

export const ANDROID_LANGUAGES: Array<AndroidCodeLanguage> = ['java', 'kotlin'];

export const REACT_NATIVE_IOS_LANGUAGES: Array<ReactNativeiOSCodeLanguage> = [
  'objectivec',
];

export const FRONTEND_LANGUAGES: Array<FrontendCodeLanguage> = [
  'javascript',
  'tsx',
];

export const CLIENT_LANGUAGE_MAPPING: Record<
  ClientLanguageDashboard,
  ClientLanguage
> = {
  JAVASCRIPT: 'javascript',
  JAVA_FE: 'java',
  KOTLIN: 'kotlin',
  OBJECTIVE_C: 'objectivec',
  REACT: 'tsx',
  SWIFT: 'swift',
  UNKNOWN_FE: 'javascript',
};

export const CLIENT_LANGUAGE_REVERSE_MAPPING: Record<
  ClientLanguage,
  ClientLanguageDashboard
> = {
  javascript: 'JAVASCRIPT',
  java: 'JAVA_FE',
  kotlin: 'KOTLIN',
  objectivec: 'OBJECTIVE_C',
  tsx: 'REACT',
  swift: 'SWIFT',
};

export const SERVER_LANGUAGE_MAPPING: Record<
  ServerLanguageDashboard,
  ServerCodeLanguage
> = {
  CURL: 'bash',
  GO: 'go',
  JAVA_BE: 'java',
  NODE: 'node',
  PYTHON: 'python',
  RUBY: 'ruby',
  UNKNOWN_BE: 'bash',
};

export const SERVER_LANGUAGE_REVERSE_MAPPING: Record<
  ServerCodeLanguage,
  ServerLanguageDashboard
> = {
  bash: 'CURL',
  go: 'GO',
  java: 'JAVA_BE',
  node: 'NODE',
  python: 'PYTHON',
  ruby: 'RUBY',
};
export const SERVER_LANGUAGE_OPTIONS: Array<SelectOption<
  ServerLanguageDashboard
>> = [
  {
    value: 'CURL',
    label: 'Curl',
  },
  {
    value: 'GO',
    label: 'Go',
  },
  {
    value: 'JAVA_BE',
    label: 'Java',
  },
  {
    value: 'NODE',
    label: 'Node',
  },
  {
    value: 'PYTHON',
    label: 'Python',
  },
  {
    value: 'RUBY',
    label: 'Ruby',
  },
];

export const CLIENT_LANGUAGE_OPTIONS: Array<SelectOption<
  ClientLanguageDashboard
>> = [
  { value: 'JAVASCRIPT', label: 'JavaScript' },
  { value: 'JAVA_FE', label: 'Java' },
  { value: 'KOTLIN', label: 'Kotlin' },
  { value: 'OBJECTIVE_C', label: 'Objective-C' },
  { value: 'REACT', label: 'React' },
  { value: 'SWIFT', label: 'Swift' },
];
