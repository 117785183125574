import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { RichTextSwitch } from 'src/containers';
import {
  Box,
  Column,
  Section,
  Stack,
  Row,
  Container,
} from 'src/components-v2/Layout';
import { CmsMediaSwitch } from 'src/components-v2/Media';
import { ILeftRightSectionProps } from './LeftRightSection';

const StandardLeftRightSection: React.FunctionComponent<ILeftRightSectionProps> = ({
  fields,
  heading,
  sys,
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Section
      sx={{
        backgroundColor: fields?.backgroundColor || 'inherit',
      }}
    >
      {heading && heading}
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row>
          <Column xs={22} xsOffset={1} smOffset={0} sm={12} lg={9} lgOffset={2}>
            {/* for mobile */}
            <Box sx={{ display: { xs: 'block', sm: 'none' }, mb: '3rem' }}>
              <MediaColumn assetSys={fields?.asset?.sys} fields={fields} />
            </Box>
            {/* for desktop */}
            <Box sx={{ height: '100%', display: { xs: 'none', sm: 'block' } }}>
              <LeftColumn
                textSys={sys}
                assetSys={fields?.asset?.sys}
                {...fields}
              />
            </Box>
          </Column>
          <Column
            xs={22}
            xsOffset={1}
            sm={11}
            smOffset={1}
            lg={10}
            lgOffset={1}
          >
            {/* for mobile */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <TextColumn textSys={sys} fields={fields} />
            </Box>
            {/* for desktop */}
            <Box sx={{ height: '100%', display: { xs: 'none', sm: 'block' } }}>
              <RightColumn
                textSys={sys}
                assetSys={fields?.asset?.sys}
                {...fields}
              />
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default StandardLeftRightSection;

const LeftColumn = ({ layout, textSys, assetSys, ...fields }) => {
  switch (layout) {
    case 'Asset left / Text right':
      return <MediaColumn assetSys={assetSys} fields={fields} />;
    case 'Text left / Asset right':
      return <TextColumn textSys={textSys} fields={fields} />;
    default:
      return null;
  }
};

const RightColumn = ({ layout, textSys, assetSys, ...fields }) => {
  switch (layout) {
    case 'Asset left / Text right':
      return <TextColumn textSys={textSys} fields={fields} />;
    case 'Text left / Asset right':
      return <MediaColumn assetSys={assetSys} fields={fields} />;
    default:
      return null;
  }
};

const TextColumn = ({ textSys, fields }) => {
  return (
    <Stack justifyContent='center' sx={{ height: '100%' }}>
      <Section
        component='header'
        sx={{
          bgColor: fields?.backgroundColor || 'inherit',
          '& h2, h3': {
            mb: { xs: '1.6rem', md: '0.8rem' },
          },
          '& ul': {
            '& li': {
              mb: { xs: 1, md: 2 },
            },
            '& li:last-child': {
              mb: '0',
            },
          },
          // add default bullets when no icon
          '& ul:has(svg)': {
            listStyleType: 'none',
            ml: '0',
          },
          // adds margin to top-level paragraph and unordered list
          '& p, ul': {
            mb: { xs: '3.2rem', sm: '4rem', lg: '5.6rem' },
          },
          // for decorative text
          '& p:has(.decorative-text)': {
            mb: { xs: 1, md: 2 },
            // for icons
            '& svg': {
              mt: { xs: '0.2rem', md: '0.4rem' },
              zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
            },
          },
          // we add this code to remove the trailing paragraph added by
          // contentful's rich text editor
          '& p:nth-last-of-type(1):empty': {
            display: 'none',
          },
          // we add this to remove margin from the second last child
          // so that content is actually vertically centered
          // however it's only applicable when there are more than two nodes
          ...(fields?.body?.content?.length > 2 && {
            '& a:nth-last-child(2), h2:nth-last-child(2), h3:nth-last-child(2), p:nth-last-child(2), ul:nth-last-child(2)': {
              mb: '0',
            },
          }),
          // in some cases we also have a last child that needs margin bottom removed
          '& p:last-child': {
            mb: '0',
          },
          '& .button-group': {
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '1.8rem', sm: '2rem', lg: '2.4rem' },
          },
          ...fields.styles,
        }}
        {...{
          bgColor: fields?.backgroundColor || 'white',
        }}
      >
        <RichTextSwitch
          sys={textSys}
          content={fields.body}
          nodes={{
            h2: {
              variant: 'h2',
              fieldId: 'body',
            },
            h3: {
              variant: 'h3',
              fieldId: 'body',
            },
            p: {
              variant: 'p',
              fieldId: 'body',
            },
          }}
        />
      </Section>
    </Stack>
  );
};

const MediaColumn = ({ fields, assetSys }) => {
  const { asset } = fields;
  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'center',
        '& video': {
          width: '100%',
          height: 'auto',
        },
        ...fields.styles,
      }}
    >
      <CmsMediaSwitch
        sys={assetSys}
        asset={asset?.fields}
        width={asset?.fields?.width}
        height={asset?.fields?.height}
        queryString={{
          xs: '?fm=webp&q=60&w=772',
          sm: '?fm=webp&q=60&w=1226',
          md: '?fm=webp&q=60&w=856',
          lg: `?fm=webp&q=60&w=${Math.ceil(asset?.fields?.width * 2)}`,
        }}
      />
    </Stack>
  );
};
