import React, { FC } from 'react';
import { OpeningsListDepartmentHeader as OpeningsListDepartmentHeaderProps } from './OpeningsListDepartmentHeader.types';
import cx from 'classnames';
import styles from './OpeningsListDepartmentHeader.module.scss';

const OpeningsListDepartmentHeader: FC<OpeningsListDepartmentHeaderProps> = ({
  department,
  description,
  thisDepartmentJobCount,
  isFiltered,
}) => (
  <div className='grid-container'>
    <div className={cx('grid-x', styles.header)}>
      <div className='cell medium-12'>
        <h4>{department}</h4>
      </div>
      <div className='cell medium-6 small-12'>
        <p>{description}</p>
      </div>
      <div className='cell medium-6 hide-for-small-only'>
        <p className={cx(styles.title)}>
          {thisDepartmentJobCount} open role
          {thisDepartmentJobCount !== 1 && 's'}
          {isFiltered && ' matching your search'}
        </p>
      </div>
    </div>
  </div>
);

export default OpeningsListDepartmentHeader;
