import { SearchPagePriorities, APIVersion } from '../types';

export * from './languages';

export const DOCS_BASE_URL = '/docs';
export const BETA_DOCS_BASE_URL = '/docs-beta';

export const COUNTRIES = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'United Kingdom',
    value: 'UK',
    isDisabled: true,
  },
  {
    label: 'Spain',
    value: 'ES',
    isDisabled: true,
  },
  {
    label: 'France',
    value: 'FR',
    isDisabled: true,
  },
  {
    label: 'Netherlands',
    value: 'NL',
    isDisabled: true,
  },
];

export const API_VERSIONS: Array<APIVersion> = [
  '2020-09-14',
  '2019-05-29',
  '2018-05-22',
  '2017-03-08',
];

export const SEARCH_PRIORITIES: SearchPagePriorities = {
  first: [],
  last: ['/docs/link/maintain-legacy-integration/'],
};

// while the offset anchor is set to 96, the top of element from getBoundingClientRect is 96.2185 for some elements.
// need to investigate this further.
export const ANCHOR_OFFSET = 100;

export const DASHBOARD_API_URL = 'https://api.dashboard.plaid.com/docs';
