export const intro = {
  title: 'Politique relative au développeur',
  effectiveDate: `Date d’entrée en vigueur : 31 janvier 2022<br><br>
    <em>La version 2019 de la Politique applicable aux Développeurs de Plaid restera en vigueur 
    jusqu’au 31 janvier 2022</em>. 
    `,
  description: [
    `La présente Politique applicable aux Développeurs (la « Politique »)
       prévoit des règles et des lignes directrices régissant l’accès ou l’utilisation 
       par nos développeurs (« vous », « votre »,« vos ») de l’API de Plaid, des sites web 
       (« Site »), des tableaux de bord, des outils connexes et d’autres produits ou 
       services (collectivement les « Services ») fournis par Plaid Inc., 
       et ses filiales, y compris Plaid Financial Ltd., et Plaid B.V., 
       (« Plaid », « nous »,« notre », « nos »). Toute violation de la Politique peut 
       entraîner une suspension ou une résiliation de votre accès aux Services et/ou 
       aux informations personnelles et financières des utilisateurs finaux fournies 
       par Plaid (« Données des Utilisateurs Finaux»)`,
    `En accédant et en utilisant les Services, vous vous engagez à respecter tous les 
      termes de la présente Politique. La Politique s'applique à chaque fois que vous 
      accédez ou que vous utilisez les Services. Si vous acceptez les termes de la 
      Politique au nom d’une organisation ou d’une entité, vous déclarez et garantissez 
      que vous êtes autorisés à vous engager au nom de cette organisation ou entité. 
      La présente Politique est importante ; nous vous prions de la lire attentivement. 
      `,
    `Nous pouvons être amenés à mettre à jour ou à modifier, à tout moment et à notre 
      discrétion, la présente Politique. Si nous apportons des modifications que nous 
      estimons importantes à la Politique, nous mettrons en œuvre des moyens raisonnables 
      pour vous en informer. Si vous n’acceptez pas une modification, votre seul recours 
      consiste à cesser d'accéder et d’utiliser les Services. `,
  ],
};

export const tableOfContentsTitle = 'Aller à la section :';

export const sections = [
  {
    title: 'Inscription',
    description: [
      `Pour vous inscrire aux Services, vous devez créer un compte (« Compte ») en 
        vous inscrivant sur notre site et en fournissant des informations exactes, 
        précises et complètes sur vous et votre utilisation des Services. Vous vous 
        engagez à ne pas fausser votre identité ou les informations que vous fournissez 
        pour votre Compte, et à mettre à jour les informations de votre Compte à tout moment. 
        Il est de votre responsabilité de conserver l'accès à votre Compte ; vous ne devez 
        jamais communiquer à un tiers les informations relatives à votre Compte, y compris 
        votre mot de passe de Tableau de Bord Plaid, ainsi que vos données d'authentification 
        API, y compris votre Numéro d’Identification Client (« ID Client ») et vos informations 
        confidentielles, ni permettre à toute autre application ou service d'agir en votre nom. 
        `,
    ],
  },
  {
    title: 'Conformité avec la Loi Applicable ',
    description: [
      `Lorsque vous utilisez les Services, vous devez vous conformer à toutes les lois applicables, 
        qu’elles soient locales, fédérales, nationales ou internationales. Vous confirmez également que 
        vous, votre entreprise, vos salariés, vos prestataires de services et toute autre personne agissant 
        en votre nom respectez toutes les lois applicables, en particulier celles applicables aux données 
        financières et à la protection des données, à la protection de la vie privée et à la sécurité des 
        données. `,
      `De plus, vous garantissez que vous, vos cadres, administrateurs, actionnaires, société mère directe 
        ou indirecte, filiales et sociétés affiliées : `,
      [
        `êtes et demeurerez en conformité avec l’ensemble des lois et règlementations applicables concernant 
          les importations, les sanctions, les exportations anti-boycott et le contrôle de la réexportation 
          (y compris toutes lois et règlementations qui s'appliquent à une société américaine, telles que les 
          Réglementations de l'Administration Américaine des Exportations, les Règlements Relatifs au Trafic 
          International d’Armes, et les programmes de sanctions économiques mis en œuvre par le Bureau du Contrôle des Avoirs Etrangers (OFAC)) ; 
          `,
        `n’êtes pas soumis, ni détenus par des tiers qui font l’objet de sanctions ou qui sont autrement identifiés sur une liste associée à des sanctions, 
          incluant sans s’y limiter les listes établies par le Gouvernement des États-Unis (telles que la Liste des Ressortissants Spécialement Désignés et 
            des Personnes Bloquées, établie par l’OFAC, la Liste des Entités établie par le Bureau de l'Industrie et de la Sécurité du Ministère Américain 
            du Commerce, et la liste de la section 231(d) de la CAATSA tenue par le département d'État Américain), le Conseil de Sécurité des Nations Unies, 
            le Royaume-Uni, l'Union Européenne ou ses États membres, ou toute autre autorité gouvernementale compétente ; et 
          `,
        `n’êtes pas impliqués et ne serez pas impliqués dans des activités qui pourraient conduire une autorité gouvernementale à engager une action 
           ou imposer des sanctions économique à votre ou notre encontre.
          `,
      ],
      `Les garanties précisées ci-dessus ne seront ni demandées ni fournies si celles-ci constituent une violation du Règlement de Blocage de l’UE, ou de 
        lois ou règlementations transposant le Règlement de Blocage dans les États membres de l’UE ou au Royaume-Uni, ou toute disposition similaire contre 
        le boycott, la discrimination ou le blocage prévue dans les lois locales applicables. 
        `,
      `Vous êtes personnellement chargés de veiller à ce que votre utilisation des Services soit en conformité avec toutes les lois qui vous sont applicables, 
        y compris et sans s’y limiter  les règles et lignes directrices de tout système ou réseau qui facilite les paiements et les exigences de sécurité, y 
        compris à la  Norme de sécurité informatique des données de l'industrie des cartes de paiements (PCI-DSS), si applicable. 
        `,
    ],
  },
  {
    title: 'Sécurité',
    description: [
      `Vous êtes personnellement responsable du maintien de la sécurité de votre nom d’utilisateur et mot de passe de Tableau de Bord Plaid, ainsi 
        que de vos données d'authentification API, y compris votre ID Client et vos informations confidentielles. Vous vous engagez à nous avertir 
        sans délai à l'adresse security@plaid.com en cas de toute violation de la sécurité ou d’utilisation non autorisée de votre Compte ou des 
        Données des Utilisateurs Finaux. Vous êtes tenus de ne jamais publier, distribuer ou partager votre ID Client ou vos informations confidentielles, 
        et de crypter ces informations lors du stockage et du transfert.
        `,
      `Vos systèmes et application(s) doivent traiter les Données des Utilisateurs Finaux de manière sécurisée. En ce qui concerne les Données des 
        Utilisateurs Finaux vous êtes tenus de suivre les meilleures pratiques de l'industrie et devez, à tout le moins, effectuer les opérations suivantes :
        `,
      [
        `Maintenir des mesures de sauvegardes administratives, techniques et physiques conçues pour garantir la sécurité, le respect de la vie privée et la confidentialité des Données des Utilisateurs Finaux. `,
        `Utiliser une méthode de cryptographie moderne et conforme aux normes du secteur pour le stockage ou la transmission des Données des Utilisateurs Finaux.`,
        `Maintenir des contrôles d'accès suffisants pour garantir que seules les personnes autorisées ayant un besoin professionnel aient accès aux Données des Utilisateurs Finaux.  `,
        `Surveiller vos systèmes pour éviter les accès non autorisés.`,
        `Remédier sans délai aux vulnérabilités.`,
        `Enregistrer et examiner tout événement suggérant un accès non autorisé.`,
        `Prévoir les incidents de sécurité et y répondre.`,
        `Se conformer aux règles et règlements applicables en ce qui concerne le type de données que vous traitez, telle que la Règle de Protection de la loi Gramm-Leach-Bliley.`,
      ],
    ],
  },
  {
    title: 'Stockage et Utilisation des Données',
    description: [
      `Toutes les Données des Utilisateurs Finaux en votre possession doivent être stockées en sécurité et conformément aux lois applicables. 
        `,
      `Le cas échéant, si vous utilisez les Données des Utilisateurs Finaux sous une forme anonymisée, votre utilisation de telles données anonymisées 
        doit être indiquée à l’Utilisateur Final clairement et de manière évidente en incluant une description de la façon dont vous utilisez ces Données 
        des Utilisateurs Finaux anonymisées.
        `,
    ],
  },
  {
    title: 'Désactivation de Compte',
    description: [
      `Une fois que vous cessez d’utiliser les Services conformément à un contrat conclu avec nous, vous pouvez désactiver votre Compte en suivant les 
        instructions sur le Site. Nous nous réservons le droit de désactiver votre Compte si vous cessez d’utiliser les Services pendant trois mois, si 
        le contrat applicable conclu avec nous est résilié ou a expiré, ou si cela est nécessaire en vertu de la loi applicable . Après la désactivation 
        de votre Compte, nous supprimerons vos accès aux Données des Utilisateurs Finaux associées à votre intégration. 
        `,
      `Même après la désactivation de Compte, et dans la limite autorisée par la loi applicable, nous pourrons conserver des informations collectées 
        à votre sujet pendant aussi longtemps qu’elles seront nécessaires à la réalisation des objectifs prévus dans notre politique/déclaration de 
        confidentialité, ou pour une période plus longue si  la loi applicable l’exige ou l'autorise. 
        `,
    ],
  },
  {
    title: 'Usages Interdits',
    description: [
      `Vous vous engagez personnellement à ne pas, et à ne pas aider ou permettre à un tiers de :`,
      [
        `vendre ou louer les Données des Utilisateurs Finaux à des sociétés de marketing ou à d'autres tiers ;`,
        `accéder aux Services ou aux Données des Utilisateurs Finaux ou les utiliser de manière illégale, abusive, 
          obscène, à des fins de harcèlement, de diffamation ou de fraude, ou dans un but illicite ; 
          `,
        `collecter et stocker les informations de connexion bancaire des utilisateurs finaux et/ou des Données 
          des Utilisateurs Finaux autres que celles qui sont nécessaires pour accéder ou utiliser les Services, 
          tel qu’autorisé par l’utilisateur final, tel que permis par Plaid ou en vertu du   de la loi applicable ; 
          `,
        `utiliser, divulguer ou conserver toute « information personnelle non publique » (telle que définie par 
            la loi Gramm-Leach-Bliley) ou « information personnelle » (telle que définie par la Loi Californienne sur 
                la Protection de la Vie Privée des Consommateurs) autrement qu’en stricte conformité avec la loi applicable;
          `,
        `utiliser, divulguer ou traiter toute « donnée à caractère personnel » (telle que définie par le Règlement (EU) 
          2016/679 (Règlement Général sur la Protection des Données)) autrement qu’en stricte conformité avec la loi applicable ; 
          `,
        `accéder ou utiliser les Services, ou accéder aux Données des Utilisateurs Finaux, les transmettre, traiter ou les 
          stocker en violation des lois sur la protection de la vie privée applicables ou d’une manière qui violerait un contrat 
          ou un accord avec l’utilisateur final concerné ; 
          `,
        `accéder aux Services ou les utiliser pour enfreindre un brevet, une marque déposée, un secret commercial, un droit 
          d’auteur, un droit à la publicité ou tout autre droit d’une personne ou d’un organisme ; 
          `,
        `accéder aux Services ou les utiliser à des fins autres que celles pour lesquelles ils sont fournis, y compris pour 
          une évaluation concurrentielle, l’espionnage, la création d’un service de substitution ou similaire à une partie des 
          Services, ou dans un autre but nuisible ; 
          `,
        `scanner ou tester (de manière manuelle ou automatique) la vulnérabilité de l’infrastructure de Plaid sans la permission 
          écrite préalable expresse de Plaid ; 
          `,
        `violer, désactiver, interférer avec ou contourner de toute autre manière toute mesure de sécurité ou d'authentification ou tout autre aspect des Services ; `,
        `surcharger, inonder ou spammer toute partie des Services ; 
          `,
        `créer des comptes de développeurs pour les Services par tout moyen autre que nos interfaces publiquement opérées (ex. en créant des comptes de développeur de 
            façon automatisée ou en nombre important) ; 
          `,
        `transférer, vendre sous licence ou autrement distribuer les Services ou les Données des Utilisateurs Finaux sans la permission écrite préalable expresse de Plaid ; 
          `,
        `déchiffrer, décompiler, désassembler, copier, procéder à l’ingénierie inversée ou tenter de dériver tout code source ou idée ou algorithme sous-jacents à toute partie
           des Services, sauf si la loi applicable le permet ; 
           `,
        `modifier, traduire ou autrement créer des travaux dérivés à partir des Services ;
          `,
        `accéder aux Services ou aux Données des Utilisateurs Finaux ou les utiliser d’une manière qui enfreint tout contrat entre vous ou l’utilisateur final et Plaid ; ou
          `,
        `accéder aux Services ou aux Données des Utilisateur Finaux ou les utiliser d’une manière qui enfreint une loi, un statut, une ordonnance ou règlementation applicables. 
          `,
      ],
    ],
  },
  {
    title: 'Suspension et Résiliation',
    description: [
      `Nous nous réservons le droit de retenir, refuser ou résilier l'accès aux Services et/ou aux Données des Utilisateurs Finaux en tout ou en partie 
        lorsque nous estimons qu’il y a eu un accès aux Services ou qu’ils ont été utilisés en violation de la présente Politique ou de tout autre contrat 
        Plaid, y compris les contrats de Plaid avec des partenaires tiers ou des fournisseurs de données de Plaid (un « Partenaire »), ou lorsque l’utilisation 
        créerait un risque de nuisance, y compris à la réputation de Plaid, à son infrastructure, ses données, aux Services, à un utilisateur final ou un Partenaire.
        `,
      `Nous mettrons en œuvre des moyens raisonnables pour vous informer par e-mail ou par un autre moyen si nous décidons de retenir, refuser ou résilier un accès 
        aux Services ou aux Données des Utilisateurs Finaux. Nous nous réservons le droit de suspendre ou de résilier immédiatement l'accès sans préavis si les circonstances 
        l'exigent, par exemple lorsque nous avons connaissance d'une activité qui constitue une violation de toute loi applicable ou lorsque nous déterminons, à notre seule 
        discrétion, que le préjudice est imminent. 
        `,
      `Plaid n’est pas responsable des dommages de toutes natures subis par vous ou par un tiers résultant de l’exercice par Plaid de ses droits en vertu de la présente 
        Politique ou de la loi applicable. 
        `,
    ],
  },
  {
    title: 'Signalement des Violations',
    description: [
      `Nous demandons à toute personne ayant connaissance d’une violation de la présente Politique de nous en informer immédiatement par e-mail à l’adresse legalnotices@plaid.com. 
        En ce qui concerne ces violations, nous nous réservons le droit de prendre toute mesure appropriée à notre seule discrétion -- y compris de signaler aux autorités policières 
        compétentes, aux régulateurs ou autres tiers compétents si nous soupçonnons qu’une activité ou conduite viole la loi.
        `,
    ],
  },
  {
    title: 'Accès Universel aux Données',
    description: [
      `Plaid veut promouvoir un écosystème financier ouvert dans lequel les utilisateurs finaux détiennent leurs propres données (ex., des données financières) et peuvent accorder 
        l'accès à celles-ci à des tiers. Dans la mesure où vous hébergez de telles données d’utilisateurs finaux non accessibles par Plaid, les parties conviennent à la demande de Plaid,
         de coopérer de bonne foi afin de négocier et de convenir d’un arrangement afin que Plaid puisse accéder aux données des utilisateurs finaux au nom des utilisateurs finaux.
        `,
    ],
  },
  {
    title: 'Divers',
    description: [
      `Le fait pour Plaid ou vous-même de ne pas exercer un droit prévu dans la présente Politique ne constitue aucunement un abandon de ce droit ou du droit de l’utiliser ultérieurement. 
        `,
      `Si une disposition de la présente Politique se révélerait être inapplicable ou invalide, cette disposition sera limitée ou annulée dans les limites du minimum nécessaire de sorte que 
        la Politique demeure par ailleurs pleinement effective, en vigueur et applicable.
        `,
    ],
  },
];
