export const defaultCRAFormIntro =
  'All of the fields below are required in order to submit a request. The information that you provide will only be used by Plaid Check to verify your identity and to process your request. We may not be able to comply with your request if we are unable to confirm your identity or connect the information you submit in your request with personal information in our possession. If we are unable to process your request, we will notify you via the email you provide.';

export const defaultCRAFormFields = {
  formHeader: 'Form',
  firstName: {
    label: 'First name',
  },
  lastName: {
    label: 'Last name',
  },
  email: {
    label: 'Email',
    error: 'Invalid email address',
  },
  phone: {
    label: 'Phone',
    error: 'Invalid phone number',
  },
  streetAddress: {
    label: 'Street address',
  },
  city: {
    label: 'City',
  },
  stateOrRegion: {
    label: 'State or region',
    error: 'Invalid 2-letter state/region',
  },
  zipCode: {
    label: 'ZIP code',
    error: 'Invalid ZIP code',
  },
  country: {
    label: 'Country',
    error: 'Country must be US',
  },
  ssnLastFour: {
    label: 'Last 4 digits of SSN (or ITIN)',
    error: 'Invalid last 4 digits of SSN (or ITIN)',
  },
  dateOfBirth: {
    label: 'Date of birth (MM/DD/YYYY)',
    error: 'Invalid date of birth',
  },
  location: {
    label: 'Location',
    locations: [
      {
        label: 'United States - California',
        value: 'United States - California',
      },
      {
        label: 'United States - Not in California',
        value: 'United States - Not in California',
      },
      {
        label: 'United Kingdom',
        value: 'United Kingdom',
      },
      {
        label: 'European Economic Area (EEA)',
        value: 'European Economic Area (EEA)',
      },
      {
        label: 'Canada',
        value: 'Canada',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  accountMask: {
    label: 'Last 4 digits of account number',
  },
  accountUsername: {
    label: 'Account username or login number (optional)',
  },
  accountNumber: {
    label: 'Account number (optional)',
  },
  applicationsConnected: {
    label: 'Apps or services connected via Plaid (optional)',
  },
  paymentInitiation: {
    label: "I've used Plaid to initiate payments from my account(s)",
  },
  supportingInformation: 'Supporting information',
  requestDetails: {
    label: 'Additional information about your request (optional)',
  },
  submitText: 'Submit',
  formSuccessMessage:
    'We’ve received your request. Please refresh this page if you would like to submit another request using this form.',
  formErrorMessage:
    'There was an error with sending your request. Please try again or contact us directly at ',
  disclaimerText:
    'By submitting this form, I certify that I am the consumer identified in this form and the information I have provided is accurate.',
  additionalInfoHeader: 'Plaid Check Legal Information',
  additionalInfoBullets: [
    [
      '/plaid-check-consumer-report/notices-of-consumer-rights',
      'Federal and State Notices of Consumer Rights',
    ],
    ['/plaid-check-consumer-report/privacy-policy', 'Privacy Policy'],
    [
      '/plaid-check-consumer-report/consumer-services-agreement',
      'Consumer Services Agreement',
    ],
  ],
};

export const defaultCRAFISearchFields = {
  financialInstitutions: {
    label: 'Search and select financial institution',
  },
  cannotFindFinancialInstitutions:
    'My financial institution is not included in search results.',
  otherFIs: {
    label: 'Other financial institution',
  },
  fiSearchLoading: 'Searching institutions...',
  fiSearchNoneFound: 'No institutions found',
  fiSearchInstruction: 'Start typing to search for your financial institution',
  institutionSearchAndSelectDisclaimer: {
    label:
      'Please provide the name of the bank or financial institution for the account that you connected to Plaid Check.',
  },
};
