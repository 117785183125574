import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Bold } from './Bold/';
import { Italic } from './Italic/';
import { Underline } from './Underline/';
import { Hyperlink } from './Hyperlink/';
import { Paragraph } from './Paragraph/';
import { UnorderedList } from './UnorderedList/';
import { OrderedList } from './OrderedList/';
import { Headings } from './Headings/';
import { RichTextShape } from 'src/lib/prop-types';
import EmbeddedEntrySwitch from './EmbeddedEntrySwitch';
import EmbeddedAssetSwitch from './EmbeddedAssetSwitch';

const propTypes = {
  content: RichTextShape.isRequired,
  classes: PropTypes.shape({
    aClassName: PropTypes.string,
    pClassName: PropTypes.string,
    ulClassName: PropTypes.string,
    olClassName: PropTypes.string,
    imgClassName: PropTypes.string,
    bClassName: PropTypes.string,
    iClassName: PropTypes.string,
    uClassName: PropTypes.string,
    divClassName: PropTypes.string,
    hClassName: PropTypes.string,
    h1ClassName: PropTypes.string,
    h2ClassName: PropTypes.string,
    h3ClassName: PropTypes.string,
    h4ClassName: PropTypes.string,
    h5ClassName: PropTypes.string,
    h6ClassName: PropTypes.string,
    figureClassName: PropTypes.string,
  }),
};

// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
const RichTextRenderer = ({ content, className = null, classes = {} }) => {
  return (
    <div className={className}>
      {documentToReactComponents(content, {
        renderMark: {
          ...Bold(classes),
          ...Italic(classes),
          ...Underline(classes),
        },
        renderNode: {
          ...Hyperlink(classes),
          ...Paragraph(classes),
          ...OrderedList(classes),
          ...UnorderedList(classes),
          ...Headings(classes),
          ...EmbeddedEntrySwitch(classes),
          ...EmbeddedAssetSwitch(classes),
        },
        renderText: (text) => {
          return text.split('\n').flatMap((text, i) => {
            return [i > 0 && <br />, text];
          });
        },
      })}
    </div>
  );
};

RichTextRenderer.propTypes = propTypes;

export default RichTextRenderer;
