import React, { FC } from 'react';
import { Stack, Box } from 'src/components-v2/Layout';

import { Btn } from 'src/components-v2/Inputs';
import { Pill } from '../Pill';
import { targetMap } from 'src/lib/constants';

type Props = {
  tabs: Array<
    | {
        fields?: { title?: string | null } | null;
        sys?: { id?: string | null } | null;
      }
    | null
    | undefined
  >;
  activeId?: string;
  backgroundColor?: string;
  onChange?: (id: string) => void;
  button?: any;
};

export const Filter: FC<Props> = (props) => {
  const { tabs, activeId, backgroundColor, onChange, button } = props;
  const { fields: buttonFields, sys } = button || {};

  return (
    <Stack
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      sx={{
        gap: { xs: '12px', sm: '16px', lg: '20px' },
      }}
    >
      {tabs.map((tab) => {
        const { fields, sys } = tab || {};

        if (!fields?.title) return null;

        const { id } = sys || {};

        return (
          <Box key={id}>
            <Pill
              isSelected={id === activeId}
              backgroundColor={backgroundColor}
              onClick={() => {
                onChange(id);
              }}
            >
              {fields.title}
            </Pill>
          </Box>
        );
      })}

      {buttonFields && (
        <Box sx={{ ml: { xs: 0, sm: 'auto' } }}>
          <Btn
            variant={buttonFields.appearance?.toLowerCase()}
            href={buttonFields.url}
            target={targetMap[buttonFields.target]}
            sys={sys}
            backgroundColor={backgroundColor}
          >
            {buttonFields.text}
          </Btn>
        </Box>
      )}
    </Stack>
  );
};
