import { Locales, StaticTemplates } from 'src/lib/constants';

export const enEuLocalizedCustomerStories = [
  {
    params: {
      id: Locales.EN_EU,
      index: 'alto',
      template: StaticTemplates.CUSTOMER_STORIES.ALTO,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'astra',
      template: StaticTemplates.CUSTOMER_STORIES.ASTRA,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'brigit',
      template: StaticTemplates.CUSTOMER_STORIES.BRIGIT,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'carvana',
      template: StaticTemplates.CUSTOMER_STORIES.CARVANA,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'drop',
      template: StaticTemplates.CUSTOMER_STORIES.DROP,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'metal',
      template: StaticTemplates.CUSTOMER_STORIES.METAL,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'placid-express',
      template: StaticTemplates.CUSTOMER_STORIES.PLACID_EXPRESS,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'prosper',
      template: StaticTemplates.CUSTOMER_STORIES.PROSPER,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'qapital',
      template: StaticTemplates.CUSTOMER_STORIES.QAPITAL,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'shift',
      template: StaticTemplates.CUSTOMER_STORIES.SHIFT,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'solo',
      template: StaticTemplates.CUSTOMER_STORIES.SOLO,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'varo',
      template: StaticTemplates.CUSTOMER_STORIES.VARO,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'wave',
      template: StaticTemplates.CUSTOMER_STORIES.WAVE,
    },
  },
];
