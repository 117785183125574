import React from 'react';
import PropTypes from 'prop-types';

import { JOB_DATA } from 'src/lib/constants.ts';

const propTypes = {
  categories: PropTypes.shape({
    team: PropTypes.string.isRequired,
  }),
};

const JobIntro = ({ categories: { team } }) => {
  const content = JOB_DATA.hasOwnProperty(team)
    ? JOB_DATA[team].description
    : '';
  return <p className='job-post__intro'>{content}</p>;
};

JobIntro.propTypes = propTypes;
export default JobIntro;
