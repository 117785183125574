import React, { useEffect, useState } from 'react';
import styles from './TutorialFeedback.module.scss';
import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';
import ChevronUpS1 from 'plaid-threads/Icons/ChevronUpS1';
import Button from 'src/components/Inputs/Button/Button';
import Select from 'src/components/Inputs/Select/Select';
import TextArea from 'src/components/Inputs/TextArea/TextArea';
// import TextArea from 'plaid-threads/TextArea';
import { TutorialKey } from './TutorialUtils';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { TUTORIAL_EVENTS } from 'src/lib/trackingEvents';

interface Props {
  tutorialKey: TutorialKey;
}

const defaultSelectObject = {
  label: '-- Pick one --',
  value: 'na',
};

const TutorialFeedback: React.FC<Props> = (props: Props) => {
  const track = useTrack();

  const [feedbackText, setFeedbackText] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [selectObject, setSelectObject] = useState(defaultSelectObject);
  const [submitted, setSubmitted] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const placeHoldersForValues = {
    good: 'Share more if you want, or just click "Submit"',
    bad: 'Sorry to hear that. How can we make it better?',
    bug: 'Oh, no. What happened?',
    neutral:
      "We're always open to suggestions. What did you want to share with us?",
    na: 'What did you want to share with us?',
  };
  useEffect(() => {
    setPlaceholder(placeHoldersForValues[selectObject.value]);
  }, [selectObject]);

  const handleSubmit = () => {
    const payload = {
      type: selectObject.value,
      text: feedbackText,
      tutorial: props.tutorialKey,
    };
    track({
      type: TUTORIAL_EVENTS.TUTORIAL_FEEDBACK,
      payload: payload,
    });
    setSubmitted(true);
  };

  const resetForm = () => {
    setSubmitted(false);
    setSelectObject(defaultSelectObject);
    setFeedbackText('');
  };

  const feedbackReadyToSubmit = () => {
    return (
      selectObject.value === 'good' ||
      selectObject.value === 'bad' ||
      (selectObject.value === 'bug' && feedbackText.length > 10) ||
      (selectObject.value === 'neutral' && feedbackText.length > 10)
    );
  };

  return (
    <>
      <div className={expanded ? styles.expandedWrapper : styles.smallWrapper}>
        <button onClick={(e) => setExpanded(!expanded)}>
          <div className={styles.header}>
            {expanded ? (
              <ChevronUpS1 />
            ) : (
              <ChevronDownS1 className={styles.showOnHover} />
            )}
            <h5>Feedback?</h5>
          </div>
        </button>
        {expanded &&
          (!submitted ? (
            <>
              <p>What'd you think of this tutorial?</p>
              <Select
                className={styles.selectFix}
                id='lang1'
                name='feedbackSelect'
                label={selectObject.label}
                onChange={(e) => setSelectObject(e)}
                options={[
                  defaultSelectObject,
                  {
                    label: 'It was great!',
                    value: 'good',
                  },
                  {
                    label: 'It was bad!',
                    value: 'bad',
                  },
                  {
                    label: 'I encountered a bug.',
                    value: 'bug',
                  },
                  {
                    label: 'I actually had something else to share',
                    value: 'neutral',
                  },
                ]}
                value={selectObject.value}
              />

              <TextArea
                className={styles.textArea}
                id='feedback-input'
                value={feedbackText}
                label='Feedback'
                placeholder={placeholder}
                onChange={(e) => setFeedbackText(e.currentTarget.value)}
                name='FeedbackTextArea'
              />
              <Button
                disabled={!feedbackReadyToSubmit()}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </>
          ) : (
            <div className={styles.thankYouWrapper}>
              {' '}
              <p>
                Thanks for your feedback. This tutorial is an experimental
                feature, so we're really interested in what you have to say.
              </p>
              <Button inline onClick={resetForm}>
                Submit more
              </Button>
            </div>
          ))}
      </div>
    </>
  );
};

TutorialFeedback.displayName = 'TutorialFeedback';
export default TutorialFeedback;
