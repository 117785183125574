import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  lists: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const JobList = ({ lists }) => (
  <div className='job-post__lists'>
    {lists.map(({ text, content }, index) => {
      // content from Lever includes raw HTML
      const contentWithMarkup = { __html: content };
      return (
        <div key={`list-${index}`} className='job-post-list'>
          <h3 className='job-post-list__heading'>{text}</h3>
          <ul
            className='job-post-list__content'
            dangerouslySetInnerHTML={contentWithMarkup}
          ></ul>
        </div>
      );
    })}
  </div>
);

JobList.propTypes = propTypes;
export default JobList;
