import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { toKebabCase } from 'src/lib/utils';
import { JOB_DATA } from 'src/lib/constants';
import { CareersPageTemplate } from 'src/templates';
import {
  OpeningsListRow,
  OpeningsListDepartmentHeader,
} from 'src/components/Careers';
import styles from 'src/components/Careers/OpeningsList.module.scss';
import Image from 'src/components/Image';

import Grid from 'plaid-threads/Grid';
import Column from 'plaid-threads/Column';
import FiveHundred from 'src/pages/500';

const DepartmentOpenings = ({ jobs, department }) => {
  const listingCount = {};

  return jobs.map((job, idx) => {
    const isLastItem = idx === jobs.length - 1;

    const jobTitle = toKebabCase(job.text);

    listingCount[jobTitle] = (listingCount[jobTitle] || 0) + 1;

    const id = listingCount[jobTitle]
      ? jobTitle
      : `${jobTitle}-${listingCount[jobTitle]}`;

    return (
      <OpeningsListRow
        {...job}
        id={id}
        department={department}
        isLastItem={isLastItem}
        key={`openings-list-row-${idx}`}
      />
    );
  });
};

const CareersBlankSlate = () => (
  <Grid>
    <Column small={12} large={4}>
      <Image
        src={'/assets/img/careers/careers-no-results@2x.png'}
        alt='No listings matched your search criteria'
      />
    </Column>
    <Column small={12} large={8} className={styles.blankSlateText}>
      <h4>Oops!</h4>
      <p>
        We're sorry, your search didn't return any results. Adjust your search
        criteria and try again.
      </p>
    </Column>
  </Grid>
);

const OpeningsPage = ({ fields }) => {
  const router = useRouter();
  const [role, setRole] = useState(null);

  const totalJobCount = useRef(0);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    setRole(router.query.role);
    totalJobCount.current = 0;
  }, [router.query.role]);

  const filterResults = (e) => {
    setRole(e.target.value);
    totalJobCount.current = 0;
  };

  if (fields.hasError) {
    return <FiveHundred />;
  }

  const jobDepartments = Object.keys(fields?.jobs);

  const departmentsMatchingSearch = jobDepartments.filter(
    (department) =>
      fields?.jobs[department].jobs.some((job) =>
        job.text.match(new RegExp(role as string, 'i')),
      ) && fields?.jobs[department].jobs.length > 0,
  );

  const getJobsMatchingSearch = ({ department }) => {
    return fields?.jobs[department].jobs.filter((job) =>
      job.text.match(new RegExp(role as string, 'i')),
    );
  };

  totalJobCount.current = departmentsMatchingSearch.reduce(
    (total, department) => {
      return total + getJobsMatchingSearch({ department }).length;
    },
    0,
  );

  return (
    <CareersPageTemplate
      className='department-location-page'
      fields={fields}
      filterResults={filterResults}
      role={role}
      totalJobCount={totalJobCount}
      noIndex={totalJobCount.current === 0}
    >
      <div className={styles.listContainer}>
        {departmentsMatchingSearch.length > 0
          ? departmentsMatchingSearch.map((department, idx) => {
              const departmentJobs = getJobsMatchingSearch({ department });
              const thisDepartmentJobCount = departmentJobs.length;
              const description = JOB_DATA[department]?.description;
              return (
                <React.Fragment key={`openings-list-job-group-${idx}`}>
                  <OpeningsListDepartmentHeader
                    department={department}
                    thisDepartmentJobCount={thisDepartmentJobCount}
                    isFiltered={role}
                    description={description}
                    key={`openings-list-department-${idx}`}
                  />
                  <DepartmentOpenings
                    jobs={departmentJobs}
                    department={department}
                  />
                </React.Fragment>
              );
            })
          : pageLoaded && <CareersBlankSlate />}
      </div>
    </CareersPageTemplate>
  );
};

export default OpeningsPage;
