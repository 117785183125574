import Grid from 'plaid-threads/Grid';
import Column from 'plaid-threads/Column';
import Image from 'src/components/Image';
import React, { ReactElement } from 'react';

import styles from './SearchBar.module.scss';

const defaultTitle = 'Oops!';
const defaultDescription =
  "We're sorry, your search didn't return any results. Adjust your search criteria and try again.";

interface NoResults {
  title?: string;
  description?: string;
}

const NoResults = ({
  title = defaultTitle,
  description = defaultDescription,
}: NoResults): ReactElement => (
  <Grid className={styles.noResults}>
    <Column small={12} large={4}>
      <Image
        src='/assets/img/careers/careers-no-results@2x.png'
        alt='No listings matched your search criteria'
      />
    </Column>
    <Column small={12} large={8} className={styles.noResultText}>
      <h4>{title}</h4>
      <p>{description}</p>
    </Column>
  </Grid>
);

export default NoResults;
