import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Grid, Column, Image, imageAdapter } from 'src/components/Layout';
import { PreviewError } from 'src/components/Feedback';

import styles from 'src/templates/Resources/ArticleTemplate/ArticleTemplate.module.scss';

const propTypes = {
  fields: PropTypes.object,
};

const ImagePreview = ({ fields }) => {
  try {
    return (
      <Grid useThreadsGrid>
        <Column small={12}>
          <Image
            classes={{
              figureClassName: cx(styles.figure, {
                [styles.fullWidth]: fields.fullWidth,
              }),
              imgClassName: styles.image,
            }}
            {...imageAdapter(fields)}
          />
        </Column>
      </Grid>
    );
  } catch (e) {
    return <PreviewError error={e} />;
  }
};

ImagePreview.propTypes = propTypes;

export default ImagePreview;
