import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { useInEu } from 'src/hooks';
import ConditionalWrapper from 'src/components/helpers/ConditionalWrapper';
import styles from './Banner.module.scss';
import { Link } from 'src/components/Inputs';
import NavigationCloseIcon from 'src/components/SVGs/Icons/NavigationCloseIcon';

const bannerPropTypes = {
  bannerTitle: PropTypes.string.isRequired,
  bannerText: PropTypes.string.isRequired,
  bannerLink: PropTypes.string,
  bannerName: PropTypes.string.isRequired,
  isClosable: PropTypes.bool,
  isHiddenInEEA: PropTypes.bool,
};

const bannerStates = Object.freeze({
  CLOSED: 'closed',
  CLOSING: 'closing',
  DEFAULT: '',
  OPEN: 'open',
});

const Banner = ({
  bannerTitle,
  bannerText,
  bannerLink = '',
  bannerName,
  isClosable = false,
  isHiddenInEEA = false,
}) => {
  const [bannerState, setBannerState] = useLocalStorage(
    `${bannerName}State`,
    bannerStates.DEFAULT,
  );

  const bannerEl = useRef(null);

  const closeBanner = () => {
    setBannerState(bannerStates.CLOSING);
    bannerEl.current.addEventListener('transitionend', () => {
      setBannerState(bannerStates.CLOSED);
    });
  };

  useEffect(() => {
    if (bannerState === bannerStates.DEFAULT) {
      setBannerState(bannerStates.OPEN);
    }
  }, [bannerState, setBannerState]);

  const isInEu = useInEu();

  if (bannerState === bannerStates.CLOSED) {
    return null;
  }

  if (isHiddenInEEA && isInEu) {
    return null;
  }

  return (
    <div
      className={cx(styles.content, {
        [styles.isClosing]: bannerState === bannerStates.CLOSING,
      })}
      ref={bannerEl}
    >
      <ConditionalWrapper
        condition={bannerLink}
        wrapper={(children) => {
          return (
            <Link href={bannerLink}>
              <a
                className={styles.linkWrapper}
                aria-label={`${bannerTitle} - ${bannerText}`}
              >
                {children}
              </a>
            </Link>
          );
        }}
      >
        <div className={styles.flag}>{bannerTitle}</div>
        <p className={styles.text}>{bannerText}</p>
      </ConditionalWrapper>
      {isClosable && (
        <button
          className={styles.closeButton}
          onClick={closeBanner}
          type='button'
        >
          <span aria-hidden='true' alt=''>
            <NavigationCloseIcon />
          </span>
        </button>
      )}
    </div>
  );
};

Banner.propTypes = bannerPropTypes;

export default Banner;
