import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { MetaTags } from 'src/components/MetaTags';
import { Unless } from 'src/components/helpers';
import { ConsentManager } from 'src/containers/ConsentManager';
import { MainNav } from 'src/components/navigation';
import { MainMenu } from 'src/sections/Navigation';
import Footer from 'src/components/footer';
import { MainNavigationBar } from 'src/components-v2/MainNavigationBar';

// helpers
import { LocaleContext } from 'src/contexts';
import { Locales, CmsThemes } from 'src/lib/constants';

import { getFirstElementFields } from '../utils';

// dynamically import the scripts component which invokes all of our js dependencies
const DocsScripts = dynamic(
  () => {
    return import('./DocsScripts');
  },
  {
    ssr: false,
  },
);

// For legal, developer policy, terms-of-use, DPR forms
const DocsTemplate = ({
  children,
  locale = Locales.EN_US,
  cmsTheme = 'web 2.0',
  sx,
  ...props
}) => {
  const { route } = useRouter();
  const { fields } = getFirstElementFields(children?.props?.components) ?? {};

  return (
    <LocaleContext.Provider value={locale}>
      <MetaTags {...props} />

      {cmsTheme === CmsThemes.WEB3 ? (
        <MainNavigationBar
          // @ts-ignore
          defaultBackgroundColor={sx?.bgcolor}
          fields={fields}
          skipLinkAnchor='#docsArticle'
        />
      ) : locale === Locales.EN_GB || locale === Locales.EN_EU ? (
        <MainNav {...props} skipLinkAnchor='#docsArticle' />
      ) : (
        <MainMenu />
      )}

      {children}
      <Unless condition={props && props['exclude-footer']}>
        <Footer />
      </Unless>
      <ConsentManager />
      <DocsScripts route={route} />
    </LocaleContext.Provider>
  );
};
export default DocsTemplate;
