export const getSlideStyles = (isHovered) => {
  return {
    height: '100%',
    willChange: isHovered ? 'filter' : 'auto',
    '& img': {
      border: 1,
      borderColor: 'transparent',
      borderRadius: '16px',
      mb: { xs: '1.9rem', md: '3.4rem' },
      width: '100%',
    },
    '& h5': {
      mb: { xs: '1.3rem', md: '2.8rem' },
    },
    '& p': {
      mb: '0.8rem',
    },
    '& button': {
      mt: 'auto',
    },
  };
};
