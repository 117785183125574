import React, { useContext, FC } from 'react';
import { trackEvent } from 'public/assets/js/analytics';
import { TrackFunction, ContextValue } from './AnalyticsContextTypes';
import useAdBlockDetector from '../../hooks/useAdBlockDetector';
import { isProduction } from 'src/lib/constants';

// Actions instrumented for analytics tracking.
// We can consider moving to a separate file if needed.
export const trackingActions = {
  BUTTON_CLICKED: 'BUTTON_CLICKED',
  CONTACT_FORM_SUCCESS: 'CONTACT_FORM_SUCCESS',
};

/**
 * Navigate the page
 * @param node - DOM node
 * @returns void
 */
const navigatePage = (node: HTMLElement) => {
  const href = node.getAttribute('href');
  const target = node.getAttribute('target');
  if (href) {
    if (target) {
      return window.open(href, target);
    }
    window.location.href = href;
  } else {
    throw new Error('no href specified');
  }
};

// defaultValue used for context instances not wrapped by Provider,
// and required to prevent TypeError and TS compile errors.
// https://reactjs.org/docs/context.html#reactcreatecontext
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/34854
const AnalyticsContext = React.createContext<ContextValue>({
  track: () => {
    return undefined;
  },
});

export const AnalyticsProvider: FC = ({ children }) => {
  const hasAdBlocker = useAdBlockDetector();

  const track: TrackFunction = (action, callback = null, retries = 0) => {
    const analyticsInitialized = window?.analytics?.initialized || false;

    try {
      if (action.type === trackingActions.BUTTON_CLICKED) {
        trackButtonClick();
      } else if (callback != null) {
        trackEventWithCallback();
      } else {
        // We can retry if necessary, because nothing is blocking
        if (!analyticsInitialized) {
          if (retries < 5) {
            setTimeout(() => {
              return track(action, callback, retries + 1);
            }, 500);
            return;
          } else {
            if (!isProduction) {
              // eslint-disable-next-line no-console
              console.log('Analytics event gave up after retries');
            }
            return;
          }
        }
        if (!isProduction) {
          // eslint-disable-next-line no-console
          console.log('ANALYTICS EVENT: ', action.type, action.payload);
        }
        trackEvent({
          event: action.type,
          payload: action.payload,
        });
      }
    } catch (e) {
      // log an error to avoid future confusion when debugging this
      console.error(e); // eslint-disable-line
    }

    /**
     * Track a button click event -- add some special logic here
     */
    function trackButtonClick() {
      // use trackingId if provided as event name for tracking CTA button clicks
      // otherwise we aren't tracking button clicks
      if (action.payload?.trackingId) {
        const {
          trackingId,
          event: mouseEvent,
          currentTarget,
          ...payload
        } = action.payload;

        // We will redirect to intended href after handling tracking
        mouseEvent.preventDefault();

        // Because some ad blockers might cause our trackEvent call to fail
        // we don't want to risk the button not working
        analyticsInitialized && !hasAdBlocker
          ? trackEvent({
              event: trackingId,
              payload,
              callback: () => {
                navigatePage(currentTarget);
              },
            })
          : // Redirect even if tracking is disabled / opted-out
            navigatePage(currentTarget);
      }
    }

    /**
     * Track an event that has a callback. Like the button logic, we won't
     * block the callback from happening if tracking fails.
     */
    function trackEventWithCallback() {
      analyticsInitialized && !hasAdBlocker
        ? trackEvent({
            event: action.type,
            payload: action.payload,
            callback,
          })
        : // Run the callback even if tracking is disabled / opted-out
          callback();
    }
  };

  const value = {
    track,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useTrack = (): TrackFunction => {
  return useContext(AnalyticsContext).track;
};

export default AnalyticsContext;
