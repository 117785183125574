import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { VideoPlayerProps } from './VideoPlayer.types';
import { Section } from 'src/components-v2/Layout';

// helpers
import { EMBEDDED_VIDEO_EVENTS } from 'src/lib/trackingEvents';
import { getUrlDomain, sanitizeVideoUrl } from 'src/lib/url-helpers';
import { Lazy } from 'src/components-v2/Helpers';

// contexts
import { useTrack } from 'src/contexts/AnalyticsContext';

const supportedVideoProviderDomains = [
  'facebook.com',
  'youtube.com',
  'vimeo.com',
  'dailymotion.com',
];

const VideoPlayer = React.forwardRef(
  (
    {
      isLazy = true,
      url = '',
      width = '100%',
      height = '100%',
      light = '',
      playing = false,
      playIcon = null,
      muted = false,
      sx,
      tabIndex,
    }: VideoPlayerProps,
    ref,
  ) => {
    const track = useTrack();
    const [player, setPlayer] = useState(null);

    useEffect(() => {
      if (!player || typeof tabIndex !== 'number') {
        return;
      }
      // find the iframe inside of wrapper div
      const iframe = player.wrapper && player.wrapper.querySelector('iframe');
      if (iframe) {
        iframe.tabIndex = tabIndex;
        iframe.contentWindow.focus();
      }
    }, [player, playing, tabIndex]);

    if (!supportedVideoProviderDomains.includes(getUrlDomain(url))) return null;
    const sanitizedUrl = sanitizeVideoUrl(url);

    return (
      <Section
        ref={ref}
        component='figure'
        sx={{
          position: 'relative',
          pt: '56.25%',
          height: '0',
          overflow: 'hidden',
          ...sx,
        }}
      >
        <Lazy isLazy={isLazy} height={height}>
          <ReactPlayer
            data-testid='video-embed'
            style={{ position: 'absolute', top: '0', left: '0' }}
            url={sanitizedUrl}
            playing={playing}
            width={width}
            height={height}
            light={light}
            controls={true}
            pip={true}
            stopOnUnmount={false}
            playIcon={playIcon}
            playsinline={true}
            muted={muted}
            onReady={(player) => {
              setPlayer(player);
            }}
            onPlay={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_PLAY,
                payload: { sanitizedUrl },
              });
            }}
            onPause={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_PAUSE,
                payload: { sanitizedUrl },
              });
            }}
            onSeek={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_SEEK,
                payload: { sanitizedUrl },
              });
            }}
            onEnded={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_ENDED,
                payload: { sanitizedUrl },
              });
            }}
            onError={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_ERROR,
                payload: { sanitizedUrl },
              });
            }}
          />
        </Lazy>
      </Section>
    );
  },
);

export default VideoPlayer;
