import React, { FC } from 'react';
import { Stack } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

const backgroundStyles = {
  left: {
    left: { xs: '-60%', sm: '-70%', md: '-70%', lg: '-85%', xl: '-70%' },
    bottom: { xs: '-85%', sm: '-130%', md: '-90%', lg: '-115%' },
    transform: 'rotate(18deg)',
  },
  center: {
    left: { xs: '-35%', sm: '-25%' },
    bottom: { xs: '-100%', sm: '-125%', md: '-110%', lg: '-125%', xl: '-122%' },
    transform: 'rotate(0deg)',
  },
  right: {
    right: { xs: '-60%', lg: '-70%', xl: '-50%' },
    bottom: { xs: '-85%', sm: '-120%', md: '-90%', lg: '-120%' },
    transform: 'rotate(-18deg)',
  },
};

interface IStat {
  children: React.ReactNode;
  backgroundImagePosition?: 'left' | 'center' | 'right';
  sx?: SxProps<Theme>;
}

const Stat: FC<IStat> = ({ children, backgroundImagePosition, sx }) => {
  return (
    <Stack
      sx={{
        px: { xs: '3.2rem', sm: '2.4rem', lg: '6.4rem' },
        pt: { xs: '2.8rem', sm: '3.5rem', lg: '8.8rem' },
        pb: { xs: '2.8rem', sm: '3.6rem', lg: '6.4rem' },
        position: 'relative',
        minHeight: 184,
        zIndex: 1,
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '150%',
          height: '150%',
          ...backgroundStyles[backgroundImagePosition],
          backgroundImage:
            'url("https://images.ctfassets.net/ss5kfr270og3/2IxEFnFAHtktv2nPUf5S1w/0a541f86aef399885174a4be236f9a9a/stats_textured_background.png?fm=webp&w=1314&q=1")',
          backgroundRepeat: 'no-repeat',

          backgroundSize: '100%',
          zIndex: -1,
        },
        ...sx,
      }}
      direction='column'
      justifyContent='center'
    >
      {children}
    </Stack>
  );
};

export default Stat;
