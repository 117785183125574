import React from 'react';

const ProblemLabelIcon = (props) => {
  return (
    <svg height={16} width={16} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle cx={8} cy={8} fill='#ffd7dc' r={8} />
        <path d='M4 4h8v8H4z' />
        <g fill='#ec4d4f'>
          <path d='M6.97 8h1.3v1.5h-1.3z' fillRule='nonzero' />
          <path d='M10.568 6.388c0 1.524-.996 2.724-3.6 2.664V7.84c1.632.036 2.1-.612 2.1-1.416 0-.744-.516-1.224-1.272-1.224-.66 0-1.056.288-1.296.684H5C5.336 4.72 6.488 4 7.784 4c1.584 0 2.784.876 2.784 2.388zm-2.844 5.544c-.612.06-.936-.24-1.02-.672s.168-.828.78-.888.936.24 1.02.672-.168.828-.78.888z' />
        </g>
      </g>
    </svg>
  );
};

export default ProblemLabelIcon;
