import React, { FC } from 'react';
import { Grid, Column } from 'src/components/Layout/Grid';
import cx from 'classnames';
import ReactPlayer from 'react-player';

// contexts
import { useTrack } from 'src/contexts/AnalyticsContext';

// helpers
import { EMBEDDED_VIDEO_EVENTS } from 'src/lib/trackingEvents';
import { VideoEmbed as VideoEmbedProps } from './VideoEmbed.types';
import styles from './VideoEmbed.module.scss';
import { getUrlDomain, sanitizeVideoUrl } from 'src/lib/url-helpers';
import { ConditionalWrapper } from 'src/components/helpers';
import { Lazy } from 'src/components-v2/Helpers';

const supportedVideoProviderDomains = [
  'facebook.com',
  'youtube.com',
  'vimeo.com',
  'dailymotion.com',
];

const VideoEmbed: FC<VideoEmbedProps> = ({
  isLazy = false,
  caption = '',
  className = '',
  useThreadsGrid = false,
  url,
  isInModal = false,
}) => {
  const track = useTrack();
  if (!supportedVideoProviderDomains.includes(getUrlDomain(url))) return null;
  // daily motion fix (cannot include '?playlist=...')
  const editedUrl = sanitizeVideoUrl(url);

  return (
    <figure className={cx(styles.videoEmbed, className)}>
      <Lazy isLazy={isLazy}>
        <ConditionalWrapper
          condition={!isInModal}
          wrapper={(children) => {
            return (
              <Grid useThreadsGrid={useThreadsGrid}>
                <Column>{children}</Column>
              </Grid>
            );
          }}
          alternateWrapper={(children) => {
            return <>{children}</>;
          }} // because the grid and column mess up the modal styling
        >
          <ReactPlayer
            url={editedUrl}
            className={styles.player}
            width={null}
            height={null}
            controls={true}
            pip={true}
            data-testid='video-embed'
            onPlay={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_PLAY,
                payload: { editedUrl },
              });
            }}
            onPause={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_PAUSE,
                payload: { editedUrl },
              });
            }}
            onSeek={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_SEEK,
                payload: { editedUrl },
              });
            }}
            onEnded={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_ENDED,
                payload: { editedUrl },
              });
            }}
            onError={() => {
              track({
                type: EMBEDDED_VIDEO_EVENTS.ON_ERROR,
                payload: { editedUrl },
              });
            }}
          />
          {caption && (
            <figcaption dangerouslySetInnerHTML={{ __html: caption }} />
          )}
        </ConditionalWrapper>
      </Lazy>
    </figure>
  );
};

export default VideoEmbed;
