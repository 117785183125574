import { partnerPageFooterAdapter } from 'src/components/PartnerDirectory/PartnerPage/Footer/adapters.js';

// NOTE: This adapter is a super thin wrapper for the Partner Directory
// Footer adapter, since the desired functionality for both is the
// same at present. Later, it may make sense to split them, but it seems
// premature to do so at this time.

export const landingPageFooterAdapter = (props) => {
  return partnerPageFooterAdapter(props);
};
