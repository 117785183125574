import React, { useMemo } from 'react';
import classNames from 'classnames';

import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Container, Section, Row, Column } from 'src/components-v2/Layout';
import { CmsSectionalHeader } from 'src/sections/Header';

import styles from './CmsSimpleStatCards.module.scss';

const CmsSimpleStatCards = ({ fields, sys }) => {
  const { header, backgroundColor, simpleStatCards } = fields || {};

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const bgColor = backgroundColor?.toLowerCase() || 'transparent';
  const isDarkMode = bgColor === ('black' || 'black1000');

  const columnProps = useMemo(() => {
    if (simpleStatCards.length === 1) {
      return {
        xs: 24,
        sm: 16,
        xl: 6,
      };
    } else if (simpleStatCards.length === 2) {
      return {
        xs: 24,
        sm: 16,
        xl: 8,
      };
    } else if (simpleStatCards.length === 3) {
      return {
        xs: 24,
        xl: 14,
      };
    }

    return {
      xs: 24,
      xl: 20,
    };
  }, [simpleStatCards]);

  if (!simpleStatCards?.length) return null;

  return (
    <Section
      className={styles.section}
      sx={{ backgroundColor: bgColor, ...fields?.styles }}
    >
      {header && (
        <div className={styles.header}>
          <CmsSectionalHeader sys={header?.sys} fields={header?.fields} />
        </div>
      )}

      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row
          className={classNames(styles.row, {
            [styles.row_1]: simpleStatCards.length === 1,
            [styles.row_2]: simpleStatCards.length === 2,
            [styles.row_3]: simpleStatCards.length === 3,
            [styles.row_4]: simpleStatCards.length >= 4,
          })}
        >
          <Column {...columnProps}>
            <div className={styles.body}>
              {simpleStatCards?.map((card) => {
                if (!card) return null;

                const { fields, sys } = card;
                const { body } = fields;

                return (
                  <div key={sys?.id} className={styles.item}>
                    <RichTextSwitch
                      sys={sys}
                      content={{
                        ...body,
                        content: body?.content?.filter((data) => {
                          return data.nodeType !== 'embedded-entry-block';
                        }),
                      }}
                      nodes={{
                        h3: {
                          variant: 'h5',
                          component: 'h3',
                          fieldId: 'body',
                          sx: {
                            color: isDarkMode ? 'white' : 'black1000',
                            fontWeight: '600',
                          },
                        },
                        p: {
                          variant: 'p',
                          fieldId: 'body',
                          sx: {
                            color: isDarkMode ? 'black300' : 'black700',
                            fontSize: {
                              md: '1.4rem',
                            },
                            fontWeight: '600',
                          },
                        },
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default CmsSimpleStatCards;
