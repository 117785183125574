import React, { FC } from 'react';
import { RichTextContent } from 'contentful';

import { PageHeaderWithForm } from 'src/components/headers/PageHeaderWithForm';
import { UnlockedContent } from 'src/components/GatedContent';
import Cta from 'src/components/cta';
import { PageHeaderFormTypeFields } from './adapters';
import { FormTypes } from 'src/lib/constants';

interface VideoAsset {
  src: string;
  type: string;
}

interface GatedContentProps {
  confirmationContent?: RichTextContent;
  gateTypeIsPageHeader: boolean;
  gateTypeIsFormCta: boolean;
  hasOnPagePostSubmitContent: boolean;
  redirectOnSubmit: boolean;
  redirectUrl?: string;
  videoContent: {
    formats: VideoAsset[];
  };
  isBodyForm?: boolean;
  title?: string;
  marketoKey: number;
  gateFields?: PageHeaderFormTypeFields;
}

const GatedContent: FC<GatedContentProps> = ({
  confirmationContent,
  gateTypeIsPageHeader,
  gateTypeIsFormCta,
  hasOnPagePostSubmitContent,
  videoContent,
  isBodyForm = false,
  marketoKey,
  title = '',
  gateFields = null,
  redirectOnSubmit,
  ...props
}) => {
  return (
    <>
      {/* @TODO: Revisit if refactoring existing Page Header impl / tech debt
       * https://jira.plaid.com/browse/SITE-1926
       * https://jira.plaid.com/browse/SITE-2568
       */}
      {gateTypeIsPageHeader && (
        <PageHeaderWithForm
          hasOnPagePostSubmitContent={hasOnPagePostSubmitContent}
          postSubmitContent={
            hasOnPagePostSubmitContent ? (
              <UnlockedContent
                confirmationContent={confirmationContent}
                videoContent={videoContent}
              />
            ) : null
          }
          shouldForward={redirectOnSubmit}
          redirectOnSubmit={redirectOnSubmit}
          {...props}
          formType={FormTypes.Gated} // we overwrite the formType possibly set by the adapter so we handle gated content correctly
        />
      )}

      {gateTypeIsFormCta && (
        <Cta
          hasOnPagePostSubmitContent={hasOnPagePostSubmitContent}
          postSubmitContent={
            hasOnPagePostSubmitContent ? (
              <UnlockedContent
                confirmationContent={confirmationContent}
                videoContent={videoContent}
              />
            ) : null
          }
          isBodyForm={isBodyForm}
          shouldForward={redirectOnSubmit}
          redirectOnSubmit={redirectOnSubmit}
          title={title}
          marketo-key={marketoKey}
          {...props}
          formType={FormTypes.Gated} // we overwrite the formType possibly set by the adapter so we handle gated content correctly
        />
      )}
    </>
  );
};

export default GatedContent;
