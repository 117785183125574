export const Actions = Object.freeze({
  setLocalizedEquivalent: 'SET_LOCALIZED_EQUIVALENT',
});

export const reducer = (state, action) => {
  switch (action.type) {
    case Actions.setLocalizedEquivalent: {
      return {
        ...state,
        localizedEquivalent: action.payload,
      };
    }
    default:
      throw new Error(`${action.type} is not a valid action`);
  }
};
