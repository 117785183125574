/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { getTrackingId } from 'src/lib/utils';

/**
 * Maps Contentful response for Marketo Form Component content type to <FormSection />
 */
export const marketoAdapter = (component, trackingId = '') => {
  return {
    marketoFormBtnUrl: _get(component, 'fields.submitButton.fields.url'),
    'marketo-key': _get(component, 'fields.marketoId'),
    includeCompany: true,
    body: _get(component, 'fields.richDescription'),
    includePhone: _get(component, 'fields.includePhoneField', true),
    includeTicketType: _get(component, 'fields.includeTicketType', false),
    includeJobTitle: _get(component, 'fields.includeJobTitle', false),
    includeUseCase: _get(component, 'fields.includeUseCase', false),
    includeCommentsField: _get(component, 'fields.includeCommentsField', false),
    includePxFieldset: _get(component, 'fields.includePxFieldset', false),
    includeSolutionsField: _get(
      component,
      'fields.includeSolutionsField',
      false,
    ),

    isWaitListSoldOut: _get(component, 'fields.isWaitListSoldOut'),
    disclaimer: _get(component, 'fields.disclaimer'),
    actionText: _get(component, 'fields.submitButton.fields.text'),
    includeNames: true,
    hopinIntegrationCode: _get(component, 'fields.hopinIntegrationCode'),
    hopinIntegrationCodeVirtual: _get(
      component,
      'fields.hopinIntegrationCodeVirtual',
    ),
    // @NOTE: `trackingId` is used as an event name prefix for analytics tracking of
    // deprecated "gated content" instances using Page Header with Form component directly.
    // https://jira.plaid.com/browse/GROWTH-58
    // For expediency's sake, the unique `internalTitle` field is suitable for this purpose.
    // As is, this will be applicable to all child forms composed with this component.
    // Rather than generating unique event names for forms that don't need conversion
    // tracking, we hard-coded a mapping to filter for specific forms by `marketoId`.
    // True `<GatedContent />` instances compose this component as a child
    // and will override the `trackingId` set here.
    // 06/21/2023 added an override to avoid these implicit hacky tracking events 🤨
    trackingId: trackingId
      ? trackingId
      : getTrackingId(
          component?.fields?.marketoId,
          component?.fields?.internalTitle,
        ),
  };
};
