import { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import { DEPLOY_ROOT } from 'src/env';

const useAdBlockDetector = (): boolean => {
  const [adBlockerDetected, setAdBlockerDetected] = useState(true);
  const subfolder = DEPLOY_ROOT ? DEPLOY_ROOT.slice(0, 8) + '/' : '';

  useEffect(() => {
    fetch(`/${subfolder}assets/js/lib/adblock-detector.js`)
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        setAdBlockerDetected(false);
      })
      .catch((error) => {
        // request blocked
      });
  }, [subfolder]);

  return adBlockerDetected;
};

export default useAdBlockDetector;
