/**
 * @param {string} string
 * @returns {string} string stripped of all style attrs embedded in source
 */
function stripStyleAttr(string) {
  const styleRegex = new RegExp(' style="[^"]*"', 'ig');
  return string.replace(styleRegex, () => '');
}

// TODO: remove this disable-next-line when we add more named exports
// eslint-disable-next-line import/prefer-default-export
export { stripStyleAttr };
