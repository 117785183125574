import React from 'react';
import useTheme from 'threads5/styles/useTheme';
import { getDesignTokens } from 'src/components-v2/theme';
import createTheme from 'threads5/themes/create-theme';

// TODO: consider only returning the new theme if the template is set to web 3.0
// so that gradients do not apply until explicitly set to web 3.0
// https://plaidinc.atlassian.net/browse/SITE-5266
export const useComponentTheme = (mode) => {
  const theme = useTheme();
  const componentTheme = React.useMemo(() => {
    // @ts-ignore
    return createTheme({ ...getDesignTokens(mode) });
  }, [mode]);

  return componentTheme || theme;
};
