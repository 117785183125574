import React from 'react';
import { Image } from 'src/components-v2/Media';
import { Typography } from 'src/components-v2/DataDisplay';
import { Stack, Box } from 'src/components-v2/Layout';

const QuoteWithImage = ({ node }) => {
  const { attribution, image, quote } = node?.data?.target?.fields;

  return (
    <Stack
      direction={'row'}
      sx={{
        padding: '8px 0',
        '&:first-of-type': {
          paddingTop: 0,
        },
        '&:last-of-type': {
          paddingBottom: 0,
        },
      }}
    >
      <Box
        sx={{
          minWidth: '44px',
          maxWidth: '44px',
          height: '44px',
          borderRadius: '50%',
          marginRight: '12px',
          overflow: 'hidden',
        }}
      >
        <Image
          src={'https:' + image.fields.file.url}
          width={image.fields.file.details.image.width}
          height={image.fields.file.details.image.height}
          alt={image.fields.description}
        />
      </Box>
      <Box>
        <Typography
          variant='p3'
          sx={{ fontWeight: 600, color: 'var(--black800)' }}
        >
          {attribution}
        </Typography>
        <Typography variant='p3' sx={{ color: 'var(--black800)' }}>
          {quote}
        </Typography>
      </Box>
    </Stack>
  );
};

export default QuoteWithImage;
