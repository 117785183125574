interface TTDApiProps {
  conversionId: string;
}

/**
 * Track a conversion action based on conversion ID.
 * @param conversionId - Conversion ID from The Trade Desk, unique for each event-specific pixel
 */
const trackTTD = ({ conversionId }: TTDApiProps): void => {
  const partnerId = '4azo4ri';
  const url = `https://insight.adsrvr.org/track/pxl/?adv=${partnerId}&ct=0:${conversionId}&fmt=3`;

  // Creates an element without actually adding it to the page DOM.
  // The call is made to TTD servers to register the conversion.
  const pixel = new Image();
  pixel.src = url;
};

// Conversion IDs provided from The Trade Desk
export const TTDConversionIds = {
  CONTACT_FORM_SUCCESS: '5esjnsb',
};

export default trackTTD;
