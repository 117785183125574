import { useEffect } from 'react';

// @TODO: For now we are reusing cookie.js methods as is.
// Consider `public/assets/js/analytics/cookie.js` deprecated (noted in file),
// and we should refactor all cookie logic to React idioms. Related Jiras:
// https://jira.plaid.com/browse/SITE-3141
// https://jira.plaid.com/browse/SITE-3142
import { setUtmAndGclid } from 'public/assets/js/analytics/cookie';

function useCookies(): null {
  useEffect(() => {
    setUtmAndGclid();
  }, []);
  return null;
}

export default useCookies;
