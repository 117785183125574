export const metaData = {
  layout: null,
  'meta-title': 'What is Plaid?',
  'meta-description':
    'Plaid makes it easy to securely connect your bank to the apps you want to use. ',
};

export const pageHeaderData = {
  'content-alignment': 'align-center',
  'text-alignment': 'text-center',
  'content-width-classes': 'page-header-breakpoint-12 large-12',
  'content-width': 'half-width',
  label: false,
  header: 'What is Plaid?',
  'header-class': 'h3',
  subheader:
    'Plaid makes it easy to securely connect your bank to the apps you want to use',
  'primary-cta': false,
  'secondary-cta': false,
  video: false,
  image: false,
  'image-width': 503,
  'image-height': 502.45,
  'image-url': '/assets/img/hero/partners-hero-2x.png',
};

export const showcaseData1 = [
  {
    type: 'single-image',
    'large-image': true,
    'extra-padding': false,
    title: 'Connecting your bank to your apps',
    description:
      'We power thousands of the apps that people rely on to manage their financial lives.',
    bullets: [
      {
        text: '<b>Venmo</b> (peer-to-peer payments)',
      },
      {
        text: '<b>Betterment</b> (automated investing)',
      },
      {
        text: '<b>Chime</b> (online banking)',
      },
      {
        text: '<b>Dave</b> (earned wage access)',
      },
      {
        text: 'And thousands more...',
      },
    ],
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/consumer-brushstroke-yellow-01.png',
    images: [
      {
        'img-src':
          '/assets/img/consumers/what-is-plaid/connect-to-apps-illustration.png',
        'alt-text': 'Connect your banks to apps',
        'has-shadow': false,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': 'https://plaid.com/customer-stories',
    'text-cta-text': 'Meet companies powered by Plaid',
  },
  {
    type: 'single-image',
    reverse: true,
    'large-image': true,
    'extra-padding': false,
    title: 'How it works',
    description:
      'We connect to 11,000 financial institutions across the United States, Canada, and Europe. With Plaid, connecting your bank account is easy:',
    bullets: [
      {
        text:
          '<b>Step 1</b> <br> When you sign up with a Plaid-powered app, you’ll be able to select your financial institution from a list. Then, enter your login and password.',
      },
      {
        text:
          '<b>Step 2</b> <br> In a matter of seconds, we encrypt the data you’ve chosen to share (for instance, your account balance) and securely share it with the app you want to use. We never share your login and password with the app.',
      },
      {
        text:
          '<b>Step 3</b> <br> We work behind the scenes to build a secure, ongoing connection between the app and your bank.',
      },
    ],
    'bg-color': 'white',
    'phone-video': true,
    'has-text-cta': true,
    'text-cta-url': 'https://plaid.com/company',
    'text-cta-text': 'Learn more about our company',
  },
  {
    type: 'single-image',
    'large-image': true,
    'extra-padding': true,
    title: 'Your data, your choice',
    description:
      'With Plaid, you’re in control. You choose whom your data is shared with, for what purpose, and how long. Some of the most common information we help people share includes:',
    bullets: [
      {
        text: 'Account and routing number',
      },
      {
        text: 'Account balance',
      },
      {
        text: 'Transaction history',
      },
      {
        text: 'Personal loans and credit cards',
      },
      {
        text: 'Investment holdings',
      },
      {
        text: 'Identity information (to prevent fraud)',
      },
    ],
    'img-brushtroke': '/assets/img/accents/consumer-brushstroke-blue-02.png',
    images: [
      {
        'img-src':
          '/assets/img/consumers/what-is-plaid/your-data-illustration.png',
        'alt-text': 'Your data, your choice',
        'has-shadow': false,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': 'https://plaid.com/overview-privacy',
    'text-cta-text': 'Learn about our data practices and policies',
  },
];

export const featureSectionData = {
  'feature-section-title': [
    {
      'header-alignment': 'center',
      'title-size': 'small',
      'title-alignment': 'center',
      title: 'Protecting your data',
      'section-id': 'protecting-your-data',
      'desc-alignment': 'center',
      description:
        'Whenever you use a Plaid-powered app, we’re working behind the scenes to protect your financial information. Our security practices are designed to meet or exceed the industry standards that banks and leading technology companies use. They include:',
      'cta-url': 'https://plaid.com/safety',
      'cta-text': 'Learn about our security policy',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/consumers/what-is-plaid/security-encryption-icon.png',
      title: 'End-to-end data encryption',
      'header-class': 'h5',
      content:
        'The combination of the Advanced Encryption Standard (AES-256) and Transport Layer Security (TLS) help keep your personal information safe end-to-end.',
      'no-link': true,
    },
    {
      img:
        '/assets/img/consumers/what-is-plaid/security-authentication-icon.png',
      title: 'Strong authentication',
      'header-class': 'h5',
      content:
        'Plaid protects your data on our systems and requires multi-factor authentication for added security.',
      'no-link': true,
    },
    {
      img:
        '/assets/img/consumers/what-is-plaid/cloud-infrastructure-illustration.png',
      title: 'Cloud infrastructure',
      'header-class': 'h5',
      content:
        'Plaid uses secure cloud infrastructure technologies to enable you to connect quickly and safely.',
      'no-link': true,
    },
    {
      img: '/assets/img/consumers/what-is-plaid/security-monitoring-icon.png',
      title: 'Robust monitoring',
      'header-class': 'h5',
      content:
        'The Plaid API and all related components are continuously monitored by our information security team.',
      'no-link': true,
    },
    {
      img: '/assets/img/consumers/what-is-plaid/security-review-icon.png',
      title: 'Third-party security reviews',
      'header-class': 'h5',
      content:
        'Security researchers and financial institutions regularly audit Plaid’s API and security controls.',
      'no-link': true,
    },
  ],
};

export const showcaseData2 = [
  {
    type: 'single-image',
    'large-image': true,
    'oversized-accent': true,
    title: 'You’re in control',
    description: 'Your data belongs to you. That means:',
    bullets: [
      {
        text: '<b>We don’t sell your data</b> to any outside companies.',
      },
      {
        text:
          '<b>We don’t share your data</b> with anyone without your permission.',
      },
      {
        text:
          '<b>You’re in control</b> of whom you share your data with and for how long.',
      },
    ],
    'img-brushtroke': '/assets/img/accents/consumer-brushstroke-yellow-03.png',
    images: [
      {
        'img-src':
          '/assets/img/consumers/what-is-plaid/in-control-illustration.png',
        'alt-text': 'Transactions history',
        'has-shadow': true,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': 'https://plaid.com/overview-privacy',
    'text-cta-text': 'Learn about our data practices and policies',
  },
  {
    type: 'single-image',
    'large-image': true,
    'extra-padding': false,
    'oversized-accent': true,
    reverse: true,
    title: 'How Plaid makes money',
    description:
      'When you connect your financial data to an app or service, they pay Plaid. For example, when you add a bank account to Square Cash, they pay us a fee. <br> <br> Plaid is free for everyone who uses a Plaid-powered app, allowing you to securely connect your bank account to the apps you want in just a few seconds.',
    images: [
      {
        'img-src':
          '/assets/img/consumers/what-is-plaid/how-plaid-make-money-illustration.png',
        'alt-text': 'Transactions history',
        'has-shadow': true,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': 'https://plaid.com/customer-stories',
    'text-cta-text': 'Meet companies powered by Plaid',
  },
];

export const customerAppCollectionData = {
  'app-section-title': [
    {
      'title-alignment': 'center',
      title: 'Build your financial life',
      'desc-alignment': 'center',
      description:
        'Your financial data is powerful. It can help you pay down debts, save for retirement, and make progress toward your goals. With Plaid-powered apps, you can:',
    },
  ],
  items: [
    {
      url: 'https://www.acorns.com/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-acorns@2x.png',
        alt: 'Acorns logo',
      },
      excerpt: 'Invest your spare change in the stock market',
      'link-text': 'Meet Acorns',
    },
    {
      url: 'https://dave.com/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-dave@2x.png',
        alt: 'Dave logo',
      },
      excerpt: 'Subscribe to a service that will keep you from overdrafting',
      'link-text': 'Meet Dave',
    },
    {
      url: 'https://www.meettally.com/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-tally.png',
        alt: 'Tally logo',
      },
      excerpt: 'Consolidate your credit card debt so you never miss a payment',
      'link-text': 'Meet Tally',
    },
    {
      url: 'https://www.pillar.app/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-pillar@2x.png',
        alt: 'Pillar logo',
      },
      excerpt: 'Determine the best strategy to pay back your student loans',
      'link-text': 'Meet Pillar',
    },
    {
      url: 'https://www.upstart.com/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-upstart@2x.png',
        alt: 'Upstart logo',
      },
      excerpt: 'Enhance a personal loan application with rent payment data',
      'link-text': 'Meet Upstart',
    },
    {
      url: 'https://www.youneedabudget.com/',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-ynab@2x.png',
        alt: 'YNAB logo',
      },
      excerpt: 'Automatically use your bank transactions to build a budget',
      'link-text': 'Meet YNAB',
    },
  ],
};
