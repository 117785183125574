import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import PageHeader from 'src/components/headers/page-header.jsx';
import { RichTextShape } from 'src/lib/prop-types';
import styles from './PageHeaderWithForm.module.scss';

const propTypes = {
  'flip-horizontal': PropTypes.bool,
  'form-header': PropTypes.bool,
  'header-image': PropTypes.string.isRequired,
  headerImageAlt: PropTypes.string,
  'header-image-vert-center': PropTypes.bool,
  'form-image-full-width': PropTypes.string,
  'image-based-mobile': PropTypes.string,
  'header-size': PropTypes.string.isRequired,
  'image-based': PropTypes.bool.isRequired,
  label: PropTypes.bool,
  'label-text': PropTypes.string,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  'subheader-p': PropTypes.bool,
  body: PropTypes.string,
  formData: PropTypes.shape({
    'marketo-key': PropTypes.number.isRequired,
    includeNames: PropTypes.bool,
    includeCompany: PropTypes.bool,
    includePhone: PropTypes.bool,
    actionText: PropTypes.string,
    disclaimer: PropTypes.oneOfType([RichTextShape, PropTypes.string]),
    trackingId: PropTypes.string,
  }).isRequired,
  hasOnPagePostSubmitContent: PropTypes.bool,
  postSubmitContent: PropTypes.element,
  backgroundColor: PropTypes.string,
  formType: PropTypes.string,

  shouldForward: PropTypes.bool,
  redirectOnSubmit: PropTypes.bool,
};

const PageHeaderWithForm = (props) => {
  return (
    <div
      data-testid='page-header-with-form'
      id={`form-${props.formData['marketo-key']}`}
      className={cx('page-header-with-form', styles.pageHeaderWithForm, {
        [`${styles[props?.backgroundColor]}`]: props?.backgroundColor,
      })}
    >
      <PageHeader {...props} />
    </div>
  );
};

PageHeaderWithForm.propTypes = propTypes;
export default PageHeaderWithForm;
