import * as React from 'react';
import { Sys } from 'contentful';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Wrapper } from 'src/containers';
import { Column, Stack } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { BackgroundContext } from 'src/contexts';

interface IBanner {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  sys?: Sys;
  bgImage?: string;
  className?: string;
}

const CtaBanner: React.FunctionComponent<IBanner> = ({
  children,
  sx,
  sys,
  bgImage,
  className,
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const bgSource =
    bgImage ||
    '//images.ctfassets.net/ss5kfr270og3/7HOKN7T3pSrW62Cpxias0t/a3aa4c23dc5610132d1fe5c2bfc9a40c/CTA_Banner_Art.png';

  return (
    <Wrapper
      component='section'
      sx={{ ...sx }}
      {...inspectorProps({ fieldId: 'internalTitle' })}
      className={className}
    >
      <BackgroundContext.Provider // updated value for always light bg cta banner
        value={bgImage ? 'white' : 'black'}
      >
        <Column lg={20} lgOffset={2} sm={24} sx={{ width: '100%' }}>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            alignItems={{ xs: 'start', lg: 'center' }}
            textAlign={{ xs: 'center', lg: 'left' }}
            justifyContent='space-between'
            sx={{
              padding: {
                xs: '2.4rem 1.4rem',
                sm: '4.0rem 5.4rem',
                lg: '6.4rem 5.6rem',
              },
              borderRadius: '24px',
              backgroundColor: 'black',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url("https:${bgSource}?fm=webp&w=2740&q=60")`,
              '& h2': {
                mb: { xs: '2.4rem', lg: '0' },
              },
            }}
          >
            {children}
          </Stack>
        </Column>
      </BackgroundContext.Provider>
    </Wrapper>
  );
};

export default CtaBanner;
