import React, { ReactElement, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cx from 'classnames';

import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';
import {
  Tile,
  TileGroup,
  articleTileGroupAdapter,
} from 'src/components/Layout';
import { Pagination } from 'src/components/Blog';
import { ContentfulTemplates, RESOURCES_PATH } from 'src/lib/constants';
import { renderCtaBlock, searchBasePath } from 'src/lib/resources-utils';
import { createSearchDropdown } from 'src/components/Inputs/SearchBar';

import styles from './TopicPageTemplate.module.scss';

const TopicPageTemplateScripts = dynamic(
  () => {
    return import(
      'src/templates/Resources/TopicPageTemplate/TopicPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const TopicPageTemplate = ({
  fields,
  articles,
  topic,
  totalArticles,
  pageNumber,
  topics = [],
  ...props
}): ReactElement => {
  const { route } = useRouter();

  const numberOfPages = Math.ceil(totalArticles / 9);

  const currentTopic = fields?.topic;
  const [searchTopic, setSearchTopic] = useState(currentTopic?.toLowerCase());

  // creates an object of all the topics ex. {ach:{topic:'ACH'}, api:{topic:'API'}, 'all topics': {topic:{..}}, banking:{topic:'Banking'}}
  const topicsObj = topics.reduce((obj, topic) => {
    if (topic) {
      const topicProp = topic.label || topic;
      return {
        ...obj,
        [topicProp.toLowerCase()]: {
          topic,
        },
      };
    }

    return obj;
  }, {});

  // Arranges the topics in alphabetical order based on the keys of the topicsObj ex. ['ACH',{..},'API','Banking']
  const sortedTopics = Object.keys(topicsObj)
    .sort()
    .map((e) => {
      return topicsObj[e].topic;
    });
  topics = sortedTopics;

  // eslint-disable-next-line no-console
  const handleChange = (search) => {
    search?.selectedOption &&
      setSearchTopic(search.selectedOption?.toLowerCase());
  };

  const topicsDropdown = createSearchDropdown({
    label: 'Topics',
    options: topics,
    defaultOption: currentTopic || '',
  });

  const search = {
    placeholder: 'Search',
    destination: searchBasePath({ topic: searchTopic }),
    resultType: 'resource',
    onChange: handleChange,
    label: `${totalArticles + 1} resource${
      totalArticles !== 1 ? 's' : ''
    } available`, // ex: 2 resources available
    dropdowns: [topicsDropdown],
  };

  const renderArticleTiles = () => {
    try {
      const { tileGroup, ...tileGroupProps } = articleTileGroupAdapter({
        articles,
      });

      return (
        <>
          <TileGroup {...tileGroupProps}>
            {tileGroup.map((tile, idx) => {
              return <Tile key={`article-tile-${idx}`} {...tile} />;
            })}
          </TileGroup>
          {totalArticles > 9 && (
            <div className={cx('grid-container', styles.paginationContainer)}>
              <Pagination
                currentPage={pageNumber || 1}
                totalPages={numberOfPages}
                basePath={`${RESOURCES_PATH}/${topic}`}
              />
            </div>
          )}
        </>
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      noIndex={fields?.metaNoindex}
      className={styles.topicPage}
      openGraphImage={fields?.openGraphImage?.fields?.file?.url}
    >
      <div data-testid={ContentfulTemplates.TOPIC}>
        <CmsComponentSwitch
          components={fields?.components}
          search={search}
          topic={currentTopic}
          templatePage={ContentfulTemplates.TOPIC}
        />
        {renderArticleTiles()}
        {renderCtaBlock(fields)}
      </div>
      <TopicPageTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};

export default TopicPageTemplate;
