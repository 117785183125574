import React from 'react';

// default to web 2.0 which is a our current theme
// available options at the time of writing are:
// web 2.0
// web 3.0
const CmsThemeContext = React.createContext('web 2.0');

export const CmsThemeContextProvider = ({ cmsTheme, children }) => {
  return (
    <CmsThemeContext.Provider value={cmsTheme}>
      {children}
    </CmsThemeContext.Provider>
  );
};

export const useCmsTheme = () => {
  return React.useContext(CmsThemeContext);
};
