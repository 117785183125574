import React from 'react';

const InstagramIcon = () => {
  return (
    <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25 19a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4zm7.61-12.81a1.25 1.25 0 101.25 1.25 1.24 1.24 0 00-1.25-1.25zM42 8a24 24 0 10-17 41A24 24 0 0042 8zm-5 23.35A5.71 5.71 0 0131.35 37H18.66A5.72 5.72 0 0113 31.35V18.66A5.71 5.71 0 0118.66 13h12.69A5.69 5.69 0 0137 18.66z'
        fill='#fff'
      />
    </svg>
  );
};

export default InstagramIcon;
