import React from 'react';
import _get from 'lodash/get';
import { BLOCKS } from '@contentful/rich-text-types';

import { RichTextAssets, Contentful } from 'src/lib/constants';
import { Image } from 'src/components-v2/Media';

const EmbeddedAssetSwitch = ({ asset }) => {
  return {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const assetType = _get(node, Contentful.EMBEDDED_ASSET_CONTENT_TYPE_ID);
      switch (assetType) {
        case RichTextAssets.ASSET: {
          const altText =
            _get(node, 'data.target.fields.description') ||
            _get(node, 'data.target.fields.title');
          return (
            <Image
              {...asset}
              src={'https:' + asset?.imgSrc}
              alt={altText}
              sources={asset?.sources?.map((src) => {
                return {
                  ...src,
                  srcSet: 'https:' + src.srcSet,
                };
              })}
            />
          );
        }
        default:
          return null;
      }
    },
  };
};

export default EmbeddedAssetSwitch;
