import React, { FC } from 'react';
import styles from './ArticlePageHeader.module.scss';
import { Grid, Column } from 'src/components/Layout/Grid';
import ShareBtns from 'src/components/ShareBtns/ShareBtns';
import { Author, authorAdapter } from '../Author';
import { Date } from 'src/components/Date/Date';
import { Breadcrumb } from 'src/components/headers';
import { ArticlePageHeader as ArticlePageHeaderProps } from './ArticlePageHeader.types';
import { RESOURCES_PATH } from 'src/lib/constants';
import { Link } from 'src/components/Inputs';
import Image from 'src/components/Image';

const ArticlePageHeader: FC<ArticlePageHeaderProps> = ({
  topics = [],
  title,
  date = null,
  subtitle = '',
  authors,
  bannerImage = null,
  path,
}) => {
  return (
    <Grid
      className={styles.articlePageHeader}
      useThreadsGrid
      noMarginRightMedium
    >
      <Column small={12}>
        <Grid noPadding useThreadsGrid>
          <Column small={12}>
            <Breadcrumb>
              <Link href={RESOURCES_PATH}>
                <a>Resources</a>
              </Link>
              <Link href={`${RESOURCES_PATH}/${topics[0]?.fields?.pageSlug}`}>
                <a>{topics[0]?.fields?.topic}</a>
              </Link>
              {title}
            </Breadcrumb>
          </Column>
        </Grid>
        {bannerImage && (
          <Grid
            containerClassName={styles.headerImageContainer}
            noPadding
            noMarginRightMedium
            useThreadsGrid
          >
            <Column small={12} className={styles.headerImageColumn}>
              <Image
                className={styles.headerImage}
                src={bannerImage?.fields?.file?.url}
                alt={bannerImage?.fields?.description}
                isLazy={false}
              />
            </Column>
          </Grid>
        )}
        <Grid
          className={styles.titlesAuthorsContainer}
          noPadding
          useThreadsGrid
        >
          <Column small={12} large={2} smallOrder={3} mediumOrder={3}>
            <ShareBtns
              className={styles.shareBtns}
              title={title}
              location={`https://plaid.com${path}`}
              showTitle={false}
            />
          </Column>
          <Column medium='hide' />
          <Column large={8}>
            <h1 className={styles.title}>{title}</h1>
            {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
            <div className={styles.hDivider} />

            {date && <Date date={date} className={styles.date} />}
            <Grid className={styles.authorContainer} noPadding useThreadsGrid>
              {authors?.map((author, idx) => (
                <Column
                  small={12}
                  medium={authors.length < 2 ? 8 : 6}
                  key={`author-column-${idx}`}
                >
                  <Author
                    {...authorAdapter(author?.fields)}
                    key={`article-author-${idx}`}
                  />
                </Column>
              ))}
            </Grid>
          </Column>
          <Column medium='hide' large={2} />
        </Grid>
      </Column>
    </Grid>
  );
};

export default ArticlePageHeader;
