import React from 'react';
import { Icon } from 'src/components-v2/DataDisplay';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import { Stack } from 'src/components-v2/Layout';
import { useIsDarkMode } from 'src/hooks/useIsDarkMode';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';
import IconButton from 'src/components-v3/Homepage/Buttons/IconButton';
import T5Icon from 'threads5/Icon';

interface ILinkCarouselBtns {
  clickCount: number;
  previousCard: () => void;
  nextCard: () => void;
  maxCount: number;
}

const getButtonStyles = (isDark) => {
  return {
    border: 'solid 1px',
    borderRadius: '240px',
    height: '48px',
    width: '100px',
    '&:hover:not(:disabled)': {
      backgroundColor: isDark ? 'white' : 'black',
      color: isDark ? 'black' : 'white',
    },
    '&:active:not(:disabled)': {
      backgroundColor: isDark ? 'black600' : 'black500',
      color: isDark ? 'black' : 'white',
    },
  };
};

const LinkCarouselBtns: React.FC<ILinkCarouselBtns> = ({
  clickCount = 0,
  previousCard,
  nextCard,
  maxCount,
}) => {
  const isDarkBackground = useIsDarkMode();
  const inUseColor = isDarkBackground ? 'white' : 'black800';
  const notInUseColor = isDarkBackground ? 'black800' : 'black400';
  const cmsTheme = useCmsTheme();

  if (cmsTheme === CmsThemes.WEB3) {
    return (
      <Stack direction='row' gap={1}>
        <IconButton
          appearance='outline'
          size='48'
          icon={(iconProps) => {
            return <T5Icon name='ArrowLeft' {...iconProps} size='20' />;
          }}
          onClick={previousCard}
          disabled={clickCount === 0}
        >
          Previous slide
        </IconButton>
        <IconButton
          appearance='outline'
          size='48'
          icon={(iconProps) => {
            return <T5Icon name='ArrowRight' {...iconProps} size='20' />;
          }}
          disabled={clickCount >= maxCount}
          onClick={nextCard}
        >
          Next slide
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack direction='row'>
      <S.Button
        sx={{
          ...getButtonStyles(isDarkBackground),
          color: clickCount === 0 ? notInUseColor : inUseColor,
          mr: '1rem',
        }}
        onClick={previousCard}
        disabled={clickCount === 0}
        aria-label='previous slide'
      >
        <Icon
          sx={{
            height: '45px',
            width: '45px',
            margin: '0.15rem 2.5rem',
            color: 'inherit !important',
          }}
          icon='ArrowLeft'
        />
      </S.Button>
      <S.Button
        sx={{
          ...getButtonStyles(isDarkBackground),
          color: clickCount >= maxCount ? notInUseColor : inUseColor,
        }}
        disabled={clickCount >= maxCount}
        onClick={nextCard}
        aria-label='next slide'
      >
        <Icon
          sx={{
            height: '45px',
            width: '45px',
            margin: '0.15rem 2.5rem',
            color: 'inherit !important',
          }}
          icon='ArrowRight'
        />
      </S.Button>
    </Stack>
  );
};

export default LinkCarouselBtns;
