import React, { FC } from 'react';

import { Link } from 'src/components/Inputs';

import styles from './Logo.module.scss';

const Logo: FC = () => (
  <Link href='/blog'>
    <a className={styles.logo}>BLOG</a>
  </Link>
);

export default Logo;
