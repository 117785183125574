/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for mvp social proof content type to the view
 *
 * @param {Object} args
 * @param {Object} args.backgroundColor
 * @param {Object[]} args.logos
 * @param {string} args.title
 * @returns {Object}
 */
export const mvpSocialProofAdapter = ({
  logos = [],
  title,
  backgroundColor = {},
}) => {
  return {
    header: title,
    alignment: 'center',
    logosPerRow: 4,
    fullWidth: true,
    bgColor: _get(backgroundColor, 'fields.color', 'black1000'),
    logos: logos.map((logo) => {
      return {
        src: 'https://' + _get(logo, 'fields.file.url'),
        alt: _get(logo, 'fields.description', ''),
      };
    }),
  };
};
