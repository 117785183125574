/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { ButtonTypes, ButtonVersion } from 'src/lib/constants';
import styles from './PageHeader--cms.module.scss';

const findCtaVariationByType = (buttons, type) => {
  return buttons.find(
    (item) =>
      item?.sys?.contentType?.sys?.id === 'variationContainer' &&
      item?.fields?.variations?.every(
        (variant) => variant?.fields?.type === type,
      ),
  );
};
/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for mvp page header content type to the view
 *
 * @param {Object} args
 * @param {Object} args.backgroundColor
 * @param {Object[]} args.ctaButtons
 * @param {Object} args.image
 * @param {string} args.heading
 * @param {string} args.subheading
 * @param {string} args.label
 * @param {Object} args.tertiaryCta
 * @param {Object[]} args.video
 * @returns {Object}
 */
export const mvpPageHeaderAdapter = ({
  backgroundColor = {},
  ctaButtons = [],
  image,
  heading,
  subheading = '',
  description = '',
  label = '',
  tertiaryCta,
  video,
} = {}) => {
  const primaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );
  const targetButton = ctaButtons.find(
    (btn) => btn?.sys?.contentType?.sys?.id === ButtonVersion.TARGET,
  );
  const primaryCtaExperiment = findCtaVariationByType(
    ctaButtons,
    ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCtaExperiment = findCtaVariationByType(
    ctaButtons,
    ButtonTypes.SECONDARY_CTA,
  );

  return {
    bgColor: _get(backgroundColor, 'fields.color', 'green200'),
    cta: {
      hasPrimaryCta: !!primaryCta,
      primaryCtaText: _get(primaryCta, 'fields.text'),
      primaryCtaLink: _get(primaryCta, 'fields.url'),
      hasSecondaryCta: !!secondaryCta,
      secondaryCtaText: _get(secondaryCta, 'fields.text'),
      secondaryCtaLink: _get(secondaryCta, 'fields.url'),
      primaryCtaTrackingId: _get(primaryCta, 'fields.trackingId', null), // from partnerships directory
      secondaryCtaTrackingId: _get(secondaryCta, 'fields.trackingId', null), // from partnerships directory
      hasTertiaryCta: !!tertiaryCta,
      tertiaryCta,
      primaryCtaExperiment,
      secondaryCtaExperiment,
    },
    ...(!!targetButton && {
      targetButton: {
        text: _get(targetButton, 'fields.text', ''),
        target: _get(targetButton, 'fields.target', ''),
        href: _get(targetButton, 'fields.url', ''),
      },
    }),
    heading: {
      text: heading,
    },
    subheading: {
      text: subheading,
    },
    description,
    image: {
      alt: _get(image, 'fields.description', ''),
      height: 528,
      src: 'https://' + _get(image, 'fields.file.url'),
      isDesktopOnly: false,
      isLazy: false,
    },
    labelText: label,
    video: {
      width: 1138,
      height: 528,
      poster: 'https://' + _get(image, 'fields.file.url'),
      formats: video?.map((item) => ({
        src: 'https://' + item.fields.file.url,
        type: item.fields.file.contentType,
      })),
      mobileImageSrc: 'https://' + _get(image, 'fields.file.url'),
    },
  };
};

/**
 * Maps the Contentful response for the Partner Directory Page Header to the view
 *
 * @param {Object} fields
 * @returns {Object}
 */
export const partnerDirectoryHeaderAdapter = (fields) => {
  return {
    ...mvpPageHeaderAdapter(fields),
    // defaults to white if no color passed
    heading: {
      text: fields.heading,
      className: styles.largeHeading,
    },
    bgColor: _get(fields, 'backgroundColor.fields.color', 'white'),
    // image is seperate as we hardcode the size
    image: {
      alt: _get(fields, 'image.fields.description', ''),
      height: 464,
      width: 464,

      className: 'headerImage',
      src: 'https://' + _get(fields, 'image.fields.file.url'),
    },
    isDesktopOnly: false,
  };
};

/**
 * Maps the Contentful response for the Partner Page Header to the view
 *
 * @param {Object} fields
 * @returns {Object}
 */

// image and cta are hardcoded as those do not change based on cms input
export const partnerPageHeaderAdapter = (fields) => {
  return {
    ...mvpPageHeaderAdapter(fields),
    heading: {
      text: fields.heading,
      className: styles.mediumHeading,
    },
    link: {
      href: _get(fields, 'partnerLink.fields.url'),
      displayName: _get(fields, 'partnerLink.fields.text'),
    },
    // image is seperate as we hardcode the size
    image: {
      alt: _get(fields, 'image.fields.description', ''),
      height: 464,
      width: 464,
      className: 'headerImage',
      src: 'https://' + _get(fields, 'image.fields.file.url'),
    },
  };
};
