import React from 'react';
import styles from './Breadcrumb.module.scss';

const insertSeparators = (items, separator) => {
  return items.reduce((acc, current, index) => {
    // we don't append a separator to last item
    if (index < items.length - 1) {
      acc = acc.concat(
        current,
        <li aria-hidden key={`separator-${index}`}>
          {separator}
        </li>,
      );
    } else {
      acc.push(current);
    }
    return acc;
  }, []);
};

const Breadcrumb = ({ children, separator = ' > ' }) => (
  <nav aria-label='breadcrumb' className={styles.breadcrumb}>
    <ol>
      {insertSeparators(
        React.Children.toArray(children).map((child, index) => (
          <li key={`child-${index}`}>{child}</li>
        )),
        separator,
      )}
    </ol>
  </nav>
);

export default Breadcrumb;
