import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

// components
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

const PartnerDirectoryScripts = dynamic(
  () => {
    return import(
      'src/templates/PartnerDirectoryTemplate/PartnerDirectoryTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: TemplateFieldShape.isRequired,
};

const PartnerDirectoryTemplate = ({ fields, ...props }) => {
  const { route } = useRouter();

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={false}
    >
      <div data-testid={ContentfulTemplates.PARTNER_DIRECTORY}>
        <CmsComponentSwitch components={fields?.components} />
      </div>
      <PartnerDirectoryScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
PartnerDirectoryTemplate.propTypes = propTypes;

export default PartnerDirectoryTemplate;
