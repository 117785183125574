import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  isInViewport,
  isAboveViewportBottom,
  isScrolledTo,
} from 'src/lib/animation-utils';

export const ANIMATION_TRIGGERS = {
  default: 'after-in-viewport',
  afterAboveViewportBottom: 'after-above-viewport-bottom',
  afterScrolled: 'after-scrolled',
  whileScrolled: 'while-scrolled',
};

const animationHandler = (animated) => {
  animated.forEach(
    ({ node: { current }, targetNode = null, activeClass, trigger }) => {
      const target = targetNode?.current || current;
      switch (trigger) {
        case ANIMATION_TRIGGERS.afterAboveViewportBottom:
          if (isAboveViewportBottom(current)) {
            target.classList.add(activeClass);
          }
          break;
        case ANIMATION_TRIGGERS.afterScrolled:
          if (isScrolledTo(current)) {
            target.classList.add(activeClass);
          }
          break;
        case ANIMATION_TRIGGERS.whileScrolled:
          if (isScrolledTo(current)) {
            target.classList.add(activeClass);
          } else {
            target.classList.remove(activeClass);
          }
          break;
        default:
          if (isInViewport(current)) {
            target.classList.add(activeClass);
          }
      }
    },
  );
};

const useAnimations = (refs) => {
  useEffect(() => {
    const loadEvent = () => {
      animationHandler(refs);
    };
    window.addEventListener('load', loadEvent);

    const throttleEvent = throttle(() => {
      animationHandler(refs);
    }, 100);
    window.addEventListener('scroll', throttleEvent);

    const debounceEvent = debounce(() => {
      animationHandler(refs);
    }, 250);
    window.addEventListener('resize', debounceEvent);

    return () => {
      window.removeEventListener('load', loadEvent);
      window.removeEventListener('scroll', throttleEvent);
      window.removeEventListener('resize', debounceEvent);
    };
  });

  return null;
};

export default useAnimations;
