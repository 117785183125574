import { FormTypes } from 'src/lib/constants';

export const metaData = {
  'meta-title': 'Lending Guide - Plaid',
  'meta-description': 'Streamline and automate your lending process',
  hasCanonicalTag: true,
};
export const pageHeaderData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'Streamline and automate your lending process',
  'header-size': 'h2',
  'header-image-vert-center': true,
  'img-class': 'is-full-size',
  'header-image': '/assets/img/use-cases/lending-guide-hero@2x.png',
  body:
    'Plaid’s lending solution drives efficiencies throughout the lending process from pre-qualifications to underwriting to loan servicing. Learn more in our guide for lenders.',
  formData: {
    'marketo-key': 2008,
    includeCompany: true,
    includePhone: true,
    actionText: 'Download the guide',
    marketoFormBtnUrl:
      'https://go.plaid.com/rs/495-WRE-561/images/Plaid-Lending-White-Paper.pdf',
  },
  formType: FormTypes.Gated,
};
export const socialProofData = {
  'is-light': true,
  header: 'Customers using Plaid for lending',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-opploans-black600.png',
      alt: 'OppLoans',
    },
    {
      img: '/assets/img/logos/logo-blend-black600.png',
      alt: 'Blend',
    },
    {
      img: '/assets/img/logos/logo-lendingclub-black600.png',
      alt: 'LendingClub',
    },
    {
      img: '/assets/img/logos/logo-upstart-black600.png',
      alt: 'Upstart',
    },
  ],
};
export const introSectionData = {
  'remove-top-padding': true,
  'custom-titles': true,
  'problem-title': 'the Problem',
  'problem-statement':
    'Lenders must gather a holistic picture of their applicants, but the process of collecting income information, account balances, and asset history creates considerable work for borrowers and lenders.',
  'solution-title': 'The Solution',
  'solution-statement':
    'Plaid provides borrowers with an easy, streamlined loan experience; and lenders with access to bank data to make informed loan decisions.',
};
export const supportingTextData = {
  'three-up': true,
  'six-up': true,
  header1: 'Asset verification',
  text1:
    'Get a consolidated summary of account balances and transactions as returned by the financial institution',
  header2: 'Income verification',
  text2:
    'Identify recurring income streams with transaction data to verify income and employment',
  header3: 'Identity verification',
  text3:
    "Confirm a user's identity with what's on file at the bank including name and address—to reduce fraud",
  header4: 'Loan servicing',
  text4:
    'Easily authenticate bank accounts when users set up loan repayments via ACH',
  header5: 'Liabilities data',
  text5:
    'Understand a user’s financial obligations including student loan and credit card accounts',
  header6: 'Trusted by Fannie Mae',
  text6:
    'Plaid is a certified Day 1 Certainty™ asset verification report supplier for Fannie Mae’s Desktop Underwriter®',
};
export const descriptionRowCodeData = {
  'mobile-margin-bottom': true,
  title: 'Seamless experience',
  body:
    'Plaid’s login module for end users is easy to customize and drop into a digital loan application.',
  stroke: '/assets/img/accents/homepage-howitworks-brushstroke-right.png',
  'cta-text': 'Read our documentation',
  'cta-url': '/docs',
  'code-lang': 'html',
  'code-body':
    '<form id="link-form-id" method="GET"> </form>\n<script\n  src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"\n  data-client-name="Your Company\'s Name"\n  data-form-id="link-form-id"\n  data-key="test_key"\n  data-product="assets"\n  data-env="sandbox">\n</script>\n',
};
export const footerExtensionData = {
  'footer-title': 'Ready to get started?',
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'Contact Sales',
  'secondary-cta-url': '#contactForm',
};
