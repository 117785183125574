import React from 'react';
import PropTypes from 'prop-types';
import Note from 'plaid-threads/Note';

//components
import { Link } from 'src/components/Inputs';
import { Label } from 'src/components/Surfaces/Label';
import Image from 'src/components/Image';

// contexts
import { useTrack } from 'src/contexts/AnalyticsContext';

// helpers
import { PARTNERSHIP_DIRECTORY_EVENTS } from 'src/lib/trackingEvents';

import styles from './PartnerCardTile.module.scss';

const propTypes = {
  pageSlug: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  partnerTag: PropTypes.string,
  url: PropTypes.string,
  partnerName: PropTypes.string,
};

const PartnerCardTile = ({
  img,
  description,
  partnerTag,
  url,
  partnerName,
  isFeatured,
}) => {
  const track = useTrack();
  return (
    <div className={styles.partnerCard}>
      <article className={styles.partnerCardTile}>
        <Link href={url}>
          <a
            href={url}
            target='_blank' //we WANT our partners to know it's us who referred them, so ignore the rel="noreferrer" warning
            rel='noopener'
            title={description}
            onClick={() =>
              track({
                type: PARTNERSHIP_DIRECTORY_EVENTS.PARTNER_CLICK,
                payload: { partnerName },
              })
            }
          >
            <div className={styles.partnerLogoWrapper}>
              <Image {...img} alt={partnerName} />
            </div>
            {isFeatured && (
              <div className={styles.solutionsPartner}>
                <Label attention small solid>
                  Solutions Partner
                </Label>
              </div>
            )}

            <h5 className={styles.partnerTitle}>{partnerName}</h5>

            <div className={styles.text}>
              <p className={styles.excerpt}>{description}</p>
            </div>
            <div className={styles.partnerTagWrapper}>
              <div>
                <Note small default>
                  {partnerTag}
                </Note>
              </div>
            </div>
          </a>
        </Link>
      </article>
    </div>
  );
};

PartnerCardTile.propTypes = propTypes;

export default PartnerCardTile;
