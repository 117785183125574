import {
  defaultCRAFISearchFields,
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Revoke access to my data',
      'meta-description': 'Revoke access to your data',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Revoke access to my data',
    introHeader: 'Introduction',
    requestType: 'Revoke access',
    intro: `<p>Complete this form in order to revoke data access for a bank account connected via Plaid Check to a third party business or app.</p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
    ...defaultCRAFISearchFields,
    appsAndServices: {
      label: 'Name of business or app to which data access should be revoked',
    },
  },
};
