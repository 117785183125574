/* eslint-disable import/prefer-default-export */
import { RichTextContent, Entry } from 'contentful';
import { FaqProps } from './';

export interface AccordionEntry {
  title: string;
  content: RichTextContent;
}

interface AccordionGroup {
  title: string;
  items: Array<Entry<AccordionEntry>>;
}

export const toFaqProps = ({ title, items }: AccordionGroup): FaqProps => {
  return {
    title,
    items: items.reduce((acc, item) => {
      if (
        item.fields.hasOwnProperty('content') &&
        item.fields.hasOwnProperty('title')
      ) {
        acc.push({
          title: item?.fields.title,
          content: item?.fields.content,
        });
      }
      return acc;
    }, []),
  };
};
