import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import useTheme from 'threads5/styles/useTheme';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import cx from 'classnames';

import { Box, Stack } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { S } from 'src/components-v2/StyledElements/StyledElements';

import { Card } from '../Card';

import { isCardWImage, Tabs as ItemsProps } from '../types';
import { NAV_LIST_DROPDOWN_HIDE_DELAY } from '../consts';

import styles from './Tabs.module.scss';

type TabsProps = {
  parentRef?: MutableRefObject<HTMLLIElement>;
  sx?: SxProps<Theme>;
  items: ItemsProps['children'];
  onClick?: (id: string) => () => void;
};

export const Tabs: FC<TabsProps> = ({ items, sx, parentRef, onClick }) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSetActiveIndex = (i: number) => {
    return (event) => {
      setActiveIndex(i);
    };
  };

  const setDefaultTab = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setActiveIndex(0);
    }, NAV_LIST_DROPDOWN_HIDE_DELAY);
  };

  useEffect(() => {
    if (parentRef?.current) {
      parentRef.current.onmouseleave = setDefaultTab;
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [parentRef]);

  return (
    <Box
      component='ul'
      sx={{
        listStyle: 'none',
        p: '20px 0 0 0',
        m: 0,
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '50% 50%',
        minHeight: '100%',
        [theme.breakpoints.up(1078)]: {
          m: 0,
          p: 0,
          gridTemplateColumns: '240px calc(100% - 240px)',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: '-20px',
          bottom: 0,
          left: '50%',
          display: 'block',
          width: '1px',
          backgroundColor: '#EAEFF0',
          [theme.breakpoints.up(1078)]: {
            top: 0,
            left: '240px',
          },
        },
        ...sx,
      }}
    >
      {items.map(({ id, title, children }, i) => {
        const isActive = i === activeIndex;
        const activeSx = { zIndex: 1, opacity: 1, pointerEvents: 'all' };
        const isImagesGrid = children.some((child) => {
          return isCardWImage(child);
        });

        return (
          <S.Li
            key={`main-menu-tabs-item-${id}`}
            sx={{ display: { sm: 'contents' } }}
          >
            <Box
              className={styles.Tab}
              sx={{
                gridColumnStart: { sm: 1 },
                gridRowStart: { sm: 1 },
                mt: { sm: `${62 * i}px` },
                [theme.breakpoints.up(1078)]: {
                  mt: `${54 * i}px`,
                },
              }}
            >
              <Box
                component='button'
                role='button'
                tabIndex={0}
                className={cx(styles.Tab__button, {
                  [styles.Tab__button_active]: isActive,
                })}
                sx={{
                  textAlign: 'left',
                  position: 'relative',
                  display: 'block',
                  width: '100%',
                  p: '15px 28px',
                  [theme.breakpoints.up(1078)]: {
                    p: '15px 24px',
                  },
                }}
                onMouseEnter={handleSetActiveIndex(i)}
                onClick={handleSetActiveIndex(i)}
              >
                <Typography
                  theme='main-menu-new'
                  component='span'
                  variant='p'
                  sx={{
                    mb: 0,
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Box>

            <Stack
              component='ul'
              sx={{
                gridArea: '1 / 2',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                gap: isImagesGrid ? '32px 0' : '8px 0',
                p: '0 16px 16px',
                m: '-4px 0 0',
                listStyle: 'none',
                opacity: 0,
                zIndex: '-1',
                pointerEvents: 'none',
                [theme.breakpoints.up(1078)]: {
                  p: '20px 24px 25px',
                  m: 0,
                  gap: isImagesGrid ? '0 16px' : '16px 36px',
                },
                ...(isActive && activeSx),
              }}
            >
              {children.map((child, i) => {
                const isOverview = 'isOverview' in child && child.isOverview;
                const overviewSx = {
                  width: '100%',
                  paddingBottom: '20px',
                  borderBottom: '1px solid #EAEFF0',
                  mb: '4px',
                };

                return (
                  <S.Li
                    key={`main-menu-tab-item-${i}`}
                    sx={{
                      width: '100%',
                      [theme.breakpoints.up(1078)]: {
                        width: isImagesGrid
                          ? 'calc(50% - 16px / 2)'
                          : 'calc(50% - 36px / 2)',
                        ...(isOverview && overviewSx),
                      },
                    }}
                  >
                    <Card
                      {...child}
                      tabIndex={isActive ? 0 : -1}
                      iconBoxSize={40}
                      isLarge={isOverview}
                    />
                  </S.Li>
                );
              })}
            </Stack>
          </S.Li>
        );
      })}
    </Box>
  );
};
