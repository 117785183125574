import React from 'react';

const RunButtonIcon = () => {
  return (
    <svg height={9} width={8} xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 4.5l-7 4v-8z' fill='#4b4b4b' fillRule='evenodd' />
    </svg>
  );
};

export default RunButtonIcon;
