import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'src/components/Inputs';

const propTypes = {
  jobId: PropTypes.string.isRequired,
};

const JobApplyButton = ({ jobId }) => {
  const url = `https://jobs.lever.co/plaid/${jobId}/apply`;
  return (
    <Button href={url} className='button-cta' target='_blank' fullWidth>
      Apply
    </Button>
  );
};

JobApplyButton.propTypes = propTypes;
export default JobApplyButton;
