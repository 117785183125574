export const metaData = {
  'meta-title': 'Customer Stories',
  'meta-description':
    'Ever wonder what Plaid does? Meet some of our customers and discover the impact that partnering with Plaid can create.',
};

export const pageHeaderData = {
  primaryHeading: 'Customer Stories',
  subheading:
    'Meet some of our customers and discover the impact that partnering with Plaid can create.',
};

export const featuredStoryData = {
  id: 'Prosper',
  headline: 'Helping people invest in each other',
  subhead:
    'Prosper uses consumer banking data to offer loans that are socially and financially rewarding',
  image: {
    alt: '',
    src: '/assets/img/customer-stories/card-hero-macbook@2x.png',
  },
  url: '/customer-stories/prosper',
  'bg-classes':
    'background background--color-pink200 background--image-stroke-yellow-card-hero background--size-contain background--position-bottom-right',
};

export const itemsData = [
  {
    id: 'Wave',
    name: 'Wave',
    goals: ['Reduce fraud risk', 'Avoid credit card fees'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-wave@2x.png',
      alt: 'Wave - read full story',
    },
    excerpt: 'Unlocking the potential of entrepreneurs',
    url: '/customer-stories/wave',
    visible: true,
  },
  {
    id: 'Brigit',
    name: 'Brigit',
    goals: ['Connect user bank accounts', 'Prevent NSF/overdraft fees'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-brigit@2x.png',
      alt: 'Brigit - read full story',
    },
    excerpt: 'A responsible way to bridge through money shocks',
    url: '/customer-stories/brigit',
    visible: true,
  },
  {
    id: 'Varo',
    name: 'Varo',
    goals: ['Onboard new users', 'Connect user bank accounts'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-varo@2x.png',
      alt: 'Varo - read full story',
    },
    excerpt: 'A bank that builds financial health',
    url: '/customer-stories/varo',
    visible: true,
  },
  {
    id: 'Qapital',
    name: 'Qapital',
    goals: [
      'A path to money happiness',
      'Connect user bank accounts',
      'Prevent NSF/overdraft fees',
    ],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-qapital@2x.png',
      alt: 'Qapital - read full story',
    },
    excerpt: 'A path to money happiness',
    url: '/customer-stories/qapital',
    visible: true,
  },
  {
    id: 'Metal',
    name: 'Metal',
    goals: ['Enable ACH payments', 'Reduce fraud risk'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-metal@2x.png',
      alt: 'Metal - read full story',
    },
    excerpt: 'Personal finance for all',
    url: '/customer-stories/metal',
    visible: true,
  },
  {
    id: 'Prosper',
    name: 'Prosper',
    goals: ['Onboard new users', 'Underwrite loans'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-prosper@2x.png',
      alt: 'Prosper - read full story',
    },
    excerpt: 'Helping people invest in each other',
    url: '/customer-stories/prosper',
    visible: true,
  },
  {
    id: 'SoLo',
    name: 'SoLo',
    goals: ['Connect user bank accounts', 'Underwrite loans'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-solo@2x.png',
      alt: 'SoLo - read full story',
    },
    excerpt: 'An ethical alternative to payday loans',
    url: '/customer-stories/solo',
    visible: true,
  },
  {
    id: 'Alto',
    name: 'Alto',
    goals: ['Enable ACH payments', 'Avoid credit card fees'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-alto@2x.png',
      alt: 'Alto - read full story',
    },
    excerpt: 'A pharmacy for the digital age',
    url: '/customer-stories/alto',
    visible: true,
  },
  {
    id: 'Carvana',
    name: 'Carvana',
    goals: ['Enable ACH payments', 'Prevent NSF/overdraft fees'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-carvana@2x.png',
      alt: 'Carvana - read full story',
    },
    excerpt: 'Accelerating the future of auto payments',
    url: '/customer-stories/carvana',
    visible: true,
  },
  {
    id: 'Astra',
    name: 'Astra',
    goals: ['Connect user bank accounts', 'Get transactions history'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-astra@2x.png',
      alt: 'Astra - read full story',
    },
    excerpt: 'Data-driven tools for the overbanked',
    url: '/customer-stories/astra',
    visible: true,
  },
  {
    id: 'Shift',
    name: 'Shift',
    goals: ['Enable ACH payments', 'Prevent NSF/overdraft fees'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-shift@2x.png',
      alt: 'Shift - read full story',
    },
    excerpt: 'Shifting the used car landscape',
    url: '/customer-stories/shift',
    visible: true,
  },
  {
    id: 'Drop',
    name: 'Drop',
    goals: ['Connect user bank accounts', 'Get transactions history'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-drop@2x.png',
      alt: 'Drop - read full story',
    },
    excerpt: 'Rapid growth and happy customers',
    url: '/customer-stories/drop',
    visible: true,
  },
  {
    id: 'Placid Express',
    name: 'Placid Express',
    goals: ['Enable ACH payments', 'Reduce fraud risk'],
    cardImage: {
      src: '/assets/img/logos/card-company-logo-placid@2x.png',
      alt: 'Placid Express - read full story',
    },
    excerpt: 'Fighting friction, fees, and fraud',
    url: '/customer-stories/placid-express',
    visible: true,
  },
];

export const credibilityData = {
  image: {
    src: '/assets/img/customer-stories/headshot-prosper@2x.png',
    alt: "headshot of Prosper's Siva Shanmugam",
  },
  'quote-text':
    'Plaid consistently outperformed our previous solution. Over the course of the Plaid pilot program, we met or exceeded every performance benchmark.',
  'quote-byline':
    'Siva Shanmugam, Director of Verification and Fraud Strategy, Prosper',
  'cta-link': '/customer-stories/prosper',
  'cta-text': 'Get the full story',
  'logo-src-small': {
    src: '/assets/img/customer-stories/logo-prosper@2x.png',
    alt: 'logo of Prosper',
  },
  'show-quote-logo-asset': true,
};

export const socialproofData = {
  header: 'Other companies we work with',
  alignment: 'center',
  'column-count': 4,
  fullWidth: true,
  logos: [
    {
      src: '/assets/img/logos/logo-acorns-black600.png',
      alt: 'Acorns',
    },
    {
      src: '/assets/img/logos/logo-betterment-black600.png',
      alt: 'Betterment',
    },
    {
      src: '/assets/img/logos/logo-sofi-black600.png',
      alt: 'SoFi',
    },
    {
      src: '/assets/img/logos/logo-elliemae-black600.png',
      alt: 'Ellie Mae',
    },
    {
      src: '/assets/img/logos/logo-expensify-black600.png',
      alt: 'Expensify',
    },
    {
      src: '/assets/img/logos/logo-drop-black600.png',
      alt: 'Drop',
    },
    {
      src: '/assets/img/logos/logo-wise-black600.png',
      alt: 'Wise',
    },
    {
      src: '/assets/img/logos/logo-venmo-black600.png',
      alt: 'Venmo',
    },
  ],
};

export const ctaData = {
  'marketo-key': 2007,
  isDarkMode: true,
  title: 'Questions?',
  'with-subtext': true,
  subtext: "Let us know how we can help and we'll be in touch shortly.",
  'subtext-class': 'h4 regular',
  'include-phone': true,
  image: {
    src: '/assets/img/customer-stories/cta-plaid-employee-row@2x.png',
    alt: 'Plaid Employees',
    width: 167,
  },
};

export const initialFilters = [
  { label: 'Onboard new users', active: false, disabled: false },
  { label: 'Connect user bank accounts', active: false, disabled: false },
  { label: 'Enable ACH payments', active: false, disabled: false },
  { label: 'Get transactions history', active: false, disabled: false },
  { label: 'Underwrite loans', active: false, disabled: false },
  { label: 'Prevent NSF/overdraft fees', active: false, disabled: false },
  { label: 'Reduce fraud risk', active: false, disabled: false },
  { label: 'Avoid credit card fees', active: false, disabled: false },
  { label: 'A path to money happiness', active: false, disabled: false },
];
