import React from 'react';

import { CustomerStoryTemplate } from 'src/templates';
import { PageHeader } from 'src/components/headers';
import { IntroSection, ContentBlockSection } from 'src/components/Layout';
import { Diagram, Infographic } from 'src/components/CustomerStories';

import Footer from 'src/components/footer';

import { dangerous_getStaticData } from 'src/lib/getStaticData';
import { CUSTOMER_STORIES_EVENTS } from 'src/lib/trackingEvents';

// @TODO: replace with references to reusable TS prop type definitions for each respective component
// Ideally component prop types are defined by their respective components for reuse
// Currently the respective components are all legacy JSX with legacy props defined by React PropTypes
interface ComponentPropTypes {
  pageHeaderData: Record<string, unknown>;
  introSectionData: Record<string, unknown>;
  content1Data: Array<Record<string, unknown>> | Record<string, unknown>;
  infographicData: Record<string, unknown>;
  content2Data: Array<Record<string, unknown>> | Record<string, unknown>;
  diagramData: Record<string, unknown>;
  content3Data: Array<Record<string, unknown>> | Record<string, unknown>;
  relatedStoriesData: Record<string, unknown>;
  footerData: Record<string, unknown>;
  components: Array<Record<string, unknown>>;
}

interface MetaData {
  metaData: {
    metaTitle: string;
    metaDescription: string;
  };
}

export interface StaticDataPropTypes extends ComponentPropTypes, MetaData {}

const LocalizedStaticTemplate = ({
  locale,
  templateType,
}: {
  locale: string;
  templateType: string;
}): React.ReactElement => {
  const { metaData, components, ...componentData }: StaticDataPropTypes =
    dangerous_getStaticData({
      locale,
      filePath: `customer-stories/${templateType}`,
    });

  return (
    <CustomerStoryTemplate
      fields={{ ...metaData, components: [] }}
      locale={locale}
    >
      <div className='customer-story'>
        {Array.from(components.keys()).map((dataType, idx) => {
          switch (dataType as unknown) {
            case 'pageHeaderData': {
              return (
                <PageHeader
                  key={`customer-story-page-header-${idx}`}
                  {...componentData[dataType]}
                  secondaryCtaTrackingId={
                    CUSTOMER_STORIES_EVENTS.USER_CLICKED_CUSTOMER_STORIES_DOWNLOAD_PDF
                  }
                />
              );
            }
            case 'introSectionData': {
              return (
                <IntroSection
                  key={`customer-story-intro-section-${idx}`}
                  {...componentData[dataType]}
                />
              );
            }
            case 'content1Data':
            case 'content2Data':
            case 'content3Data': {
              return Array.isArray(componentData[dataType]) ? (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (componentData[dataType] as any[]).map((content, i) => {
                  return (
                    <ContentBlockSection
                      key={`customer-story-content-block${i}`}
                      {...content}
                    />
                  );
                })
              ) : (
                <ContentBlockSection
                  key={`customer-story-content-block${idx}`}
                  {...componentData[dataType]}
                />
              );
            }
            case 'infographicData': {
              return (
                <Infographic
                  key={`customer-story-infographic-${idx}`}
                  {...componentData[dataType]}
                />
              );
            }
            case 'diagramData': {
              return (
                <Diagram
                  key={`customer-story-diagram-${idx}`}
                  {...componentData[dataType]}
                />
              );
            }
            case 'footerData': {
              return (
                <Footer
                  key={`customer-story-footer-${idx}`}
                  hasFooterCta={true}
                  {...componentData[dataType]}
                />
              );
            }
            default:
              return null;
          }
        })}
      </div>
    </CustomerStoryTemplate>
  );
};

export default LocalizedStaticTemplate;
