const {
  banking,
  businessFinances,
  consumerPayments,
  lending,
  openFinance,
  personalFinances,
  wealth,
} = {
  banking: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Digital banking',
    url: '/use-cases/digital-banking/',
    icon: '/assets/img/navbar/icon-banking.png',
  },
  businessFinances: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Business finances',
    url: '/use-cases/business-finances/',
    icon: '/assets/img/navbar/icon-business-finance.png',
  },
  consumerPayments: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Consumer payments',
    url: '/use-cases/consumer-payments/',
    icon: '/assets/img/navbar/icon-consumer-payments.png',
  },
  lending: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Lending',
    url: '/use-cases/lending/',
    icon: '/assets/img/navbar/icon-lending-services.png',
  },
  openFinance: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Open finance',
    url: '/use-cases/open-finance/',
    icon: '/assets/img/navbar/icon-open-finance.png',
  },
  personalFinances: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Personal finances',
    url: '/use-cases/personal-finances/',
    icon: '/assets/img/navbar/icon-personal-finance.png',
  },
  wealth: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Wealth',
    url: '/use-cases/wealth/',
    icon: '/assets/img/navbar/icon-wealth.png',
  },
};

const columns = [
  {
    gridClasses: 'cell small-5',
    items: [personalFinances, lending, wealth, openFinance],
  },
  {
    gridClasses: 'cell small-5 small-push-1',
    items: [consumerPayments, banking, businessFinances],
  },
];

const useCases = {
  label: 'Use cases',
  subtitle: 'Learn how you can make the most of our products',
  menuLinks: [
    {
      name: 'Customer stories',
      url: '/customer-stories/',
    },
  ],
  submenu: {
    columns,
  },
};

export default useCases;
