import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';
import { NavDataItems } from './types';

const enUS: { items: NavDataItems; ctaTrackingIds: { getAPIkeys: string } } = {
  items: [
    {
      id: 'main-menu-item-001',
      title: 'Why Plaid',
      href: '/why-plaid/',
    },
    {
      id: 'main-menu-item-002',
      type: 'tabs',
      title: 'Products',
      children: [
        {
          id: 'main-menu-item-012',
          type: 'list',
          title: 'Payments',
          children: [
            {
              title: 'Overview',
              description: 'Explore payments solutions',
              href: '/solutions/payments/',
              icon: 'Income',
              isOverview: true,
            },
            {
              title: 'Auth',
              description: 'Verified account and routing numbers',
              href: '/products/auth/',
              icon: 'Institution',
            },
            {
              title: 'Identity',
              description: 'Bank account-holder information',
              href: '/products/identity/',
              icon: 'Identity',
            },
            {
              title: 'Balance',
              description: 'Real-time balance checks',
              href: '/products/balance/',
              icon: 'LineGraph',
            },
            {
              title: 'Signal',
              description: 'ACH risk assessment and scoring',
              href: '/products/signal/',
              icon: 'ProductBeacon',
            },
            {
              title: 'Transfer',
              description: 'ACH, RTP, and FedNow payment processing',
              href: '/products/transfer/',
              icon: 'Transfer',
            },
          ],
        },
        {
          id: 'main-menu-item-022',
          type: 'list',
          title: 'Fraud and Compliance',
          children: [
            {
              title: 'Overview',
              description: 'Explore fraud and compliance solution',
              href: '/solutions/fraud-compliance/',
              icon: 'ItemManagement',
              isOverview: true,
            },
            {
              title: 'Identity Verification',
              description: 'Global KYC and anti-fraud',
              href: '/products/identity-verification/',
              icon: 'ProductIdv',
            },
            {
              title: 'Beacon',
              description: 'Anti-fraud network',
              href: '/products/beacon/',
              icon: 'ProductBeacon',
            },
            {
              title: 'Monitor',
              description: 'AML watchlist screening',
              href: '/products/monitor/',
              icon: 'ProductMonitor',
            },
            {
              title: 'Signal',
              description: 'ACH risk assessment and scoring',
              href: '/products/signal/',
              icon: 'Shield',
            },
            {
              title: 'Identity',
              description: 'Bank account-holder information',
              href: '/products/identity/',
              icon: 'Identity',
            },
          ],
        },
        {
          id: 'main-menu-item-032',
          type: 'list',
          title: 'Personal Finance Insights',
          children: [
            {
              title: 'Overview',
              description: 'Explore the personal finance insights solution',
              href: '/solutions/personal-financial-management/',
              icon: 'Guide',
              isOverview: true,
            },
            {
              title: 'Transactions',
              description: 'Up to 24 months of categorized data',
              href: '/products/transactions/',
              icon: 'ProductTransactions',
            },
            {
              title: 'Investments',
              description: 'Retirement, brokerage, and crypto data',
              href: '/products/investments/',
              icon: 'LineGraph',
            },
            {
              title: 'Liabilities',
              description: 'Credit card and mortgage data',
              href: '/products/liabilities/',
              icon: 'BarGraph',
            },
            {
              title: 'Enrich',
              description: 'Cleanse and categorize internal transactions',
              href: '/products/enrich/',
              icon: 'Tag',
            },
          ],
        },
        {
          id: 'main-menu-item-042',
          type: 'list',
          title: 'Credit Underwriting',
          children: [
            {
              title: 'Overview',
              description: 'Explore the credit underwriting insights solution',
              href: '/solutions/credit/',
              icon: 'Clipboard',
              isOverview: true,
            },
            {
              title: 'Consumer Report',
              description: 'Cash flow insights powered by Plaid Check',
              href: '/check/consumer-report/',
              icon: 'Wallet',
            },
            {
              title: 'Assets',
              description: "Point-in-time snapshots of users' finances",
              href: '/products/assets/',
              icon: 'LineGraph',
            },
            {
              title: 'Income',
              description: 'Income and employment verification',
              href: '/products/income/',
              icon: 'Income',
            },
          ],
        },
        {
          id: 'main-menu-item-052',
          type: 'list',
          title: 'Open Finance',
          children: [
            {
              title: 'Overview',
              description: 'Explore open finance solution',
              href: '/solutions/open-finance/',
              icon: 'Institution',
              isOverview: true,
            },
            {
              title: 'Core Exchange',
              description: 'Industry-aligned API connectivity',
              href: '/products/core-exchange/',
              icon: 'Globe',
            },
            {
              title: 'Permissions Manager',
              description: 'Embeddable consumer permissions management',
              href: '/products/permissions-manager/',
              icon: 'Users',
            },
            {
              title: 'App Directory',
              description: 'App insights to help manage risk and compliance',
              href: '/products/app-directory/',
              icon: 'Mobile',
            },
          ],
        },
        {
          id: 'main-menu-item-062',
          type: 'list',
          title: 'Onboarding',
          children: [
            {
              title: 'Link',
              href: '/plaid-link/',
              description:
                'Industry-leading bank connections for +12,000 institutions',
              image: 'link',
              imagePos: 'bottom',
            },
            {
              title: 'Layer',
              href: '/layer/',
              description:
                'Capture identity, contact and bank info in a single click.  ',
              image: 'layer',
              imagePos: 'top',
            },
          ],
        },
      ],
    },
    {
      id: 'main-menu-item-003',
      type: 'list',
      title: 'Use cases',
      children: [
        {
          title: 'Lending',
          href: '/use-cases/lending/',
          icon: 'Income',
        },
        {
          title: 'Wealth',
          href: '/use-cases/wealth/',
          icon: 'Wallet',
        },
        {
          title: 'Pay by bank',
          href: '/pay-by-bank/',
          icon: 'Tag',
        },
        {
          title: 'Digital banking',
          href: '/use-cases/digital-banking/',
          icon: 'Institution',
        },
        {
          title: 'Business finances',
          href: '/use-cases/business-finances/',
          icon: 'Buildings',
        },
        {
          title: 'Crypto',
          href: '/use-cases/crypto/',
          icon: 'Globe',
        },
        {
          title: 'Property management',
          href: '/plaid-for-property-management/',
          icon: 'Home',
        },
      ],
    },
    {
      id: 'main-menu-item-005',
      type: 'list',
      title: 'Developers',
      children: [
        {
          title: 'Quickstart',
          href: '/docs/quickstart/',
          icon: 'ChevronRightDouble',
        },
        {
          title: 'API documentation',
          href: '/docs/',
          icon: 'Docs',
        },
        {
          title: 'Libraries',
          href: '/docs/api/libraries/',
          icon: 'Libraries',
        },
        {
          title: 'Github',
          href: 'https://github.com/plaid/',
          icon: 'Webhooks',
        },
        {
          title: 'Link demo',
          href: '/demo/',
          icon: 'Mobile',
        },
      ],
    },
    {
      id: 'main-menu-item-006',
      type: 'columns',
      title: 'Resources',
      children: [
        {
          title: 'For builders',
          children: [
            {
              title: 'What’s new',
              href: '/whats-new/fall-2024/',
              icon: 'Guide',
            },
            {
              title: 'Blog',
              href: '/blog/',
              icon: 'Notebook',
            },
            {
              title: 'Library',
              href: '/resources/library/',
              icon: 'Libraries',
            },
            {
              title: 'Industry resources',
              href: '/resources/',
              icon: 'NewWindow',
            },
            {
              title: 'Customer Stories',
              href: '/customer-stories/',
              icon: 'Message',
            },
            {
              title: 'Annual conference',
              href: '/events/effects/',
              icon: 'Video',
            },
            {
              title: 'What is Plaid',
              href: '/what-is-plaid/',
              icon: 'PlaidLogo',
            },
          ],
        },
        {
          title: 'For users',
          children: [
            {
              title: 'Control your data with Plaid Portal',
              href: '//my.plaid.com/',
            },
            {
              title: 'How we handle data',
              href: '/how-we-handle-data/',
            },
            {
              title: 'Plaid-powered apps',
              href: '/discover-apps/',
            },
          ],
        },
      ],
    },
    {
      id: 'main-menu-item-004',
      title: 'Pricing',
      href: '/pricing/',
      trackingId: NAVIGATION_EVENTS.PRIMARY.USER_CLICKED_PRIMARY_NAV_PRICING,
    },
  ],
  ctaTrackingIds: {
    getAPIkeys: NAVIGATION_EVENTS.PRIMARY.GET_API_KEYS_BUTTON,
  },
};

export const navData = {
  'en-us': enUS,
};
