import React, { FC } from 'react';
import { CustomerStoryTile as CustomerStoryTileProps } from './CustomerStoryTile.types';
import { Tile } from 'src/components/Layout/Tile';

const CustomerStoryTile: FC<CustomerStoryTileProps> = ({
  url,
  cardImage,
  name,
  excerpt,
  'link-text': linkText = 'Read full story',
}) => {
  const cta = {
    href: url,
    text: linkText,
  };
  const image = {
    src: cardImage.src,
    alt: cardImage.alt,
  };

  return <Tile image={image} title={name} description={excerpt} cta={cta} />;
};

export default CustomerStoryTile;
