import React from 'react';
import PropTypes from 'prop-types';

import { stripStyleAttr } from 'src/components/job/utils';

const propTypes = {
  description: PropTypes.string.isRequired,
};

const JobDescription = ({ description }) => {
  // description from Lever includes raw HTML
  const descriptionWithMarkup = { __html: stripStyleAttr(description) };
  return (
    <div className='job-post__description'>
      <div dangerouslySetInnerHTML={descriptionWithMarkup}></div>
    </div>
  );
};

JobDescription.propTypes = propTypes;
export default JobDescription;
