import * as React from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { prependLocale, prependStagingHash } from 'src/lib/utils';
import { usePageLocale } from 'src/contexts';

const reservedProps = new Set([
  'href',
  'onClick',
  'onTouchStart',
  'onMouseEnter',
]);

// NOTE: we cannot change this interface in the event
// the component ever falls back to the actual NEXT.js link component
// we can however emulate any of the properties of LinkProps
// so far we have only emulated locale
const Link: React.FunctionComponent<LinkProps> = ({
  children,
  ...linkProps
}) => {
  const router = useRouter();
  const userLocale = usePageLocale();

  // if locale is available then we know next.js' i18n is enabled
  // so we short circuit and just use the Link component as it's intended
  const shouldShortCircuit = Object.keys(linkProps).filter((v) => {
    return !reservedProps.has(v);
  }).length;
  if (router?.locale || !!shouldShortCircuit) {
    return (
      <NextLink href={linkProps.href} {...linkProps}>
        {children}
      </NextLink>
    );
  }

  // if next i18n locale is not available then fallback
  // and prepend the hard coded locale to the anchor
  return (
    <>
      {React.Children.map(children, (child: React.ReactElement, index) => {
        const href = prependStagingHash(
          prependLocale({
            locale: linkProps.locale || userLocale,
            href: child.props.href || linkProps.href,
          }),
          router?.basePath || '',
        );

        const { children, ...rest } = child.props;
        if (child.type === 'a') {
          return (
            <a href={href} {...rest}>
              {children}
            </a>
          );
        }

        return React.cloneElement(child, {
          ...child.props,
          href,
        });
      })}
    </>
  );
};

export default Link;
