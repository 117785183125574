/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the contentful response for Infochart section content type to the view
 * @param {Object} args
 * @param {string} args.heading
 * @param {Object[]} args.items
 * @param {Object[]} args.footnote
 * @param {string} args.backgroundColor
 * @returns {Object}
 */

export const infoChartSectionAdapter = ({
  heading,
  items,
  footnote,
  backgroundColor,
}) => {
  const row = [];
  row.push({ header: true, content: heading });

  items.forEach((item) => {
    row.push({
      label: _get(item, 'fields.label'),
      content: _get(item, 'fields.metric'),
    });
  });

  const footnoteArr = [];
  footnote?.split(/[\n\r]/g).forEach((item) => {
    footnoteArr.push({ content: item });
  });

  return {
    row,
    footnote: footnoteArr,
    className:
      backgroundColor &&
      'background--color-' + _get(backgroundColor, 'fields.color'),
  };
};
