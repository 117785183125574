import React, { useRef } from 'react';

// helpers
import {
  CODE_LINE_HEIGHT,
  HOT_SPOT_OFFSET,
} from '../with-animated-response-constants';
import { Property } from '../withAnimatedResponse.types';
import { DeviceContext } from 'src/contexts';

interface Props {
  index: number;
  property: Property;
  handleEvent({ e, currentElement, parentContainer, device }): void;
  parentContainer: HTMLDivElement;
  isMobile?: boolean;
  isHidden?: boolean;
}

const InteractiveHotSpot: React.FC<Props> = ({
  index,
  property,
  handleEvent,
  parentContainer,
  isMobile = false,
  isHidden = false,
}) => {
  const currentElement = useRef(null);
  return (
    <DeviceContext.Consumer>
      {(device) => (
        <span
          ref={currentElement}
          onClick={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          onKeyDown={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          role='button'
          tabIndex={0}
          data-id={`${isMobile ? 'mobile-' : ''}object-property-${
            property.title
          }-hotspot`}
          className={`interactive-hotspot-outer ${
            index === 0 ? 'interactive-hotspot-outer--hidden' : ''
          }`}
          style={{
            top: `${
              property['code-line-start'] * CODE_LINE_HEIGHT + HOT_SPOT_OFFSET
            }px`,
            display: isHidden ? 'none' : 'inline-block',
          }}
        >
          <span className='interactive-hotspot-inner'></span>
        </span>
      )}
    </DeviceContext.Consumer>
  );
};

export default InteractiveHotSpot;
