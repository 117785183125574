import {
  Children,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
} from 'react';

import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

import { S } from 'src/components-v2/StyledElements/StyledElements';
import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';

import { Dropdown } from '../Dropdown';
import { Tabs } from '../Tabs';
import { Columns } from '../Columns';
import { List } from '../List';

import { isLink, NavDataItems } from '../types';
import { NAV_LIST_DROPDOWN_HIDE_DELAY } from '../consts';

import styles from './NavList.module.scss';

type NavListProps = {
  animatedLayerRef?: MutableRefObject<HTMLDivElement>;
  items: NavDataItems;
  sx?: SxProps<Theme>;
};

export const NavList: FC<NavListProps> = ({
  children,
  items,
  sx,
  animatedLayerRef,
}) => {
  const liBaseSx = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  };

  const tabsParentRef = useRef<HTMLLIElement>(null);
  const dropdownRefs = useRef<Array<HTMLDivElement>>(new Array(items.length));

  const refCb = (ref: HTMLDivElement) => {
    if (!ref) return;

    const index = Array.from(ref.parentNode.parentNode.children).indexOf(
      ref.parentElement,
    );

    if (dropdownRefs.current && index !== -1) {
      dropdownRefs.current[index] = ref;
    }
  };

  const firstTimeRef = useRef(true);
  const closeTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const activeIndexRef = useRef(-1);

  // HELPERS
  const show = (index: number) => {
    const dropdown = dropdownRefs.current[index];

    if (!dropdown || !animatedLayerRef.current) return;

    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }

    const { width, height, x, y } = dropdown.getBoundingClientRect();

    animatedLayerRef.current.style.width = `${width + 16}px`;
    animatedLayerRef.current.style.height = `${height}px`;
    animatedLayerRef.current.style.top = `${y + 8}px`;
    animatedLayerRef.current.style.left = `${x - 8}px`;

    animatedLayerRef.current.style.transitionProperty = '';
    animatedLayerRef.current.style.transitionDuration = '';
    animatedLayerRef.current.style.transitionTimingFunction = '';

    dropdown.style.opacity = '1';
    dropdown.style.visibility = 'visible';
    dropdown.style.pointerEvents = 'all';

    dropdown.style.transitionProperty = '';
    dropdown.style.transitionDuration = '';
    dropdown.style.transitionDelay = '';

    if (!firstTimeRef.current) {
      animatedLayerRef.current.style.transitionProperty = 'width, height, left';
      animatedLayerRef.current.style.transitionDuration = '200ms';
      animatedLayerRef.current.style.transitionTimingFunction =
        'cubic-bezier(0.47, 0, 0.23, 1.38)';

      dropdown.style.transitionProperty = 'opacity, visibility';
      dropdown.style.transitionDuration = '100ms';
      dropdown.style.transitionDelay = '100ms';
    }

    firstTimeRef.current = false;
    activeIndexRef.current = index;
  };

  const hideWithoutAnimation = (index: number) => {
    const dropdown = dropdownRefs.current[index];

    if (!dropdown) return;

    dropdown.style.opacity = '0';
    dropdown.style.visibility = 'hidden';
    dropdown.style.pointerEvents = 'none';

    dropdown.style.transitionProperty = '';
    dropdown.style.transitionDuration = '';
    dropdown.style.transitionDelay = '';
  };

  const hide = (index: number, delay?: number) => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }

    closeTimer.current = setTimeout(() => {
      const dropdown = dropdownRefs.current[index];

      if (!dropdown || !animatedLayerRef.current) return;

      animatedLayerRef.current.style.height = '0';

      animatedLayerRef.current.style.transitionProperty = 'height';
      animatedLayerRef.current.style.transitionDuration = '200ms';
      animatedLayerRef.current.style.transitionTimingFunction = 'linear';

      dropdown.style.opacity = '0';
      dropdown.style.visibility = 'hidden';
      dropdown.style.pointerEvents = 'none';

      dropdown.style.transitionProperty = 'opacity, visibility';
      dropdown.style.transitionDuration = '50ms';
      dropdown.style.transitionDelay = '';

      firstTimeRef.current = true;
      activeIndexRef.current = -1;
    }, delay ?? NAV_LIST_DROPDOWN_HIDE_DELAY);
  };

  // EVENT HANDLERS
  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    const currentIndex = Array.from(
      event.currentTarget.parentNode.children,
    ).indexOf(event.currentTarget);

    if (!currentIndex || currentIndex === activeIndexRef.current) return;

    if (activeIndexRef.current !== -1) {
      hideWithoutAnimation(activeIndexRef.current);
    }

    show(currentIndex);
  };

  const handleMouseLeave = (event: MouseEvent<HTMLElement>) => {
    const currentIndex = Array.from(
      event.currentTarget.parentNode.children,
    ).indexOf(event.currentTarget);

    if (!currentIndex) return;

    hide(currentIndex);
  };

  const handleKeyUpCommon = (event: KeyboardEvent) => {
    const currentIndex = Array.from(
      event.currentTarget.parentNode.children,
    ).indexOf(event.currentTarget);

    if (!currentIndex) return;

    const { key } = event;

    if (key === 'Escape') {
      hide(currentIndex, 0);
    }
  };

  const handleKeyUpButton = (event: KeyboardEvent) => {
    const currentIndex = Array.from(
      event.currentTarget.parentNode.parentNode.children,
    ).indexOf(event.currentTarget.parentElement);

    if (!currentIndex) return;

    const { key } = event;

    if (key === ' ' || key === 'Space' || key === 'Enter') {
      if (currentIndex === activeIndexRef.current) {
        hide(currentIndex, 0);
      } else {
        if (activeIndexRef.current !== -1) {
          hideWithoutAnimation(activeIndexRef.current);
        }

        show(currentIndex);
      }
    }
  };

  const handleFocus = (event: FocusEvent) => {
    const currentIndex = Array.from(
      event.currentTarget.parentNode.parentNode.children,
    ).indexOf(event.currentTarget.parentElement);

    if (!currentIndex || currentIndex === activeIndexRef.current) return;

    if (activeIndexRef.current !== -1) {
      hide(activeIndexRef.current, 0);
    }
  };

  useEffect(() => {
    return () => {
      if (closeTimer.current) {
        clearTimeout(closeTimer.current);
      }
    };
  }, []);

  return (
    <Stack
      className={styles.NavList}
      component='ul'
      direction='row'
      alignItems='center'
      sx={{ listStyle: 'none', margin: 0, padding: 0, ...sx }}
    >
      {Children.map(children, (child, i) => {
        return (
          <S.Li key={`main-menu-nav-prefix-item-${i}`} sx={liBaseSx}>
            {child}
          </S.Li>
        );
      })}

      {items.map((item, i) => {
        const id = `menu-item-${item.id}`;

        if (!isLink(item)) {
          switch (item.type) {
            case 'list': {
              const { title, children } = item;

              return (
                <S.Li
                  className={styles.NavList__list}
                  key={id}
                  sx={liBaseSx}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onKeyUp={handleKeyUpCommon}
                >
                  <Box
                    className={styles.Button}
                    component='button'
                    role='button'
                    aria-expanded={false}
                    aria-controls={id}
                    tabIndex={0}
                    id={`control-${id}`}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    onKeyUp={handleKeyUpButton}
                    onFocus={handleFocus}
                  >
                    <Typography
                      sx={{ whiteSpace: 'nowrap', marginBottom: 0 }}
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                    >
                      {title}
                    </Typography>
                  </Box>

                  <Dropdown
                    ref={refCb}
                    className={styles.Dropdown}
                    id={id}
                    sx={{
                      width: '296px',
                    }}
                  >
                    <List items={children} />
                  </Dropdown>
                </S.Li>
              );
            }

            case 'columns': {
              const { title, children } = item;

              return (
                <S.Li
                  className={styles.NavList__columns}
                  key={id}
                  sx={liBaseSx}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onKeyUp={handleKeyUpCommon}
                >
                  <Box
                    className={styles.Button}
                    component='button'
                    role='button'
                    tabIndex={0}
                    aria-expanded={false}
                    aria-controls={id}
                    id={`control-${id}`}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    onKeyUp={handleKeyUpButton}
                    onFocus={handleFocus}
                  >
                    <Typography
                      sx={{ whiteSpace: 'nowrap', marginBottom: 0 }}
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                    >
                      {title}
                    </Typography>
                  </Box>

                  <Dropdown
                    ref={refCb}
                    className={styles.Dropdown}
                    id={id}
                    sx={{
                      left: '-42px',
                      width: `${254 * +children.length}px`,
                    }}
                  >
                    <Columns className={styles.Columns} items={children} />
                  </Dropdown>
                </S.Li>
              );
            }

            case 'tabs': {
              const { title, children } = item;

              return (
                <S.Li
                  ref={tabsParentRef}
                  className={styles.NavList__tabs}
                  key={id}
                  sx={liBaseSx}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onKeyUp={handleKeyUpCommon}
                >
                  <Box
                    className={styles.Button}
                    component='button'
                    role='button'
                    tabIndex={0}
                    aria-expanded={false}
                    aria-controls={id}
                    id={`control-${id}`}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    onKeyUp={handleKeyUpButton}
                    onFocus={handleFocus}
                  >
                    <Typography
                      sx={{ whiteSpace: 'nowrap', marginBottom: 0 }}
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                    >
                      {title}
                    </Typography>
                  </Box>

                  <Dropdown
                    ref={refCb}
                    className={styles.Dropdown}
                    id={id}
                    sx={{
                      width: '847px',
                      left: '-54px',
                    }}
                  >
                    <Tabs items={children} parentRef={tabsParentRef} />
                  </Dropdown>
                </S.Li>
              );
            }

            default:
              return null;
          }
        }

        const { href, title, trackingId } = item;

        return (
          <S.Li key={id} className={styles.NavList__item} sx={liBaseSx}>
            <Link href={href} trackingId={trackingId}>
              <a className={styles.Link} role='link' tabIndex={0}>
                <Typography
                  sx={{ whiteSpace: 'nowrap', marginBottom: 0 }}
                  theme='main-menu-new'
                  variant='p'
                  component='span'
                >
                  {title}
                </Typography>
              </a>
            </Link>
          </S.Li>
        );
      })}
    </Stack>
  );
};
