import React, { FC } from 'react';
import styles from './ArticleTOC.module.scss';
import { Grid, Column } from 'src/components/Layout/Grid';
import { Anchors as AnchorProps } from './ArticleTOC.types';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmallGary from 'src/components/SVGs/Icons/ArrowRightSingleSmallGray';

const ArticleTOC: FC<AnchorProps> = ({ anchors }) => (
  <Grid useThreadsGrid>
    <Column small={12}>
      <div className={styles.tocContainer}>
        <div className={styles.tocTitle}>Table of Contents</div>
        {anchors.map((anchor, idx) => (
          <Link href={`#${anchor.fields.anchorId}`} key={`toc-anchor-${idx}`}>
            <a className={styles.tocLink}>
              {anchor.fields.title}
              <span aria-hidden='true' className={styles.tocLinkArrow}>
                <ArrowRightSingleSmallGary />
              </span>
            </a>
          </Link>
        ))}
      </div>
    </Column>
  </Grid>
);

export default ArticleTOC;
