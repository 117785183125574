// emulate a handlebars unless helper
// https://handlebarsjs.com/guide/builtin-helpers.html#unless
const Unless = ({ condition, children }) => {
  if (!condition) {
    return children;
  }

  return null;
};

export default Unless;
