import { Locales } from 'src/lib/constants';

const columns = [
  {
    heading: 'GET STARTED',
    gridClasses: 'cell small-5',
    items: [
      {
        name: 'Quickstart',
        url: '/docs/quickstart',
        locale: Locales.EN_US, // forces the Link component to link to US content
      },
      {
        name: 'API documentation',
        url: '/docs',
        locale: Locales.EN_US, // forces the Link component to link to US content
      },
    ],
  },
  {
    heading: 'RESOURCES',
    gridClasses: 'cell small-5',
    items: [
      {
        name: 'Libraries',
        url: '/docs/api/libraries/',
        locale: Locales.EN_US, // forces the Link component to link to US content
      },
      {
        name: 'GitHub',
        url: 'https://github.com/plaid',
        locale: Locales.EN_US, // forces the Link component to link to US content
      },
    ],
  },
];

const forDevelopers = {
  label: 'For developers',
  subtitle:
    'Everything you need to integrate with Plaid and learn about our APIs',
  subtitleColSize: 4,
  submenu: {
    columns,
  },
};

export default forDevelopers;
