/**
 * Maps the contentful response for EmbeddedVideo content type to the view
 *
 * @param {Object} args
 * @param {string} args.videoProvider
 * @param {string} args.videoId
 * @param {string=} args.caption
 * @returns {Object}
 */
export const videoEmbedAdapter = ({ url, caption }) => {
  return {
    url: url ?? '', // guard against build failures from empty contentful entries
    caption,
  };
};
