// https://blog.bitscry.com/2018/08/17/getting-and-setting-url-parameters-with-javascript/
export const getUrlParameter = function (name) {
  if (typeof window !== 'undefined') {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
};

// https://blog.bitscry.com/2018/08/17/getting-and-setting-url-parameters-with-javascript/
export const setUrlParameter = function (keyInput, valueInput) {
  const key = encodeURIComponent(keyInput);
  const value = encodeURIComponent(valueInput);
  const url = window.location.href;
  const baseUrl = url.split('?')[0];
  const newParam = key + '=' + value;

  let params = '?' + newParam;
  let urlQueryString = '';

  // if there are no query strings, make urlQueryString empty
  if (url.split('?')[1] === undefined) {
    urlQueryString = '';
  } else {
    urlQueryString = '?' + url.split('?')[1];
  }

  // If the "search" string exists, then build params from it
  if (urlQueryString) {
    var updateRegex = new RegExp('([?&])' + key + '[^&]*');
    var removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

    // Remove param if value is empty
    if (value === undefined || value === null || value === '') {
      params = urlQueryString.replace(removeRegex, '$1');
      params = params.replace(/[&;]$/, '');
    }
    // If param exists already, update it
    else if (urlQueryString.match(updateRegex) !== null) {
      params = urlQueryString.replace(updateRegex, '$1' + newParam);
    }
    // If there are no query strings
    else if (urlQueryString == '') {
      params = '?' + newParam;
    }
    // Otherwise, add it to end of query string
    else {
      params = urlQueryString + '&' + newParam;
    }
  }

  // no parameter was set so we don't need the question mark
  params = params === '?' ? '' : params;

  window.history.replaceState(null, null, params);

  return baseUrl + params;
};

/**
 * Updates query string
 *
 * @param {Object} [properties] Arguments
 * @param {string} [properties.uri=window.location]
 * @param {string} [properties.key=''] query param key
 * @param {string} [properties.value=''] query param new value
 * @returns {string} modified URI string
 */
export const updateQueryStringParameter = ({
  uri = window.location,
  key = '',
  value = '',
}) => {
  const originalURL = new window.URL(uri);
  const params = originalURL.searchParams;
  params.set(key, value);
  return `${originalURL.origin}${originalURL.pathname}?${params.toString()}`;
};

/**
 * Updates window history using window.history.pushState
 *
 * @param {Object} [properties] Arguments
 * @param {string} [properties.key=''] query param key
 * @param {string} [properties.value=''] query param new value
 */
export const updateBrowserHistory = ({ key = '', value = '' }) => {
  const url = updateQueryStringParameter({ key, value });
  window.history.pushState({}, '', url); // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
};
