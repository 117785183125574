import cx from 'classnames';
import globalStyles from 'src/components/docs/DocsBeta/index.module.scss';
import tutorialStyles from './Step.module.scss';

interface StepProps {
  title?: string;
}

const Step: React.FC<StepProps> = ({ title, children }) => {
  return (
    <div className={cx(globalStyles.docs, tutorialStyles.tutorialDocs)}>
      {title && <h4 className={tutorialStyles.tutorialStepTitle}>{title}</h4>}
      <div>{children}</div>
    </div>
  );
};

Step.displayName = 'Step';

export default Step;
