import React from 'react';

const InfoIcon = () => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm1-6h-2v-8h2zM12 5.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z' />
    </svg>
  );
};

export default InfoIcon;
