import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Typography } from 'src/components-v2/DataDisplay';
import { useIsDarkBackground } from 'src/contexts';

import styles from './Pill.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  isSelected?: boolean;
  backgroundColor?: string;
};

export const Pill: React.FC<Props> = (props) => {
  const { children, isSelected = false, backgroundColor, ...rest } = props;
  const isDarkBackground = useIsDarkBackground(backgroundColor);

  return (
    <button
      type='button'
      className={classNames(styles.button, {
        [styles.button_dark]: isDarkBackground,
      })}
      role='tab'
      aria-selected={isSelected || undefined}
      {...rest}
    >
      <Typography
        variant='p2'
        component='span'
        sx={{ color: 'inherit', letterSpacing: { lg: ' -0.27px' } }}
      >
        {children}
      </Typography>
    </button>
  );
};
