import { createElement, Fragment, useState, useEffect } from 'react';
import { OpenAPIObject, PathItemObject } from 'openapi3-ts';

import {
  ServerCodeLanguage,
  TableOfContents,
  ArticleMetadata,
  CodeBlockType,
} from './types';
import {
  SERVER_LANGUAGES,
  IOS_LANGUAGES,
  ANDROID_LANGUAGES,
  DOCS_BASE_URL,
  BETA_DOCS_BASE_URL,
  REACT_NATIVE_IOS_LANGUAGES,
  FRONTEND_LANGUAGES,
  SERVER_LANGUAGE_MAPPING,
  CLIENT_LANGUAGE_MAPPING,
} from './constants';

import {
  ROUTE_MAP,
  ROUTE_MAP_FLAT,
  NavItem,
  NavGroup,
} from './constants/routeMap';
import { EXCHANGE_DOCS_BASE_URL } from '../exchange-docs/constants';
import { CORE_EXCHANGE_DOCS_BASE_URL } from '../core-exchange-docs/constants';
import { DocsState } from 'src/contexts/docs/reducer';

type SelectOption = {
  label: string;
  value: string | number | boolean | null;
};

export const getSelectValue = (
  options: Array<SelectOption>,
  v: string | number | boolean | null,
) => options.find(({ value }) => value === v);

// language-node -> node
// language-foobar -> null (foobar is not a ServerCodeLanguage)
export const languageFromPrismClassName = (
  className: string,
  type: CodeBlockType,
): ServerCodeLanguage => {
  if (className == null) {
    return null;
  }

  const maybeLanguage = className.split('-')[1];

  let languages = [];

  switch (type) {
    case 'ios':
      languages = IOS_LANGUAGES;
      break;
    case 'android':
      languages = ANDROID_LANGUAGES;
      break;
    case 'react_native_ios':
      languages = REACT_NATIVE_IOS_LANGUAGES;
      break;
    case 'frontend':
      languages = FRONTEND_LANGUAGES;
      break;
    case 'server':
    default:
      languages = SERVER_LANGUAGES;
  }

  return languages.find((lang) => lang === maybeLanguage);
};

export const capitalize = (s: string): string =>
  s == null ? '' : s.charAt(0).toUpperCase() + s.slice(1);

// https://github.com/sindresorhus/is-absolute-url
// isAbsoluteUrl :: string -> boolean
export const isAbsoluteUrl = (url: string) => {
  // Don't match Windows paths `c:\`
  if (/^[a-zA-Z]:\\/.test(url)) {
    return false;
  }

  // Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
  // Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
};

export const stripTrailingSlash = (route: string): string => {
  const routeParts = route.split('#');
  routeParts[0] = routeParts[0].replace(/\/$/, '');
  return routeParts.join('#');
};

export const isExternalUrl = (url: string): boolean => {
  if (url.startsWith('//')) return true;
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const withTrailingSlash = (route: string): string => {
  // dont mess with external urls
  if (isExternalUrl(route)) {
    return route;
  }
  const routeParts = route.split('#');
  const base = routeParts[0];
  if (base.length === 0) {
    return route;
  }
  routeParts[0] = base.endsWith('/') ? base : `${base}/`;
  return routeParts.join('#');
};

// get route from the routemap
export const getRoute = (route: string): NavItem | undefined => {
  if (route == null) {
    return;
  }
  const routeWithoutBasePath = route
    .replace(BETA_DOCS_BASE_URL, '')
    .replace(DOCS_BASE_URL, '');
  // we search in reverse so that we find /page's nested children before /page root
  const reversed = [...ROUTE_MAP_FLAT].reverse();
  return reversed.find(
    (r) =>
      stripTrailingSlash(r.path) === stripTrailingSlash(routeWithoutBasePath),
  );
};

// get only top level section, for example `/auth`
export const getSection = (
  route: string,
  basePath?: string,
  routeMap?: NavGroup,
): NavItem | undefined => {
  let routeWithoutBasePath: string;
  if (route.startsWith(EXCHANGE_DOCS_BASE_URL)) {
    routeWithoutBasePath = route.replace(EXCHANGE_DOCS_BASE_URL, '');
  } else if (route.startsWith(CORE_EXCHANGE_DOCS_BASE_URL)) {
    routeWithoutBasePath = route.replace(CORE_EXCHANGE_DOCS_BASE_URL, '');
  } else {
    routeWithoutBasePath = route
      .replace(BETA_DOCS_BASE_URL, '')
      .replace(DOCS_BASE_URL, '');
  }

  if (basePath != null) {
    routeWithoutBasePath = routeWithoutBasePath.replace(basePath, '');
  }
  const routes = routeMap != null ? routeMap : ROUTE_MAP;

  // throw away the rest of the path after the section, e.g. /auth/add-to-app => /auth
  const routeSection = routeWithoutBasePath.split('/').slice(0, 2).join('/');
  return routes
    .flatMap((s) => s.children)
    .find(
      (r) => stripTrailingSlash(r.path) === stripTrailingSlash(routeSection),
    );
};

export const withBasePath = (route: string, basePath?: string): string =>
  basePath != null ? `${basePath}${route}` : `${DOCS_BASE_URL}${route}`;

// React hook for debouncing a value update trigger by a set delay
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => clearTimeout(handler);
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

// takes any string or react node and checks if its api route
export const isApiRoute = (
  pathString: string | React.ReactNode,
  apiDefinition: OpenAPIObject,
): boolean => {
  if (typeof pathString !== 'string' || apiDefinition == null) {
    return false;
  }

  // try to return as early as possible if its not a path
  if (pathString == null || pathString.charAt(0) !== '/') {
    return false;
  }

  const pathDefinition: PathItemObject = apiDefinition.paths[pathString];

  return Boolean(pathDefinition);
};

// takes any string or react node and attempts to look up the externalDocs
// url for that string. e.g. /transactions/get =>
// /docs/api/products/transactions/#transactionsget
export const getDocumentationUrlFromDefinition = (
  pathString: string | React.ReactNode,
  apiDefinition: OpenAPIObject,
): string | null => {
  if (
    typeof pathString !== 'string' ||
    apiDefinition == null ||
    !isApiRoute(pathString, apiDefinition)
  ) {
    return null;
  }

  const pathDefinition: PathItemObject = apiDefinition.paths[pathString];

  // if you just return this nullish coalesced statement this function
  // becomes hard to unit test as it can be undefined or null,
  // so we are explicit
  if (pathDefinition?.post?.externalDocs?.url != null) {
    return withTrailingSlash(
      withBasePath(pathDefinition.post.externalDocs.url),
    );
  }

  return null;
};

// takes a markdown string with raw references to api routes like `/transactions/get`
// and replaces them with a markdown link to that route specified in
// externalDocs property for that route in the the OpenAPI definition
// e.g. `/transactions/get` ->
// [`/transactions/get`](/docs/api/products/transactions/#transactionsget)
export const replaceApiRouteLinks = (
  source: string,
  apiDefinition: OpenAPIObject,
): string => {
  const markdownCodeTagRegExp = new RegExp('`.*?`', 'g');

  return source.replace(markdownCodeTagRegExp, (tag: string) => {
    const maybePath = tag.replace(/`/g, '');
    const documentationUrl = getDocumentationUrlFromDefinition(
      maybePath,
      apiDefinition,
    );
    if (documentationUrl != null) {
      return `[${tag}](${withTrailingSlash(documentationUrl)})`;
    }
    return tag;
  });
};

export const filterTableOfContents = (
  tableOfContents: TableOfContents,
  metadata: ArticleMetadata,
): TableOfContents => {
  if (metadata == null) {
    return tableOfContents;
  }
  if (tableOfContents == null || tableOfContents.length === 0) {
    return [];
  }

  let result = tableOfContents;

  if (metadata.minTocLevel) {
    result = result.filter((t) => t.level >= metadata.minTocLevel);
  }

  if (metadata.maxTocLevel) {
    result = result.filter((t) => t.level <= metadata.maxTocLevel);
  }

  return result;
};

export const normalizeBetaUrl = (path: string): string =>
  path.replace(BETA_DOCS_BASE_URL, DOCS_BASE_URL);

export const createId = (
  baseName: string,
  key: string,
  parentKeys: string[],
): string => {
  const keyKebabCase: string = key.split('_').join('-');
  return `${baseName}${
    parentKeys.length > 0
      ? `-${parentKeys.join('-')}-${keyKebabCase}`
      : `-${keyKebabCase}`
  }`;
};

/**
 * Truncates long strings so they wrap and break at "/" or "_" characters
 * by adding <wbr/> at the nearest character if the length exceeds the
 * "lineLength" parameter.
 *
 * @param longString The string we might want to break up
 * @param lineLength Start breaking up strings longer than this length
 * @returns A react fragment
 */
export const addWordBreaks = (longString: string, lineLength = 22) => {
  const sliceAndInsertBreak = (text: string, character: string) => {
    if (text.length > lineLength) {
      let marker = 0;
      // find the nearest '/' or "_" and mark its place
      for (let i = lineLength - 1; i >= 0; i--) {
        if (text[i] === character) {
          marker = i;
          break;
        }
      }
      if (marker === 0) {
        return createElement(Fragment, null, text);
      }
      return createElement(
        Fragment,
        null,
        text.slice(0, marker),
        createElement(
          'wbr',
          {
            role: 'none',
          },
          null,
        ),
        sliceAndInsertBreak(text.slice(marker), character),
      );
    }
    return createElement(Fragment, null, text);
  };

  // TODO: This logic could probably be redone to make this a little more
  // general purpose
  if (typeof longString === 'string') {
    if (
      // check to see if it is an endpoint
      longString.indexOf('/') === 0
    ) {
      return sliceAndInsertBreak(longString, '/');
    } else if (longString.indexOf('_') > -1) {
      return sliceAndInsertBreak(longString, '_');
    }
  }

  return createElement(Fragment, null, longString);
};

export const getServerCodeFromString = (code: string): ServerCodeLanguage => {
  if (code in SERVER_LANGUAGE_MAPPING) {
    return SERVER_LANGUAGE_MAPPING[code];
  }
  return 'bash';
};

export const getFrontendCodeFromString = (code: string): Partial<DocsState> => {
  switch (code) {
    case 'JAVASCRIPT':
      return { frontendCodeLanguage: 'javascript' };
    case 'REACT':
      return { frontendCodeLanguage: 'tsx' };
    case 'SWIFT':
      return { iOSLanguage: 'swift' };
    case 'OBJECTIVE_C':
      return { iOSLanguage: 'objectivec' };
    case 'KOTLIN':
      return { androidLanguage: 'kotlin' };
    case 'JAVA_FE':
      return { androidLanguage: 'java' };
    case 'REACT_NATIVE':
      return { reactNativeiOSLanguage: 'objectivec' };
    case 'WEB_VIEW':
      return { clientPlatform: 'web' };
    default:
      return { frontendCodeLanguage: 'javascript' };
  }
};
