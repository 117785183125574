import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

// components
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { Alert } from 'src/components/Feedback';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

const LandingPageScripts = dynamic(
  () => {
    return import(
      'src/templates/LandingPageTemplate/LandingPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: TemplateFieldShape.isRequired,
  locale: PropTypes.string,
};

const LandingPageTemplate = ({ fields, locale, ...props }) => {
  const { route } = useRouter();

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      noIndex={fields?.metaNoindex}
      locale={locale}
    >
      <div data-testid={ContentfulTemplates.LANDING_PAGE}>
        {/* @TODO: TBD when to remove FinRise banner https://jira.plaid.com/browse/SITE-3901 */}
        {fields.pageSlug === 'accelerate' && (
          <Alert
            ctaText='Read our blog post'
            href='/blog/accelerate/'
            target='_blank'
            title={false}
            success
          >
            We are excited to announce that Plaid FinRise is now{' '}
            <strong>Plaid Accelerate</strong>.
          </Alert>
        )}
        <CmsComponentSwitch components={fields?.components} />
      </div>
      <LandingPageScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
LandingPageTemplate.propTypes = propTypes;

export default LandingPageTemplate;
