/* eslint-disable import/prefer-default-export */
const codeBody = `http code 200
{
  "accounts": [
    {
      "balances": {
        "available": null,
        "current": 30000,
        "iso_currency_code": "USD",
        "limit": 40000,
        "unofficial_currency_code": null
      }
      ...
    }
  ],
  "liabilities": {
    "student": [
      {
        "account_id": "vokyE5Rn6vHKqDLRXEn5fne7LwbKPLIXGK98d",
        "account_number": "3735928559",
        "disbursement_dates": [
          "2017-05-20",
          "2017-06-20"
        ],
        "expected_payoff_date": "2030-04-21",
        "guarantor": "Gingham Guarantor",
        "interest_rate_percentage": 18.25,
        "is_overdue": false,
        "last_payment_amount": 420,
        "last_payment_date": "2019-03-15",
        "last_statement_issue_date": "2019-03-01",
        "loan_name": "Direct Subsidized Stafford",
        "loan_status": {
          "end_date": "2019-09-24",
          "type": "repayment"
        },
        "minimum_payment_amount": 123,
        "next_payment_due_date": "2019-04-01",
        "origination_date": "2017-04-21",
        "origination_principal_amount": 12345.6,
        "outstanding_interest_amount": 1234.5,
        "payment_reference_number": "4277009102",
        "pslf_status": {
          "estimated_eligibility_date": "2021-01-01",
          "payments_made": 42,
          "payments_remaining": 78
        },
        "repayment_plan": {
          "description": "STANDARD REPAYMENT",
          "type": "standard"
        },
        "sequence_number": "1",
        "servicer_address": {
          "city": "San Matias",
          "country": "US",
          "postal_code": "93405-2255",
          "region": null,
          "street": "2493 Leisure Lane"
        },
        "ytd_interest_paid": 123.4,
        "ytd_principal_paid": 1234.5
      }
    ],
    "credit": [
      {
        "account_id": "LgDOJbZz7pFKpaPNgRpZU57zY395q1I0Lxpew",
        "aprs": [
          {
            "apr_percentage": 23.99,
            "apr_type": "purchase_apr",
            "balance_subject_to_apr": 2345.6,
            "interest_charge_amount": 27.01
          }
        ],
        "is_overdue": false,
        "last_payment_amount": 420,
        "last_payment_date": "2019-03-15",
        "last_statement_issue_date": "2019-03-01",
        "minimum_payment_amount": 123,
        "next_payment_due_date": "2019-04-01"
      }
    ],
    "mortgage": [
      {
        "account_id": "LgDOJbZz7pFKpaPNgRpZU57zY395q1I0Lxpew",
        "current_late_fee": 25.00,
        "escrow_balance": 3141.54,
        "has_pmi": true,
        "has_prepayment_penalty": true,
        "interest_rate": [
          {
            "type": "fixed",
            "percentage": 0.0399,
            "next_scheduled_interest_rate_change": "2025-04-01"
          }
        ],
        "last_payment_amount": 3141.54,
        "last_payment_date": "2019-08-01",
        "account_number": 3120194154,
        "loan_type": "conventional",
        "loan_term": "30 year",
        "origination_date": "2015-08-01",
        "origination_principal_amount": 425000,
        "maturity_date": "2045-07-31",
        "past_due_amount": 2304,
        "property_address": {
          "city": "Malakoff",
          "region": "NY",
          "street": "2992 Cameron Road",
          "postal_code": "14236",
          "country": "US"
        },
        "next_payment_due_date": "2019-11-15",
        "next_monthly_payment": 2156.00,
        "ytd_interest_paid": 123.4,
        "ytd_principal_paid": 1234.5
      }
    ]
  },
  "item": {},
  "request_id": "9GMVig"
}
`;

export const interactiveData = {
  'title-text': 'Make a Liabilities request',
  'description-text': 'Use the Liabilities endpoint to submit a POST request',
  'code-title': 'Retrieve Liabilities request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/liabilities/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Liabilities data',
  'post-run-description-text':
    'The <strong>/liabilities/get</strong> endpoint enables you to retrieve user-permissioned data from different types of accounts including student loans, credit cards, and mortgages',
  'post-run-code-title': 'Retrieve Liabilities response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      'data-title': 'student_loans',
      title: 'Student loans',
      description:
        'Access student loan details including disbursement dates, expected payoff date, interest rate, next payment due date, remittances address, and more',
      'code-line-start': 16,
      'code-highlight-length': 47,
      'custom-icon': 'studentLoans',
    },
    {
      'data-title': 'credit_cards',
      title: 'Credit cards',
      description:
        'Access credit card details including APRs, credit limit, minimum payment amount, next payment due date, last statement issue date, and more',
      'code-line-start': 63,
      'code-highlight-length': 19,
      'custom-icon': 'creditCards',
    },
    {
      'data-title': 'mortgages',
      title: 'Mortgages',
      description: [
        'Access mortgage details including loan number, original loan amount, interest rate, property address, escrow balance, last payment date, and more',
      ],
      'code-line-start': 82,
      'code-highlight-length': 36,
      'custom-icon': 'mortgages',
    },
  ],
  'bottom-cta-url': '/docs/liabilities',
  'bottom-cta-text': 'View API docs',
};
