import { CmsSectionalHeader } from 'src/sections';
import { MultiStateCarousel } from '../MultiStateCarousel';
import { Section } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers';
import { LeftRightAssetAdapter } from './LeftRightSection';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';

const AccordionAdapter = ({ fields, sys }) => {
  return fields?.accordionItems?.map((item) => {
    const title = {
      content: [...(item?.fields?.body?.content || [])]?.filter((content) => {
        return content?.nodeType === 'heading-4';
      }),
    };
    const body = {
      content: [...(item?.fields?.body?.content || [])]?.filter((content) => {
        return content?.nodeType !== 'heading-4';
      }),
    };
    return {
      title: (
        <RichTextSwitch
          sys={item.sys}
          content={title}
          nodes={{
            h4: {
              variant: 'h5',
              fieldId: 'body',
              component: 'h4',
              sx: {
                fontWeight: '600',
                '& svg': {
                  mt: { xs: '0.2rem', md: '0.4rem' },
                  zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
                  color: 'inherit !important', // fixes icon color issues when in the accordion view (colors should match the font color)
                },
              },
            },
            // TODO: it would be nice to handle paragraphs as well
          }}
        />
      ),
      content: (
        <RichTextSwitch
          sys={item.sys}
          content={body}
          nodes={{
            p: {
              variant: 'p',
              fieldId: 'body',
            },
          }}
        />
      ),
      asset: LeftRightAssetAdapter(item),
      sys: item?.sys,
      sx: item?.fields?.styles,
    };
  });
};

const AccordionLeftRightSection = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'inherit';
  const cmsMode = useCmsMode({ mode: fields?.mode, backgroundColor: bgColor });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;
  const isGradient = fields?.backgroundColor?.includes('gradient');
  return (
    <ThemeProvider theme={componentTheme}>
      <MultiStateCarousel
        fieldId='internalTitle'
        appearance='accordion'
        isReversed={fields?.layout === 'Asset left / Text right'}
        items={AccordionAdapter({ fields, sys })}
        sx={{
          background: background || 'inherit',
          ...fields?.styles,
        }}
        sys={sys}
        heading={
          fields?.header ? (
            <CmsSectionalHeader
              fields={{
                ...fields?.header?.fields,
                backgroundColor: isGradient ? 'transparent' : background,
              }}
              sys={fields?.header?.sys}
            />
          ) : null
        }
      >
        {fields?.body && (
          <Section
            component='header'
            sx={{
              pb: { xs: '2.9rem', sm: '2.6rem', md: '4.8rem' },
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
            }}
          >
            <RichTextSwitch
              sys={sys}
              content={fields?.body}
              nodes={{
                h2: {
                  variant: 'h3',
                  component: 'h3',
                  fieldId: 'body',
                },
                h3: {
                  fieldId: 'body',
                  variant: 'h3',
                },
                p: {
                  fieldId: 'body',
                  variant: 'p',
                },
              }}
            />
          </Section>
        )}
      </MultiStateCarousel>
    </ThemeProvider>
  );
};

export default AccordionLeftRightSection;
