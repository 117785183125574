import { interactiveData as assetsData } from './products/assets';
import { interactiveData as authData } from './products/auth';
import { interactiveData as balanceData } from './products/balance';
import { interactiveData as identityData } from './products/identity';
import { interactiveData as liabilitiesData } from './products/liabilities';
import { interactiveData as transactionsData } from './products/transactions';
import { interactiveData as euAssetsData } from './en-eu/products/assets';
import { interactiveData as euAuthData } from './en-eu/products/auth';
import { interactiveData as euBalanceData } from './en-eu/products/balance';
import { interactiveData as euIdentityData } from './en-eu/products/identity';
import { interactiveData as euTransactionsData } from './en-eu/products/transactions';
import { interactiveData as ukAssetsData } from './en-gb/products/assets';
import { interactiveData as ukAuthData } from './en-gb/products/auth';
import { interactiveData as ukBalanceData } from './en-gb/products/balance';
import { interactiveData as ukIdentityData } from './en-gb/products/identity';
import { interactiveData as ukTransactionsData } from './en-gb/products/transactions';

export const interactiveData = {
  Assets: assetsData,
  Auth: authData,
  Balance: balanceData,
  Identity: identityData,
  Liabilities: liabilitiesData,
  Transactions: transactionsData,
  'EU Assets': euAssetsData,
  'EU Auth': euAuthData,
  'EU Balance': euBalanceData,
  'EU Identity': euIdentityData,
  'EU Transactions': euTransactionsData,
  'UK Assets': ukAssetsData,
  'UK Auth': ukAuthData,
  'UK Balance': ukBalanceData,
  'UK Identity': ukIdentityData,
  'UK Transactions': ukTransactionsData,
};
