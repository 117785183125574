import { CmsThemes } from 'src/lib/constants';

export const cardContainerStyles = {
  [CmsThemes.WEB2]: {},
  [CmsThemes.WEB3]: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '1.5rem',
      backgroundImage: (theme) => {
        return theme.palette['web3/component/shadow'];
      },
      filter: 'blur(8px)',
    },
  },
};

export const cardStyles = {
  [CmsThemes.WEB2]: {
    borderRadius: '2.4rem',
    padding: { md: '4.8rem 5.4rem', xs: '2.8rem 3.2rem' },
    border: 'solid 1px',
    borderColor: 'black500',
    '& h3': {
      mb: '1.2rem',
    },
  },
  [CmsThemes.WEB3]: {
    position: 'relative',
    borderRadius: '1.5rem',
    padding: { md: '4.8rem 5.4rem', xs: '2.8rem 3.2rem' },
    backgroundColor: 'base/white',
    '& h3': {
      mb: '1rem',
    },
  },
};
