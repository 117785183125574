import React from 'react';

// components
import { Column, Stack } from 'src/components-v2/Layout';
import { Logo } from './Logo';
import { Wrapper } from 'src/containers';

import { ILogoWall } from './LogoWall.types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const LogoWall: React.FC<ILogoWall> = ({ sx, images, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Wrapper {...inspectorProps({ fieldId: 'internalTitle' })}>
      <Column lg={20} xs={24} lgOffset={2}>
        <Stack
          direction='row'
          sx={{
            justifyContent: 'center',
            gap: {
              xs: '9.5rem 5.7rem',
              sm: images?.length === 4 ? '11.7rem 10%' : '11.7rem 18%',
              lg: images?.length === 4 ? '11.7rem 10%' : '11.7rem 22%',
            },
            flexWrap: 'wrap',
            border: 1,
            borderColor: 'black500',
            borderRadius: '1.6rem',
            pt: { xs: '4.0rem', sm: '6.4rem' },
            pb: { xs: '4.0rem', sm: '5.3rem' },
            background: 'white',
            ...sx,
          }}
        >
          {images?.map((image, index) => {
            return <Logo image={image} sys={sys} key={`logo-${index}`} />;
          })}
        </Stack>
      </Column>
    </Wrapper>
  );
};

export default LogoWall;
