export const pageHeaderData = {
  bgColor: 'green400',
  cta: {
    hasPrimaryCta: true,
    primaryCtaText: 'Get started',
    primaryCtaLink: '//dashboard.plaid.com/signup',
    primaryCtaTrackingId: 'FILP_HERO_GET_API_KEYS_BUTTON',
    hasSecondaryCta: true,
    secondaryCtaText: 'Explore docs',
    secondaryCtaLink: '/docs/',
    secondaryCtaTrackingId: 'FILP_HERO_DOCS_BUTTON',
    hasTertiaryCta: false,
  },
  image: {
    src: '/assets/img/institutions/FILPs-page-header.png',
  },
  labelText: 'FINANCIAL INSTITUTION',
  removeHeightRestriction: true,
};

export const supportingTextData = {
  classes: 'background background--color-white supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '>8,000',
  text1:
    'Plaid powers over 8,000 financial apps and services around the world.',
  stat2: '>12,000',
  text2:
    'Plaid connects to over 12,000 financial institutions in the US, Canada, the UK, and Europe.',
  stat3: '>40,000',
  text3:
    '40,000+ developers are currently building or starting to build with Plaid.',
};

export const featuresPlaidIntegrationData = {
  hasThreeColumns: true,
  items: [
    {
      title: 'Expansive data integration',
      hasConstrainedImage: true,
      img: {
        src: '/assets/img/products/standalone/auth/feature-auth-2.png',
        alt: 'Expansive data integration',
      },
      hasSimpleContent: true,
      hasConstrainedContent: true,
      content: 'Plaid supports {{FI_NAME}} with data connectivity solutions',
    },
    {
      title: 'Set up frictionless payments',
      hasConstrainedImage: true,
      img: {
        src: '/assets/img/products/standalone/auth/feature-auth-3.png',
        alt: 'Set up frictionless payments',
      },
      hasSimpleContent: true,
      hasConstrainedContent: true,
      content: 'Move money with RTP, ACH, Wire, Check, and Virtual Card',
    },
    {
      title: 'Easy integration',
      hasConstrainedImage: true,
      img: {
        src: '/assets/img/products/feature-link-robust-docs-112@2x.png',
        alt: 'Easy integration',
      },
      hasSimpleContent: true,
      hasConstrainedContent: true,
      content: 'Ease of integration with our endpoints',
    },
  ],
};

export const introSectionData = {
  items: [
    {
      label: 'Made for developers, by developers',
      description: [
        'Plaid provides developers with the tools they need to help users make the most of their finances. We power over 8,000 apps and services worldwide, as well as connect to over 12,000 banks and financial institutions across the US, Canada, the UK, and Europe.',
        'From personal and business financial management or banking, to consumer payments, lending, and investments, Plaid enables a wide range of use cases',
      ],
      descriptionSize: '',
      paddingTop: true,
    },
    {
      label: 'How it works',
      description: [
        'Plaid helps users safely connect their {{FI_NAME}} accounts to your app or service in a matter of seconds, thanks in part to an information security program that’s designed to meet or exceed industry standards. And because every Plaid product is built with control, security, and transparency in mind, users can easily manage their Plaid connections at any time.',
        'Supported Plaid products include {{PRODUCTS}}. This means you can focus on what you do best: empowering your users to lead better financial lives.',
      ],
      descriptionSize: '',
    },
  ],
};

export const footerExtensionData = {
  'bg-color': 'bg-white',
  'footer-title': 'Build your financial data integrations with Plaid',
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  primaryCtaTrackingId: 'FILP_FOOTER_GET_API_KEYS_BUTTON',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/',
  secondaryCtaTrackingId: 'FILP_FOOTER_DOCS_BUTTON',
};
