import React from 'react';

import { marketingCmsClient } from 'src/lib/contentful-client';
import { ContentfulTemplates, isTesting } from 'src/lib/constants';
import { TemplateSwitch } from 'src/templates';
import { optimizeContentfulImages } from 'src/lib/image-helpers';
import type { PagePathShape, StaticPathsShape } from 'src/lib/ts-types';
import type { ArticlePathShape } from 'src/templates/Resources/TopicPageTemplate/TopicPage.types';
import type { Entry } from 'contentful';

const Article = (props) => {
  return <TemplateSwitch {...props} />;
};

export async function getStaticPaths(): Promise<StaticPathsShape> {
  // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/entries
  const entries = await marketingCmsClient.getEntries({
    content_type: ContentfulTemplates.ARTICLE,
  });

  const paths = entries.items.reduce(
    (pagePaths: PagePathShape[], entry: Entry<ArticlePathShape>) => {
      if (entry.fields.pageSlug && entry.fields?.topics) {
        const firstTopic = entry.fields.topics[0];

        if (firstTopic.fields.topic) {
          pagePaths.push({
            params: {
              topic: firstTopic.fields.topic
                ?.toLowerCase()
                .split(' ')
                .join('-'),
              article: entry.fields.pageSlug,
            },
          });
        }
      }
      return pagePaths;
    },
    [],
  );
  return {
    paths: isTesting ? [] : paths,
    fallback: false,
  };
}

export async function getStaticProps({ params, preview }) {
  // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/entries

  const article = await marketingCmsClient.getEntries({
    content_type: ContentfulTemplates.ARTICLE,
    include: 10,
    'fields.pageSlug': params.article,
  });

  const data = article.items[0];

  return {
    props: { ...optimizeContentfulImages(data), preview: !!preview },
  };
}

export default Article;
