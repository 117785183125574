import React from 'react';
import PropTypes from 'prop-types';
import { MARKS } from '@contentful/rich-text-types';

const propTypes = {
  bClassName: PropTypes.string,
};

const Bold = ({ bClassName = '' }) => {
  return {
    [MARKS.BOLD]: (text) => {
      // https://www.npmjs.com/package/@contentful/rich-text-react-renderer#note-on-adding-a-key-prop-in-custom-renderers
      return (
        <b key={`${text}-key`} className={bClassName}>
          {text}
        </b>
      );
    },
  };
};

Bold.propTypes = propTypes;

export default Bold;
