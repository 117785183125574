import React, { useEffect } from 'react';
import ThirdPartyScripts from './ThirdPartyScripts';
import useOneTrust from 'src/containers/ConsentManager/useOneTrust';
import Script from 'next/script';
import { PreviewContext, useTrack } from 'src/contexts';
import { isProduction } from 'src/lib/constants';
import { useRouter } from 'next/router';
import { LINKEDIN_CAPI_EVENTS } from 'src/lib/trackingEvents';
import { generateUUID, setLiFatId } from 'src/lib/analytics-utils';

const ConsentManager: React.FC = () => {
  const { consentSettings } = useOneTrust();
  const previewContext = React.useContext(PreviewContext);
  const track = useTrack();
  const { asPath } = useRouter();

  useEffect(() => {
    setLiFatId();
    const uuid = generateUUID();

    track({
      type: LINKEDIN_CAPI_EVENTS.PAGE_VIEW,
      payload: {
        user_data: {
          linkedinFirstPartyId: window.li_fat_id,
        },
        currency: 'USD',
        value: 0,
        event_id: uuid,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  // we hide the cookie banner for previews
  if (previewContext?.preview) {
    return null;
  }

  return (
    <>
      {isProduction && (
        <Script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          data-domain-script='33c336c7-85e9-4246-9792-798feeeb4ed8'
          strategy='beforeInteractive'
        />
      )}
      {!isProduction && (
        <Script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          data-domain-script='33c336c7-85e9-4246-9792-798feeeb4ed8-test'
          strategy='beforeInteractive'
        />
      )}
      <ThirdPartyScripts consentSettings={consentSettings} />
    </>
  );
};

export default ConsentManager;
