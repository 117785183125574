import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

const propTypes = {
  fields: TemplateFieldShape.isRequired,
  locale: PropTypes.string,
  children: PropTypes.element,
};

const ProductsPageTemplateScripts = dynamic(
  () => {
    return import(
      'src/templates/ProductPageTemplate/ProductPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const ProductPageTemplate = ({ fields, locale, children, ...props }) => {
  const { route } = useRouter();
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.footerCta}
      noIndex={fields?.metaNoindex}
      locale={locale}
    >
      <div data-testid={ContentfulTemplates.PRODUCT_PAGE}>
        <CmsComponentSwitch
          templatePage={ContentfulTemplates.PRODUCT_PAGE}
          components={fields.components}
        />
        {children}
      </div>
      <ProductsPageTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};

ProductPageTemplate.propTypes = propTypes;

export default ProductPageTemplate;
