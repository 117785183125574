import React from 'react';

const SidebarOverviewIcon = () => {
  return (
    <svg height={18} width={18} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.6 5.25h-3.2A1.16 1.16 0 009.25 6.4v3.2a1.16 1.16 0 001.15 1.15h3.2a1.16 1.16 0 001.15-1.15V6.4a1.16 1.16 0 00-1.15-1.15zm-.35 4h-2.5v-2.5h2.5zM10 .25A7.76 7.76 0 002.25 8 7.67 7.67 0 004 12.92L.47 16.47l1.06 1.06L5.08 14A7.67 7.67 0 0010 15.75a7.75 7.75 0 000-15.5zM3.75 8a5.7 5.7 0 01.13-1.25h2.37v2.5H3.88A5.7 5.7 0 013.75 8zM10 14.25a6.24 6.24 0 01-5.6-3.5h2.2A1.16 1.16 0 007.75 9.6V6.4A1.16 1.16 0 006.6 5.25H4.4a6.25 6.25 0 115.6 9z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default SidebarOverviewIcon;
