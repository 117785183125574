import React from 'react';
import { ConditionalWrapper } from 'src/components/helpers';
import { Lazy } from 'src/components-v2/Helpers';
import { optimizeImage } from 'src/lib/image-helpers';

interface Props {
  className?: string;
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  isLazy?: boolean;
  isDesktopOnly?: boolean;
}

const Image: React.FC<Props> = ({
  className = '',
  src = '',
  alt = '',
  width,
  height,
  isLazy = true,
  isDesktopOnly = false,
  ...props
}) => {
  return (
    <Lazy isLazy={isLazy} height={height}>
      <ConditionalWrapper
        condition={isDesktopOnly}
        wrapper={(children) => {
          return (
            <picture>
              {/* small base64 data url for equivalent-to-null image for mobile */}
              <source
                srcSet='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                media='(max-width: 639px)'
              />
              {children}
            </picture>
          );
        }}
      >
        <img
          className={className}
          src={optimizeImage(src)}
          alt={alt}
          width={width}
          height={height}
          {...props}
        />
      </ConditionalWrapper>
    </Lazy>
  );
};

export default Image;
