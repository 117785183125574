export const metaData = {
  metaTitle: 'Varo',
  metaDescription:
    'In just five months, more than 176,000 Varo customers have used Plaid Direct to connect their Varo accounts to outside apps and services.',
};

export const pageHeaderData = {
  classes:
    'background background--color-purple600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-purple-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Varo',
  header: 'A bank that builds financial health',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/varo-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-varo@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-varo@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Onboard new users,<br />connect user bank accounts',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Banking',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Varo needed a way for users to seamlessly link their external bank accounts to Varo—as well as a way for users to link their Varo accounts to outside apps and institutions.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'In just five months, more than 176,000 Varo customers have used Plaid Direct to connect their Varo accounts to outside apps and services.',
};

export const content1Data = {
  heading:
    'Varo is an online mobile banking company committed to improving lives by making premium financial services available to everyone.',
  paragraph: [
    'Collin Walsh started the company in 2015 on the principle that a bank could put customers’ interests first and still be profitable. The app launched in July 2017 on iOS, and since then, Varo has seen $600 million come through the platform—including more than $50 million in savings.',
    'In August 2019, Varo took the next step: the company now offers no-fee overdraft protection on overdrafts of up to $50 for users who meet certain eligibility requirements. $50 may not sound like much, but it can make all the difference to someone struggling with financial health. Consider:',
    {
      list: [
        '4 out of 10 millennials have paid an overdraft fee in the last year',
        'The average overdraft amount was a negative balance of just $50',
        'Over the course of a year, the average over-drafter paid $225 in fees',
        'All told, Americans paid more than $34.5 billion in overdraft fees in 2018',
        '40% of Americans don’t have $400 in savings',
      ],
    },
    '"More than half of Americans are cash-strapped,” said Colin Walsh, CEO of Varo. “I believe that banks have an obligation to help people get ahead financially. At the very least, they shouldn’t make things worse by charging fees and being opaque.',
    'For Varo to continue to live into its mission and provide superior service, they needed a way for users to seamlessly link their external bank accounts to Varo—as well as a way for users to link their Varo accounts to outside apps and institutions. When a previous vendor wasn’t up to the job, Varo turned to Plaid.',
  ],
};

export const supportingTextData = {
  classes:
    'background background--color-purple200 supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '1.5K',
  text1:
    'Every day, more than 1,500 Varo customers use Plaid Direct to connect their Varo accounts to outside apps and services.',
  stat2: '176K',
  text2:
    'In just five months, more than 176,000 Varo customers have used Plaid Direct to connect their Varo accounts to outside apps and services.',
  stat3: '15%',
  text3:
    'Since partnering with Plaid, Varo’s success rate for linking customer bank accounts has improved by 15%.',
};

export const content2Data = {
  title: 'People before profit',
  'narrow-title': true,
  paragraph: [
    'Varo offers customer-first banking products that help people save money, earn interest, and finance their goals, including:',
    {
      list: [
        'No-fee, FDIC-insured mobile checking accounts',
        'Access to paychecks up to two days early',
        'No-minimum, high-interest savings accounts',
        'Automated savings tools',
        'Debit card + large, fee-free ATM network',
        'Fixed-rate personal loans',
      ],
    },
    'The goal is to improve user financial health, and the data show that it’s working. Since 2018, Varo has seen the number of new savings accounts opened by users grow by 670%. What’s more, of the $50 million users have saved, more than 20% has come through Varo’s automated savings tools.',
    'But with Varo’s explosive growth have come a few growing pains. Namely, the technology that Varo was using to link customers’ external bank accounts wasn’t living up to user expectations. The process wasn’t intuitive, and less than half of linking attempts were successful. As a result, Varo was receiving a high volume of customer complaints.',
    'In response, Varo issued an RFI to service providers. They compared things like institution coverage, data quality, linking speeds, user experience—and ultimately selected Plaid.',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-purple-1@2x.png',
  'stroke-bottom-right':
    '/assets/img/accents/diagram-brushstroke-purple-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Varo’s onboarding flow asks customers to link an outside bank account. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! Varo’s customers can start funding their new accounts in a matter of seconds.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-purple@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img:
        '/assets/img/customer-stories/diagram-step-link-mobile-purple@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-purple@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-purple@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Better banking for all',
    'narrow-title': true,
    paragraph: [
      'Broadly speaking, Varo uses Plaid for two things. The first is allowing users to connect their outside bank accounts to Varo. The second is exporting data from users’ Varo accounts to outside apps and services: places like Acorns, Betterment, and Schwab.',
      'In the first case, Varo allows customers to connect their outside bank accounts. That way, customers can fund their Varo accounts via ACH transfer. They can also see their outside bank account balances and transactions in the Varo app. From Varo’s point of view, the ability to see the customer’s wider financial life allows Varo to provide more relevant offers for financial products and services.',
      'Since launching its Plaid integration, Varo has seen a 15% improvement in the success rate of linking customers’ external bank accounts. As a result, the topic is no longer a significant driver of calls to Varo’s customer service team. According to Pelham Burn, linking was once a top-five customer service issue. Now, with Plaid, it doesn’t break the top ten.',
      '“Now that we don’t have to focus so much on the process, we can focus on outcomes that are higher value-add for the customer,” he said.',
      'In the second case, Varo allows users to send data from their Varo accounts to outside apps and services. That way, they can see their Varo account balance and transactions in a budgeting app like Truebill. They can also use their Varo account to fund savings apps like Oportun or Acorns.',
      'The service is called Plaid Direct, and the takeup has been tremendous. Every day, more than 1,500 Varo customers use Plaid to connect their Varo accounts to outside apps and services. In fact, in the five months since Varo started offering Plaid Direct, more than 176,000 Varo users have taken advantage of the service.',
      '“Our customers appreciate the consolidated view of their finances, and Plaid makes it possible in a safe and secure way,” said Pelham Burn. “It’s an essential part of the mix.”',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-varo@2x.png',
      alt: 'Headshot of James Pelham Burn, Product Manager at Varo',
    },
    quote: [
      {
        copy:
          'Now that we don’t have to worry about banking integrations, we can focus on things that are higher value-add for the customer.',
        source: 'James Pelham Burn, Product Manager, Varo',
      },
    ],
  },
  {
    title: 'Better data, better products',
    paragraph: [
      'In 2020, Varo aims to become the first nationally chartered bank built for people who bank on their smartphones. In the fall of 2018, they received preliminary approval from the Office of the Comptroller of the Currency.',
      'The goal is to become a true challenger to legacy financial firms—without losing sight of the customer’s best interests. Competing on that level means developing products and services on par with the market’s biggest banks. And that will require some great data.',
      '<strong>“Plaid lets us see the full picture, so we can develop products and services to truly support our customers.” Pelham Burn explained. “That is helping to deliver our transformation.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
    {
      name: 'Brigit',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-brigit@2x.png',
        alt: 'Brigit - read full story',
      },
      excerpt: 'A responsible way to bridge through money shocks',
      url: '/customer-stories/brigit',
    },
    {
      name: 'Prosper',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-prosper@2x.png',
        alt: 'Prosper - read full story',
      },
      excerpt: 'Helping people invest in each other',
      url: '/customer-stories/prosper',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/varo-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
