import React from 'react';

type Wrapper = (children: React.ReactNode) => any;

interface Props {
  condition: boolean | string;
  wrapper: Wrapper;
  alternateWrapper?: Wrapper;
  children: React.ReactNode;
}

const ConditionalWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  alternateWrapper = null,
  children,
}) => {
  if (condition) {
    return wrapper(children);
  }
  if (alternateWrapper) {
    return alternateWrapper(children);
  } else {
    return children;
  }
};

export default ConditionalWrapper;
