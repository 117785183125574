/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for mvp feature section item content type to the view
 *
 * @param {Object} args
 * @param {Object} args.fields
 * @returns {Object}
 */
export const mvpFeatureSectionItemAdapter = ({ fields }) => {
  return {
    img: {
      src: _get(fields, 'image.fields.file.url'),
      alt:
        fields?.image?.fields?.description || fields?.title || 'feature icon',
    },
    title: _get(fields, 'title'),
    content: _get(fields, 'content'),
    isBetaItem: _get(fields, 'isBetaItem'),
    cta: {
      href: _get(fields, 'cta.fields.url'),
      text: _get(fields, 'cta.fields.text'),
    },
    hasBetaItems: _get(fields, 'isBetaItem'),
    //todo: (in order to make this adapter useful for other than just previews) https://github.plaid.com/plaid/plaid-dot-com/pull/4297/files#r409458
    hasThreeColumns: false, // hard coded for purpose of features item preview only
    assetSize: '112x112', // hard coded for purpose of features item preview only
  };
};

/**
 * Maps the contentful response for mvp feature section content type to the view
 *
 * @param {Object} args
 * @param {string} args.label
 * @param {boolean} args.hasThreeColumns
 * @param {Object[]} args.featureItems
 * @param {number=} args.customPaddingTop
 * @param {number=} args.customPaddingBottom
 * @param {string} args.internalTitle
 * @param {string} args.assetSize
 * @returns {Object}
 */
export const mvpFeatureSectionAdapter = ({
  label,
  title = '',
  hasThreeColumns,
  featureItems = [],
  customPaddingTop,
  customPaddingBottom,
  internalTitle,
  assetSize,
}) => {
  const hasBetaItems = featureItems.some((item) => item?.fields.isBetaItem);

  // 48x48 and 240x240 should only be able to render with 3 columns
  // See: http://pl/cms-features-section-doc
  const assetSizeToColumnSetting = {
    '48x48': true,
    '240x240': true,
  };
  const shouldRenderWithThreeColumns =
    assetSizeToColumnSetting[assetSize] ?? hasThreeColumns;

  return {
    label,
    title,
    hasBetaItems,
    hasThreeColumns: shouldRenderWithThreeColumns,
    items: featureItems.map((item) => ({
      img: {
        src:
          _get(item, 'fields.image.fields.file.url') &&
          _get(item, 'fields.image.fields.file.url'),
        alt:
          item?.fields?.image?.fields?.description ||
          item?.fields?.title ||
          'feature icon',
      },
      title: _get(item, 'fields.title'),
      content: _get(item, 'fields.content'),
      isBetaItem: _get(item, 'fields.isBetaItem'),
      cta: {
        href: _get(item.fields.cta, 'fields.url'),
        text: _get(item.fields.cta, 'fields.text'),
      },
      hasBetaItems,
      hasThreeColumns: shouldRenderWithThreeColumns,
      assetSize,
    })),
    customPaddingTop: Number.isInteger(customPaddingTop)
      ? `${customPaddingTop}px`
      : '',
    customPaddingBottom: Number.isInteger(customPaddingBottom)
      ? `${customPaddingBottom}px`
      : '',
    uid: internalTitle.toLowerCase().replace(/\s/g, '-'),
  };
};
