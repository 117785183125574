import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import styles from './OrderedList.module.scss';
import cx from 'classnames';

const propTypes = {
  olClassName: PropTypes.string,
};

const OrderedList = ({ olClassName = '' }) => {
  return {
    [BLOCKS.OL_LIST]: (node) => {
      return (
        <ol
          className={cx(styles.ol, olClassName)}
          dangerouslySetInnerHTML={{ __html: documentToHtmlString(node) }}
        />
      );
    },
  };
};

OrderedList.propTypes = propTypes;

export default OrderedList;
