export const metaData = {
  metaTitle: 'Wave',
  metaDescription:
    'When Wave’s end-users choose to connect their bank accounts with Plaid, they are 5.6 times less likely to make a mistake resulting in a failed payment.',
};

export const pageHeaderData = {
  classes:
    'background background--color-blue600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-blue-3@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Wave',
  header: 'Unlocking the potential of entrepreneurs',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/wave-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-wave@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-wave@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Reduce fraud risk,<br />avoid credit card fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'Canada, United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Business financial management',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'In 2017, Wave launched their ACH bank payments experience. Their goal was simple: make ACH as easy as paying with a credit card.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'When Wave’s end-users choose to connect their bank accounts with Plaid, they are 5.6 times less likely to make a mistake resulting in a failed payment.',
};

export const content1Data = {
  heading:
    'Wave believes that no small business should fail because they don’t understand their finances.',
  paragraph: [
    'It’s a pressing need. All told, there are 31 million small businesses in North America; collectively, they employ more than 65 million people. But more than 50% of those companies will fail in their first five years—often because they don’t know how to manage their money.',
    'To help them succeed, Wave provides accounting, invoicing, payments, and payroll software on a comprehensive platform built specifically for small businesses. The Toronto-based company believes that cost shouldn’t be an issue, so they provide many of their products for free.',
    '“We support entrepreneurs by making it simple for small business owners to understand their finances and manage cash flow. That requires aggregating the financial data that’s locked up in their online banking and credit card platforms,” said Mani Fazeli, Senior Vice President of Product at Wave.',
    'One problem Wave solves for their customers is accepting online payments. They know that small business owners want to get paid fast and through the method of their choice. With Wave, they can accept both credit card and ACH (bank payments) through online invoices.',
    'In 2017, Wave launched their ACH bank payments experience, powered by Plaid data. Their goal was simple: make ACH as easy as paying with a credit card.',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-blue200 supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '60%',
  text1:
    'When payers choose a Plaid-powered account authentication experience (versus manually entering their account and routing numbers), Wave has 60% less confirmed fraud.',
  stat2: '5.6x',
  text2:
    'When payers choose a Plaid-powered account authentication experience (versus manually entering their account and routing numbers), they are 5.6 times less likely to make a mistake resulting in a failed payment.',
  stat3: '90%',
  text3:
    'With Plaid, Wave is able to deposit 90% of ACH bank payments on invoices within two business days, an industry-leading rate.',
};

export const content2Data = {
  title: 'Swift and easy payments',
  paragraph: [
    'Without Plaid, anyone who wants to pay one of Wave’s customers with ACH has to fish out their checkbooks and manually enter account and routing numbers. It’s a clunky experience that often results in failed payments. Even worse, it can take as long as 7 days for the customer to receive funds.',
    'After carefully evaluating their options, Wave chose Plaid to help them make it better.',
    '“We needed a way to entice payers to try ACH,” said Fazeli. “We chose Plaid because we love the quality of the data and how much thought they have put into elevating the user experience.”',
    'Today the vast majority of payers can securely authenticate their bank accounts and start paying in a matter of seconds. Plaid now connects to all major Canadian banks, as well as all ~11,500 US banks and credit unions.',
    'For Wave’s customers, it’s a game-changer. Today, for almost 90% of ACH bank payments, Wave can settle funds into the bank account of the small business owner within just two business days—an industry-leading rate.',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-blue-1@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-blue-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Wave’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy: 'That’s it! End-users can start paying in a matter of seconds.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Better UX, less risk',
    paragraph: [
      'When Wave was comparing different ways for payers to authenticate their bank accounts, they prioritized three criteria:',
      {
        list: [
          'Provide a simple, elegant user experience',
          'Reduce errors from manually entering bank account details',
          'Mitigate fraud risk',
        ],
      },
      'They evaluated multiple vendors and found that Plaid outperformed the competition in several key metrics, including speed, authentication success, and data quality.',
      'When payers choose a Plaid-powered account authentication experience (versus manually entering their account and routing numbers), they are 5.6 times less likely to make a mistake resulting in a failed payment. In Canada, where users who manually enter their information must also provide an institution number, Plaid results in 10x fewer failed payments.',
      'Plaid also helps Wave prevent fraud. How exactly? Plaid provides additional layers of security, so it’s tougher for bad actors to make a fraudulent payment.',
      'The data bear this out. When payers choose a Plaid-powered account authentication experience, instances of suspected fraud are 71% less common, and instances of confirmed fraud are 44% less common.',
      'In terms of sheer dollars, Plaid accounts for 72% less suspected fraud and 60% less confirmed fraud.',
      'Why do we mention both confirmed suspected fraud? The answer is that, in order to catch bad actors, Wave has to investigate all instances of suspected fraud, which is expensive and time-consuming. Plaid helps them minimize the number of investigations.',
      '“We know that fraudsters are looking for the easiest way to make a buck, and they hit a significant roadblock with Plaid” Fazeli said.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-wave@2x.png',
      alt: 'Headshot of Mani Fazeli, Senior Vice President of Product at Wave',
    },
    quote: [
      {
        copy:
          'We know that fraudsters are looking for the easiest way to make a buck, and they hit a significant roadblock with Plaid.',
        source: 'Mani Fazeli, Senior Vice President of Product, Wave',
      },
    ],
  },
  {
    title: 'Mission-driven technology',
    paragraph: [
      'With Plaid, Wave helps business owners get paid several days faster than traditional ACH bank payments, checks, or cash.',
      'It’s just one of the reasons that they are experiencing such rapid growth. To date, Wave has helped over 400,000 small businesses in 200 countries manage their income and expenses. Today they manage more than $250 billion on behalf of their customers and have enjoyed year-over-year quarterly revenue growth of more than 40% for the past several years.',
      '“With Plaid, our customers don’t have to get out their checkbooks. They sign in with the same bank username and password they use every day.” said Wylie Kyi, senior product manager at Wave. “That’s a huge win.”',
      'Industry heavyweights are taking notice: in June 2019, Wave was acquired by H&R Block for $405 million. After the success of ACH, Wave has started using Plaid to automate bookkeeping for its North American customers.',
      '<strong>“Thanks to technologies like Plaid, we can build delightful financial services that enable us to give away our software for free,” Fazeli said. “That unlocks the potential of millions of entrepreneurs around the world.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Alto',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-alto@2x.png',
        alt: 'Alto - read full story',
      },
      excerpt: 'A pharmacy for the digital age',
      url: '/customer-stories/alto',
    },
    {
      name: 'Metal',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-metal@2x.png',
        alt: 'Metal - read full story',
      },
      excerpt: 'Personal finance for all',
      url: '/customer-stories/metal',
    },
    {
      name: 'Varo',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-varo@2x.png',
        alt: 'Varo - read full story',
      },
      excerpt: 'A bank that builds financial health',
      url: '/customer-stories/varo',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/wave-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
