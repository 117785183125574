import { useEffect, useState } from 'react';
import { setCookie, getCookie } from 'public/assets/js/analytics/cookie';

interface ILocale {
  localeCode?: string;
  countryRegionName?: string;
  intrinsicLocale?: string;
}

interface IUserLocale {
  setLocale: (string) => void;
  locale: ILocale;
}

const localeCookieAdapter = (localeCookie: string): ILocale => {
  if (localeCookie) {
    try {
      return JSON.parse(decodeURIComponent(localeCookie));
    } catch (e) {
      return {
        localeCode: localeCookie || null,
        countryRegionName: null,
        intrinsicLocale: null,
      };
    }
  }
  return {
    localeCode: localeCookie || null,
    countryRegionName: null,
    intrinsicLocale: null,
  };
};

const getUserLocale = () => {
  if (typeof window === 'undefined') {
    return localeCookieAdapter('');
  }
  let userLocale = localeCookieAdapter(getCookie('locale'));
  try {
    if (!userLocale?.localeCode) {
      userLocale = localeCookieAdapter(localStorage.getItem('locale'));
    }
  } catch (e) {
    console.log('local storage not available'); // eslint-disable-line
  }
  return userLocale;
};

const useUserLocale = (): IUserLocale => {
  const [state, setState] = useState<ILocale>(getUserLocale());
  useEffect(() => {
    setState(getUserLocale());
  }, []);

  const setLocale = (localeCode: string): void => {
    const newState = {
      ...state,
      localeCode,
    };

    setCookie({
      cname: 'locale',
      cvalue: JSON.stringify(newState),
    });
    try {
      localStorage.setItem('locale', JSON.stringify(newState));
    } catch (error) {
      console.log('local storage not available'); // eslint-disable-line
    }
    setState(newState);
  };

  // return null if no locale can be parsed
  // this will be used later to render a banner asking user to explicitly set their locale
  return {
    locale: state,
    setLocale,
  };
};

export default useUserLocale;
