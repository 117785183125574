import React, { FC } from 'react';
import Image from 'src/components/Image';
import { ContactForm } from 'src/components/Forms';
import DefaultTemplate from 'src/templates/default';

import { Locales } from 'src/lib/constants';
import { dangerous_getStaticData } from 'src/lib/getStaticData';

interface Props {
  locale?: string;
}

const Contact: FC<Props> = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    header,
    subheader,
    socialProofData,
    ctas,
  } = dangerous_getStaticData({
    locale,
    filePath: 'contact',
  });
  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <section className='background-page-wrapper'>
        <div className='grid-container section-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell medium-6'>
              <h2 className='section-header'>{header}</h2>
            </div>
          </div>
          <div className='grid-x grid-margin-x contact-form-grid'>
            <div className='cell small-12 large-auto'>
              <p className='regular section-subheader'>{subheader}</p>
              <ContactForm />
            </div>

            <div className='cell small-10 medium-6 large-auto'>
              <div className='separator mb1'></div>
              <p
                dangerouslySetInnerHTML={{
                  __html: socialProofData?.description,
                }}
              />
              <div className='grid-x small-up-2 mb3'>
                {socialProofData.items.map((item, i) => {
                  return (
                    <div
                      key={`item-${i}`}
                      className='cell social-proof-logo social-proof-logo--no-padding'
                    >
                      <Image src={item.src} alt={item.alt} />
                    </div>
                  );
                })}
              </div>

              <div className='separator mb1'></div>
              <p className='mb3'>{ctas?.description}</p>
              {ctas?.links?.map((link) => {
                return <p dangerouslySetInnerHTML={{ __html: link }} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </DefaultTemplate>
  );
};
Contact.displayName = 'Contact';
export default Contact;
