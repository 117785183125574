export const apiCreateExampleData = {
  flipped: true,
  'bg-first-image': true,
  'title-text': 'Retrieve payment request data',
  'description-text':
    'The <strong>/payment/create</strong> endpoint allows you to create a payment initiation request',
  'code-title': 'Create payment response',
  'code-classes': 'code-container--payment-create',
  'code-lang': 'json',
  'code-body': `
  http code 200
  {
    "payment_id": "payment-id-sandbox-feca8a7a-5591-4aef-9297-f3062bb735d3",
    "status": "PAYMENT_STATUS_INPUT_NEEDED",
    "request_id": "4ciYVmesrySiUAB"
  }
  `,
  properties: [
    {
      title: 'payment_id',
      description:
        'The backend identifier of the payment request and can be used to retrieve metadata for the payment request',
      'code-line-start': 3,
      'code-highlight-length': 1,
    },
    {
      title: 'status',
      description:
        'Describes whether the payment has been initiated, needs user input, or has been verified',
      'code-line-start': 4,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/payment-initiation',
  'bottom-cta-text': 'View API docs',
};

export const apiGetExampleData = {
  'title-text': 'Retrieve payment status data',
  'description-text':
    'The <strong>/payment/get</strong> endpoint allows you to retrieve the status of a payment and returns details such as the amount and recipient_id',
  'code-title': 'Get payment response',
  'code-classes': 'code-container--payment-get',
  'code-lang': 'json',
  'code-body': `
  http code 200
  {
    "payment_id": "payment-id-sandbox-feca8a7a-5591-4aef-9297-f3062bb735d3",
    "payment_token": "payment-token-sandbox-c6a26505-42b4-46fe-8ecf-bf9edcafbebb",
    "reference": "Account Funding 99744",
    "amount": {
      "currency": "GBP",
      "value": 100.00
    },
    "status": "PAYMENT_STATUS_INPUT_NEEDED",
    "last_status_update": "2019-11-06T21:10:52Z",
    "recipient_id": "recipient-id-sandbox-9b6b4679-914b-445b-9450-efbdb80296f6",
    "request_id": "aEAQmewMzlVa1k6"
  }
  `,
  properties: [
    {
      title: 'amount',
      description: 'Settled value in specified currency',
      'code-line-start': 6,
      'code-highlight-length': 4,
    },
    {
      title: 'last_status_update',
      description: 'The last time that the payment status was updated',
      'code-line-start': 11,
      'code-highlight-length': 1,
    },
    {
      title: 'recipient_id',
      description:
        'The unique identifier of the payment recipient, returned by the /payment_initiation/recipient/create endpoint',
      'code-line-start': 13,
      'code-highlight-length': 1,
    },
    {
      title: 'status',
      description:
        'Describes whether the payment has been initiated, needs user input, or has been verified',
      'code-line-start': 10,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/payment-initiation',
  'bottom-cta-text': 'View API docs',
};
