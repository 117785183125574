import React, { FC } from 'react';
import cx from 'classnames';

// components
import { Checkbox, TextInput } from 'src/components/Inputs';
import Image from 'src/components/Image';
import Tooltip from 'plaid-threads/Tooltip';

import styles from './FormSectionSolutionsFields.module.scss';

import { FormSectionSolutionsFieldsProps } from './FormSectionSolutionsFields.types';

const FormSectionSolutionsFields: FC<FormSectionSolutionsFieldsProps> = ({
  formData,
  setValue,
  errors,
  register,
  clearErrors,
}) => {
  return (
    <fieldset className='solutions-field'>
      <p>{formData.solutions.label}</p>
      {formData.solutions.options.map((option) => {
        return (
          <div
            className={cx(
              'checkbox-tooltip-container',
              styles.checkboxContainer,
            )}
          >
            <Checkbox
              key={option.id}
              id={option.id}
              name={option.id}
              onChange={(e) => {
                setValue(option.id, e.target.checked);
                clearErrors('solutions');
              }}
              hasError={!!errors?.['solutions']?.message}
            >
              {option.label}
            </Checkbox>
            <Tooltip
              placement='top'
              showOn='hover'
              small
              target={
                <Image
                  className='info-dropdown inject-me'
                  src='/assets/img/icons/info-icon.svg'
                  aria-describedby='learn more'
                  alt='information icon'
                />
              }
            >
              {option.tooltipDescription}
            </Tooltip>
          </div>
        );
      })}
      <div style={{ height: errors?.['solutions']?.message ? 'auto' : 0 }}>
        <TextInput
          type='hidden'
          label=''
          name={'solutions'}
          id={'solutions'}
          errorMessage={errors?.['solutions']?.message}
          register={register}
        />
      </div>
    </fieldset>
  );
};

export default FormSectionSolutionsFields;
