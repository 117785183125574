import React from 'react';

export const getBorderStyles = (
  activeIndex: number,
  index: number,
  isDarkBackground: boolean,
) => {
  const isActive = activeIndex === index;
  const styles = {
    borderTop: { xs: 1, sm: index > activeIndex ? 0 : 1 },
    borderTopColor: {
      xs: isActive
        ? isDarkBackground
          ? 'white'
          : 'black'
        : isDarkBackground
        ? 'black800'
        : 'black600',
      sm:
        index < activeIndex
          ? isDarkBackground
            ? 'black800'
            : 'black600'
          : isDarkBackground
          ? 'white'
          : 'black',
    },
    borderBottom: { sm: index < activeIndex ? 0 : 1 },
    borderBottomColor: {
      sm:
        index > activeIndex
          ? isDarkBackground
            ? 'black800'
            : 'black600'
          : isDarkBackground
          ? 'white'
          : 'black',
    },
    '&:last-child': {
      borderBottom: { xs: 1, sm: index < activeIndex ? 0 : 1 },
      borderBottomColor: {
        xs: isActive
          ? isDarkBackground
            ? 'white'
            : 'black'
          : isDarkBackground
          ? 'black800'
          : 'black600',
        sm:
          index > activeIndex
            ? isDarkBackground
              ? 'black800'
              : 'black600'
            : isDarkBackground
            ? 'white'
            : 'black',
      },
    },
  };

  return styles;
};

export const recursiveMap = (children, fn) => {
  return React.Children.map(children, (child: React.ReactElement) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    // @ts-ignore
    if (child.props.children) {
      child = React.cloneElement(child, {
        // @ts-ignore
        children: recursiveMap(child.props.children, fn),
      });
    }

    return fn(child);
  });
};
