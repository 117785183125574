export const metaData = {
  metaTitle: 'Auth API - instant bank account verification',
  metaDescription:
    "Plaid's Auth API enables companies to retrieve user's sort code and account numbers, using secure Open Banking integrations.",
};

export const productHeaderData = {
  'hero-one-poster-url':
    '/assets/img/products/standalone/auth/hero-auth-1-poster-us-en.png',
  'hero-one-poster-fallback-url':
    '/assets/img/products/standalone/auth/hero-auth-1-fallback-intl.png',
  'hero-one-video-url':
    '/assets/img/products/standalone/auth/hero-auth-1-us-en.webm',
  'hero-one-video-fallback-url':
    '/assets/img/products/standalone/auth/hero-auth-1-us-en.mp4',
  'hero-one-video-width': null,
  'hero-one-video-height': null,
  'hero-two-poster-url':
    '/assets/img/products/standalone/auth/hero-auth-2-poster-us-en.png',
  'hero-two-poster-fallback-url':
    '/assets/img/products/standalone/auth/hero-auth-2-fallback-us-en.png',
  'hero-two-video-url':
    '/assets/img/products/standalone/auth/hero-auth-2-us-en.webm',
  'hero-two-video-fallback-url':
    '/assets/img/products/standalone/auth/hero-auth-2-us-en.mp4',
  'hero-two-video-width': null,
  'hero-two-video-height': null,
  hasImageOnly: true,
  heroImageOneWidth: 606,
  heroImageOneHeight: 514,
  label: 'Auth',
  description: 'Seamlessly setup direct debits and payouts',
  'top-cta-text': 'Get started',
  'top-cta-url': 'http://dashboard.plaid.com/signup',
  'bottom-cta-text': 'View API docs',
  'bottom-cta-url': '/docs/auth',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'product features',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'wider-feature': true,
      'feature-img': '/assets/img/products/standalone/auth/feature-auth-1.png',
      'feature-title': 'Increase conversion rates',
      'feature-body':
        'Retrieve account number, sort code, IBAN, and BIC automatically within your application’s flow',
    },
    {
      'wider-feature': true,
      'feature-img':
        '/assets/img/products/standalone/auth/feature-auth-2-intl.png',
      'feature-title': 'Prevent misdirected payments',
      'feature-body':
        'Ensure users set up payments with the correct account number and sort code',
      'prevent-widow': true,
    },
    {
      'wider-feature': true,
      'feature-img': '/assets/img/products/features-open-banking-psd2.png',
      'feature-title': 'Open Banking and PSD2 integrations',
      'feature-body':
        'Plaid’s integrations are Open Banking and PSD2 compliant, delivering secure and reliable access to the UK and European financial ecosystem',
      'prevent-widow': true,
    },
  ],
};

export const interactiveData = {
  modifier: 'extended',
  'title-text': 'Make an Auth request',
  'description-text': 'Use the Auth endpoint to submit a POST request',
  'code-title': 'Retrieve Auth request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/auth/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Instant account authentication',
  'post-run-description-text':
    'Once a user securely connects their account using bank credentials, the <strong>/auth/get</strong> endpoint enables you to retrieve the account number, sort code, IBAN, and BIC, along with high-level account data and balances when available',
  'post-run-code-title': 'Retrieve Auth response',
  'post-run-code-lang': 'json',
  'post-run-code-body':
    '\nhttp code 200\n{\n  "accounts": [{\n    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",\n    "balances": {\n      "available": 100,\n      "current": 110,\n      "limit": null,\n      "iso_currency_code": "GBP",\n      "unofficial_currency_code": null,\n    },\n    "mask": "9606",\n    "name": "Plaid Current Account",\n    "official_name": "Plaid Gold Current Account",\n    "subtype": "checking",\n    "type": "depository"\n  }],\n  "numbers": {\n    "ach": [{\n      "account": "9900009606",\n      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",\n      "routing": "011401533",\n      "wire_routing": "021000021"\n    }],\n    "eft":[{\n      "account": "111122223333",\n      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",\n      "institution": "021",\n      "branch": "01140"\n    }],\n    "bacs":[{\n      "account": "31926819",\n      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",\n      "sort_code": "601613"\n    }],\n    "international": [{\n      "account_id": "intlxyz",\n      "iban": "987654321",\n      "bic": "DABAUE2D"\n    }]\n  }\n  "item": {Object},\n  "request_id": "m8MDnv9okwxFNBV"\n}',
  properties: [
    {
      title: 'UK',
      'preserve-title-case': true,
      description:
        'Retrieve account number, sort code, IBAN and BIC to enable seamless debit withdrawals using Faster Payments',
      'code-line-start': 31,
      'code-highlight-length': 5,
    },
    {
      title: 'Europe',
      'preserve-title-case': true,
      description:
        'Retrieve IBAN and BIC to enable seamless debit withdrawals using SEPA',
      'code-line-start': 36,
      'code-highlight-length': 5,
    },
    {
      title: 'US',
      'preserve-title-case': true,
      description:
        'Retrieve account number and routing number to authenticate a checking or savings account for ACH payments',
      'code-line-start': 19,
      'code-highlight-length': 6,
    },
    {
      title: 'Canada',
      'preserve-title-case': true,
      description:
        'Retrieve account number, institution number, and branch number to authenticate an account for EFT payments',
      'code-line-start': 25,
      'code-highlight-length': 6,
    },
  ],
  'bottom-cta-url': '/docs/auth',
  'bottom-cta-text': 'View API docs',
};

export const featureSectionData = {
  'feature-section-title': [
    {
      label: 'Use Cases',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/use-cases/bbs-112.png',
      title: 'Banking and wealth',
      'header-class': 'h5',
      content:
        'Make it easy for people to interact with their bank accounts and investments',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/banking-and-wealth',
    },
  ],
};

export const footerExtensionData = {
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/auth',
};
