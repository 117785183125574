import React, { ReactElement } from 'react';
import Script from 'next/script';
import { normalizeContentSettings } from './utils';

interface Props {
  consentSettings: string;
}

const ThirdPartyScripts = ({ consentSettings }: Props): ReactElement => {
  const [isScriptLoaded, setIsScriptLoaded] = React.useState(false);
  const settings = normalizeContentSettings(consentSettings);

  React.useEffect(() => {
    settings.forEach((category) => {
      switch (category) {
        case 'C0002':
          injectQualified(setIsScriptLoaded);
      }
    });
  }, [settings]);

  React.useEffect(() => {
    if (!isScriptLoaded) {
      return;
    }
  }, [isScriptLoaded]);

  return (
    <>
      {settings.map((category) => {
        switch (category) {
          // strictly necessary
          case 'C0001': // all strictly necessary scripts are loaded directly in the ConsentManager.tsx
            return null;
          // performance
          case 'C0002':
            return (
              <React.Fragment key='C0002'>
                <Script
                  id='marketo-munchkin-script'
                  dangerouslySetInnerHTML={{
                    __html: `
                  (function (w, d) {
                    const initMunchkin = () => {
                      w?.Munchkin?.init?.('495-WRE-561');
                    };
                    const s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = '//munchkin.marketo.net/munchkin.js';
                    s['onreadystatechange'] = function () {
                      if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        initMunchkin();
                      }
                    };
                    s.onload = initMunchkin;
                    d.getElementsByTagName('head')[0].appendChild(s);
                  })(window, document);
                `,
                  }}
                />
                <Script
                  type='text/javascript'
                  src='https://go.plaid.com/rs/495-WRE-561/images/rwts.js'
                />
              </React.Fragment>
            );
          // functional
          case 'C0003':
            return (
              <React.Fragment key='C0003'>
                {/*
                    We put this mutiny script here, as it is used for personalizing the website after
                    consenting to functional cookies. (the other client mutiny script is loaded in
                    ConsentManager.tsx) https://plaidinc.atlassian.net/browse/SITE-4954
                */}
                <Script
                  id='mutiny-client-registry'
                  data-nscript='afterInteractive'
                  src='https://client-registry.mutinycdn.com/personalize/client/51c52cf96f032582.js'
                />
              </React.Fragment>
            );
          // targeting
          case 'C0004':
            return (
              <React.Fragment key='C0004'>
                <Script
                  id='sixsense-client-script'
                  dangerouslySetInnerHTML={{
                    __html: `
                  (function (w,d) {
                    const _6sToken = '84597002790a907dc7593763a409b716';
                    w._6si = [['enableEventTracking', true],['setToken', _6sToken],['setEndpoint', 'b.6sc.co']];
                    const gd = d.createElement('script');
                    const s = d.scripts[0];
                    gd.src = '//j.6sc.co/6si.min.js';
                    s.parentNode.insertBefore(gd, s);
                  })(window, document);
                `,
                  }}
                />
                <Script
                  id='ttd-client-script'
                  dangerouslySetInnerHTML={{
                    __html: `
                  (function (d) {
                    const initTTD = () => {
                      if (typeof TTDUniversalPixelApi === 'function') {
                        const universalPixelApi = new TTDUniversalPixelApi();
                        universalPixelApi.init(
                          '4azo4ri',
                          ['u0iai2i'],
                          'https://insight.adsrvr.org/track/up',
                        );
                      }
                    };
                    const gd = document.createElement('script');
                    const s = d.scripts[0];
                    gd.src = '//js.adsrvr.org/up_loader.1.1.0.js';
                    s.parentNode.insertBefore(gd, s);
                    s.onload = initTTD;
                  })(document);
                `,
                  }}
                />
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default ThirdPartyScripts;

const injectScript = (properties, callback) => {
  const hasScript = document.querySelector(`#${properties?.id}`);
  if (hasScript) {
    return;
  }
  const script = document.createElement('script');
  Object.entries(properties).forEach(([k, v]) => {
    script[k] = v;
  });
  script.addEventListener('load', () => {
    callback(true);
  });
  document.body.appendChild(script);
};

const injectQualified = (callback) => {
  // qualified object
  injectScript(
    {
      id: 'qualified-object',
      innerHTML: `(function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
    (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`,
    },
    callback,
  );
  // load qualified dependencies
  injectScript(
    {
      id: 'qualified-dependencies',
      async: true,
      src: 'https://js.qualified.com/qualified.js?token=D4MCQzekrVDNASqw',
    },
    callback,
  );
  // add meetings experience
  injectScript(
    {
      id: 'qualified-meetings',
      innerHTML: `((window) => {
        const urlParams = new URLSearchParams(window.location.search);
        const formId = Number(urlParams.get('form'));
        // 2006 and 2007 are contact us forms
        // const qualifiedMeetingForms = new Set([2006, 2007]);
        const qualifiedMeetingForms = new Set([2683]);
        if (window?.qualified && qualifiedMeetingForms.has(formId)) {
          window.qualified(
            'showFormExperience',
            'experience-1699026891029',
          );
        }
      })(window);
    `,
    },
    callback,
  );
};
