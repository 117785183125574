export const intro = {
  title: 'Política de Desarrolladores',
  effectiveDate: `Fecha de Entrada en Vigor: 31 de enero de 2022<br><br>
    <em>Hasta el 31 de enero de 2022, se mantendrá en vigor la versión de 2019 de la Política para Desarrolladores de Plaid</em>.
    `,
  description: [
    `Esta Política para Desarrolladores (la “Política”) establece las normas y las directrices que rigen el acceso o el uso, por parte
      de nuestros desarrolladores (“usted” o “su”), de la API (application programming interface, interfaz de programación de aplicaciones),
      sitios web (“Sitio”), paneles, herramientas asociadas y otros productos o servicios de Plaid (de manera conjunta, los “Servicios”),
      proporcionados por Plaid Inc. y sus filiales, incluidas Plaid Financial Ltd. y Plaid, B.V. (“Plaid”, “nosotros”, “nuestro” y “nos”).
      Cualquier violación de esta Política podrá provocar la suspensión o cancelación de su acceso a los Servicios y/o del acceso a la
      información personal y financiera de los usuarios finales facilitada por Plaid (“Datos del Usuario Final”).
      `,
    `Al acceder a los Servicios y utilizarlos, usted acepta cumplir con todas las condiciones de esta Política. Esta Política aplicará
      cada vez que usted acceda a los Servicios o los utilice. En caso de que usted acepte las condiciones de esta Política actuando por
      cuenta de una organización o entidad, usted manifiesta y garantiza que está autorizado para aceptar actuando por cuenta de dicha
      organización o entidad. Esta Política es importante, por lo que le rogamos que la lea con atención.
      `,
    `Podremos actualizar o cambiar esta Política siempre que lo consideremos necesario. Si introducimos cualquier cambio en ella que
      consideremos sustancial, haremos un esfuerzo razonable para informarle de dicho cambio. En caso de que usted se oponga a un cambio,
      su única alternativa es dejar de acceder a los Servicios y no utilizarlos en modo alguno.
      `,
  ],
};

export const tableOfContentsTitle = 'Ir a la sección:';

export const sections = [
  {
    title: 'Registro',
    description: [
      `Para darse de alta en los Servicios, usted deberá crear una cuenta (la “Cuenta”) registrándose en nuestro Sitio y proporcionando
        información cierta, exacta y completa sobre usted y su uso de los Servicios. Usted acuerda no falsear su identidad ni ninguna información
        que usted facilite para su Cuenta, así como mantener la información de su Cuenta actualizada en todo momento. Usted es el responsable de
        mantener el acceso a su Cuenta; no deberá compartir nunca su información de Cuenta, incluyendo su contraseña del Panel de Plaid y sus
        credenciales de autenticación API, incluyendo su Número de Identificación de Cliente (“ID de Cliente”) y secreto, con un tercero, ni
        permitir que ninguna otra aplicación o servicio actúe haciéndose pasar por usted.
        `,
    ],
  },
  {
    title: 'Cumplimiento con la Normativa Aplicable',
    description: [
      `Cuando usted utilice los Servicios, acepta cumplir toda la normativa local, estatal, nacional e internacional que resulte de aplicación.
        Asimismo, usted confirma que usted, su empresa, sus trabajadores, sus prestadores de servicios y cualquier otra persona que actúe por
        cuenta de usted cumplen con toda la normativa aplicable, en particular la relativa a datos financieros, protección de datos, privacidad
        y seguridad de los datos.
        `,
      `Además, usted garantiza que usted, sus directivos, accionistas y empresas matrices y filiales (directas e indirectas):
        `,
      [
        `cumplen y cumplirán toda la normativa aplicable en materia de importación, reimportación, sanciones, antiboicot, exportación y control
          de reexportación (incluyendo toda la referida normativa aplicable a una empresa estadounidense, como las Export Administration Regulations
          (Reglamento sobre la Administración de la Exportación), las International Traffic in Arms Regulations (Reglamento sobre Tráfico Internacional
          de Armas) y los programas de sanciones económicas implementados por la Office of Foreign Assets Control (Oficina de Control de Activos Extranjeros;
          OFAC, por sus siglas en inglés));
          `,
        `no están sujetos a, ni pertenecen a entidades que estén sujetas a, sanciones, ni están identificados de otra forma en ninguna lista relacionada con
          sanciones, incluyendo, con carácter enunciativo y no limitativo, listas mantenidas por el Gobierno de los Estados Unidos (como la List of Specially
          Designated Nationals and Blocked Persons (Lista de Nacionales Especialmente Designados y Personas Bloqueadas), mantenida por la OFAC, la Entity List
          (Lista de Entidades) mantenida por la U.S. Commerce Department’s Bureau of Industry and Security (Oficina de Industria y Seguridad del Departamento
          de Comercio de los Estados Unidos ) y la lista de la sección 231(d) de la CAATSA (Countering America's Adversaries Through Sanctions Act, Ley para
          Combatir a los Adversarios de América a través de Sanciones) mantenida por el U.S. State Department (Departamento de Estado de Estados Unidos)), el
          Consejo de Seguridad de las Naciones Unidas, el Reino Unido, la Unión Europea o sus Estados Miembros u otra autoridad gubernamental que resulte de
          aplicación; y
          `,
        `no participan ni participarán en actividades que pudieran exigir o justificar que cualquier autoridad gubernamental que resulte de aplicación inicie
           acciones legales contra usted o contra nosotros o le imponga, a usted o a nosotros, sanciones económicas.
          `,
      ],
      `Las garantías inmediatamente anteriores no se exigirán ni se facilitarán, si y en la medida en que dicha solicitud o garantía constituya una violación
        del Estatuto de Bloqueo de la Unión Europea, de normativa que lo implemente en los Estados Miembros de la UE o en el Reino Unido, o de cualesquiera
        disposiciones similares antiboicot, de no discriminación o de bloqueo previstas en la normativa local aplicable.
        `,
      `Usted es el único responsable de garantizar que su uso de los Servicios cumple con todas las normas que le sean aplicables, entre las que se incluyen,
        con carácter enunciativo y no limitativo, las reglas y directrices de cualquier sistema o red que facilite pagos, así como cualesquiera requisitos de
        seguridad, incluyendo bajo los Payment Card Industry Data Security Standards (Estándares de Seguridad de Datos de la Industria de las Tarjetas de Pago, PCI-DSS),
        según le apliquen a usted.
        `,
    ],
  },
  {
    title: 'Seguridad',
    description: [
      `Usted es el responsable de conservar de manera segura su nombre de usuario y contraseña del Panel de Plaid, así como sus credenciales de autenticación API, incluyendo
        su ID de Cliente y secreto. Usted se compromete a notificarnos de inmediato a través de security@plaid.com en caso de cualquier brecha de seguridad o uso no autorizado
        de su Cuenta o cualesquiera Datos del Usuario Final. Nunca deberá publicar, distribuir ni compartir su ID de Cliente o secreto y deberá cifrar esta información cuando se
        encuentre almacenada y en tránsito.
        `,
      `Sus sistemas y aplicación(es) deberán manejar los Datos del Usuario Final de forma segura. Respecto de los Datos del Usuario Final, deberá cumplir con las mejores prácticas
        del sector pero, como mínimo, deberá adoptar las siguientes medidas:
        `,
      [
        `Mantener las salvaguardas administrativas, técnicas y físicas diseñadas para garantizar la seguridad, la privacidad y la confidencialidad de los Datos del Usuario Final.
          `,
        `Usar una criptografía actual y habitual en el sector cuando almacene o transmita cualesquiera Datos del Usuario Final.
          `,
        `Mantener unos controles de acceso razonables para garantizar que sólo las personas autorizadas que así lo requieran por necesidades del negocio tengan acceso a cualesquiera Datos del Usuario Final.
          `,
        `Monitorizar sus sistemas para detectar cualquier acceso no autorizado.`,
        `Solucionar las vulnerabilidades en un plazo oportuno.`,
        `Registrar y examinar cualquier evento que sugiera un acceso no autorizado.`,
        `Prepararse para y dar una respuesta a incidentes de seguridad.`,
        `Cumplir con la normativa relevante respecto del tipo de datos que usted esté manejando, como la Safeguards Rule (Regla de Seguridad) de conformidad con la
           Graham-Leach-Bliley Act (Ley Graham-Leach-Bliley).
          `,
      ],
    ],
  },
  {
    title: 'Almacenamiento de Datos',
    description: [
      `Cualesquiera Datos del Usuario Final que se encuentren en su poder deberán ser almacenados de forma segura y de acuerdo con la normativa aplicable.
        `,
      `En la medida que proceda, si usted utiliza Datos del Usuario Final de forma anonimizada, entonces deberá informar clara y manifiestamente al Usuario
        Final del uso que usted haga de tales datos anonimizados, incluyendo una descripción de cómo usa usted los referidos Datos del Usuario Final anonimizados.
        `,
    ],
  },
  {
    title: 'Desactivación de la Cuenta',
    description: [
      `Una vez que usted deje de usar los Servicios de conformidad con cualquier acuerdo que resulte de aplicación que usted pueda tener con nosotros, podrá
        desactivar su Cuenta siguiendo las instrucciones que figuran en el Sitio. Nosotros también podremos desactivar su Cuenta si usted ha dejado de utilizar
        los Servicios durante tres meses, se produce la terminación de su contrato con nosotros que resulte aplicable o según sea razonablemente necesario de
        conformidad con la normativa aplicable. Después de que se haya desactivado su Cuenta, cancelaremos su acceso a todos los Datos del Usuario Final asociados
        a su integración.
        `,
      `Incluso tras la desactivación de su Cuenta, y en la medida que la normativa aplicable lo permita, aún podremos conservar cualquier información que
        recabásemos sobre usted durante el plazo que sea necesario para cumplir con las finalidades descritas en nuestra política o declaración de privacidad o
        durante un plazo de retención mayor si la normativa aplicable así lo exige o permite.
        `,
    ],
  },
  {
    title: 'Conductas Prohibidas',
    description: [
      `Usted acuerda que no realizará ninguna de las conductas siguientes, ni ayudará a ni facilitará de ninguna manera que ningún tercero las realice:`,
      [
        `vender o alquilar los Datos del Usuario Final a comercializadores o a cualquier otro tercero;`,
        `acceder a los Servicios o los Datos del Usuario Final, o usarlos, con cualquier propósito ilegal, infractor, amenazante, abusivo, obsceno,
           hostigador, difamatorio, engañoso o fraudulento;
          `,
        `recabar y almacenar credenciales bancarias de los usuarios finales y/o Datos del Usuario Final de una forma distinta de la necesaria para acceder a
          los Servicios o usarlos, distinta de la autorizada por el usuario final, la permitida por Plaid y la permitida por la normativa aplicable;
          `,
        `usar, divulgar o conservar cualquier “información personal no pública” (según lo definido por la Gramm-Leach-Bliley Act (Ley Gramm-Leach-Bliley)) o
          “información personal” (según se define en la California Consumer Privacy Act (Ley sobre la Privacidad del Consumidor de California)), de una manera
          en que no se cumpla estrictamente con la normativa aplicable;
          `,
        `usar, divulgar o tratar de otra forma cualquier “dato personal” (según se define en el Reglamento (UE) 2016/679 (Reglamento General de Protección de Datos)),
           de una manera en que no se cumpla estrictamente con la normativa aplicable;
          `,
        `acceder a o usar los Servicios o acceder a, transmitir, tratar o almacenar Datos del Usuario Final con infracción de cualquier normativa aplicable en materia
          de privacidad o de cualquier modo que constituiría un incumplimiento de un contrato o acuerdo con el usuario final correspondiente;
          `,
        `acceder a los Servicios o usarlos para infringir cualquier patente, marca, secreto comercial, derecho de autor, derecho de publicidad u otro derecho de cualquier
           persona o entidad;
          `,
        `acceder a los Servicios o usarlos, para cualquier finalidad distinta de aquellas para las que son facilitados por nosotros, incluyendo para evaluación competitiva,
           espionaje, crear Servicios sustitutivos de o similares a cualquiera de los Servicios u otros fines maliciosos;
          `,
        `analizar o probar (de manera manual o automática) la vulnerabilidad de cualquier infraestructura de Plaid sin autorización expresa, previa y por escrito de Plaid;
          `,
        `infringir, deshabilitar, interferir con o eludir de otro modo cualquier medida de seguridad o autenticación o cualquier otro aspecto de los Servicios;`,
        `sobrecargar, inundar (flood) o enviar spam
          a cualquier parte de los Servicios;
          `,
        `crear cuentas de desarrollador para los Servicios, por cualquier medio distinto a nuestras interfaces con soporte público (p. ej., crear cuentas de desarrollador de manera automática
            o masivamente de otro modo);
          `,
        `transferir, re-difundir o distribuir de otro modo los Servicios o Datos del Usuario Final sin
          el consentimiento expreso, previo y por escrito de Plaid;
          `,
        `descifrar, descompilar, desarmar, copiar, realizar ingeniería inversa o intentar obtener cualquier código fuente o ideas o algoritmos subyacentes de cualquier parte de los Servicios,
           salvo que lo permita la normativa aplicable;
          `,
        `modificar, traducir o crear de otra forma obras derivadas de cualquier parte de los Servicios;
          `,
        `acceder a los Servicios o los Datos del Usuario Final, o usarlos, de una manera que infrinja cualquier acuerdo entre usted o el usuario final y Plaid; o
          `,
        `acceder a los Servicios o los Datos del Usuario Final, o usarlos, de una manera que infrinja cualquier ley, estatuto, ordenanza o reglamento aplicable.
          `,
      ],
    ],
  },
  {
    title: 'Suspensión y Cancelación ',
    description: [
      `Nos reservamos el derecho de denegar, rechazar o cancelar el acceso a los Servicios y/o a los Datos del Usuario Final, en todo o en parte, si, a nuestro juicio,
        se está accediendo a los Servicios o se están utilizando de forma que se viole la presente Política o cualquier otro acuerdo de Plaid, incluyendo los acuerdos
        que Plaid mantiene con cualesquiera socios externos o fuentes de datos de Plaid (cada uno de ellos, un “Socio”), o cuando el uso pueda implicar un riesgo de daños,
        incluidos daños reputacionales, para Plaid, su infraestructura, sus datos, los Servicios, un usuario final o un Socio.
        `,
      `Haremos esfuerzos razonables para comunicarle por correo electrónico o por otro medio nuestra decisión de denegar, rechazar o cancelar el acceso a los Servicios
        y/o a los Datos del Usuario Final. Podremos suspender o cancelar el acceso de inmediato, sin comunicárselo con antelación, cuando sea apropiado según las circunstancias,
        como cuando tengamos conocimiento de una actividad que suponga una violación de cualquier normativa aplicable o cuando juzguemos, a nuestra entera discreción, que el daño
        es inminente.
        `,
      `Plaid no será responsable de ningún daño de ninguna naturaleza, que usted o cualquier tercero sufran como resultado del ejercicio, por parte de Plaid, de sus derechos
        conforme a esta Política o de acuerdo con la normativa aplicable.
        `,
    ],
  },
  {
    title: 'Comunicación de Infracciones',
    description: [
      `Si cualquier persona tuviera conocimiento de una infracción de la presente Política, le rogamos que nos lo comunique de inmediato a través de un correo electrónico
        dirigido a legalnotices@plaid.com. Podremos adoptar cualquier medida pertinente (incluida la denuncia de cualquier actividad o conducta que sospechemos que viola la
        ley a las autoridades competentes, reguladores u otros terceros que correspondan), a nuestra entera discreción, con respecto a dichas violaciones.
        `,
    ],
  },
  {
    title: 'Acceso Universal a los Datos',
    description: [
      `Plaid promueve un ecosistema Open Finance (Finanzas Abiertas) en el que los usuarios finales son propietarios de sus datos (p. ej. datos financieros) y pueden dar
        acceso a terceros a tales datos. En la medida en que usted aloje cualesquiera de esos datos de usuario final a los que Plaid todavía no tenga acceso, a petición razonable
        de Plaid, las partes acuerdan cooperar de buena fe para negociar e iniciar un acuerdo en virtud del cual Plaid tenga acceso a dichos datos de usuario final por cuenta de
        los usuarios finales.
        `,
    ],
  },
  {
    title: 'Miscelánea',
    description: [
      `La falta de ejercicio por su parte o por parte de Plaid, en cualquier aspecto, de cualquier derecho establecido en este documento o en cualquier otro acuerdo que usted pueda
        tener con nosotros, no se considerará una renuncia a cualquier otro derecho aquí reconocido.
        `,
      `Si cualquier disposición de la presente Política fuera declarada inaplicable o carente de validez, dicha disposición se limitará o eliminará en la medida mínima necesaria, de
        forma tal que esta Política continúe estando plenamente vigente, con plenos efectos y siendo aplicable.
        `,
    ],
  },
];
