import React, { FC } from 'react';
import cx from 'classnames';
import { toKebabCase } from 'src/lib/utils';
import { JOB_LOCATIONS, CAREERS_PATH } from 'src/lib/constants';
import styles from './OpeningsListRow.module.scss';
import { OpeningsListRow as OpeningsListRowProps } from './OpeningsListRow.types';
import { Link } from 'src/components/Inputs';
import ArrowUp from '../SVGs/Icons/ArrowUp';

const generateLocationClass = ({ location = '' }) =>
  JOB_LOCATIONS.includes(location) && !location.match(/\//)
    ? `${location.substring(0, 1).toLowerCase()}${location.substring(1)}`
        .split(' ')
        .join('')
    : 'generic';

const OpeningsListRow: FC<OpeningsListRowProps> = ({
  id,
  text,
  categories: { location },
  department,
  isLastItem,
}) => {
  const locationClass = generateLocationClass({
    location,
  });

  return (
    <div className='grid-container'>
      <Link
        href={`${CAREERS_PATH}/openings/${toKebabCase(
          department,
        )}/${toKebabCase(location)}/${id}`}
      >
        <a
          data-id='openings-item'
          className={cx('grid-x', styles.item, isLastItem && styles.noBorder)}
        >
          <div className='cell medium-6'>
            <p className={styles.title}>{text}</p>
          </div>
          <div className='cell medium-6'>
            <p className={cx(styles.location, styles[locationClass])}>
              <span>{location}</span>
            </p>
          </div>
          <div className={styles.itemArrow}>
            <ArrowUp />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default OpeningsListRow;
