import React from 'react';
import { Lazy } from 'src/components-v2/Helpers';
import Image from 'src/components/Image';
import { optimizeImage } from 'src/lib/image-helpers';

interface Video {
  url: string;
  type: string;
}

interface Props {
  className?: string;
  width?: number;
  height?: number;
  poster?: string;
  formats: Array<Video>;
  mobileImageSrc?: string;
  isLazy?: boolean;
  onCanPlay?: React.ReactEventHandler<HTMLVideoElement>;
}

const Video = React.forwardRef<HTMLVideoElement, Props>(
  (
    {
      className = '',
      width = null,
      height = null,
      poster = '',
      formats,
      mobileImageSrc = '',
      isLazy = false,
      onCanPlay = null,
    },
    ref,
  ) => {
    return (
      <Lazy isLazy={isLazy} height={height}>
        <video
          data-testid='video'
          className={
            className ? className : 'page-header-video show-for-medium'
          }
          autoPlay
          loop
          muted
          width={width}
          height={height}
          poster={optimizeImage(poster)}
          ref={ref}
          onCanPlay={onCanPlay}
        >
          {formats.map((item, i) => {
            return <source {...item} key={`video-format-${i}`} />;
          })}
        </video>
        {mobileImageSrc && <Image src={mobileImageSrc} alt='' />}
      </Lazy>
    );
  },
);

export default Video;
