import React, { FC } from 'react';
import styles from './PostCard.module.scss';
import cx from 'classnames';

// helpers
import { truncateString } from 'src/lib/utils';

import { Link } from 'src/components/Inputs';
import { Date } from 'src/components/Blog/Heading';
import { Tag } from 'src/components/DataDisplay';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

import { Component } from 'src/pages/blog';

import {
  MAX_EXCERPT_WORD_COUNT,
  MAX_EXCERPT_WORD_COUNT_FEATURED,
} from 'src/lib/constants';

export interface PostCardProps {
  components?: Component[];
  date: string;
  excerpt?: string; // TODO: migration to make this required https://jira.plaid.com/browse/SITE-2495
  featured?: boolean;
  image?: {
    src: string;
    alt: string;
  };
  link: string;
  tags?: string[];
  title: string;
}

const PostCard: FC<PostCardProps> = ({
  components = [],
  date,
  excerpt = '',
  featured = false,
  image,
  link,
  tags = [],
  title,
}) => {
  const maxExcerptLength = featured
    ? MAX_EXCERPT_WORD_COUNT_FEATURED
    : MAX_EXCERPT_WORD_COUNT;
  const TitleEl = `h${featured ? 2 : 4}` as keyof JSX.IntrinsicElements;
  const ExcerptEl = `${featured ? 'h4' : 'p'}` as keyof JSX.IntrinsicElements;

  return (
    <Link href={link}>
      <a className={styles.postLink}>
        <div
          className={cx(
            styles.postCardWrapper,
            featured && styles.postCardWrapperFeatured,
          )}
        >
          {image?.src && (
            <>
              <Image
                className={cx(
                  styles.postImage,
                  !featured && styles.postImageAdjacent,
                )}
                isLazy={!featured}
                {...image}
              />
            </>
          )}
          <div
            className={cx(
              styles.postBody,
              !featured && styles.postBodyAdjacent,
            )}
          >
            <Date date={date} />
            <TitleEl>{title}</TitleEl>
            {excerpt.length > 0 && (
              <ExcerptEl className={styles.featuredPostExcerpt}>
                {truncateString(excerpt, maxExcerptLength)}
              </ExcerptEl>
            )}
            <div className={styles.postCardLink}>
              <strong>Read the article</strong> <ArrowRightSingleSmall />
            </div>
            {tags.length > 0 && (
              <div data-testid='post-tags'>
                {tags.map((tag) => (
                  <Tag
                    tag={tag?.split('-').join(' ')}
                    href={`/blog/tag/${tag}`}
                    key={tag}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default PostCard;
