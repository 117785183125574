export const metaData = {
  'meta-title': 'Customer Stories',
  'meta-description':
    'Ever wonder what Plaid does? Meet some of our customers and discover the impact that partnering with Plaid can create.',
};

export const pageHeaderData = {
  primaryHeading: 'Customer Stories',
  subheading:
    'Meet some of our customers and discover the impact that partnering with Plaid can create.',
};

export const featuredStoryData = {
  id: 'Prosper',
  headline: 'Helping people invest in each other',
  subhead:
    'Prosper uses consumer banking data to offer loans that are socially and financially rewarding',
  image: {
    alt: '',
    src: '/assets/img/customer-stories/card-hero-macbook@2x.png',
  },
  url: '/customer-stories/prosper',
  'bg-classes':
    'background background--color-pink200 background--image-stroke-yellow-card-hero background--size-contain background--position-bottom-right',
};

export const credibilityData = {
  image: {
    src: '/assets/img/customer-stories/headshot-prosper@2x.png',
    alt: "headshot of Prosper's Siva Shanmugam",
  },
  'quote-text':
    'Plaid consistently outperformed our previous solution. Over the course of the Plaid pilot program, we met or exceeded every performance benchmark.',
  'quote-byline':
    'Siva Shanmugam, Director of Verification and Fraud Strategy, Prosper',
  'cta-link': '/customer-stories/prosper',
  'cta-text': 'Get the full story',
  'logo-src-small': {
    src: '/assets/img/customer-stories/logo-prosper@2x.png',
    alt: 'logo of Prosper',
  },
  'show-quote-logo-asset': true,
};

export const socialproofData = {
  header: 'Other companies we work with',
  alignment: 'center',
  'column-count': 4,
  fullWidth: true,
  logos: [
    {
      src: '/assets/img/logos/logo-acorns-black600.png',
      alt: 'Acorns',
    },
    {
      src: '/assets/img/logos/logo-betterment-black600.png',
      alt: 'Betterment',
    },
    {
      src: '/assets/img/logos/logo-sofi-black600.png',
      alt: 'SoFi',
    },
    {
      src: '/assets/img/logos/logo-elliemae-black600.png',
      alt: 'Ellie Mae',
    },
    {
      src: '/assets/img/logos/logo-expensify-black600.png',
      alt: 'Expensify',
    },
    {
      src: '/assets/img/logos/logo-drop-black600.png',
      alt: 'Drop',
    },
    {
      src: '/assets/img/logos/logo-wise-black600.png',
      alt: 'Wise',
    },
    {
      src: '/assets/img/logos/logo-venmo-black600.png',
      alt: 'Venmo',
    },
  ],
};

export const ctaData = {
  'marketo-key': 2007,
  isDarkMode: true,
  title: 'Questions?',
  'with-subtext': true,
  subtext: "Let us know how we can help and we'll be in touch shortly.",
  'subtext-class': 'h4 regular',
  'include-phone': true,
  image: {
    src: '/assets/img/customer-stories/cta-plaid-employee-row@2x.png',
    alt: 'Plaid Employees',
    width: 167,
  },
};

export const initialFilters = [
  { label: 'Onboard new users', active: false, disabled: false },
  { label: 'Connect user bank accounts', active: false, disabled: false },
  { label: 'Enable ACH payments', active: false, disabled: false },
  { label: 'Get transactions history', active: false, disabled: false },
  { label: 'Underwrite loans', active: false, disabled: false },
  { label: 'Prevent NSF/overdraft fees', active: false, disabled: false },
  { label: 'Reduce fraud risk', active: false, disabled: false },
  { label: 'Avoid credit card fees', active: false, disabled: false },
  { label: 'A path to money happiness', active: false, disabled: false },
];
