import * as React from 'react';
import { Box } from 'src/components-v2/Layout';
import { useIsDarkBackground } from 'src/contexts';

interface IBulletProps {
  bounds: {
    bottom?: number;
    height?: number;
    left?: number;
    right?: number;
    top?: number;
    width?: number;
    x?: number;
    y?: number;
  };
}

const Bullet: React.FunctionComponent<IBulletProps> = ({ bounds }) => {
  const isDarkBackground = useIsDarkBackground();
  const leftPosition = bounds.left + bounds.width;
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        bgcolor: isDarkBackground ? 'white' : 'black800',
        marginTop: '-1.5rem',
        left: {
          md: leftPosition - 24, // 24 is half the width of this element
        },
        border: isDarkBackground
          ? '18px solid rgba(255, 255, 255, .1)'
          : '18px solid rgba(0, 0, 0, .1)',
        backgroundClip: 'padding-box',
        display: { md: 'block', xs: 'none' },
      }}
    />
  );
};

export default Bullet;
