import React, { FC } from 'react';
import cx from 'classnames';

// components
import { Button } from 'src/components/Inputs';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';
import InfoIcon from 'src/components/SVGs/Icons/InfoIcon';
import Image from 'src/components/Image';

interface Props {
  title?: string;
  header?: string;
  tableData?: {
    label?: string;
    img: string;
    imageLabel: string;
    alt: string;
    heading?: string;
    description?: string;
    hasInfoDropdown: boolean;
    infoId: string;
    infoText: string;
    ctaLink?: string;
    ctaText?: string;
    buttonTrackingId: string;
    services: {
      title?: string;
      items?: string[];
      hasAddOns: boolean;
      addOns: string[];
    };
  }[];
}

const PricingHeaderTable: FC<Props> = ({ title, header, tableData }) => {
  return (
    <div className='pricing-header-table'>
      <div className='grid-container section-container'>
        <div className='grid-x'>
          <div className='cell medium-7 medium-offset-3 large-12 large-offset-0'>
            <h6 className='section-title mb1'>{title}</h6>
            <h2 className='section-header'>{header}</h2>
          </div>
        </div>
        <div className='grid-x'>
          <div className='cell medium-9 medium-offset-3 large-12 large-offset-0'>
            <div className='items-table-layout'>
              <div className='grid-x'>
                {tableData.map((tableItem, i) => (
                  <div className='cell large-4' key={`tableItem-${i}`}>
                    <div className='items-table'>
                      <div
                        className={cx(
                          `items-table-img ${tableItem.imageLabel}-img`,
                        )}
                      >
                        <Image
                          src={tableItem.img}
                          aria-hidden='true'
                          alt={tableItem.alt}
                        />
                      </div>
                      <h2 className='items-table-heading'>
                        {tableItem.heading}
                      </h2>
                      <h6 className='items-table-label'>{tableItem.label}</h6>
                      <p className='items-table-description'>
                        {tableItem.description}{' '}
                        {tableItem.hasInfoDropdown && (
                          <button
                            className='info-dropdown'
                            type='button'
                            data-toggle={`items-info-dropdown-${tableItem.infoId}`}
                            aria-labelledby={`items-info-dropdown-${tableItem.infoId}`}
                          >
                            <span className='info-icon'>
                              <InfoIcon />
                            </span>
                          </button>
                        )}
                      </p>
                      <div
                        className='dropdown-pane info-dropdown-pane'
                        id={`items-info-dropdown-${tableItem.infoId}`}
                        data-dropdown
                        data-position='top'
                        data-alignment='center'
                        data-v-offset='10'
                        data-allow-overlap='true'
                        data-hover='true'
                      >
                        <p>{tableItem.infoText}</p>
                      </div>
                      <div className='items-table-cta-section'>
                        <Button
                          href={tableItem.ctaLink}
                          className='button-cta signup-attach-utm'
                          fullWidth
                          trackingId={tableItem.buttonTrackingId}
                        >
                          {tableItem.ctaText}
                        </Button>
                      </div>
                      <div className='items-table-list-container'>
                        <div className='items-table-list-header'></div>
                        <p className='items-table-list-title'>
                          {tableItem.services.title}
                        </p>
                        {tableItem.services.items.map((serviceItem, i) => (
                          <div
                            key={`service-${i}`}
                            className={'items-table-list-item has-feature'}
                          >
                            <span className='check-icon'>
                              <Checkmark />
                            </span>
                            <span className='items-table-list-item-text'>
                              {serviceItem}
                            </span>
                          </div>
                        ))}
                        {tableItem.services.hasAddOns && (
                          <>
                            <p className='items-table-list-item-addon'>
                              Add-ons
                            </p>
                            {tableItem.services.addOns?.map(
                              (serviceAddon, i) => (
                                <div
                                  key={`serviceAddon-${i}`}
                                  className={
                                    'items-table-list-item has-feature'
                                  }
                                >
                                  <span className='check-icon'>
                                    <Checkmark />
                                  </span>
                                  <span className='items-table-list-item-text'>
                                    {serviceAddon}
                                  </span>
                                </div>
                              ),
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingHeaderTable;
