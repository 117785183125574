export const metaData = {
  metaTitle: 'Brigit',
  metaDescription:
    'With Plaid, Brigit has helped its users save more than $100 million in overdraft fees and payday loan interest.',
};

export const pageHeaderData = {
  classes:
    'background background--color-green200 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-green-2@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-text': 'Customer story: Brigit',
  header: 'A responsible way to bridge through money shocks',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/brigit-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-brigit@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-brigit@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Connect user bank accounts,<br />prevent NSF/overdraft fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Personal financial management',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'To help users avoid overdraft fees and predatory loans, Brigit needed a way to access their bank transactions data.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Brigit has helped its users save more than $100 million in overdraft fees and payday loan interest.',
};

export const content1Data = {
  heading:
    'Brigit is an app that builds financial health by offering members budgeting tools, automated alerts, interest-free cash advances, and a centralized view of their money.',
  paragraph: [
    'It’s timely work. An estimated 100 million Americans live paycheck-to-paycheck; each year, more than 40 million pay an overdraft fee.',
    'The majority need only a small amount of money—about $150—to cover their expenses in the four or five days before the next paycheck. Until recently, there weren’t many ways for them to bridge the gap. If they couldn’t get a loan from family or friends, they often had to turn to costly payday loans or credit card advances.',
    'According to the <a href="https://files.consumerfinance.gov/f/201403_cfpb_report_payday-lending.pdf">Consumer Financial Protection Bureau</a>, the average payday loan is $320, but the average interest paid on that loan is $520. (That’s an APR of almost 400%.) Even worse, the average payday borrower takes out 10 loans per year and spends 199 out of 365 days in debt.',
    'Brigit helps its users avoid those fees and stay out of debt, freeing up mental bandwidth to focus on the things they care about. By tracking users’ bank account transactions with Plaid, Brigit lets them know when they have a bill coming up, tells them whether they’ll have enough to cover it, and wires them money if they need it.',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-green200 supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '$100M',
  text1:
    'With Plaid, Brigit has helped its users save more than $100 million in overdraft fees and payday loan interest.',
  stat2: '20%',
  text2:
    'In a head-to-head comparison, Plaid offers 20% better coverage of transaction categories than the nearest competitor.',
  stat3: '$500',
  text3:
    'With Plaid, Brigit helps each of its users save, on average, $500 per year in prevented overdraft fees and predatory interest.',
};

export const content2Data = {
  title: 'Preventing overdrafts',
  paragraph: [
    'Zuben Mathews is Brigit’s co-founder and CEO. Although today he is financially healthy, he once counted himself among those who struggle to make it to the next payday.',
    '"When I was in college, I struggled to get by,” said Mathews. “My paychecks and money transfers never came soon enough. I would have to eat Snickers bar on Saturday nights just to save money."',
    'Mathews and co-founder Hamel Kothari started Brigit as a way to help people in similar situations achieve financial peace of mind and a better quality of life.',
    'Each month, users pay a $9.99 membership fee—and they’re covered. Whenever they need it, they can access tailored financial tools like balance alerts, cash flow predictions, and financial standing reports. They can also access interest-free cash advances of up to $250. That way, they are prepared for moments of unexpected financial shock.',
    'To date, Brigit has helped its more than 100,000 users remedy their short-term cash flow challenges and avoid payday loans, saving the average user more than $500 per year in fees and interest. With Plaid, Brigit has helped its users save more than $100 million in overdraft fees and payday loan interest.',
    'But Brigit wouldn’t be possible without robust transactions data. To model cash flows, evaluate creditworthiness, and facilitate speedy transfers, they needed an API that would connect to user bank accounts swiftly and seamlessly.',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-green-1@2x.png',
  'stroke-bottom-left':
    '/assets/img/accents/diagram-brushstroke-green-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Brigit’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Brigit can access the data they need to onboard new users and prevent overdraft fees.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-green@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-green@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-green@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-green@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Financial help in real time',
    'narrow-title': true,
    paragraph: [
      'When seeking a way to connect user bank accounts, Brigit carefully evaluated the options.',
      'They found some vendors too frustrating to work with. Others did not provide reliable connectivity. Among the competition, Plaid stood out. For example, Plaid offered 20% better coverage of transaction categories than the nearest competitor.',
      '“Plaid was the best choice for us because of its data quality and ease of integration,” Mathews said. “Plaid has done a great job of normalizing its service across all financial institutions, so we don’t have to worry about where our users do their banking.”',
      'With Plaid data, Brigit can verify a user’s identity and place of employment. They can also model a user’s cash flow over time and assess its volatility, which allows Brigit to notify users when they are in danger of overdrafting—in real time.',
      '“Many of our users have little or no credit history, so credit scores are a bad way to measure how financially responsible they’ve been in the past” Mathews explained. “Instead, we look at things like consistent bill payments and account balance over time. We couldn’t do that without Plaid.”',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-brigit@2x.png',
      alt: 'Headshot of Zuben Mathews, Co-Founder and CEO of Brigit',
    },
    quote: [
      {
        copy:
          'Engineering staff time is one of our scarcest resources. Building something on our own would have taken months, and it wouldn’t have been half as good.',
        source: 'Zuben Mathews, Co-Founder and CEO, Brigit',
      },
    ],
  },
  {
    title: 'A scalable partnership',
    paragraph: [
      'Integrating with Plaid was simple; it took just a few weeks. Looking back, Mathews says that’s a key reason Brigit was able to launch quickly and grow explosively.',
      '“Engineering staff time is one of our scarcest resources,” he said. “Building something on our own would have taken months, and it wouldn’t have been half as good. Plaid allowed us to launch quickly, scale sustainably, and focus on our competitive advantage.”',
      '<strong>Looking ahead, Mathews and Kothari say they will use Plaid data to continue to build out their suite of products, specifically with the goal of helping users grow their financial health.</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
    {
      name: 'Shift',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-shift@2x.png',
        alt: 'Shift - read full story',
      },
      excerpt: 'Shifting the used car landscape',
      url: '/customer-stories/shift',
    },
    {
      name: 'Varo',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-varo@2x.png',
        alt: 'Varo - read full story',
      },
      excerpt: 'A bank that builds financial health',
      url: '/customer-stories/varo',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/brigit-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
