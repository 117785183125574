import React from 'react';
import { RichTextSwitch } from 'src/containers';
import { targetMap } from 'src/lib/constants';
import Quote from '../../Quote';
import { Column } from 'src/components-v2/Layout';

import cx from 'classnames';
import styles from './CmsQuoteCentered.module.scss';

export const CmsQuoteCentered = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor || 'white';
  return (
    <Quote
      sx={{
        backgroundColor: bgColor,
        ...fields?.styles,
        '& p': {
          color: bgColor === 'black' ? 'white' : 'var(--black800)',
        },
      }}
      sys={sys}
      cta={{
        href: fields?.ctaLink?.fields?.url,
        trackingId: fields?.ctaLink?.fields?.trackingId,
        target: targetMap[fields?.ctaLink?.fields?.target],
        sys: fields?.ctaLink?.sys,
        text: fields?.ctaLink?.fields?.text,
        sx: {
          position: 'static',
          mt: { xs: '2rem', md: '2.4rem' },
        },
      }}
      wrapperClass={cx(styles.CmsQuoteCentered)}
    >
      <Column sm={22} md={24}>
        <RichTextSwitch
          sys={sys}
          content={fields?.body}
          nodes={{
            h3: {
              variant: 'h4',
              component: 'blockquote',
              fieldId: 'body',
            },
            h5: {
              variant: 'p2',
              component: 'cite',
              fieldId: 'body',
            },
            p: {
              variant: 'p2',
            },
            asset: {
              queryString: {
                xs: '?fm=webp&w=172&q=60',
                sm: '?fm=webp&w=228&q=60',
                md: '?fm=webp&w=264&q=60',
                lg: '?fm=webp&w=264&q=60',
              },
              width: 150,
              height: 60,
              fieldId: 'body',
            },
          }}
        />
      </Column>
    </Quote>
  );
};
