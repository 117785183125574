import React from 'react';
import { CmsQuote } from './CmsQuote';
import { CmsQuoteCentered } from './CmsQuoteCentered';

export const CmsQuoteSwitch = ({ fields, sys }) => {
  const appearance = fields?.appearance;
  switch (appearance) {
    case 'Centered':
      return <CmsQuoteCentered fields={fields} sys={sys} />;
    default:
      return <CmsQuote fields={fields} sys={sys} />;
  }
};
