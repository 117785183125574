import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

export enum MenuListVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface MenuListProps {
  variant?: 'primary' | 'secondary' | 'tertiary';
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  isActive?: boolean;
}
