import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'src/components-v2/DataDisplay';
import { TypographyProps } from 'src/components-v2/DataDisplay/Typography/Typography.types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

interface IParagraph {
  p: TypographyProps;
}

const Paragraph = ({ p }: IParagraph, sys) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  return {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Typography
          {...p}
          {...inspectorProps({
            fieldId: p?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
  };
};

export default Paragraph;
