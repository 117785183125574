// compiler-disable-overwrite
import React from 'react';

export default ({ 'p-classes': pClasses, content }) => (
  <div className='grid-container'>
    <div className='grid-x align-center'>
      <div className='cell small-12'>
        <p
          className={pClasses}
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      </div>
    </div>
  </div>
);
