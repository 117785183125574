// @TODO: cookie.js should be considered deprecated,
// in favor of refactoring all cookie logic in React idioms.
// https://jira.plaid.com/browse/SITE-3142
import { getUrlParameter } from '../utilities/urlParameters';
import { isDevelopment } from 'src/lib/constants';

const utmSource = getUrlParameter('utm_source') || null;
const utmMedium = getUrlParameter('utm_medium') || null;
const hasUtmParamsInUrl = utmSource && utmMedium; // minimum UTMs required

export const utmParametersToKeysMapping = {
  utm_source__c: 'utm_source',
  utm_medium__c: 'utm_medium',
  utm_campaign__c: 'utm_campaign',
  utm_term__c: 'utm_term',
  utm_creative__c: 'utm_creative',
  utm_content__c: 'utm_content',
};

/**
 * Get cookie value by cookie name
 *
 * @param {string} [cname=''] cookie name
 * @returns {string}
 */
export const getCookie = (cname = '') => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Sets a cookie
 *
 * @param {Object} [properties]
 * @param {string} [properties.cname=''] cookie name
 * @param {string} [properties.cvalue=''] cookie value
 * @param {string} [properties.domain=''] cookie domain
 * @param {number} [properties.exdays=60] cookie expiration days
 */
export const setCookie = ({
  cname = '',
  cvalue = '',
  domain = 'plaid.com',
  exdays = 60,
}) => {
  const setDomain = isDevelopment ? '' : ` domain=${domain};`;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue}; ${expires};${setDomain} path=/`;
};

/**
 * Backwards compatible function for getting utm params from localstorage or cookie.
 *
 * @param {string} [key='utm']
 * @returns {Object}
 * @TODO: https://jira.plaid.com/browse/SITE-3141 - refactor getStoredUtmParams
 */
export const getStoredUtmParams = (key = 'utm') => {
  const utmParams = getCookie(key);
  if (utmParams !== '') {
    try {
      return JSON.parse(atob(utmParams)) || {};
    } catch (e) {
      return {};
    }
  }
  try {
    return JSON.parse(localStorage.getItem(key)) || {};
  } catch (e) {
    return {};
  }
};

// NOTE: This is a hack to make this function available to MarketoForm
// @TODO: https://jira.plaid.com/browse/SITE-3141 - refactor getStoredUtmParams
export function patchWindowDotPlaid() {
  if (window) {
    window.Plaid = window.Plaid || {};
    window.Plaid.getStoredUtmParams = getStoredUtmParams;
  }
}

/**
 * Stores base64 encoded UTM params in cookie for sharing across plaid.com subdomains
 * This UTM data is sent to Marketo at Signup and other form submissions
 * The use case is attributing leads to the last paid ad campaign that drove
 * the traffic source and conversion (form submission)
 * As such we allow the cookie to be updated to capture the most recent UTMs
 */
function setUtm() {
  // only run if required UTM params are in the URL
  if (hasUtmParamsInUrl) {
    const utmObj = {};
    for (const key of Object.keys(utmParametersToKeysMapping)) {
      utmObj[key] = getUrlParameter(utmParametersToKeysMapping[key]) || null;
    }
    const utmJson = JSON.stringify(utmObj);
    setCookie({ cname: 'utm', cvalue: btoa(utmJson), exdays: 60 });
  }
}

/**
 * Stores referrer url in cookie for sharing across plaid.com subdomains
 * This referrer data is sent to Marketo at Signup and other form submissions
 * The use case is for attributing leads to an originating traffic source
 * As such we are looking to capture and preserve the "original referrer"
 */
function setReferrerUrl() {
  // set a cookie with referrer if not already stored in cookie
  const storedReferrer = getCookie('referrer_url');
  const referrerUrl = document.referrer;
  if (!storedReferrer.length) {
    setCookie({ cname: 'referrer_url', cvalue: referrerUrl, exdays: 60 });
  }
}

/**
 * Stores entry url in cookie for sharing across plaid.com subdomains
 * This data is sent to Marketo at Signup and other form submissions
 * The use case is for attributing leads to an originating traffic source
 * As such we are looking to capture and preserve the original entry url
 */
function setEntryUrl() {
  // set a cookie with entry url if not already stored in cookie
  const storedEntryUrl = getCookie('entry_url');
  const entryUrl = window.location.href;
  if (!storedEntryUrl.length) {
    setCookie({ cname: 'entry_url', cvalue: entryUrl, exdays: 60 });
  }
}

/**
 * Stores gclid URL parameter in a cookie for sharing across plaid.com subdomains.
 * This gclid data is sent to Marketo at Signup and other form submissions.
 * The use case is attributing leads to the last Google Ads campaign that drove
 * the traffic source and conversion (form submission).
 * As such we allow the cookie to be updated to capture the most recent gclid URL param.
 */
function setGclid() {
  // only run if gclid param is in the URL
  const gclidParam = getUrlParameter('gclid');
  if (gclidParam.length > 0) {
    const gclsrcParam = getUrlParameter('gclsrc');
    const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
    if (gclidParam && isGclsrcValid) {
      setCookie({ cname: 'gclid', cvalue: gclidParam, exdays: 60 });
    }
  }
}

// end

export function setUtmAndGclid() {
  setUtm();
  setReferrerUrl();
  setEntryUrl();
  setGclid();
}
