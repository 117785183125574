import React from 'react';

const ArrowRightCaret = () => {
  return (
    <svg height={8} width={6} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M-.174 0l3.6 4.001-3.6 4h2.4l3.6-4-3.6-4z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default ArrowRightCaret;
