import { Option } from 'src/components/Inputs/Select/Select';

/* eslint-disable import/prefer-default-export */
interface CreateSearchDropdown {
  label?: string;
  name?: string;
  describedBy?: string;
  options: Option[] | any;
  defaultOption?: string;
}

/**
 * Normalizes and sorts an array of strings into dropdown options
 * For use with {@link src/components/Inputs/SearchBar/SearchBar.tsx}
 *
 * @param {string} args.label Label to be shown above dropdown
 * @param {string} args.name html name attribute - used as a query param when submitted; can be omitted for no query param
 * @param {Object[]} args.describedBy https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
 * @param {string[]} args.options Array of option strings {opt1, opt2, opt3}
 * @param {string=} [args.defaultOption=options[0]] Optional; defaults to first option in the args.options array
 * @returns {Object[]} Formatted dropdown options
 *
 **/

export const createSearchDropdown = ({
  label = '',
  name = '',
  describedBy = name || '',
  options = [],
  defaultOption = options?.[0] || '',
}: CreateSearchDropdown): Option[] | any => {
  // @TODO align with existing APIs and type definitions https://jira.plaid.com/browse/SITE-3811
  const optionsArr = [];

  options.forEach((option) => {
    if (typeof option === 'string') {
      // @TODO align with existing APIs and type definitions https://jira.plaid.com/browse/SITE-3811
      optionsArr.push({
        name: option,
        label: option,
        value: option,
        default:
          defaultOption &&
          defaultOption.toLowerCase() === option?.toLowerCase(),
      });
    } else {
      optionsArr.push({
        name: option?.name || '',
        label: option?.label || '',
        value: option?.value || '',
        default:
          defaultOption &&
          defaultOption.toLowerCase() === option?.label?.toLowerCase(),
      });
    }
  });

  return {
    label,
    name,
    describedBy,
    options: optionsArr,
  };
};
