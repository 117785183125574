import React from 'react';
// eslint-disable-next-line no-restricted-imports
import ThreadsCallout from 'plaid-threads/Callout';

interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactElement;
  noIcon?: boolean;
  title?: string;
  warning?: boolean;
}

const Callout = (props: Props): JSX.Element => {
  return <ThreadsCallout {...props} />;
};

Callout.displayName = 'Callout';

export default Callout;
