import ArrowLeft from 'plaid-threads/Icons/ArrowLeft';
import ArrowRight from 'plaid-threads/Icons/ArrowRight';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Tutorial.module.scss';
import { useTrack } from 'src/contexts/AnalyticsContext';
import {
  loadStepForTutorial,
  loadUserProjectForTutorial,
  saveLastStepForTutorial,
  TutorialKey,
} from './TutorialUtils';
import { TUTORIAL_EVENTS } from 'src/lib/trackingEvents';

interface Props {
  tutorialKey: TutorialKey;
  userProject: string;
}
let analyticsTimerID;

const Tutorial: React.FC<Props> = ({ tutorialKey, userProject, children }) => {
  // Analytics methods
  const track = useTrack();
  const instructionsRef = useRef(null);

  const trackStepViewed = function (stepNum) {
    track({
      type: TUTORIAL_EVENTS.TUTORIAL_STEP_VIEWED,
      payload: {
        tutorial_id: tutorialKey,
        step_num: stepNum,
      },
    });
  };

  const trackTutorialStart = function (stepNum) {
    track({
      type: TUTORIAL_EVENTS.TUTORIAL_STARTED,
      payload: {
        tutorial_id: tutorialKey,
        step_num: stepNum,
      },
    });
  };

  const [currStep, setCurrStep] = useState(0);

  useEffect(() => {
    const lastStepTaken = loadStepForTutorial(tutorialKey);
    trackTutorialStart(lastStepTaken);
    setCurrStep(parseInt(lastStepTaken));
  }, []);

  useEffect(() => {
    saveLastStepForTutorial(tutorialKey, currStep);
    instructionsRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    if (analyticsTimerID != null) {
      clearTimeout(analyticsTimerID);
    }
    analyticsTimerID = setTimeout(trackStepViewed, 3000, currStep);
  }, [currStep]);

  const steps = React.Children.toArray(children);
  const pctDoneNumber = (currStep / (steps.length - 1)) * 100;
  // Clamp it!
  const pctDone = Math.min(Math.max(0, pctDoneNumber), 100).toFixed(0);

  return (
    <>
      <div className={styles.wrapper}>
        {userProject != null && userProject !== '' && (
          <iframe
            src={`https://glitch.com/embed/#!/embed/${userProject}?path=README.md&previewSize=0&attributionHidden=true`}
            title='Embedded tutorial!'
            allow='geolocation; microphone; camera; midi; encrypted-media; xr-spatial-tracking; fullscreen'
            allowFullScreen
            className={styles.glitchFrame}
          />
        )}
        <div className={styles.instructionsWrapper} ref={instructionsRef}>
          <div className={styles.headerWrapper}>
            <div className={styles.arrowBar}>
              <div className={styles.arrowArea}>
                <button
                  onClick={() => {
                    setCurrStep(Math.max(currStep - 1, 0));
                  }}
                >
                  <ArrowLeft />
                </button>
              </div>
              <div className={styles.stepIntro}>Step {currStep + 1}</div>
              <div className={styles.arrowArea}>
                <button
                  onClick={() => {
                    setCurrStep(Math.min(currStep + 1, steps.length - 1));
                  }}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
            <div
              className={styles.progressBar}
              style={{
                background: `linear-gradient(to right, #5befbd ${pctDone}%, #f6f6f6 ${pctDone}%)`,
              }}
            >
              &nbsp;
            </div>
          </div>
          <div>{steps[currStep]}</div>
        </div>
      </div>
    </>
  );
};

Tutorial.displayName = 'Tutorial';

export default Tutorial;
