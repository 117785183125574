/* eslint-disable import/prefer-default-export */
export const formData = {
  firstName: {
    label: 'First name',
    name: 'FirstName', // no-translate
    describedby: 'your first name',
    autoComplete: 'given-name',
  },
  lastName: {
    label: 'Last name',
    name: 'LastName', // no-translate
    describedby: 'your last name',
    autoComplete: 'family-name',
  },
  email: {
    label: 'Company email',
    name: 'Email', // no-translate
    describedby: 'your email',
    invalidMessage: 'Email address is invalid',
    autoComplete: 'email',
  },
  company: {
    label: 'Company name',
    name: 'Company', // no-translate
    describedby: 'company name',
    autoComplete: 'organization',
  },
  phone: {
    label: 'Phone number (optional)',
    name: 'Phone', // no-translate
    describedby: 'your phone number',
    autoComplete: 'tel',
  },
  country: {
    label: 'Country',
    name: 'Country', // no-translate
    describedby: 'your country',
    autoComplete: 'country-name',
  },
  state: {
    label: 'State',
    name: 'State', // no-translate
    describedby: 'your state',
    autoComplete: 'address-level1',
  },
  plaidForumTicketType: {
    label: 'Ticket type',
    name: 'plaidForumTicketType', // no-translate
    describedby: 'Ticket Type',
    options: [
      { label: 'In person', value: 'In person' },
      { label: 'Virtual', value: 'Virtual' },
    ],
  },
  isWaitlisted: {
    label:
      'Please add me to the waitlist for the in-person event. You will be notified via email when a spot opens up.',
    name: 'isWaitlisted', // no-translate
    describedby: 'Waitlist',
  },
  plaidForumUseCase: {
    label: 'Use case',
    name: 'plaidForumUseCase', // no-translate
    describedby: 'Use Case',
    options: [
      { label: 'Banking', value: 'Banking' },
      { label: 'Consumer Payments', value: 'Consumer Payments' },
      { label: 'Crypto', value: 'Crypto' },
      { label: 'Financial Services', value: 'Financial Services' },
      { label: 'Investments & Wealth', value: 'Investments & Wealth' },
      { label: 'Lending', value: 'Lending' },
      { label: 'Payroll', value: 'Payroll' },
      { label: 'Personal Finances', value: 'Personal Finances' },
      { label: 'Other', value: 'Other' },
    ],
  },
  otherUseCaseExplained: {
    label: 'Please provide what type of user you are',
    name: 'OtherUseCaseExplained',
    describedby: 'Other',
  },
  covidwaiveroptin: {
    label:
      "Check the box if you agree to <a href='https://plaid.com/plaid-forum-terms-and-conditions/'>Plaid's Covid safety protocols & event terms & conditions</a>",
    name: 'covidwaiveroptin', // no-translate
    describedby: 'Covid Opt-in',
  },
  jobTitle: {
    label: 'Job title',
    name: 'Title', // no-translate
    describedby: 'Job Title',
    autoComplete: 'organization-title',
  },
  comments: {
    id: 'Plaid_Comments__c',
    name: 'Plaid_Comments__c',
    label: 'Comments',
    placeholder: 'Tell us more about your request',
  },
  categoryInput: {
    label: 'Select a category',
    id: 'PX_Select_a_Category__c',
    name: 'PX_Select_a_Category__c',
    options: [
      {
        label: 'We want to become a Plaid-supported institution',
        value: 'We want to become a Plaid-supported institution',
      },
      {
        label: 'My customers are having trouble linking their accounts',
        value: 'My customers are having trouble linking their accounts',
      },
      {
        label:
          "I want to update my institution's display name, logo, and/or URL",
        value:
          "I want to update my institution's display name, logo, and/or URL",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  coreProviderInput: {
    label: 'Core provider',
    name: 'PX_Core_Provider__c',
    id: 'PX_Core_Provider__c',
  },
  bankingProviderInput: {
    label: 'Online banking provider',
    name: 'PX_Online_Banking_Provider__c',
    id: 'PX_Online_Banking_Provider__c',
  },
  solutions: {
    label: 'Which solution(s) are you interested in learning about?',
    options: [
      {
        label: 'Identity verification and compliance',
        id: 'identityVerificationCompliance',
        tooltipDescription: 'Identity Verification, Monitor',
      },
      {
        label: 'Lending',
        id: 'lending',
        tooltipDescription: 'IDV, Assets, Income, Auth',
      },
      {
        label: 'Money movement and transfers',
        id: 'moneyMovementTransfers',
        tooltipDescription: 'Auth, Identity, Balane, Transfer',
      },
      {
        label: 'Open finance',
        id: 'openFinance',
        tooltipDescription: 'Core Exchange, Plaid Exchange',
      },
      {
        label: 'Personal finances',
        id: 'personalFinance',
        tooltipDescription:
          'Transactions, Transactions Sync, Recurring Transactions, Investments, Liabilities',
      },
      {
        label: 'Data enrichment',
        id: 'dataEnrichment',
        tooltipDescription: 'Enrich',
      },
    ],
  },
  optInLabel:
    'Opt-in to receive email updates from Plaid about its products, events, and resources. You can opt out at any time.',
  actionText: 'Contact Sales',
  disclaimer:
    "By submitting this form, I confirm that I have read and understood Plaid's <a href='/legal/#privacy-statement'>Privacy Statement</a>." +
    '<br/><br/>' +
    "This form goes to our sales team. If you have questions about connecting your financial accounts to a Plaid-powered app, Visit our <a href='https://support-my.plaid.com/hc/en-us/categories/4405983222679-Connecting-Financial-Accounts'>consumer help center</a> for more information." +
    '<br/><br/>' +
    "For developers and existing Plaid customers, <a href='https://dashboard.plaid.com'>please open a case</a> through the Plaid Dashboard.",
};

export const partnerFormData = {
  firstName: {
    label: 'First name',
    name: 'FirstName', // no-translate
    describedby: 'your first name',
    autoComplete: 'given-name',
  },
  lastName: {
    label: 'Last name',
    name: 'LastName', // no-translate
    describedby: 'your last name',
    autoComplete: 'family-name',
  },
  email: {
    label: 'Company email',
    name: 'Email', // no-translate
    describedby: 'your email',
    invalidMessage: 'Email address is invalid',
    autoComplete: 'email',
  },
  company: {
    label: 'Company name',
    name: 'Company', // no-translate
    describedby: 'company name',
    autoComplete: 'organization',
  },
  phone: {
    label: 'Phone number (optional)',
    name: 'Phone', // no-translate
    describedby: 'your phone number',
    autoComplete: 'tel',
  },
  country: {
    label: 'Country',
    name: 'Country', // no-translate
    describedby: 'your country',
    autoComplete: 'country-name',
  },
  state: {
    label: 'State',
    name: 'State', // no-translate
    describedby: 'your state',
    autoComplete: 'address-level1',
  },
  plaidForumTicketType: {
    label: 'Ticket type',
    name: 'plaidForumTicketType', // no-translate
    describedby: 'Ticket Type',
    options: [
      { label: 'In person', value: 'In person' },
      { label: 'Virtual', value: 'Virtual' },
    ],
  },
  isWaitlisted: {
    label:
      'Please add me to the waitlist for the in-person event. You will be notified via email when a spot opens up.',
    name: 'isWaitlisted', // no-translate
    describedby: 'Waitlist',
  },
  plaidForumUseCase: {
    label: 'Use case',
    name: 'plaidForumUseCase', // no-translate
    describedby: 'Use Case',
    options: [
      { label: 'Banking', value: 'Banking' },
      { label: 'Consumer Payments', value: 'Consumer Payments' },
      { label: 'Crypto', value: 'Crypto' },
      { label: 'Financial Services', value: 'Financial Services' },
      { label: 'Investments & Wealth', value: 'Investments & Wealth' },
      { label: 'Lending', value: 'Lending' },
      { label: 'Payroll', value: 'Payroll' },
      { label: 'Personal Finances', value: 'Personal Finances' },
      { label: 'Other', value: 'Other' },
    ],
  },
  otherUseCaseExplained: {
    label: 'Please provide what type of user you are',
    name: 'OtherUseCaseExplained',
    describedby: 'Other',
  },
  covidwaiveroptin: {
    label:
      "Check the box if you agree to <a href='https://plaid.com/plaid-forum-terms-and-conditions/'>Plaid's Covid safety protocols & event terms & conditions</a>",
    name: 'covidwaiveroptin', // no-translate
    describedby: 'Covid Opt-in',
  },
  jobTitle: {
    label: 'Job title',
    name: 'Title', // no-translate
    describedby: 'Job Title',
    autoComplete: 'organization-title',
  },
  comments: {
    id: 'Plaid_Comments__c',
    name: 'Plaid_Comments__c',
    label: 'Comments',
    placeholder: 'Tell us more about your request',
  },
  categoryInput: {
    label: 'Select a category',
    id: 'PX_Select_a_Category__c',
    name: 'PX_Select_a_Category__c',
    options: [
      {
        label: 'We want to become a Plaid-supported institution',
        value: 'We want to become a Plaid-supported institution',
      },
      {
        label: 'My customers are having trouble linking their accounts',
        value: 'My customers are having trouble linking their accounts',
      },
      {
        label:
          "I want to update my institution's display name, logo, and/or URL",
        value:
          "I want to update my institution's display name, logo, and/or URL",
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  coreProviderInput: {
    label: 'Core provider',
    name: 'PX_Core_Provider__c',
    id: 'PX_Core_Provider__c',
  },
  bankingProviderInput: {
    label: 'Online banking provider',
    name: 'PX_Online_Banking_Provider__c',
    id: 'PX_Online_Banking_Provider__c',
  },
  solutions: {
    label: 'Which solution(s) are you interested in learning about?',
    options: [
      {
        label: 'Identity verification and compliance',
        id: 'identityVerificationCompliance',
        tooltipDescription: 'Identity Verification, Monitor',
      },
      {
        label: 'Lending',
        id: 'lending',
        tooltipDescription: 'IDV, Assets, Income, Auth',
      },
      {
        label: 'Money movement and transfers',
        id: 'moneyMovementTransfers',
        tooltipDescription: 'Auth, Identity, Balane, Transfer',
      },
      {
        label: 'Open finance',
        id: 'openFinance',
        tooltipDescription: 'Core Exchange, Plaid Exchange',
      },
      {
        label: 'Personal finances',
        id: 'personalFinance',
        tooltipDescription:
          'Transactions, Transactions Sync, Recurring Transactions, Investments, Liabilities',
      },
      {
        label: 'Data enrichment',
        id: 'dataEnrichment',
        tooltipDescription: 'Enrich',
      },
    ],
  },
  optInLabel:
    'Opt-in to receive email updates from Plaid about its products, events, and resources. You can opt out at any time.',
  actionText: 'Contact Sales',
  disclaimer:
    "By submitting this form, I confirm that I have read and understood Plaid's <a href='/legal/#privacy-statement'>Privacy Statement</a>." +
    '<br/><br/>' +
    "This form goes to our sales team. If you have questions about connecting your financial accounts to a Plaid-powered app, Visit our <a href='https://support-my.plaid.com/hc/en-us/categories/4405983222679-Connecting-Financial-Accounts'>consumer help center</a> for more information." +
    '<br/><br/>' +
    "For developers and existing Plaid customers, <a href='https://dashboard.plaid.com'>please open a case</a> through the Plaid Dashboard.",
  currentPartner: {
    label: "Current partner's name (if applicable)",
    name: 'currentPartner', // no-translate
    describedby: 'current partner',
  },
  plaidPartners: {
    label: "Select a partner you'd like to work with (optional)",
    name: 'plaidPartners', // no-translate
    describedby: 'Partners',
    options: [
      {
        label: 'ACHQ',
        value: 'ACHQ',
      },
      {
        label: 'Airwallex',
        value: 'Airwallex',
      },
      {
        label: 'Alchemy',
        value: 'Alchemy',
      },
      {
        label: 'Alviere',
        value: 'Alviere',
      },
      {
        label: 'Approve Owl',
        value: 'Approve Owl',
      },
      {
        label: 'Astra, Inc.',
        value: 'Astra, Inc.',
      },
      {
        label: 'Atos',
        value: 'Atos',
      },
      {
        label: 'Bottomline Technologies',
        value: 'Bottomline Technologies',
      },
      {
        label: 'BridgeFT',
        value: 'BridgeFT',
      },
      {
        label: 'Byteplexure Technologies Pvt Ltd',
        value: 'Byteplexure Technologies Pvt Ltd',
      },
      {
        label: 'Capco',
        value: 'Capco',
      },
      {
        label: 'Check',
        value: 'Check',
      },
      {
        label: 'Checkbook',
        value: 'Checkbook',
      },
      {
        label: 'Checkout.com',
        value: 'Checkout.com',
      },
      {
        label: 'Circle',
        value: 'Circle',
      },
      {
        label: 'Cloud Maven, Inc.',
        value: 'Cloud Maven, Inc.',
      },
      {
        label: 'Codat',
        value: 'Codat',
      },
      {
        label: 'Computer Services, Inc.',
        value: 'Computer Services, Inc.',
      },
      {
        label: 'Core10',
        value: 'Core10',
      },
      {
        label: 'Deserve',
        value: 'Deserve',
      },
      {
        label: 'Doconomy',
        value: 'Doconomy',
      },
      {
        label: 'Dwolla',
        value: 'Dwolla',
      },
      {
        label: 'E-Complish',
        value: 'E-Complish',
      },
      {
        label: 'fiVISION',
        value: 'fiVISION',
      },
      {
        label: 'Galileo Financial Technologies',
        value: 'Galileo Financial Technologies',
      },
      {
        label: 'Gusto',
        value: 'Gusto',
      },
      {
        label: 'Highnote',
        value: 'Highnote',
      },
      {
        label: 'Humble Devs',
        value: 'Humble Devs',
      },
      {
        label: 'i2c Inc.',
        value: 'i2c Inc.',
      },
      {
        label: 'Informed.IQ',
        value: 'Informed.IQ',
      },
      {
        label: 'Infosys Ltd.',
        value: 'Infosys Ltd.',
      },
      {
        label: 'Jack Henry',
        value: 'Jack Henry',
      },
      {
        label: 'Jade Global',
        value: 'Jade Global',
      },
      {
        label: 'Kunai',
        value: 'Kunai',
      },
      {
        label: 'MANTL',
        value: 'MANTL',
      },
      {
        label: 'Marotino INC',
        value: 'Marotino INC',
      },
      {
        label: 'Marqeta',
        value: 'Marqeta',
      },
      {
        label: 'MeridianLink',
        value: 'MeridianLink',
      },
      {
        label: 'Modern Treasury',
        value: 'Modern Treasury',
      },
      {
        label: 'Moov Financial',
        value: 'Moov Financial',
      },
      {
        label: 'Moven ',
        value: 'Moven ',
      },
      {
        label: 'nCino',
        value: 'nCino',
      },
      {
        label: 'NCR Terafina',
        value: 'NCR Terafina',
      },
      {
        label: 'Northteq',
        value: 'Northteq',
      },
      {
        label: 'Nova Credit',
        value: 'Nova Credit',
      },
      {
        label: 'Nuvei',
        value: 'Nuvei',
      },
      {
        label: 'Ocrolus',
        value: 'Ocrolus',
      },
      {
        label: 'OpenClose',
        value: 'OpenClose',
      },
      {
        label: 'Patch ',
        value: 'Patch ',
      },
      {
        label: 'Personetics',
        value: 'Personetics',
      },
      {
        label: 'Praxent',
        value: 'Praxent',
      },
      {
        label: 'Prime Trust',
        value: 'Prime Trust',
      },
      {
        label: 'Prism',
        value: 'Prism',
      },
      {
        label: 'Q2',
        value: 'Q2',
      },
      {
        label: 'Rightfoot',
        value: 'Rightfoot',
      },
      {
        label: 'Rize',
        value: 'Rize',
      },
      {
        label: 'Scienaptic AI',
        value: 'Scienaptic AI',
      },
      {
        label: 'Sila',
        value: 'Sila',
      },
      {
        label: 'Silicon Valley Bank',
        value: 'Silicon Valley Bank',
      },
      {
        label: 'Skyflow ',
        value: 'Skyflow ',
      },
      {
        label: 'SS&C Technologies',
        value: 'SS&C Technologies',
      },
      {
        label: 'Synctera',
        value: 'Synctera',
      },
      {
        label: 'Truework',
        value: 'Truework',
      },
      {
        label: 'Turnkey Lender, Inc.',
        value: 'Turnkey Lender, Inc.',
      },
      {
        label: 'Unit',
        value: 'Unit',
      },
      {
        label: 'VGS',
        value: 'VGS',
      },
      {
        label: 'Virtusa',
        value: 'Virtusa',
      },
      {
        label: 'VoPay International Inc.',
        value: 'VoPay International Inc.',
      },
      {
        label: 'Weavik Inc',
        value: 'Weavik Inc',
      },
      {
        label: 'Wyre',
        value: 'Wyre',
      },
    ],
  },
};
