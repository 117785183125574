/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for mvp quote content type to the view
 *
 * @param {Object} args
 * @param {Object} args.logo
 * @param {string} args.quote
 * @param {string} args.cite
 * @param {Object} args.cta
 * @param {Object} args.disableDefaultDesign
 * @param {Object} args.backgroundColor
 * @param {string=} args.heading
 * @returns {Object}
 */
export const mvpQuoteAdapter = ({
  logo,
  quote,
  cite,
  cta,
  disableDefaultDesign,
  backgroundColor,
  heading = '',
}) => {
  return {
    quote,
    logo: {
      // TODO: https://jira.plaid.com/browse/SITE-3947
      // Make alt text required for logo
      alt: _get(logo, 'fields.description', 'logo image'),
      src: _get(logo, 'fields.file.url'),
    },
    cite,
    cta: {
      href: _get(cta, 'fields.url'),
      text: _get(cta, 'fields.text'),
    },
    disableDefaultDesign: Boolean(disableDefaultDesign),
    bgColor: _get(backgroundColor, 'fields.color'),
    heading,
  };
};
