import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AnimatedGroup } from 'src/components/helpers';

// helpers
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';

const propTypes = {
  classes: PropTypes.string,
  legendLabel: PropTypes.string.isRequired,
  legendLabelSecondary: PropTypes.string.isRequired,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      percentage1: PropTypes.number.isRequired,
      percentage2: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  graphTitle: PropTypes.string.isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      mobileDescription: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const Infographic = ({
  classes,
  legendLabel,
  legendLabelSecondary,
  graphs,
  graphTitle,
  metrics,
}) => (
  <AnimatedGroup className={cx('infographic', classes)}>
    <div className='grid-container section-container'>
      <div className='grid-x animated-group'>
        <div className='cell small-12 hide-for-large'>
          <div
            className={cx(
              'infographic-legend',
              animationStyles['animationStylesanimated-group__animation'],
              animationStyles['animated-group__animation--fade-in'],
              animationStyles['animated-group__animation--delay-300'],
              animationStyles['animated-group__animation--duration-300'],
            )}
          >
            <div className='infographic-legend__label'>{legendLabel}</div>
            <div className='infographic-legend__label infographic-legend__label--secondary'>
              {legendLabelSecondary}
            </div>
          </div>
        </div>
        <div className='cell small-12 show-for-large'>
          <hr className='hr hr--thick' />
        </div>
        {graphs.map((item, i) => (
          <div
            key={`item-${i}`}
            className='cell small-12 medium-4 infographic__cell'
          >
            <hr className='hr hr--thick hide-for-large' />
            <div
              className='infographic__heading'
              dangerouslySetInnerHTML={{ __html: item.heading }}
            />
            <div
              className={cx(
                animationStyles['animated-group__animation'],
                animationStyles['animated-group__animation--slide-up-8'],
                animationStyles['animated-group__animation--duration-300'],
              )}
            >
              <hr className='hr hr--color-black700' />
              <div className='infographic__graph'>
                <div className='infographic-bar'>
                  <div
                    className='infographic-bar__container'
                    data-testid={`bar_container_1_${i}`}
                    style={{
                      height: `${item.percentage1}%`,
                    }}
                  >
                    <div
                      className={cx(
                        'infographic-bar__fill',
                        animationStyles['animated-group__transition'],
                        animationStyles[
                          'animated-group__transition--grow-height'
                        ],
                        animationStyles[
                          'animated-group__transition--delay-600'
                        ],
                        animationStyles[
                          'animated-group__transition--duration-300'
                        ],
                      )}
                    ></div>
                    <div
                      className={cx(
                        'infographic-bar__label',
                        animationStyles['animated-group__animation'],
                        animationStyles['animated-group__animation--fade-in'],
                        animationStyles['animated-group__animation--delay-900'],
                        animationStyles[
                          'animated-group__animation--duration-300'
                        ],
                      )}
                    >
                      {item.percentage1}%
                    </div>
                  </div>
                </div>
                <div className='infographic-bar'>
                  <div
                    className='infographic-bar__container'
                    data-testid={`bar_container_2_${i}`}
                    style={{
                      height: `${item.percentage2}%`,
                    }}
                  >
                    <div
                      className={cx(
                        'infographic-bar__fill',
                        'infographic-bar__fill--secondary',
                        animationStyles['animated-group__transition'],
                        animationStyles[
                          'animated-group__transition--grow-height'
                        ],
                        animationStyles[
                          'animated-group__transition--delay-600'
                        ],
                        animationStyles[
                          'animated-group__transition--duration-300'
                        ],
                      )}
                    ></div>
                    <div
                      className={cx(
                        'infographic-bar__label',
                        animationStyles['animated-group__animation'],
                        animationStyles['animated-group__animation--fade-in'],
                        animationStyles['animated-group__animation--delay-900'],
                        animationStyles[
                          'animated-group__animation--duration-300'
                        ],
                      )}
                    >
                      {item.percentage2}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className='cell small-12 show-for-large'>
          <div
            className={cx(
              'infographic-legend',
              animationStyles['animated-group__animation'],
              animationStyles['animated-group__animation--fade-in'],
              animationStyles['animated-group__animation--delay-300'],
              animationStyles['animated-group__animation--duration-300'],
            )}
          >
            <div
              className='infographic-legend__label'
              dangerouslySetInnerHTML={{ __html: legendLabel }}
            />
            <div
              className='infographic-legend__label infographic-legend__label--secondary'
              dangerouslySetInnerHTML={{
                __html: legendLabelSecondary,
              }}
            />
          </div>
        </div>
        <div className='cell small-12'>
          <hr className='hr hr--thick' />
        </div>
        <div
          className='cell small-12 medium-4'
          dangerouslySetInnerHTML={{ __html: graphTitle }}
        />

        {metrics.map((item, i) => (
          <div
            key={`item-${i}`}
            className={cx(
              'infographic-percentage',
              'cell',
              'small-6',
              'medium-4',
              animationStyles['animated-group__animation'],
              animationStyles['animated-group__animation--slide-up-16'],
              animationStyles['animated-group__animation--delay-1200'],
              animationStyles['animated-group__animation--duration-300'],
            )}
          >
            <div className='infographic-percentage__heading'>
              {item.heading}
            </div>
            <div className='show-for-large'>{item.description}</div>
            <div className='hide-for-large'>{item.mobileDescription}</div>
          </div>
        ))}
      </div>
    </div>
  </AnimatedGroup>
);

Infographic.propTypes = propTypes;
export default Infographic;
