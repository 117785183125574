import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'src/components-v2/DataDisplay';
import { TypographyProps } from 'src/components-v2/DataDisplay/Typography/Typography.types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

interface IHeadings {
  h1: TypographyProps;
  h2: TypographyProps;
  h3: TypographyProps;
  h4: TypographyProps;
  h5: TypographyProps;
  h6: TypographyProps;
}

const Headings = ({ h1, h2, h3, h4, h5, h6 }: IHeadings, sys) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  return {
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <Typography
          {...h1}
          {...inspectorProps({
            fieldId: h1?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <Typography
          {...h2}
          {...inspectorProps({
            fieldId: h2?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <Typography
          {...h3}
          {...inspectorProps({
            fieldId: h3?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <Typography
          {...h4}
          {...inspectorProps({
            fieldId: h4?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <Typography
          {...h5}
          {...inspectorProps({
            fieldId: h5?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return (
        <Typography
          {...h6}
          {...inspectorProps({
            fieldId: h6?.fieldId,
          })}
        >
          {children}
        </Typography>
      );
    },
  };
};

export default Headings;
