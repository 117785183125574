import { Locales } from 'src/lib/constants';

export const metaData = {
  'meta-title': 'Global coverage - North America, UK, and Europe',
  'meta-description':
    'Plaid is available in the US, Canada, and Europe, including the UK. Sign up to be notified as soon as we launch in your country.',
};

export const pageHeaderData = {
  'content-width-classes': 'medium-6',
  label: true,
  'label-text': 'Global coverage',
  header: 'We’re here to support you globally and locally',
  'header-class': 'h2',
  subheader:
    'Reach out to a member of our team and learn about how we can work together.',
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '#contact-form',
  'secondary-cta': false,
  'secondary-cta-text': null,
  'tertiary-cta': false,
  image: true,
  'image-url': '/assets/img/hero/global-hero.png',
  'image-width': 944,
  'image-height': 487,
};

export const ctaData = {
  id: 'contact-form',
  'marketo-key': 1191,
  shouldForward: false,
  title: 'Questions about how Plaid can fit into your business?',
  'with-subtext': true,
  subtext: 'Tell us how we can help and we’ll get in touch shortly.',
  'subtext-class': 'h4 regular',
  'include-phone': true,
  hasOnPagePostSubmitContent: true,
  'post-submit-header': 'Thank you!',
  'post-submit-subheader':
    'Your message has been recorded and a Plaid team member will be in touch soon',
  'post-submit-action-text': 'View our products',
  'post-submit-action-link': '/products',
};

export const globalTableData = {
  title: 'Countries and products supported',
  tableData: [
    [
      {
        img: '/assets/img/icons/flags/flag-uk.png',
        alt: 'UK Flag',
        label: 'UK',
        products: [
          {
            feature: 'Payments',
            url: '/en-gb/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-gb/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-gb/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-gb/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-gb/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-gb/products/assets',
          },
          {
            feature: 'Income',
            url: '/products/income',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Identity Verification',
            url: '/en-gb/products/identity-verification',
          },
          {
            feature: 'Monitor',
            url: '/en-gb/products/monitor',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-de.png',
        alt: 'Germany flag',
        label: 'Germany',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-fr.png',
        alt: 'France flag',
        label: 'France',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
    ],
    [
      {
        img: '/assets/img/icons/flags/flag-nl.png',
        alt: 'Netherlands flag',
        label: 'Netherlands',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-ie.png',
        alt: 'Ireland flag',
        label: 'Ireland',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-es.png',
        alt: 'Spain flag',
        label: 'Spain',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
    ],
    [
      {
        img: '/assets/img/icons/flags/flag-se.png',
        alt: 'Sweden flag',
        label: 'Sweden',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-dk.png',
        alt: 'Denmark flag',
        label: 'Denmark',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-pl.png',
        alt: 'Poland flag',
        label: 'Poland',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
    ],
    [
      {
        img: '/assets/img/icons/flags/flag-pt.png',
        alt: 'Portugal flag',
        label: 'Portugal',
        products: [
          {
            feature: '* Payments coming soon *',
            url: '',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-it.png',
        alt: 'Italy flag',
        label: 'Italy',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-lt.png',
        alt: 'Lithuania flag',
        label: 'Lithuania',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
    ],
    [
      {
        img: '/assets/img/icons/flags/flag-lv.png',
        alt: 'Latvia flag',
        label: 'Latvia',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-ee.png',
        alt: 'Estonia flag',
        label: 'Estonia',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-no.png',
        alt: 'Norway flag',
        label: 'Norway',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
    ],
    [
      {
        img: '/assets/img/icons/flags/flag-be.png',
        alt: 'Belgium flag',
        label: 'Belgium',
        products: [
          {
            feature: 'Payments',
            url: '/en-eu/products/payment-initiation',
          },
          {
            feature: 'Transactions',
            url: '/en-eu/products/transactions',
          },
          {
            feature: 'Auth',
            url: '/en-eu/products/auth',
          },
          {
            feature: 'Balance',
            url: '/en-eu/products/balance',
          },
          {
            feature: 'Identity',
            url: '/en-eu/products/identity',
          },
          {
            feature: 'Assets',
            url: '/en-eu/products/assets',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-us.png',
        alt: 'USA flag',
        label: 'USA',
        products: [
          {
            feature: 'Transactions',
            url: '/products/transactions',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Auth',
            url: '/products/auth',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Balance',
            url: '/products/balance',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Identity',
            url: '/products/identity',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Assets',
            url: '/products/assets',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Investments',
            url: '/products/investments',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Liabilities',
            url: '/products/liabilities',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Income',
            url: '/products/income/',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Identity Verification',
            url: '/products/identity-verification',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Monitor',
            url: '/products/monitor',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Signal',
            url: '/products/signal/',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Enrich',
            url: '/products/enrich/',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Beacon',
            url: '/products/beacon/',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Transfer',
            url: '/products/transfer/',
            locale: Locales.EN_US, // forces link to en-us
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-ca.png',
        alt: 'Canada flag',
        label: 'Canada',
        products: [
          {
            feature: 'Transactions',
            url: '/products/transactions',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Auth',
            url: '/products/auth',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Balance',
            url: '/products/balance',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Identity',
            url: '/products/identity',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Assets',
            url: '/products/assets',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Investments',
            url: '/products/investments',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Identity Verification',
            url: '/products/identity-verification',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Monitor',
            url: '/products/monitor',
            locale: Locales.EN_US, // forces link to en-us
          },
          {
            feature: 'Enrich',
            url: '/products/enrich/',
            locale: Locales.EN_US, // forces link to en-us
          },
        ],
      },
    ],
  ],
};

export const globalTableBetaCountriesData = {
  title: 'Coming soon',
  tableData: [
    [
      {
        img: '/assets/img/icons/flags/flag-at.png',
        alt: 'Austria flag',
        label: 'Austria',
        products: [
          {
            feature: '* Coming soon *',
            url: '',
          },
        ],
      },
      {
        img: '/assets/img/icons/flags/flag-fi.png',
        alt: 'Finland flag',
        label: 'Finland',
        products: [
          {
            feature: '* Coming soon *',
            url: '',
          },
        ],
      },
    ],
  ],
};
