import React from 'react';

import { Section, Container, Row, Column } from 'src/components-v2/Layout';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { CmsMediaSwitch } from 'src/components-v2/Media';

const APPEARANCE = {
  large: 'Large width',
  medium: 'Medium width',
  small: 'Small width',
};

const SectionAsset = ({ fields, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const { asset, appearance, backgroundColor } = fields || {};
  const bgColor = backgroundColor?.toLowerCase() || 'transparent';
  const assetProps = {
    ...asset?.fields,
    ...(asset?.fields?.appearance !== 'Lottie' && {
      width: '100%',
      height: 'auto',
    }),
  };
  const sectionCommonProps = {
    sx: { backgroundColor: bgColor, ...fields?.styles },
    ...inspectorProps({ fieldId: 'internalTitle' }),
  };

  switch (appearance) {
    case APPEARANCE.large: {
      return (
        <Section {...sectionCommonProps}>
          <Container
            sx={{
              px: {
                xs: '0rem',
                lg: '4rem',
              },
            }}
          >
            <Row sx={{ my: 0 }}>
              <Column xs={24} sx={{ py: 0 }}>
                <CmsMediaSwitch asset={assetProps} />
              </Column>
            </Row>
          </Container>
        </Section>
      );
    }

    case APPEARANCE.medium: {
      return (
        <Section {...sectionCommonProps}>
          <Container
            sx={{
              px: {
                xs: '0.8rem',
                sm: '4.35rem',
                lg: '4rem',
              },
            }}
          >
            <Row sx={{ my: 0 }}>
              <Column xs={24} lg={20} lgOffset={2} sx={{ py: 0 }}>
                <CmsMediaSwitch asset={assetProps} />
              </Column>
            </Row>
          </Container>
        </Section>
      );
    }

    case APPEARANCE.small: {
      return (
        <Section {...sectionCommonProps}>
          <Container
            sx={{
              px: {
                xs: '0.8rem',
                sm: '4.35rem',
                lg: '4rem',
              },
            }}
          >
            <Row sx={{ my: 0 }}>
              <Column
                xs={24}
                xsOffset={0}
                sm={20}
                smOffset={2}
                lg={8}
                lgOffset={8}
                sx={{ py: 0 }}
              >
                <CmsMediaSwitch asset={assetProps} />
              </Column>
            </Row>
          </Container>
        </Section>
      );
    }

    default: {
      return null;
    }
  }
};

export default SectionAsset;
