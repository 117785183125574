import { useEffect, useState } from 'react';
import styled from 'threads5/styles/styled';
import PlaidTheme from 'threads5/themes/theme';
import SxProps from 'threads5/themes/sx-props';

const StyledOneTrustButton = styled('button')({
  padding: 0,
  margin: 0,
  border: 'none',
  color: '#fff',
  '&:hover': {
    color: '#63daff',
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  fontWeight: '600',
  svg: {
    with: 'auto',
    height: '20px',
  },
});
export type OneTrustButtonProps = {
  sx?: SxProps<PlaidTheme>;
};
export default function OneTrustButton(props: OneTrustButtonProps) {
  const [oneTrustLoaded, setOneTrustLoaded] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!!window.OneTrust) {
        setOneTrustLoaded(true);
        return;
      }

      // if OneTrust isn't loaded, try waiting for it a bit.
      // try 10 times then bail
      let ticks = 10;
      const maybeOneTrust = setInterval(() => {
        ticks--;
        if (!!window.OneTrust) {
          setOneTrustLoaded(!!window.OneTrust);
          clearInterval(maybeOneTrust);
        }
        if (ticks <= 0) {
          clearInterval(maybeOneTrust);
        }
      }, 100);

      return () => {
        return clearInterval(maybeOneTrust);
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (oneTrustLoaded) {
        // if the floater cookie image is there, remove it
        const floater = document.getElementById('ot-sdk-btn-floating');
        if (floater) {
          floater.style.display = 'none';
        }

        // add css class to body to enable styles to hide the floater cookie image
        document.body.classList.add('ot-v2');
      }
    }
  }, [oneTrustLoaded]);

  if (!oneTrustLoaded) return null;

  return (
    <StyledOneTrustButton
      onClick={() => {
        window?.OneTrust?.ToggleInfoDisplay();
      }}
      sx={props.sx}
    >
      <span>Your Privacy Choices</span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        viewBox='0 0 30 14'
      >
        <path
          d='M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8z'
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#fff',
          }}
        />
        <path
          d='M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8z'
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#06f',
          }}
        />
        <path
          d='M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0z'
          style={{
            fill: '#fff',
          }}
        />
        <path
          d='M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0z'
          style={{
            fill: '#06f',
          }}
        />
      </svg>
    </StyledOneTrustButton>
  );
}
