import utils from './utilities';

// For each page-load, track a new 'Page' event, with additional
const trackPageView = () => {
  const defaultParams = utils.getDefaultParameters();
  window?.analytics?.page('page-view', 'Page', defaultParams, {
    context: {
      ip: '0.0.0.0',
    },
  });
};

export default {
  trackPageView,
};
