import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

const propTypes = {
  hClassName: PropTypes.string, // optionally use for generalized h[n] styles
  h1ClassName: PropTypes.string,
  h2ClassName: PropTypes.string,
  h3ClassName: PropTypes.string,
  h4ClassName: PropTypes.string,
  h5ClassName: PropTypes.string,
  h6ClassName: PropTypes.string,
};

const Headings = ({
  hClassName = '',
  h1ClassName = '',
  h2ClassName = '',
  h3ClassName = '',
  h4ClassName = '',
  h5ClassName = '',
  h6ClassName = '',
}) => {
  return {
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className={cx(hClassName, h1ClassName)}>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className={cx(hClassName, h2ClassName)}>{children}</h2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className={cx(hClassName, h3ClassName)}>{children}</h3>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className={cx(hClassName, h4ClassName)}>{children}</h4>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className={cx(hClassName, h5ClassName)}>{children}</h5>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className={cx(hClassName, h6ClassName)}>{children}</h6>;
    },
  };
};
Headings.propTypes = propTypes;

export default Headings;
