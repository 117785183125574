import React, { useState } from 'react';
import { Locales } from 'src/lib/constants';
import { toKebabCase } from 'src/lib/utils';
import { dangerous_getStaticData } from 'src/lib/getStaticData';

// lazy load template so that we have access to the window object
import DocsTemplate from 'src/templates/DocsTemplate/DocsTemplate';

//components
import { Link } from 'src/components/Inputs';

// meta data
const metaData = {
  layout: 'docs',
  'meta-title': 'Developer Policy',
  'subnav-filters-hidden': 'true',
  'hide-search': 'true',
};
const DeveloperPolicy = ({ locale = Locales.EN_US }) => {
  const [filePath, setFilePath] = useState('developer-policy');
  const [isPolicy2019, setIsPolicy2019] = useState(false);
  const [isPolicy2022, setIsPolicy2022] = useState(true);

  const changeFilePath = (file) => {
    return setFilePath(file);
  };
  const { intro, tableOfContentsTitle, sections } = dangerous_getStaticData({
    locale,
    filePath,
  });

  return (
    <DocsTemplate {...metaData} locale={locale}>
      <div className='bg-docs'>
        <div id='docsWrapper' className='grid-container'>
          <div className='grid-x'>
            <aside id='docsSidenav' className='cell large-3 sidenav-docs'>
              <div
                className='sticky show-for-large'
                data-sticky
                data-top-anchor='docsSidenav:top'
                data-btm-anchor='footer:top'
                data-margin-top='8'
                data-margin-bottom='1'
                data-check-every='0'
              >
                <ul
                  id='desktopSidenavMenu'
                  className='menu sidenav-menu vertical'
                  data-sidenav-toggle
                >
                  <ul
                    style={{ display: 'block' }}
                    className='menu sidenav-submenu vertical'
                    data-sidenav-toggle
                  >
                    <li>
                      <a
                        href={() => {
                          return false;
                        }}
                        tabindex='0'
                        className={isPolicy2022 ? 'is-active' : null}
                        onClick={() => {
                          setIsPolicy2022(true);
                          setIsPolicy2019(false);
                          return changeFilePath
                            ? changeFilePath('developer-policy')
                            : null;
                        }}
                        onKeyDown={() => {
                          setIsPolicy2022(true);
                          setIsPolicy2019(false);
                          return changeFilePath
                            ? changeFilePath('developer-policy')
                            : null;
                        }}
                      >
                        {'Developer Policy'}
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => {
                          return false;
                        }}
                        tabindex='0'
                        className={isPolicy2019 ? 'is-active' : null}
                        onClick={() => {
                          setIsPolicy2022(false);
                          setIsPolicy2019(true);
                          return changeFilePath
                            ? changeFilePath('developer-policy-2019')
                            : null;
                        }}
                        onKeyDown={() => {
                          setIsPolicy2022(false);
                          setIsPolicy2019(true);
                          return changeFilePath
                            ? changeFilePath('developer-policy-2019')
                            : null;
                        }}
                      >
                        {'Developer Policy (2019)'}
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
            </aside>
            <article
              id='docsArticle'
              className='cell small-12 large-auto article-docs'
            >
              <div className='article-docs-content'>
                <div id='policy-intro'>
                  <h2 id={filePath}>{intro.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: intro.effectiveDate }}
                  />
                  {Array.isArray(intro.description)
                    ? intro.description?.map((paragraph) => {
                        return (
                          <p
                            dangerouslySetInnerHTML={{ __html: paragraph }}
                          ></p>
                        );
                      })
                    : intro?.description}
                </div>
                <div id='policy-table-of-contents'>
                  <p>
                    <em>{tableOfContentsTitle}</em>
                  </p>
                  {sections.map((section, i) => {
                    return (
                      <p key={`${section.title}-${i}`}>
                        <Link
                          href={`#${toKebabCase(section.title) || section?.id}`}
                        >
                          <a data-smooth-scroll data-offset='-10'>
                            {section.title}
                          </a>
                        </Link>
                      </p>
                    );
                  })}
                </div>
                <div id='policy-sections'>
                  {sections.map((section, i) => {
                    return (
                      <div
                        className='policy-section'
                        key={`${section.title}-${i}`}
                      >
                        <h5 id={toKebabCase(section.title) || section?.id}>
                          {section.title}
                        </h5>
                        <p>
                          {Array.isArray(section?.description)
                            ? section?.description?.map((desc) => {
                                if (Array.isArray(desc)) {
                                  return (
                                    <ul>
                                      {desc.map((listItem) => {
                                        return (
                                          <li
                                            dangerouslySetInnerHTML={{
                                              __html: listItem,
                                            }}
                                          />
                                        );
                                      })}
                                    </ul>
                                  );
                                } else {
                                  return (
                                    <p
                                      dangerouslySetInnerHTML={{ __html: desc }}
                                    />
                                  );
                                }
                              })
                            : section?.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </DocsTemplate>
  );
};

export default DeveloperPolicy;
