import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Search from 'plaid-threads/Icons/Search';

import PartnerCardTile from './PartnerCardTile/PartnerCardTile';
import styles from './PartnerCards.module.scss';
import { TextInput } from 'src/components/Inputs';

// contexts
import { useTrack } from 'src/contexts/AnalyticsContext';

// helpers
import { PARTNERSHIP_DIRECTORY_EVENTS } from 'src/lib/trackingEvents';

const propTypes = {
  components: PropTypes.shape({
    partnerCards: PropTypes.shape({
      pageSlug: PropTypes.string,
      description: PropTypes.string,
      img: PropTypes.string,
      partnerTag: PropTypes.string,
      partnerName: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};
const filterPartnerCards = (partnerCards, input) => {
  if (input === 'All Industries') {
    return partnerCards;
  }
  return partnerCards.filter(
    (cards) =>
      cards.partnerName.toLowerCase().includes(input.toLowerCase()) ||
      cards.partnerTag.toLowerCase().includes(input.toLowerCase()) ||
      (cards.isFeatured && 'solutions partner'.includes(input.toLowerCase())),
  );
};

const PartnerCardsSection = (components) => {
  const [partnerCards, setPartnerCards] = useState(components.partnerCards);
  const [selectedIndustry, setSelectedIndustry] = useState(-1);
  const [value, setValue] = useState('');
  const router = useRouter();
  const { type } = router.query;

  const track = useTrack();

  const arrayOfTags = [
    ...new Set(components.partnerCards.map(({ partnerTag }) => partnerTag)),
  ];

  useEffect(() => {
    // if parameter passed through router we populate search bar with parameter
    // and display partner cards filtered by parameter
    if (type) {
      setPartnerCards(filterPartnerCards(components.partnerCards, type));
    }
  }, [components.partnerCards, type]);

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    setPartnerCards(
      filterPartnerCards(components.partnerCards, e.target.value),
      setSelectedIndustry(null),
    );
  };

  const selectIndustry = (industry, index) => {
    setPartnerCards(filterPartnerCards(components.partnerCards, industry));
    setSelectedIndustry(index);
    setValue('');
  };

  return (
    <div className={styles.content}>
      <div className='grid-container'>
        <TextInput
          id='searchInput'
          placeholder='Search by name or industry...'
          onChange={onChange}
          value={value}
          isDarkMode={false}
          iconButtons={[<Search />]}
        />
        <ul className={styles.selectContainer}>
          <li
            className={cx(
              styles.__item,
              selectedIndustry === -1 && styles.__btnIsActive,
            )}
          >
            <button
              onClick={() => {
                selectIndustry('All Industries', -1);
              }}
              type='button'
              className={cx(
                'button',
                styles.__btn,
                selectedIndustry === -1 && styles.__btnIsActive,
              )}
              disabled={false}
              data-filter-id='All Industries'
            >
              All Industries
            </button>
          </li>
          {arrayOfTags.map((tag, index) => {
            return (
              <li
                className={cx(
                  styles.__item,
                  selectedIndustry === index && styles.__btnIsActive,
                )}
                key={`filter-${tag}`}
              >
                <button
                  onClick={() => {
                    selectIndustry(tag, index);
                    track({
                      type: PARTNERSHIP_DIRECTORY_EVENTS.INDUSTRY_SEARCH,
                      payload: { industry: tag },
                    });
                  }}
                  type='button'
                  className={cx(
                    'button',
                    styles.__btn,
                    selectedIndustry === index && styles.__btnIsActive,
                  )}
                  disabled={false}
                  data-filter-id={tag}
                >
                  {tag}
                </button>
              </li>
            );
          })}
        </ul>
        <div className={styles.collection}>
          {partnerCards.map((item, i) => (
            <PartnerCardTile key={`item-${i}`} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

PartnerCardsSection.propTypes = propTypes;

export default PartnerCardsSection;
