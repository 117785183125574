import React from 'react';
import cx from 'classnames';

// components
import { CodeSnippet } from 'src/components/Surfaces';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

//helper
import { optimizeImage } from 'src/lib/image-helpers';

const TextSection = (props) => (
  <div
    className={cx('section-text', 'cell', 'small-12', {
      end: props['flip-horizontal'],
      [`medium-${props['text-section-col-size']}`]: props[
        'text-section-col-size'
      ],
    })}
  >
    <h6 className='section-title hide-for-small-only'>&nbsp;</h6>
    {props.label && <h6 className='section-label'>{props.label}</h6>}
    {props.title && (
      <h4
        className='section-header'
        dangerouslySetInnerHTML={{ __html: props.title }}
      />
    )}
    <p
      className='content-carousel-description'
      dangerouslySetInnerHTML={{ __html: props.body }}
    />
    {props['cta-url'] && (
      <Link href={props['cta-url']}>
        <a>
          <span className='feature-section-link-text'>
            {props['cta-text']}
            <ArrowRightSingleSmall />
          </span>
        </a>
      </Link>
    )}
  </div>
);

const ImageSection = (props) => (
  <div
    className={cx(
      'small-12 cell description-row-wrapper',
      props.classes,
      props['custom-image-offset'],
      {
        [`medium-${props['image-section-col-size']}`]: props[
          'image-section-col-size'
        ],
        [`medium-offset-${props['image-section-col-offset']}`]:
          !props['flip-horizontal'] && props['image-section-col-offset'],
        flipped: !props['not-flipped'],
        'hide-for-small-only': props['mobile-hide-brush-section'],
      },
    )}
  >
    <div
      className={cx(props['accent-modifier'], {
        'description-row-accent': !props['standard-image'],
        'description-row-accent--hide': props['mobile-image'],
        'right-align-brush':
          !props['flip-horizontal'] && !props['accent-modifier'],
        'description-row-accent--mobile-image':
          !props['flip-horizontal'] && props['mobile-image'],
      })}
    >
      <Image
        className='description-row-image'
        src={props.image}
        alt={props.alt}
      />
      {props['mobile-image'] && (
        <Image
          className='show-for-small-only'
          src={props['mobile-image']}
          alt={props.alt}
        />
      )}
    </div>
    {props['link-video'] && (
      <>
        <Image
          className={cx(
            'simple-carousel-phone-img',
            props['carousel-modifier'],
          )}
          src={
            props['override-phone']
              ? props['override-phone']
              : '/assets/img/phones/screen1.png'
          }
          width={props['phone-width'] ? props['phone-width'] : '304'}
        />
        {!props['hide-video'] && (
          <video
            className={cx('phone-video', props['phone-video-class'])}
            autoPlay
            loop
            muted
            width='267'
            height='578'
            poster={optimizeImage(
              '/assets/img/backgrounds/institution-list@2x.jpg',
            )}
          >
            <source src='/assets/video/how-it-works.webm' type='video/webm' />
            <source src='/assets/video/how-it-works.mp4' type='video/mp4' />
          </video>
        )}
      </>
    )}
    {props['primary-image'] && (
      <Image
        src={props['primary-image']}
        height={props['primary-image-height']}
        width={props['primary-image-width']}
      />
    )}
    {props.code && (
      <div className='description-row-code'>
        <CodeSnippet syntax='html' children={props.code} />
      </div>
    )}
  </div>
);

const DescriptionRow = (props) => (
  <div
    className={cx({
      [`background background--color-${props['bg-color']}`]: props['bg-color'],
    })}
  >
    <div
      className={cx(
        'grid-x grid-container description-row',
        props['custom-desc-row-margin'],
        {
          'description-row--row-reverse': props['row-reverse'],
          'description-row--extra-bottom-margin': props['extra-bottom-margin'],
          'description-row--extra-extra-bottom-margin':
            props['extra-extra-bottom-margin'],
        },
      )}
    >
      {props['flip-horizontal'] ? (
        <>
          <ImageSection {...props} />
          <TextSection {...props} />
        </>
      ) : (
        <>
          <TextSection {...props} />
          <ImageSection {...props} />
        </>
      )}
    </div>
  </div>
);

export default DescriptionRow;
