import React from 'react';
import PropTypes from 'prop-types';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { RichTextShape, TargetButtonShape } from 'src/lib/prop-types';
import Paywall from 'src/components/Surfaces/Paywall';
import { CtaButton } from 'src/components/Inputs';
import { usePaywallProvider } from 'src/components/Surfaces';

export const CtaShape = PropTypes.shape({
  hasPrimaryCta: PropTypes.bool.isRequired,
  primaryCtaText: PropTypes.string,
  primaryCtaLink: PropTypes.string,
  primaryCtaTrackingId: PropTypes.string,
  hasSecondaryCta: PropTypes.bool.isRequired,
  secondaryCtaText: PropTypes.string,
  secondaryCtaLink: PropTypes.string,
  secondaryCtaTrackingId: PropTypes.string,
  hasTertiaryCta: PropTypes.bool.isRequired,
  tertiaryCta: RichTextShape,
  primaryCtaExperiment: PropTypes.any,
  secondaryCtaExperiment: PropTypes.any,
});

const CtaSectionProps = {
  cta: CtaShape,
  targetButton: TargetButtonShape,
};

// TODO: https://jira.plaid.com/browse/SITE-2057
// .button-group Foundation class to be decoupled / replaced.
// It is targeted by contextual (leaky scope) selectors in .page-header.scss
// which will also need to be addressed.

const CtaSection = ({ cta, targetButton }) => {
  const { setIsModalOpen, isContentUnlocked, renderOption, hasPaywall } =
    usePaywallProvider();

  return (
    <>
      <div className='button-group button-group--margin-bottom'>
        {cta.hasPrimaryCta && !cta.primaryCtaExperiment && (
          <CtaButton
            href={cta.primaryCtaLink}
            trackingId={cta.primaryCtaTrackingId}
            className='signup-attach-utm'
          >
            {cta.primaryCtaText}
          </CtaButton>
        )}
        {cta.hasSecondaryCta && !cta.secondaryCtaExperiment && (
          <CtaButton
            href={cta.secondaryCtaLink}
            trackingId={cta.secondaryCtaTrackingId}
            secondary
          >
            {cta.secondaryCtaText}
          </CtaButton>
        )}
      </div>
      {cta.hasTertiaryCta && (
        <RichTextRenderer
          content={cta.tertiaryCta}
          classes={{
            pClassName: 'page-header-subtext',
          }}
        />
      )}
      <Paywall.TargetButton
        targetButton={targetButton}
        setIsModalOpen={setIsModalOpen}
        hasPaywall={hasPaywall}
        isContentUnlocked={isContentUnlocked}
        renderOption={renderOption}
      />
    </>
  );
};

CtaSection.propTypes = CtaSectionProps;

export default CtaSection;
