import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Remove a fraud alert',
      'meta-description': 'Remove a fraud alert',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Remove a fraud alert',
    introHeader: 'Introduction',
    requestType: 'Remove fraud alert',
    intro: `<p>Complete this form in order to remove a fraud alert from your Plaid Check consumer file.</p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
