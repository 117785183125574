import React from 'react';
import { array, bool } from 'prop-types';

import CredibilityItem from './CredibilityItem';
import { CarouselIterator } from 'src/components/helpers';

const propTypes = {
  items: array.isRequired,
  carousel: bool,
};

const CredibilityCarousel = ({ items, carousel = false, ...props }) => (
  <div className='credibility'>
    <CarouselIterator
      items={items}
      isCarousel={carousel}
      render={(item) => <CredibilityItem item={item} {...props} />}
    />
  </div>
);

CredibilityCarousel.propTypes = propTypes;

export default CredibilityCarousel;
