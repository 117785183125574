import React, { FC, useState } from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { useDeviceSize } from 'src/hooks';
import {
  MultiStateCarouselProps,
  ContentListItemProps,
} from './MultiStateCarousel.types';
import {
  Box,
  Column,
  Container,
  Row,
  Section,
  Stack,
} from 'src/components-v2/Layout';
import { AccordionItem, HoverItem } from 'src/components-v2/Surfaces';
import { AccordionItemProps } from 'src/components-v2/Surfaces/AccordionItem/AccordionItem.types';
import { MediaSwitch } from 'src/components-v2/Media';

const assetStyles = {
  '& video, img': {
    width: '100%',
    height: 'auto',
    borderRadius: '0.9rem',
  },
};

const MultiStateCarousel: FC<MultiStateCarouselProps> = ({
  children,
  fieldId = '',
  heading,
  isReversed = false,
  items,
  sx = {},
  sys,
  appearance = 'accordion',
}) => {
  const itemType = {
    accordion: AccordionItem,
    hover: HoverItem,
  };
  const ItemComponent = itemType[appearance];
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  const device = useDeviceSize();
  const isMobile = device.isSmall;
  const [activeIndexes, setActiveIndexes] = useState(new Set([0]));
  const [firstActiveIndex] = activeIndexes;

  const toggleAccordion = (i) => {
    setActiveIndexes((prevState) => {
      if (isMobile) {
        if (prevState.has(i)) {
          prevState.delete(i);
        } else {
          prevState.add(i);
        }
      } else {
        prevState.clear();
        prevState.add(i);
      }
      return new Set(prevState);
    });
  };

  const handleClick = (i) => {
    toggleAccordion(i);
  };

  return (
    <Section
      sx={{
        '& h2': {
          mb: { xs: '1.8rem', sm: '2.0rem', md: '3.2rem' },
        },
        '& header': {
          '& p': {
            mb: '0.8rem',
          },
        },
        ...sx,
      }}
      {...sx}
      className='multiStateCarousel'
    >
      {heading && heading}
      <Container
        {...inspectorProps({
          fieldId,
        })}
      >
        <Row>
          {isReversed ? (
            <>
              <MediaColumn
                asset={items && items[firstActiveIndex]?.asset}
                isReversed={isReversed}
              />
              <ContentColumn
                items={items}
                activeIndexes={activeIndexes}
                onClick={handleClick}
                isReversed={isReversed}
                ItemComponent={ItemComponent}
              >
                {children}
              </ContentColumn>
            </>
          ) : (
            <>
              <ContentColumn
                items={items}
                activeIndexes={activeIndexes}
                onClick={handleClick}
                isReversed={isReversed}
                ItemComponent={ItemComponent}
              >
                {children}
              </ContentColumn>
              <MediaColumn
                asset={items?.[firstActiveIndex]?.asset}
                isReversed={isReversed}
              />
            </>
          )}
        </Row>
      </Container>
    </Section>
  );
};

export default MultiStateCarousel;

const MediaEl = ({ asset }) => {
  return (
    <MediaSwitch
      asset={{
        ...asset,
      }}
    />
  );
};

const ContentColumn = ({
  children,
  items,
  activeIndexes,
  onClick,
  isReversed,
  ItemComponent,
}: React.PropsWithChildren<{
  items: Array<ContentListItemProps>;
  activeIndexes: Set<number>;
  onClick: (i: number, wasClicked?: boolean) => void;
  isReversed: boolean;
  ItemComponent: React.FC<AccordionItemProps>;
}>) => {
  return (
    <Column
      xs={22}
      xsOffset={1}
      sm={isReversed ? 11 : 12}
      smOffset={isReversed ? 1 : 0}
      lg={9}
      lgOffset={isReversed ? 1 : 2}
    >
      <Stack
        justifyContent='center'
        sx={{
          height: '100%',
          '& h3': {
            mb: '0.8rem',
          },
          '& h4': {
            mb: '0',
            fontWeight: '600',
            pr: { xs: '0.8rem', lg: '1.5rem' },
            color: 'inherit',
            display: 'flex',
            '& span': { mr: { xs: '0.8rem', lg: '1.5rem' }, display: 'flex' },
          },
          '& p:not(:first-child)': {
            mb: '0.8rem',
          },
          '& p:first-child': {
            mb: '0',
            pb: { xs: '1.7rem', sm: '2.0rem', lg: '2.5rem' },
            pr: '2.5rem',
          },
        }}
      >
        {children}
        <Box component='ul' sx={{ m: '0', listStyle: 'none' }}>
          {items?.map((item, i) => {
            const isActive = activeIndexes.has(i);
            const [firstActiveIndex] = activeIndexes;
            return (
              <ItemComponent
                key={`content-${i}`}
                {...item}
                onClick={() => {
                  return onClick(i, true);
                }}
                isActive={isActive}
                index={i}
                activeIndex={firstActiveIndex}
                sx={item?.sx}
                sys={item?.sys}
                content={
                  <>
                    {item?.content}
                    <Box
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        pb: { xs: '2.5rem', sm: '0' },
                        ...assetStyles,
                      }}
                    >
                      <MediaEl asset={item?.asset} />
                    </Box>
                  </>
                }
              />
            );
          })}
        </Box>
      </Stack>
    </Column>
  );
};

const MediaColumn = ({ asset, isReversed }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: asset?.sys?.id,
  });
  return (
    <Column
      xs={24}
      sm={isReversed ? 12 : 11}
      smOffset={isReversed ? 0 : 1}
      lg={10}
      lgOffset={isReversed ? 2 : 1}
      sx={{ display: { xs: 'none', sm: 'block' } }}
    >
      <Stack
        direction='column'
        justifyContent='center'
        sx={{
          height: '100%',
          ...assetStyles,
          ...asset?.sx,
        }}
        {...inspectorProps({
          fieldId: asset?.fieldId,
        })}
      >
        <MediaEl asset={asset} />
      </Stack>
    </Column>
  );
};
