import React, { FC } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Image, AnimatedVideo } from 'src/components-v2/Media';
import { isProduction } from 'src/lib/constants';
import { Box } from 'src/components-v2/Layout';
import { AssetTypeProps } from 'src/lib/ts-types';

interface MediaSwitchProps {
  asset: AssetTypeProps;
}

const MediaSwitch: FC<MediaSwitchProps> = ({ asset }) => {
  switch (asset.type) {
    case 'image':
      return <Image {...asset} />;
    case 'video':
      return (
        <AnimatedVideo
          height='100%'
          sources={asset?.sources}
          width='100%'
          sx={asset?.sx}
          isLazy={asset?.isLazy}
        />
      );
    case 'lottie':
      return (
        <Box sx={asset.sx}>
          <Player
            src={asset.src}
            autoplay={true}
            loop={true}
            style={{
              width: '100%',
              height: '100%',
              margin: '0 auto',
              ...asset.inlineStyle,
            }}
          />
        </Box>
      );
    default:
      if (!isProduction) {
        return <p>hmm something went wrong</p>;
      } else {
        return null;
      }
  }
};

export default MediaSwitch;
