import React, { FC } from 'react';

// components
import { RichTextRenderer } from 'src/components/RichTextRenderer';

import { FormSectionDisclaimerProps } from './FormSectionDisclaimer.types';

const FormSectionDisclaimer: FC<FormSectionDisclaimerProps> = ({
  formData,
  cmsProps,
}) => {
  if (cmsProps.formData.disclaimer) {
    if (typeof cmsProps.formData.disclaimer === 'string') {
      return (
        <p
          className='contact-form__disclaimer'
          dangerouslySetInnerHTML={{
            __html: cmsProps.formData.disclaimer,
          }}
        />
      );
    } else {
      return <RichTextRenderer content={cmsProps.formData.disclaimer} />;
    }
  } else {
    return (
      <p
        data-testid='disclaimer'
        className='contact-form__disclaimer'
        dangerouslySetInnerHTML={{
          __html: formData.disclaimer,
        }}
      />
    );
  }
};

export default FormSectionDisclaimer;
