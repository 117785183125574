import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Place a fraud alert',
      'meta-description': 'Place a fraud alert',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Place a fraud alert',
    requestType: 'Place fraud alert',
    introHeader: 'Introduction',
    intro: `<p>Complete this form in order to place a fraud alert on your Plaid Check consumer file. Fraud alert messages notify potential credit grantors to verify your identity before extending credit in your name in case someone is using your information without your consent. This alert will remain on your file for 90 days and automatically expires unless you remove it before the end of the term.</p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
