import _get from 'lodash/get';

export const getFirstElementFields = (components: any) => {
  return components?.find((component) => {
    const id = _get(component, 'sys.contentType.sys.id', '');

    if (id !== 'backgroundContainer') {
      return component;
    }
  });
};
