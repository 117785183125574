import React from 'react';

import { Image } from 'src/components-v2/Media';
import { Button } from 'src/components/Inputs';
import DefaultTemplate from 'src/templates/default';

// helpers
import { Locales } from 'src/lib/constants';
import { dangerous_getStaticData } from 'src/lib/getStaticData';

const img = {
  MOBILE_IMAGE: '?fm=webp&w=304&q=75',
  DESKTOP_IMAGE: '?fm=webp&q=75',
  src:
    'https://images.ctfassets.net/ss5kfr270og3/5hC5qQMmTexlUJiMoqY1pB/258e1ebfbb6a390b79af07d81531456b/scene-contact_2x.png',
};

const ContactThanks = ({ locale = Locales.EN_US }) => {
  const { metaData, heading, subheading, cta } = dangerous_getStaticData({
    locale,
    filePath: 'contact-thanks',
  });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <section className='background-page-wrapper'>
        <div className='grid-container section-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell medium-12 large-5'>
              <h1 className='section-header'>{heading}</h1>
            </div>
          </div>

          <div className='grid-x grid-margin-x contact-thanks-grid'>
            <div className='cell large-5'>
              <h4 className='regular section-subheader'>{subheading}</h4>
              <Button href={cta?.url} className='mb1' secondary fullWidth>
                {cta?.text}
              </Button>
            </div>

            <div className='cell small-7 large-auto'>
              <Image
                isLazy={false}
                className='contact-inline-img'
                src={img.src + img.DESKTOP_IMAGE}
                alt=''
                width='569'
                height='569'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: `${img.src + img.MOBILE_IMAGE}`,
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: `${img.src + img.DESKTOP_IMAGE}`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </DefaultTemplate>
  );
};

export default ContactThanks;
