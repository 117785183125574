import React, { FC } from 'react';
import Footer from 'src/components/PartnerDirectory/PartnerPage/Footer/Footer';

// NOTE: This component is a super thin wrapper for the Partner Directory
// <Footer /> component, since the desired functionality for both is the
// same at present. Later, it may make sense to split them, but it seems
// premature to do so at this time.

const LandingPageFooter: FC = (props) => {
  return <Footer {...props} />;
};

export default LandingPageFooter;
