import React from 'react';
import PropTypes from 'prop-types';

import styles from './Item.module.scss';

const propTypes = {
  industryTitle: PropTypes.string.isRequired,
  integratedSystemsTitle: PropTypes.string.isRequired,
  geographyTitle: PropTypes.string.isRequired,
  industryItems: PropTypes.arrayOf(PropTypes.string),
  integratedSystemsItems: PropTypes.arrayOf(PropTypes.string),
  geographyItems: PropTypes.arrayOf(PropTypes.string),
};

const ItemListSection = ({
  industryTitle,
  industryItems,
  integratedSystemsTitle,
  integratedSystemsItems,
  geographyTitle,
  geographyItems,
}) => {
  return (
    <div className='grid-container'>
      <div className='supporting-text-wrapper'>
        <div className='cell supporting-text-item'>
          <div className='separator' />
          <h5 className={styles.title}>{industryTitle}</h5>
          {industryItems.length === 1 ? (
            // if there is only one item we do not want
            // present it as a bullet point
            industryItems[0]
          ) : (
            <ul>
              {industryItems.map((item, i) => (
                <li key={`item-${i}`}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        <div className='cell supporting-text-item'>
          <div className='separator' />
          <h5 className={styles.title}>{integratedSystemsTitle}</h5>
          <ul>
            {integratedSystemsItems.map((item, i) => (
              <li key={`item-${i}`}>{item}</li>
            ))}
          </ul>
        </div>
        <div className='cell supporting-text-item'>
          <div className='separator' />
          <h5 className={styles.title}>{geographyTitle}</h5>
          <ul>
            {geographyItems.map((item, i) => (
              <li key={`item-${i}`}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ItemListSection.propTypes = propTypes;

export default ItemListSection;
