/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { staticData } from 'src/store/static-data';
import { ButtonTypes } from 'src/lib/constants';
import kebabCase from 'lodash/kebabCase';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for static data content type to the view
 *
 * @param {Object} args
 * @param {string} args.titleText
 * @param {string} args.descriptionText
 * @param {string} args.endpoint
 * @param {Object[]} args.ctaButtons
 * @param {boolean} args.flipped
 * @param {boolean} args.backgroundFirstImage
 * @returns {Object}
 */
export const staticDataAdapter = ({
  titleText,
  descriptionText,
  endpoint,
  ctaButtons,
  flipped,
  backgroundFirstImage,
  hideCtas,
}) => {
  const primaryCta = ctaButtons?.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons?.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );

  const pageClass = kebabCase(endpoint.replace(/UK |EU /g, ''));

  return {
    ...staticData[endpoint],
    'title-text': titleText,
    'description-text': descriptionText,
    'bottom-cta-url': _get(primaryCta, 'fields.url'),
    'bottom-cta-text': _get(primaryCta, 'fields.text'),
    'secondary-cta-url': _get(secondaryCta, 'fields.url'),
    'secondary-cta-text': _get(secondaryCta, 'fields.text'),
    flipped,
    'bg-first-image': backgroundFirstImage,
    pageClass,
    hideCtas,
  };
};
