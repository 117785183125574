import React from 'react';
import PropTypes from 'prop-types';

// helpers
import { AnalyticsProvider } from 'src/contexts';
import { MetaTags } from 'src/components/MetaTags';

import styles from './CoastTemplate.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CoastTemplate = ({ children, title = 'Some Demo', ...props }) => {
  return (
    <AnalyticsProvider>
      <MetaTags {...props} />
      <div id='main-content' className={styles.mainContent}>
        {children}
      </div>
    </AnalyticsProvider>
  );
};
export default CoastTemplate;

CoastTemplate.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};
