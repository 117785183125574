import { toKebabCase, unKebab, convertToSelectOptions } from 'src/lib/utils';
import { marketingCmsClient } from 'src/lib/contentful-client';
import {
  CareersUrlSlugs,
  ContentfulTemplates,
  JOB_DATA,
  JOB_LOCATIONS,
} from 'src/lib/constants';
import { Entry, EntryCollection } from 'contentful';
import { TemplateShape, Component as ComponentProps } from 'src/lib/ts-types';
import { Option as OptionProps } from 'src/components/Inputs/Select/Select';
import Lever from 'src/lib/lever-client';

interface JobPathParams {
  jobDepartments?: string[];
  jobLocations?: string[];
}

interface JobPath {
  params: {
    department?: string;
    location?: string;
  };
}

interface JobPaths {
  paths: JobPath[];
  fallback: boolean;
}

// Same functionality as getStaticPaths() but tailored to DRY up job listings.
// Takes in arrays of job locations and job departments and returns the same
// shape as getStaticPaths.
const generateJobsPaths = ({
  pathParams: { jobDepartments, jobLocations },
}: {
  pathParams: JobPathParams;
}): JobPaths => {
  const makePaths = ({ arr, key }) =>
    arr.map((entity) => ({ params: { [key]: toKebabCase(entity) } }));

  const departmentAndLocationPaths = [];
  let jobDepartmentPaths = [];
  let jobLocationPaths = [];

  // If the current rou
  if (jobDepartments?.length && jobLocations?.length) {
    while (jobDepartments.length) {
      const department = jobDepartments.shift();
      jobLocations.forEach((location) => {
        departmentAndLocationPaths.push({
          params: {
            department: toKebabCase(department),
            location: toKebabCase(location),
          },
        });
      });
    }
  } else if (jobDepartments?.length) {
    jobDepartmentPaths = makePaths({
      arr: jobDepartments,
      key: 'department',
    });
  } else if (jobLocations?.length) {
    jobLocationPaths = makePaths({ arr: jobLocations, key: 'location' });
  }

  const paths = [].concat(
    departmentAndLocationPaths,
    jobDepartmentPaths,
    jobLocationPaths,
  );

  return {
    paths,
    fallback: false,
  };
};

const unKebabLocation = (str: string): string => {
  if (!str) {
    return null;
  }
  return unKebab(str);
};

const generateJobsUrl = ({
  dynamicSegments = [],
  department = null,
  location = null,
}): string => {
  let jobFetchUrl = 'https://api.lever.co/v0/postings/plaid?mode=json';

  if (
    dynamicSegments.includes('department') &&
    department !== CareersUrlSlugs.ALL_DEPARTMENTS
  ) {
    const jobDepartments = Object.keys(JOB_DATA);
    const departmentLabel = jobDepartments.find(
      (jobDepartment) => department === toKebabCase(jobDepartment),
    );
    jobFetchUrl += `&team=${encodeURIComponent(departmentLabel)}`;
  }

  if (
    dynamicSegments.includes('location') &&
    location !== CareersUrlSlugs.ALL_LOCATIONS
  ) {
    const multiLocationRegex = new RegExp(unKebabLocation(location));
    const locationPermutations = JOB_LOCATIONS.filter((location) =>
      location.match(multiLocationRegex),
    );
    const locationString = locationPermutations
      .map((match) => `&location=${match}`)
      .join('');

    jobFetchUrl += locationString;
  }

  return jobFetchUrl;
};

const fetchJobs = async ({ jobsUrl }) => {
  try {
    const jobData = await Lever.api.getJobs(jobsUrl);
    const jobCount = jobData.length;

    const jobsByDepartment = {};

    jobData.forEach((job) => {
      const jobDepartment = job.categories.team;
      const jobDepartmentExists = !!jobsByDepartment[jobDepartment];
      if (jobDepartmentExists) {
        jobsByDepartment[jobDepartment].jobs = [
          ...jobsByDepartment[jobDepartment].jobs,
          job,
        ];
      } else {
        jobsByDepartment[jobDepartment] = { jobs: [job] };
      }
    });
    return { jobsByDepartment, jobCount };
  } catch {
    return false;
  }
};

const makeMetaTitle = ({ department, location, careersPage }) => {
  const isAllDepartments = department === CareersUrlSlugs.ALL_DEPARTMENTS;
  const isAllLocations = location === CareersUrlSlugs.ALL_LOCATIONS;
  const isRemote = location === CareersUrlSlugs.REMOTE;

  // If we're on the root Careers page
  if (department === undefined && location === undefined) {
    return `${careersPage?.fields?.metaTitle} | Plaid Careers`;
  }
  return `${
    isAllDepartments
      ? isRemote
        ? 'All Remote '
        : 'All '
      : isRemote
      ? `Remote ${unKebab(department)} `
      : `${unKebab(department)} `
  }Roles${
    isAllLocations || isRemote ? '' : ` in ${unKebabLocation(location)}`
  } | Plaid Careers`;
};

const makeMetaDescription = ({ department, location, careersPage, params }) => {
  // If we're on the root Careers page
  if (department === undefined && location === undefined) {
    return `${careersPage?.fields?.metaDescription}`;
  }
  return `At Plaid, our mission is to unlock financial freedom for everyone. We’re looking for creative, collaborative ${
    department === CareersUrlSlugs.ALL_DEPARTMENTS
      ? 'entrepreneurs'
      : `${unKebab(department)} experts`
  } to join our team${params.location === 'remote' ? ' remotely' : ''}${
    [CareersUrlSlugs.ALL_LOCATIONS, 'remote'].includes(params.location)
      ? '.'
      : ` in ${unKebabLocation(params.location)}.`
  }`;
};
interface JobsDataParams {
  location?: string;
  department?: string;
}

interface IndividualJobProps {
  categories: {
    location: string;
    team: string;
  };
}

export interface JobPropsParams {
  props: {
    fields: {
      components: ComponentProps[] | string[];
      jobs: {
        [key: string]: {
          jobs: IndividualJobProps[];
        };
      };
      metaTitle: string;
      jobCount: number;
      jobLocations: OptionProps[];
      jobDepartments: OptionProps[];
      location: string | null;
      department: string | null;
    };
    preview: boolean;
  };
}

interface JobPropsError {
  props: {
    fields: {
      hasError: boolean;
    };
  };
}

export type JobPropsReturnType = JobPropsParams | JobPropsError;

const generateJobsProps = async ({
  params = {},
  dynamicSegments,
  components = [],
  preview,
}: {
  params?: JobsDataParams;
  dynamicSegments: string[];
  components?: string[];
  preview: boolean;
}): Promise<JobPropsReturnType> => {
  const { department, location } = params;

  const jobDepartments = Object.keys(JOB_DATA);
  const jobLocations = JOB_LOCATIONS;

  const jobsUrl = generateJobsUrl({ dynamicSegments, department, location });
  const jobsData = await fetchJobs({ jobsUrl });
  if (!jobsData) {
    return {
      props: {
        fields: {
          hasError: true,
        },
      },
    };
  }

  const jobsByDepartment = jobsData.jobsByDepartment;
  const jobCount = jobsData.jobCount;

  const departmentOptions = convertToSelectOptions({
    items: ['All departments', ...jobDepartments],
  });
  const locationOptions = convertToSelectOptions({
    items: ['All locations', ...jobLocations],
  });

  const sortedJobs = Object.keys(jobsByDepartment)
    .sort()
    .reduce((obj, key) => {
      obj[key] = jobsByDepartment[key];
      return obj;
    }, {});

  let includedComponents = [];

  const entries: EntryCollection<TemplateShape> = await marketingCmsClient.getEntries(
    {
      content_type: ContentfulTemplates.CAREERS_PAGE,
      include: 10,
      'fields.pageSlug': 'careers',
    },
  );
  const careersPage: Entry<TemplateShape> = entries.items[0];
  if (careersPage) {
    if (components.length > 0) {
      includedComponents = careersPage.fields?.components?.filter(
        (component: ComponentProps) =>
          components.includes(component.sys?.contentType?.sys?.id),
      );
    } else {
      includedComponents = careersPage.fields?.components;
    }
  }

  const metaTitle = makeMetaTitle({
    department: params.department,
    location: params.location,
    careersPage,
  });

  const metaDescription = makeMetaDescription({
    department: params.department,
    location: params.location,
    careersPage,
    params,
  });

  const fields = {
    ...careersPage?.fields,
    components: includedComponents,
    jobs: sortedJobs,
    metaTitle,
    metaDescription,
    jobCount,
    jobLocations: locationOptions,
    jobDepartments: departmentOptions,
    location: null,
    department: null,
  };

  if (params.location) {
    fields.location = params.location;
  }
  if (params.department) {
    fields.department = params.department;
  }

  return {
    props: {
      fields,
      preview: !!preview,
    },
  };
};

export { generateJobsPaths, generateJobsProps, unKebabLocation };
