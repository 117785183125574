import { FC } from 'react';

import { Stack } from 'src/components-v2/Layout';

import { Card } from '../Card';
import { isCardWImage, CardWIcon, CardWImage } from '../types';

type ListProps = {
  className?: string;
  items: Array<CardWIcon | CardWImage>;
};

export const List: FC<ListProps> = ({ className, items }) => {
  const isImagesGrid = items.some((child) => {
    return isCardWImage(child);
  });

  return (
    <Stack
      className={className}
      component='ul'
      gap={{ xs: isImagesGrid ? '32px' : '8px', md: 0 }}
      sx={{ listStyle: 'none', margin: 0, p: 0 }}
    >
      {items.map((item, i) => {
        return (
          <li key={`main-menu-list-item-${i}-${item.href}`}>
            <Card {...item} />
          </li>
        );
      })}
    </Stack>
  );
};
