const {
  bankingAndWealth,
  businessFinances,
  iGaming,
  lending,
  payments,
  personalFinances,
  propertyManagement,
} = {
  bankingAndWealth: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Banking & wealth',
    url: '/en-eu/use-cases/banking-and-wealth',
    icon: '/assets/img/navbar/icon-wealth.png',
  },
  businessFinances: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Business finances',
    url: '/en-eu/use-cases/business-finances',
    icon: '/assets/img/navbar/icon-business-finance.png',
  },
  iGaming: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'iGaming',
    url: '/en-eu/use-cases/igaming',
    icon: '/assets/img/navbar/icon-igaming.png',
  },
  lending: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Lending',
    url: '/en-eu/use-cases/lending',
    icon: '/assets/img/navbar/icon-lending-services.png',
  },
  payments: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Payments',
    url: '/en-eu/use-cases/payments',
    icon: '/assets/img/navbar/icon-consumer-payments.png',
  },
  personalFinances: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Personal finances',
    url: '/en-eu/use-cases/personal-finances',
    icon: '/assets/img/navbar/icon-personal-finance.png',
  },
  propertyManagement: {
    class: 'Navbar__mega-menu-item-title--is-use-case',
    name: 'Property management',
    url: '/en-eu/plaid-for-property-management',
    icon: '/assets/img/navbar/icon-property-management.png',
  },
};

const columns = [
  {
    gridClasses: 'cell small-4',
    items: [personalFinances, businessFinances, payments],
  },
  {
    gridClasses: 'cell small-4',
    items: [lending, bankingAndWealth, iGaming],
  },
  {
    gridClasses: 'cell small-4',
    items: [propertyManagement],
  },
];

const useCases = {
  label: 'Use cases',
  megaMenuClass: 'use-cases',
  submenuClass: 'cell small-9',
  subtitle: 'Learn how you can make the most of our products',
  menuLinks: [
    {
      name: 'Customer stories',
      url: '/customer-stories',
    },
  ],
  submenu: {
    columns,
  },
};

export default useCases;
