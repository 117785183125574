import React from 'react';

const FacebookIcon = () => {
  return (
    <svg height={25} width={24} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.718 11.662l-.08.963h-1.813v6.127h-2.452v-6.127H9.137v-2.452h1.222V9.07c0-1.01.472-2.565 2.578-2.565h1.894v2.1h-1.375c-.226 0-.539.113-.539.585v.983h1.947zm5.768-7.522A11.956 11.956 0 0012 .625 11.954 11.954 0 003.516 4.14 11.954 11.954 0 000 12.625c0 3.316 1.343 6.312 3.516 8.485A11.956 11.956 0 0012 24.625c3.316 0 6.313-1.342 8.486-3.515A11.957 11.957 0 0024 12.625c0-3.316-1.342-6.312-3.514-8.485z'
        fill='#9b9b9b'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default FacebookIcon;
