export const metaData = {
  metaTitle: 'Prosper',
  metaDescription:
    'Prosper needed a reliable way to onboard new users. With Plaid, Prosper’s customers successfully connect their bank accounts about 80% of the time.',
};

export const pageHeaderData = {
  classes:
    'background background--color-pink200 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-yellow-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  label: true,
  'label-text': 'Customer story: Prosper',
  header: 'Helping people invest in each other',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/prosper-case-study.pdf',
  video: false,
  image: true,
  'image-width': 669,
  'image-height': 389,
  'image-url': '/assets/img/customer-stories/header-hero-prosper@2x.png',
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-prosper@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Onboard new users,<br />underwrite loans',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Lending',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'A vendor Prosper had been using to connect customer bank accounts began to experience frequent outages.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Prosper’s customers successfully connect their bank accounts about 80% of the time, an industry-leading rate.',
};

export const content1Data = {
  heading:
    'As the first peer-to-peer lending marketplace in the United States, Prosper’s mission is to advance financial wellbeing by giving borrowers access to affordable credit—while giving investors the opportunity to invest in the consumer credit asset class.',
  paragraph: [
    'A seamless onboarding process is critical to Prosper’s success. So when a vendor they had been using to connect customer bank accounts began to experience frequent outages, they knew they needed a solution.',
    "To streamline the process of connecting customer bank accounts, Prosper launched a pilot program with Plaid. The resulting partnership has exceeded every one of their performance benchmarks. With Plaid, Prosper's customers successfully connect their bank accounts about 80% of the time, an industry-leading rate.",
  ],
};

export const infographicData = {
  classes: 'background background--color-pink200',
  legendLabel: 'Plaid',
  legendLabelSecondary: 'Initial vendor',
  graphs: [
    {
      heading: 'Conversion<br />(signups)',
      percentage1: 75,
      percentage2: 60,
    },
    {
      heading: 'Bank account<br />authentication data quality',
      percentage1: 99,
      percentage2: 85,
    },
    {
      heading: 'Identity verification<br />data quality',
      percentage1: 99,
      percentage2: 85,
    },
  ],
  graphTitle: 'Transaction data',
  metrics: [
    {
      heading: '>95%',
      description: 'Over 95% of selected accounts had 5 or more transactions',
      mobileDescription: 'Data quality',
    },
    {
      heading: '>95%',
      description:
        'Plaid returned 90 days of transactions in <10 minutes for over 95% of accounts',
      mobileDescription: 'Data response time',
    },
  ],
};

export const content2Data = {
  title: 'No time for downtime',
  paragraph: [
    'Since Prosper’s founding in 2005, the company has facilitated more than $14 billion in fixed-term, fixed-rate personal loans through its platform to nearly 900,000 people. Its customers are looking for an alternative to credit cards as they seek to consolidate debt or pay for large purchases like home improvements, medical treatments, or weddings.',
    'Prosper’s marketplace model is simple: connect good-credit borrowers with individual and institutional investors. In return, investors can expect solid returns and feel good about knowing that they have helped someone achieve their goals.',
    'For most of the company’s history, Prosper relied on just one vendor to process bank account verification using bank account connection requests. That vendor often experienced outages, and its data quality was inconsistent.',
    '“When our vendor went down, it meant that we needed to manually verify the bank account ownership by asking for additional documents from our customers” said Siva Shanmugam, Prosper’s director of verification and fraud strategy. “We did not have an alternative to fall back on, and we needed to change that.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-large-top-right':
    '/assets/img/accents/diagram-brushstroke-pink-1@2x.png',
  'stroke-large-bottom-left':
    '/assets/img/accents/diagram-brushstroke-pink-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Prosper’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Prosper can access the data they need to authenticate accounts and onboard new users.',
    },
  ],
  'icon-row-1': [
    {
      label: 'LOAN REQUEST',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-request-pink@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-front-end-pink@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'PLAID BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-pink@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'LOAN DECISION',
      'label-delay': 1500,
      'icon-delay': 1200,
      img: '/assets/img/customer-stories/diagram-step-piggy-bank-pink@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Hit every mark',
    paragraph: [
      'The Prosper-Plaid pilot program started in 2017 with the goal of testing the feasibility of integrating with Plaid’s Auth, Identity, and Transaction products.',
      'Over a three-month period, Prosper gave a randomized set of its users the option to connect their bank accounts using Plaid’s Link interface. Simultaneously, they collected performance metrics like conversion rate, response time, and data quality. Compared with what had come before, the Plaid results showed a significant improvement.',
      '“Plaid consistently outperformed our previous solution on the top banks,” Shanmugam reported. “Over the course of the Plaid pilot program, we met or exceeded every performance benchmark.”',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-prosper@2x.png',
      alt:
        'Headshot of Siva Shanmugam, Director of Verification and Fraud Strategy at Prosper',
    },
    quote: [
      {
        copy:
          'Previously, we only had access to three months of transaction history. Plaid provides up to two years.',
        source:
          'Siva Shanmugam, Director of Verification and Fraud Strategy, Prosper',
      },
    ],
  },
  {
    title: 'Know your customer',
    paragraph: [
      'As a result of its successful pilot, Prosper converted to a long-term Plaid customer. Beyond uptime, Shanmugam says that Plaid’s superior data quality has helped Prosper improve the simplicity and speed of the verification process.',
      '“Plaid has direct APIs with the top financial institutions. That makes it more robust, not just in terms of response time, but also the quality and depth of data it provides. To put things in perspective, Prosper previously only had access to three months of transaction history. Plaid provides up to two years.”',
      'That data provides a better understanding of a borrower’s cash flow, as well as variations in income over time. This in turn helps Prosper make better verification decisions for loan applications.',
      '“Is the borrower within an acceptable risk tolerance level? Is an auto-verification appropriate, meaning we don’t require additional documents like pay stubs or W2s? Those are the questions more confidently answered with the extended transaction history Plaid provides,” said Shanmugam.',
    ],
  },
  {
    title: 'Grow together',
    paragraph: [
      'In 2019, Prosper plans to launch home equity lines of credit — a space that Shanmugam says is “ripe for disruption from a customer experience perspective.” Prosper will also explore leveraging Plaid in connection to these products, which, Shanmugam says, continues to enable Prosper to deliver an exceptional experience to borrowers and investors alike:',
      '<strong>“Better data, better response times, fewer outages—these were things that we were able to achieve with Plaid.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'SoLo',
      goals: ['Connect user bank accounts', 'Underwrite loans'],
      cardImage: {
        src: '/assets/img/logos/card-company-logo-solo@2x.png',
        alt: 'SoLo - read full story',
      },
      excerpt: 'An ethical alternative to payday loans',
      url: '/customer-stories/solo',
    },
    {
      name: 'Carvana',
      goals: ['Enable ACH payments', 'Prevent NSF/overdraft fees'],
      cardImage: {
        src: '/assets/img/logos/card-company-logo-carvana@2x.png',
        alt: 'Carvana - read full story',
      },
      excerpt: 'Accelerating the future of auto payments',
      url: '/customer-stories/carvana',
    },
    {
      name: 'Qapital',
      goals: ['Connect user bank accounts', 'Prevent NSF/overdraft fees'],
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/prosper-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['infographicData', infographicData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
