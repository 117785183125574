export const metaData = {
  'meta-title': 'Discover apps that are powered by Plaid',
  'meta-description':
    'When you connect your financial accounts, you can do some pretty amazing things. Discover more than 7,000 apps and services that are powered by Plaid.',
  'exclude-footer': 'true',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-5 large-5',
  label: false,
  'label-text': null,
  header: 'Discover a world of financial apps',
  'header-class': 'h2',
  subheader:
    'When you connect your financial accounts to an app, you can do some pretty amazing things. Meet some of the apps that are powered by Plaid.',
  image: true,
  'background-image': '/assets/img/accents/discover-apps-brushstroke-hero.png',
  'image-url': '/assets/img/hero/discover-apps-hero.png',
};

export const appsData = [
  {
    classes: 'discover-apps__mobile-banking',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Banking',
    title: 'Mobile banking',
    description:
      'Think beyond the branch. With the latest generation of banking apps, you can manage nearly every aspect of your finances from your connected device.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-01.png',
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-moneylion.png',
        'alt-text': 'MoneyLion',
        name: 'MoneyLion',
        description:
          'Easy way to borrow, save, invest, and earn. All in one app.',
        url: 'https://www.moneylion.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-varo.png',
        'alt-text': 'Varo',
        name: 'Varo',
        description: 'Online bank accounts with no minimum balance.',
        url: 'https://www.varomoney.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-chime.png',
        'alt-text': 'Chime',
        name: 'Chime',
        description: 'Grow your savings automatically, like a boss.',
        url: 'https://www.chimebank.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-m1finance.png',
        'alt-text': 'M1 Finance',
        name: 'M1 Finance',
        description: 'Invest, borrow, and spend seamlessly, all in one place.',
        url: 'https://www.m1finance.com/',
      },
    ],
    reverse: true,
  },
  {
    classes: 'discover-apps__saving-investing',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Wealth',
    title: 'Saving and investing',
    description:
      'Make smart money decisions. Instantly create a custom portfolio tailored to your financial goals—or round up your credit card purchases and save the spare change for a rainy day.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-02.png',
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-acorns.png',
        'alt-text': 'Acorns',
        name: 'Acorns',
        description: 'Invest spare change and save for retirement.',
        url: 'https://www.acorns.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-oportun.png',
        'alt-text': 'Oportun',
        name: 'Oportun',
        description:
          'Save money, pay off debt, and achieve your financial goals.',
        url: 'https://oportun.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-ellevest.png',
        'alt-text': 'Ellevest',
        name: 'Ellevest',
        description: 'We’re on a mission to close the gender money gap.',
        url: 'https://www.ellevest.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-qapital.png',
        'alt-text': 'Qapital',
        name: 'Qapital',
        description: 'Save smartly, spend happily, and invest comfortably.',
        url: 'https://www.qapital.com/',
      },
    ],
  },
  {
    classes: 'discover-apps__personal-finances',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Personal Finances',
    title: 'Build financial health',
    description:
      'The latest financial health tools can help you instantly build a budget based on your recent transactions or get notified when you’re in danger of overdrawing your account.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-03.png',
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-ynab.png',
        'alt-text': 'YNAB',
        name: 'YNAB',
        description: 'Stop living paycheck-to-paycheck. You need a budget.',
        url: 'https://www.youneedabudget.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-rocketmoney.jpg',
        'alt-text': 'Rocket Money',
        name: 'Rocket Money',
        description:
          'Find and cancel subscriptions. Track your spending. Take control.',
        url: 'https://www.rocketmoney.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-albert.png',
        'alt-text': 'Albert',
        name: 'Albert',
        description: 'Make good money decisions with confidence.',
        url: 'https://albert.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-copilot.png',
        'alt-text': 'Copilot',
        name: 'Copilot',
        description: 'Do money better with Copilot.',
        url: 'https://copilot.money/',
      },
    ],
    reverse: true,
  },
  {
    classes: 'discover-apps__business-finances',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Business Finances',
    title: 'Boost your business',
    description:
      'Modern tools allow you to automate things like payments, fraud prevention, payroll, taxes, and employee expenses—so you can focus on your strategic priorities.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-04.png',
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/wave-app-icon.png',
        'alt-text': 'Wave',
        name: 'Wave',
        description: 'One-stop money management for small business owners.',
        url: 'https://www.waveapps.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-bench.png',
        'alt-text': 'Bench',
        name: 'Bench',
        description: 'Online bookkeeping services for your small business.',
        url: 'https://bench.co/partner/friends-of-bench/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-expensify.png',
        'alt-text': 'Expensify',
        name: 'Expensify',
        description: 'Receipt tracking and expense management for everyone.',
        url: 'https://use.expensify.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-fundbox.png',
        'alt-text': 'Fundbox',
        name: 'Fundbox',
        description:
          'Revolving lines of credit to growing small businesses like yours.',
        url: 'https://fundbox.com/',
      },
    ],
  },
  {
    classes: 'discover-apps__payment',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Payments',
    title: 'Pay friends and family',
    description:
      'With modern payment apps, instantly send cash to friends and family.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-05.png',
    'brushstroke-shrink-to-fit': true,
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-venmo.png',
        'alt-text': 'Venmo',
        name: 'Venmo',
        description:
          'A digital wallet that lets you make and share payments with friends.',
        url: 'https://venmo.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-wise.png',
        'alt-text': 'Wise',
        name: 'Wise',
        description: 'Send money abroad quickly and easily.',
        url: 'https://wise.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-metal.png',
        'alt-text': 'Metal',
        name: 'Metal',
        description:
          'Send cash and crypto to friends. Get rewarded for doing it.',
        url: 'https://www.metalpay.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-worldremit.png',
        'alt-text': 'WorldRemit',
        name: 'WorldRemit',
        description:
          'Transfer money to friends and family overseas in over 150 countries.',
        url: 'https://www.worldremit.com/',
      },
    ],
    reverse: true,
  },
  {
    classes: 'discover-apps__lending',
    type: 'icon-grid',
    'extra-padding': false,
    label: 'Lending',
    title: 'Apply for a loan',
    description:
      'New online lending services help you save time and get the best rates by allowing you to supplement your loan application with data from your financial accounts.',
    'bg-color': 'white',
    'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-06.png',
    'icon-grid': [
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-sofi.png',
        'alt-text': 'SoFi',
        name: 'SoFi',
        description: 'It’s time to take control of your finances.',
        url: 'https://www.sofi.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-figure.png',
        'alt-text': 'Figure',
        name: 'Figure',
        description: 'Financial products to simplify your finances, faster.',
        url: 'https://www.figure.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-petal.png',
        'alt-text': 'Petal',
        name: 'Petal',
        description: 'Responsible credit for the modern world.',
        url: 'https://www.petalcard.com/',
      },
      {
        'img-src': '/assets/img/logos/app-icons/app-icon-avant.png',
        'alt-text': 'Avant',
        name: 'Avant',
        description: 'Manage your loan or credit card wherever you are.',
        url: 'https://www.avant.com/',
      },
    ],
  },
];

export const footerData = {
  'cta-title': 'Want to see your app here?',
  'cta-title-wide': true,
  'cta-subtitle':
    "Build with us. Tell us how we can help and we'll get in touch shortly.",
  'primary-cta-text': 'Contact Us',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'bg-color': 'bg-white',
  'hide-secondary-cta': true,
};

export const textBlockData = {
  'p-classes': 'disclaimer',
  content:
    'The inclusion of certain applications on this page does not constitute or imply an endorsement of the application by Plaid.',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'RESOURCES',
  'header-text': 'Take the next step',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/how-it-works.png',
      'feature-title': 'How Plaid works',
      'feature-body': 'Plaid powers the apps in your financial life.',
      'feature-url': '/how-it-works-for-consumers',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/manage-connections.png',
      'feature-title': 'Manage connections',
      'feature-body':
        'See the types of data you’ve shared and manage your connections.',
      'feature-url': '//my.plaid.com', // TODO: Get real URL
      'feature-link-text': 'Learn more',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/why-is-plaid-involved.png',
      'feature-title': 'Why is Plaid involved?',
      'feature-body':
        'Connecting your financial accounts just got a whole lot easier.',
      'feature-url': '/why-is-plaid-involved',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
  ],
};
