export const metaData = {
  metaTitle: 'Astra',
  metaDescription:
    'Astra makes personal finance more intelligent and empowering. With Plaid, their customers can link credit cards and bank accounts in a matter of seconds.',
};

export const pageHeaderData = {
  classes:
    'background background--color-blue400 background--size-contain background--position-bottom-right',
  'background-image':
    '/assets/img/backgrounds/stroke-multi-green-blue-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-text': 'Customer story: Astra',
  header: 'Helping people invest in each other',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Astra-Case-Study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-astra@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-astra@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Connect user bank accounts,<br />get transactions history',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Personal financial management',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Astra needed a way to import users’ balance and transaction data.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'Today, Astra uses Plaid to collect and display users’ spending data in real time. With the Plaid + Dwolla integration, they can seamlessly execute ACH payments.',
};

export const content1Data = {
  heading:
    'When Gil Akos and Sam Morgan set out in 2016 to develop prototypes of Astra—a smart personal finance tool for the overbanked, goal-oriented professional—they quickly realized that their product wasn’t going to scale without the insight that comes with personalized data.',
  paragraph: [
    'Everyone’s financial situation, they reasoned, is too idiosyncratic to base a platform on generalizations and assumptions.',
    'To make managing financial goals easier and more efficient, they needed a way to import user balance and transaction information and enable intra-app money transfers seamlessly and securely. For that, they turned to Plaid.',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-blue200 supporting-text--constrained',
  'two-up': true,
  stat: true,
  stat2: '3 days',
  text2: 'It only took Astra three days to get Plaid up and running.',
  stat3: '350 accts',
  text3: 'In just eight weeks, users connected 350 bank accounts.',
};

export const content2Data = [
  {
    title: 'Customized money management',
    paragraph: [
      'Astra’s mission is to make personal finance more intelligent, empowering, and personal.',
      'To that end, Astra recently launched a flexible, customizable iOS app that leverages user-defined rules and automation to save people time and reduce stress in the face of hairy financial challenges—like paying down debt while saving for vacation.',
      'Astra puts at its users’ fingertips big-picture information about where their money is going, giving them total control of what’s happening across all of their goals and accounts. Users simply connect any and all of their banking and credit accounts with Astra to quickly view their financial situation in one easy-to-access and -navigate digital space.',
      'From there, users input savings goals and create rules, called “actions,” to automate the movement of their money from account to account to proactively fund said goals.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-astra@2x.png',
      alt: 'Headshot of Gil Akos, Co-founder and CEO of Astra',
    },
    quote: [
      {
        copy:
          'Our customers say it’s super-fast and really easy to connect their external accounts. They trust the interface.',
        source: 'Gil Akos, Co-founder and CEO, Astra',
      },
    ],
  },
  {
    title: 'Robust and developer-friendly',
    paragraph: [
      'To get Astra to a place of intrinsic utility, Akos and Morgan needed to find an API that allowed for secure account integration and could act as a switching station as users’ money moved between accounts based on their defined actions. From the beginning, they found their solution in Plaid.',
      '“Plaid was something we were experimenting with when we converted a simple version of our early Excel models into a web app,” Akos said. “It was foundational to what we were doing even before we had a proper product in place. Nothing else was as robust or developer friendly.”',
      'Today, the Astra iOS app uses Plaid products Auth, Balance, and Transactions, plus the Plaid Link + Dwolla integration to collect and display users’ spending data in real time and authorize and execute ACH transfers between financial institutions. From the users’ perspective, the process is painless.',
      '“Any step in the process that can be low-friction is hugely important for us to convert customers to the point where they’re actually seeing the true value of what we’re offering,” said Morgan. “So, when our customers say it’s super-fast and really easy to connect their external accounts and that they trust the interface, that makes our integration with Plaid key.”',
    ],
  },
];

export const content3Data = [
  {
    title: 'Three days to integrate',
    paragraph: [
      'Astra customers aren’t the only beneficiaries of Plaid’s user-friendly API. Astra itself advantages from Plaid’s low barrier to entry and exceptional customer service.',
      'In addition to offering developer documentation that Akos describes as legible, clean, and easily consumed, Plaid has a human side that he considers equally compelling.',
      '“It only took three days to get Plaid up and running. But beyond that, a fantastic partnership has emerged,” he said. “There is engaging communication between myself, my business point of contact, the technical team, and from the user feedback team. They offer way more than just a great product.”',
      'The partnership alone was enough to convince Akos that Dwolla was the right payments network provider for Astra—a decision the company made because of the trusted relationship between the Dwolla Platform and Plaid products.',
      '“We were so happy with Plaid, and it already offered the Dwolla integration,” he explained. “We didn’t want to take on a relationship with a really big institution and just be small fry to them. With Plaid, we’re a priority.”',
      'Akos contends that if it weren’t for the technology offered by Plaid, his team would still be at square one. Instead, after just eight weeks live, the iOS app has connected 350 accounts and facilitated 200 money transfers valued at $20,000 via its Plaid interface.',
    ],
  },
  {
    quote: [
      {
        copy:
          'We didn’t want to take on a relationship with a really big institution and just be small fry to them. With Plaid, we’re a priority.',
        source: 'Gil Akos, Co-founder and CEO, Astra',
      },
    ],
  },
  {
    title: 'Intelligent, empowering, personal',
    paragraph: [
      'Even in its early stages, Morgan calls that volume significant.',
      '“Our mission is to help people save. When you consider that 46 percent of Americans can’t afford a $400 expense, the fact that we’re already getting our users, on average, toward $600 saved annually is substantial. Hopefully, by giving them the ability to transfer money more easily, we’re also helping mitigate the $320 in average annual overdraft fees that users pay. I think Plaid is foundational to those achievements.”',
      'Plaid also affords Akos and Morgan the peace of mind to intensely focus on the high-level aspects of Astra’s technology that link back to its mission—namely, the introduction of learning-based balance forecast functionality.',
      'As the app matures, Astra will rely on proprietary forecasting models to offer incremental optimization of users’ savings rules in accordance with their financial circumstances at any given moment. In effect, Astra stands to offer one of the most personalized money management apps on the market—something Morgan attributes, at least in part, to its Plaid partnership.',
      '<strong>“We couldn’t be focusing on these advanced features at all if we didn’t have the core technology in place.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Brigit',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-brigit@2x.png',
        alt: 'Brigit - read full story',
      },
      excerpt: 'A responsible way to bridge through money shocks',
      url: '/customer-stories/brigit',
    },
    {
      name: 'Varo',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-varo@2x.png',
        alt: 'Varo - read full story',
      },
      excerpt: 'A bank that builds financial health',
      url: '/customer-stories/varo',
    },
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-blue-1@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-blue-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Astra’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Astra’s users can begin transferring money between bank accounts.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Astra-Case-Study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
