import React from 'react';
import { Btn } from 'src/components-v2/Inputs';
import { Link } from 'src/components/Inputs';
import { INLINES } from '@contentful/rich-text-types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import _get from 'lodash/get';

const MAIN_ADDRESS = 'plaid.com';

const Hyperlink = ({ hyperlink }, sys) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  return {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const newUrl = node.data.target.fields.url;
      const oldUrl = node.data.target.fields.pageSlug;

      const uri = newUrl ? newUrl.join('/') : oldUrl;

      return (
        <Btn
          {...inspectorProps({
            fieldId: hyperlink?.fieldId,
          })}
          variant='quinary'
          href={`/${uri}`}
        >
          {children}
        </Btn>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = _get(node, 'data.uri', '');
      return (
        <Link href={uri}>
          <a target={uri.includes(MAIN_ADDRESS) ? null : '_blank'}>
            {children}
          </a>
        </Link>
      );
    },
  };
};

export default Hyperlink;
