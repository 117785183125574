import React, { FC } from 'react';

// components
import { RichTextRenderer } from 'src/components/RichTextRenderer';

import { FormSectionRichDescriptionProps } from './FormSectionRichDescription.types';

const FormSectionRichDescription: FC<FormSectionRichDescriptionProps> = ({
  body,
  showDescription = true,
}) => {
  if (showDescription) {
    if (typeof body === 'string') {
      return <p dangerouslySetInnerHTML={{ __html: body }} />;
    }
    if (typeof body === 'object') {
      return <RichTextRenderer content={body} />;
    }
  }
  return null;
};

export default FormSectionRichDescription;
