export const metaData = {
  'meta-title': 'Personal Finances: Get Started - Plaid',
  'meta-description':
    'Learn how Plaid&apos;s suite of products can take your app to the next level',
  hasCanonicalTag: true,
};

export const pageHeaderData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'The future of personal finances starts here',
  'header-size': 'h2',
  'header-image-vert-center': true,
  'img-class': 'is-prominent',
  'header-image':
    '/assets/img/use-cases/personal-finance-campaign-header-no-padding@2x.png',
  body:
    "Learn how Plaid's suite of products can take your app to the next level",
  formData: {
    'marketo-key': 1252,
    includeCompany: true,
    includePhone: true,
    actionText: 'Learn More',
  },
};

export const socialProofData = {
  'is-light': true,
  header: 'Personal Finance Apps Powered By Plaid',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-dave-black600.png',
      alt: 'Dave',
    },
    {
      img: '/assets/img/logos/logo-drop-black600.png',
      alt: 'Drop',
    },
    {
      img: '/assets/img/logos/logo-qapital-black600.png',
      alt: 'Qapital',
    },
    {
      img: '/assets/img/logos/logo-trim-black600.png',
      alt: 'Trim',
    },
  ],
};

export const introSectionData = {
  'remove-top-padding': true,
  'custom-titles': true,
  'problem-title': 'The modern world',
  'problem-statement':
    'The modern consumer has an overwhelming number of financial accounts: checkings, savings, credit cards, brokerage, retirement, HSAs, student loans, and more...',
  'solution-title': 'Simplified by Plaid',
  'solution-statement':
    'Plaid gives you access to all of these accounts and more. We take care of the data so you can focus on building innovative solutions and products that improve the way that consumers live their financial lives.',
};

export const supportingText = {
  'three-up': true,
  header1: 'Categorized transactions',
  text1:
    'Clean and categorized transaction data is the key to understanding spending habits and trends',
  header2: 'Account balances',
  text2: 'Verify real-time account balances, and enable in-app balance checks',
  header3: 'Investments and liabilities',
  text3:
    'For a complete financial picture, surface insights from investments, student loans, and credit card accounts',
};

export const quoteData = {
  quote:
    'Because of Plaid, we were able to scale quickly without hiring additional engineers, saving us an immense amount of costs and time.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/icon-logo-drop@2x.png',
  'logo-alt': 'Drop logo',
  cite: 'Daniel Whiffing, Technical Lead at Drop',
  'quote-link': '/customer-stories/drop',
  'quote-link-text': 'See the full customer story',
};
