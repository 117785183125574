import React, { FC } from 'react';

import { TileGroup as TileGroupProps } from './TileGroup.types';
import styles from './TileGroup.module.scss';
import { Grid, Column } from 'src/components/Layout/Grid';

const TileGroup: FC<TileGroupProps> = ({
  label,
  title,
  subtitle,
  children,
  useThreadsGrid = false,
}) => (
  <Grid useThreadsGrid={useThreadsGrid}>
    <Column>
      <div className={styles.tileGroupContainer}>
        <h6 className={styles.label}>{label}</h6>
        <h2 className={styles.title}>{title}</h2>
        {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
        <div className={styles.tileContainer}>{children}</div>
      </div>
    </Column>
  </Grid>
);

export default TileGroup;
