import React from 'react';

// helpers
import { arrayToCommaSeparatedString } from 'src/lib/utils';
interface Props {
  metaTitle: string;
  metaDescription: string;
  pagePath: string;
  isBlogPost?: boolean;
  openGraphImage?: string;
  tags?: Array<string>;
  authors?: Array<string>;
  published?: string;
  updated?: string;
}

const OpenGraphData: React.FC<Props> = ({
  metaTitle,
  metaDescription,
  pagePath,
  isBlogPost = false,
  openGraphImage,
  tags = [],
  authors = [],
  published,
  updated,
}) => (
  <>
    <meta property='og:title' content={metaTitle} />
    <meta
      property='og:site_name'
      content={isBlogPost ? 'Plaid - Blog' : 'Plaid'}
    />
    <meta property='og:description' content={metaDescription} />
    <meta property='og:type' content={isBlogPost ? 'article' : 'website'} />
    <meta property='og:url' content={`https://plaid.com${pagePath}`} />
    <meta
      property='og:image'
      content={
        openGraphImage
          ? `https:${decodeURI(openGraphImage)}`
          : 'https://plaid.com/assets/img/social-card-landscape.jpg'
      }
    />
    {published && (
      <meta property='article:published_time' content={published} />
    )}
    {updated && <meta property='article:modified_time' content={updated} />}
    {tags.map((tag) => (
      <meta property='article:tag' content={tag} />
    ))}

    <meta name='twitter:site' content='@plaid' />
    <meta name='twitter:title' content={metaTitle} />
    <meta name='twitter:card' content='summary_large_image' />
    <meta property='twitter:description' content={metaDescription} />
    <meta name='twitter:url' content={`https://plaid.com${pagePath}`} />
    <meta
      name='twitter:image'
      content={
        openGraphImage
          ? `https:${decodeURI(openGraphImage)}`
          : 'https://plaid.com/assets/img/social-card.jpg'
      }
    />
    {authors.length > 0 ? (
      <>
        <meta name='twitter:label1' content='Written by' />
        <meta
          name='twitter:data1'
          content={arrayToCommaSeparatedString(authors)}
        />
      </>
    ) : null}
    {tags.length > 0 ? (
      <>
        <meta name='twitter:label2' content='Filed under' />
        <meta
          name='twitter:data2'
          content={arrayToCommaSeparatedString(tags)}
        />
      </>
    ) : null}
  </>
);

export default OpenGraphData;
