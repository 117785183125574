import React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { CardsRow, LogoWall } from 'src/sections';
import { createCards } from './Utils';
import { Wrapper, RichTextSwitch } from 'src/containers';
import { Column } from 'src/components-v2/Layout';
import { MediaColumn, TextColumn } from './DefaultColumns';

const CmsSection = ({ fields, sys }) => {
  const cards = fields?.components;
  const bgColor = fields?.backgroundColor?.fields?.color;
  const type = fields?.type;
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  switch (type) {
    case 'Cards Row':
      return (
        <CardsRow
          sx={fields?.styles}
          sys={sys}
          header={
            <RichTextSwitch
              sys={sys}
              content={fields?.cardsRowHeader}
              nodes={{
                h2: {
                  variant: 'h2',
                  fieldId: 'header',
                },
                p: {
                  variant: 'p',
                  fieldId: 'header',
                },
              }}
            />
          }
          cards={createCards(cards)}
        />
      );
    case 'Logo Wall':
      const logos = fields?.logos?.map((asset) => {
        return {
          src: 'https:' + asset?.fields?.file?.url,
          alt: fields?.title || '',
        };
      });
      return <LogoWall images={logos} sys={sys} />;
    case 'Default':
      return (
        <Wrapper
          {...inspectorProps({ fieldId: 'internalTitle' })}
          sx={{ backgroundColor: bgColor || 'inherit' }}
        >
          <Column xs={24} sm={12} lg={9} lgOffset={2}>
            {fields?.defaultLayout === 'Reversed' ? (
              <MediaColumn media={fields?.defaultMedia} />
            ) : (
              <TextColumn
                body={fields?.defaultBody}
                sys={sys}
                bgColor={bgColor}
              />
            )}
          </Column>
          <Column xs={24} sm={11} smOffset={1} lg={10} lgOffset={1}>
            {fields?.defaultLayout === 'Reversed' ? (
              <TextColumn
                body={fields?.defaultBody}
                sys={sys}
                bgColor={bgColor}
              />
            ) : (
              <MediaColumn media={fields?.defaultMedia} />
            )}
          </Column>
        </Wrapper>
      );
    default:
      return null;
  }
};

export default CmsSection;
