import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  MenuItem,
  MenuList,
  MenuLink,
  MenuHeading,
} from 'src/sections/Navigation';
import { Box } from 'src/components-v2/Layout';
import { MenuItemTypes } from 'src/lib/constants';

// data
import { navData } from './nav-data';

const wrappingStyles = (isGreaterThanSix, isWrapping) => {
  return {
    gridRow: 1,
    gridColumn: 1,
    width: { lg: isGreaterThanSix && isWrapping ? '480px' : '256px' },
    display: {
      lg: isWrapping ? 'grid' : 'flex',
    },
    gridTemplateRows: { lg: 'repeat(6, 1fr)' },
    gridAutoFlow: { lg: 'column' },
  };
};

const SecondaryDropdown = ({
  child,
  isActive,
  isMobile,
  setActiveIndex,
  activeIndex,
  activeItem,
  portal,
  i,
}) => {
  const isChildGreaterThanSix = child.children.length > 6;
  const isChildWrapping =
    child.isWrapping === false ? false : isChildGreaterThanSix;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isActive) {
        return;
      }

      if (e.key === 'ArrowRight') {
        // find focusable link inside of active `ul` (not inert)
        const focusableEl = portal.querySelector(
          'ul:not([inert]) > li > [tabindex="0"]',
        );
        requestAnimationFrame(() => {
          if (focusableEl) {
            focusableEl.focus();
          }
        });
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive, portal]);

  // manage portal's display dynamically based on active item to ensure
  // tertiary menu is closed when navigating to links
  useEffect(() => {
    if (!portal || !activeItem) {
      return;
    }
    if (activeItem.type !== 'dropdown') {
      portal.style.display = 'none';
    }
    return () => {
      portal.style.display = 'grid';
    };
  }, [activeItem, portal]);

  const menuList = (
    <MenuList
      variant='tertiary'
      isActive={isActive}
      sx={{
        ...wrappingStyles(isChildGreaterThanSix, isChildWrapping),
      }}
    >
      {child.children.map((nestedChild, i) => {
        switch (nestedChild.type) {
          case MenuItemTypes.heading:
            return (
              <MenuHeading
                variant='tertiary'
                sx={nestedChild.sx}
                key={`${nestedChild.title}-${i}`}
                {...nestedChild}
              />
            );
          case MenuItemTypes.link:
            return (
              <MenuLink
                variant='tertiary'
                key={`${nestedChild}-${i}`}
                isActive={true}
                {...nestedChild}
              />
            );
        }
      })}
    </MenuList>
  );

  return (
    <MenuItem
      isActive={isActive}
      isMobile={isMobile}
      variant='secondary'
      title={child.title}
      onShow={() => {
        return setActiveIndex(i);
      }}
      onHide={() => {
        return setActiveIndex(-1);
      }}
      onClick={() => {
        return setActiveIndex(i);
      }}
    >
      {activeIndex >= 0 && (portal ? createPortal(menuList, portal) : menuList)}
    </MenuItem>
  );
};

const SubMenuList = ({ item, portal, isMobile, isActive }) => {
  const [activeIndexes, setActiveIndexes] = useState(new Set());
  const isGreaterThanSix = item.children.length > 6;
  const isWrapping = item.isWrapping === false ? false : isGreaterThanSix;

  useEffect(() => {
    if (isActive && !isMobile) {
      setActiveIndexes(new Set([0]));
    }
  }, [isActive, isMobile, setActiveIndexes]);

  const handleClick = (i: number) => {
    setActiveIndexes((prevState) => {
      const newState = new Set(prevState);
      if (isMobile) {
        if (newState.has(i)) {
          newState.delete(i);
        } else {
          newState.add(i);
        }
      } else {
        newState.clear();
        newState.add(i);
      }
      return newState;
    });
  };
  return (
    <MenuList
      isActive={isActive}
      variant='secondary'
      sx={{
        ...wrappingStyles(isGreaterThanSix, isWrapping),
        ...item.sx,
      }}
    >
      {item.children.map((child, i) => {
        const isActive = activeIndexes.has(i);
        switch (child.type) {
          case MenuItemTypes.dropdown:
            return (
              <SecondaryDropdown
                child={child}
                isActive={isActive}
                isMobile={isMobile}
                i={i}
                activeIndex={isActive ? i : -1}
                activeItem={isActive ? item.children[i] : null}
                portal={portal}
                setActiveIndex={handleClick}
                key={`${child}-${i}`}
              />
            );
          case MenuItemTypes.link:
            return (
              <MenuLink
                variant='secondary'
                key={`${child.title}-${i}`}
                onFocus={() => {
                  return handleClick(i);
                }}
                {...child}
              />
            );
          case MenuItemTypes.heading:
            return (
              <MenuHeading
                sx={child.sx}
                key={`${child.title}-${i}`}
                {...child}
              />
            );
          default:
            return null;
        }
      })}
    </MenuList>
  );
};

const SubMenu = ({ item, isMobile, isActive }) => {
  const [portal, setPortal] = useState(null);

  return isMobile ? (
    <SubMenuList
      item={item}
      portal={portal}
      isMobile={isMobile}
      isActive={isActive}
    />
  ) : (
    <Box display='flex' flexDirection='row' sx={{ flexGrow: 1, flexBasis: 0 }}>
      <SubMenuList
        item={item}
        portal={portal}
        isMobile={isMobile}
        isActive={isActive}
      />
      <Box
        ref={(el) => {
          return setPortal(el);
        }}
        sx={{ display: 'grid' }}
      />
    </Box>
  );
};

const NavList = ({ locale, isMobile }) => {
  const [activeIndexes, setActiveIndexes] = useState(new Set([-1]));
  const [firstActiveIndex] = activeIndexes;

  const handleClick = (i: number) => {
    setActiveIndexes((prevState) => {
      const newState = new Set(prevState);
      if (isMobile) {
        if (newState.has(i)) {
          newState.delete(i);
        } else {
          newState.add(i);
        }
      } else {
        newState.clear();
        newState.add(i);
      }
      return newState;
    });
  };
  return (
    <MenuList variant='primary'>
      {navData[locale]?.items?.map((item, i) => {
        const isActive = activeIndexes.has(i);

        switch (item.type) {
          case MenuItemTypes.dropdown:
            return (
              <MenuItem
                isMobile={isMobile}
                index={i}
                activeIndex={firstActiveIndex}
                isActive={isActive}
                variant='primary'
                key={`${item.title}-${i}`}
                title={item.title}
                onShow={() => {
                  handleClick(i);
                }}
                onHide={() => {
                  handleClick(-1);
                }}
                onClick={() => {
                  handleClick(i);
                }}
              >
                <SubMenu
                  isActive={isActive}
                  key={`${item.title}-${i}-${firstActiveIndex}`}
                  item={item}
                  isMobile={isMobile}
                />
              </MenuItem>
            );
          case MenuItemTypes.link:
            return (
              <MenuLink
                variant='primary'
                key={`${item.title}-${i}`}
                trackingId={item.trackingId}
                onFocus={() => {
                  handleClick(i);
                }}
                {...item}
              />
            );
          default:
            return null;
        }
      })}
    </MenuList>
  );
};

export default NavList;
