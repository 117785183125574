interface LinkedInApiProps {
  conversionId: string;
  partnerId?: string;
}

/**
 * Track a conversion action based on conversion ID.
 * We set default for partnerId, unclear if this may change between spaces
 * (marketing, blog) so leaving as optional arg.
 * @param conversionId - Conversion ID received from LinkedIn event-specific pixel
 * @param partnerId - Partner id received from LinkedIn, override if needed
 */
const trackLinkedIn = ({
  conversionId,
  partnerId = '1149084',
}: LinkedInApiProps): void => {
  const url = `https://px.ads.linkedin.com/collect/?pid=${partnerId}&conversionId=${conversionId}&fmt=gif`;

  // Creates an element without actually adding it to the page DOM.
  // The call is made to LinkedIn servers to register the conversion.
  const pixel = new Image();
  pixel.src = url;
};

// Conversion IDs provided from LinkedIn Campaign Manager.
export const linkedInConversionIds = {
  CONTACT_FORM_SUCCESS: '3849868',
  PLAID_EXCHANGE_SUCCESS: '3812300',
  DOWNLOAD_FINTECH_CONSUMER_REPORT_SUCCESS: '3812308',
  DOWNLOAD_FINTECH_COVID_REPORT: '3812324',
};

export default trackLinkedIn;
