import React from 'react';
import cx from 'classnames';

import { NoResults } from 'src/components/Inputs/SearchBar';
import { Tile, TileGroup } from 'src/components/Layout';
import { Pagination } from 'src/components/Blog';

import { renderLoadingTiles, searchBasePath } from 'src/lib/resources-utils';

import styles from './TopicPageTemplate/TopicPageTemplate.module.scss';
import tileStyles from 'src/components/Layout/Tile/Tile.module.scss';

const SearchResultArticles = ({
  topic,
  isLoading,
  results,
  pagination,
  queryString,
  ...props
}) => {
  const { hasPagination, pageNumber, totalPages } = pagination;
  const doneLoadingWithNoResults = isLoading === false && results.length < 1;

  if (isLoading) {
    return <TileGroup>{renderLoadingTiles()}</TileGroup>;
  }

  if (doneLoadingWithNoResults) {
    return <NoResults />;
  }

  if (results.length > 0) {
    return (
      <>
        <TileGroup>
          {results.map((article, i) => (
            <Tile
              className={tileStyles.tile}
              {...article}
              key={`article-tile-${i}`}
            />
          ))}
        </TileGroup>
        {hasPagination && (
          <div className={cx('grid-container', styles.paginationContainer)}>
            <Pagination
              currentPage={pageNumber}
              totalPages={totalPages}
              currentPath={searchBasePath({
                topic,
                queryString,
                withQueryParams: pageNumber > 1,
                pageNumber: pageNumber > 1 && pageNumber,
              })}
              prevPath={
                pageNumber === 2
                  ? searchBasePath({ topic, withQueryParams: false })
                  : searchBasePath({
                      topic,
                      queryString,
                      pageNumber: pageNumber - 1,
                    })
              }
              nextPath={searchBasePath({
                topic,
                queryString,
                pageNumber: pageNumber + 1,
              })}
              lastPagePath={searchBasePath({
                topic,
                queryString,
                pageNumber: totalPages,
              })}
            />
          </div>
        )}
      </>
    );
  }
};

export default SearchResultArticles;
