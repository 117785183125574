import React from 'react';
import PropTypes from 'prop-types';
import styles from './SocialProof.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const SocialProofHeader = ({ children }) => (
  <h6 className={styles.header}>{children}</h6>
);

SocialProofHeader.propTypes = propTypes;
export default SocialProofHeader;
