import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  logosPerRow: PropTypes.number,
};

const SocialProofLogosContainer = ({
  children,
  logosPerRow = 4,
  alignment,
}) => (
  <div
    className={cx(
      'grid-x',
      'small-up-2',
      `medium-up-${logosPerRow}`,
      alignment && `align-${alignment}`,
    )}
    data-testid='social-proof-logos-container'
  >
    {children}
  </div>
);

SocialProofLogosContainer.propTypes = propTypes;
export default SocialProofLogosContainer;
