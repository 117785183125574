/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { format } from 'fecha';
import cx from 'classnames';
import { marketoAdapter } from 'src/components/Forms/MarketoForm';

import { formatTime, getTimePeriod, removeTimezoneOffset } from 'src/lib/utils';
import { FormTypes } from 'src/lib/constants';

/**
 * Maps the contentful response for Page Header with Form content type to the view
 *
 * @param {Object} args
 * @param {string} args.heading
 * @param {Object} args.form
 * @param {Object} args.image
 * @returns {Object}
 */
export const pageHeaderWithFormAdapter = ({
  heading,
  subheader,
  form,
  image,
  backgroundColor,
}) => {
  return {
    'flip-horizontal': true,
    'image-based': true,
    'form-header': true,
    header: heading,
    subheader,
    'header-size': 'h2',
    'header-image-vert-center': true,
    'header-image': _get(image, 'fields.file.url'),
    headerImageAlt: _get(image, 'fields.description') || '',
    body: _get(form, 'fields.richDescription'),
    formData: marketoAdapter(form),
    backgroundColor: _get(backgroundColor, 'fields.color', 'white'),
    formType: FormTypes.CMS,
  };
};

export const EventHeaderAdapter = (component, currentEvent) => {
  const descriptionHtml = `
  <span>${_get(component?.fields?.form, 'fields.description', '')}</span>
`;

  const labelProps = {
    label: true,
    'label-text': 'PLAID EVENTS',
  };

  const timeString = ({ startDate, endDate = '' }) => {
    const startDatePeriod = getTimePeriod(startDate);
    const startTimeString = `${formatTime(startDate)}${startDatePeriod}`;
    const timezone = _get(currentEvent, 'fields.timezone', '');
    if (!endDate) {
      return ` | ${startTimeString} ${timezone}`;
    }

    const endDatePeriod = getTimePeriod(endDate);
    const endTimeString = `${formatTime(endDate)}${endDatePeriod}`;
    return ` | ${startTimeString} - ${endTimeString} ${timezone}`;
  };

  const dateTimeString = ({ start, end = '' }) => {
    const startDate = new Date(start);
    if (!end) {
      const dateText = format(startDate, 'MMMM Do, YYYY');
      return `${dateText}${timeString({ startDate })}`;
    }
    const endDate = new Date(end);

    const sameYear = startDate.getFullYear() === endDate.getFullYear();
    const sameMonth = sameYear && startDate.getMonth() === endDate.getMonth();
    const sameDate =
      sameYear && sameMonth && startDate.getDate() === endDate.getDate();

    if (sameDate) {
      const startDateText = format(startDate, 'MMMM Do, YYYY');
      return `${startDateText}${timeString({
        startDate,
        endDate,
      })}`;
    }

    if (sameMonth) {
      const startDateText = format(startDate, 'MMMM Do');
      const endDateText = format(endDate, 'Do, YYYY');
      return `${startDateText} - ${endDateText}`;
    }

    if (sameYear) {
      const startDateText = format(startDate, 'MMMM Do');
      const endDateText = format(endDate, 'MMMM Do, YYYY');
      return `${startDateText} - ${endDateText}`;
    }

    const startDateText = format(startDate, 'MMMM Do, YYYY');
    const endDateText = format(endDate, 'MMMM Do, YYYY');
    return `${startDateText} - ${endDateText}`;
  };

  // Content model returns startTime with timezone
  // using ISO 8601 format with time zone offset to UTC
  // We remove the time zone offset to avoid Date localization
  const eventDateText = currentEvent?.fields?.startTime
    ? dateTimeString({
        start: removeTimezoneOffset(currentEvent.fields.startTime),
        ...(currentEvent?.fields?.endTime && {
          end: removeTimezoneOffset(currentEvent.fields.endTime),
        }),
      })
    : '';

  const eventDateClass = cx('event-date', {
    'event-date--hidden': !eventDateText,
  });
  const body = `
    ${descriptionHtml}
    <span class="${eventDateClass}">${eventDateText}</span>
    `;

  return {
    ...labelProps,
    header: component?.fields?.heading,
    body,
  };
};
