import styled from 'threads5/styles/styled';

const Logo = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M14.7217 14.7216L16 9.73192L14.2681 8.00008L15.9999 6.26808L14.7216 1.27832L9.73192 0L8.00008 1.732L6.268 8e-05L1.27832 1.27848L0 6.26808L1.73216 7.99992L8e-05 9.732L1.2784 14.7217L6.26808 16L8.00008 14.268L9.732 15.9999L14.7217 14.7216ZM8.98976 13.2783L10.6392 11.6289L12.7378 13.7276L10.1118 14.4004L8.98976 13.2783ZM6.35064 5.36088L8.00016 3.71144L9.6496 5.3608L8.00008 7.01024L6.35064 5.36088ZM7.0104 8L5.36088 9.64944L3.71144 8L5.36088 6.35056L7.0104 8ZM10.6393 6.35048L12.2887 8L10.6392 9.64944L8.98976 8L10.6393 6.35048ZM9.64952 10.6392L8.00008 12.2886L6.35056 10.6391L8.00008 8.98968L9.64952 10.6392ZM5.88824 14.4004L3.26208 13.7276L5.36088 11.6288L7.0104 13.2783L5.88824 14.4004ZM2.72176 8.98968L4.3712 10.6391L2.2724 12.7379L1.5996 10.1118L2.72176 8.98968ZM2.2724 3.26192L4.37128 5.3608L2.72176 7.01032L1.5996 5.88808L2.2724 3.26192ZM7.01048 2.72168L5.36096 4.3712L3.26224 2.2724L5.8884 1.5996L7.01048 2.72168ZM10.1119 1.5996L12.738 2.2724L10.6392 4.37112L8.98976 2.72168L10.1119 1.5996ZM13.2784 7.01032L11.6289 5.3608L13.7276 3.26216L14.4004 5.88832L13.2784 7.01032ZM13.7276 12.7379L11.6289 10.6392L13.2784 8.98976L14.4004 10.1118L13.7276 12.7379Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default styled(Logo)({});
