export const metaData = {
  metaTitle: 'Solo',
  metaDescription:
    'By nearly doubling its conversion rate, Plaid has helped SoLo Funds bring stability to the financially insecure.',
};

export const pageHeaderData = {
  classes:
    'background background--color-yellow600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-yellow-2@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: SoLo',
  header:
    'An ethical alternative<br class="show-for-medium" /><span class="show-for-small-only"> </span>to payday loans',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/solo-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-solo@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-solo@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Connect user bank accounts, underwrite loans',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Lending',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'SoLo Funds needed a way to quickly and efficiently connect user bank accounts. Their previous vendor wasn’t up to the task.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, SoLo Funds has achieved a superior conversion rate (77%) which has helped them process more than $2 million in loans—and counting.',
};

export const content1Data = {
  heading:
    'SoLo Funds is a peer-to-peer lending platform that helps people solve short-term cash flow challenges without having to resort to predatory financing options like payday loans.',
  paragraph: [
    'SoLo needed a way for users to quickly and efficiently connect their bank accounts, but the first vendor they tried wasn’t up to the task. Then they switched to Plaid. The result is a superior conversion rate (77%) that has helped SoLo process more than $2 million in loans—and counting.',
  ],
};

export const supportingTextData = {
  classes:
    'background background--color-yellow200 supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '77%',
  text1:
    'Since integrating with Plaid, SoLo’s conversion rate has nearly doubled, to 77%.',
  stat2: '100K+',
  text2: 'Plaid has helped SoLo acquire 100,000 users in just over one year.',
  stat3: '$2M',
  text3:
    'To date, SoLo has processed more than $2M in loans through the platform.',
};

export const content2Data = {
  title: 'Pay it forward',
  paragraph: [
    'SoLo launched in 2018 with a clear mission: make life easier for the millions of Americans who lack financial health.',
    'That includes the 78% of us who live paycheck-to-paycheck and the 47% without $400 to cover an emergency. When these people need short-term, small-dollar loans—for instance, to pay for utilities, cover medical expenses, or buy groceries—they frequently turn to high-interest credit cards or payday loans. Long term, these solutions often leave them worse-off, financially.',
    'SoLo offers an ethical alternative. They connect borrowers with individual lenders who have discretionary cash and are willing to help. Borrowers submit a loan request describing their need, and lenders can elect to fund it based on the presented terms. Lenders also have the option to create a counter-offer to the original loan request. The result is a friendly system with financial and emotional benefits for both parties.',
    '“For borrowers, it can be embarrassing to ask friends and family for money. This is a way around that,” said Sheldon Satenstein, Growth Marketing Analyst at SoLo. “On the flip side, lenders like the idea of helping someone out and making a bit of money in the process. We’ve found that many lenders have been in those tough situations before. They want to pay it forward.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-yellow-1@2x.png',
  'stroke-bottom-left':
    '/assets/img/accents/diagram-brushstroke-yellow-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'SoLo Fund’s onboarding flow asks customers to link an outside bank account. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, SoLo Funds can access the data they need to authenticate accounts and onboard new users.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-yellow@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img:
        '/assets/img/customer-stories/diagram-step-link-mobile-yellow@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-yellow@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-yellow@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Conversion problems',
    paragraph: [
      'In the beginning, SoLo wasn’t easy for some users to access. That was in conflict with the company’s goal to make the financial system more accessible for everyone—especially those who struggle to make ends meet.',
      'The problem was connecting users’ bank accounts. Before they signed on with Plaid, SoLo relied on another vendor. Unfortunately, the other vendor served only a small number of financial institutions, and users whose banks weren’t on the list couldn’t complete the onboarding process. The result was a high volume of complaints and low conversion rates.',
      "“We were getting multiple emails every day from users who couldn't sign up because we didn't support their bank,” said Jon Blackwell, Chief Product Officer at SoLo.",
      'Every time they got a complaint, SoLo would ask for an update from the vendor. Often, they were told that the requested bank was not currently on the vendor’s product roadmap.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-solo@2x.png',
      alt: 'Headshot of Jon Blackwell, Chief Product Officer at SoLo Funds',
    },
    quote: [
      {
        copy:
          'Plaid makes life easier for our team. It frees up engineering time that we can put toward our long-term business goals.',
        source: 'Jon Blackwell, Chief Product Officer, SoLo Funds',
      },
    ],
  },
  {
    title: 'A Plaid solution',
    paragraph: [
      'SoLo first heard about Plaid through word-of-mouth.',
      '“Every financial technology company we spoke to recommended Plaid,” said Blackwell. “They had a reputation for being industry experts and delivering a great product.”',
      'But SoLo wanted to find out for themselves. They performed an exploratory exercise to determine which of Plaid’s products made sense for their business and how big a lift would be required to implement them. The answer? Integrating with Plaid required just one week and 30 lines of code.',
      '“The more we looked into Plaid, the clearer it became,” said Blackwell. “This was a no-brainer for us.”',
      'In short order, SoLo had five Plaid products—Identity, Auth, Balance, Transactions, and Assets—up and running. Today, SoLo can onboard borrowers regardless of where they do their banking. That’s because Plaid now offers support for all 11,500 US banks and credit unions.',
      'Beyond onboarding, Plaid enables SoLo to perform due diligence on every borrower. Along with other metrics, they leverage a borrower’s banking transactions and cash flow over time to assess creditworthiness and predict their ability to repay loans on time. The analysis yields a borrower’s SoLo Score, which gives potential lenders an additional datapoint to consider when making a decision.',
    ],
  },
  {
    title: 'Focus on what you do well',
    'narrow-title': true,
    paragraph: [
      'Before their Plaid integration, SoLo’s conversion rate hovered around 45%. Since their Plaid integration, it has nearly doubled, to 77%.',
      'Before, when users realized they couldn’t link their bank accounts, they would drop out of the onboarding process. Today, Plaid makes a quick and seamless integration possible for nearly every borrower. As a result, SoLo is able to grow at a faster rate while focusing on those aspects of their business that drive their competitive advantage.',
      '“Plaid makes life easier for our team,” said Blackwell. “It frees up engineering time that we can put toward our long-term business goals.”',
      '<strong>In just over one year, SoLo has acquired more than 100,000 users, and they recently surpassed $2 million in loans processed through the platform. As the company looks toward the future, they are already considering how they can leverage additional Plaid products to help them accomplish their goals.</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Prosper',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-prosper@2x.png',
        alt: 'Prosper - read full story',
      },
      excerpt: 'Helping people invest in each other',
      url: '/customer-stories/prosper',
    },
    {
      name: 'Brigit',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-brigit@2x.png',
        alt: 'Brigit - read full story',
      },
      excerpt: 'A responsible way to bridge through money shocks',
      url: '/customer-stories/brigit',
    },
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/solo-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
