import React, { FC } from 'react';
import styles from './Pagination.module.scss';
import cx from 'classnames';

import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  basePath?: string;
  currentPath?: string;
  prevPath?: string;
  nextPath?: string;
  lastPagePath?: string;
}

const getPreviousHref = ({ currentPage, basePath }): string => {
  const previousPage = currentPage - 1;
  return previousPage === 1 ? basePath : `${basePath}/page/${previousPage}`;
};

const getCurrentHref = ({ currentPage, basePath }): string => {
  return currentPage - 1 === 0 ? basePath : `${basePath}/page/${currentPage}`;
};

const Pagination: FC<PaginationProps> = ({
  totalPages,
  currentPage,
  basePath = '/blog',
  currentPath,
  prevPath,
  nextPath,
  lastPagePath,
}) => {
  const previousHref = prevPath || getPreviousHref({ basePath, currentPage });
  const nextHref = nextPath || `${basePath}/page/${currentPage + 1}`;
  const currentHref = currentPath || getCurrentHref({ basePath, currentPage });
  const totalPagesHref = lastPagePath || `${basePath}/page/${totalPages}`;

  return (
    <nav className={styles.paginationContainer} role='navigation'>
      {currentPage > 1 && (
        <>
          <Link href={previousHref}>
            <a className={cx(styles.paginationLink, styles.previous)}>
              <ArrowRightSingleSmall />
              {' Previous'}
            </a>
          </Link>
          <Link href={previousHref}>
            <a className={styles.paginationLink}>{currentPage - 1}</a>
          </Link>
        </>
      )}
      <Link href={currentHref}>
        <a className={cx(styles.paginationLink, styles.current)}>
          {currentPage}
        </a>
      </Link>
      {currentPage < totalPages && (
        <>
          <Link href={nextHref}>
            <a className={styles.paginationLink}>{currentPage + 1}</a>
          </Link>
          <Link href={totalPagesHref}>
            <a className={cx(styles.paginationLink, styles.last)}>&hellip;</a>
          </Link>
          <Link href={nextHref}>
            <a className={cx(styles.paginationLink, styles.next)}>
              {'Next '}
              <ArrowRightSingleSmall />
            </a>
          </Link>
        </>
      )}
    </nav>
  );
};

export default Pagination;
