import React from 'react';
import cx from 'classnames';
import { Button } from 'src/components/Inputs';

import { PaywallRenderOptions, TargetButtonTargets } from 'src/lib/constants';

interface Props {
  setIsModalOpen?: (input: boolean) => void;
  isContentUnlocked?: boolean;
  renderOption?: string;
  hasPaywall?: boolean;
  dark?: boolean;
  targetButton: {
    text: string;
    target: string;
    href?: string;
  };
}

export const TargetButton = (props: Props) => {
  const isModalButton =
    props?.targetButton &&
    props?.targetButton?.target === TargetButtonTargets.MODAL;
  const isModalPaywall =
    props.hasPaywall && props.renderOption === PaywallRenderOptions.MODAL;

  if (isModalButton && isModalPaywall && !props.isContentUnlocked) {
    return (
      <>
        <Button
          onClick={() => {
            props.setIsModalOpen(true);
          }}
          className={cx({
            alternate: props.dark,
          })}
          primary
        >
          {props?.targetButton?.text}
        </Button>
      </>
    );
  }

  // in the event this is used as a non-modal button
  const isStandardTargetButton =
    props?.targetButton &&
    props?.targetButton.target != null &&
    props?.targetButton?.target !== TargetButtonTargets.MODAL;
  if (isStandardTargetButton) {
    const target =
      props?.targetButton?.target === TargetButtonTargets.NEW_TAB
        ? '_blank'
        : '_self';
    return (
      <>
        <Button
          href={props?.targetButton?.href}
          className={cx({
            alternate: props.dark,
          })}
          primary
          target={target}
        >
          {props?.targetButton?.text}
        </Button>
      </>
    );
  }

  return null;
};

export default TargetButton;
