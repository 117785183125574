// TODO: Check if this page needs to be deprecated, it is currently redirecting to /safety page

import React from 'react';
import Image from 'src/components/Image';
import { Locales } from 'src/lib/constants';

// lazy load template so that we have access to the window object
import DocsTemplate from 'src/templates/DocsTemplate/DocsTemplate';

//components
import { Link } from 'src/components/Inputs';
import ArrowRightCaret from 'src/components/SVGs/Icons/ArrowRightCaret';

import { dangerous_getStaticData } from 'src/lib/getStaticData';

const Security = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    sidenavData,
    pageHeadingData,
    sectionsWithIconData,
    collaborationSectionData,
  } = dangerous_getStaticData({ locale, filePath: 'security' });

  return (
    <DocsTemplate {...metaData} locale={locale}>
      <div className='bg-docs security'>
        <div id='docsWrapper' className='grid-container'>
          <div className='grid-x'>
            <aside id='docsSidenav' className='cell large-3 sidenav-docs'>
              <div
                className='sticky show-for-large'
                data-sticky
                data-top-anchor='docsSidenav:top'
                data-btm-anchor='footer:top'
                data-margin-top='8'
                data-margin-bottom='1'
                data-check-every='0'
              >
                <ul
                  id='desktopSidenavMenu'
                  className='menu sidenav-menu vertical'
                  data-sidenav-toggle
                >
                  {sidenavData.map((item, i) => {
                    return (
                      <li key={`sidenav${i}`}>
                        <Link href={`#${item.id}`}>
                          <a data-smooth-scroll className='sidenav-menu-item'>
                            <span
                              aria-hidden='true'
                              className='sidebar-docs-arrow'
                            >
                              <ArrowRightCaret />
                            </span>
                            {item.title}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </aside>
            <article
              id='docsArticle'
              className='cell small-12 large-auto article-docs article-docs--padding-bottom-extra'
            >
              <div className='article-docs-content article-docs-content--padding-bottom-extra'>
                <header className='article-header article-header--no-divider'>
                  <h3 id='overview' data-smooth-scroll>
                    {pageHeadingData.title}
                  </h3>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: pageHeadingData.subtitle,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: pageHeadingData.paragraph,
                    }}
                  />
                </header>
                <hr className='hr hr--margin-3 hr--margin-3-mobile' />
                <div className='icon-rows'>
                  {sectionsWithIconData.map((item, i) => {
                    return (
                      <div
                        className='article-docs__icon-row'
                        key={`icon-row-${i}`}
                        id={item.id}
                      >
                        <div className='grid-container full'>
                          <div className='grid-x'>
                            <div className='cell small-12 large-3'>
                              <Image
                                alt=''
                                src={`/assets/img/security/${item.iconSlug}@2x.png`}
                                className='icon-rows__icon'
                              />
                            </div>
                            <div className='cell small-12 large-9'>
                              <h4>
                                <strong>{item.title}</strong>
                              </h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.paragraph,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr className='hr hr--margin-3 hr--margin-3-mobile' />
                <div id={collaborationSectionData.id} data-smooth-scroll>
                  <h4>
                    <strong>{collaborationSectionData.title}</strong>
                  </h4>
                  {collaborationSectionData.paragraphs.map((item, i) => {
                    return (
                      <p
                        key={`collaboration-p-${i}`}
                        dangerouslySetInnerHTML={{
                          __html: item,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </DocsTemplate>
  );
};

export default Security;
