const columns = [
  {
    heading: 'GET STARTED',
    gridClasses: 'cell small-5',
    items: [
      {
        name: 'Company',
        description: '',
        url: '/company',
      },
      {
        name: 'Careers',
        description: '',
        url: '/careers',
      },
      {
        name: 'Blog',
        description: '',
        url: '/blog',
      },
      {
        name: 'Contact',
        description: '',
        url: '//dashboard.plaid.com/contact',
      },
    ],
  },
  {
    heading: 'PRIVACY & SECURITY',
    gridClasses: 'cell small-5',
    items: [
      {
        name: 'Safety',
        description: '',
        url: '/safety',
      },
      {
        name: 'How we handle data',
        description: '',
        url: '/how-we-handle-data',
      },
      {
        name: 'Legal',
        description: '',
        url: '/legal',
      },
    ],
  },
];

const aboutUs = {
  label: 'About us',
  subtitle:
    'Get to know the people behind the code and the mission behind the work',
  subtitleColSize: 4,
  submenu: {
    columns,
  },
};

export default aboutUs;
