import React, { FC } from 'react';
import Image from 'src/components/Image';
import { Grid, Column } from 'src/components/Layout/Grid';
import bodyFormCtaStyles from 'src/components/Forms/BodyFormCta/BodyFormCta.module.scss';
import styles from 'src/components/Forms/CtaFormWrapper/CtaFormWrapper.module.scss';
import { CtaFormWrapper as CtaFormWrapperProps } from './CtaFormWrapper.types';
import cx from 'classnames';
import { Spacer } from 'src/components-v2/Layout';

const CtaFormWrapper: FC<CtaFormWrapperProps> = ({
  bullets,
  children,
  headerClass,
  image,
  isBodyForm = false,
  isNewGrid = false,
  subtext = '',
  subtextClass,
  title,
  withSubtext,
}) => {
  const hasBullets = !!bullets.length;
  if (isBodyForm) {
    return (
      <Grid
        className={bodyFormCtaStyles.bodyFormCtaSection}
        noPadding
        useThreadsGrid
      >
        <Column medium={image.src ? 4 : 6} className='cta-section-content'>
          {image.src ? (
            <Image
              className={bodyFormCtaStyles.image}
              src={image.src}
              alt={image.alt}
              width={image.width}
            />
          ) : (
            <>
              <h4 className={headerClass}>{title}</h4>
              <p dangerouslySetInnerHTML={{ __html: subtext }} />
            </>
          )}
        </Column>
        <Column
          medium={image.src ? 8 : 6}
          className={bodyFormCtaStyles.formColumn}
          allowOverflow
        >
          {image.src && (
            <>
              <h4 className={headerClass}>{title}</h4>
              {subtext && <p dangerouslySetInnerHTML={{ __html: subtext }} />}
            </>
          )}
          {children}
        </Column>
      </Grid>
    );
  } else {
    return (
      <Grid
        containerClassName={cx({
          [styles.gridWrapperWide]: isNewGrid,
        })}
        className={cx({
          'align-justify': !isNewGrid,
          [styles.gridWrapperWideSpaceBetween]: isNewGrid,
        })}
      >
        <Column
          medium={isNewGrid ? 12 : 6}
          large={6}
          className={cx('cta-section-content', {
            [styles.noMargin]: isNewGrid,
            [styles.verticallyCentered]: isNewGrid,
          })}
        >
          <h2
            className={cx({
              [headerClass]: !!headerClass,
              [styles.heading]: isNewGrid,
            })}
          >
            {title}
          </h2>
          {withSubtext && (
            <>
              {subtextClass === 'regular-text' ? (
                <p dangerouslySetInnerHTML={{ __html: subtext }} />
              ) : (
                <h4
                  className={cx({
                    [subtextClass]: !!subtextClass,
                    [styles.body]: isNewGrid,
                  })}
                >
                  {subtext}
                </h4>
              )}
            </>
          )}
          {image.src && (
            <>
              <Image
                className='cta-section__image'
                src={image.src}
                alt={image.alt}
                width={image.width}
              />
            </>
          )}
          {hasBullets && (
            <ul className='cta-section__bullets'>
              {bullets.map((item, i) => {
                return (
                  <li
                    className='cta-section__bullets__li'
                    key={`cta-section-bullet-${i}`}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          )}
          {isNewGrid && <Spacer sx={{ height: { xs: 0, sm: 24, md: 0 } }} />}
        </Column>
        <Column
          allowOverflow
          medium={isNewGrid ? 12 : 6}
          large={6}
          className={cx({
            [styles.noMargin]: isNewGrid,
            [styles.card]: isNewGrid,
          })}
        >
          {children}
        </Column>
      </Grid>
    );
  }
};

export default CtaFormWrapper;
