import { useEffect } from 'react';

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement('script');
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({
  baseUrl,
  munchkinId,
  formId,
  scriptLoaded,
  setScriptLoaded,
  marketoFormLoaded,
}) {
  useEffect(() => {
    appendScript(baseUrl, setScriptLoaded);
    if (scriptLoaded) {
      marketoFormLoaded.current = new Promise((resolve) => {
        window.MktoForms2.loadForm(
          baseUrl,
          munchkinId,
          formId,
          (marketoForm) => {
            resolve(marketoForm);
          },
        );
      });
    }
  }, [
    baseUrl,
    munchkinId,
    formId,
    scriptLoaded,
    setScriptLoaded,
    marketoFormLoaded,
  ]);
}

export default useMarketo;
