export const metaData = {
  'meta-title': 'Bank Account Onboarding - Plaid',
  'meta-description': 'The technology layer for financial services"',
  hasCanonicalTag: true,
};
export const pageHeaderData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'A fast, simple, and digital bank onboarding experience',
  'header-size': 'h2',
  'header-image': '/assets/img/accents/description-section/banking-hero.png',
  body:
    'Plaid enables banks and credit unions to instantly verify accounts and identities to streamline account opening and funding. Get in touch to learn more.',
  formData: {
    'marketo-key': 2007,
    includePhone: true,
    actionText: "Let's talk",
  },
};
export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/patriot-bank-logo.png',
      alt: 'Patriot Bank',
    },
    {
      img: '/assets/img/logos/radius-bank-logo.png',
      alt: 'Radius Bank',
    },
    {
      img: '/assets/img/logos/tdecu-credit-union-logo.png',
      alt: 'TDECU',
    },
    {
      img: '/assets/img/logos/union-bank-trust-logo.png',
      alt: 'Union Bank & Trust',
    },
  ],
};
export const introSectionData = {
  'problem-statement':
    'Opening and funding a new bank account should be a seamless, digital experience. However, many banks and credit unions still offer a cumbersome and manual process—falling short of consumer expectations.',
  'solution-statement':
    'Plaid streamlines the digital account onboarding workflow with instant account authentication and identity verification. Users input their account login credentials and Plaid instantly links their bank account as an external funding source.',
};
export const quoteData = {
  quote:
    'It’s a win-win for both the bank and the customer. We now see less customer complaints, and more customer feedback on how smooth and efficient our process is.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/liveoak-app-icon.png',
  'logo-icon-full-bleed': true,
  'logo-alt': 'Live Oak logo',
  cite: 'Mark Moroz, Live Oak Director of deposits',
  'quote-link': 'https://plaid.com/documents/Live-Oak-Bank-Case-Study.pdf',
  'quote-link-text': 'See the full case study',
  'with-background': false,
  'remove-default-design': false,
  dark: false,
};
export const featuresData = {
  'header-section': true,
  'header-subtext': 'PRODUCTS AND BENEFITS',
  'header-text': null,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img': '/assets/img/products/auth-112@2x.png',
      'feature-title': 'Get account and routing numbers for bank transfers',
      'feature-body':
        '<a href="/products/auth/">Auth</a> pulls users\' account and routing information instantly, which can be used for linking an external funding source.',
    },
    {
      'feature-img': '/assets/img/products/identity-112.png',
      'feature-title': 'Streamline KYC and onboarding',
      'feature-body':
        '<a href="/products/identity/">Identity</a> confirms users\' identities with what\'s on file at the bank—in other words, their name, phone number, address, and email. This information can be auto-populated in onboarding forms.',
    },
    {
      'feature-img': '/assets/img/products/balance-112.png',
      'feature-title': 'Reduce NSF fees and overdrafts',
      'feature-body':
        '<a href="/products/balance/">Balance</a> instantly checks whether users have sufficient funds before executing a bank transfer.',
    },
  ],
};
