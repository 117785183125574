const contentful = require('contentful');
const env = require('../env');

const marketingCmsClient = contentful.createClient({
  accessToken: env.CONTENTFUL_ACCESS_TOKEN,
  environment: env.CONTENTFUL_ENVIRONMENT,
  host: env.CONTENTFUL_HOST,
  space: env.CONTENTFUL_SPACE_ID, // plaid.com space (marketing pages)
  retryLimit: 50,
});

const partnerCmsClient = contentful.createClient({
  accessToken: env.PARTNER_ACCESS_TOKEN,
  environment: env.CONTENTFUL_ENVIRONMENT,
  host: env.CONTENTFUL_HOST,
  space: env.PARTNER_SPACE_ID, // partner directory space
  retryLimit: 50,
});

const blogCmsClient = contentful.createClient({
  accessToken: env.BLOG_ACCESS_TOKEN,
  environment: env.CONTENTFUL_ENVIRONMENT,
  host: env.CONTENTFUL_HOST,
  space: env.BLOG_SPACE_ID, // blog space
  retryLimit: 50,
});

module.exports = {
  marketingCmsClient,
  partnerCmsClient,
  blogCmsClient,
};
