import React from 'react';
import { TypographyProps } from './Typography.types';
import { themes, darkOverrides } from './TypographyThemes';
import { useIsDarkBackground } from 'src/contexts';
import { useIsDarkMode } from 'src/hooks/useIsDarkMode';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

const Typography = React.forwardRef(
  (
    {
      children,
      className = '',
      variant = 'p', // how the element looks
      theme: styleTheme = 'default',
      component, // semantic element
      sx,
      ...props
    }: TypographyProps,
    ref,
  ) => {
    const isDarkMode = useIsDarkMode();
    const isDarkBackground = useIsDarkBackground();
    const Component = themes[styleTheme][variant];
    const cmsTheme = useCmsTheme();
    return (
      <Component
        ref={ref}
        as={component} // this is a styled component prop that renders the specified tag
        sx={{
          // TODO: once the migration is complete we can do away with this logic and just rely on semantic tokens
          ...((isDarkBackground || isDarkMode) && {
            ...darkOverrides[cmsTheme],
          }),
          ...sx,
        }}
        className={className}
        cmsTheme={cmsTheme}
        {...props}
      >
        {children}
      </Component>
    );
  },
);

export default Typography;
