import React, { FC } from 'react';
import styles from './Author.module.scss';
import { arrayToCommaSeparatedString } from 'src/lib/utils';

interface Props {
  authors: string[];
}

const Author: FC<Props> = ({ authors }) => (
  <p className={styles.blogPostAuthor}>
    {arrayToCommaSeparatedString(authors)}
  </p>
);

export default Author;
