import utilities from './utilities';
import actionTypes from './actionTypes';

// trackDocsEvent :: () -> ()
const trackDocsEvent = (actionType, metadata) => {
  const defaultParams = utilities.getDefaultParameters();
  const allParams = Object.assign(
    defaultParams,
    {
      actionType,
      lang: utilities.getDocsStateLanguage(),
      beta: false,
    },
    metadata,
  );
  window.analytics.track('DOCS_EVENT', allParams, {
    context: {
      ip: '0.0.0.0',
    },
  });
};

// setupDocsPathListener :: () -> ()
const setupDocsPathListener = () => {
  // only match on /docs/* urls
  if (window.location.pathname.match(/\/docs*/)) {
    // Listen to navigation events through # changes scoped to a single page.
    // Page <> Page transitions will automatically be tracked through
    window.addEventListener(
      'hashchange',
      function (event) {
        const hash = new URL(event.newURL).hash;
        const previousHash = new URL(event.oldURL).hash;

        trackDocsEvent(actionTypes.DOCS_ANCHOR_CLICK, {
          previousHash,
          hash,
        });
      },
      false,
    );
  }
};

export default {
  trackDocsEvent,
  setupDocsPathListener,
};
