import React, { FC } from 'react';
import styles from './Author.module.scss';
import { Author as AuthorProps } from './Author.types';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import Image from 'src/components/Image';

const Author: FC<AuthorProps> = ({ name, image, blurb }) => (
  <div className={styles.author}>
    {image.src && (
      <div className={styles.authorPhoto}>
        <Image
          src={image.src}
          alt={image.alt}
          isLazy={false}
          width={60}
          height={60}
        />
      </div>
    )}
    <div className={styles.authorInfo}>
      <div className={styles.name}>{name}</div>
      {blurb?.content && (
        <div className={styles.blurb}>
          <RichTextRenderer content={blurb} />
        </div>
      )}
    </div>
  </div>
);

export default Author;
