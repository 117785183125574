import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import cx from 'classnames';

import FeatureSectionTitle from 'src/components/features/feature-section-title';
import FeatureSectionItem from 'src/components/features/feature-section-item';

const propTypes = {
  'feature-section-title': PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ]),
  'feature-section-items': PropTypes.arrayOf(
    PropTypes.shape({
      'no-link': PropTypes.bool, // 😅 Wraps the markup in a hyperlink when false
      'link-text': PropTypes.string, // 😅 Required when when no-link is false
      'link-location': PropTypes.string, // 😅 The hypertext reference for the hyperlink
      img: PropTypes.string.isRequired,
      'header-class': PropTypes.string, // For custom styling of the H4 element
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired, // body content
    }),
  ).isRequired,
  featureSectionWrapperModifiers: PropTypes.string,
};

export const hasBetaFeatures = (collection = []) => {
  return collection.some((item) => item?.isBetaFeature);
};

const featureItemContainerStyles = (fullWidthMediumUp) =>
  cx(
    'grid-x',
    'small-up-1',
    fullWidthMediumUp ? 'medium-up-1 large-up-2' : 'medium-up-2',
    'grid-margin-x',
  );

const featureSectionWrapperStyles = (
  allowBottomPaddingMobile,
  featureSectionWrapperModifiers,
  featureSectionItems,
) =>
  cx('feature-section-wrapper', {
    'feature-section-wrapper--no-bottom-padding-mobile': !allowBottomPaddingMobile,
    featureSectionWrapperModifiers,
    'feature-section-wrapper--hasBetaFeature': hasBetaFeatures(
      featureSectionItems,
    ),
  });

const FeatureSectionsUseCases = ({
  featureSectionWrapperModifiers = '',
  'feature-section-title': featureSectionTitleProps,
  'feature-section-items': featureSectionItems,
  allowBottomPaddingMobile,
  fullWidthMediumUp,
}) => (
  <div
    className={featureSectionWrapperStyles(
      allowBottomPaddingMobile,
      featureSectionWrapperModifiers,
      featureSectionItems,
    )}
  >
    <div className='grid-container'>
      <FeatureSectionTitle
        {..._get(featureSectionTitleProps, '0', featureSectionTitleProps)}
      />
      <div className={featureItemContainerStyles(fullWidthMediumUp)}>
        {featureSectionItems.map((item, i) => (
          <FeatureSectionItem key={`feature-item-${i}`} {...item} />
        ))}
      </div>
    </div>
  </div>
);

FeatureSectionsUseCases.propTypes = propTypes;

export default FeatureSectionsUseCases;
