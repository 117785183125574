import React from 'react';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';

import { Link } from 'src/components/Inputs';

const propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

const Feature = ({ heading, image, altText, content, linkUrl, linkText }) => {
  const headingWithMarkup = { __html: heading };
  return (
    <>
      <div className='job-post-feature__media'>
        <Image src={image} alt={altText} className='job-post-feature__img' />
      </div>
      <h3
        className='job-post-feature__heading'
        dangerouslySetInnerHTML={headingWithMarkup}
      ></h3>
      <p className='job-post-feature__content'>{content}</p>
      <Link href={linkUrl}>
        <a className='job-post-feature__link'>{linkText}</a>
      </Link>
    </>
  );
};

Feature.propTypes = propTypes;
export default Feature;
