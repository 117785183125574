export const metaData = {
  metaTitle: 'Drop',
  metaDescription:
    'Drop uses Plaid to deliver a seamless customer loyalty rewards program. They built it on extensive, reliable customer transactions data.',
};

export const pageHeaderData = {
  classes:
    'background background--color-green600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-green-3@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Drop',
  header: 'Rapid growth and happy customers',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Drop-Case-Study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-drop@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-drop@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Connect user bank accounts,<br />get transactions history',
  'right-label-1': 'Region',
  'right-label-1-content': 'Canada and United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Rewards',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'To deliver an industry-leading customer loyalty rewards program, Drop needed clean, up-to-date transactions data.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Drop can access 24 months of customer transactions data, including enhanced geolocation, merchant, and category information.',
};

export const content1Data = {
  heading:
    'When the founders of Drop set out to build their customer loyalty rewards platform, they envisioned a truly seamless user experience. To realize their vision, they turned to Plaid.',
  paragraph: [
    'Plaid’s Transactions product offers clean transactional data and simple integration, so that companies can focus on delivering the best customer experience in the least amount of time.',
    'In an industry plagued by friction at every turn—loyalty cards, online links, corporate partners calling the shots on ubiquitous, standardized offerings—Drop aimed to deliver a frictionless, customer-first approach. “We saw a big gap in terms of what transactions were being rewarded, and the types of rewards that were provided,” says Daniel Whiffing, Technical Lead at Drop.',
    '“Existing loyalty programs tend to offer rewards only for brands with whom partnership explicitly benefited the company, rather than offering rewards for brands that the user personally engaged with. But we wanted to go directly to consumers. To do that, we knew we needed a company like Plaid.”',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-green200 supporting-text--constrained',
  'single-stat': true,
  stat: true,
  stat1: '500K',
  text1:
    'By integrating with Plaid, Drop was able to onboard 500,000 members in six months.',
};

export const content2Data = {
  title: 'Personalized to perfection',
  paragraph: [
    'Drop stood out from the moment it launched.',
    'Built to leverage Plaid’s Transactions, Drop has easy access to clean customer data through connected bank and credit cards, allowing the app to offer rewards based on each user’s individual spending habits and transactions, and consolidate all rewards into one incredibly simple-to-use app.',
    'For users, that means no irrelevant rewards content determined by corporate partners and no friction of physical reward cards or accounts. For Drop, this means that the majority of their engineers can focus on Drop’s core product rather than data solutions. In turn, Drop can offer users the best possible experience a rewards platform could deliver.',
    '“One huge benefit has been the great developer experience Plaid provides,” says Whiffing. “It allows us to focus on the area that we’re experts in. The integration is so seamless that it’s invisible to most of our team—only a small subset is needed to work on Plaid integrations, so the majority of our engineers can focus on optimizing the core product.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-green-1@2x.png',
  'stroke-bottom-left':
    '/assets/img/accents/diagram-brushstroke-green-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Drop’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Drop can access the transactions data they need to deliver a seamless customer loyalty rewards program.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-green@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-green@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-green@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-green@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'A one-stop shop',
    paragraph: [
      'It wasn’t until Drop explored cross-providers that the team fully realized how comprehensively Plaid’s Transactions delivered data.',
      '“We didn’t even recognize the full scope of Plaid’s transaction cleaning until then. Other providers deliver data that is less clean while drastically increasing the complexity of our platform. Data cleaning is very difficult, and while others deliver basic functionality, Plaid has the foresight and resources to go above and beyond that basic responsibility, and takes the initiative to increase the scope of their responsibility to their customers.”',
      '“In the end, Plaid allowed us to focus on what was needed to grow quickly. We could have spent years building a data solution that wouldn’t have come close to what Plaid provides,” says Whiffing. “With clean data, we are truly able to understand a customer’s unique spending habits and deliver exciting, personalized rewards content.”',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-drop@2x.png',
      alt: 'Headshot of Daniel Whiffing, Technical Lead at Drop',
    },
    quote: [
      {
        copy:
          'Plaid allowed us to focus on what was needed to grow quickly. We could have spent years building a data solution that wouldn’t have come close to what Plaid provides.',
        source: 'Daniel Whiffing, Technical Lead, Drop',
      },
    ],
  },
  {
    title: 'Scaling faster, delivering more',
    paragraph: [
      'That personalized edge and time savings helped power Drop’s rapid organic customer growth and impressive engagement rates. After launching in Canada, Drop quickly expanded its services into the U.S., where they onboarded over half a million members in just five months.',
      '“We wouldn’t have been able to do that without Plaid,” Whiffing says.',
      'In just two years, the company’s user base had rocketed from a small group of friends and family to over one million Drop members, while its staff has grown from a small group of five to over 30 employees and counting. By the end of 2018, Drop aims to hit 5 million members across North America.',
      '“Plaid has been like a spring, propelling us forward,” says Esther Park, Head of Marketing and Community. “It allows us to focus on what’s needed to grow and scale more quickly than any other company could have allowed.”',
      '<strong>“We get to focus on member experience and our core product, and let Plaid deliver the transactional data. It’s been a catalyst to faster and better innovation. Because of Plaid, we were able to scale quickly without hiring additional engineers, saving us an immense amount of costs and time.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Brigit',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-brigit@2x.png',
        alt: 'Brigit - read full story',
      },
      excerpt: 'A responsible way to bridge through money shocks',
      url: '/customer-stories/brigit',
    },
    {
      name: 'Varo',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-varo@2x.png',
        alt: 'Varo - read full story',
      },
      excerpt: 'A bank that builds financial health',
      url: '/customer-stories/varo',
    },
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Drop-Case-Study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
