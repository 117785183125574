import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';

const propTypes = {
  pClassName: PropTypes.string,
};

const Paragraph = ({ pClassName = '' }) => {
  return {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className={pClassName}>{children}</p>;
    },
  };
};

Paragraph.propTypes = propTypes;

export default Paragraph;
