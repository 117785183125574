import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CloseS1 from 'plaid-threads/Icons/CloseS1';

import { injectStyle } from 'react-toastify/dist/inject-style';
import styles from './Toast.module.scss';

interface CloseButtonProps {
  closeToast?: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ closeToast }) => {
  return (
    <button onClick={closeToast} type='button' className={styles.closeButton}>
      <CloseS1 />
    </button>
  );
};

// https://fkhadra.github.io/react-toastify/introduction
const Toast = (props) => {
  useEffect(() => {
    // inject react toast styles to avoid webpack errors
    injectStyle();
    if (props.openOnRender) {
      toast(props.message, {
        toastId: props?.id,
      });
    }
  });
  return <ToastContainer closeButton={<CloseButton />} {...props} />;
};

export default Toast;
