import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from 'src/components/Inputs';
import ObjectPropertyCard from './ObjectPropertyCard';
import MobileObjectPropertyCard from './MobileObjectPropertyCard';
import RunButtonIcon from 'src/components/SVGs/Icons/RunButtonIcon';

// helpers
import {
  withAnimatedResponse,
  WithAnimatedResponseShape,
  InteractiveElements,
} from 'src/components/Enhancers';
import useAnimationChain, {
  handleEvent,
  makeAnimationChain,
} from './useAnimateInteractiveData';

const propTypes = {
  pageClass: PropTypes.string,
  modifier: PropTypes.string,
  properties: PropTypes.array,
  testId: PropTypes.string,
  'bottom-cta-url': PropTypes.string,
  'bottom-cta-text': PropTypes.string,
  'secondary-cta-url': PropTypes.string,
  'secondary-cta-text': PropTypes.string,
  'secondary-cta-url-new-tab': PropTypes.string,
  'title-text': PropTypes.string,
  'description-text': PropTypes.string,
  'code-body': PropTypes.string,
  'code-title': PropTypes.string,
  'code-lang': PropTypes.string,
  'post-run-title-text': PropTypes.string,
  'post-run-description-text': PropTypes.string,
  'post-run-code-title': PropTypes.string,
  'post-run-code-body': PropTypes.string,
  'post-run-code-lang': PropTypes.string,
  style: PropTypes.string,
  withAnimatedResponse: WithAnimatedResponseShape.isRequired,
  stayTallHeight: PropTypes.number,
};

const InteractiveData = ({
  properties,
  withAnimatedResponse,
  style = '',
  stayTallHeight = 624,
  ...props
}) => {
  const interactiveDataNode = useRef(null);
  const parentContainer = useRef(null);
  const mobileParentContainer = useRef(null);
  const hasAnimated = useRef(false);
  const animationChain = useRef(makeAnimationChain({ stayTallHeight }));
  useAnimationChain({
    withAnimatedResponse,
    interactiveDataNode,
    parentContainer,
    mobileParentContainer,
    hasAnimated,
    animationChain,
  });

  return (
    <div
      ref={interactiveDataNode}
      className={props.pageClass}
      data-id='interactive-data'
      data-testid={props.testId}
    >
      <div
        className={cx(
          'product-data',
          'interactive-data',
          props.modifier && `interactive-data--${props.modifier}`,
        )}
      >
        <div
          data-id='interactive-data-container'
          className='grid-container interactive-data-container'
        >
          <div
            ref={parentContainer}
            className='grid-x align-justify show-for-large'
          >
            <div
              data-id='overview-text-container'
              className='cell medium-4 overview-text-container'
            >
              <h4 data-id='overview-text-title'>{props['title-text']}</h4>
              <p
                data-id='overview-text-description'
                dangerouslySetInnerHTML={{
                  __html: props['description-text'],
                }}
              ></p>
              <div data-id='object-properties' className='property-list'>
                {properties.map((item, i) => {
                  return (
                    <ObjectPropertyCard
                      key={`opc-${i}`}
                      index={i}
                      property={item}
                      handleEvent={withAnimatedResponse.handleEvent}
                      parentContainer={parentContainer}
                    />
                  );
                })}
              </div>
              {Boolean(props['bottom-cta-url']) && (
                <React.Fragment>
                  <div
                    data-id='button-cta-container'
                    className='button-cta-container button-cta-container--hide'
                  >
                    <Button href={props['bottom-cta-url']} fullWidth>
                      {props['bottom-cta-text']}
                    </Button>
                    {Boolean(props['secondary-cta-url']) && (
                      <React.Fragment>
                        <Button
                          href={props['secondary-cta-url']}
                          className='button'
                          secondary
                          fullWidth
                        >
                          {props['secondary-cta-text']}
                        </Button>
                      </React.Fragment>
                    )}
                    {props['secondary-cta-url-new-tab'] &&
                      !props['secondary-cta-url'] && (
                        <Button
                          href={props['secondary-cta-url-new-tab']}
                          target='_blank'
                          rel='noopener noreferrer'
                          className=' button'
                          secondary
                          fullWidth
                        >
                          {props['secondary-cta-text']}
                        </Button>
                      )}
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className='cell medium-1'></div>
            <div className='cell medium-7'>
              <div data-id='code-container' className='code-container'>
                <header className='code-container-header'>
                  <p
                    data-id='code-container-header-title'
                    className='code-container-header-title'
                  >
                    {props['code-title']}
                  </p>
                </header>
                <div className='code-container-body code-product-example'>
                  <div className='code-example is-active'>
                    <pre
                      className='line-numbers remove-line-numbers'
                      data-id='code-pre'
                    >
                      {' '}
                      <code
                        className={`language-${props['code-lang']}`}
                        data-id='code-block'
                      >
                        {props['code-body']}
                      </code>
                      <InteractiveElements
                        parentContainer={parentContainer}
                        properties={properties}
                        handleEvent={withAnimatedResponse.handleEvent}
                        isHidden
                      />
                    </pre>
                    <span
                      data-id='post-run-data'
                      data-title={props['post-run-title-text']}
                      data-text={props['post-run-description-text']}
                      data-code-title={props['post-run-code-title']}
                      data-code-body={props['post-run-code-body']}
                      data-code-lang={`language-${props['post-run-code-lang']}`}
                      style={{
                        display: 'none',
                      }}
                    ></span>
                  </div>
                </div>
                <span
                  onKeyDown={(e) => {
                    return handleEvent({
                      e,
                      parentContainer: interactiveDataNode.current,
                      withAnimatedResponse,
                      hasAnimated,
                      animationChain,
                    });
                  }}
                  data-id='run-interactive-data'
                  className='interactive-data-run-button'
                  onClick={(e) => {
                    return handleEvent({
                      e,
                      parentContainer: interactiveDataNode.current,
                      withAnimatedResponse,
                      hasAnimated,
                      animationChain,
                    });
                  }}
                  role='button'
                  tabIndex={0}
                >
                  <p>Run</p>
                  <span
                    data-id='run-button-gradient'
                    className='interactive-data-run-button__gradient interactive-data-run-button__gradient--hide'
                  ></span>
                  <span aria-hidden='true'>
                    <RunButtonIcon />
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className='grid-x align-justify hide-for-large'>
            <div
              data-id='overview-text-container'
              className='cell small-12 overview-container'
            >
              <h4 data-id='overview-text-title'>{props['title-text']}</h4>
              <p data-id='overview-text-description'>
                {props['description-text']}
              </p>
              <div data-id='code-container' className='code-container'>
                <header className='code-container-header'>
                  <p
                    data-id='code-container-header-title'
                    className='code-container-header-title'
                  >
                    {props['code-title']}
                  </p>
                </header>
                <div className='code-container-body code-product-example'>
                  <div className='code-example is-active'>
                    <pre className='line-numbers remove-line-numbers'>
                      <code className={`language-${props['code-lang']}`}>
                        {props['code-body']}
                      </code>
                      <InteractiveElements
                        parentContainer={parentContainer}
                        properties={properties}
                        handleEvent={withAnimatedResponse.handleEvent}
                        isHiddenOnMobile
                      />
                    </pre>
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={mobileParentContainer}
              data-id='overview-text-container'
              className='cell small-12'
            >
              <h4 data-id='overview-text-title'>
                {props['post-run-title-text']}
              </h4>
              <p
                data-id='overview-text-description'
                dangerouslySetInnerHTML={{
                  __html: props['post-run-description-text'],
                }}
              />
              <div
                data-id='code-container'
                className='code-container code-container--mobile-no-margin-bottom code-container--post-run-mobile'
              >
                <header className='code-container-header'>
                  <p
                    data-id='mobile-code-container-header-title'
                    className='code-container-header-title'
                  >
                    {props['post-run-code-title']}
                  </p>
                </header>
                <div className='code-container-body code-product-example'>
                  <div className='code-example is-active'>
                    <pre className='line-numbers' data-id='mobile-code-pre'>
                      <code
                        className={`language-${props['post-run-code-lang']}`}
                        data-id='mobile-code-block'
                      >
                        {`${props['post-run-code-body']}`}
                      </code>
                      <InteractiveElements
                        parentContainer={mobileParentContainer}
                        properties={properties}
                        handleEvent={withAnimatedResponse.handleEvent}
                        isMobile
                      />
                    </pre>
                  </div>
                </div>
              </div>
              <div className='mobile-object-property-description-container'>
                {properties.map((item, i) => {
                  return (
                    <MobileObjectPropertyCard
                      key={`mopc-${i}`}
                      index={i}
                      property={item}
                      handleEvent={withAnimatedResponse.handleEvent}
                      parentContainer={mobileParentContainer}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {style && <style jsx>{style}</style>}
    </div>
  );
};
InteractiveData.propTypes = propTypes;

export default withAnimatedResponse(InteractiveData);
