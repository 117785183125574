// Get Cookie function
const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
    ),
  );

  // usually a cookie value is encoded, so decode it.
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

// Get li_fat_id value
export const setLiFatId = () => {
  if (typeof window.li_fat_id === 'undefined') {
    window.li_fat_id =
      (typeof window !== 'undefined' &&
        new URLSearchParams(window.location.search).get('li_fat_id')) ||
      (typeof document !== 'undefined' && getCookie('li_fat_id')) ||
      '';
  }
};

// Generate uuid function
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Email Hash Function
export const hashData = async (value) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value.toLowerCase());
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  // Convert buffer to hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => {
      return b.toString(16).padStart(2, '0');
    })
    .join('');
  return hashHex;
};
