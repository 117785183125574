import React, { FC, useState } from 'react';
import cx from 'classnames';
import ThreadsButton from 'plaid-threads/Button';
import { useRouter } from 'next/router';

// contexts
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import { usePageLocale } from 'src/contexts';

// helpers
import { prependLocale, prependStagingHash } from 'src/lib/utils';

// styles
import styles from './Button.module.scss';

interface Classes {
  divClassName?: string;
  aClassName?: string;
  buttonClassName?: string;
}
interface Props {
  type?: 'submit' | 'reset' | 'button';
  value?: string;
  form?: string;
  href?: string;
  invertedColors?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  noMarginBottom?: boolean;
  noMinWidth?: boolean;
  'data-id'?: string;
  small?: boolean;
  centered?: boolean;
  classes?: Classes;
  onClick?: (event) => void;
  trackingId?: string;
  noHref?: boolean;
  target?: string;
  showLoader?: boolean;
  submitting?: boolean;
  animatedCaret?: boolean;
}

const COLOR_ARRAY = ['blue', 'green', 'pink', 'purple'];

const Button: FC<Props> = ({
  invertedColors = false,
  children,
  className = '', // NOTE: this is now deprecated in favor of classes.aClassName
  disabled = false,
  secondary = false,
  fullWidth = false,
  noMarginBottom = false,
  noMinWidth = false,
  inline = !fullWidth,
  onClick = () => undefined,
  trackingId = '',
  href = '',
  noHref = false,
  type = 'button',
  animatedCaret = true,
  ...props
}) => {
  const track = useTrack();
  const router = useRouter();
  const userLocale = usePageLocale();
  const locale = router?.locale || userLocale;
  const basePath = router?.basePath || '';

  const [color, setColor] = useState(null);
  const colorArray = COLOR_ARRAY;
  const [remainingColors, setRemainingColors] = useState(colorArray);

  const changeButtonColor = () => {
    if (remainingColors.length > 0) {
      const randomIndex = Math.floor(Math.random() * remainingColors.length);
      setColor(remainingColors[randomIndex]);
      setRemainingColors(
        remainingColors.filter((color, idx) => idx !== randomIndex),
      );
    } else {
      const randomIndex = Math.floor(Math.random() * colorArray.length);
      setColor(colorArray[randomIndex]);
      setRemainingColors(
        colorArray.filter((color, idx) => idx !== randomIndex),
      );
    }
  };

  const clearButtonColor = () => {
    return setColor(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    track({
      type: trackingActions.BUTTON_CLICKED,
      payload: { trackingId, event, currentTarget: event.currentTarget },
    });
    onClick(event);
  };

  const handleHref = () =>
    type === 'submit'
      ? ''
      : prependStagingHash(prependLocale({ locale, href }), basePath);

  return (
    <div
      onMouseEnter={() => {
        !disabled && changeButtonColor();
      }}
      onMouseLeave={() => clearButtonColor()}
      className={cx(
        styles.wrapper,
        'button-module-wrapper',
        secondary ? styles.outlinedButtonWrapper : styles.filledButtonWrapper,
        {
          [styles.invertedColorButtonWrapper]: invertedColors,
          [styles.fullWidthButtonWrapper]: fullWidth,
        },
        props?.classes?.divClassName,
        props?.classes?.buttonClassName,
      )}
      data-id={props['data-id']}
    >
      <ThreadsButton
        className={cx(
          styles[color],
          styles.threadsButton,
          noMarginBottom && styles.noMarginBottom,
          noMinWidth && styles.noMinWidthButton,
          !props.small && 'button',
          props?.classes?.aClassName || className,
        )}
        secondary={secondary}
        disabled={disabled}
        inline={inline}
        onClick={handleClick}
        animatedCaret={animatedCaret}
        type={type}
        href={noHref === true ? undefined : handleHref()}
        {...props}
      >
        {children}
      </ThreadsButton>
    </div>
  );
};

export default Button;
