import React from 'react';
import PropTypes from 'prop-types';

import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';
import Image from 'src/components/Image';

export const DiagramStepShape = {
  label: PropTypes.string.isRequired,
  'label-delay': PropTypes.number,
  'icon-delay': PropTypes.number,
  img: PropTypes.string.isRequired,
  'arrow-down': PropTypes.bool,
  'arrow-down-delay': PropTypes.number,
  'arrow-lateral': PropTypes.bool,
  'arrow-lateral-delay': PropTypes.number,
  'arrow-left': PropTypes.bool,
  'arrow-right': PropTypes.bool,
  badge: PropTypes.string,
};

const propTypes = DiagramStepShape;

const DiagramStep = (props) => (
  <React.Fragment>
    <div className='diagram__icon-container'>
      <div
        className={`
      diagram__icon-label
      ${animationStyles['animated-group__animation']}
      ${animationStyles['animated-group__animation--fade-in']}
      ${animationStyles['animated-group__animation--duration-300']}
      ${
        Boolean(props['label-delay'])
          ? `${
              animationStyles[
                `animated-group__animation--delay-${props['label-delay']}`
              ]
            }`
          : ''
      }
    `}
      >
        <div className='diagram__icon-label-content'>{props['label']}</div>
      </div>
      <div
        className={`
      diagram__icon
      ${animationStyles['animated-group__animation']}
      ${animationStyles['animated-group__animation--fade-in']}
      ${
        Boolean(props['icon-delay'])
          ? `${
              animationStyles[
                `animated-group__animation--delay-${props['icon-delay']}`
              ]
            }`
          : ''
      }
    `}
      >
        <Image
          className='diagram__icon-image'
          alt={props.label}
          src={props['img']}
        />
      </div>
      {Boolean(props['arrow-down']) && (
        <React.Fragment>
          <div
            className={`
            ${animationStyles['animated-group__animation']}
            ${animationStyles['animated-group__animation--slide-up-16']}
            ${
              Boolean(props['arrow-down-delay'])
                ? `${
                    animationStyles[
                      `animated-group__animation--delay-${props['arrow-down-delay']}`
                    ]
                  }`
                : ''
            }
          `}
          >
            <div className='diagram__arrow diagram__arrow'></div>
          </div>
        </React.Fragment>
      )}
      {Boolean(props['arrow-lateral']) && (
        <React.Fragment>
          <div
            className={`
          ${animationStyles['animated-group__animation']}
          ${animationStyles['animated-group__animation--fade-in']}
          ${animationStyles['animated-group__animation--duration-300']}
          ${
            Boolean(props['arrow-lateral-delay'])
              ? `${
                  animationStyles[
                    `animated-group__animation--delay-${props['arrow-lateral-delay']}`
                  ]
                }`
              : ''
          }
        }`}
          >
            {Boolean(props['arrow-left']) && (
              <React.Fragment>
                <div className='diagram__arrow diagram__arrow--left'></div>
              </React.Fragment>
            )}
            {Boolean(props['arrow-right']) && (
              <React.Fragment>
                <div className='diagram__arrow diagram__arrow--right'></div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
      {Boolean(props['badge']) && (
        <React.Fragment>
          <div
            className={`
          diagram__badge-${props['badge']}
          ${animationStyles['animated-group__animation']}
          ${animationStyles['animated-group__animation--slide-up-8']}
          ${animationStyles['animated-group__animation--duration-300']}
          ${
            Boolean(props['icon-delay'])
              ? `${
                  animationStyles[
                    `animated-group__animation--delay-${props['icon-delay']}`
                  ]
                }`
              : ''
          }
        `}
          />
        </React.Fragment>
      )}
    </div>
  </React.Fragment>
);

DiagramStep.propTypes = propTypes;
export default DiagramStep;
