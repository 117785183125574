import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';

const PartnerPageScripts = dynamic(
  () => {
    return import(
      'src/templates/PartnerPageTemplate/PartnerPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: TemplateFieldShape.isRequired,
};

const PartnerPageTemplate = ({ fields, ...props }) => {
  const { route } = useRouter();
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={false}
    >
      <div data-testid={ContentfulTemplates.PARTNER_PAGE}>
        <CmsComponentSwitch
          components={fields?.components}
          templatePage={ContentfulTemplates.PARTNER_PAGE}
        />
      </div>
      <PartnerPageScripts route={route} {...props} />
    </TemplateWrapper>
  );
};
PartnerPageTemplate.propTypes = propTypes;

export default PartnerPageTemplate;
