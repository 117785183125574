import React from 'react';

// components
import { Link } from 'src/components/Inputs';
import ArrowRight from 'src/components/SVGs/Icons/ArrowRight';

const NewsBanner = (props) => (
  <Link href={props['news-banner-url']}>
    <a
      className={`news-banner
      ${props['news-banner-modifier'] ? props['news-banner-modifier'] : ''}
      ${props.mobile ? 'hide-for-medium' : ''}`}
    >
      <span
        className={
          'news-banner-label label ' + props['news-banner-label-class']
        }
      >
        {props['news-banner-label-text']}
      </span>
      <p className='news-banner-description'>
        {props['news-banner-description']}
      </p>
      <span aria-hidden='true' className='news-banner-arrow'>
        <ArrowRight />
      </span>
    </a>
  </Link>
);

export default NewsBanner;
