import PropTypes from 'prop-types';

export const ImageShape = PropTypes.shape({
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
});

export const RichTextShape = PropTypes.shape({
  content: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  nodeType: PropTypes.string.isRequired,
});

export const TemplateFieldShape = PropTypes.shape({
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasFooterCta: PropTypes.bool,
  metaDescription: PropTypes.string.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaNoindex: PropTypes.bool,
});

export const ContentfulSystemMetadata = PropTypes.shape({
  contentType: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
});

const VideoFormatShape = PropTypes.shape({
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const VideoShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
  poster: PropTypes.string,
  formats: PropTypes.arrayOf(VideoFormatShape.isRequired),
  mobileImageSrc: PropTypes.string,
});

export const CredibilityQuoteTypes = {
  'cta-link': PropTypes.string.isRequired,
  'cta-text': PropTypes.string.isRequired,
  'logo-src-small': ImageShape,
  'quote-byline': PropTypes.string.isRequired,
  'quote-headline': PropTypes.string,
  'quote-label': PropTypes.string,
  'quote-text': PropTypes.string.isRequired,
  'show-quote-logo-asset': PropTypes.bool,
};

export const CtaExperimentShape = {
  sys: PropTypes.shape({
    id: PropTypes.string,
    contentType: PropTypes.shape({
      sys: PropTypes.shape({
        type: PropTypes.string,
        linkType: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
  fields: PropTypes.shape({
    experimentTitle: PropTypes.string,
    experimentId: PropTypes.string,
    experimentKey: PropTypes.string,
    meta: PropTypes.object.isRequired,
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        sys: PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.string,
        }),
        fields: PropTypes.shape({
          trackingId: PropTypes.string,
          button: PropTypes.shape({
            sys: PropTypes.shape({
              space: PropTypes.shape({
                sys: PropTypes.shape({
                  type: PropTypes.string,
                  linkType: PropTypes.string,
                  id: PropTypes.string,
                }),
              }),
              type: PropTypes.string,
              id: PropTypes.string,
              contentType: PropTypes.shape({
                sys: PropTypes.shape({
                  type: PropTypes.string,
                  linkType: PropTypes.string,
                  id: PropTypes.string,
                }),
              }),
            }),
            fields: PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
              url: PropTypes.string,
            }),
          }),
        }),
      }),
    ),
  }),
};
