import { FC, useState } from 'react';
import { Column, Grid } from 'src/components/Layout';
import ChevronUpS1 from 'plaid-threads/Icons/ChevronUpS1';
import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';

import styles from './SideDiscussion.module.scss';

export interface SideDiscussionProps {
  title: string;
}

const SideDiscussion: FC<SideDiscussionProps> = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.wrapper}>
      <Grid containerClassName={styles.sideDiscussion} noPadding>
        <Column>
          <button
            className={styles.discussionToggle}
            onClick={handleClick}
            aria-expanded={isExpanded}
            type='button'
          >
            <Grid noPadding className={styles.titleSection}>
              <Column
                medium={11}
                small={11}
                large={11}
                className={styles.title}
              >
                {title}
              </Column>
              <Column medium={1} className={styles.iconColumn}>
                <span className={styles.icon}>
                  {isExpanded ? <ChevronUpS1 /> : <ChevronDownS1 />}
                </span>
              </Column>
            </Grid>
          </button>
        </Column>
      </Grid>
      <Grid noPadding>
        <Column>
          <div
            className={styles.content}
            role='region'
            style={{
              display: isExpanded ? 'block' : 'none',
            }}
          >
            {children}
          </div>
        </Column>
      </Grid>
    </div>
  );
};

export default SideDiscussion;
