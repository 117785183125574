import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeaturesSection--cms.module.scss';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  hasThreeColumns: PropTypes.bool,
  classes: PropTypes.string,
  customPaddingTop: PropTypes.string,
  customPaddingBottom: PropTypes.string,
  uid: PropTypes.string,
};

const FeaturesSection = ({
  children,
  label = '',
  title = '',
  hasThreeColumns = false,
  hasBetaItems = false,
  classes = '',
  customPaddingTop = '',
  customPaddingBottom = '',
  uid = '',
}) => {
  return (
    <div
      className={cx(
        styles.wrapper,
        { [`featuresSection--${uid}`]: uid },
        classes,
      )}
      data-testid='featuresSection'
    >
      {customPaddingTop.length > 0 && (
        <style jsx data-testid='customPaddingTop'>{`
          .featuresSection--${uid} {
            padding-top: ${customPaddingTop};
          }
        `}</style>
      )}
      {customPaddingBottom.length > 0 && (
        <style jsx data-testid='customPaddingBottom'>{`
          .featuresSection--${uid} {
            padding-bottom: ${customPaddingBottom};
          }
        `}</style>
      )}
      <div className='grid-container'>
        <div className='grid-x'>
          {label && (
            <div className='cell small-12'>
              <div className={styles.headerWrapper}>
                <h6
                  className={cx(
                    styles.label,
                    hasBetaItems && styles.labelWithBetaItems,
                  )}
                >
                  {label}
                </h6>
              </div>
            </div>
          )}
          {title && (
            <div className='cell small-12'>
              <div className={styles.title}>{<h4>{title}</h4>}</div>
            </div>
          )}
        </div>
        <div
          className={`
          grid-x
          grid-margin-x
          feature-section-items-${hasThreeColumns ? 3 : 2}
        `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

FeaturesSection.propTypes = propTypes;
export default FeaturesSection;
