import React from 'react';

const TwitterWhiteIcon = () => {
  return (
    <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M42 8a24 24 0 107 17 24 24 0 00-7-17zm-7.25 12.09v.64a13.43 13.43 0 01-.33 3 14 14 0 01-14 11.08 14.45 14.45 0 01-7.71-2.22 12.09 12.09 0 001.21.07 10.17 10.17 0 006.23-2.12 5.06 5.06 0 01-4.69-3.45 4.17 4.17 0 00.94.11 4.91 4.91 0 001.37-.2 5 5 0 01-3.69-3.06 4.56 4.56 0 01-.35-1.78v-.06a5 5 0 002.27.61 4.94 4.94 0 01-2.23-4.12 5.06 5.06 0 01.69-2.49 14.25 14.25 0 0010.37 5.17 5.14 5.14 0 01-.15-1.13 5.05 5.05 0 018.71-3.37 10.39 10.39 0 003.2-1.21 5 5 0 01-2.22 2.75 10.48 10.48 0 002.88-.79 10.18 10.18 0 01-2.5 2.57z'
        fill='#fff'
      />
    </svg>
  );
};

export default TwitterWhiteIcon;
