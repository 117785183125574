import React from 'react';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { ConditionalWrapper } from 'src/components/helpers';
import { MediaObject } from 'src/components/Layout';
import { BetaLabel } from 'src/components/Surfaces/Label';
import { RichTextRenderer } from '../RichTextRenderer';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

// helpers
import { RichTextShape } from 'src/lib/prop-types';

// styles
import styles from './FeaturesSectionItem.module.scss';

const ASSET_SIZES = ['48x48', '112x112', '240x240'];

const propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([RichTextShape.isRequired, PropTypes.node]),
  assetSize: PropTypes.oneOf(ASSET_SIZES),
  isBetaItem: PropTypes.bool,
  hasThreeColumns: PropTypes.bool,
  hasBetaItems: PropTypes.bool,
  cta: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  hasSimpleContent: PropTypes.bool,
  hasConstrainedContent: PropTypes.bool,
  hasConstrainedImage: PropTypes.bool,
  hasSeparator: PropTypes.bool,
  isSingleRow: PropTypes.bool,
};

const FeatureSectionItemBody = ({
  title,
  isBetaItem,
  hasSimpleContent = false,
  hasConstrainedContent = false,
  content,
  hasCta,
  cta,
}) => {
  return (
    <>
      {isBetaItem && (
        <div
          className={styles.betaFeatureLabel}
          data-testid='beta-feature-label'
        >
          <BetaLabel />
        </div>
      )}
      <h5 className={styles.title}>{title}</h5>
      {hasSimpleContent ? (
        <div
          className={cx(styles.description, {
            [styles.contentIsConstrained]: hasConstrainedContent,
          })}
        >
          {content}
        </div>
      ) : (
        <RichTextRenderer
          content={content}
          classes={{
            pClassName: `${styles.description} feature-description`,
          }}
        />
      )}
      {hasCta && (
        <span className='feature-section-link-text'>
          {cta.text} <ArrowRightSingleSmall />
        </span>
      )}
    </>
  );
};

const FeaturesSectionItem = ({
  img = {},
  isBetaItem = false,
  hasBetaItems = false,
  hasThreeColumns = false,
  cta = { href: '', text: '' },
  hasConstrainedImage = false,
  hasSeparator = false,
  isSingleRow = false,
  assetSize = '112x112',
  ...props
}) => {
  const hasImage = img?.src && img?.alt;
  const hasCta = cta?.href && cta?.text;

  return (
    <div
      className={cx('cell', 'small-12', `medium-${hasThreeColumns ? 4 : 6}`, {
        [styles[`size-${assetSize}`]]: assetSize,
      })}
      data-testid='features-section-item-column'
    >
      <ConditionalWrapper
        condition={hasCta}
        wrapper={(children) => (
          <Link href={cta.href}>
            <a data-testid='anchor-wrapper'>{children}</a>
          </Link>
        )}
      >
        <div
          className={cx(
            styles.item,
            isBetaItem && styles.betaItem,
            hasBetaItems && styles.itemWithBetaItems,
            !hasThreeColumns && styles.twoColumns,
            isSingleRow && styles.singleRow,
          )}
        >
          {hasSeparator && <div className='separator mb1'></div>}
          {hasImage ? (
            <MediaObject
              figure={
                <Image
                  className={cx(styles.image, `feature-image`, {
                    [styles.imageIsConstrained]: hasConstrainedImage,
                  })}
                  src={img.src}
                  alt={img.alt}
                />
              }
              body={
                <FeatureSectionItemBody
                  isBetaItem={isBetaItem}
                  hasCta={hasCta}
                  cta={cta}
                  {...props}
                />
              }
              canStackInDesktop={hasThreeColumns}
            />
          ) : (
            <FeatureSectionItemBody
              isBetaItem={isBetaItem}
              hasCta={hasCta}
              cta={cta}
              {...props}
            />
          )}
        </div>
      </ConditionalWrapper>
    </div>
  );
};

FeaturesSectionItem.propTypes = propTypes;
export default FeaturesSectionItem;
