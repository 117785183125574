/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { formatUrl } from 'src/lib/utils';
import { ButtonTypes } from 'src/lib/constants';

/**
 * Maps the contentful response for mvp page header content type to the view
 *
 * @param {Object} args
 * @param {Object} args.label
 * @param {string} args.heading
 * @param {Object[]} args.ctaButtons
 * @param {string} args.primaryImage
 * @param {Object[]} args.primaryVideo
 * @param {Object} args.secondaryImage
 * @param {Object[]} args.secondaryVideo
 * @param {Boolean} args.isBetaFeature
 * @returns {Object}
 */

const productsPageHeaderAdapter = ({
  label,
  heading,
  ctaButtons = [],
  primaryImage,
  primaryVideo,
  secondaryImage,
  secondaryVideo,
  isBetaFeature,
}) => {
  const primaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );

  return {
    label,
    isBetaFeature,
    description: heading,
    /* hero one */
    'hero-one-poster-url': _get(primaryImage, 'fields.file.url'),
    'hero-one-poster-fallback-url': _get(primaryImage, 'fields.file.url'),
    'hero-one-video-url':
      primaryVideo && formatUrl(primaryVideo[0].fields.file.url),
    'hero-one-video-fallback-url':
      primaryVideo && formatUrl(primaryVideo[1].fields.file.url),
    /* hero two */
    'hero-two-poster-fallback-url': _get(secondaryImage, 'fields.file.url'),
    'hero-two-video-url':
      secondaryVideo && formatUrl(secondaryVideo[0].fields.file.url),
    'hero-two-video-fallback-url':
      secondaryVideo && formatUrl(secondaryVideo[1].fields.file.url),
    /* CTAs */
    'top-cta-text': _get(primaryCta, 'fields.text'),
    'top-cta-url': _get(primaryCta, 'fields.url'),
    'bottom-cta-text': _get(secondaryCta, 'fields.text'),
    'bottom-cta-url': _get(secondaryCta, 'fields.url'),
    hasImagePrimaryOnly: Boolean(!primaryVideo && primaryImage),
    hasImageSecondaryOnly: Boolean(!secondaryVideo && secondaryImage),
  };
};

export default productsPageHeaderAdapter;
