import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

export enum MenuLinkVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface MenuLinkProps {
  variant: 'primary' | 'secondary' | 'tertiary';
  title: string;
  description?: string;
  href: string;
  sx?: SxProps<Theme>;
  trackingId?: string;
  onFocus?(): void;
}
