import React, { FC } from 'react';

// components
import { FormSectionTextInput } from '../FormSectionTextInput';

import { FormSectionNameFieldsProps } from './FormSectionNameFields.types';

const FormSectionNameFields: FC<FormSectionNameFieldsProps> = ({
  formData,
  register,
  errors,
}) => {
  return (
    <div className=' full'>
      <div className='grid-x form-group full'>
        <div className='cell small-12 medium-6'>
          <FormSectionTextInput
            data={formData.firstName}
            register={register}
            errors={errors}
            required
          />
        </div>
        <div className='cell small-12 medium-6'>
          <FormSectionTextInput
            data={formData.lastName}
            register={register}
            errors={errors}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default FormSectionNameFields;
