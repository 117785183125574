import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from 'src/components/Inputs';
import { CtaShape } from 'src/components/headers/MvpPageHeader/CtaSection/CtaSection';

import styles from './Footer.module.scss';
import { optimizeImage } from 'src/lib/image-helpers';

const propTypes = {
  cta: CtaShape,
  className: PropTypes.string,
  footerTitle: PropTypes.string.isRequired,
  backgroundImageBottomLeft: PropTypes.string,
  backgroundImageTopRight: PropTypes.string,
};

// NOTE: This component is also being used, with a thin wrapper layer, in the
// LandingPageFooter component, since the desired functionality for both is
// the same at present. Later, it may make sense to split them, but it seems
// premature to do so at this time.

const Footer = ({
  cta,
  className,
  backgroundImageBottomLeft,
  backgroundImageTopRight,
  footerTitle,
}) => {
  return (
    <div
      className={cx(styles.partnerPageFooter, className)}
      style={{
        backgroundImage:
          backgroundImageBottomLeft && backgroundImageTopRight
            ? `url('${optimizeImage(
                backgroundImageBottomLeft,
              )}'),url('${optimizeImage(backgroundImageTopRight)}')`
            : 'none',
      }}
    >
      <div className='grid-container'>
        <div className='grid-x grid-margin-x'>
          <div className='cell small-12 medium-4 large-6'>
            <h3 className={styles.title}>{footerTitle}</h3>
          </div>
          <div className='cell medium-2 show-for-medium-only'></div>
          <div className='cell small-12 medium-6'>
            <div className='grid-x grid-margin-x'>
              {cta.hasPrimaryCta && (
                <div
                  className={cx(
                    'cell small-12 medium-12 large-6',
                    styles.buttonGroup,
                  )}
                >
                  <Button
                    href={cta.primaryCtaLink}
                    className={'button-cta'}
                    fullWidth
                  >
                    {cta.primaryCtaText}
                  </Button>
                </div>
              )}
              {cta.hasSecondaryCta && (
                <div
                  className={cx(
                    'cell small-12 medium-12 large-6',
                    styles.buttonGroup,
                  )}
                >
                  <Button
                    href={cta.secondaryCtaLink}
                    className={'button-cta'}
                    fullWidth
                    secondary
                  >
                    {cta.secondaryCtaText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = propTypes;

export default Footer;
