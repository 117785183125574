import React, { useRef } from 'react';
import cx from 'classnames';

// helpers
import { Property, HandleEvent } from './StaticData.types';
import { DeviceContext } from 'src/contexts';

//components
import Checkmark from 'src/components/SVGs/Icons/Checkmark';

interface Props {
  index: number;
  property: Property;
  handleEvent: HandleEvent;
  parentContainer: HTMLDivElement;
}

const MobileObjectPropertyCard: React.FC<Props> = ({
  index,
  property,
  handleEvent,
  parentContainer,
}) => {
  const currentElement = useRef(null);
  return (
    <DeviceContext.Consumer>
      {(device) => (
        <div
          ref={currentElement}
          onClick={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          onKeyDown={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          role='button'
          tabIndex={0}
          key={`property-${index}`}
          data-id={`mobile-object-property-${property.title}`}
          data-code-line={property['code-line-start']}
          data-code-length={property['code-highlight-length']}
          className={cx(
            'interactive-data-description interactive-data-description--is-mobile',
            {
              'interactive-data-description--is-selected': index === 0,
            },
          )}
        >
          <span className='interactive-data-description--title-container'>
            <span className='check-icon'>
              <Checkmark />
            </span>
            <p className='interactive-data-title'>{property.title}</p>
          </span>
          <p className='interactive-data-desc'>{property.description}</p>
        </div>
      )}
    </DeviceContext.Consumer>
  );
};

export default MobileObjectPropertyCard;
