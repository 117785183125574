import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

export enum MenuItemVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface MenuItemProps {
  title: string;
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
  index?: number;
  isMobile?: boolean;
  isActive?: boolean;
  activeIndex?: number;
  onClick?(): void;
  onShow?(): void;
  onHide?(): void;
  sx?: SxProps<Theme>;
}
