import React, { FC } from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Image, AnimatedVideo, VideoPlayer } from 'src/components-v2/Media';
import { isProduction } from 'src/lib/constants';
import { Box } from 'src/components-v2/Layout';
import { CmsMediaSwitchProps } from './CmsMediaSwitch.types';
import { byVideoType } from 'src/lib/utils';
import { PlayButton } from 'src/components-v2/Inputs';
import { Icon } from 'src/components-v2/DataDisplay';

const CmsMediaSwitch: FC<CmsMediaSwitchProps> = ({
  asset,
  queryString = {
    xs: '',
    sm: '',
    md: '',
    lg: '',
  },
  width,
  height,
  sys,
  sx,
}) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });

  switch (asset?.appearance) {
    case 'Image':
      const lgImgSrc = 'https:' + asset?.largeImage?.fields?.file?.url;
      const mdImgSrc = asset?.mediumImage
        ? 'https:' + asset?.mediumImage?.fields?.file?.url
        : lgImgSrc;
      const smImgSrc = asset?.smallImage
        ? 'https:' + asset?.smallImage?.fields?.file?.url
        : mdImgSrc;
      return (
        <Image
          isLazy={asset?.isLazy || false}
          sx={{ ...sx, ...asset?.styles }}
          width={asset?.width || width}
          height={asset?.height || height}
          src={lgImgSrc + queryString.lg}
          alt={asset?.description}
          sources={[
            {
              media: '(max-width: 412px)',
              srcSet: smImgSrc + queryString.xs,
            },
            {
              media: '(min-width: 413px) and (max-width: 639px)',
              srcSet: smImgSrc + queryString.sm,
            },
            {
              media: '(min-width: 640px) and (max-width: 1023px)',
              srcSet: mdImgSrc + queryString.md,
            },
            {
              media: '(min-width: 1024px)',
              srcSet: lgImgSrc + queryString.lg,
            },
          ]}
          {...inspectorProps({ fieldId: 'internalTitle' })}
        />
      );
    case 'Video':
      const sortedVideos = asset?.video?.sort(byVideoType);
      return (
        <AnimatedVideo
          isLazy={asset?.isLazy || false}
          width={asset?.width || width}
          height={asset?.height || height}
          sx={{ ...sx, ...asset?.styles }}
          sources={sortedVideos?.map((asset) => {
            return {
              src: asset?.fields?.file?.url,
              type: asset?.fields?.file?.contentType,
            };
          })}
          {...inspectorProps({ fieldId: 'internalTitle' })}
        />
      );
    case 'Lottie':
      const src = 'https:' + asset?.lottie?.fields?.file?.url;
      return (
        <Box
          sx={{ ...sx, ...asset?.styles }}
          {...inspectorProps({ fieldId: 'internalTitle' })}
        >
          <Player
            src={src}
            autoplay={true}
            loop={true}
            style={{
              width: asset?.width || width || '100%',
              height: asset?.height || height || 'auto',
              margin: '0 auto',
            }}
          />
        </Box>
      );
    case 'Embedded':
      const coverImg = asset?.coverImage?.fields?.file?.url;

      return (
        <VideoPlayer
          sx={{ ...sx, ...asset?.styles }}
          url={asset?.url}
          light={coverImg ? `${coverImg + '?fm=webp&w=1364&q=60'}` : true}
          playing={true}
          playIcon={
            <PlayButton
              playIcon={<Icon icon='ChevronRight' />}
              sx={{
                '& svg': {
                  zoom: 2,
                },
                '&:hover, :focus': {
                  '& svg': {
                    color: 'white',
                  },
                  backgroundColor: 'black',
                },
              }}
            />
          }
          {...inspectorProps({ fieldId: 'internalTitle' })}
        />
      );

    default:
      if (!isProduction) {
        return (
          <>
            <Image
              width={600}
              height={400}
              src='https://placehold.co/600x400'
              alt={asset?.description}
              {...inspectorProps({ fieldId: 'internalTitle' })}
            />
          </>
        );
      } else {
        return null;
      }
  }
};

export default CmsMediaSwitch;
