import React, { FC } from 'react';
import { Button } from 'src/components/Inputs';
import styles from './Tag.module.scss';

interface Props {
  tag: string;
  href: string;
}

const Tag: FC<Props> = ({ tag, href }) => (
  <div className={styles.blogPostTagWrapper}>
    <Button
      className={styles.blogPostTag}
      href={href}
      small
      inline
      centered
      secondary
    >
      {tag}
    </Button>
  </div>
);

export default Tag;
