export const intro = {
  title: 'Richtlinie für Entwickler',
  effectiveDate: `Gültigkeitsdatum: 31. Januar 2022<br><br>
    <em>Vor dem 31. Januar 2022 gilt weiterhin die vorherige Version 2019 der Plaid-Richtlinie für Entwickler.</em>`,
  description: [
    `Diese Richtlinie für Entwickler (die „Richtlinie“) legt Regeln und Richtlinien fest, die den Zugang oder die Nutzung der Plaid-API, der Websites (die „Website“), der Dashboards, der zugehörigen Tools und anderer Produkte oder Dienstleistungen (zusammen die „Dienste“) regeln, welche von Plaid Inc. und seinen Tochtergesellschaften, einschließlich Plaid Financial Ltd. und Plaid, B.V. („Plaid“, „wir“, „unser“ und „uns“) bereitgestellt werden. Ein Verstoß gegen diese Richtlinie kann zur Aussetzung oder Beendigung Ihres Zugangs zu den Diensten und/oder Zugangs zu den von Plaid bereitgestellten personenbezogenen und finanziellen Daten der Endnutzer (die „Endnutzerdaten“) führen`,
    `Mit Ihrem Zugriff auf die Dienste und deren Nutzung erklären Sie sich damit einverstanden, alle Bestimmungen dieser Richtlinie einzuhalten. Diese Richtlinie gilt jedes Mal, wenn Sie die Dienste aufrufen oder nutzen. Falls Sie im Namen einer Organisation oder einer juristischen Person den Bedingungen dieser Richtlinie zustimmen, sichern Sie zu, dass Sie berechtigt sind, im Namen dieser Organisation oder juristischen Person zuzustimmen. Diese Richtlinie ist wichtig; bitte lesen Sie sie sorgfältig.`,
    `Wir sind berechtigt, diese Richtlinie jederzeit nach eigenem Ermessen zu aktualisieren oder zu ändern. Wenn wir Änderungen an der Richtlinie vornehmen, die unserer Ansicht nach wesentlich sind, werden wir uns in angemessener Weise bemühen, Sie über diese Änderungen in Kenntnis zu setzen. Wenn Sie mit einer Änderung nicht einverstanden sind, besteht Ihre einzige Abhilfe darin, den Zugang zu den Diensten und deren Nutzung einzustellen.`,
  ],
};

export const tableOfContentsTitle = 'Zum Abschnitt springen:';

export const sections = [
  {
    title: 'Registrierung',
    description: [
      `Wenn Sie sich für die Dienste anmelden möchten, müssen Sie ein Konto erstellen (das „Konto“), indem Sie sich auf unserer Website registrieren und wahrheitsgemäße, genaue und vollständige Angaben zu Ihrer Person und Ihrer Nutzung der Dienste machen. Sie verpflichten sich, keine falschen Angaben zu Ihrer Identität oder zu den Informationen, die Sie für Ihr Konto angeben, zu machen und Ihre Kontoinformationen stets auf dem neuesten Stand zu halten. Es ist Ihre Verantwortung, den Zugang zu Ihrem Konto aufrechtzuerhalten. Sie dürfen Ihre Kontoinformationen, einschließlich Ihres Plaid Dashboard-Passworts, sowie Ihre API-Authentifizierungsdaten, einschließlich Ihrer Kundenidentifikationsnummer (die „Kunden-ID“) und Ihres Geheimnisses, niemals an Dritte weitergeben oder zulassen, dass eine andere Anwendung oder ein anderer Dienst in Ihrem Namen handelt.`,
    ],
  },
  {
    title: 'Befolgung der anwendbaren Gesetze',
    description: [
      `Wenn Sie die Dienste nutzen, erklären Sie sich damit einverstanden, alle anwendbaren lokalen, bundesstaatlichen, nationalen und internationalen Gesetze zu befolgen. Darüber hinaus bestätigen Sie, dass Sie, Ihr Unternehmen, Ihre Mitarbeiter, Ihre Dienstleister und alle anderen, die in Ihrem Namen handeln, alle anwendbaren Gesetze befolgen, insbesondere solche, die sich auf Finanzdaten, Datenschutz, Privatsphäre und Datensicherheit beziehen.`,
      `Des Weiteren bestätigen Sie, dass Sie, Ihre Führungskräfte, Direktoren, Aktionäre, direkten und indirekten Muttergesellschaften, Tochtergesellschaften und verbundenen Unternehmen:`,
      [
        `alle anwendbaren Import-, Re-Import-, Sanktions-, Anti-Boykott-, Export- und Re-Export-Kontrollgesetze und -vorschriften (darunter auch alle Gesetze und Vorschriften, die für ein US-Unternehmen gelten, wie z. B. die Export Administration Regulations, die International Traffic in Arms Regulations und die vom Office of Foreign Assets Control (OFAC) umgesetzten Wirtschaftssanktionsprogramme) befolgen und befolgen werden`,
        `keinen Sanktionen unterliegen oder im Eigentum von Parteien stehen, die Sanktionen unterliegen oder anderweitig auf einer sanktionsbezogenen Liste aufgeführt sind, beispielsweise auf der von der US-Regierung geführten Liste der „Specially Designated Nationals and Blocked Persons“ (OFAC), der vom Bureau of Industry and Security des US-Handelsministeriums geführten „Entity List“ und der vom US-Außenministerium geführten „CAATSA Section 231(d)“-Liste, einer Liste des Sicherheitsrats der Vereinten Nationen, des Vereinigten Königreichs, der Europäischen Union oder ihrer Mitgliedstaaten oder einer anderen zuständigen Regierungsbehörde, und`,
        `sich weder jetzt noch in Zukunft an Aktivitäten beteiligen, die es erforderlich machen oder ermöglichen, dass eine zuständige Regierungsbehörde eine Vollstreckungsmaßnahme gegen Sie oder uns ergreift oder wirtschaftliche Sanktionen gegen uns verhängt.`,
      ],
      `Die unmittelbar vorstehend genannten Bestätigungen werden nicht verlangt und nicht abgegeben, wenn und soweit das Verlangen oder die Bestätigung einen Verstoß gegen das EU-Sperrgesetz, gegen Gesetze oder Verordnungen zur Umsetzung des EU-Sperrgesetzes in den EU-Mitgliedstaaten oder im Vereinigten Königreich oder gegen ähnliche Anti-Boykott-, Nicht-Diskriminierungs- oder Sperrbestimmungen, die im Rahmen der anwendbaren lokalen Gesetze vorgesehen sind, darstellen würde.`,
      `Sie sind allein dafür verantwortlich, dass Ihre Nutzung der Dienste im Einklang mit allen auf Sie anwendbaren Gesetzen steht, insbesondere mit den Regeln und Richtlinien aller Systeme oder Netzwerke, die Zahlungen ermöglichen, sowie mit allen Sicherheitsanforderungen, einschließlich der Payment Card Industry Data Security Standards (PCI-DSS), soweit diese auf Sie anwendbar sind.`,
    ],
  },
  {
    title: 'Sicherheit',
    description: [
      `Für die sichere Aufbewahrung Ihres Plaid Dashboard-Benutzernamens und -Passworts sowie Ihrer API-Authentifizierungsdaten, einschließlich Ihrer Kunden-ID und Ihres Geheimnisses, sind Sie selbst verantwortlich. Für den Fall einer Verletzung der Sicherheit oder einer unbefugten Nutzung Ihres Kontos oder von Endnutzerdaten verpflichten Sie sich, uns unverzüglich unter security@plaid.com zu benachrichtigen. Sie dürfen Ihre Kunden-ID und Ihr Geheimnis niemals veröffentlichen, teilen oder weitergeben und müssen diese Informationen bei der Speicherung und während der Übertragung verschlüsseln.`,
      `Ihre Systeme und Anwendungen müssen die Endnutzerdaten sicher behandeln. In Bezug auf Endnutzerdaten sollten Sie bewährte Praktiken der Branche befolgen, müssen aber mindestens die folgenden Maßnahmen ergreifen:`,
      [
        `Treffen Sie administrative, technische und physische Sicherheitsvorkehrungen, um die Sicherheit, den Datenschutz und die Vertraulichkeit von Endnutzerdaten zu gewährleisten.`,
        `Setzen Sie bei der Speicherung oder Übertragung von Endnutzerdaten moderne und branchenübliche Verschlüsselungsverfahren ein.`,
        `Führen Sie angemessene Zugangskontrollen durch, um den Zugriff auf Endnutzerdaten auf autorisierte Personen zu beschränken, die dafür eine geschäftliche Notwendigkeit haben.`,
        `Überwachen Sie Ihre Systeme auf unbefugten Zugriff.`,
        `Schließen Sie rechtzeitig etwaige Sicherheitslücken.`,
        `Protokollieren und überprüfen Sie alle Ereignisse, die auf einen unbefugten Zugriff hinweisen.`,
        `Planen Sie Sicherheitszwischenfälle und Ihre Reaktion darauf.`,
        `Halten Sie die einschlägigen Regeln und Vorschriften in Bezug auf die Art der von Ihnen gehandhabten Daten ein, wie z. B. die Safeguards Rule des Graham-Leach-Bliley Act.`,
      ],
    ],
  },
  {
    title: 'Datenspeicherung und -verwendung',
    description: [
      `Alle Endnutzerdaten, die sich in Ihrem Besitz befinden, müssen sicher und unter Beachtung der anwendbaren Gesetze aufbewahrt werden. Sollten Sie Endnutzerdaten in anonymisierter Form verwenden, müssen Sie den Endnutzer klar und deutlich darauf hinweisen, einschließlich einer Beschreibung, wie Sie diese anonymisierten Endnutzerdaten verwenden.`,
    ],
  },
  {
    title: 'Deaktivierung des Kontos',
    description: [
      `Wenn Sie die Dienste nicht mehr gemäß der geltenden Vereinbarung mit uns nutzen, können Sie Ihr Konto anhand der Anweisungen auf der Website deaktivieren. Wir können Ihr Konto auch deaktivieren, wenn Sie die Dienste drei Monate lang nicht mehr genutzt haben, wenn Ihre Vereinbarung mit uns gekündigt wird oder ausläuft oder wenn dies nach dem anwendbaren Recht angemessen ist. Nach der Deaktivierung Ihres Kontos werden wir Ihnen den Zugriff auf alle mit Ihrer Integration verbundenen Endnutzerdaten entziehen.`,
      `Selbst nach der Deaktivierung Ihres Kontos können wir, soweit dies gesetzlich zulässig ist, die von uns über Sie erfassten Daten so lange aufbewahren, wie es für die Erfüllung der in unserer Datenschutzrichtlinie/-erklärung genannten Zwecke erforderlich ist, oder noch länger, wenn dies nach dem anwendbaren Recht erforderlich oder zulässig ist.`,
    ],
  },
  {
    title: 'Verbotenes Verhalten',
    description: [
      `Sie verpflichten sich, Folgendes zu unterlassen und auch Dritte nicht dabei zu unterstützen:`,
      [
        `Endnutzerdaten an Vermarkter oder andere Dritte zu verkaufen oder zu vermieten`,
        `auf die Dienste oder Endnutzerdaten für ungesetzliche, verletzende, bedrohende, missbräuchliche, obszöne, belästigende, verleumderische, täuschende oder betrügerische Zwecke zuzugreifen oder diese zu nutzen`,
        `die Bankdaten von Endnutzern und/oder Endnutzerdaten zu erfassen und zu speichern, es sei denn, dies ist für den Zugang zu den Diensten oder deren Nutzung erforderlich, vom Endnutzer gestattet, von Plaid erlaubt oder nach dem anwendbaren Recht erlaubt`,
        `„nicht-öffentliche personenbezogene Daten“ (laut Definition im Gramm-Leach-Bliley Act) oder „personenbezogene Daten“ (laut Definition im California Consumer Privacy Act) zu verwenden, offenzulegen oder aufzubewahren, es sei denn, dies geschieht unter strikter Einhaltung des anwendbaren Rechts`,
        `„personenbezogene Daten“ (wie in der Verordnung (EU) 2016/679 [Datenschutz-Grundverordnung] definiert) zu verwenden, offenzulegen oder anderweitig zu verarbeiten, es sei denn, dies geschieht unter strikter Einhaltung des anwendbaren Rechts`,
        `auf die Dienste zuzugreifen oder diese zu nutzen oder auf Endnutzerdaten zuzugreifen, diese zu übermitteln, zu verarbeiten oder zu speichern, wenn dies gegen einschlägige Datenschutzgesetze verstoßen oder eine Verletzung des Vertrags oder der Vereinbarung mit dem jeweiligen Endnutzer darstellen würde`,
        `auf die Dienste zuzugreifen oder diese zu nutzen, um ein Patent, eine Marke, ein Geschäftsgeheimnis, ein Urheberrecht, ein Veröffentlichungsrecht oder ein anderes Recht einer natürlichen oder juristischen Person zu verletzen`,
        `zu einem anderen als dem von uns vorgesehenen Zweck auf die Dienste zuzugreifen oder diese zu nutzen, einschließlich zu Zwecken der Wettbewerbsanalyse, des Ausspionierens, der Schaffung eines Ersatzprodukts oder eines ähnlichen Angebots zu den Diensten oder zu anderen unlauteren Zwecken`,
        `die Schwachstellen der Infrastruktur von Plaid ohne ausdrückliche vorherige schriftliche Genehmigung von Plaid zu scannen oder zu testen (manuell oder auf automatisierte Weise)`,
        `Sicherheits- oder Authentifizierungsmaßnahmen oder andere Aspekte der Dienste zu verletzen, zu deaktivieren, zu stören oder anderweitig zu umgehen`,
        `die Dienste ganz oder teilweise zu überlasten, zu überschwemmen oder zu spammen`,
        `Entwicklerkonten für die Dienste auf andere Weise als über unsere öffentlich unterstützten Schnittstellen zu erstellen (z. B. durch die automatische Erstellung von Entwicklerkonten oder auf andere Weise in großen Mengen)`,
        `die Dienste oder Endnutzerdaten ohne ausdrückliche vorherige schriftliche Genehmigung von Plaid zu übertragen, zu syndizieren oder anderweitig zu verbreiten`,
        `den Quellcode oder die zugrundeliegenden Ideen oder Algorithmen der Dienste zu entschlüsseln, zu dekompilieren, zu disassemblieren, zu kopieren, zurückzuentwickeln oder abzuleiten, es sei denn, dies ist nach anwendbarem Recht zulässig`,
        `die Dienste zu modifizieren, zu übersetzen oder auf andere Weise Bearbeitungen davon zu erstellen`,
        `auf eine Weise auf die Dienste oder Endnutzerdaten zuzugreifen oder diese zu nutzen, die gegen eine Vereinbarung zwischen Ihnen oder dem Endnutzer und Plaid verstößt, oder`,
        `auf die Dienste oder Endnutzerdaten in einer Weise zuzugreifen oder diese zu nutzen, die gegen ein anwendbares Gesetz, ein Regelwerk, eine Verordnung oder eine Vorschrift verstößt`,
      ],
    ],
  },
  {
    title: 'Aussetzung und Beendigung',
    description: [
      `Wir behalten uns das Recht vor, den Zugang zu den Diensten und/oder Endnutzerdaten ganz oder teilweise zu verweigern oder zu beenden, wenn wir glauben, dass der Zugang zu den Diensten oder deren Nutzung gegen diese Richtlinie oder eine andere Vereinbarung von Plaid verstößt, einschließlich der Vereinbarungen von Plaid mit Drittpartnern oder Datenquellen von Plaid (jeweils ein „Partner“), oder wenn die Nutzung das Risiko eines Schadens, einschließlich eines Imageschadens, für Plaid, seine Infrastruktur, seine Daten, die Dienste, einen Endnutzer oder einen Partner bedeuten würde.`,
      `Falls wir beschließen, den Zugang zu den Diensten und/oder Endnutzerdaten zu sperren, zu verweigern oder zu beenden, werden wir uns in angemessener Weise bemühen, Sie per E-Mail oder auf anderem Wege zu informieren. Wir können den Zugang sofort und ohne Vorankündigung aussetzen oder beenden, wenn die Umstände dies erfordern, z. B. wenn wir von Aktivitäten Kenntnis erlangen, die gegen ein anwendbares Gesetz verstoßen, oder wenn wir nach eigenem Ermessen feststellen, dass ein Schaden droht.`,
      `Plaid haftet nicht für Schäden irgendwelcher Art, die Ihnen oder Dritten durch die Wahrnehmung der Rechte von Plaid aus dieser Richtlinie oder nach anwendbarem Recht entstehen.`,
    ],
  },
  {
    title: 'Meldung von Verstößen',
    description: [
      `Sollten Sie von einem Verstoß gegen diese Richtlinie Kenntnis erlangen, bitten wir Sie, uns unverzüglich per E-Mail an legalnotices@plaid.com zu informieren. Wir können nach eigenem Ermessen in Bezug auf solche Verstöße alle angemessenen Maßnahmen ergreifen – einschließlich der Meldung von Aktivitäten oder Verhaltensweisen, die unserer Meinung nach gegen das Gesetz verstoßen, an die zuständigen Strafverfolgungsbehörden, Aufsichtsbehörden oder andere geeignete Dritte.`,
    ],
  },
  {
    title: 'Universeller Datenzugriff',
    description: [
      `Plaid unterstützt ein Open Finance-Ökosystem, in dem Endnutzer Eigentümer ihrer Daten (z. B. Finanzdaten) sind und Dritten Zugang zu diesen Daten verschaffen können. Soweit Sie solche Endnutzerdaten hosten, die Plaid noch nicht zugänglich sind, verpflichten sich die Parteien, nach angemessener Aufforderung durch Plaid nach Treu und Glauben miteinander zu kooperieren, um eine Vereinbarung auszuhandeln und in die Wege zu leiten, die Plaid den Zugriff auf diese Endnutzerdaten im Namen der Endnutzer erlaubt.`,
    ],
  },
  {
    title: 'Sonstiges',
    description: [
      `Das Versäumnis Ihrerseits oder von Plaid, ein hierin oder in einer anderen Vereinbarung mit uns vorgesehenes Recht geltend zu machen, gilt nicht als Verzicht auf weitere Rechte aus der Vereinbarung.`,
      `Sollte eine Bestimmung dieser Richtlinie für nicht durchsetzbar oder unwirksam befunden werden, wird diese Bestimmung auf das erforderliche Mindestmaß beschränkt oder aufgehoben, so dass diese Richtlinie ansonsten in vollem Umfang wirksam und durchsetzbar bleibt.`,
    ],
  },
];
