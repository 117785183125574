export const people = {
  carousel: true,
  items: [
    {
      type: 'single-quote',
      'image-src-medium-up': '/assets/img/careers/plaid-people-kevin@2x.jpg',
      'logo-src-small': {
        src: '/assets/img/careers/plaid-people-kevin@2x.jpg',
        alt: "Plaid's Kevin Hu",
      },
      'large-mobile-format': true,
      'bg-color': 'bg-grey',
      'quote-label': 'Plaid People',
      'quote-text':
        'Data cleaning is very difficult, and while others deliver basic functionality, Plaid has the foresight and resources to go above and beyond that basic responsibility, and takes the initiative to increase the scope of their responsibility to their customers.',
      'quote-byline': 'Kevin Hu, Data Science',
      'cta-text': 'Read the full interview',
      'cta-link': 'https://blog.plaid.com/plaid-people-kevin-hu/',
    },
    {
      type: 'single-quote',
      'image-src-medium-up': '/assets/img/careers/plaid-people-joy@2x.jpg',
      'logo-src-small': {
        src: '/assets/img/careers/plaid-people-joy@2x.jpg',
        alt: "Plaid's Joy Zheng",
      },
      'large-mobile-format': true,
      'bg-color': 'bg-grey',
      'quote-label': 'Plaid People',
      'quote-text':
        'The people here are very generous with their time. They are always willing to speak with you or explain something that they’ve done or are working on.',
      'quote-byline': 'Joy Zheng, Engineering',
      'cta-text': 'Read the full interview',
      'cta-link': 'https://blog.plaid.com/plaid-people-joy-zheng/',
    },
    {
      type: 'single-quote',
      'image-src-medium-up': '/assets/img/careers/plaid-people-emily@2x.jpg',
      'logo-src-small': {
        src: '/assets/img/careers/plaid-people-emily@2x.jpg',
        alt: "Plaid's Emily Downs",
      },
      'large-mobile-format': true,
      'bg-color': 'bg-grey',
      'quote-label': 'Plaid People',
      'quote-text':
        'It’s super cool to work at a place where everyone is passionate not only about their own projects but also equally interested in learning about what you are working on and how you are helping build Plaid.',
      'quote-byline': 'Emily Downs, People Operations',
      'cta-text': 'Read the full interview',
      'cta-link': 'https://blog.plaid.com/emily-downs/',
    },
  ],
};

export const locations = {
  'title-label': 'Our office locations',
  'legacy-base-url': '/careers/openings/?',
  'office-one-img': '/assets/img/careers/locations/sf@2x.jpg',
  'office-one-img-landscape':
    '/assets/img/careers/locations/sf-landscape@2x.jpg',
  'office-one-name': 'San Francisco',
  'office-one-description':
    'Our headquarters is in sunny SOMA and includes a beautiful sun-filled atrium, a private outdoor deck and even a (semi-hidden) climbing wall.',
  'office-one-cta': 'san-francisco',
  'office-two-img': '/assets/img/careers/locations/slc@2x.jpg',
  'office-two-name': 'Salt Lake City',
  'office-two-description':
    'This office, conveniently located in downtown SLC, is a fast-growing engineering hub.',
  'office-two-cta': 'salt-lake-city',
  'office-three-img': '/assets/img/careers/locations/remote@2x.jpg',
  'office-three-name': 'Remote',
  'office-three-description':
    'We guarantee a great work experience for our distributed team, whether they are working from a coworking space, living room, or traveling the world!',
  'office-three-cta': 'remote',
  'office-four-img': '/assets/img/careers/locations/nyc@2x.jpg',
  'office-four-name': 'New York',
  'office-four-description':
    'Our second-largest office and East Coast command center is in the heart of Manhattan’s Soho neighborhood.',
  'office-four-cta': 'new-york',
  'office-five-img': '/assets/img/careers/locations/ldn@2x.jpg',
  'office-five-name': 'London',
  'office-five-description':
    'Located in Shoreditch, our office is in the heart of the London fintech community.',
  'office-five-cta': 'london',
  'office-six-img': '/assets/img/careers/locations/ams@2x.jpg',
  'office-six-name': 'Amsterdam',
  'office-six-description':
    'Flanked by canals, our Amsterdam office is located in the Central Business District.',
  'office-six-cta': 'amsterdam',
};

export const payIntervalMap = {
  'per-year-salary': 'per year',
  'per-month-salary': 'per month',
  'semi-month-salary': 'twice a month',
  'bi-month-salary': 'every two months',
  'bi-week-salary': 'every 2 weeks',
  'per-week-salary': 'a week',
  'per-day-wage': 'per day',
  'per-hour-wage': 'an hour',
  'one-time': '',
};
