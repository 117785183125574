/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the contentful response for infographic content type to the view
 *
 * @param {Object} args
 * @param {string} args.title
 * @param {Object[]} args.barGraphs
 * @param {string} args.legend1
 * @param {string} args.legend2
 * @param {Object[]} args.metrics
 * @param {string} args.backgroundColor
 * @returns {Object}
 */

export const infographicSectionAdapter = ({
  title,
  barGraphs = [],
  legend1,
  legend2,
  metrics = [],
  backgroundColor,
}) => {
  return {
    graphTitle: title,
    graphs: barGraphs.map((item) => ({
      heading: _get(item, 'fields.heading'),
      percentage1: _get(item, 'fields.barGraph1'),
      percentage2: _get(item, 'fields.barGraph2'),
    })),
    legendLabel: legend1,
    legendLabelSecondary: legend2,
    metrics: metrics.map((item) => ({
      heading: _get(item, 'fields.heading'),
      description: _get(item, 'fields.body'),
      mobileDescription: _get(item, 'fields.mobileBody'),
    })),
    classes: 'background--color-' + _get(backgroundColor, 'fields.color'),
  };
};
