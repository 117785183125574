import React, { FC, useState, useLayoutEffect } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { Grid, Column } from 'src/components/Layout/Grid';
import { TextInput, Button, SelectWithController } from 'src/components/Inputs';
import { SearchBar as SearchBarProps } from './SearchBar.types';
import styles from './SearchBar.module.scss';

const SearchBar: FC<SearchBarProps> = ({
  dropdowns = [],
  preventDefault,
  destination,
  placeholder = '',
  label = '',
  onChange,
  onSubmit,
}) => {
  const { register, control, setValue } = useForm();
  const [searchState, setSearchState] = useState({ query: '' });

  const { query } = useRouter();

  useLayoutEffect(() => {
    onChange && onChange(searchState);
  }, [onChange, searchState]);

  const handleSubmit = (event) => {
    // if there's an explicit preventDefault (the prop) or no destination, e.preventDefault()
    (preventDefault || !destination) && event.preventDefault();
    onSubmit && onSubmit(event);
  };

  const handleChange = ({ event = null, newState = {}, persist = false }) => {
    // Fixes React event pooling error - (event pooling deprecated in React 17)
    // Event Pooling - https://reactjs.org/docs/legacy-event-pooling.html
    // Error example - https://stackoverflow.com/questions/49500255/warning-this-synthetic-event-is-reused-for-performance-reasons-happening-with
    event && persist && event.persist();
    setSearchState((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  return (
    <Grid noPadding noMarginRightLarge noMarginRightMedium useThreadsGrid>
      <Column
        medium={12}
        className={styles.searchBox}
        data-id='search-box'
        allowOverflow
      >
        <form
          data-testid='general-search-form'
          id='SearchBarForm'
          className={styles.form}
          onSubmit={(e) => handleSubmit(e)}
          action={destination.toLowerCase()}
        >
          <Grid
            className={styles.formGroup}
            noPadding
            noMarginRightSmall
            noMarginRightMedium
            noMarginRightLarge
            useThreadsGrid
          >
            <Column medium={12}>
              <p className={styles.resultCountParagraph}>
                <span
                  className={styles.resultCount}
                  data-testid='totalResultCount'
                >
                  {label}
                </span>
              </p>
            </Column>
            <Column
              medium={12}
              large={dropdowns.length > 0 ? 6 : 10}
              className={cx(styles.cell, styles.fieldsContainer)}
              noMarginRight
              allowOverflow
              data-testid='general-search-query'
            >
              <TextInput
                id='general-search-query'
                name='q' // TODO: make this optional so that it's not always in the URL if it isn't wanted
                describedby='query'
                isDarkMode={false}
                register={register}
                label={placeholder}
                value={query?.q ? query.q.toString() : searchState.query}
                onChange={(event) =>
                  handleChange({
                    event,
                    newState: { query: event.target.value },
                    persist: true,
                  })
                }
              />
            </Column>
            <Column
              className={cx(styles.cell, styles.topicSelectColumn)}
              medium={12}
              large='auto'
              allowOverflow
              noMarginRight
            >
              {dropdowns.map((dropdown, i) => (
                <SelectWithController
                  key={`dropdown-${i}`}
                  className={cx(
                    'mb1',
                    'cell',
                    styles.cell,
                    'large-auto',
                    'medium-12',
                  )}
                  label={dropdown?.label}
                  name={dropdown?.name}
                  describedby={dropdown?.describedby || dropdown?.name}
                  isDarkMode={false}
                  id={dropdown?.name}
                  register={register}
                  setValue={setValue}
                  defaultValue={dropdown?.options.find((opt) => !!opt?.default)}
                  control={control}
                  options={dropdown?.options}
                  onChange={(e) =>
                    handleChange({
                      newState: { selectedOption: e.value?.toLowerCase() },
                    })
                  }
                />
              ))}
            </Column>
            <Column
              large={2}
              medium={12}
              className={(styles.cell, styles.searchButtonContainer)}
              noMarginRight
              allowOverflow
            >
              <Button className={cx('no-focus', styles.button)} type='submit'>
                Search
              </Button>
            </Column>
          </Grid>
        </form>
      </Column>
    </Grid>
  );
};

export default SearchBar;
