import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import { credibilityQuoteWithImageAdapter } from '../adapters';
/**
 * Maps the contentful response for credibilitycarousel content type to the view
 *
 * @param {Object} args
 * @param {Array<Object>} args.carouselSlides
 * @returns {Object}
 */

const stats = (slide) => {
  const hasStat = _get(slide, 'fields.stat', []).length > 0;
  const stats = hasStat ? slide.fields.stat : [];

  return stats.map((stat) => {
    return {
      data: _get(stat, 'fields.stat'),
      text: _get(stat, 'fields.description'),
    };
  });
};

const credibilityItemAdapter = (slide, showQuoteLogo) => {
  // Logo
  const logo = _get(slide, 'fields.logoSrcSmall');

  // CTA
  const cta = _get(slide, 'fields.cta');
  const href = _get(cta, 'fields.url');
  const text = _get(cta, 'fields.text');
  const hasCta = href && text;

  // Image
  const slideImage = _get(slide, 'fields.image.fields.file.url');

  // Quote
  const quote = _get(slide, 'fields.quote.fields');
  const quoteAdapter =
    quote &&
    credibilityQuoteWithImageAdapter({
      quote: quote.quote,
      attribution: quote.cite,
      cta: cta || quote.cta,
      label: quote.label,
      headline: quote.heading,
    });

  return {
    'image-src-medium-up': slideImage,
    'bg-color': _get(slide, 'fields.backgroundColor.fields.color', 'bg-green'),
    ...(quote && quoteAdapter),
    // Overwrites quote CTA if one is present from credibilityItem
    ...(hasCta && {
      'cta-text': text,
      'cta-link': href,
    }),
    'show-quote-logo-asset': showQuoteLogo,
    'logo-src-small': {
      src: _get(logo, 'fields.file.url'),
    },
    stats: stats(slide),
  };
};

const getSlides = (slides, showQuoteLogo = true) => {
  return slides.map((slide) => {
    // Logo
    const logo = _get(slide, 'fields.logoSrcSmall');

    // CTA
    const cta = _get(slide, 'fields.cta');
    const href = _get(cta, 'fields.url');
    const text = _get(cta, 'fields.text');
    const hasCta = href && text;

    // Image
    const slideImage = _get(slide, 'fields.image.fields.file.url');

    // Quote
    const quote = _get(slide, 'fields.quote.fields');
    const quoteAdapter =
      quote &&
      credibilityQuoteWithImageAdapter({
        quote: quote.quote,
        attribution: quote.cite,
        cta: cta || quote.cta,
        label: quote.label,
        headline: quote.heading,
      });

    return {
      'image-src-medium-up': slideImage,
      'bg-color': _get(
        slide,
        'fields.backgroundColor.fields.color',
        'bg-green',
      ),
      ...(quote && quoteAdapter),
      // Overwrites quote CTA if one is present from credibilityItem
      ...(hasCta && {
        'cta-text': text,
        'cta-link': href,
      }),
      'show-quote-logo-asset': showQuoteLogo,
      'logo-src-small': {
        src: _get(logo, 'fields.file.url'),
      },
      stats: stats(slide),
    };
  });
};

const credibilityCarouselAdapter = ({ showQuoteLogo = true, ...fields }) => {
  const credibilitySlides = [
    ..._get(fields, 'carouselSlides', []),
    ..._get(fields, 'credibilitySlides', []),
  ].filter((slide) => {
    return _get(slide, 'sys.contentType.sys.id') === 'credibilityItem';
  });
  return {
    carousel: credibilitySlides.length > 0,
    items: getSlides(credibilitySlides, showQuoteLogo),
  };
};

const careersCredibilityCarouselAdapter = ({
  carouselSlides = [],
  credibilitySlides = [],
}) => {
  const slides = _uniqBy(
    carouselSlides.concat(credibilitySlides).filter((slide) => {
      return _get(slide, 'sys.contentType.sys.id') === 'credibilityItem';
    }),
    'sys.id',
  );
  return credibilityCarouselAdapter({
    carouselSlides: slides || [],
    showQuoteLogo: false,
  });
};

export {
  credibilityCarouselAdapter,
  careersCredibilityCarouselAdapter,
  credibilityItemAdapter,
};
