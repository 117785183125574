import React from 'react';
import _get from 'lodash/get';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Contentful, CmsComponents, targetMap } from 'src/lib/constants';
import DecorativeText from './DecorativeText';
import ButtonGroup from './ButtonGroup';
import CtaBlockWithImage from './CtaBlockWithImage';
import { Btn, Link } from 'src/components-v2/Inputs';
import Pill from './Pill';
import QuoteWithImage from './QuoteWithImage';
import { getHrefFromRichText } from 'src/lib/utils';
import { Spacer, spacerAdapter } from 'src/components-v2/Layout';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import { useTrack } from 'src/contexts/AnalyticsContext';

const EmbeddedEntrySwitch = ({ video, asset }, theme) => {
  const track = useTrack();
  return {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const componentType = _get(
        node,
        Contentful.EMBEDDED_ENTRY_CONTENT_TYPE_ID,
      );

      const fields = node?.data?.target?.fields;
      switch (componentType) {
        case CmsComponents.ASSET:
          return (
            <CmsMediaSwitch
              asset={fields}
              width={fields?.width || asset?.width}
              height={fields?.height || asset?.height}
              queryString={{
                xs: asset?.queryString?.xs,
                sm: asset?.queryString?.sm,
                md: asset?.queryString?.md,
                lg: asset?.queryString?.lg,
              }}
              sys={node?.data?.target?.sys}
            />
          );
        case CmsComponents.BUTTON:
          return (
            <Btn
              variant={fields?.appearance?.toLowerCase()}
              href={fields?.url}
              target={targetMap[fields?.target]}
              trackingId={fields?.trackingId || ''}
              sys={node?.data?.target?.sys}
            >
              {fields?.text}
            </Btn>
          );
        case CmsComponents.BUTTON_GROUP:
          return <ButtonGroup node={node} />;
        case CmsComponents.CTA_BLOCK_WITH_IMAGE:
          const href = getHrefFromRichText(fields?.body?.content);
          return (
            <CtaBlockWithImage node={node} {...(href && { href: href })} />
          );
        case CmsComponents.QUOTE_WITH_IMAGE:
          return <QuoteWithImage node={node} />;
        case CmsComponents.SPACER:
          return <Spacer {...spacerAdapter(node?.data?.target)} />;
        case CmsComponents.HYPERLINK:
          return (
            <Btn
              variant={'tertiary'}
              href={fields?.url}
              target={targetMap[fields?.target]}
              trackingId={fields?.trackingId || ''}
              sys={node?.data?.target?.sys}
            >
              {fields?.text}
            </Btn>
          );
        case CmsComponents.ANCHOR:
          return (
            <span
              id={fields.anchorId}
              key={`anchor-${fields.anchorId}`}
              data-testid={fields.anchorId}
            ></span>
          );
        default:
          return null;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const componentType = _get(
        node,
        Contentful.EMBEDDED_ENTRY_CONTENT_TYPE_ID,
      );
      const fields = node?.data?.target?.fields;
      switch (componentType) {
        case CmsComponents.DECORATIVE_TEXT:
          return <DecorativeText node={node} />;
        case CmsComponents.HYPERLINK:
          const trackingId = fields?.trackingId || '';
          const onClick = {
            onClick: () => {
              track({ type: trackingId });
            },
          };
          return (
            <Link href={fields?.url}>
              <a
                target={targetMap[fields?.target]}
                {...(trackingId !== '' && onClick)}
              >
                {fields?.text}
              </a>
            </Link>
          );
        case CmsComponents.PILL:
          return <Pill node={node} />;
        default:
          return null;
      }
    },
  };
};

export default EmbeddedEntrySwitch;
