import React, { useRef } from 'react';

// helpers
import { Property, HandleEvent } from './InteractiveData.types';
import { DeviceContext } from 'src/contexts';

// SVG components
import Checkmark from 'src/components/SVGs/Icons/Checkmark';
import StudentLoansIcon from 'src/components/SVGs/Icons/StudentLoansIcon';
import CreditCardsIcon from 'src/components/SVGs/Icons/CreditCardsIcon';
import MortgagesIcon from 'src/components/SVGs/Icons/MortgagesIcon';

interface Props {
  index: number;
  property: Property;
  handleEvent: HandleEvent;
  parentContainer: HTMLDivElement;
}

const getIcon = (icon) => {
  switch (icon) {
    case 'studentLoans':
      return <StudentLoansIcon />;
    case 'creditCards':
      return <CreditCardsIcon />;
    case 'mortgages':
      return <MortgagesIcon />;
    default:
      return null;
  }
};

const ObjectPropertyCard: React.FC<Props> = ({
  index,
  property,
  handleEvent,
  parentContainer,
}) => {
  const currentElement = useRef(null);
  return (
    <DeviceContext.Consumer>
      {(device) => (
        <div
          onClick={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          onKeyDown={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          role='button'
          tabIndex={0}
          ref={currentElement}
          data-id={`object-property-${
            property['data-title'] ? property['data-title'] : property.title
          }`}
          data-code-line={property['code-line-start']}
          data-code-length={property['code-highlight-length']}
          className='interactive-data-property'
          data-testid={`object-property-${property.title}`}
        >
          {property['custom-icon'] ? (
            <span aria-hidden='true' className='custom-icon'>
              {getIcon(property['custom-icon'])}
            </span>
          ) : (
            <span className='check-icon'>
              <Checkmark />
            </span>
          )}
          <p
            className={`interactive-data-title ${
              property['preserve-title-case']
                ? 'interactive-data-title--preserve-case'
                : ''
            }`}
          >
            {property.title}
          </p>
          <div className='interactive-data-description-container'>
            {Array.isArray(property.description) ? (
              property.description.map((desc, i) => (
                <p
                  className='interactive-data-description'
                  dangerouslySetInnerHTML={{ __html: desc }}
                  key={`desc-${i}`}
                />
              ))
            ) : (
              <p
                className='interactive-data-description'
                dangerouslySetInnerHTML={{
                  __html: property.description,
                }}
              />
            )}
          </div>
        </div>
      )}
    </DeviceContext.Consumer>
  );
};

export default ObjectPropertyCard;
