// TODO: Refactor, implement / support features from legacy page-header
// https://jira.plaid.com/browse/SITE-1926
import React, { FC } from 'react';
import cx from 'classnames';

// components
import CareersSearch from 'src/components/Inputs/CareersSearch/CareersSearch';
import { Label } from 'src/components/headers/MvpPageHeader/shared/Label';
import { Image } from 'src/components/headers/MvpPageHeader/shared/Image';
import { HeadingText } from 'src/components/headers/MvpPageHeader/shared/HeadingText';
import { CareersPageHeader as CareersPageHeaderProps } from './CareersPageHeader.types';

// pageHeaderStyles
import pageHeaderStyles from 'src/components/headers/MvpPageHeader/PageHeader--cms.module.scss';
import styles from './CareersPageHeader.module.scss';

const CareersPageHeader: FC<CareersPageHeaderProps> = ({
  heading,
  subheading = {},
  description = '',
  image = {
    alt: '',
    className: '',
    src: '',
  },
  labelText = '',
  bgColor = '',
  jobCount,
  jobLocations,
  jobDepartments,
  filterResults,
  role,
  totalJobCount,
  hasSearchButton,
}) => {
  return (
    <header
      className={cx(pageHeaderStyles.pageHeader, styles.pageHeader, {
        [`${pageHeaderStyles[bgColor]}`]: bgColor,
      })}
      role='banner'
    >
      <div className='grid-container'>
        <div className='grid-x align-justify'>
          <div className='cell small-12 medium-6'>
            <div
              className={cx(
                pageHeaderStyles.contentWrapper,
                styles.contentWrapper,
              )}
            >
              <div className='grid-x'>
                <div className='cell small-10 large-12'>
                  {labelText && <Label>{labelText}</Label>}
                  <HeadingText className={heading.className}>
                    {heading.text}
                  </HeadingText>
                </div>
                {subheading && (
                  <div className='cell small-10 large-12'>
                    <h4 className='regular page-header-subheader'>
                      {subheading.text}
                    </h4>
                  </div>
                )}
                {description && (
                  <p dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </div>
            </div>
          </div>
          <div className='cell small-12'>
            <CareersSearch
              hasSearchButton={hasSearchButton}
              totalJobCount={totalJobCount}
              jobLocations={jobLocations}
              jobDepartments={jobDepartments}
              filterResults={filterResults}
              role={role}
            />
          </div>
        </div>
        <div className={styles.imageContainer}>
          {image && !!image.src && <Image {...image} />}
        </div>
      </div>
    </header>
  );
};

export default CareersPageHeader;
