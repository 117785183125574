import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Assistance for survivors of human trafficking',
      'meta-description': 'Assistance for survivors of human trafficking',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Assistance for survivors of human trafficking',
    requestType: 'Assistance for survivors of human trafficking',
    introHeader: 'Introduction',
    intro: `<p>Complete this form if you are a victim of Sex or Labor Trafficking.
            <p>You may be eligible to  prevent the reporting of adverse information that resulted from human trafficking if you experienced either of the the following:<p/>
            <p>Sex trafficking: You were recruited, transported, solicited, or harbored for the purpose of a commercial sex act or if you were induced into a commercial sex act by force, fraud, or coercion.<p/>
            <p>Labor trafficking: You were transported or harbored by force or fraud for slavery, debt bondage, or involuntary servitude.<p/>
            </p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
