import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';

import { RichTextAssets, Contentful } from 'src/lib/constants';
import { optimizeImage } from 'src/lib/image-helpers';
import Image from 'src/components/Image';

const propTypes = {
  imgClassName: PropTypes.string,
};

const EmbeddedAssetSwitch = ({ imgClassName = '' }) => {
  return {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const assetType = _get(node, Contentful.EMBEDDED_ASSET_CONTENT_TYPE_ID);

      switch (assetType) {
        case RichTextAssets.ASSET: {
          const imageSrc = _get(node, 'data.target.fields.file.url');
          const altText =
            _get(node, 'data.target.fields.description') ||
            _get(node, 'data.target.fields.title');

          return (
            <Image
              className={imgClassName}
              src={optimizeImage(imageSrc)}
              alt={altText}
            />
          );
        }
        default:
          throw new Error(
            `No asset was found for this field: "${assetType}" @ EmbeddedAssetSwitch.jsx`,
          );
      }
    },
  };
};

EmbeddedAssetSwitch.propTypes = propTypes;
export default EmbeddedAssetSwitch;
