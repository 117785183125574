import { IconNames } from 'threads5/Icon/Icon.types';
// import { Icons } from '../DataDisplay/Icon/Icon.types';
import { MenuImagesNames } from './assets/types';

export type Link = {
  href: string;
  title: string;
  trackingId?: string;
};

export type CardWIcon = Link & {
  icon: IconNames | 'PlaidLogo';
  description?: string;
};

export type CardWImage = Link & {
  image: MenuImagesNames;
  imagePos?: 'top' | 'bottom';
  description?: string;
};

export type Tabs = {
  id: string;
  type: 'tabs';
  title: string;
  children: Array<Tab>;
};

export type Tab = {
  id: string;
  type: 'list';
  title: string;
  children: Array<(CardWIcon & { isOverview?: boolean }) | CardWImage>;
};

export type Columns = {
  id: string;
  type: 'columns';
  title: string;
  children: [
    {
      title: string;
      children?: CardWIcon[];
    },
    {
      title: string;
      children?: Link[];
    },
  ];
};

export type List = {
  id: string;
  type: 'list';
  title: string;
  children?: Array<CardWIcon>;
};

export type NavDataItems = Array<
  (Link & { id: string }) | Tabs | Columns | List
>;

export type SubMenuData = Tabs | Columns | List;

export const isLink = (obj: any): obj is Link => {
  return typeof obj === 'object' && obj !== null && !('children' in obj);
};

export const isCardWIcon = (obj: any): obj is CardWIcon => {
  return typeof obj === 'object' && obj !== null && 'icon' in obj;
};

export const isCardWImage = (obj: any): obj is CardWImage => {
  return typeof obj === 'object' && obj !== null && 'image' in obj;
};
