// inEU must be used within a hook because it must access the window object
import { useEffect, useState } from 'react';
import inEU from '@segment/in-eu';
import { useUserLocale } from './useUserLocale';

function useInEu(): boolean | null {
  // inEU initial state is set to null until we know the user location
  const [isInEu, setIsInEU] = useState(null);
  const { locale } = useUserLocale();

  useEffect(() => {
    if (locale?.intrinsicLocale === 'eu') {
      setIsInEU(true);
    } else {
      setIsInEU(inEU());
    }
  }, [locale.intrinsicLocale]);

  return isInEu;
}

export default useInEu;
