import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  categories: PropTypes.shape({
    team: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    commitment: PropTypes.string.isRequired,
  }),
};

const JobCategories = ({ categories: { team, location, commitment } }) => {
  const order = [location, commitment, team];
  return (
    <div className='job-post__categories show-for-medium'>
      {order.map((item, index) => (
        <div key={`category-${index}`} className='job-post-category'>
          {item}
        </div>
      ))}
    </div>
  );
};

JobCategories.propTypes = propTypes;
export default JobCategories;
