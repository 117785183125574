/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC } from 'react';
import { RichTextContent } from 'contentful';

import { RichTextRenderer } from 'src/components/RichTextRenderer';
import styles from './Styles.module.scss';

interface VideoAsset {
  src: string;
  type: string;
}

interface UnlockedContentProps {
  confirmationContent?: RichTextContent;
  videoContent: {
    formats: VideoAsset[];
  };
}

const UnlockedContent: FC<UnlockedContentProps> = ({
  confirmationContent,
  videoContent,
}) => {
  return (
    <>
      {confirmationContent && (
        <div className={styles.confirmation}>
          <RichTextRenderer
            content={confirmationContent}
            classes={{
              pClassName: styles.text,
              h2ClassName: styles.h2,
              h3ClassName: styles.h3,
              h4ClassName: styles.h4,
            }}
          />
        </div>
      )}

      {/* @TODO: Video abstraction https://jira.plaid.com/browse/SITE-2516 */}
      <video
        data-testid='unlocked-video-content'
        autoPlay={false}
        loop={false}
        muted={false}
        controls
        width='100%'
        height='auto'
      >
        {videoContent.formats.map((item, i) => (
          <source {...item} key={`video-format-${i}`} />
        ))}
      </video>
    </>
  );
};

export default UnlockedContent;
