import React from 'react';
import MUIGrid from '@mui/system/Unstable_Grid';
import { GridProps } from './Grid.types';
import { SxProps, Theme } from '@mui/system';

const Column = React.forwardRef(
  ({ children, sx, ...props }: GridProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <MUIGrid ref={ref} component='div' sx={sx as SxProps<Theme>} {...props}>
        {children}
      </MUIGrid>
    );
  },
);

export default Column;
