import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Image from 'src/components/Image';

const ImagePropTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  isDesktopOnly: PropTypes.bool,
};

const HeaderImage = ({
  alt,
  className,
  src,
  isDesktopOnly = true,
  ...props
}) => (
  <Image
    src={src}
    className={cx('image', className)}
    alt={alt}
    isDesktopOnly={isDesktopOnly}
    {...props}
  />
);

HeaderImage.propTypes = ImagePropTypes;
export default HeaderImage;
