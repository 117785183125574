import React, { FC } from 'react';
import ThreadsCheckbox, { Props } from 'plaid-threads/Checkbox';
import cx from 'classnames';

import styles from './Checkbox.module.scss';
export type { Props } from 'plaid-threads/Checkbox';

interface CheckboxProps extends Omit<Props, 'value'> {
  // we hack the Checkbox interface because Threads for some reason doesn't support optional values
  // we need an optional value because for some reason Threads does not support passing a ref 🤷
  value?: boolean;
  hasError?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ hasError = false, ...props }) => {
  return (
    <div className={styles.wrapper}>
      {/* @ts-ignore */}
      <ThreadsCheckbox
        {...props}
        className={cx(styles.container, {
          // @ts-ignore
          [props?.className || '']: !!props?.className,
          [styles.error]: hasError,
        })}
      />
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
export default Checkbox;
