import * as React from 'react';
import { CtaBanner } from 'src/sections';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Btn as Button } from 'src/components-v2/Inputs';
import { targetMap } from 'src/lib/constants';

import cx from 'classnames';
import styles from './CmsCtaHeaderBanner.module.scss';

export const CmsCtaHeaderBanner = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const bgImage = fields?.backgroundImage?.fields?.file?.url;

  const paragraphIncluded =
    fields?.body?.content?.filter((item) => {
      if (item.nodeType === 'paragraph') {
        if (item.content[0].value.length > 1) {
          return true;
        }
      }
      return false;
    }).length > 0;

  return (
    <CtaBanner
      sx={{
        backgroundColor: bgColor,
        ...fields?.styles,
      }}
      sys={sys}
      bgImage={bgImage}
      className={cx(
        paragraphIncluded
          ? styles.CmsCtaSubheaderBanner
          : styles.CmsCtaHeaderBanner,
      )}
    >
      <RichTextSwitch
        sys={sys}
        content={fields?.body}
        nodes={{
          h2: {
            variant: paragraphIncluded ? 'h3' : 'h2',
            component: 'h2',
            fieldId: 'body',
          },
          p: {
            variant: 'p2',
            sx: {
              color: bgImage ? 'var(--black800)' : 'var(--black200)',
            },
          },
        }}
      />
      <Button
        variant={'primary'}
        href={fields?.cta?.fields?.url || ''}
        target={targetMap[fields?.cta?.fields?.target] || '_self'}
        trackingId={fields?.cta?.fields?.trackingId || ''}
        sys={fields?.cta?.sys}
      >
        {fields?.cta?.fields?.text}
      </Button>
    </CtaBanner>
  );
};
