import React, { FC } from 'react';

import { FormSectionHopInCodeProps } from './FormSectionHopInCode.types';

const FormSectionHopInCode: FC<FormSectionHopInCodeProps> = ({
  ticketType,
  register,
  formData,
}) => {
  return (
    <input
      name='hopInIntegrationCode'
      id='hopInIntegrationCode'
      data-testid='hopInIntegrationCode'
      ref={register}
      value={
        formData.hopinIntegrationCodeVirtual && ticketType !== 'In person'
          ? formData.hopinIntegrationCodeVirtual
          : formData.hopinIntegrationCode
      }
      type='hidden'
    />
  );
};

export default FormSectionHopInCode;
