import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { RichTextRenderer } from 'src/components/RichTextRenderer';
import Image from 'src/components/Image';

import { RichTextShape } from 'src/lib/prop-types';

const propTypes = {
  carouselTitle: PropTypes.string,
  imageShouldCoverRightHalf: PropTypes.bool,
  header: PropTypes.string, // TODO: deprecate this once 100% migrated https://jira.plaid.com/browse/SITE-2196
  description: PropTypes.string, // TODO: deprecate this once 100% migrated https://jira.plaid.com/browse/SITE-2196
  img: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  'bg-color': PropTypes.string.isRequired,
  'use-variant-image-layout': PropTypes.bool,
  'variant-image-height': PropTypes.number,
  'variant-image-width': PropTypes.number,
  body: RichTextShape,
  verticalAlignment: PropTypes.string,
};

// text alignment options
// if more are added the CSS class will need to be written as well
const VerticalAlignment = Object.freeze({
  top: 'top',
  center: 'center',
});

const SimpleSlide = ({
  carouselTitle = '',
  imageShouldCoverRightHalf,
  header, // TODO: deprecate this once 100% migrated https://jira.plaid.com/browse/SITE-2196
  description, // TODO: deprecate this once 100% migrated https://jira.plaid.com/browse/SITE-2196
  img,
  'bg-color': bgColor,
  'variant-image-height': variantImageHeight = '625',
  'variant-image-width': variantImageWidth = '304',
  'use-variant-image-layout': useVariantImageLayout = false,
  body,
  verticalAlignment = VerticalAlignment.top,
}) => {
  return (
    <div className='content-carousel-slide simple-carousel'>
      <div className='grid-x'>
        <div className='cell small-12 medium-6 content-carousel-slide-content small-order-1 medium-order-1'>
          <div
            className={cx('content-carousel-slide-content-container', {
              'content-carousel-slide-content-container--centered-vertically':
                verticalAlignment === VerticalAlignment.center,
            })}
          >
            {carouselTitle && (
              <h6 className='section-title'>{carouselTitle}</h6>
            )}
            {body ? (
              <RichTextRenderer
                content={body}
                classes={{
                  pClassName: 'simple-carousel-body',
                  h4ClassName: 'section-header',
                }}
              />
            ) : (
              <>
                {/** TODO: deprecate this once 100% migrated https://jira.plaid.com/browse/SITE-2196 */}
                <h4 className='section-header'>{header}</h4>
                <p
                  className='section-description'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </>
            )}
          </div>
        </div>
        <div
          className={`cell small-12 medium-auto content-carousel-slide-img small-order-2 medium-order-2 carousel-media-center-wrapper media-container ${bgColor} carousel-media-center-wrapper--${bgColor}`}
        >
          <Image
            className={cx('simple-carousel-phone-img', {
              overview: useVariantImageLayout,
              'carousel-item--image-cover': imageShouldCoverRightHalf,
            })}
            src={img.src ? img.src : img}
            height={variantImageHeight}
            width={variantImageWidth}
            alt={header ? header : img.alt}
          />
        </div>
      </div>
    </div>
  );
};
SimpleSlide.propTypes = propTypes;

export default SimpleSlide;
