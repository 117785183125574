import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';
import { generateEventStructuredData } from 'src/lib/utils';

const propTypes = {
  fields: TemplateFieldShape.isRequired,
};

const EventPageTemplateScripts = dynamic(
  () => {
    return import('src/templates/EventPageTemplate/EventPageTemplateScripts');
  },
  {
    ssr: false,
  },
);

const EventPageTemplate = ({ fields, events, ...props }) => {
  const { route } = useRouter();
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={fields?.hasFooterCta}
      noIndex={fields?.metaNoindex}
      className='event-page'
      structuredData={generateEventStructuredData({
        name: fields?.metaTitle,
        description: fields?.metaDescription,
        startDate: fields?.startTime,
        eventStatus: fields?.eventStatus,
        eventAttendanceMode: fields?.eventAttendanceMode,
        streetAddress: fields?.streetAddress,
        addressLocality: fields?.addressLocality,
        addressRegion: fields?.addressRegion,
        postalCode: fields?.postalCode,
        addressCountry: fields?.addressCountry,
        url: fields?.url || `https://plaid.com/events/${fields?.pageSlug}/`,
      })}
    >
      <div data-testid={ContentfulTemplates.EVENT}>
        <CmsComponentSwitch
          components={fields?.components}
          events={events}
          currentEvent={{ fields }}
          templatePage={ContentfulTemplates.EVENT}
        />
      </div>
      <EventPageTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
EventPageTemplate.propTypes = propTypes;

export default EventPageTemplate;
