import { Sys } from 'contentful';
import { ImageEntryShape } from 'src/lib/ts-types';

interface DescriptionRowCmsData {
  metadata: { tags: [] };
  sys: Sys;
  fields: {
    label?: string;
    title: string;
    body: string;
    image?: {
      fields: ImageEntryShape;
    };
  };
}

interface DescriptionRowProps {
  label?: string;
  title?: string;
  body: string;
  image?: string;
  alt?: string;
  'standard-image': boolean;
  'row-reverse': boolean;
  'image-section-col-size': number;
  'image-section-col-offset': number;
  'text-section-col-size': number;
  'not-flipped': boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const descriptionRowAdapter = ({
  fields,
}: DescriptionRowCmsData): DescriptionRowProps => {
  return {
    label: fields.label || '',
    title: fields.title || '',
    body: fields.body,
    image: fields.image?.fields?.file.url,
    alt: fields.image?.fields?.description,
    'standard-image': true,
    'row-reverse': false,
    'image-section-col-size': 3,
    'image-section-col-offset': 3,
    'text-section-col-size': 6,
    'not-flipped': true,
  };
};
