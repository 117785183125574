export const gdprFieldsData = {
  country: {
    label: 'Country',
    name: 'Country',
    describedby: 'your country',
    autoComplete: 'country-name',
  },
  state: {
    label: 'State',
    name: 'State',
    describedby: 'your state',
    autoComplete: 'address-level1',
  },
  optInLabel:
    'By checking this box I confirm that I have read and understand <a href="https://plaid.com/legal/#privacy-statement" target="_blank">Plaid’s Privacy Statement</a> and I authorize Plaid to send me marketing communications at the email address provided',
};
