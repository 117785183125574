import React, { ReactElement } from 'react';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { ParsedUrlQuery } from 'querystring';

import { findComponent } from 'src/lib/utils';
import {
  ContentfulTemplates,
  CmsComponents,
  RESOURCES_PATH,
} from 'src/lib/constants';
import { marketingCmsClient } from 'src/lib/contentful-client';

import { articleTileGroupAdapter } from 'src/components/Layout';
import { ctaBlockWithButtonsAdapter } from 'src/components/CtaBlockWithButtons';
import FooterExtension from 'src/components/footer-extension';

import styles from 'src/templates/Resources/TopicPageTemplate/TopicPageTemplate.module.scss';
import tileStyles from 'src/components/Layout/Tile/Tile.module.scss';

import { TopicEntry } from 'src/templates/Resources/TopicPageTemplate/TopicPage.types';
import { CtaBlockFields } from 'src/components/CtaBlockWithButtons/CtaBlockWithButtons.types';

import { Option } from 'src/components/Inputs/Select/Select';

interface ArticleQuery {
  topicId: string;
  query: string | string[];
  limit?: string | number;
  skip?: number;
  topic: string;
}

export const topicPaginationPaths = async (entry: TopicEntry) => {
  const topic = entry?.fields?.topic.toLowerCase().split(' ').join('-');
  let articles;
  try {
    articles = await marketingCmsClient.getEntries({
      content_type: ContentfulTemplates.ARTICLE,
      links_to_entry: entry.sys.id,
    });
  } catch (error) {
    throw new Error(error);
  }

  // Create an array from the number of pages
  // and generate pagination routes
  const numberOfPages = Math.ceil(articles.items.length / 9);
  return Array.from(Array(numberOfPages)).reduce((pages, page, i) => {
    const pageNumber = i + 1;

    if (pageNumber > 1) {
      pages.push({
        params: {
          topic,
          page: `${pageNumber}`,
        },
      });
    }
    return pages;
  }, []);
};

// This needs to be renamed as it is no longer filtering articles by topic
// This function is used to get the articles that are to be shown on the page
export const getTopicArticles = ({ articleEntries = [], pagesToSkip = 0 }) => {
  const data = {
    articles: [],
    totalArticles: articleEntries?.length,
  };

  if (articleEntries?.length < 9) {
    data.articles = articleEntries;
  } else {
    // pagination
    const sliceStart = pagesToSkip * 9;
    const sliceEnd = sliceStart + 9;

    data.articles = articleEntries.slice(sliceStart, sliceEnd);
  }

  return data;
};

export const queryArticlesByTopic = (
  { topicId, query, skip = 0, limit, topic }: ArticleQuery,
  callback: (articles) => void,
): void => {
  const articleQuery = {
    content_type: ContentfulTemplates.ARTICLE,
    query, // we may want phrase full-text search by default https://www.contentful.com/developers/docs/references/content-delivery-api/#phrase-full-text-search
    limit,
    skip: skip * 9,
  };

  if (topic !== 'All Topics') {
    articleQuery['fields.topics.sys.id'] = topicId;
  }

  marketingCmsClient
    .getEntries(articleQuery)
    .then((data) => {
      let res = [];
      if (data.items.length > 0) {
        const { tileGroup } = articleTileGroupAdapter({
          articles: data.items,
        });
        res = tileGroup;
      }
      callback && callback(res);
    })
    .catch(({ message }) => {
      throw new Error(`Error fetching articles: ${message}`);
    });
};

// This could maybe be under src/components/Layout/Tile/ :thinking:
const TileSkeleton = () => {
  return (
    <div className={cx(tileStyles.tile, styles.skeletonContainer)}>
      <Skeleton className={styles.skeletonImage} />
      <div className={styles.skeletonTextContainer}>
        <Skeleton className={styles.textSkeleton} />
        <Skeleton
          className={cx([styles.textSkeleton, styles.bodyTwoSkeleton])}
        />
        <Skeleton className={cx([styles.textSkeleton, styles.ctaSkeleton])} />
      </div>
    </div>
  );
};

export const renderLoadingTiles = (quantity = 3): ReactElement[] => {
  return [...Array(quantity)].map((tile, i) => {
    return <TileSkeleton key={`tile-skeleton-${i}`} />;
  });
};

export const renderCtaBlock = (fields) => {
  const ctaBlock = findComponent(
    fields?.components,
    CmsComponents.CTA_BLOCK_WITH_BUTTONS,
  );

  if (ctaBlock) {
    try {
      return (
        <FooterExtension
          {...ctaBlockWithButtonsAdapter(ctaBlock?.fields as CtaBlockFields)}
        />
      );
    } catch (e) {
      return null;
    }
  }
};

// `omit` is used to exclude certain params from the query string
export const generateQueryString = ({
  queryObject = {},
  omit,
}: {
  queryObject: ParsedUrlQuery | Record<string, string>;
  omit?: string | string[];
}) => {
  let queryString = '';

  for (const queryParam in queryObject) {
    const prefix = queryString === '' ? '?' : '&';
    const notOmitted = Array.isArray(omit)
      ? !omit.includes(queryParam)
      : omit !== queryParam;

    if (notOmitted) {
      queryString += `${prefix}${queryParam}=${queryObject[queryParam]}`;
    }
  }

  return queryString === '' ? '?q=' : queryString;
};

/**
 * Returns an array of topic strings; ex: ['Banking', 'Lending']
 *
 * @param {Object} args
 * @param {Object[]} args.topicEntries
 * @returns {string[]}
 */
export const createTopicsArray = ({
  topicEntries = [],
}): (string | Option)[] => {
  try {
    const topics = topicEntries.reduce((topics, entry) => {
      if (entry?.fields?.['topic'])
        topics.push(entry?.fields['topic'].split(' ').join('-'));
      return topics;
    }, []);

    return topics;
  } catch (e) {
    throw new Error(e);
  }
};

export const searchBasePath = ({
  queryString = '',
  withQueryParams = true,
  pageNumber = null,
  topic = '',
} = {}) => {
  let path = `${RESOURCES_PATH}/${
    topic === 'all topics' ? '' : topic.toLowerCase().split(' ').join('-')
  }/search/`;
  if (withQueryParams) path += queryString;
  if (pageNumber) path += `&page=${pageNumber}`;

  return path;
};

export const getFeaturedArticleSlug = async ({ topicEntry }) => {
  const featuredArticle = topicEntry?.fields?.components?.find((component) => {
    return component.sys.contentType.sys.id === CmsComponents.LEGACY_CAROUSEL;
  });

  const featuredArticleSlides = featuredArticle?.fields?.carouselSlides || [];

  const featuredArticleUrl = featuredArticleSlides[0]?.fields?.body?.content.find(
    (content) => {
      return content.nodeType === 'embedded-entry-block';
    },
  ).data?.target?.fields?.url;

  const slugMatches = featuredArticleUrl?.match(/\/([a-z0-9-]+)\/?$/) || [];

  return slugMatches[1] || null;
};
