import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DiagramStep } from 'src/components/CustomerStories/Diagram';
import { DiagramStepShape } from 'src/components/CustomerStories/Diagram/DiagramStep';
import { AnimatedGroup } from 'src/components/helpers';

// helpers
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';
import { optimizeImage } from 'src/lib/image-helpers';

const propTypes = {
  classes: PropTypes.string,
  'stroke-top-right': PropTypes.string,
  'stroke-bottom-right': PropTypes.string,
  'stroke-bottom-left': PropTypes.string,
  'stroke-large-top-right': PropTypes.string,
  'stroke-large-bottom-left': PropTypes.string,
  'medium-column-width': PropTypes.number,
  heading: PropTypes.string.isRequired,
  'intro-text': PropTypes.string,
  'icon-row-1': PropTypes.arrayOf(PropTypes.shape(DiagramStepShape)).isRequired,
  'icon-row-2': PropTypes.arrayOf(PropTypes.shape(DiagramStepShape)).isRequired,
  'icon-row-3': PropTypes.arrayOf(PropTypes.shape(DiagramStepShape)).isRequired,
  step: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      copy: PropTypes.string.isRequired,
      delay: PropTypes.number,
      'hr-color': PropTypes.string,
    }).isRequired,
  ).isRequired,
};

const DiagramBeta = (props) => {
  return (
    <div
      className={cx('diagram', props['classes'])}
      style={{
        backgroundImage: `
        ${
          Boolean(props['stroke-top-right'])
            ? `url(${optimizeImage(props['stroke-top-right'])})`
            : 'none'
        },
        ${
          Boolean(props['stroke-bottom-right'])
            ? `url(${optimizeImage(props['stroke-bottom-right'])})`
            : 'none'
        },
        ${
          Boolean(props['stroke-bottom-left'])
            ? `url(${optimizeImage(props['stroke-bottom-left'])})`
            : 'none'
        },
        ${
          Boolean(props['stroke-large-top-right'])
            ? `url(${optimizeImage(props['stroke-large-top-right'])})`
            : 'none'
        },
        ${
          Boolean(props['stroke-large-bottom-left'])
            ? `url(${optimizeImage(props['stroke-large-bottom-left'])})`
            : 'none'
        }
      `,
      }}
    >
      <div className='grid-container section-container'>
        <div className='grid-x'>
          <div
            className={`cell small-12 medium-${
              props['medium-column-width'] || '6'
            } large-6`}
          >
            <h4>{props['heading']}</h4>
            {props['intro-text'] && (
              <p dangerouslySetInnerHTML={{ __html: props['intro-text'] }} />
            )}
          </div>
        </div>
        <AnimatedGroup className='grid-x diagram__grid'>
          <div className='cell small-12 medium-8'>
            <div className='diagram__steps-graphics-container'>
              <div className='diagram__row'>
                {props['icon-row-1'].map((item, i) => (
                  <DiagramStep {...item} key={i} />
                ))}
              </div>
              <div className='diagram__row'>
                {props['icon-row-2'].map((item, i) => (
                  <DiagramStep {...item} key={i} />
                ))}
              </div>
              <div className='diagram__row'>
                {props['icon-row-3'].map((item, i) => (
                  <DiagramStep {...item} key={i} />
                ))}
              </div>
            </div>
          </div>
          <div className='cell small-12 medium-4'>
            <div className='diagram__steps-copy-container'>
              {props['step'].map((item, i) => (
                <React.Fragment key={i}>
                  <div
                    className={cx(
                      animationStyles['animated-group__animation'],
                      animationStyles['animated-group__animation--slide-up-16'],
                      animationStyles[
                        'animated-group__animation--duration-300'
                      ],
                      {
                        [animationStyles[
                          `animated-group__animation--delay-${item.delay}`
                        ]]: item.delay,
                      },
                    )}
                  >
                    <hr
                      className={`hr diagram__hr ${
                        item['hr-color'] ? `hr--color-${item['hr-color']}` : ''
                      }`}
                    />
                    <div className='diagram__number-column'>
                      <strong>{item['number']}</strong>
                    </div>
                    <div className='diagram__copy-column'>{item['copy']}</div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </AnimatedGroup>
      </div>
    </div>
  );
};

DiagramBeta.propTypes = propTypes;
export default DiagramBeta;
