export const metaData = {
  metaTitle: 'Carvana',
  metaDescription:
    'Before Plaid, Carvana&apos;s customers were dropping out of their checkout flow because it took too long. Now they can link their bank accounts in a matter of seconds.',
};

export const pageHeaderData = {
  classes:
    'background background--color-blue600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-blue-2@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Carvana',
  header: 'Accelerating the future of auto payments',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Carvana-Case-Study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-carvana@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-carvana@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Enable ACH payments,<br />prevent NSF/overdraft fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Automotive',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Carvana’s customers were dropping out of their checkout flow because it took too long, and there was too much friction.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Carvana’s customers can connect their bank accounts and start making affordable ACH payments in a matter of seconds. Even better, Plaid’s balance checks prevent painful NSF fees.',
};

export const content1Data = {
  heading:
    'In allowing customers to browse, finance, and trade used cars exclusively through its website, Carvana offers a whole new way to shop for a vehicle.',
  paragraph: [
    'The company’s rigorously inspected and reconditioned inventory comes without the haggling, middleman markups, or time commitment associated with traditional dealerships. What’s more, Carvana’s unique business model puts control in the hands of customers.',
    'Individuals can trade in a car, closely examine Carvana’s extensive selection, secure financing, and complete a purchase any time of day or night, on any device, and from any location. And unlike many of its analog competitors, Carvana offers home vehicle delivery or Car Vending Machine pickup and a seven-day money-back guarantee on all purchases.',
    'But to live up to their promise of being a better way to shop, they needed a financial technology solution to modernize and simplify their digital payment process.',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-blue-1@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-blue-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Carvana’s onboarding flow asks users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Carvana’s users can start paying with ACH.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content2Data = [
  {
    title: 'A payments problem',
    paragraph: [
      'Carvana’s payment process needed an upgrade. Up to that point, the then-four-year-old company had relied on traditional ACH to clear down payments. That meant customers had to first locate their personal checkbooks and then figure out how to input their correct routing and account numbers into Carvana’s system.',
      'Carvana’s customers found the payment experience cumbersome and inconvenient, and they often made mistakes and typos that invalidated payments.',
      'ACH also resulted in inefficiencies for Carvana’s operations team. In many instances, Carvana was required to hold three-way calls with customers and bank representatives to prove sufficient funds. The calls could last up to 30 minutes and had to be scheduled during standard bank hours, which many customers had difficulty accommodating in their schedules. ',
      'As Carvana grew to about $900 million in revenue, the simple act of verifying balances required an unsustainable allocation of resources.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-carvana@2x.png',
      alt: 'Headshot of Wylie Kyi, Senior Product Manager at Carvana',
    },
    quote: [
      {
        copy:
          'With Plaid, our customers don’t have to get out their checkbooks. They sign in with the same bank username and password they use every day.',
        source: 'Wylie Kyi, Senior Product Manager, Carvana',
      },
    ],
  },
  {
    title: 'A Plaid solution',
    paragraph: [
      'In response to its escalating inefficiencies, Carvana considered building its own processing platform—but ultimately decided that it was beyond the company’s in-house expertise.',
      'After extensively researching the market, the Carvana team selected Plaid for its ease of integration, superior usability, and projected cost savings. Today, Plaid’s Identity, Balance, and Transactions products fuel Carvana’s market-leading usability.',
      '“With Plaid, our customers don’t have to get out their checkbooks. They sign in with the same bank username and password they use every day.” said Wylie Kyi, senior product manager at Carvana. “That’s a huge win.”',
      'For Carvana, Plaid also comes with many non-public-facing advantages. With Plaid Balance, for example, Carvana instantly knows if a customer’s available funds are enough to cover down payment, preventing overdrafts and costly NSF fees.',
      '“Plaid lets us perform real-time funds verification,” said Kyi. “It gives us peace of mind before we ever move the vehicle.”',
    ],
  },
  {
    quote: [
      {
        copy:
          'The technology is simple and gives us so much more velocity. It took only a month for basic implementation, and another two months to use the full suite of products.',
        source: 'Wylie Kyi, Senior Product Manager, Carvana',
      },
    ],
  },
  {
    title: 'Driving profitability',
    paragraph: [
      'With Plaid Transactions, Carvana has access to up to 24 months of transaction history for customers who opt to finance through Carvana.',
      'This enables the company to more easily confirm the stability of a customer’s income, reducing what was once a three-day approval process to two days. “Plaid gives us more confidence in the documents we ask customers for, so we don’t have to request W2s as often as we used to,” said Kyi.',
      'Plaid also benefits Carvana’s bottom line. According to Kyi, preliminary models show that the company stands to save a significant amount of money within one year of its transition to Plaid. For Kyi, that impact is especially impressive when he considers the low cost of setup and how easy it was to configure Plaid to Carvana’s platform.',
      '“The technology is simple and gives us so much more velocity,” he said. “It took only a month for basic implementation and another two months to really use the full suite of products, because we took our time experimenting to understand how Plaid was affecting our customers.”',
      '<strong>When asked what advice he would give to others considering Plaid’s API for their products, he offered: “I’d say just look at the documentation. Everything is perfectly laid out, and any engineer, whether they’re junior or senior, will go into it and be able to understand how to hit these endpoints. Even I can do it.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Alto',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-alto@2x.png',
        alt: 'Alto - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/alto',
    },
    {
      name: 'Shift',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-shift@2x.png',
        alt: 'Shift - read full story',
      },
      excerpt: 'Shifting the used car landscape',
      url: '/customer-stories/shift',
    },
    {
      name: 'Placid',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-placid@2x.png',
        alt: 'Placid - read full story',
      },
      excerpt: 'Fighting friction, fees, and fraud',
      url: '/customer-stories/placid-express',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Carvana-Case-Study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['diagramData', diagramData],
  ['content2Data', content2Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
