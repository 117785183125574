import React from 'react';
import PropTypes from 'prop-types';

import { JOB_DATA, PROFILES } from 'src/lib/constants';

import { Feature } from '../';

/**
 * @param {string} team
 * @param {string} location
 * @returns {Object} The profile object
 */
export function getProfile(team, location) {
  if (team in JOB_DATA) {
    const {
      profiles: { byLocation, locationDependent },
    } = JOB_DATA[team];
    if (locationDependent && byLocation[location]) {
      return PROFILES[byLocation[location]];
    }
    return PROFILES[byLocation.all] || PROFILES.default;
  }
  return PROFILES.default;
}

const propTypes = {
  categories: PropTypes.shape({
    team: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
};

const FeaturedPerson = ({ categories: { team, location } }) => {
  const { name, title, bio, image, url } = getProfile(team, location);
  const featureProps = {
    heading: `${name} - <span class="job-post-feature__role">${title}</span>`,
    altText: `image of Plaid Person, ${name}`,
    content: bio,
    linkUrl: url,
    linkText: "Meet Plaid's People",
    image,
  };
  return (
    <article className='job-post-feature job-post-feature--employee'>
      <Feature {...featureProps} />
    </article>
  );
};

FeaturedPerson.propTypes = propTypes;
export default FeaturedPerson;
