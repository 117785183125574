const codeBody = `http code 200
{
  "report": {
    "asset_report_id": "c5b638f9-02b8-45c6-9093-552195149b0c",
    "client_report_id": "123456",
    "date_generated": "2018-04-12T03:32:11Z",
    "days_requested": 730,
    "items": [
      {
        "accounts": [
          {
            "account_id": "jW4r4QvdeXcAZNj8J3Dni68DqxQ3laHZwGyBD",
            "balances": {
              "available": 100,
              "current": 110
            },
            "days_available": 730,
            "historical_balances": [
              {
                "current": 110,
                "date": "2018-04-12",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 110,
                "date": "2018-04-11",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 110,
                "date": "2018-04-10",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              ...
            ],
            "mask": "0000",
            "name": "Plaid Checking",
            "official_name": "Plaid Gold Standard 0% Interest Checking",
            "owners": [
              {
                "addresses": [
                  {
                    "data": {
                      "city": "Malakoff",
                      "region": "NY",
                      "street": "2992 Cameron Road",
                      "postal_code": "14236",
                      "country": "US"
                    },
                    "primary": true
                  },
                  {
                    "data": {
                      "city": "San Matias",
                      "region": "CA",
                      "street": "2493 Leisure Lane",
                      "postal_code": "93405-2255",
                      "country": "US"
                    },
                    "primary": false
                  }
                ],
                "emails": [
                  {
                    "data": "accountholder0@example.com",
                    "primary": true,
                    "type": "primary"
                  },
                  {
                    "data": "accountholder1@example.com",
                    "primary": false,
                    "type": "secondary"
                  },
                  {
                    "data": "extraordinarily.long.email.username.123456@reallylonghostname.com",
                    "primary": false,
                    "type": "other"
                  }
                ],
                "names": [
                  "Alberta Bobbeth Charleson"
                ],
                "phone_numbers": [
                  {
                    "data": "1112223333",
                    "primary": false,
                    "type": "home"
                  },
                  {
                    "data": "1112224444",
                    "primary": false,
                    "type": "work"
                  },
                  {
                    "data": "1112225555",
                    "primary": false,
                    "type": "mobile1"
                  }
                ]
              }
            ],
            "subtype": "checking",
            "transactions": [
              ...
              {
                "account_id": "jW4r4QvdeXcAZNj8J3Dni68DqxQ3laHZwGyBD",
                "amount": -500,
                "date": "2018-03-25",
                "original_description": "United Airlines  REFUND ",
                "pending": false,
                "transaction_id": "wPkQknvpz9HV8bPK465rC6GnyrnlrXCMr9vgm",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "jW4r4QvdeXcAZNj8J3Dni68DqxQ3laHZwGyBD",
                "amount": 5.4,
                "date": "2018-03-27",
                "original_description": "Uber 063015 SFPOOL",
                "pending": false,
                "transaction_id": "GM4K4LJqGBu5g8yqjkQJuVnry6rz6zh8Qwkv9",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "jW4r4QvdeXcAZNj8J3Dni68DqxQ3laHZwGyBD",
                "amount": 6.33,
                "date": "2018-04-09",
                "original_description": "Uber 072515 SFPOOL",
                "pending": false,
                "transaction_id": "DekykZJWrjf5g7kjRaLvceLZb4avg5CVBDGz5x",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              }
            ],
            "type": "depository"
          },
          {
            "account_id": "V3EkERBQXGF5z6Jb9NDkupNeoy1b81cjpWVDe",
            "balances": {
              "available": 200,
              "current": 210
            },
            "days_available": 730,
            "historical_balances": [
              {
                "current": 210,
                "date": "2018-04-12",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 210,
                "date": "2018-04-11",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 210,
                "date": "2018-04-10",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              ...
            ],
            "mask": "1111",
            "name": "Plaid Saving",
            "official_name": "Plaid Silver Standard 0.1% Interest Saving",
            "owners": [
              {
                "addresses": [
                  {
                    "data": {
                      "city": "Malakoff",
                      "region": "NY",
                      "street": "2992 Cameron Road",
                      "postal_code": "14236",
                      "country": "US"
                    },
                    "primary": true
                  },
                  {
                    "data": {
                      "city": "San Matias",
                      "region": "CA",
                      "street": "2493 Leisure Lane",
                      "postal_code": "93405-2255",
                      "country": "US"
                    },
                    "primary": false
                  }
                ],
                "emails": [
                  {
                    "data": "accountholder0@example.com",
                    "primary": true,
                    "type": "primary"
                  },
                  {
                    "data": "accountholder1@example.com",
                    "primary": false,
                    "type": "secondary"
                  },
                  {
                    "data": "extraordinarily.long.email.username.123456@reallylonghostname.com",
                    "primary": false,
                    "type": "other"
                  }
                ],
                "names": [
                  "Alberta Bobbeth Charleson"
                ],
                "phone_numbers": [
                  {
                    "data": "1112223333",
                    "primary": false,
                    "type": "home"
                  },
                  {
                    "data": "1112224444",
                    "primary": false,
                    "type": "work"
                  },
                  {
                    "data": "1112225555",
                    "primary": false,
                    "type": "mobile1"
                  }
                ]
              }
            ],
            "subtype": "savings",
            "transactions": [
              ...
              {
                "account_id": "V3EkERBQXGF5z6Jb9NDkupNeoy1b81cjpWVDe",
                "amount": 25,
                "date": "2018-02-25",
                "original_description": "CREDIT CARD 3333 PAYMENT //",
                "pending": false,
                "transaction_id": "LkexeEJ3Ryf5Vw3evRMMIXlLjkRkawIdzm6Ak",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "V3EkERBQXGF5z6Jb9NDkupNeoy1b81cjpWVDe",
                "amount": -4.22,
                "date": "2018-03-22",
                "original_description": "INTRST PYMNT",
                "pending": false,
                "transaction_id": "y7EJEmwgazsjJDVdMZ3QSQBKpaKnQzSjElP71M",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "V3EkERBQXGF5z6Jb9NDkupNeoy1b81cjpWVDe",
                "amount": 25,
                "date": "2018-03-27",
                "original_description": "CREDIT CARD 3333 PAYMENT //",
                "pending": false,
                "transaction_id": "dLz7z41KlaSWLbmJqpxXiaqPgJPbn5IgMwymJ",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              }
            ],
            "type": "depository"
          },
          {
            "account_id": "4RWyWq143jIEm7BaqJNKCQyG9Ndnv5iJaENyV",
            "balances": {
              "available": null,
              "current": 5000
            },
            "days_available": 730,
            "historical_balances": [
              {
                "current": 5000,
                "date": "2018-04-12",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 1000,
                "date": "2018-04-11",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "current": 1000,
                "date": "2018-04-10",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              ...
            ],
            "mask": "2222",
            "name": "Plaid CD",
            "official_name": "Plaid Bronze Standard 0.2% Interest CD",
            "owners": [
              {
                "addresses": [
                  {
                    "data": {
                      "city": "Malakoff",
                      "region": "NY",
                      "street": "2992 Cameron Road",
                      "postal_code": "14236",
                      "country": "US"
                    },
                    "primary": true
                  },
                  {
                    "data": {
                      "city": "San Matias",
                      "region": "CA",
                      "street": "2493 Leisure Lane",
                      "postal_code": "93405-2255",
                      "country": "US"
                    },
                    "primary": false
                  }
                ],
                "emails": [
                  {
                    "data": "accountholder0@example.com",
                    "primary": true,
                    "type": "primary"
                  },
                  {
                    "data": "accountholder1@example.com",
                    "primary": false,
                    "type": "secondary"
                  },
                  {
                    "data": "extraordinarily.long.email.username.123456@reallylonghostname.com",
                    "primary": false,
                    "type": "other"
                  }
                ],
                "names": [
                  "Alberta Bobbeth Charleson"
                ],
                "phone_numbers": [
                  {
                    "data": "1112223333",
                    "primary": false,
                    "type": "home"
                  },
                  {
                    "data": "1112224444",
                    "primary": false,
                    "type": "work"
                  },
                  {
                    "data": "1112225555",
                    "primary": false,
                    "type": "mobile1"
                  }
                ]
              }
            ],
            "subtype": "cd",
            "transactions": [
              ...
              {
                "account_id": "4RWyWq143jIEm7BaqJNKCQyG9Ndnv5iJaENyV",
                "amount": 1000,
                "date": "2018-01-25",
                "original_description": "CD DEPOSIT .INITIAL.",
                "pending": false,
                "transaction_id": "M8QdQWJx1GS51ZymxjNNILQ7GwjwG1I3qr3RwX",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "4RWyWq143jIEm7BaqJNKCQyG9Ndnv5iJaENyV",
                "amount": 1000,
                "date": "2018-02-24",
                "original_description": "CD DEPOSIT .INITIAL.",
                "pending": false,
                "transaction_id": "q3Z4ZgvempFnljLVzqxxhPQ3we7eW9sdJy5jP",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              },
              {
                "account_id": "4RWyWq143jIEm7BaqJNKCQyG9Ndnv5iJaENyV",
                "amount": 1000,
                "date": "2018-03-26",
                "original_description": "CD DEPOSIT .INITIAL.",
                "pending": false,
                "transaction_id": "aZqNqxQAEyHvboB8jazrsbPWNwW6WxU8zRj5kw",
                "iso_currency_code": "USD",
                "unofficial_currency_code": null
              }
            ],
            "type": "depository"
          }
        ],
        "date_last_updated": "2018-04-12T03:32:10Z",
        "institution_id": "ins_109511",
        "institution_name": "Tartan Bank",
        "item_id": "zeWoWyv84xfkGg1w4ox5iQy5k6j75xu8QXMEm"
      }
    ],
    "user": {
      "client_user_id": "123456789",
      "email": "accountholder0@example.com",
      "first_name": "Alberta",
      "last_name": "Charleson",
      "middle_name": "Bobbeth",
      "phone_number": "111-222-3333",
      "ssn": "123-45-6789"
    }
  },
  "request_id": "pdwYD",
  "warnings": []
}
`;

export const interactiveData = {
  'title-text': 'Make an Asset Report request',
  'description-text': 'Use the Assets endpoint to submit a POST request',
  'code-title': 'Retrieve Asset Report request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/asset_report/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n  "include_insights": Boolean\n}\'\n',
  'post-run-title-text': 'Asset data',
  'post-run-description-text':
    'The <strong>/asset_report/get</strong> or <strong>/asset_report/pdf/get</strong> endpoints allow you to retrieve a consolidated summary of account balances and transactions in JSON or PDF formats',
  'post-run-code-title': 'Retrieve Asset Report response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      title: 'historical_balance',
      description:
        'Calculated data about the historical balances on the account',
      'code-line-start': 18,
      'code-highlight-length': 21,
    },
    {
      title: 'owners',
      description:
        'Account owner(s)’ addresses, emails, names, and phone numbers',
      'code-line-start': 42,
      'code-highlight-length': 61,
    },
    {
      title: 'transactions',
      description:
        'Transaction data that includes date, description, amount, and category',
      'code-line-start': 106,
      'code-highlight-length': 33,
    },
    {
      title: 'account_type',
      description:
        'Type of account such as depository, credit, brokerage, and loan',
      'code-line-start': 139,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/assets/',
  'bottom-cta-text': 'View API docs',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-url-new-tab': '/documents/sample-asset-report.pdf',
};
