import React, { FC } from 'react';
import ThreadsColumn from 'plaid-threads/Column';
import styles from './Column.module.scss';
import cx from 'classnames';
import { Column as ColumnProps } from './Column.types';

const Column: FC<ColumnProps> = ({
  children,
  useThreadsGrid = true,
  noMarginRight = false,
  allowOverflow = false,
  small = null,
  medium = null,
  large = null,
  xlarge = null,
  smallOrder = null,
  mediumOrder = null,
  className = '',
  ...props
}) =>
  useThreadsGrid ? (
    <ThreadsColumn
      className={className}
      small={small}
      medium={medium}
      large={large}
      xlarge={xlarge}
      smallOrder={smallOrder}
      mediumOrder={mediumOrder}
      noMarginRight={noMarginRight}
      allowOverflow={allowOverflow}
      testId='threads-column'
      {...props}
    >
      {children}
    </ThreadsColumn>
  ) : (
    <div
      className={cx(
        'cell',
        small === 'hide' ? 'hide-for-small-only' : small && `small-${small}`,
        medium === 'hide'
          ? 'hide-for-medium-only'
          : medium && `medium-${medium}`,
        large && `large-${large}`,
        xlarge && `xlarge-${xlarge}`,
        Number.isInteger(smallOrder) && `small-order-${smallOrder}`,
        Number.isInteger(mediumOrder) && `medium-order-${mediumOrder}`,
        large === 'hide' && 'hide-for-large-only' && styles.largeHide,
        className,
      )}
      data-testid='foundation-column'
      {...props}
    >
      {children}
    </div>
  );

export default Column;
