import React from 'react';

import { BLOCKS } from '@contentful/rich-text-types';
import { S } from 'src/components-v2/StyledElements/StyledElements';

const ListItem = ({ li }) => {
  return {
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <S.Li {...li}>{children}</S.Li>;
    },
  };
};

export default ListItem;
