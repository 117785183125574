export const metaData = {
  metaTitle: 'Alto',
  metaDescription:
    'Alto was getting crushed by sky-high credit card fees. With ACH payments powered by Plaid, they save $20,000 per month.',
};

export const pageHeaderData = {
  classes:
    'background background--color-purple400 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-blue-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-text': 'Customer story: Alto',
  header:
    'A pharmacy for the<br class="show-for-medium" /><span class="show-for-small-only"> </span>digital age',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/alto-case-study.pdf',
  video: false,
  image: true,
  'image-width': 669,
  'image-height': 389,
  'image-url': '/assets/img/customer-stories/header-hero-alto@2x.png',
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-alto@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Enable ACH payments,<br />reduce transaction fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Pharmacy',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Alto was getting crushed by sky-high credit card fees, paying as much as $150 to process a single high-value payment.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With ACH powered by Plaid, Alto saves an average of $50 on every high-value transaction. That translates to a monthly savings of $20,000—and growing.',
};

export const content1Data = {
  heading:
    'Alto is a digital pharmacy that makes prescription medications more accessible for more than 50,000 patients in three U.S. cities.',
  paragraph: [
    'Since its founding in 2015, it has experienced tremendous growth—as well as a few growing pains. To wit: sky-high credit card fees for its higher-value transactions.',
    'By partnering with Plaid to enable ACH payments, Alto saves money (on average, $50 per transaction over $1000) that can be reinvested in the business and passed on to customers.',
  ],
};

export const supportingTextData = {
  classes:
    'background background--color-purple200 supporting-text--constrained',
  'two-up': true,
  stat: true,
  stat2: '$20K',
  text2:
    'With ACH powered by Plaid, Alto saves $20,000 per month at its current volume.',
  stat3: '$50',
  text3:
    'By integrating with Plaid, Alto saves $50 on each of its high-value transactions (>$1,000).',
};

export const content2Data = {
  title: 'Patient-first technology',
  paragraph: [
    'Back in 2015, Alto co-founders Jamie Karraker and Mattieu Gamache-Asselin were working as engineers at a prominent tech firm.',
    'They enjoyed what they were doing, but they dreamt of starting a company that would make a meaningful impact in people’s daily lives. That led them to pharmacy, where they quickly learned that their experience in consumer products could create a competitive advantage.',
    '“Most of the incumbents weren’t taking the product-focused approach of prioritizing and optimizing the user experience,” Karraker explained in a recent interview. “We knew there was a big opportunity.”',
    'Seizing that opportunity meant building new technology to address the industry’s foundational challenge: coordinating communication among doctors, insurance companies, and patients.',
    '“Historically, all of that communication has happened by fax and phone call,” said Karraker. “As you might expect, it breaks constantly; it’s really slow; and things fall through the cracks. And patients are the ones who end up having to deal with it.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right':
    '/assets/img/accents/diagram-brushstroke-perfume-1@2x.png',
  'stroke-bottom-right':
    '/assets/img/accents/diagram-brushstroke-perfume-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Alto’s checkout flow allows users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Alto’s customers can start paying with ACH.',
    },
  ],
  'icon-row-1': [
    {
      label: 'LOAN REQUEST',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-request-purple@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-front-end-purple@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'PLAID BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-purple@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img: '/assets/img/customer-stories/diagram-step-piggy-bank-purple@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Better living through automation',
    paragraph: [
      'To solve that problem, Karraker and Gamache-Asselin built software that integrates deeply with insurance companies, clinics, hospitals, and other industry middlemen. At the same time, they created a patient platform for desktop and mobile where individuals can manage every aspect of their pharmaceutical care.',
      'Doctors send a prescription to Alto electronically, which triggers Alto to text the patient. The patient clicks a registration link, then schedules delivery for their medication. Most information is supplied to Alto by the doctor’s office, so it usually only takes a few minutes. That same day, the prescription is dropped off at the patient’s door—with no delivery fee.',
      'Orders are filled from a centralized location according to a highly automated process. In fact, a single Alto location can handle the order volume of upward of 25 retail pharmacies. These and other efficiencies enable Alto’s 240 employees, including dozens of pharmacists and pharmacy techs, to dedicate more of their time to building a better customer experience.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-alto@2x.png',
      alt: 'Headshot of Jamie Karraker, Co-founder and CTO of Alto',
    },
    quote: [
      {
        copy:
          'Plaid helps us save money on credit card fees while giving patients more flexibility about their payment options.',
        source: 'Jamie Karraker, Co-founder and CTO, Alto',
      },
    ],
  },
  {
    title: 'Improved UX, big savings',
    paragraph: [
      'As Alto began to treat more complex illnesses, the number of therapies not covered by insurance increased. That led to more patients making large out-of-pocket payments by credit card. Over time, high-value transactions (>$1,000) came to make up 5% of its orders, and the credit card fees started adding up.',
      '“The credit card fee on a $10,000 transaction is a very meaningful dollar amount for a business like ours,” Karraker said. “We needed to find a way to save money on fees while giving patients more flexibility about their payment options.”',
      'To address that problem, Alto began offering feeless ACH as a payment option for patients. The problem, its founders discovered, was that most ACH products rely on clumsy, confusing microdeposits to link an account—hardly the seamless experience they wanted for their customers. Then they found Plaid.',
      '“Plaid’s ability to instantly authenticate direct deposit has become a seamless part of our typical checkout flow,” said Karraker. “Just a few clicks, and it’s all done."',
    ],
  },
  {
    title: 'A seamless integration',
    paragraph: [
      'After just a month of back-end work, Alto was fully integrated with Plaid Auth. The benefits were immediate.',
      'In encouraging patients to switch from credit cards to ACH powered by Plaid, Alto saves an average of $50 in credit card fees per order over $1,000. In some cases, they have saved as much as $150 per order—and that’s after they have passed savings along to patients.',
      'As more patients choose ACH over credit, Plaid has the potential to save Alto $20,000 per month at its current volume, potential that will only grow as the company scales up and expands to more cities.',
      'Alto shares a portion of those savings with patients who choose ACH in the form of an incentive discount. It reinvests the remaining marginal revenue in the company’s future.',
      '<strong>“Improvements to our margins are extremely valuable to our business because they allow us to invest in faster growth and in a better experience for our patients,” Karraker explained. “The better our margins, the more quickly we can help more patients lead healthier, happier lives.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Carvana',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-carvana@2x.png',
        alt: 'Carvana - read full story',
      },
      excerpt: 'Accelerating the future of auto payments',
      url: '/customer-stories/carvana',
    },
    {
      name: 'Placid',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-placid@2x.png',
        alt: 'Placid - read full story',
      },
      excerpt: 'Fighting friction, fees, and fraud',
      url: '/customer-stories/placid-express',
    },
    {
      name: 'Shift',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-shift@2x.png',
        alt: 'Shift - read full story',
      },
      excerpt: 'Shifting the used car landscape',
      url: '/customer-stories/shift',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/alto-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
