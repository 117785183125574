import React, { FC, useContext } from 'react';

// helpers
import { replaceHrefValueWithLocale } from 'src/lib/utils';

// contexts
import { LocaleContext } from 'src/contexts';

interface Props {
  body: string;
}

const RawHtml: FC<Props> = ({ body }) => {
  const locale = useContext(LocaleContext);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: replaceHrefValueWithLocale({ locale, str: body }),
      }}
    />
  );
};

export default RawHtml;
