import React from 'react';

import { InteractiveHotSpot } from 'src/components/Enhancers/withAnimatedResponse/InteractiveHotSpot';
import { LineHighlight } from 'src/components/Enhancers/withAnimatedResponse/LineHighlight';
import { DeviceContext } from 'src/contexts';

interface CodeProperties {
  title: string;
  description: string;
  'code-line-start': number;
  'code-highlight-length': number;
}

interface Props {
  properties: Array<CodeProperties>;
  parentContainer: HTMLDivElement;
  isMobile?: boolean;
  isHidden?: boolean;
  isHiddenOnMobile?: boolean;
  handleEvent(): void;
}

const InteractiveElements: React.FC<Props> = ({
  properties,
  parentContainer,
  isMobile = false,
  isHidden = false,
  isHiddenOnMobile = false,
  handleEvent,
}) => {
  return (
    <DeviceContext.Consumer>
      {(device) => {
        if (isHiddenOnMobile && !device.isLarge) {
          return null;
        }

        return properties.map((property, index) => {
          return (
            <React.Fragment key={`${isMobile ? 'mobile-' : ''}ie-${index}`}>
              <InteractiveHotSpot
                index={index}
                property={property}
                handleEvent={handleEvent}
                parentContainer={parentContainer}
                isMobile={isMobile}
                isHidden={isHidden}
              />
              <LineHighlight
                index={index}
                property={property}
                isHidden={isHidden}
              />
            </React.Fragment>
          );
        });
      }}
    </DeviceContext.Consumer>
  );
};

export default InteractiveElements;
