import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'src/components/headers/MvpPageHeader/PageHeader--cms.module.scss';

const HeaderLabelProps = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const HeaderLabel = ({ children, className = '' }) => (
  <h6 data-testid='header-label' className={cx(styles.label, className)}>
    {children}
  </h6>
);

HeaderLabel.propTypes = HeaderLabelProps;

export default HeaderLabel;
