import React, { FC } from 'react';
import ChevronRightS1 from 'plaid-threads/Icons/ChevronRightS1';

// components
import { Box } from 'src/components-v2/Layout';

interface Props {
  isHover: boolean;
  isCaretActive?: boolean;
}
const AnimatedCaret: FC<Props> = ({ isHover, isCaretActive }) => {
  return (
    <Box
      component='span'
      sx={{
        width: '16px',
        height: '16px',
        bottom: '4px',
        ml: '1rem',
        position: 'relative',
      }}
    >
      <Box
        component='span'
        sx={{
          position: 'absolute',
          opacity: isCaretActive ? (isHover ? 0 : 1) : 1,
          transform: `translateX(${
            isCaretActive ? (isHover ? '.8rem' : 0) : 0
          })`,
          transition: isCaretActive
            ? 'transform cubic-bezier(0.23, 1.2, 0.32, 1) 1400ms, opacity cubic-bezier(0.23, 1.2, 0.32, 1) 1400ms'
            : '',
        }}
      >
        <ChevronRightS1 />
      </Box>
      {isCaretActive && (
        <Box
          component='span'
          sx={{
            position: 'absolute',
            opacity: isHover ? 1 : 0,
            transform: `translateX(${isHover ? 0 : '-0.8rem'})`,
            transition:
              'transform cubic-bezier(0.23, 1.2, 0.32, 1) 1400ms, opacity cubic-bezier(0.23, 1.2, 0.32, 1) 1400ms',
          }}
        >
          <ChevronRightS1 />
        </Box>
      )}
    </Box>
  );
};

export default AnimatedCaret;
