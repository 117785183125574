import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

// components
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import Footer from 'src/components/footer';
import { ctaBlockWithButtonsAdapter } from 'src/components/CtaBlockWithButtons';
import { PaywallProvider, usePaywall } from 'src/components/Surfaces';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

// helpers
import { ContentfulTemplates, Locales } from 'src/lib/constants';

const CustomerStoryScripts = dynamic(
  () => {
    return import(
      'src/templates/CustomerStoryTemplate/CustomerStoryTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const propTypes = {
  fields: PropTypes.shape({
    components: PropTypes.arrayOf(PropTypes.object).isRequired,
    metaDescription: PropTypes.string.isRequired,
    metaTitle: PropTypes.string.isRequired,
    customFooterCta: PropTypes.object,
    relatedStories: PropTypes.arrayOf(PropTypes.object),
    openGraphImage: PropTypes.string,
  }),
};

const CustomerStoryTemplate = ({ fields, locale, ...props }) => {
  const { route } = useRouter();
  const metaData = {
    metaTitle: _get(fields, 'metaTitle', fields['meta-title']),
    metaDescription: _get(
      fields,
      'metaDescription',
      fields['meta-description'],
    ),
  };

  const { paywallComponent } = usePaywall(fields?.components);

  return (
    <TemplateWrapper
      meta-title={metaData?.metaTitle}
      meta-description={metaData?.metaDescription}
      hasFooterCta={false}
      exclude-footer={true}
      locale={locale || Locales.EN_US}
      openGraphImage={fields?.openGraphImage?.fields?.file?.url}
    >
      <PaywallProvider
        cookieName='customerStories'
        unlockKey={paywallComponent?.fields?.secretKey}
      >
        <div
          className='customer-story'
          data-testid={ContentfulTemplates.CUSTOMER_STORY}
        >
          {props.children}
          {fields?.components && (
            <CmsComponentSwitch
              components={fields.components}
              fields={fields}
              templatePage={ContentfulTemplates.CUSTOMER_STORY}
            />
          )}
          {fields?.customFooterCta && (
            <Footer
              {...ctaBlockWithButtonsAdapter(fields?.customFooterCta.fields)}
            />
          )}
        </div>
        <CustomerStoryScripts route={route} {...props} />
        <ContentfulToolbar />
      </PaywallProvider>
    </TemplateWrapper>
  );
};
CustomerStoryTemplate.propTypes = propTypes;

export default CustomerStoryTemplate;
