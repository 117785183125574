import {
  defaultCRAFISearchFields,
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Submit a dispute',
      'meta-description': 'Submit a dispute',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Submit a dispute',
    introHeader: 'Introduction',
    intro: `<p>Complete this form to dispute data that appears in your Plaid Check consumer file. To share a concern about data appearing in any other Plaid Inc. services, please visit the <a href="https://support-my.plaid.com/hc/en-us">Plaid Inc. Consumer Help Center</a>.</p>
            <p>${defaultCRAFormIntro}</p>`,
    requestType: 'Dispute',
    ...defaultCRAFormFields,
    ...defaultCRAFISearchFields,
    requestDetails: {
      label:
        'Please describe the data that you are disputing and the reason for your dispute',
      required: true,
    },
  },
};
