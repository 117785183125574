import React, { FC } from 'react';
import { useTrack } from 'src/contexts/AnalyticsContext';
import Modal from 'plaid-threads/deprecated/Modal'; // eslint-disable-line
import ModalBody from 'plaid-threads/deprecated/ModalBody'; // eslint-disable-line
import { PAGE } from 'src/lib/trackingEvents';

import styles from './ModalPane.module.scss'; // test

export interface ModalPaneItemProps {
  children: React.ReactNode;
  setIsModalOpen: (arg: boolean) => void;
  isModalOpen: boolean;
  trackingId?: string;
}

const ModalPane: FC<ModalPaneItemProps> = ({
  children,
  setIsModalOpen,
  isModalOpen,
  trackingId = null,
}) => {
  const track = useTrack();
  const trackingIdPrefix = trackingId != null ? PAGE[trackingId] : null;
  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      onRequestClose={() => {
        setIsModalOpen(false);
        if (trackingIdPrefix != null) {
          track({ type: `${trackingIdPrefix}_PAYWALL_MODAL_CLOSED` });
        }
      }}
      isOpen={isModalOpen}
      className={styles.paywallModal}
    >
      <ModalBody
        bodyClassName={styles.paywallModalBody}
        onClickCancel={() => {
          setIsModalOpen(false);
          if (trackingIdPrefix != null) {
            track({ type: `${trackingIdPrefix}_PAYWALL_MODAL_CLOSED` });
          }
        }}
      >
        {children}
      </ModalBody>
    </Modal>
  );
};

export default ModalPane;
