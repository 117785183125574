export const metaData = {
  metaTitle: 'Metal',
  metaDescription:
    'With Plaid, Metal can connect a user’s bank account in 7 seconds. Plaid helped them prevent more than $75,000 of fraud—in just one month.',
};

export const pageHeaderData = {
  classes:
    'background background--color-yellow600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-pink-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Metal',
  header:
    'A path to money<br class="show-for-medium" /><span class="show-for-small-only"> </span>happiness',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/metal-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-metal@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-metal@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content': 'Enable ACH payments,<br />reduce fraud risk',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Payments',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Metal needed a swift, seamless way to connect users’ bank accounts while protecting them from fraud.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Metal can connect a user’s bank account in 7 seconds. Plaid helped them prevent more than $75,000 of fraud—in just one month.',
};

export const content1Data = {
  heading:
    'Metal began with an audacious goal: put cryptocurrency within easy reach of all users, regardless of their technological or financial savvy.',
  paragraph: [
    'To do that, they had to build trust with users. That meant eliminating typical barriers to entry, things like specialized financial knowledge and high transaction fees. It also meant building an app that was secure, transparent, and easy-to-use.',
    '“Managing your finances should be fun,” said Metal founder and CEO Marshall Hayner. “Your relationship with the companies that hold your financial assets should be one of trust.”',
    'To accomplish those goals, Metal needed a way to link users’ bank accounts while protecting them from fraud. For a solution, they turned to Plaid, who outperformed the competition on several key metrics.',
  ],
};

export const infoChartData = {
  className: 'background background--color-yellow200',
  row: [
    {
      header: true,
      content: 'Average processing time - account linking<sup>1</sup>',
    },
    {
      label: 'Plaid',
      content: '0:00:07',
    },
    {
      label: 'Competitor 1',
      content: '0:00:20',
    },
    {
      label: 'Competitor 2',
      content: '0:00:54',
    },
  ],
  footnote: [
    {
      content: '<sup>1</sup>Data provided by Metallicus, Inc.',
    },
  ],
};

export const content2Data = [
  {
    title: 'Break new ground',
    paragraph: [
      'Metal Pay is a cheerfully designed app that lets users make instant peer-to-peer (P2P) payments.',
      'Unlike many payments platforms, it also lets users buy, sell, send, receive, and hold 18 different cryptocurrencies, including MTL (Metal’s native cryptocurrency), Bitcoin, Litecoin, and Ethereum.',
      'What sets Metal apart is its rewards program, Proof of Processed Payments (PoPP). It rewards users with MTL tokens every time they make or receive an eligible payment or refer friends to the app. That means every one of its active users is also a holder of cryptocurrency.',
      'These are exciting features, but they expose Metal to risk. Fraud, in particular, has been a scourge of the cryptocurrency space. To cite just one example, cryptocurrency stolen from exchanges and investors increased more than 400 percent in 2018 to around $1.7 billion, according to cybersecurity firm CipherTrace.',
      'Hayner and his team considered several other solutions to link customers’ bank accounts. Ultimately, they found that Plaid outperformed the competition in terms of speed, fraud prevention, and the breadth of supported institutions.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-metal@2x.png',
      alt: 'Headshot of Marshall Hayner, Founder & CEO of Metal',
    },
    quote: [
      {
        copy:
          'If we had asked users to dig out their checkbooks, if we had asked them to verify microdeposits, many of them never would have completed our signup flow.',
        source: 'Marshall Hayner, Founder & CEO, Metal',
      },
    ],
  },
  {
    title: 'Pay it forward',
    paragraph: [
      'While other providers sometimes took more than two minutes to link users’ bank accounts—or even failed to do so entirely—Plaid links accounts in a matter of seconds.',
      '“We want the payment experience to delight our users,” Hayner said. “If the app crashes, if it lags, if it looks bad, users are going to click out of it. That made Plaid the obvious choice.”',
      'To connect an account, users simply enter their bank username and password, eliminating the need for microdeposits and voided checks. Plaid now connects to all 11,642 U.S. banks and credit unions.',
      'Since launch, Plaid has enabled explosive growth for Metal Pay. During a referral-based marketing push, the app catapulted from an average of 100 sign-ups per day to 1000 sign-ups per day for a period of several weeks.',
      'Today, Metal Pay has more than 120,000 users in 38 states. They have processed more than $10 million in payments.',
      '“Our growth wouldn’t have been possible without Plaid,” Hayner said. “If we had asked users to dig out their checkbooks, if we had asked them to verify micro-deposits, many of them never would have completed our signup flow.”',
    ],
  },
];

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-pink-3@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-pink-4@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Metal’s onboarding flow asks customers to link an outside bank account. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! Metal’s customers can start paying with ACH in a matter of seconds.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-yellow@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img:
        '/assets/img/customer-stories/diagram-step-link-mobile-yellow@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-yellow@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-yellow@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Better safe than sorry',
    paragraph: [
      'With Plaid, Metal is able to mitigate fraud risk by comparing its own know-your-customer (KYC) data against the same data gathered by users’ financial institutions.',
      'Here’s one example: do the name and date-of-birth associated with the linked bank account match the personal information that the user shared during sign-up? If so, good. If not, it may indicate an elevated fraud risk.',
      'How about the physical address and phone number associated with the account? How about the age of the account? Plaid data includes these and other endpoints that are essential for fraud prevention, while most competitors do not.',
      'Plaid’s anti-fraud technology turned out to be crucial during Metal’s marketing push, when they were onboarding more than 1000 users every day. Some bad actors tried to game the system by creating fake accounts, referring themselves, and attempting to collect the referral bonus.',
      'Fortunately, they didn’t get away with it. Metal wouldn’t issue the referral bonus until new users had successfully linked a bank account with Plaid.',
      'In fact, during one month at the height of the marketing push, Plaid successfully prevented more than 5,000 fraudulent users (7% of total signups) from joining Metal and collecting a signup bonus—resulting in a savings of more than $75,000.',
    ],
  },
  {
    quote: [
      {
        copy: 'Our growth wouldn’t have been possible without Plaid.',
        source: 'Marshall Hayner, Founder & CEO, Metal',
      },
    ],
  },
  {
    title: 'Personal finance for all',
    'narrow-title': true,
    paragraph: [
      'In the long term, Metal’s mission is twofold: democratize access to capital and change the way the world thinks about money.',
      'Metal Pay is just the first step. In the future, Hayner anticipates using Plaid to enable users to invest their spare change in cryptocurrency. This opt-in feature would also allow users to see all of their linked accounts (including balances) in one central interface.',
      '<strong>“Access to banking and personal finance is a fundamental right,” said Hayner. “Right now, it’s hard for many people. With Plaid in our arsenal, we are working to change that.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Prosper',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-prosper@2x.png',
        alt: 'Prosper - read full story',
      },
      excerpt: 'Helping people invest in each other',
      url: '/customer-stories/prosper',
    },
    {
      name: 'Alto',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-alto@2x.png',
        alt: 'Alto - read full story',
      },
      excerpt: 'A pharmacy for the digital age',
      url: '/customer-stories/alto',
    },
    {
      name: 'Drop',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-drop@2x.png',
        alt: 'Drop - read full story',
      },
      excerpt: 'Rapid growth and happy customers',
      url: '/customer-stories/drop',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/metal-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['infoChartData', infoChartData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
