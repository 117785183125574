import React, { FC } from 'react';
import ThreadsTextInput, { Props } from 'plaid-threads/TextInput';
export type { Props } from 'plaid-threads/TextInput';

/**
 * A simple wrapped component around the standard Threads TextInput component
 */
const TextInput: FC<Props> = ({ ...props }) => {
  return <ThreadsTextInput {...props} />;
};

TextInput.displayName = 'TextInput';
export default TextInput;
