export const metaData = {
  'meta-title': 'Pricing',
  'meta-description':
    "Compare Plaid's plans and pricing and choose the right solution for you",
};

// some products are only available in the scale tier.
const productDetails = [
  {
    feature: 'Payment Initiation',
    url: '/uk/products/payment-initiation',
  },
  {
    feature: 'Transactions',
    url: '/uk/products/transactions',
  },
  {
    feature: 'Auth',
    url: '/uk/products/auth',
  },
  {
    feature: 'Balance',
    url: '/uk/products/balance',
  },
  {
    feature: 'Identity',
    url: '/uk/products/identity',
  },
  {
    feature: 'Assets',
    url: '/uk/products/assets',
    isScaleTierOnly: true,
  },
];

const freeTierProductsEnabled = productDetails.map(
  ({ isScaleTierOnly, ...rest }) => {
    return {
      ...rest,
      hasFeature: !isScaleTierOnly,
    };
  },
);

const scaleTierProductsEnabled = productDetails.map(
  // We declare isScaleTierOnly here to ensure it doesn't show up in the products enabled map.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isScaleTierOnly, ...rest }) => {
    return {
      ...rest,
      hasFeature: true,
    };
  },
);

export const pricingTableData = {
  title: 'Pricing',
  header: 'UK institution access',
  link: {
    href: 'https://plaid.com/docs/account/billing/',
    text: 'Learn more about Plaid billing',
    target: '_blank',
    rel: 'noreferrer',
  },
  tableData: [
    {
      img: '/assets/img/icons/pricing-test-plant.png',
      label: 'test',
      price: 'Free',
      description:
        'Build and test using our core set of products with live data',
      ctaLink: '//dashboard.plaid.com/signup',
      ctaText: 'Get started',
      hasHeader: true,
      products: freeTierProductsEnabled,
      'hide-services-mobile': true,
      services: [
        {
          feature: 'Unlimited Sandbox test API calls',
          hasFeature: true,
        },
        {
          feature: '200 live API calls per product',
          hasFeature: true,
        },
        {
          feature: 'Premium support',
        },
        {
          feature: 'Account management',
        },
        {
          feature: 'Integration assistance',
        },
      ],
    },
    {
      img: '/assets/img/icons/pricing-scale-plant.png',
      label: 'scale',
      price: 'Custom',
      description:
        'Get tailored solutions, volume pricing, and dedicated support for your team',
      hasInfoDropdown: false,
      ctaLink: '/contact#Enterprise',
      ctaText: 'Contact sales',
      products: scaleTierProductsEnabled,
      services: [
        {
          feature: 'Unlimited Sandbox test API calls',
          hasFeature: true,
        },
        {
          feature: 'Unlimited live API calls',
          hasFeature: true,
        },
        {
          feature: 'Premium support',
          hasFeature: true,
        },
        {
          feature: 'Account management',
          hasFeature: true,
        },
        {
          feature: 'Integration assistance',
          hasFeature: true,
        },
      ],
    },
  ],
};
