import React from 'react';

const StudentLoansIcon = () => {
  return (
    <svg height={18} width={22} xmlns='http://www.w3.org/2000/svg'>
      <g
        fill='none'
        fillRule='evenodd'
        stroke='#111'
        transform='translate(-1 -5)'
      >
        <path
          d='M5.412 13.413v5.19l6.08 3.004 5.92-3.001v-5.193z'
          fill='#63daff'
        />
        <path
          d='M11.696 5.967a.5.5 0 00-.484 0l-9.127 5.05a.5.5 0 000 .874l9.127 5.05a.5.5 0 00.484 0l9.126-5.05a.5.5 0 000-.875z'
          fill='#63daff'
        />
        <path d='M20.412 12.413v6.523' strokeLinecap='square' />
        <circle cx={20.412} cy={20.413} fill='#fce76b' r={1.5} />
      </g>
    </svg>
  );
};

export default StudentLoansIcon;
