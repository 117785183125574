// @ts-nocheck
export const findChildById = (items, id) => {
  for (const item of items) {
    if (item?.id === id) return item;
    const found = item.children?.length
      ? findChildById(item?.children, id)
      : null;
    if (found) return found;
  }
  return null;
};

export const findParentById = (items, id, parent = null) => {
  for (const item of items) {
    if (item?.id === id) return parent;
    const found = item.children?.length
      ? findParentById(item?.children, id, item)
      : null;
    if (found) return found;
  }
  return null;
};

export const getBackgroundColor = (elem?: Element) => {
  if (!elem) return 'rgb(255, 255, 255)';

  const bgColor = getComputedStyle(elem).backgroundColor || 'rgba(0, 0, 0, 0)';

  return bgColor !== 'rgba(0, 0, 0, 0)'
    ? bgColor
    : getBackgroundColor(elem.parentElement);
};

const rgbToArray = (rgbString) => {
  // Use a regular expression to extract the numbers
  const matches = rgbString.match(/\d+/g);

  // Convert the strings to numbers
  return matches.map(Number);
};

const rgbaToRgb = (color) => {
  const values = rgbToArray(color);
  if (values.length === 4) {
    const [r, g, b, a] = values;

    // If alpha is 1, return the color as is
    if (a === 1) {
      return [r, g, b];
    } else {
      // Calculate the blended color over a white background
      const blendedR = r * a + 255 * (1 - a);
      const blendedG = g * a + 255 * (1 - a);
      const blendedB = b * a + 255 * (1 - a);

      return [blendedR, blendedG, blendedB];
    }
  } else {
    // It's an rgb color, so just return the values as is
    return values;
  }
};

export const hexToRgb = (hex) => {
  if (!hex) return 'rgb(255, 255, 255)';

  // Ensure the hex code starts with '#'
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  // If the hex code is 3 characters, expand it to 6
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((c) => {
        return c + c;
      })
      .join('');
  }

  // Convert hex to RGB values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the RGBA string
  return `rgb(${r}, ${g}, ${b})`;
};

const toLinear = (value) => {
  const scaled = value / 255;

  return scaled <= 0.03928
    ? scaled / 12.92
    : Math.pow((scaled + 0.055) / 1.055, 2.4);
};

// Helper function to calculate relative luminance
const relativeLuminance = ([r, g, b]) => {
  const [rLinear, gLinear, bLinear] = [r, g, b].map(toLinear);

  return 0.2126 * rLinear + 0.7152 * gLinear + 0.0722 * bLinear;
};

/**
 * Calculates the contrast ratio between two RGB colors.
 * @param {Array} color1 - First color as a string.
 * @param {Array} color2 - Second color as a  string.
 * @returns {number} - The contrast ratio between the two colors (1-21).
 */
export const calculateContrastRatio = (color1, color2) => {
  const rgb1 = rgbaToRgb(color1);
  const rgb2 = rgbaToRgb(color2);

  // Calculate the relative luminance of both colors
  const luminance1 = relativeLuminance(rgb1);
  const luminance2 = relativeLuminance(rgb2);

  // Calculate the contrast ratio
  const brighter = Math.max(luminance1, luminance2);
  const darker = Math.min(luminance1, luminance2);

  return (brighter + 0.05) / (darker + 0.05);
};
