import React, { useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';
import _clamp from 'lodash/clamp';
import { useDebouncedCallback } from 'use-debounce';
import { useSwipeable } from 'react-swipeable';

import { useDeviceSize } from 'src/hooks';
import { getColumn } from './utils';

import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import {
  Container,
  Section,
  Row,
  Column,
  Stack,
  Spacer,
  Box,
} from 'src/components-v2/Layout';
import { Indicator } from '../Cards/Paginator';
import { CardLinked } from '../Cards/CardLinked';
import { Filter } from './Filter';
import { CmsSectionalHeader } from 'src/sections/Header';

import styles from './LinkedCardsSection.module.scss';

const LinkedCardsSection = ({ fields, sys }) => {
  const { backgroundColor, styles: stylesProp, header, tabs, button } =
    fields || {};
  // const bgColor = backgroundColor?.toLowerCase() || 'transparent';
  // const isDarkMode = bgColor === ('black' || 'black1000');

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  // Tabs Controller
  const initialTabId = tabs?.[0]?.sys?.id || '';
  const [activeTabId, setActiveTabId] = useState<string>(initialTabId);

  const handleChangeActiveTab = useCallback((id: string) => {
    setActiveTabId(id);
  }, []);

  const currentTab = useMemo(() => {
    return tabs.find((tab) => {
      return tab?.sys?.id === activeTabId;
    });
  }, [activeTabId, tabs]);
  const currentTabCards = currentTab.fields.cards;

  // Slider Controller
  const initialState =
    currentTabCards?.map((_, i) => {
      return i === 0;
    }) || [];
  const [isActive, setIsActive] = React.useState(initialState);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [xPos, translateX] = React.useState(0);
  const [cardRef, cardRefBounds] = useMeasure();
  const [rowRef, rowRefBounds] = useMeasure();
  const device = useDeviceSize();
  const activeCard = isActive.findIndex((c) => {
    return c === true;
  });

  const getX = (index) => {
    const marginLeft = (rowRefBounds.width * 1) / 24;
    const delta = rowRefBounds.width - cardRefBounds.width;

    if (index === 0) return 0;

    if (index === currentTabCards?.length - 1) {
      return (cardRefBounds.width + marginLeft) * index - delta;
    }

    return (cardRefBounds.width + marginLeft) * index - delta / 2;
  };

  const handleClick = (index) => {
    const x = getX(index);

    translateX(x);
    setIsActive((prevState) => {
      return prevState.map((_, i) => {
        return i === index;
      });
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleClick(_clamp(activeCard + 1, 0, currentTabCards?.length - 1));
    },
    onSwipedRight: () => {
      handleClick(_clamp(activeCard - 1, 0, currentTabCards?.length - 1));
    },
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    swipeDuration: 1000, // allowable duration of a swipe (ms)
    touchEventOptions: { passive: true }, // options for touch listeners
  });

  const recalculateX = useDebouncedCallback((index) => {
    const x = getX(index);
    translateX(x);
  }, 100);

  useEffect(() => {
    // reset the rows, this is uncommon, but will happen during testing and will scare people
    if (!device.isSmall && xPos !== 0) {
      translateX(0);
      setIsActive(initialState);
    }

    window.addEventListener('resize', recalculateX.bind(null, activeCard));

    return () => {
      window.removeEventListener('resize', recalculateX);
    };
  }, [recalculateX, device.isSmall, initialState, isActive, xPos, activeCard]);

  useEffect(() => {
    translateX(0);
    setIsActive(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId]);

  const tabCardsComponents = currentTabCards?.map((card, index) => {
    if (!card) return null;

    const component = <CardLinked card={card} />;

    return React.cloneElement(component, {
      onMouseEnter: () => {
        setHoveredIndex(index);
      },
      onMouseLeave: () => {
        setHoveredIndex(-1);
      },
      onFocus: () => {
        setHoveredIndex(index);
      },
      onBlur: () => {
        setHoveredIndex(-1);
      },
    });
  });

  return (
    <Section
      sx={{ backgroundColor: backgroundColor || 'white', ...stylesProp }}
    >
      {header && (
        <CmsSectionalHeader sys={header?.sys} fields={header?.fields} />
      )}

      <Container>
        <Row>
          {tabs.length > 1 && (
            <Column
              xs={24}
              xsOffset={0}
              lg={20}
              lgOffset={2}
              sx={
                header
                  ? { mt: { xs: '0.8rem', sm: '1.2rem', lg: '0.8rem' } }
                  : {}
              }
            >
              <Box
                sx={
                  header
                    ? {
                        pt: { xs: '12px', sm: '22px', lg: '24px' },
                        borderTop: '1px solid var(--black600)',
                      }
                    : {}
                }
              >
                <Filter
                  tabs={tabs}
                  button={button}
                  activeId={activeTabId}
                  onChange={handleChangeActiveTab}
                  backgroundColor={backgroundColor || 'white'}
                />
              </Box>
            </Column>
          )}
        </Row>
      </Container>

      <Spacer sx={{ height: { xs: '12px', sm: '26px' } }} />

      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row
          ref={handlers.ref}
          sx={{
            flexWrap: { xs: 'nowrap' },
            transform: `translateX(-${xPos}px)`,
            transition: 'transform 0.3s ease-in-out',
          }}
          {...handlers.onMouseDown}
        >
          {tabCardsComponents?.map((card, i, arr) => {
            if (!card) return null;

            const columns = getColumn(arr, i);

            return (
              <Column
                className={classNames(styles.column, {
                  [styles['column_not-hovered']]:
                    i !== hoveredIndex && hoveredIndex !== -1,
                })}
                ref={cardRef}
                key={`card-column-${i}`}
                sx={{
                  minWidth: {
                    xs: 'calc(100% * 21 / var(--Grid-columns))',
                    sm: '0',
                  },
                }}
                {...columns}
              >
                {card}
              </Column>
            );
          })}
        </Row>

        <Row ref={rowRef} />

        <Spacer sx={{ height: { xs: '12px', sm: '0' } }} />

        <Row sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <Column xs={24}>
            <Stack justifyContent='center' flexDirection='row' gap={0}>
              {tabCardsComponents?.map((_, i) => {
                return (
                  <Indicator
                    key={`indicator-${i}`}
                    isActive={isActive[i]}
                    index={i}
                    onClick={handleClick}
                    sx={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  />
                );
              })}
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default LinkedCardsSection;
