import React, { useRef } from 'react';
import cx from 'classnames';

// helpers
import { Property, HandleEvent } from './StaticData.types';
import { DeviceContext } from 'src/contexts';

//components
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';

interface Props {
  index: number;
  property: Property;
  handleEvent: HandleEvent;
  parentContainer: HTMLDivElement;
}

const ObjectPropertyCard: React.FC<Props> = ({
  index,
  property,
  handleEvent,
  parentContainer,
}) => {
  const currentElement = useRef(null);
  return (
    <DeviceContext.Consumer>
      {(device) => (
        <span
          ref={currentElement}
          onClick={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          onKeyDown={(e) => {
            handleEvent({ e, currentElement, parentContainer, device });
          }}
          role='button'
          tabIndex={0}
          key={`property-${index}`}
          data-testid={`object-property-${property.title}`}
          data-id={`object-property-${property.title}`}
          data-code-line={property['code-line-start']}
          data-code-length={property['code-highlight-length']}
          className={cx('interactive-data-property', {
            'interactive-data-property--is-selected': index === 0,
          })}
        >
          <span className='check-icon'>
            <Checkmark />
          </span>
          <p className='interactive-data-title'>{property.title}</p>
          <div className='interactive-data-description-container'>
            <p className='interactive-data-description'>
              {property.description}
            </p>
            {property['bottom-cta-text'] && (
              <span className='feature-section-link-text'>
                {property['bottom-cta-text']}
                <ArrowRightSingleSmall />
              </span>
            )}
          </div>
        </span>
      )}
    </DeviceContext.Consumer>
  );
};

export default ObjectPropertyCard;
