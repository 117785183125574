export const borderStyles = {
  'web 2.0': {
    borderTop: 1,
    borderBottom: 1,
    borderColor: 'black600',
  },
  'web 3.0': {},
};
export const headingStyles = {
  'web 2.0': {
    color: 'black800',
  },
  'web 3.0': {
    color: 'text/contrast-medium',
  },
};
export const dividerStyles = {
  'web 2.0': {
    color: 'black600',
  },
  'web 3.0': {
    color: 'base/minted/450',
  },
};
