import { Paginator } from './Paginator';
import { CmsSectionalHeader } from 'src/sections/Header';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { makeImageCardNodes } from './CmsCardsSwitch/utils';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';

const CmsImageCards = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const cmsMode = useCmsMode({ mode: fields?.mode, backgroundColor: bgColor });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;

  return (
    <ThemeProvider theme={componentTheme}>
      <Paginator
        appearance='image'
        sys={sys}
        sx={{
          background,
          ...fields?.styles,
        }}
        heading={
          fields?.header ? (
            <CmsSectionalHeader
              sys={fields?.header?.sys}
              fields={fields?.header?.fields}
            />
          ) : null
        }
        cards={fields?.imageCards?.map((card, index) => {
          const sys = card?.sys;
          return {
            content: (
              <RichTextSwitch
                key={`image-card-${index}`}
                sys={sys}
                content={card?.fields?.body}
                nodes={makeImageCardNodes(fields)}
              />
            ),
            sx: card?.fields?.styles,
          };
        })}
      />
    </ThemeProvider>
  );
};

export default CmsImageCards;
