/* eslint-disable import/prefer-default-export */
const codeBody = `http code 200
{
  "accounts": [
    {
      ...,
      "owners": [
        {
          "addresses": [
            {
              "data": {
                "city": "Malakoff",
                "region": "NY",
                "street": "2992 Cameron Road",
                "postal_code": "14236",
                "country": "US"
              },
              "primary": true
            },
            {
              "data": {
                "city": "San Matias",
                "region": "CA",
                "street": "2493 Leisure Lane",
                "postal_code": "93405-2255",
                "country": "US"
              },
              "primary": false
            }
          ],
          "emails": [
            {
              "data": "accountholder0@example.com",
              "primary": true,
              "type": "primary"
            }
          ],
          "names": [
            "Alberta Bobbeth Charleson"
          ],
          "phone_numbers": [{
            "primary": true,
            "type": "home",
            "data": "4673956022"
          }]
        }
      ]
    }
  ],
  "item": {object},
  "request_id": "m8MDnv9okwxFNBV"
}
`;

export const interactiveData = {
  'title-text': 'Make an Identity request',
  'description-text': 'Use the Identity endpoint to submit a POST request',
  'code-title': 'Retrieve Identity request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/identity/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Identity data straight from the source',
  'post-run-description-text':
    'The <strong>/identity/get</strong> endpoint allows you to retrieve various account holder information on file with the financial institution, including names, emails, phone numbers, and addresses',
  'post-run-code-title': 'Retrieve Identity response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      title: 'addresses',
      description: 'A list of addresses associated with the account(s)',
      'code-line-start': 8,
      'code-highlight-length': 22,
    },
    {
      title: 'emails',
      description: 'A list of emails associated with the account(s)',
      'code-line-start': 30,
      'code-highlight-length': 7,
    },
    {
      title: 'names',
      description: 'A list of names associated with the account(s)',
      'code-line-start': 37,
      'code-highlight-length': 3,
    },
    {
      title: 'phone_numbers',
      description: 'A list of phone numbers associated with the account(s)',
      'code-line-start': 40,
      'code-highlight-length': 5,
    },
  ],
  'bottom-cta-url': '/docs/#identity',
  'bottom-cta-text': 'View API docs',
};
