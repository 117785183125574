/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for showcaseCard type to the view
 *
 * @param {Object} args
 * @param {Object} args.card
 * @returns {Object}
 */
export const showCaseCardAdapter = (fields) => {
  return {
    text: fields.copy,
    logo: 'https:' + _get(fields, 'logo.fields.file.url'),
    'cta-link': _get(fields, 'cta.fields.url'),
    'cta-text': _get(fields, 'cta.fields.text'),
  };
};
/**
 * Maps the contentful response for mvp quote content type to the view
 *
 * @param {Object} args
 * @param {Object} args.backgroundColor
 * @param {Object} args.cta
 * @param {string} args.description
 * @param {boolean} flipped
 * @param {boolean} fullWidthImage
 * @param {Object} args.image
 * @param {Object} args.video
 * @param {Object} args.videoWidth
 * @param {Object} args.videoHeight
 * @param {string} args.title
 * @returns {Object}
 */
export const mvpShowcaseAdapter = ({
  backgroundColor,
  cta,
  description,
  dropshadow,
  flipped,
  fullWidthImage,
  image,
  label,
  title,
  video,
  videoWidth,
  videoHeight,
  showcaseAccent,
  showcaseCard = [],
  showcaseArt,
}) => {
  const href = _get(cta, 'fields.url');
  const text = _get(cta, 'fields.text');
  const hasCta = !!(href && text);
  return {
    title,
    description,
    brushstroke: !!_get(showcaseAccent, 'fields.file.url')
      ? {
          alt: _get(showcaseAccent, 'fields.description', ''),
          src: _get(showcaseAccent, 'fields.file.url'),
        }
      : null,
    illustration: !!_get(showcaseArt, 'fields.file.url')
      ? {
          src: _get(showcaseArt, 'fields.file.url'),
        }
      : null,
    bgColor: _get(backgroundColor, 'fields.color', 'blue200'),
    image: !!_get(image, 'fields.file.url')
      ? {
          alt: _get(image, 'fields.description', title), // prefer description, use title as fallback
          src: _get(image, 'fields.file.url'),
        }
      : null,
    video: {
      type: video?.fields?.file?.contentType,
      src: video && 'https://' + video.fields.file.url,
      width: videoWidth,
      height: videoHeight,
    },
    hasCta,
    cta: {
      href: _get(cta, 'fields.url'),
      text: _get(cta, 'fields.text'),
    },
    cards: showcaseCard?.map((card) => {
      return {
        text: _get(card, 'fields.copy'),
        logo: 'https://' + _get(card.fields.logo, 'fields.file.url'),
        'cta-link': _get(card.fields.cta, 'fields.url'),
        'cta-text': _get(card.fields.cta, 'fields.text'),
      };
    }),
    flipped,
    fullWidthImage,
    label,
    dropshadow,
  };
};
