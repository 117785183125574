// TODO: Component cleanup/refactor - https://jira.plaid.com/browse/SITE-1969

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ImageShape } from 'src/lib/prop-types';
import { Grid } from 'src/components/Layout/Grid';
import Image from 'src/components/Image';
import { RichTextRenderer } from 'src/components/RichTextRenderer';

const renderSupportingTextPropTypes = {
  'single-stat': PropTypes.bool,
  'with-background': PropTypes.bool,
  'background-class': PropTypes.string,
  withImage: PropTypes.bool,
  img: ImageShape,
  'three-up': PropTypes.bool,
  stat: PropTypes.bool,
  stat1: PropTypes.string,
  header1: PropTypes.string,
  text1: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const renderSupportingText = (props) => {
  if (props['single-stat']) {
    return (
      <>
        <div className='cell supporting-text-item'></div>
        <div className='cell supporting-text-item'>
          <div className='separator'></div>
          {props.stat && <span className='stat'>{props.stat1}</span>}
          <h5>{props.header1}</h5>
          {typeof props.text1 === 'object' ? (
            <RichTextRenderer content={props.text1} />
          ) : (
            <p dangerouslySetInnerHTML={{ __html: props.text1 }} />
          )}
        </div>
        <div className='cell supporting-text-item hide-for-small-only'></div>
      </>
    );
  }
  if (props['with-background']) {
    return (
      <div className='cell supporting-text-image-wrapper '>
        <div
          className={`supporting-text-background ${props['background-class']}`}
        ></div>
      </div>
    );
  }
  if (props.withImage) {
    return (
      <div className='cell supporting-text-item'>
        <Image
          className='supporting-text-img'
          src={props.img.src}
          alt={props.img.alt}
        />
      </div>
    );
  }
  if (props['three-up']) {
    return (
      <div className='cell supporting-text-item'>
        <div className='separator'></div>
        {props.stat && <span className='stat'>{props.stat1}</span>}
        <h5>{props.header1}</h5>
        {typeof props.text1 === 'object' ? (
          <RichTextRenderer content={props.text1} />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: props.text1 }} />
        )}
      </div>
    );
  }

  return <div className='cell supporting-text-item'></div>;
};
renderSupportingText.propTypes = renderSupportingTextPropTypes;

const propTypes = {
  classes: PropTypes.string,
  'single-stat': PropTypes.bool,
  'six-up': PropTypes.bool,
  'has-intro': PropTypes.bool,
  'intro-header': PropTypes.string,
  'intro-text': PropTypes.string,
  stat: PropTypes.bool,
  stat2: PropTypes.string,
  header2: PropTypes.string,
  text2: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stat3: PropTypes.string,
  header3: PropTypes.string,
  text3: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stat4: PropTypes.string,
  header4: PropTypes.string,
  text4: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stat5: PropTypes.string,
  header5: PropTypes.string,
  text5: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stat6: PropTypes.string,
  header6: PropTypes.string,
  text6: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  'hide-separators': PropTypes.bool,
  useThreadsGrid: PropTypes.bool,
  customPaddingTop: PropTypes.string,
  customPaddingBottom: PropTypes.string,
  uid: PropTypes.string,
};

const SupportingText = ({
  useThreadsGrid = false,
  customPaddingTop = '',
  customPaddingBottom = '',
  uid = '',
  ...props
}) => {
  return (
    <div
      className={cx(
        'supporting-text',
        { [`supporting-text--${uid}`]: uid },
        { 'hide-separators': props['hide-separators'] },
        props.classes,
      )}
      data-testid='supportingText'
    >
      {customPaddingTop.length > 0 && (
        <style jsx data-testid='customPaddingTop'>{`
          .supporting-text--${uid} {
            padding-top: ${customPaddingTop};
          }
        `}</style>
      )}
      {customPaddingBottom.length > 0 && (
        <style jsx data-testid='customPaddingBottom'>{`
          .supporting-text--${uid} {
            padding-bottom: ${customPaddingBottom};
          }
        `}</style>
      )}

      <Grid useThreadsGrid={useThreadsGrid} excludeGridClass>
        <div className='supporting-text-wrapper'>
          {props['has-intro'] && (
            <div className='grid-x align-justify grid-margin-x'>
              <div className='cell medium-12 large-6 supporting-text__intro'>
                {props['intro-header'] && (
                  <h4 className='supporting-text__intro-header'>
                    {props['intro-header']}
                  </h4>
                )}
                {props['intro-text'] && (
                  <p
                    className='supporting-text__intro-text'
                    dangerouslySetInnerHTML={{ __html: props['intro-text'] }}
                  />
                )}
              </div>
              <div className='cell medium-6 large-6'></div>
            </div>
          )}
          {renderSupportingText(props)}
          {!props['single-stat'] && (
            <>
              <div className='cell supporting-text-item'>
                <div className='separator'></div>
                {props.stat && <span className='stat'>{props.stat2}</span>}
                <h5>{props.header2}</h5>
                {typeof props.text2 === 'object' ? (
                  <RichTextRenderer content={props.text2} />
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: props.text2 }} />
                )}
              </div>
              <div className='cell supporting-text-item'>
                <div className='separator'></div>
                {props.stat && <span className='stat'>{props.stat3}</span>}
                <h5>{props.header3}</h5>
                {typeof props.text3 === 'object' ? (
                  <RichTextRenderer content={props.text3} />
                ) : (
                  <p>{props.text3}</p>
                )}
              </div>
            </>
          )}
          {props['six-up'] && (
            <>
              <div className='cell supporting-text-item'>
                <div className='separator'></div>
                {props.stat && <span className='stat'>{props.stat4}</span>}
                <h5>{props.header4}</h5>
                {typeof props.text4 === 'object' ? (
                  <RichTextRenderer content={props.text4} />
                ) : (
                  <p>{props.text4}</p>
                )}
              </div>
              <div className='cell supporting-text-item'>
                <div className='separator'></div>
                {props.stat && <span className='stat'>{props.stat5}</span>}
                <h5>{props.header5}</h5>
                {typeof props.text5 === 'object' ? (
                  <RichTextRenderer content={props.text5} />
                ) : (
                  <p>{props.text5}</p>
                )}
              </div>
              <div className='cell supporting-text-item'>
                <div className='separator'></div>
                {props.stat && <span className='stat'>{props.stat6}</span>}
                <h5>{props.header6}</h5>
                {typeof props.text6 === 'object' ? (
                  <RichTextRenderer content={props.text6} />
                ) : (
                  <p>{props.text6}</p>
                )}
              </div>
            </>
          )}
        </div>
      </Grid>
    </div>
  );
};

SupportingText.propTypes = propTypes;
export default SupportingText;
