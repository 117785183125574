import React from 'react';

const SolutonLabelIcon = (props) => {
  return (
    <svg height={17} width={16} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle cx={8} cy={8.742} fill='#cbf2f8' r={8} />
        <path
          d='M10 4.742L7.333 9.408 6 7.408H4l3.333 5.334 4.667-8z'
          fill='#63daff'
        />
      </g>
    </svg>
  );
};

export default SolutonLabelIcon;
