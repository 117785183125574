import React, { FC } from 'react';
import cx from 'classnames';
import styles from './ContentBlockSection.module.scss';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { ContentBlock as ContentBlockProps } from './ContentBlockSection.types';
import { ImageShape } from 'src/lib/ts-types';
import { Grid, Column } from 'src/components/Layout/Grid';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

const ContentBlockSectionCms: FC<ContentBlockProps> = ({
  useThreadsGrid = false,
  paragraph,
  cta,
  image = {
    src: '',
    alt: '',
  },
  title = '',
  narrowTitle = false,
  heading = '',
  quote = [],
  noPadding = false,
}) => {
  return (
    <section className={styles.container}>
      <Grid className={styles.grid} useThreadsGrid={useThreadsGrid}>
        <Column small={12} medium={4}>
          <Grid excludeGridContainerClass noPadding={noPadding}>
            {image.src && <ContentBlockSectionImg {...(image as ImageShape)} />}
            {title && (
              <ContentBlockSectionTitle
                title={title}
                narrowTitle={narrowTitle}
                noPadding={noPadding}
              />
            )}
          </Grid>
        </Column>
        <Column small={12} medium={8}>
          {heading && <h4 className={styles.heading}>{heading}</h4>}
          {quote.length > 0 &&
            quote.map((item, i) => (
              <ContentBlockSectionQuote key={`quote-${i}`} {...item} />
            ))}
          {paragraph && (
            <div
              data-testid='content-block-richtext'
              className='content-block-richtext'
            >
              <RichTextRenderer
                classes={{
                  pClassName: cx(styles.paragraph, {
                    [styles.paragraphWithQuote]: quote.length > 0,
                  }),
                  ulClassName: styles.ul,
                }}
                content={paragraph}
              />
              {cta?.fields?.text && cta?.fields?.url && (
                <a className={styles.ctaLink} href={cta.fields.url}>
                  <span className={styles.cta}>
                    {cta.fields.text} <ArrowRightSingleSmall />
                  </span>
                </a>
              )}
            </div>
          )}
        </Column>
      </Grid>
    </section>
  );
};

const ContentBlockSectionImg = ({ src, alt }) => (
  <div className='cell small-8 medium-8 medium-offset-0'>
    <Image className={styles.image} src={src} alt={alt} />
  </div>
);

const ContentBlockSectionTitle = ({ title, narrowTitle, noPadding }) => (
  <div className={cx('cell', 'small-12', `medium-${narrowTitle ? '6' : '8'}`)}>
    <h3
      className={`${noPadding === true ? styles.noPaddingTitle : styles.title}`}
    >
      {title}
    </h3>
  </div>
);

const ContentBlockSectionQuote = ({ copy, source }) => (
  <div data-testid='content-block-quote'>
    <div className='cell small-12'>
      <h4 className={styles.quote}>{copy}</h4>
    </div>
    <div className='cell small-12'>
      <p className={styles.paragraph}>{source}</p>
    </div>
  </div>
);

export default ContentBlockSectionCms;
