import {
  ComponentPropsWithoutRef,
  forwardRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _debounce from 'lodash/debounce';
import cx from 'classnames';

import { Link } from 'src/components/Inputs';
import { Box, Stack } from 'src/components-v2/Layout';
import { PlaidLogo } from 'src/components-v2/SVGs';
import { colors } from 'src/components-v2/theme';
import { Typography, Icon } from 'src/components-v2/DataDisplay';
import { S } from 'src/components-v2/StyledElements/StyledElements';

import { SubMenu } from '../SubMenu';
import { isLink, NavDataItems } from '../types';
import { findChildById, findParentById } from '../utils';

import styles from './Dialog.module.scss';

type DialogProps = ComponentPropsWithoutRef<'dialog'> & {
  className?: string;
  items: NavDataItems;
  trackingIds: { getAPIkeys: string };
  onClose?: () => void;
};

export const Dialog = forwardRef(
  (props: DialogProps, ref: RefObject<HTMLDialogElement>) => {
    const { className, items, trackingIds, onClose } = props;

    const [subMenuID, setSubMenuID] = useState<string>(null);

    const subMenuData = useMemo(() => {
      return findChildById(items, subMenuID);
    }, [items, subMenuID]);

    const handleNextMenuLevel = (id: string) => {
      return () => {
        setSubMenuID(id);
      };
    };

    const handlePrevMenuLevel = () => {
      const parent = findParentById(items, subMenuID);
      setSubMenuID(parent ? parent.id : null);
    };

    const handleClose = () => {
      setSubMenuID(null);
      onClose?.();
    };

    const handleResize = _debounce(() => {
      if (!ref.current || !ref.current.open) return;

      handleClose();
      ref.current.close();
    }, 100);

    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [handleResize]);

    return (
      <Box
        className={cx(styles.Dialog, className)}
        component='dialog'
        id='main-menu-dialog'
        ref={ref}
        sx={{
          overflow: 'hidden',
          inset: '8px',
          maxWidth: 'none',
          maxHeight: 'none',
          p: 0,
          width: 'auto',
          height: 'auto',
          border: 'none',
          borderRadius: '16px',
          color: 'inherit',
          '&::backdrop': {
            backgroundColor: 'rgba(12, 17, 22, 0.48)',
            boxShadow:
              '0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 6px 12px 0px rgba(85, 85, 85, 0.12)',
            backdropFilter: 'blur(30px)',
          },
        }}
      >
        <Stack sx={{ height: '100%', backgroundColor: colors.white }}>
          <Stack
            direction='row-reverse'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              position: 'relative',
              p: '24px 16px 20px',
              '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: '16px',
                right: '16px',
                borderBottom: '1px solid #EAEFF0',
              },
            }}
          >
            <form method='dialog'>
              <Box
                className={styles.Close}
                component='button'
                role='button'
                tabIndex={0}
                aria-label='Close main menu modal'
                sx={{ p: '4px 8px' }}
                onClick={handleClose}
              >
                <Icon icon='Close' sx={{ width: 22, height: 24 }} />
              </Box>
            </form>

            {subMenuID ? (
              <Box
                className={styles.Back}
                component='button'
                role='button'
                tabIndex={0}
                onClick={handlePrevMenuLevel}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Icon icon='ChevronLeft' sx={{ width: 18, height: 20 }} />

                <Typography
                  sx={{ mb: 0 }}
                  theme='main-menu-new'
                  variant='p'
                  component='span'
                >
                  Back
                </Typography>
              </Box>
            ) : (
              <Link href='/'>
                <a
                  className={styles.Logo}
                  aria-label='Go to Plaid homepage'
                  role='link'
                  tabIndex={0}
                >
                  <PlaidLogo
                    sx={{
                      display: 'block',
                      height: { xs: '24px', sm: '28px' },
                    }}
                  />
                </a>
              </Link>
            )}
          </Stack>

          <Box sx={{ flex: 1, overflow: 'auto', px: '16px' }}>
            {subMenuData ? (
              <SubMenu data={subMenuData} onClick={handleNextMenuLevel} />
            ) : (
              <Stack
                component='ul'
                gap='8px'
                sx={{ listStyle: 'none', margin: 0, p: '20px 0' }}
              >
                {items.map((item, i) => {
                  const key = `menu-item-${i}`;

                  if (!isLink(item)) {
                    const { id, title } = item;

                    return (
                      <S.Li key={key}>
                        <Box
                          className={styles.Button}
                          component='button'
                          role='button'
                          tabIndex={0}
                          sx={{
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            padding: '15px 12px',
                          }}
                          onClick={handleNextMenuLevel(id)}
                        >
                          <Typography
                            sx={{ mb: 0 }}
                            theme='main-menu-new'
                            variant='p'
                            component='span'
                          >
                            {title}
                          </Typography>
                        </Box>
                      </S.Li>
                    );
                  }

                  const { href, title, trackingId } = item;

                  return (
                    <S.Li key={key}>
                      <Link href={href} trackingId={trackingId}>
                        <a
                          className={styles.Link}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '15px 12px',
                          }}
                        >
                          <Typography
                            sx={{ mb: 0 }}
                            theme='main-menu-new'
                            variant='p'
                            component='span'
                          >
                            {title}
                          </Typography>
                        </a>
                      </Link>
                    </S.Li>
                  );
                })}

                <S.Hr
                  sx={{ borderColor: '#EAEFF0', m: '12px 0', maxWidth: 'none' }}
                />

                <S.Li>
                  <Link href='https://dashboard.plaid.com/signin/'>
                    <a
                      className={styles.Link}
                      style={{
                        display: 'block',
                        width: '100%',
                        padding: '15px 12px',
                      }}
                    >
                      <Typography
                        sx={{ mb: 0 }}
                        theme='main-menu-new'
                        variant='p'
                        component='span'
                      >
                        Log in
                      </Typography>
                    </a>
                  </Link>
                </S.Li>

                <S.Li>
                  <Link
                    href='https://dashboard.plaid.com/contact'
                    trackingId={trackingIds?.getAPIkeys}
                  >
                    <a
                      className={styles.Link}
                      style={{
                        display: 'block',
                        width: '100%',
                        padding: '15px 12px',
                      }}
                    >
                      <Typography
                        sx={{ mb: 0 }}
                        theme='main-menu-new'
                        variant='p'
                        component='span'
                      >
                        Contact sales
                      </Typography>
                    </a>
                  </Link>
                </S.Li>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    );
  },
);
