import React, {
  createContext,
  useState,
  FC,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { PAYWALL_SELECTOR } from 'src/lib/constants';

// we have a set of hard coded keys due to a mistake made with the SOFI and Public launches
// we need to work around these to avoid a poor experience for returning organic users
// see thread: https://plaidchat.slack.com/archives/C04A58UUD8U/p1675876095349979
// TLDR: two unique keys were used for a subfolder and both need to function the same
// this function will union the keys into one while still maintaining the correct behavior
const isUnionedKey = (key): boolean => {
  const unionedKeys = [
    '19aj2304lr', // Public
    '7564838986', // SOFI
  ];
  return unionedKeys.some((unionedKey) => {
    return unionedKey === key;
  });
};

export const PaywallContext = createContext({
  isModalOpen: false,
  setIsModalOpen: (isOpen: boolean) => {
    return null;
  },
  hasPaywall: false,
  handleUnlockContent: () => {
    return null;
  },
  isContentUnlocked: false,
  renderOption: '',
  resetPaywall: () => {
    return null;
  },
});

interface Props {
  children: React.ReactNode;
  cookieName: string;
  unlockKey: string;
  hasInvertedPaywallLogic?: boolean;
}

export const PaywallProvider: FC<Props> = ({
  children,
  cookieName,
  unlockKey,
  hasInvertedPaywallLogic = false,
}) => {
  const [state, setState] = useState({
    isModalOpen: false,
    isContentUnlocked: false,
    hasPaywall: false,
    renderOption: '',
  });

  const handleUnlockContent = useCallback(() => {
    try {
      localStorage.setItem(cookieName, String(unlockKey));
    } catch (e) {
      console.error('local storage not supported'); //eslint-disable-line
    }
    setState((state) => {
      return { ...state, isContentUnlocked: true };
    });
  }, [cookieName, unlockKey]);

  useEffect(() => {
    // for whatever reason marketing wants the magic link to invert
    // this means that we lock the page when it has the special key instead of unlocking it
    const urlParams = new URLSearchParams(window.location.search);
    const hasLock = urlParams.get('pwl');
    if (hasInvertedPaywallLogic && !hasLock && !state.isContentUnlocked) {
      handleUnlockContent();
      return;
    }

    const hasUnlock = urlParams.get('unlock');
    const hasMatchingBypassKey =
      hasUnlock === unlockKey || isUnionedKey(hasUnlock || '');
    if (unlockKey && hasMatchingBypassKey && !state.isContentUnlocked) {
      handleUnlockContent();
      return;
    }

    try {
      const userKey = localStorage.getItem(cookieName);
      const hasMatchingKey = userKey === unlockKey || isUnionedKey(userKey);
      if (hasMatchingKey && !state.isContentUnlocked) {
        setState((state) => {
          return { ...state, isContentUnlocked: true };
        });
      }
    } catch (e) {
      console.error('local storage not supported'); //eslint-disable-line
    }

    // to avoid prop drilling we check if the document has a paywall and derive information
    // from the nodes dataset to avoid duplication of work
    const paywalllNode: HTMLElement = document.querySelector(PAYWALL_SELECTOR);
    if (paywalllNode && !state.hasPaywall) {
      const renderOption = paywalllNode.dataset.renderOption;
      setState((state) => {
        return { ...state, hasPaywall: true, renderOption };
      });
    }
  }, [
    cookieName,
    handleUnlockContent,
    hasInvertedPaywallLogic,
    state,
    unlockKey,
  ]);

  const setIsModalOpen = (isModalOpen) => {
    setState((state) => {
      return { ...state, isModalOpen };
    });
  };

  const resetPaywall = () => {
    localStorage.setItem(cookieName, 'false');
    window.location.reload();
  };

  const value = {
    setIsModalOpen,
    isModalOpen: state.isModalOpen,
    hasPaywall: state.hasPaywall,
    isContentUnlocked: state.isContentUnlocked,
    handleUnlockContent,
    renderOption: state.renderOption,
    resetPaywall,
  };
  return (
    <PaywallContext.Provider value={value}>{children}</PaywallContext.Provider>
  );
};

export const usePaywallProvider = () => {
  const context = useContext(PaywallContext);
  if (!context) {
    throw new Error(
      `usePaywallProvider must be used within a Paywall provider`,
    );
  }
  return context;
};

export default usePaywallProvider;
