export const metaData = {
  metaTitle: 'Overview',
  metaDescription: 'The technology layer for financial services"',
  preloadImages: [
    {
      src: '/assets/img/backgrounds/fallback-auth-hero@2x.png',
    },
  ],
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'flip-horizontal': true,
  'image-based': true,
  header: 'Instantly connect bank accounts for payments',
  'header-class': 'h2',
  subheader:
    'Plaid makes it easy to set up ACH transfers by connecting accounts from any bank or credit union in the U.S. to your app.',
  'subheader-p': true,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': false,
  'secondary-cta-text': null,
  'tertiary-cta':
    'Or, <a class="signup-attach-utm" href="//dashboard.plaid.com/signup">get started</a>',
  image: true,
  'image-url': '/assets/img/backgrounds/fallback-auth-hero@2x.png',
  'image-width': 900,
  'image-height': 711,
  'no-min-height-header': true,
};

export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-current-black600.png',
      alt: 'Current',
      width: 252,
      height: 126,
    },
    {
      img: '/assets/img/logos/logo-paysafe-black600.png',
      alt: 'Paysafe',
      width: 252,
      height: 126,
    },
    {
      img: '/assets/img/logos/logo-wise-black600.png',
      alt: 'TransferWise',
      width: 252,
      height: 126,
    },
    {
      img: '/assets/img/logos/logo-venmo-black600.png',
      alt: 'Venmo',
      width: 252,
      height: 126,
    },
  ],
};

export const introSectionData = {
  'problem-statement':
    'Receiving ACH payments allows for direct bank transfers, but getting users to connect their accounts can be a cumbersome process.',
  'solution-statement':
    'Plaid makes it easy to set up ACH transfers from any bank or credit union in the U.S. Once a user links their account via their banking credentials, Plaid retrieves their account and routing numbers. ACH payments can be set up in moments.',
};

export const quoteData = {
  quote:
    'Plaid enabled Shift to offer a fully integrated online purchasing experience. The financial aspects of the process are much more seamless.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/shift-logo.svg',
  'logo-alt': 'Shift logo',
  quoteLogoWidth: 91,
  quoteLogoHeight: 28,
  cite: 'James Wu, Software Engineer at Shift',
  'quote-link': 'https://plaid.com/documents/Shift-Case-Study.pdf',
  'quote-link-text': 'See the full case study',
  'with-background': false,
  'remove-default-design': false,
  dark: false,
};

export const supportingTextData = {
  'with-background': false,
  'background-class': null,
  'with-image': false,
  'three-up': true,
  'six-up': false,
  header1: 'Maximize conversions',
  text1:
    'Multiple ways to authenticate accounts through Plaid’s&nbsp;front-end&nbsp;module.',
  header2: 'Institution coverage',
  text2:
    'Authenticate accounts from ~11,500 banks and credits unions in the U.S.',
  header3: 'Business account support',
  text3: 'Authenticate business accounts using Same Day Micro-Deposits.',
};

export const featuresData = {
  'header-section': true,
  'header-text': 'Features',
  'feature-count': 'one',
  'feature-title': 'One integration with Auth, multiple ways to connect',
  'feature-body':
    "We'll select the best and quickest route depending on a user's institution.",
  'feature-img': '/assets/img/backgrounds/fallback-auth-features@2x.png',
  featureImgWidth: 384,
  featureImgHeight: 384,
  checklist: true,
  'checklist-items': [
    {
      'checklist-item-text':
        '<strong>Instant authentication</strong> Plaid connects accounts in seconds when users sign in with their banking credentials.',
    },
    {
      'checklist-item-text':
        '<strong>Instant match</strong> Plaid matches user-provided account and routing numbers with what’s on file at the bank.',
    },
    {
      'checklist-item-text':
        '<strong>Automated micro-deposits</strong> Plaid sends micro-deposits which are automatically verified on the behalf of users.',
    },
    {
      'checklist-item-text':
        '<strong>Same Day micro-deposits</strong> Plaid sends micro-deposits for users to verify within 1 business day.',
    },
  ],
};
