import React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components-v2/Inputs';
import { BackgroundContext } from 'src/contexts';
import { CmsBackgroundColors } from 'src/lib/ts-types';
import { useIsDarkMode } from 'src/hooks/useIsDarkMode';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { gridCardStyles } from './Card.styles';
import { CmsThemes } from 'src/lib/constants';

interface ICard {
  href: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  isFeatured?: boolean;
  mode?: string;
  backgroundColor?: CmsBackgroundColors;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const getCardMode = ({ cmsTheme, mode, isFeatured, isDark }) => {
  if (isFeatured) return isDark ? 'light' : 'dark';

  if (cmsTheme === CmsThemes.WEB2) {
    return isDark ? 'dark' : 'light';
  }

  return mode || (isDark ? 'dark' : 'light');
};

const getProviderBgColor = ({ cardMode }) => {
  return cardMode === 'dark' ? 'black' : 'white';
};

const Card: React.FC<ICard> = ({
  href,
  children,
  sx,
  isFeatured = false,
  mode = '',
  backgroundColor = 'white',
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
}) => {
  const cmsTheme = useCmsTheme();
  const isDark = useIsDarkMode();
  const cardMode = getCardMode({ cmsTheme, mode, isFeatured, isDark });
  const cardTheme = useComponentTheme(cardMode);
  const bgProviderColor = getProviderBgColor({ cardMode });

  return (
    <ThemeProvider theme={cardTheme}>
      <BackgroundContext.Provider value={bgProviderColor}>
        <Link href={href}>
          <a
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            <Stack
              direction='column'
              sx={{
                ...gridCardStyles({
                  cmsTheme,
                  isFeatured,
                  backgroundColor: backgroundColor,
                  isDark: isDark,
                }),
                height: '100%',
                p: {
                  xs: `${
                    isFeatured
                      ? '4.0rem 3.0rem 4.2rem 3.0rem'
                      : '3.4rem 1.3rem 3.2rem 1.4rem'
                  }`,
                  sm: '4.8rem 3.1rem 5.5rem 4.0rem',
                  lg: '4.0rem 4.0rem 2.7rem 4.0rem',
                },
                alignItems: { xs: 'center', sm: 'flex-start' },
                justifyContent: 'flex-start',
                textAlign: { xs: 'center', sm: 'left' },
                '& h3': {
                  mb: { xs: '1.5rem', md: '1.6rem' },
                },
                '& p': {
                  mb: { xs: '2.0rem', sm: '5.7rem', md: '5.4rem' },
                },
                '& button': {
                  marginTop: 'auto',
                },
                '& img': {
                  width: { xs: '40px', sm: '64px', md: '80px' },
                  mb: { xs: '1.9rem', sm: '3.0rem', md: '1.7rem' },
                },
                '& p:first-child': {
                  // TODO: leftover from static fraud-compliance page.  remove once the static page is removed. https://plaidinc.atlassian.net/browse/SITE-5099
                  mb: { xs: '4.5rem', md: '2.4rem' },
                  mt: { sm: '0.7rem', md: '1.7rem' },
                },
                '& .featured-paragraph': {
                  mb: { xs: '1.5rem', sm: '1.6rem', md: '2.4rem' },
                },
                '& p:nth-last-of-type(1):empty': {
                  display: 'none',
                },
                ...sx,
              }}
            >
              {children}
            </Stack>
          </a>
        </Link>
      </BackgroundContext.Provider>
    </ThemeProvider>
  );
};

export default Card;
