import styled from 'threads5/styles/styled';
import { colors } from 'src/components-v2/theme';
import { CmsThemes } from 'src/lib/constants';

interface CmsTheme {
  cmsTheme?: 'web 2.0' | 'web 3.0';
}

// *********  Homepage styles **********
const DefaultStyles = {
  fontWeight: '800',
  backgroundColor: 'inherit',
  marginBottom: '0.8rem',
  marginTop: '0',
};

// *********  Threads Event styles **********
const DefaultThreadsEventStyles = {
  fontWeight: '600',
  color: colors.black,
  backgroundColor: 'inherit',
  marginBottom: '0.8rem',
  marginTop: '0',
};

const getFontStyles = ({ theme, cmsTheme, fontType = 'sans' }) => {
  const styles = {
    sans: {
      [CmsThemes.WEB2]: {
        fontFamily: theme.typography.sans,
        color: theme.palette['text/contrast-max'],
      },
      [CmsThemes.WEB3]: {
        fontFamily: theme.typography.plaidSans + ' !important', // bang important to override the global styles
        color: theme.palette['web3/text/contrast-max'],
      },
    },
    // NOTE: we're making very brave assumptions here that all monos are changing to plaid sans
    mono: {
      [CmsThemes.WEB2]: {
        fontFamily: theme.typography.mono,
        color: theme.palette['text/contrast-max'],
      },
      [CmsThemes.WEB3]: {
        fontFamily: theme.typography.plaidSans + ' !important', // bang important to override the global styles
        color: theme.palette['web3/text/contrast-max'],
      },
    },
  };
  return styles[fontType][cmsTheme];
};

// TODO: https://plaidinc.atlassian.net/browse/SITE-5245
const inlineLinkStyles = (theme) => {
  // we have to use !important to override the global styles
  return {
    '& a': {
      fontWeight: 600,
      textDecorationColor: `${theme.palette.black600} !important`,
      textDecorationThickness: '1px !important',
      transition: 'text-decoration-color !important',
      transitionDuration: '350ms !important',
      transitionTimingFunction: 'cubic-bezier(0.23, 1.2, 0.32, 1) !important',
      '&:hover, :focus': {
        color: 'inherit !important',
        textDecoration: 'underline !important',
        textDecorationColor: theme.palette.black,
        textDecorationThickness: '2px !important',
        textUnderlineOffset: '4px !important',
      },
      '&:active': {
        textDecorationColor: `${theme.palette.black600} !important`,
        textDecorationThickness: '1px !important',
      },
    },
  };
};

export const themes = {
  // web 2.0 styles
  // bear in mind that these styles are still inheriting from the global styles
  // it's best to inspect the semantic elements and explicitly add the global styles
  // this way when they're removed we will not have any regressions
  // NOTE: that we take a mobile first approach when styling
  default: {
    h1: styled('h1')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '4.0rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '100%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '8.0rem',
        },
      };
    }),
    h2: styled('h2')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '3.2rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '110%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '6.0rem',
        },
      };
    }),
    h3: styled('h3')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '2.4rem',
        fontWeight: 600,
        letterSpacing: '-0.2rem',
        lineHeight: '120%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '4.0rem',
        },
      };
    }),
    h4: styled('h4')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '2.0rem',
        fontWeight: 600,
        letterSpacing: '-0.15rem',
        lineHeight: '130%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '3.0rem',
        },
      };
    }),
    // Subhead Regular
    h5: styled('h5')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '140%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.4rem',
        },
        // Subhead SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
      };
    }),
    // P1 Regular
    p: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
        },
        // P1 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P2 Regular
    p2: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.4rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem',
        },
        // P2 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P3 Regular
    p3: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.6rem',
        },
        // P3 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P4 Regular
    p4: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.6rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem',
        },
        // P4 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // P5 Regular
    p5: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.8rem',
        fontWeight: 400,
        letterSpacing: '0rem',
        lineHeight: '150%',
        marginBottom: '0',
        // P5 SemiBold
        '& b, strong': {
          fontWeight: 600,
        },
        ...inlineLinkStyles(theme),
      };
    }),
    // EYEBROW
    h6: styled('h6')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        // NOTE: we're making very brave assumptions here that all monos are changing to plaid sans
        ...getFontStyles({ theme, cmsTheme, fontType: 'mono' }),
        fontSize: '1.2rem',
        fontWeight: 800,
        letterSpacing: '0.125rem',
        lineHeight: '100%',
        marginBottom: '0',
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
          letterSpacing: '0.2rem',
        },
      };
    }),
  },

  // legacy themes to be deprecated
  'post-threads-hero': {
    h1: styled('h1')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '6rem',
        lineHeight: '7.5rem',
        letterSpacing: '-0.425rem',
        [theme.breakpoints.up('lg')]: {
          lineHeight: '9rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.5rem',
          lineHeight: '4rem',
          letterSpacing: '-0.05rem',
        },
      };
    }),
  },
  'post-threads-two-column-video-card': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '9rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '3.2rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    h6: styled('h6')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        letterSpacing: '0.024rem',
        textTransform: 'uppercase',
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'post-threads-two-column-video-layout': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '9rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '3.2rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'post-threads-features': {
    h2: styled('h2')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '4.5rem',
        lineHeight: '5.5rem',
        letterSpacing: '-0.3rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '6rem',
          lineHeight: '6.8rem',
          letterSpacing: '-0.425rem',
        },
      };
    }),
    h3: styled('h3')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '3.2rem',
        fontWeight: '500',
        lineHeight: '4.5rem',
        letterSpacing: '-0.15rem',
        [theme.breakpoints.up('sm')]: {
          fontSize: '4.5rem',
          lineHeight: '5.5rem',
          letterSpacing: '-0.3rem',
        },
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultThreadsEventStyles,
        fontFamily: theme.typography.sans,
        fontSize: '1.8rem',
        lineHeight: '2.6rem',
        letterSpacing: '0.008rem',
        fontWeight: '400',
      };
    }),
  },
  'main-menu': {
    h6: styled('h6')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontSize: '1.6rem',
        fontFamily: theme.typography.mono,
        textTransform: 'uppercase',
        color: colors.black700,
        letterSpacing: '0.15rem',
      };
    }),
    p: styled('p')(({ theme }) => {
      return {
        ...DefaultStyles,
        fontFamily: theme.typography.sans,
        color: theme.palette.black,
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: '2.24rem',
      };
    }),
  },
  'main-menu-new': {
    h6: styled('h6')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.8rem',
        lineHeight: '2.4rem',
        fontWeight: 600,
        letterSpacing: 0,
        textTransform: 'none',
        color: 'inherit',
        [theme.breakpoints.up(1078)]: {
          fontSize: '1.4rem',
          lineHeight: '2rem',
        },
      };
    }),
    p: styled('p')<CmsTheme>(({ theme, cmsTheme }) => {
      return {
        ...getFontStyles({ theme, cmsTheme }),
        fontSize: '1.8rem',
        lineHeight: 1.333333,
        fontWeight: 600,
        color: 'inherit',
        [theme.breakpoints.up(1078)]: {
          fontSize: '1.6rem',
          lineHeight: 1.5,
        },
      };
    }),
  },
};

// TODO: we should be able to remove this once we've migrated everything to web 3.0 paradigms
export const darkOverrides = {
  [CmsThemes.WEB2]: {
    color: 'white',
    '& a': { color: 'white !important' },
  },
  [CmsThemes.WEB3]: {
    color: 'web3/text/contrast-max',
    '& a': { color: 'web3/text/contrast-max !important' },
  },
};
