/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import styled from 'threads5/styles/styled';

// components
import NavbarMenu from './NavbarMenu';
import { Link, Button } from 'src/components/Inputs';
import { BlogLogo } from 'src/components/Blog/Logo';
import PlaidLogo from 'src/components-v2/SVGs/PlaidLogo';

// helpers
import { DesktopNavbarProps } from './types';

const Nav = styled('nav')(({}) => {
  return {};
});

const DesktopNavbar: React.FC<DesktopNavbarProps> = ({
  skipLinkCta,
  logoAlt,
  menuItems,
  login,
  getStarted,
  skipLinkAnchor = '#main-content',
  onBlogPage = false,
  getStartedTrackingId = '',
}) => {
  return (
    <Nav id='mainNav' className='Navbar'>
      <div className='Navbar__inner-content'>
        <Link href={skipLinkAnchor}>
          <a
            tabIndex={0}
            className='Navbar__skip-link button'
            href={skipLinkAnchor}
          >
            {skipLinkCta}
          </a>
        </Link>
        {/* the ID here is required by Foundation for the docs sticky sidenav */}
        <div className='Navbar__logo-wrapper'>
          <Link href='/'>
            <a className='Navbar__logo' aria-label='Go to https://plaid.com/'>
              <PlaidLogo />
            </a>
          </Link>
          {onBlogPage && <BlogLogo />}
        </div>
        <NavbarMenu menuItems={menuItems} />
        <div className='Navbar__actions'>
          <Link href={login.url}>
            <a className='button clear Navbar__action'>{login.name}</a>{' '}
          </Link>
          <Button
            href={getStarted.url}
            className='button-cta signup-attach-utm'
            secondary
            trackingId={getStartedTrackingId}
          >
            {getStarted.name}
          </Button>
        </div>
      </div>
    </Nav>
  );
};

export default DesktopNavbar;
