import React from 'react';
import { useIsDarkBackground } from '../BackgroundContext';
import { useCmsTheme } from '../CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';

// default to light mode
const ModeContext = React.createContext(undefined);

// we intentionally do not call this "light/dark" mode provider
// because we could potentially have more than two modes
export const ModeContextProvider = ({ mode, children }) => {
  return <ModeContext.Provider value={mode}>{children}</ModeContext.Provider>;
};

export interface ICmsModeProps {
  backgroundColor?: string;
  mode?: string;
}

export const useCmsMode = (props?: ICmsModeProps) => {
  const { backgroundColor, mode } = props || {};
  const context = React.useContext(ModeContext);
  const isDarkBackground = useIsDarkBackground(backgroundColor);
  const cmsTheme = useCmsTheme();

  // all pages using the Main Template will default to web 2.0 even if not explicitly set
  // therefore we short-circuit to the old is dark background logic to avoid breaking changes
  if (cmsTheme === CmsThemes.WEB2) {
    return isDarkBackground ? 'dark' : 'light';
  }

  // when a page is set to web 3.0 but has not yet been migrated to a background container
  // and/or a mode has not been explicitly set, we again short-circuit to the old is dark background logic
  if (backgroundColor !== 'transparent' && !mode) {
    return isDarkBackground ? 'dark' : 'light';
  }

  // when the cms mode is set to web 3 and the background color is a solid color
  // we short-circuit to the old is dark background logic to improve the editor experience
  // in other words color will take precedence over cms mode unless the bg is transparent or a gradient
  if (cmsTheme === CmsThemes.WEB3 && isDarkBackground !== undefined) {
    return isDarkBackground ? 'dark' : 'light';
  }

  // otherwise we use the mode context or explicit component mode
  return context ?? mode;
};
