import React from 'react';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

const ShowcaseCard = (props) => (
  <React.Fragment>
    <div
      className={`content-card showcase-card ${
        props['index'] === 0 ? 'showcase-card--a' : ''
      }${props['index'] === 1 ? 'showcase-card--b' : ''}`}
      data-testid='showcase-card'
    >
      <div className='showcase-card__logo'>
        <Image src={props.logo} />
      </div>
      <p className='showcase-card__text'>{props.text}</p>
      <Link href={props['cta-link']}>
        <a>
          <span className='feature-section-link-text'>
            {props['cta-text']} <ArrowRightSingleSmall />
          </span>
        </a>
      </Link>
    </div>
  </React.Fragment>
);

export default ShowcaseCard;
