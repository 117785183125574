import React from 'react';

const CreditCardsIcon = () => {
  return (
    <svg height={12} width={17} xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <rect
          fill='#ffaab9'
          height={11}
          rx={2}
          stroke='#111'
          width={16}
          x={0.5}
          y={0.5}
        />
        <g fill='#111'>
          <path d='M3 7h4v2H3zM1 3h15v2H1z' />
        </g>
      </g>
    </svg>
  );
};

export default CreditCardsIcon;
