import React, { FC } from 'react';
import IntroSectionItem, { IntroSectionItemProps } from './IntroSectionItem';
import IntroSectionHeader from './IntroSectionHeader';

export interface IntroSectionProps {
  backgroundImageBottomLeft?: string;
  backgroundImageTopRight?: string;
  classes?: string;
  header?: {
    hasHeader: boolean;
    items: { label: string; content: string }[];
  };
  items: IntroSectionItemProps[];
}

const IntroSectionCms: FC<IntroSectionProps> = ({
  backgroundImageBottomLeft = '',
  backgroundImageTopRight = '',
  classes = '',
  header: { hasHeader, items: headerItems } = {},
  items,
}) => (
  <div
    className={`intro-section ${classes}`}
    style={{
      backgroundImage:
        backgroundImageBottomLeft && backgroundImageTopRight
          ? `url('${backgroundImageBottomLeft}'),url('${backgroundImageTopRight}')`
          : 'none',
    }}
    data-testid='intro-section'
  >
    <div className='grid-container'>
      {hasHeader && <IntroSectionHeader headerItems={headerItems} />}
      {items != null &&
        items.map((item, i) => (
          <IntroSectionItem {...item} key={`intro-section-item-${i}`} />
        ))}
    </div>
  </div>
);

export default IntroSectionCms;
