import React from 'react';
import cx from 'classnames';

// components
import AccountTableList, { AccountTableListProps } from './AccountTableList';
import PlainAccountTable from './PlainAccountTable';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

export interface AccountTableSectionProps {
  accountTypeDescription?: string;
  accountTypeHeader?: string;
  bottomCtaText?: string;
  bottomCtaUrl?: string;
  mainAccountTable?: AccountTableListProps;
  noBottomPadding?: boolean;
  noTopPadding?: boolean;
  plainAccountTable?: AccountTableListProps;
  secondaryAccountTables?: AccountTableListProps[];
}

const AccountTableSection: React.FC<AccountTableSectionProps> = ({
  accountTypeDescription = '',
  accountTypeHeader = '',
  bottomCtaText,
  bottomCtaUrl,
  mainAccountTable,
  noBottomPadding = false,
  noTopPadding = false,
  plainAccountTable,
  secondaryAccountTables,
}) => (
  <div
    className={cx(
      'static-data-table',
      noTopPadding && 'static-data-table--no-top-padding',
      noBottomPadding && 'static-data-table--no-bottom-padding',
    )}
  >
    <div className='grid-container static-data-container'>
      <div className='grid-x align-justify'>
        <div className='cell medium-6'>
          <div className={cx('account-type-table')}>
            <h4 className='account-type-header'>{accountTypeHeader}</h4>
            {accountTypeDescription && (
              <p className='account-table-description'>
                {accountTypeDescription}
              </p>
            )}
          </div>
        </div>
        {plainAccountTable ? (
          <PlainAccountTable {...plainAccountTable} />
        ) : (
          <div className='cell medium-12'>
            <AccountTableList {...mainAccountTable} />
            {secondaryAccountTables && (
              <div className='grid-x grid-margin-x'>
                {secondaryAccountTables.map((table, i) => (
                  <div
                    className={cx(
                      'cell',
                      `medium-${12 / secondaryAccountTables.length}`,
                    )}
                    key={`secondary-account-table-${i}`}
                  >
                    <AccountTableList {...table} />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {bottomCtaUrl && bottomCtaText && (
          <Link href={bottomCtaUrl}>
            <a>
              <span className='feature-section-link-text'>
                {bottomCtaText} <ArrowRightSingleSmall />
              </span>
            </a>
          </Link>
        )}
      </div>
    </div>
  </div>
);

export default AccountTableSection;
