/* eslint-disable import/prefer-default-export */
const codeBody = `http code 200
{
  "accounts": [{
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "balances": {
      "available": 100,
      "current": 110,
      "limit": null,
      "iso_currency_code": "USD",
      "unofficial_currency_code": null,
    },
    "mask": "9606",
    "name": "Plaid Checking",
    "official_name": "Plaid Gold Checking",
    "subtype": "checking",
    "type": "depository"
  }],
  "numbers": {
    "ach": [{
      "account": "9900009606",
      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
      "routing": "011401533",
      "wire_routing": "021000021"
    }],
    "eft":[{
      "account": "111122223333",
      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
      "institution": "021",
      "branch": "01140"
    }],
    "bacs":[{
      "account": "31926819",
      "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
      "sort_code": "601613"
    }]
  }
  "item": {Object},
  "request_id": "m8MDnv9okwxFNBV"
}`;

export const interactiveData = {
  'title-text': 'Make an Auth request',
  'description-text': 'Use the Auth endpoint to submit a POST request',
  'code-title': 'Retrieve Auth request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/auth/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Instant account authentication',
  'post-run-description-text':
    'Once a user securely connects their account using bank credentials, the <strong>/auth/get</strong> endpoint enables you to retrieve the bank account and routing numbers, along with high-level account data and balances when available',
  'post-run-code-title': 'Retrieve Auth response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      title: 'US',
      'preserve-title-case': true,
      description:
        'Retrieve account number and routing number to authenticate a checking or savings account for ACH payments',
      'code-line-start': 19,
      'code-highlight-length': 6,
    },
    {
      title: 'Canada',
      'preserve-title-case': true,
      description:
        'Retrieve account number, institution number, and branch number to authenticate an account for EFT payments',
      'code-line-start': 25,
      'code-highlight-length': 6,
    },
    {
      title: 'UK',
      'preserve-title-case': true,
      description:
        'Retrieve account number and sort code to enable seamless debit withdrawals using Faster Payments',
      'code-line-start': 31,
      'code-highlight-length': 5,
    },
  ],
  'bottom-cta-url': '/docs/auth/',
  'bottom-cta-text': 'View API docs',
};
