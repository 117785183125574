import React, { FC } from 'react';

import { FormSectionCovidWaiverProps } from './FormSectionCovidWaiver.types';

const FormSectionCovidWaiver: FC<FormSectionCovidWaiverProps> = ({
  formData,
  register,
}) => {
  return (
    <div className='grid-container full'>
      <div className='grid-x form-group'>
        <div className='checkbox__container'>
          <label className='cta-section__label checkbox--label-inline checkbox'>
            <div className='checkbox__input-container'>
              <input
                className='checkbox__input'
                type='checkbox'
                id={formData.covidwaiveroptin.name}
                name={formData.covidwaiveroptin.name}
                ref={register}
                required
              />
              <div className='checkbox__faux-input' />
            </div>
          </label>
          <label
            htmlFor={formData.covidwaiveroptin.name}
            className='cta-section__label checkbox__label-text checkbox__label-text--inline-block'
            dangerouslySetInnerHTML={{
              __html: formData.covidwaiveroptin?.label,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormSectionCovidWaiver;
