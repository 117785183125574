import React from 'react';

// components
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import { Box } from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import { Link } from 'src/components/Inputs';

import { ILogo } from './Logo.types';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const DESKTOP_IMAGE = '?fm=webp&w=320&q=70';
const MOBILE_IMAGE = '?fm=webp&w=240&q=70';

const Logo: React.FC<ILogo> = ({ image, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Box
      {...inspectorProps({ fieldId: 'logos' })}
      sx={{
        width: {
          xs: '120px',
          md: '144px',
          lg: '160px',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ConditionalWrapper
        condition={image.href}
        wrapper={(children) => {
          return (
            <Link href={image.href}>
              <a>{children}</a>
            </Link>
          );
        }}
      >
        <Image
          sx={{ cursor: image.href ? 'pointer' : 'auto' }}
          alt={image.alt}
          src={image.src + DESKTOP_IMAGE}
          width={image.width || 160}
          height={image.height || 30}
          isLazy={image.isLazy}
          sources={[
            {
              media: '(max-width: 639px)',
              srcSet: `${image.src + MOBILE_IMAGE}`,
            },
            {
              media: '(min-width: 640px)',
              srcSet: `${image.src + DESKTOP_IMAGE}`,
            },
          ]}
        />
      </ConditionalWrapper>
    </Box>
  );
};

export default Logo;
