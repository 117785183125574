import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// hooks
import { useInEu } from 'src/hooks';

// helpers
import { getCurrentPage, mutateHardCodedLinks } from 'src/lib/utils';

// constants
import { Locales } from 'src/lib/constants';

// components
import { Link, LocaleSwitch } from 'src/components/Inputs';
import { Lazy } from 'src/components-v2/Helpers';
import { CtaBlockWithButtons } from './CtaBlockWithButtons';
import TwitterWhiteIcon from 'src/components/SVGs/Icons/TwitterWhiteIcon';
import InstagramIcon from 'src/components/SVGs/Icons/InstagramIcon';
import OneTrustButton from 'src/components-v3/Footer/OneTrustButton';

// contexts
import { usePageLocale } from 'src/contexts';

import * as enUsFooter from 'src/store/navigation/footer';
import * as enEuFooter from 'src/store/en-eu/navigation/footer';
import * as enGbFooter from 'src/store/en-gb/navigation/footer';

const ListItemProptypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  locale: PropTypes.string,
  trackingId: PropTypes.string,
};
const ListItem = ({ className, name, url, locale = '', trackingId = '' }) => {
  const pageLocale = usePageLocale();
  return (
    <li className={className}>
      {url ? (
        <Link href={url} locale={locale || pageLocale} trackingId={trackingId}>
          <a>{name}</a>
        </Link>
      ) : (
        <>{name}</>
      )}
    </li>
  );
};

ListItem.propTypes = ListItemProptypes;

const FooterSectionPropTypes = {
  header: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(ListItemProptypes)),
};
const FooterSection = ({ header, items }) => {
  return (
    <>
      <ListItem className='menu-divider' />
      <ListItem className='footer-header' name={header} />
      {items.map((item, i) => {
        return <ListItem {...item} key={`${header}-${i}`} />;
      })}
    </>
  );
};

FooterSection.propTypes = FooterSectionPropTypes;

const propTypes = {
  hasFooterCta: PropTypes.bool,
};

const footerPerLocale = {
  [Locales.EN_US]: enUsFooter,
  [Locales.EN_EU]: enEuFooter,
  [Locales.EN_GB]: enGbFooter,
};

const Footer = ({ hasFooterCta, ...props }) => {
  const { route } = useRouter();
  const currentPage = getCurrentPage(route);
  const isInEu = useInEu(); // TODO: replace with locale cookie when it's ready
  const locale = usePageLocale();

  // data
  const { footerData } = mutateHardCodedLinks({
    data: footerPerLocale[locale] || footerPerLocale[Locales.EN_US],
    locale,
  });
  const { copyrightName, eeaDisclaimer, hideCTAWhen, sections } = footerData;

  return (
    <footer id='footer' className='footer'>
      {hasFooterCta && <CtaBlockWithButtons {...props} />}
      {typeof hasFooterCta === 'undefined' &&
        !hideCTAWhen.includes(currentPage) && (
          <CtaBlockWithButtons {...props} />
        )}
      <div className='grid-container'>
        <div className='grid-x grid-margin-x'>
          <div className='cell small-6 large-3'>
            {sections.products != null && (
              <ul className='menu vertical'>
                <FooterSection {...sections.products} />
              </ul>
            )}
          </div>
          <div className='cell small-6 large-3'>
            <div className='show-for-large'>
              {(sections.useCases != null || sections.docs != null) && (
                <ul className='menu vertical'>
                  {sections.useCases != null && (
                    <FooterSection {...sections.useCases} />
                  )}
                  {sections.docs != null && (
                    <>
                      <li className='footer-invisible-space'></li>
                      <FooterSection {...sections.docs} />
                    </>
                  )}
                </ul>
              )}
            </div>
            <div className='hide-for-large'>
              {(sections.aboutUs != null || sections.resources != null) && (
                <ul className='menu vertical'>
                  {sections.aboutUs != null && (
                    <FooterSection {...sections.aboutUs} />
                  )}
                  {sections.resources != null && (
                    <>
                      <li className='footer-invisible-space'></li>
                      <FooterSection {...sections.resources} />
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className='cell small-6 large-3'>
            <div className='show-for-large'>
              {(sections.aboutUs != null || sections.resources != null) && (
                <ul className='menu vertical'>
                  {sections.aboutUs != null && (
                    <FooterSection {...sections.aboutUs} />
                  )}
                  {sections.resources != null && (
                    <>
                      <li className='footer-invisible-space'></li>
                      <FooterSection {...sections.resources} />
                    </>
                  )}
                </ul>
              )}
            </div>
            <div className='hide-for-large'>
              {(sections.useCases != null || sections.docs != null) && (
                <ul className='menu vertical'>
                  {sections.useCases != null && (
                    <FooterSection {...sections.useCases} />
                  )}
                  {sections.docs != null && (
                    <>
                      <li className='footer-invisible-space'></li>
                      <FooterSection {...sections.docs} />
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className='cell small-6 large-3'>
            {(sections.forConsumers != null ||
              sections.forInstitutions != null) && (
              <ul className='menu vertical'>
                {sections.forConsumers != null && (
                  <FooterSection {...sections.forConsumers} />
                )}
                {sections.forInstitutions != null && (
                  <>
                    <li className='footer-invisible-space' />
                    <FooterSection {...sections.forInstitutions} />
                  </>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className='grid-x grid-margin-x footer-details'>
          <div className='cell small-12 medium-6'>
            <LocaleSwitch />
          </div>
          {/* <div className='cell small-12 medium-3'></div> */}
          <div className='cell small-12 medium-3'>
            <p className='footer-copyright'>
              &copy; {new Date().getFullYear()} {copyrightName}
            </p>
          </div>
          <div className='cell small-12 medium-3'>
            <Link href='https://twitter.com/plaid'>
              <a
                className='footer-details-icon'
                aria-label='Go to https://twitter.com/plaid'
              >
                <Lazy>
                  <span aria-hidden='true'>
                    <TwitterWhiteIcon />
                  </span>
                </Lazy>
              </a>
            </Link>
            <Link href='https://www.instagram.com/plaid/'>
              <a
                className='footer-details-icon'
                aria-label='Go to https://www.instagram.com/plaid/'
              >
                <Lazy>
                  <span aria-hidden='true'>
                    <InstagramIcon />
                  </span>
                </Lazy>
              </a>
            </Link>
          </div>
        </div>
        <div className='grid-x grid-margin-x ot-btn-container'>
          <div className='cell small-24'>
            <OneTrustButton />
          </div>
        </div>
        {isInEu && <div className='footer-eea-disclaimer'>{eeaDisclaimer}</div>}
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default Footer;
