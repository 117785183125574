import React from 'react';
import PropTypes from 'prop-types';
import { toKebabCase } from 'src/lib/utils';

import { JOB_LOCATIONS } from 'src/lib/constants';

/**
 * @param {string} location
 * @returns {string} string to use as a classname modifier based on location
 */
export function locationModifierClassname(location) {
  const base = 'job-post__brackets';
  return JOB_LOCATIONS.includes(location)
    ? `${base}--${toKebabCase(location)}`
    : `${base}--generic`;
}

const propTypes = {
  text: PropTypes.string.isRequired,
  categories: PropTypes.shape({
    team: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    commitment: PropTypes.string.isRequired,
  }),
};

const JobHeading = ({ text, categories: { team, location, commitment } }) => {
  const modifier = locationModifierClassname(location);
  return (
    <>
      <div className='job-post__dept'>{team}</div>
      <h2 className='job-post__title'>{text}</h2>
      <p className={`job-post__brackets ${modifier}`}>
        {location} | {commitment}
      </p>
    </>
  );
};

JobHeading.propTypes = propTypes;
export default JobHeading;
