import { Locales, StaticTemplates } from 'src/lib/constants';

export const enEuRootPages = [
  {
    params: {
      id: Locales.EN_EU,
      index: 'what-is-plaid',
      template: StaticTemplates.WHAT_IS_PLAID,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'why-is-plaid-involved',
      template: StaticTemplates.WHY_PLAID_IS_INVOLVED,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'contact-thanks',
      template: StaticTemplates.CONTACT_THANKS,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'contact',
      template: StaticTemplates.CONTACT,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'developer-policy',
      template: StaticTemplates.DEVELOPER_POLICY,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'discover-apps',
      template: StaticTemplates.DISCOVER_APPS,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'global',
      template: StaticTemplates.GLOBAL,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'how-it-works-for-consumers',
      template: StaticTemplates.HOW_IT_WORKS,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'open-banking',
      template: StaticTemplates.OPEN_BANKING,
    },
  },
  {
    params: {
      id: Locales.EN_EU,
      index: 'security',
      template: StaticTemplates.SECURITY,
    },
  },

  {
    params: {
      id: Locales.EN_EU,
      index: 'how-we-handle-data',
      template: StaticTemplates.HOW_WE_HANDLE_DATA,
    },
  },
];
