import styles from './SmallCodeBlock.module.scss';

interface SmallCodeProps {}

const SmallCodeBlock: React.FC<SmallCodeProps> = ({ children }) => {
  return <pre className={styles.smallCode}>{children}</pre>;
};

SmallCodeBlock.displayName = 'SmallCodeBlock';

export default SmallCodeBlock;
