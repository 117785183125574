import React from 'react';
import Image from 'src/components/Image';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

const ContentBlockSection = (props) => (
  <section className='content-block-section' id={props.id}>
    <div className='grid-container'>
      <div className='grid-x'>
        <div className='cell small-12 medium-4'>
          <div className='grid-x'>
            {props.image && (
              <div className='cell small-8 medium-8 medium-offset-0'>
                <Image
                  className='content-block-section__image'
                  src={props.image.src}
                  alt={props.image.alt}
                />
              </div>
            )}
            {props.title && (
              <div
                className={`cell small-12 medium-${
                  props['narrow-title'] ? '6' : '8'
                }`}
              >
                <h3 className='content-block-section__title'>{props.title}</h3>
              </div>
            )}
          </div>
        </div>
        <div className='cell small-12 medium-8'>
          <div className='grid-x'>
            <div className='cell small-12'>
              {props.heading && (
                <h4 className='content-block-section__heading'>
                  {props.heading}
                </h4>
              )}
            </div>
            {props.quote &&
              props.quote.map((item, i) => (
                <React.Fragment key={`quote-${i}`}>
                  <div className='cell small-12'>
                    <h4 className='content-block-section__quote center-content center-content--mobile'>
                      {item.copy}
                    </h4>
                  </div>
                  <div className='cell small-12'>
                    <p className='content-block-section__paragraph center-content center-content--mobile'>
                      {item.source}
                    </p>
                  </div>
                </React.Fragment>
              ))}
            {props.paragraph &&
              props.paragraph.map((item, i) => (
                <div className='cell small-12' key={`paragraph-${i}`}>
                  {item.list ? (
                    <ul className='content-block-section__ul'>
                      {item.list.map((item, i) => (
                        <li key={`paragraph-list-${i}`}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p
                      className='content-block-section__paragraph'
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  )}

                  {props['content-url'] && (
                    <Link href={props['content-url']}>
                      <a>
                        <span className='feature-section-link-text'>
                          {props['content-link-text']} <ArrowRightSingleSmall />
                        </span>
                      </a>
                    </Link>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ContentBlockSection;
