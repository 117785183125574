const fs = require('fs');
const path = require('path');

type Job = {
  categories: {
    team: string;
  };
};

type Jobs = Array<Job>;

const Lever = {
  api: {
    getJobs: async (
      url = 'https://api.lever.co/v0/postings/plaid?mode=json',
    ): Promise<Jobs> => {
      let jobs;
      try {
        jobs = await fetch(url)
          .then((response) => response.json())
          .then((data) => data);
      } catch (e) {
        throw new Error(e);
      }
      return jobs;
    },
  },
  cache: {
    get: async (id: string): Promise<Job | null | undefined> => {
      const data = await fs.readFileSync(
        path.join(process.cwd(), 'src/store/jobs.db'),
      );
      const products = JSON.parse((data as unknown) as string);
      return products.find((job) => !!job[id]);
    },
    set: async (jobs: Jobs): Promise<void> => {
      return await fs.writeFileSync(
        path.join(process.cwd(), 'src/store/jobs.db'),
        JSON.stringify(jobs),
      );
    },
  },
};

export default Lever;
