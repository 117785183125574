export const makeCardGridNodes = (isFeatured) => {
  const baseNodes = {
    h3: {
      variant: isFeatured ? 'h4' : 'h5',
      component: 'h3',
      fieldId: isFeatured ? 'featuredBody' : 'standardBody',
      sx: {
        fontWeight: '600',
      },
    },
    p: {
      variant: isFeatured ? 'p' : 'p2',
      fieldId: isFeatured ? 'featuredBody' : 'standardBody',
      className: isFeatured ? 'featured-paragraph' : '',
    },
  };

  const assetNode = isFeatured
    ? {}
    : {
        asset: {
          queryString: {
            xs: '?fm=webp&w=80&q=60',
            sm: '?fm=webp&w=80&q=60',
            md: '?fm=webp&w=128&q=60',
            lg: '?fm=webp&w=160&q=60',
          },
          width: 64,
          height: 64,
        },
      };

  return { ...baseNodes, ...assetNode };
};

export const makeStatCardNodes = (sys) => {
  return {
    h3: {
      variant: 'h1',
      component: 'h2',
      fieldId: 'body',
    },
    p: {
      variant: 'h5',
      component: 'p',
      fieldId: 'body',
      style: {
        width: '100%',
      },
    },
    asset: {
      queryString: {
        xs: '?fm=webp&q=60',
        sm: '?fm=webp&q=60',
        md: '?fm=webp&q=60',
        lg: '?fm=webp&q=60',
      },
      width: 76,
      height: 80,
      sys: sys,
    },
  };
};

export const makeImageCardNodes = (fields) => {
  return {
    h3: {
      variant: 'h4',
      component: 'h3',
      fieldId: 'body',
    },
    p: {
      variant: 'p2',
      component: 'p',
      fieldId: 'body',
    },
    asset: {
      queryString: {
        xs: '?fm=webp&w=664&q=60',
        sm: '?fm=webp&w=1080&q=60',
        md:
          fields?.imageCards?.length === 3
            ? '?fm=webp&w=602&q=60'
            : '?fm=webp&w=772&q=60',
        lg:
          fields?.imageCards?.length === 3
            ? '?fm=webp&w=800&q=60'
            : '?fm=webp&w=1220&q=60',
      },
      width: fields?.imageCards?.length === 3 ? 540 : 610,
      height: fields?.imageCards?.length === 3 ? 480 : 460,
    },
  };
};

export const parallaxCardNodes = {
  h3: {
    variant: 'h4',
    component: 'h3',
    fieldId: 'body',
  },
  p: {
    variant: 'p',
    fieldId: 'body',
  },
};
