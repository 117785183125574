import React from 'react';
import { Section, Container, Row } from 'src/components-v2/Layout';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { SectionProps } from 'src/components-v2/Layout/Section/Section.types';

interface IWrapper {
  children: React.ReactNode;
  component?: SectionProps['component'];
  sx?: SxProps<Theme> | { backgroundColor: string };
  className?: string;
}

const Wrapper = React.forwardRef(
  ({ component = 'section', sx = {}, children, ...rest }: IWrapper, ref) => {
    // TODO: we should try to remove spreading over sx here because the keys
    // end up being forwarded to the DOM node which isn't always valid.
    // Ideally this is all done with context providers inside each component
    return (
      <Section component={component} sx={sx} {...rest} ref={ref} {...sx}>
        <Container>
          <Row>{children}</Row>
        </Container>
      </Section>
    );
  },
);
Wrapper.displayName = 'Wrapper';

export default Wrapper;
