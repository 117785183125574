import React from 'react';
import styled from 'threads5/themes/styled';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import AddCircleFilled from 'plaid-threads/Icons/AddCircleFilled';
import AlertErrorRed from 'plaid-threads/Icons/AlertErrorRed';
import Beacon from 'plaid-threads/Icons/Beacon';
import CheckmarkGreenSquareCash from 'plaid-threads/Icons/CheckmarkGreenSquareCash';
import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';
import ChevronDownS2 from 'plaid-threads/Icons/ChevronDownS2';
import ChevronLeftDoubleS1 from 'plaid-threads/Icons/ChevronLeftDoubleS1';
import ChevronLeftS1 from 'plaid-threads/Icons/ChevronLeftS1';
import ChevronLeftS2 from 'plaid-threads/Icons/ChevronLeftS2';
import ChevronRightDoubleS1 from 'plaid-threads/Icons/ChevronRightDoubleS1';
import ChevronRightDoubleS2 from 'plaid-threads/Icons/ChevronRightDoubleS2';
import ChevronRightS1 from 'plaid-threads/Icons/ChevronRightS1';
import ChevronRightS2 from 'plaid-threads/Icons/ChevronRightS2';
import ChevronS1Down from 'plaid-threads/Icons/ChevronS1Down';
import ChevronS1Left from 'plaid-threads/Icons/ChevronS1Left';
import ChevronS1Right from 'plaid-threads/Icons/ChevronS1Right';
import ChevronS1Up from 'plaid-threads/Icons/ChevronS1Up';
import ChevronS2Down from 'plaid-threads/Icons/ChevronS2Down';
import ChevronS2Left from 'plaid-threads/Icons/ChevronS2Left';
import ChevronS2Right from 'plaid-threads/Icons/ChevronS2Right';
import ChevronS2Up from 'plaid-threads/Icons/ChevronS2Up';
import ChevronUpS1 from 'plaid-threads/Icons/ChevronUpS1';
import ChevronUpS2 from 'plaid-threads/Icons/ChevronUpS2';
import CloseS1 from 'plaid-threads/Icons/CloseS1';
import CloseS2 from 'plaid-threads/Icons/CloseS2';
import Configuration from 'plaid-threads/Icons/Configuration';
import GraduationCap from 'plaid-threads/Icons/GraduationCap';
import MenuS1 from 'plaid-threads/Icons/MenuS1';
import MenuS2 from 'plaid-threads/Icons/MenuS2';
import PlaidLogo from 'plaid-threads/Icons/PlaidLogo';
import ProductAssets from 'plaid-threads/Icons/ProductAssets';
import ProductBalance from 'plaid-threads/Icons/ProductBalance';
import ProductIdentity from 'plaid-threads/Icons/ProductIdentity';
import ProductIncome from 'plaid-threads/Icons/ProductIncome';
import ProductInvestments from 'plaid-threads/Icons/ProductInvestments';
import ProductLiabilities from 'plaid-threads/Icons/ProductLiabilities';
import Retry from 'plaid-threads/Icons/Retry';
import StepComplete from 'plaid-threads/Icons/StepComplete20';
import StepInProgress from 'plaid-threads/Icons/StepInProgress20';
import StepIncomplete from 'plaid-threads/Icons/StepIncomplete20';

import {
  Icon as ExperimentalIcon,
  IconProps,
} from 'plaid-threads/_experimental_/Icon'; // eslint-disable-line
import { Icons } from './Icon.types';
const StyledAddCircleFilled = styled(AddCircleFilled)({});
const StyledAlertErrorRed = styled(AlertErrorRed)({});
const StyledBeacon = styled(Beacon)({});
const StyledCheckmarkGreenSquareCash = styled(CheckmarkGreenSquareCash)({});
const StyledChevronDownS1 = styled(ChevronDownS1)({});
const StyledChevronDownS2 = styled(ChevronDownS2)({});
const StyledChevronLeftDoubleS1 = styled(ChevronLeftDoubleS1)({});
const StyledChevronLeftS1 = styled(ChevronLeftS1)({});
const StyledChevronLeftS2 = styled(ChevronLeftS2)({});
const StyledChevronRightDoubleS1 = styled(ChevronRightDoubleS1)({});
const StyledChevronRightDoubleS2 = styled(ChevronRightDoubleS2)({});
const StyledChevronRightS1 = styled(ChevronRightS1)({});
const StyledChevronRightS2 = styled(ChevronRightS2)({});
const StyledChevronS1Down = styled(ChevronS1Down)({});
const StyledChevronS1Left = styled(ChevronS1Left)({});
const StyledChevronS1Right = styled(ChevronS1Right)({});
const StyledChevronS1Up = styled(ChevronS1Up)({});
const StyledChevronS2Down = styled(ChevronS2Down)({});
const StyledChevronS2Left = styled(ChevronS2Left)({});
const StyledChevronS2Right = styled(ChevronS2Right)({});
const StyledChevronS2Up = styled(ChevronS2Up)({});
const StyledChevronUpS1 = styled(ChevronUpS1)({});
const StyledChevronUpS2 = styled(ChevronUpS2)({});
const StyledCloseS1 = styled(CloseS1)({});
const StyledCloseS2 = styled(CloseS2)({});
const StyledConfiguration = styled(Configuration)({});
const StyledGraduationCap = styled(GraduationCap)({});
const StyledMenuS1 = styled(MenuS1)({});
const StyledMenuS2 = styled(MenuS2)({});
const StyledPlaidLogo = styled(PlaidLogo)({});
const StyledProductAssets = styled(ProductAssets)({});
const StyledProductBalance = styled(ProductBalance)({});
const StyledProductIdentity = styled(ProductIdentity)({});
const StyledProductIncome = styled(ProductIncome)({});
const StyledProductInvestments = styled(ProductInvestments)({});
const StyledProductLiabilities = styled(ProductLiabilities)({});
const StyledRetry = styled(Retry)({});
const StyledStepComplete = styled(StepComplete)({});
const StyledStepInProgress = styled(StepInProgress)({});
const StyledStepIncomplete = styled(StepIncomplete)({});

const StyledExperimentalIcon = styled(ExperimentalIcon)({});

export interface IIconProps {
  icon: Icons;
  sx?: SxProps<Theme>;
  size?: IconProps['size'];
  contrast?: IconProps['contrast'];
}

const Icon = ({ icon, ...rest }: IIconProps) => {
  switch (icon) {
    case 'Activity':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='activity'
          appearance='activity'
          {...rest}
        />
      );
    case 'Add':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='add'
          appearance='add'
          {...rest}
        />
      );
    case 'AddCircle':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='add circle'
          appearance='add-circle'
          {...rest}
        />
      );
    case 'AddCircleFilled':
      return <StyledAddCircleFilled {...rest} />;
    case 'AlertBell':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='alert bell'
          appearance='alert-bell'
          {...rest}
        />
      );
    case 'AlertError':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='alert error'
          appearance='alert-error'
          {...rest}
        />
      );
    case 'AlertErrorRed':
      return <StyledAlertErrorRed {...rest} />;
    case 'AlertWarning':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='alert warning'
          appearance='alert-warning'
          {...rest}
        />
      );
    case 'AppearanceHide':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='appearance hide'
          appearance='appearance-hide'
          {...rest}
        />
      );
    case 'AppearanceShow':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='appearance show'
          appearance='appearance-show'
          {...rest}
        />
      );
    case 'ArrowDown':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='arrow down'
          appearance='arrow-down'
          {...rest}
        />
      );
    case 'ArrowLeft':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='arrow left'
          appearance='arrow-left'
          {...rest}
        />
      );
    case 'ArrowRight':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='arrow right'
          appearance='arrow-right'
          {...rest}
        />
      );
    case 'ArrowRightDown':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='arrow right down'
          appearance='arrow-right-down'
          {...rest}
        />
      );
    case 'ArrowUp':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='arrow up'
          appearance='arrow-up'
          {...rest}
        />
      );
    case 'Attachment':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='attachment'
          appearance='attachment'
          {...rest}
        />
      );
    case 'BarGraph':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='bar graph'
          appearance='bar-graph'
          {...rest}
        />
      );
    case 'Beacon':
      return <StyledBeacon {...rest} />;
    case 'Book':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='book'
          appearance='book'
          {...rest}
        />
      );
    case 'BookOpen':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='book open'
          appearance='book-open'
          {...rest}
        />
      );
    case 'Bookmark':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='bookmark'
          appearance='bookmark'
          {...rest}
        />
      );
    case 'Briefcase':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='briefcase'
          appearance='briefcase'
          {...rest}
        />
      );
    case 'Build':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='build'
          appearance='build'
          {...rest}
        />
      );
    case 'Buildings':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='buildings'
          appearance='buildings'
          {...rest}
        />
      );
    case 'Calendar':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='calendar'
          appearance='calendar'
          {...rest}
        />
      );
    case 'Cancel':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='cancel'
          appearance='cancel'
          {...rest}
        />
      );
    case 'Categories':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='categories'
          appearance='categories'
          {...rest}
        />
      );
    case 'Changelog':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='changelog'
          appearance='changelog'
          {...rest}
        />
      );
    case 'Checkmark':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='checkmark'
          appearance='checkmark'
          {...rest}
        />
      );
    case 'CheckmarkGreenSquareCash':
      return <StyledCheckmarkGreenSquareCash {...rest} />;
    case 'ChevronDown':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron down'
          appearance='chevron-down'
          {...rest}
        />
      );
    case 'ChevronDownS1':
      return <StyledChevronDownS1 {...rest} />;
    case 'ChevronDownS2':
      return <StyledChevronDownS2 {...rest} />;
    case 'ChevronLeft':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron left'
          appearance='chevron-left'
          {...rest}
        />
      );
    case 'ChevronLeftDouble':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron left double'
          appearance='chevron-left-double'
          {...rest}
        />
      );
    case 'ChevronLeftDoubleS1':
      return <StyledChevronLeftDoubleS1 {...rest} />;
    case 'ChevronLeftS1':
      return <StyledChevronLeftS1 {...rest} />;
    case 'ChevronLeftS2':
      return <StyledChevronLeftS2 {...rest} />;
    case 'ChevronRight':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron right'
          appearance='chevron-right'
          {...rest}
        />
      );
    case 'ChevronRightDouble':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron right double'
          appearance='chevron-right-double'
          {...rest}
        />
      );
    case 'ChevronRightDoubleS1':
      return <StyledChevronRightDoubleS1 {...rest} />;
    case 'ChevronRightDoubleS2':
      return <StyledChevronRightDoubleS2 {...rest} />;
    case 'ChevronRightS1':
      return <StyledChevronRightS1 {...rest} />;
    case 'ChevronRightS2':
      return <StyledChevronRightS2 {...rest} />;
    case 'ChevronS1Down':
      return <StyledChevronS1Down {...rest} />;
    case 'ChevronS1Left':
      return <StyledChevronS1Left {...rest} />;
    case 'ChevronS1Right':
      return <StyledChevronS1Right {...rest} />;
    case 'ChevronS1Up':
      return <StyledChevronS1Up {...rest} />;
    case 'ChevronS2Down':
      return <StyledChevronS2Down {...rest} />;
    case 'ChevronS2Left':
      return <StyledChevronS2Left {...rest} />;
    case 'ChevronS2Right':
      return <StyledChevronS2Right {...rest} />;
    case 'ChevronS2Up':
      return <StyledChevronS2Up {...rest} />;
    case 'ChevronUp':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='chevron up'
          appearance='chevron-up'
          {...rest}
        />
      );
    case 'ChevronUpS1':
      return <StyledChevronUpS1 {...rest} />;
    case 'ChevronUpS2':
      return <StyledChevronUpS2 {...rest} />;
    case 'ClearedRec':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='cleared rec'
          appearance='cleared-rec'
          {...rest}
        />
      );
    case 'Clipboard':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='clipboard'
          appearance='clipboard'
          {...rest}
        />
      );
    case 'Clock':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='clock'
          appearance='clock'
          {...rest}
        />
      );
    case 'Close':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='close'
          appearance='close'
          {...rest}
        />
      );
    case 'CloseS1':
      return <StyledCloseS1 {...rest} />;
    case 'CloseS2':
      return <StyledCloseS2 {...rest} />;
    case 'Comment':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='comment'
          appearance='comment'
          {...rest}
        />
      );
    case 'Configuration':
      return <StyledConfiguration {...rest} />;
    case 'Configure':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='configure'
          appearance='configure'
          {...rest}
        />
      );
    case 'Copy':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='copy'
          appearance='copy'
          {...rest}
        />
      );
    case 'Database':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='database'
          appearance='database'
          {...rest}
        />
      );
    case 'Docs':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='docs'
          appearance='docs'
          {...rest}
        />
      );
    case 'Document':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='document'
          appearance='document'
          {...rest}
        />
      );
    case 'Download':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='download'
          appearance='download'
          {...rest}
        />
      );
    case 'Edit':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='edit'
          appearance='edit'
          {...rest}
        />
      );
    case 'External':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='external'
          appearance='external'
          {...rest}
        />
      );
    case 'Folder':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='folder'
          appearance='folder'
          {...rest}
        />
      );
    case 'Gear':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='gear'
          appearance='gear'
          {...rest}
        />
      );
    case 'Globe':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='globe'
          appearance='globe'
          {...rest}
        />
      );
    case 'GraduationCap':
      return <StyledGraduationCap {...rest} />;
    case 'Guide':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='guide'
          appearance='guide'
          {...rest}
        />
      );
    case 'Hand':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='hand'
          appearance='hand'
          {...rest}
        />
      );
    case 'Hashtag':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='hashtag'
          appearance='hashtag'
          {...rest}
        />
      );
    case 'Help':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='help'
          appearance='help'
          {...rest}
        />
      );
    case 'Home':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='home'
          appearance='home'
          {...rest}
        />
      );
    case 'Identity':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='identity'
          appearance='identity'
          {...rest}
        />
      );
    case 'Inbox':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='inbox'
          appearance='inbox'
          {...rest}
        />
      );
    case 'Income':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='income'
          appearance='income'
          {...rest}
        />
      );
    case 'Incomplete':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='incomplete'
          appearance='incomplete'
          {...rest}
        />
      );
    case 'Information':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='information'
          appearance='information'
          {...rest}
        />
      );
    case 'Institution':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='institution'
          appearance='institution'
          {...rest}
        />
      );
    case 'Integrations':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='integrations'
          appearance='integrations'
          {...rest}
        />
      );
    case 'ItemManagement':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='item management'
          appearance='item-management'
          {...rest}
        />
      );
    case 'Items':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='items'
          appearance='items'
          {...rest}
        />
      );
    case 'Key':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='key'
          appearance='key'
          {...rest}
        />
      );
    case 'Learn':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='learn'
          appearance='learn'
          {...rest}
        />
      );
    case 'Libraries':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='libraries'
          appearance='libraries'
          {...rest}
        />
      );
    case 'Lightbulb':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='lightbulb'
          appearance='lightbulb'
          {...rest}
        />
      );
    case 'Lightning':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='lightning'
          appearance='lightning'
          {...rest}
        />
      );
    case 'LineGraph':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='line graph'
          appearance='line-graph'
          {...rest}
        />
      );
    case 'Link':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='link'
          appearance='link'
          {...rest}
        />
      );
    case 'List':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='list'
          appearance='list'
          {...rest}
        />
      );
    case 'Lock':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='lock'
          appearance='lock'
          {...rest}
        />
      );
    case 'Login':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='login'
          appearance='login'
          {...rest}
        />
      );
    case 'Logout':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='logout'
          appearance='logout'
          {...rest}
        />
      );
    case 'Mail':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='mail'
          appearance='mail'
          {...rest}
        />
      );
    case 'Menu':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='menu'
          appearance='menu'
          {...rest}
        />
      );
    case 'MenuS1':
      return <StyledMenuS1 {...rest} />;
    case 'MenuS2':
      return <StyledMenuS2 {...rest} />;
    case 'Message':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='message'
          appearance='message'
          {...rest}
        />
      );
    case 'Mobile':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='mobile'
          appearance='mobile'
          {...rest}
        />
      );
    case 'NewWindow':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='new window'
          appearance='new-window'
          {...rest}
        />
      );
    case 'Notebook':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='notebook'
          appearance='notebook'
          {...rest}
        />
      );
    case 'Overflow':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='overflow'
          appearance='overflow'
          {...rest}
        />
      );
    case 'Override':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='override'
          appearance='override'
          {...rest}
        />
      );
    case 'Overview':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='overview'
          appearance='overview'
          {...rest}
        />
      );
    case 'Pause':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='pause'
          appearance='pause'
          {...rest}
        />
      );
    case 'Phone':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='phone'
          appearance='phone'
          {...rest}
        />
      );
    case 'PieChart':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='pie chart'
          appearance='pie-chart'
          {...rest}
        />
      );
    case 'Pin':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='pin'
          appearance='pin'
          {...rest}
        />
      );
    case 'PlaidLogo':
      return <StyledPlaidLogo {...rest} />;
    case 'Platform':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='platform'
          appearance='platform'
          {...rest}
        />
      );
    case 'ProductAssets':
      return <StyledProductAssets {...rest} />;
    case 'ProductAuth':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='product auth'
          appearance='product-auth'
          {...rest}
        />
      );
    case 'ProductBalance':
      return <StyledProductBalance {...rest} />;
    case 'ProductIdentity':
      return <StyledProductIdentity {...rest} />;
    case 'ProductIdv':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='product idv'
          appearance='product-idv'
          {...rest}
        />
      );
    case 'ProductIncome':
      return <StyledProductIncome {...rest} />;
    case 'ProductInvestments':
      return <StyledProductInvestments {...rest} />;
    case 'ProductLiabilities':
      return <StyledProductLiabilities {...rest} />;
    case 'ProductMonitor':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='product monitor'
          appearance='product-monitor'
          {...rest}
        />
      );
    case 'ProductTransactions':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='product transactions'
          appearance='product-transactions'
          {...rest}
        />
      );
    case 'Products':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='products'
          appearance='products'
          {...rest}
        />
      );
    case 'Progress':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='progress'
          appearance='progress'
          {...rest}
        />
      );
    case 'Question':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='question'
          appearance='question'
          {...rest}
        />
      );
    case 'Refresh':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='refresh'
          appearance='refresh'
          {...rest}
        />
      );
    case 'RejectedRec':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='rejected rec'
          appearance='rejected-rec'
          {...rest}
        />
      );
    case 'Remove':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='remove'
          appearance='remove'
          {...rest}
        />
      );
    case 'Reset':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='reset'
          appearance='reset'
          {...rest}
        />
      );
    case 'Retry':
      return <StyledRetry {...rest} />;
    case 'Rocket':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='rocket'
          appearance='rocket'
          {...rest}
        />
      );
    case 'Rotate':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='rotate'
          appearance='rotate'
          {...rest}
        />
      );
    case 'Safe':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='safe'
          appearance='safe'
          {...rest}
        />
      );
    case 'Sandbox':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='sandbox'
          appearance='sandbox'
          {...rest}
        />
      );
    case 'Search':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='search'
          appearance='search'
          {...rest}
        />
      );
    case 'Shield':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='shield'
          appearance='shield'
          {...rest}
        />
      );
    case 'ShieldCaution':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='shield caution'
          appearance='shield-caution'
          {...rest}
        />
      );
    case 'SidebarHide':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='sidebar hide'
          appearance='sidebar-hide'
          {...rest}
        />
      );
    case 'SidebarShow':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='sidebar show'
          appearance='sidebar-show'
          {...rest}
        />
      );
    case 'StepComplete':
      return <StyledStepComplete {...rest} />;
    case 'StepInProgress':
      return <StyledStepInProgress {...rest} />;
    case 'StepIncomplete':
      return <StyledStepIncomplete {...rest} />;
    case 'Submit':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='submit'
          appearance='submit'
          {...rest}
        />
      );
    case 'Subtract':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='subtract'
          appearance='subtract'
          {...rest}
        />
      );
    case 'Switch':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='switch'
          appearance='switch'
          {...rest}
        />
      );
    case 'Tag':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='tag'
          appearance='tag'
          {...rest}
        />
      );
    case 'Terminal':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='terminal'
          appearance='terminal'
          {...rest}
        />
      );
    case 'Transfer':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='transfer'
          appearance='transfer'
          {...rest}
        />
      );
    case 'Trash':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='trash'
          appearance='trash'
          {...rest}
        />
      );
    case 'Tweet':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='tweet'
          appearance='tweet'
          {...rest}
        />
      );
    case 'User':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='user'
          appearance='user'
          {...rest}
        />
      );
    case 'Users':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='users'
          appearance='users'
          {...rest}
        />
      );
    case 'Video':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='video'
          appearance='video'
          {...rest}
        />
      );
    case 'Wallet':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='wallet'
          appearance='wallet'
          {...rest}
        />
      );
    case 'Webhooks':
      return (
        <StyledExperimentalIcon
          accessibilityLabel='webhooks'
          appearance='webhooks'
          {...rest}
        />
      );

    default:
      return null;
  }
};

export default Icon;
