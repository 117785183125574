import React from 'react';
import cx from 'classnames';

// components
import { Link } from 'src/components/Inputs';

// contexts
import { usePageLocale } from 'src/contexts';

interface MobileNavbarSubmenuItemProps {
  url: string;
  name: string;
  locale?: string;
  trackingId?: string;
}

const MobileNavbarSubmenuItem: React.FC<MobileNavbarSubmenuItemProps> = ({
  url,
  name,
  locale,
  trackingId = '',
}) => {
  const pageLocale = usePageLocale();
  return (
    <li className={cx('MobileNavbar__submenu-item')}>
      <Link href={url} locale={locale || pageLocale} trackingId={trackingId}>
        <a className={cx('MobileNavbar__submenu-item-anchor')}>{name}</a>
      </Link>
    </li>
  );
};

export default MobileNavbarSubmenuItem;
