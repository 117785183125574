import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { Button } from 'src/components/Inputs';
import ObjectPropertyCard from './ObjectPropertyCard';
import MobileObjectPropertyCard from './MobileObjectPropertyCard';

// helpers
import { PropertiesShape } from './StaticData.types';
import {
  withAnimatedResponse,
  WithAnimatedResponseShape,
  InteractiveElements,
} from 'src/components/Enhancers';

const CodeSectionPropTypes = {
  'code-classes': PropTypes.string,
  'code-title': PropTypes.string.isRequired,
  'code-lang': PropTypes.string.isRequired,
  'code-body': PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(PropertiesShape).isRequired,
  parentContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  withAnimatedResponse: WithAnimatedResponseShape.isRequired,
};

const CodeSection = ({
  'code-classes': classes,
  'code-title': title,
  'code-lang': lang,
  'code-body': body,
  properties,
  parentContainer,
  withAnimatedResponse,
}) => {
  useEffect(() => {
    withAnimatedResponse.scrollToHighlightedCode(parentContainer.current);
  });

  return (
    <div className='cell medium-7'>
      <div
        data-id='code-container'
        className={cx('code-container static', classes)}
      >
        <header className='code-container-header'>
          <p
            data-id='code-container-header-title'
            className='code-container-header-title'
          >
            {title}
          </p>
        </header>
        <div className='code-container-body code-product-example'>
          <div className='code-example is-active'>
            <pre className='line-numbers' data-id='code-pre'>
              <code className={`language-${lang}`} data-id='code-block'>
                {body}
              </code>
              <InteractiveElements
                parentContainer={parentContainer}
                properties={properties}
                handleEvent={withAnimatedResponse.handleEvent}
              />
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

const TextSectionPropTypes = {
  'title-text': PropTypes.string.isRequired,
  'description-text': PropTypes.string.isRequired,
  'bottom-cta-url': PropTypes.string.isRequired,
  'bottom-cta-text': PropTypes.string.isRequired,
  'secondary-cta-url': PropTypes.string,
  'secondary-cta-text': PropTypes.string,
  hideCtas: PropTypes.bool,
  properties: PropTypes.arrayOf(PropertiesShape).isRequired,
  parentContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
};

const TextSection = ({
  properties,
  hideCtas = false,
  'title-text': title,
  'description-text': description,
  'bottom-cta-url': ctaUrl,
  'bottom-cta-text': ctaText,
  'secondary-cta-url': secondaryCtaUrl,
  'secondary-cta-text': secondaryCtaText,
  parentContainer,
  withAnimatedResponse,
}) => {
  const objectProperties = useRef(null);
  return (
    <div data-id='overview-text-container' className='cell medium-4'>
      <h4 data-id='overview-text-title'>{title}</h4>
      <p
        data-id='overview-text-description'
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <div
        data-id='object-properties'
        className='property-list'
        ref={objectProperties}
      >
        {properties?.map((item, i) => (
          <ObjectPropertyCard
            key={`opc-${i}`}
            index={i}
            property={item}
            handleEvent={withAnimatedResponse.handleEvent}
            parentContainer={parentContainer}
          />
        ))}
      </div>
      {!hideCtas && (
        <React.Fragment>
          {ctaUrl && (
            <Button
              href={ctaUrl}
              data-id='button-cta'
              className='button-cta button-cta--hide'
              fullWidth
            >
              {ctaText}
            </Button>
          )}
          {secondaryCtaUrl && (
            <Button
              href={secondaryCtaUrl}
              data-id='button-cta'
              className='button-cta button-cta--hide'
              fullWidth
              secondary
            >
              {secondaryCtaText}
            </Button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

TextSection.propTypes = TextSectionPropTypes;

const MobileStaticData = ({
  'code-classes': classes,
  'code-title': codeTitle,
  'code-lang': lang,
  'code-body': codeBody,
  properties,
  'title-text': titleText,
  'description-text': description,
  withAnimatedResponse,
}) => {
  const mobileObjectProperties = useRef(null);
  const parentContainer = useRef(null);
  useEffect(() => {
    withAnimatedResponse.scrollToHighlightedCode(parentContainer.current);
  });

  return (
    <div
      className='grid-x align-justify hide-for-large'
      data-id='parent-element'
      ref={parentContainer}
    >
      <div data-id='overview-text-container' className='cell small-12'>
        <h4 data-id='overview-text-title'>{titleText}</h4>
        <p
          data-id='overview-text-description'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div
          data-id='code-container'
          className={cx(
            'code-container code-container--mobile-no-margin-bottom',
            classes,
          )}
        >
          <header className='code-container-header'>
            <p
              data-id='mobile-code-container-header-title'
              className='code-container-header-title'
            >
              {codeTitle}
            </p>
          </header>
          <div className='code-container-body code-product-example'>
            <div className='code-example is-active'>
              <pre className='line-numbers' data-id='mobile-code-pre'>
                <code
                  className={`language-${lang}`}
                  data-id='mobile-code-block'
                >
                  {codeBody}
                </code>
                <InteractiveElements
                  parentContainer={parentContainer}
                  properties={properties}
                  handleEvent={withAnimatedResponse.handleEvent}
                  isMobile
                />
              </pre>
            </div>
          </div>
        </div>
        <div
          className='mobile-object-property-description-container'
          data-id='mobile-object-property-description-container'
          ref={mobileObjectProperties}
        >
          {properties?.map((item, i) => (
            <MobileObjectPropertyCard
              key={`mopc-${i}`}
              index={i}
              property={item}
              handleEvent={withAnimatedResponse.handleEvent}
              parentContainer={parentContainer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileStaticData.propTypes = {
  ...CodeSectionPropTypes,
  ...TextSectionPropTypes,
};

const StaticDataPropTypes = {
  flipped: PropTypes.bool,
  'bg-first-image': PropTypes.bool,
  pageClass: PropTypes.string,
  testId: PropTypes.string,
  style: PropTypes.string,
  withAnimatedResponse: WithAnimatedResponseShape.isRequired,
};

const StaticData = ({
  flipped,
  'bg-first-image': bgFirstImage,
  pageClass = '',
  testId = '',
  style = '',
  ...restProps
}) => {
  const dataAttributes = { 'data-flipped': flipped };
  const parentContainer = useRef(null);
  return (
    <div className={pageClass} data-testid={testId}>
      <div
        data-id='static-data'
        {...dataAttributes}
        className={cx('product-data', 'static-data', {
          'static-data-first-bg-image': bgFirstImage,
          'static-data-second-bg-image': !bgFirstImage,
        })}
      >
        <div className='grid-container interactive-data-container'>
          <div
            className='grid-x align-justify show-for-large'
            data-id='parent-element'
            ref={parentContainer}
          >
            {flipped ? (
              <>
                <CodeSection {...restProps} parentContainer={parentContainer} />
                <div className='cell medium-1'></div>
                <TextSection {...restProps} parentContainer={parentContainer} />
              </>
            ) : (
              <>
                <TextSection {...restProps} parentContainer={parentContainer} />
                <div className='cell medium-1'></div>
                <CodeSection {...restProps} parentContainer={parentContainer} />
              </>
            )}
          </div>
          <MobileStaticData {...restProps} />
        </div>
      </div>
      {style && <style jsx>{style}</style>}
    </div>
  );
};
StaticData.propTypes = StaticDataPropTypes;

export default withAnimatedResponse(StaticData);
