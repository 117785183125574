import React from 'react';

const MortgagesIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={13}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FCE76B' d='M3.5 5.388v7.031h16.892V5.388L11.946.589z' />
        <path
          stroke='#121313'
          strokeLinejoin='round'
          d='M20.392 5.388L11.946.589 3.5 5.388v7.031h16.892z'
        />
        <path
          fill='#FCE76B'
          d='M7.129 7.311h-.187c-.817 0-1.478.662-1.478 1.478v3.63h3.143v-3.63c0-.816-.662-1.478-1.478-1.478'
        />
        <path
          stroke='#121313'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M7.129 7.311h-.187c-.817 0-1.478.662-1.478 1.478v3.63h3.143v-3.63c0-.816-.662-1.478-1.478-1.478z'
        />
        <path fill='#FCE76B' d='M10.964 10.061h4.321V6.919h-4.321z' />
        <path
          stroke='#121313'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M10.964 10.061h4.321V6.919h-4.321z'
        />
        <path
          fill='#5BEFBD'
          d='M13.321 12.419h9.429v-.794a.816.816 0 00-.26-.588c-.794-.757-1.658-.98-2.393-.825a1.47 1.47 0 01-1.263-.343c-1.263-1.082-3.295-1.304-5.158.545a1.205 1.205 0 00-.355.852v1.153z'
        />
        <path
          stroke='#121313'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M13.321 12.419h9.429v-.794a.816.816 0 00-.26-.588c-.794-.757-1.658-.98-2.393-.825a1.47 1.47 0 01-1.263-.343c-1.263-1.082-3.295-1.304-5.158.545a1.205 1.205 0 00-.355.852v1.153z'
        />
        <path
          fill='#5BEFBD'
          d='M1.196 12.419a2.854 2.854 0 012.835-2.535 2.854 2.854 0 012.835 2.535h-5.67z'
        />
        <path
          stroke='#121313'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M1.196 12.419a2.854 2.854 0 012.835-2.535 2.854 2.854 0 012.835 2.535h-5.67z'
        />
      </g>
    </svg>
  );
};

export default MortgagesIcon;
