export const metaData = {
  'meta-title': 'Enabling all companies to build fintech solutions',
  'meta-description':
    'Plaid helps companies build fintech solutions by making it easy, safe and reliable for people to connect their financial data to apps and services.',
};

export const pageHeaderData = {
  classes: '',
  'content-width-classes': 'medium-12 large-7',
  mediaWidthClasses: 'medium-12 large-5',
  header: '<span>One integration,</span><br> all of open banking.',
  subheader:
    'Plaid is a world-leading data network and payments platform to help you cut costs, onboard and convert more customers.',
  'header-class': 'page-header-homepage-title',
  'primary-cta': true,
  'primary-cta-text': 'Get Started',
  'primary-cta-link': '/contact/',
  'secondary-cta': false,
  'tertiary-cta': 'Start building with Plaid’s APIs today',
  image: true,
  'image-width': 1140,
  'image-height': 530,
  'image-url': '/assets/img/hero/uk/uk-hero-static.png',
  'image-classes': '',
  'news-banner': true,
  'news-banner-url': '/the-fintech-effect-report-2023/',
  'news-banner-label-class': 'label--green400',
  'news-banner-label-text': 'NEW',
  'news-banner-description': 'Our new Fintech Effect 2023 is here',
  'news-banner-modifier': 'news-banner--black',
};

export const socialProofData = {
  text:
    'Plaid is a single API but doesn’t believe in one-size-fits-all. We bring together the technology of PIS (Payment Initiation Services) and AIS (Account Information Services) to support you creating user journeys for your business needs.',
  logos: [
    {
      img: '/assets/img/logos/logo-kraken-transparent.png',
      alt: 'Kraken logo',
    },
    {
      img: '/assets/img/logos/logo-wayflyer-transparent.png',
      alt: 'Wayflyer logo',
    },
    {
      img: '/assets/img/logos/logo-paysend-transparent.png',
      alt: 'Paysend logo',
    },
    {
      img: '/assets/img/logos/logo-cleo-transparent.png',
      alt: 'Cleo logo',
    },
    {
      img: '/assets/img/logos/logo-atom-transparent.png',
      alt: 'Atom logo',
    },
    {
      img: '/assets/img/logos/logo-wise-transparent.png',
      alt: 'Wise logo',
    },
  ],
};

export const valuesData = [
  {
    classes: 'homepage-value-video video--with-shadow',
    type: 'video',
    label: 'Why Plaid?',
    title: 'Seamless user experience',
    description:
      'Our simple front-end module streamlines your onboarding experience to bring more users through the funnel.',
    videos: [
      {
        'video-width': '100%',
        'video-height': 445,
        'video-fallback-image': '/assets/video/PIS-Consent-Pane.png',
        'video-frame-image': '/assets/video/PIS-Consent-Pane.png',
        'video-formats': [
          {
            url: '/assets/video/link-pis-animation-frame-ios.webm',
            type: 'webm',
          },
        ],
      },
    ],
    'has-text-cta': true,
    'text-cta-url': '/products/',
    'text-cta-text': 'Our Products',
    'img-brushtroke':
      '/assets/img/accents/art-scene-link-accent-brushstroke@2x.png',
  },
  {
    type: 'single-image',
    maxWidthImage: true,
    reverse: true,
    title: 'Expand your coverage',
    description:
      'Plaid has the most reliable open banking connectivity in Europe and North America, with the highest uptime and deepest coverage.',
    images: [
      {
        'img-src': '/assets/img/uk-landing/global-coverage.png',
        'alt-text': 'Global network',
        'has-shadow': true,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': '/global/',
    'text-cta-text': 'Learn More',
  },
  {
    type: 'single-image',
    maxWidthImage: true,
    title: 'It’s a partnership',
    description:
      'We’re here to help - from friendly API docs, access to our team of designers, engineers, and product managers, and integration times of weeks — not months.',
    images: [
      {
        'img-src': '/assets/img/uk-landing/partnership-scene.png',
        'alt-text': 'Partnership scene',
        'has-shadow': true,
      },
    ],
    'has-text-cta': true,
    'text-cta-url': '/docs/',
    'text-cta-text': 'Read our API documentation',
  },
];

export const featuresData = {
  'feature-section-title': [
    {
      label: 'PRODUCTS',
      title: 'Build the future of <br/>financial services',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/use-cases/products-spot-144-payment-initiation@2x.png',
      title: 'Payment initiation',
      'header-class': 'h4',
      content: 'Initiate payments within your app',
      'link-text': 'Learn more',
      'link-location': '/uk/products/payment-initiation/',
    },
    {
      img: '/assets/img/use-cases/products-spot-transactions-global@2x.png',
      title: 'Transactions',
      'header-class': 'h4',
      content: 'Up to 24 months of categorised data',
      'link-text': 'Learn more',
      'link-location': '/uk/products/transactions/',
    },
    {
      img: '/assets/img/use-cases/products-spot-balance-global@2x.png',
      title: 'Balance',
      'header-class': 'h4',
      content: 'Verify real-time account balances',
      'link-text': 'Learn more',
      'link-location': '/uk/products/balance/',
    },
    {
      img: '/assets/img/use-cases/products-spot-assets-global@2x.png',
      title: 'Assets',
      'header-class': 'h4',
      content: 'Point in time snapshots of users’ finances',
      'link-text': 'Learn more',
      'link-location': '/uk/products/assets/',
    },
    {
      img: '/assets/img/use-cases/products-spot-144-identity@2x.png',
      title: 'Identity',
      'header-class': 'h4',
      content: 'Verify users’ identities and reduce fraud',
      'link-text': 'Learn more',
      'link-location': '/uk/products/identity/',
    },
    {
      img: '/assets/img/use-cases/products-spot-144-auth@2x.png',
      title: 'Auth',
      'header-class': 'h4',
      content: 'Enable a seamless setup for direct debits and payouts',
      'link-text': 'Learn more',
      'link-location': '/uk/products/auth/',
    },
  ],
};

export const relatedArticlesData = {
  hasSectionHeader: true,
  sectionHeaderData: {
    label: 'INSIGHTS FROM OUR BLOG',
  },
  layout: 'two-column',
  items: [
    {
      title:
        'UK consumers double down on fintech use through a turbulent economy',
      description:
        'The Fintech Effect is an annual report published by Plaid, which gathers insights on fintech’s consumer impact by surveying over 4,000 respondents in the UK and the US. Our UK spotlight this year highlights the value fintech is bringing to UK consumers after the pandemic, setting the stage for the shift to open finance.',
      img:
        'https://images.ctfassets.net/zucqsg1ttqqy/21OVgINOh4U0M9cvfiQOJy/b38a539afe32b28ef0e3d62a12cfdca1/Fintech_Effect_UK.png',
      'img-alt': 'Fintech Effect report for UK consumers',
      cta: 'Read more',
      url: '/blog/fintech-effect-uk-consumers',
    },
    {
      title: 'Instant payments - A gateway to digital finance in Europe',
      description:
        'Instant payments are electronic retail payments that are settled virtually in real-time, all the time—including weekends and holidays—via the Single Euro Payments Area Instant Credit Transfer Scheme (SEPA SCT Inst.).',
      img:
        'https://images.ctfassets.net/zucqsg1ttqqy/0hFjm3qMYKIWvpmlpKCqI/ff9025c125c4662eec61129e80be5717/blog-header-payroll-data-access_2x.png',
      'img-alt': 'Instant payments gateway to digital finance',
      cta: 'Read more',
      url: '/blog/instant-payments-europe',
    },
  ],
};

export const accordionData = [
  {
    title: 'What is open banking?',
    content:
      'Open banking refers to the safe and improved technology for transferring money or accessing a consumer’s financial information.',
  },
  {
    title: 'How do open banking APIs work?',
    content:
      'Open banking access is provided through APIs (application programming interfaces) such as Plaid. An open banking API allows financial innovators open and controlled access to a consumer’s data from banks (with consent every 90 days).',
  },
  {
    title: 'What is the difference between open banking and PSD2?',
    content:
      'PSD2 stands for the Revised Payment Service Directive. PSD2 is a piece of legislation authorised in the EU that gives open banking both authorisation and enforced security, specifically through multi-factor authentication. PSD2 adds to open banking by enhancing its security, providing assurance to both consumers and a high level of standards for third parties in terms of security protocols.',
  },
  {
    title: 'What is the difference between ASPSP,  AISP, and PISP?',
    content:
      'ASPSP stands for Account Servicing Payment Service Provider. ASPSPs are financial institutions such as banks and building societies.<br><br>AISP stands for Account Information Service Provider. With the consumer’s consent, AISPs can analyse and interpret their bank account data, but do not have the authorisation to process payments.<br><br>PISP stands for Payment Initiation Service Provider. A PISP has authorisation to process payments on the consumer’s behalf, again with their explicit consent.',
  },
  {
    title: 'What is VRP?',
    content:
      'Variable Recurring Payments (VRPs) allow PISPs to authorise payment transfers that may differ in amount each month. VRPs, like other PISP functions, directly debit a consumer’s bank account with the consumer’s consent. The primary difference with VRPs is the change of that amount each month.',
  },
];
