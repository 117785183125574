import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

export const footerData = {
  copyrightName: 'Plaid Inc.',
  eeaDisclaimer: `Plaid Financial Ltd. is an authorised payment institution regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 (Firm Registration Number: 804718) for the provision of payment services. Plaid, B.V. is an authorised payment institution regulated by the Dutch Central Bank under the Dutch Financial Supervision Act for the provision of payment services (account information services). Plaid, B.V. is included in the public register of the Dutch Central Bank (License number: R179714) and registered at the Dutch Chamber of Commerce (CoC number: 74716603).`,
  hideCTAWhen: [
    '404',
    '500',
    'account-funding',
    'ach-start',
    'ACH-start',
    'assets',
    'auth',
    'balance',
    'banking',
    'business-brokerage-services',
    'business-finances',
    'careers',
    'consumer-payments',
    'contact-thanks',
    'contact',
    'customer-stories',
    'global',
    'how-it-works-for-consumers',
    'how-we-handle-data',
    'identity',
    'income',
    'integration',
    'investments',
    'job',
    'lending',
    'liabilities',
    'openings',
    'partners',
    'payment-initiation',
    'personal-finances',
    'plaid-exchange',
    'signup',
    'transactions',
    'use-cases',
    'wealth',
    'what-is-plaid',
    'why-is-plaid-involved',
    'trouble-connecting',
    '/', // The "/" represents homepage
  ],
  sections: {
    products: {
      header: 'Products',
      items: [
        {
          name: 'Auth',
          url: '/products/auth/',
        },
        {
          name: 'Identity',
          url: '/products/identity/',
        },
        {
          name: 'Balance',
          url: '/products/balance/',
        },
        {
          name: 'Signal',
          url: '/products/signal/',
        },
        {
          name: 'Transfer',
          url: '/products/transfer/',
        },
        {
          name: 'Identity Verification',
          url: '/products/identity-verification/',
        },
        {
          name: 'Beacon',
          url: '/products/beacon/',
        },
        {
          name: 'Monitor',
          url: '/products/monitor/',
        },
        {
          name: 'Transactions',
          url: '/products/transactions/',
        },
        {
          name: 'Investments',
          url: '/products/investments/',
        },
        {
          name: 'Liabilities',
          url: '/products/liabilities/',
        },
        {
          name: 'Enrich',
          url: '/products/enrich/',
        },
        {
          name: 'Assets',
          url: '/products/assets/',
        },
        {
          name: 'Income',
          url: '/products/income/',
        },
        {
          name: 'Plaid Link',
          url: '/plaid-link/',
        },
        {
          name: 'Consumer Report',
          url: '/check/consumer-report/',
        },
        {
          name: 'Layer',
          url: '/layer/',
        },
      ],
    },
    useCases: {
      header: 'Use Cases',
      items: [
        {
          name: 'Personal finances',
          url: '/use-cases/personal-finances/',
        },
        {
          name: 'Lending',
          url: '/use-cases/lending/',
        },
        {
          name: 'Wealth',
          url: '/use-cases/wealth/',
        },
        {
          name: 'Pay by bank',
          url: '/pay-by-bank/',
        },

        {
          name: 'Digital banking',
          url: '/use-cases/digital-banking/',
        },

        {
          name: 'Business finances',
          url: '/use-cases/business-finances/',
        },
        {
          name: 'Crypto',
          url: '/use-cases/crypto/',
        },
      ],
    },
    docs: {
      header: 'Developers',
      items: [
        {
          name: 'Quickstart',
          url: '/docs/quickstart/',
        },
        {
          name: 'API documentation',
          url: '/docs/',
        },
        {
          name: 'Libraries',
          url: '/docs/api/libraries/',
        },
        {
          name: 'GitHub',
          url: 'https://github.com/plaid/',
        },
        {
          name: 'Link Demo',
          url: '/demo/',
        },
      ],
    },
    aboutUs: {
      header: 'About us',
      items: [
        {
          name: 'Company',
          url: '/company/',
        },
        {
          name: 'Careers',
          url: '/careers/',
        },
        {
          name: 'Contact',
          url: '/contact/',
        },
        {
          name: 'Partners',
          url: '/partners/',
        },
        {
          name: 'Press',
          url: '/press/',
        },
        {
          name: 'Safety',
          url: '/safety/',
        },
        {
          name: 'How we handle data',
          url: '/how-we-handle-data/',
        },
        {
          name: 'Legal',
          url: '/legal/#privacy-statement',
        },
        {
          name: 'Why Plaid',
          url: '/why-plaid/',
        },
      ],
    },
    resources: {
      header: 'Resources',
      items: [
        {
          name: 'Pricing',
          url: '/pricing/',
          trackingId: NAVIGATION_EVENTS.FOOTER.USER_CLICKED_FOOTER_NAV_PRICING,
        },
        {
          name: 'Global coverage',
          url: '/global/',
        },
        {
          name: 'Plaid Blog',
          url: '/blog/',
        },
        {
          name: 'Industry resources',
          url: '/resources/',
        },
        {
          name: 'Annual conference',
          url: '/events/effects/',
        },
        {
          name: 'Customer stories',
          url: '/customer-stories/',
        },
      ],
    },
    forConsumers: {
      header: 'For consumers',
      items: [
        {
          name: 'How it works',
          url: '/how-it-works-for-consumers/',
        },
        {
          name: 'Discover apps',
          url: '/discover-apps/',
        },
        {
          name: 'Trouble connecting?',
          url: '/trouble-connecting/',
        },
        {
          name: 'Plaid Portal',
          url: '//my.plaid.com/',
        },
        {
          name: 'Delete my data',
          url: '/legal/data-protection-request-form/',
        },
        {
          name: 'End User Privacy Policy',
          url: '/legal/#end-user-privacy-policy',
        },
        {
          name: 'FAQs',
          url: '//my.plaid.com/help/',
        },
        {
          name: 'Plaid Consumer Reporting Agency, Inc.',
          url: '/check/consumer-service-center',
        },
      ],
    },
    forInstitutions: {
      header: 'For financial institutions',
      items: [
        {
          name: 'Open Finance Solution',
          url: '/solutions/open-finance/',
        },
        {
          name: 'Core Exchange',
          url: '/products/core-exchange/',
        },
        {
          name: 'Permissions Manager',
          url: '/products/permissions-manager/',
        },
        {
          name: 'App Directory',
          url: '/products/app-directory/',
        },
      ],
    },
  },
};
