import React, { createContext, FC } from 'react';

// helpers
import { useDeviceSize } from 'src/hooks';
import { computeDeviceSize } from 'src/lib/utils';

// create initial context values
export const DeviceContext = createContext(computeDeviceSize(0));

const DeviceProvider: FC = ({ children }) => {
  const state = useDeviceSize();

  return (
    <DeviceContext.Provider value={state}>{children}</DeviceContext.Provider>
  );
};

export default DeviceProvider;
