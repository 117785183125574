import React from 'react';
import PropTypes from 'prop-types';
import { MARKS } from '@contentful/rich-text-types';

const propTypes = {
  iClassName: PropTypes.string,
};

const Italic = ({ iClassName = '' }) => {
  return {
    [MARKS.ITALIC]: (text) => {
      // https://www.npmjs.com/package/@contentful/rich-text-react-renderer#note-on-adding-a-key-prop-in-custom-renderers
      return (
        <i key={`${text}-key`} className={iClassName}>
          {text}
        </i>
      );
    },
  };
};

Italic.propTypes = propTypes;
export default Italic;
