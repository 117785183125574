export const DISCONNECT_REQUEST_TYPE_VALUE = {
  en: 'Disconnect',
  es: 'Desconectar',
  fr: 'Déconnecter',
  nl: 'Ontkoppelen',
};

export const FORGET_REMEMBER_ME = {
  en: 'Forget RM',
  es: 'Olvidar RM',
  fr: 'Oublier RM',
  nl: 'Vergeten RM',
};

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Privacy Request Form: Access/Delete and Other Data Protection Rights',
      'meta-description': 'Exercise your data protection rights',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Privacy Request Form: Access/Delete and Other Data Protection Rights',
    introHeader: 'Introduction',
    intro: `<p>Please complete the form below to submit your privacy request. Please note that, in order to process your request, you may be required to provide additional information, if necessary, to verify your identity. The information that you provide below will be used solely for the purpose of fulfilling your request.</p>
      <p>To learn more about and/or exercise any other data protection rights that may be applicable to you as an End User, please refer to our <a href="https://plaid.com/legal/#end-user-privacy-policy">End User Privacy Policy</a> under the section entitled “Your Data Protection Rights” or email us at <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. If you are not an End User (you have not used Plaid’s products or services and/or connected an application through Plaid), please refer to our Privacy Statement for more information about your rights or email us at <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. If you are a Candidate or Employee of Plaid please refer to our Candidate Privacy Notice or Personnel Privacy Notice for information or email us at <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>.</p>
      <p><strong>You should not use this form if you are having trouble connecting to a particular app.</strong> For further assistance with your connection to an app, please contact the app directly. You can find general information about connecting financial accounts on our <a href="https://support-my.plaid.com/hc/en-us/categories/4405983222679">consumer help center</a>.</p>`,
    formHeader: 'Form',
    firstName: {
      label: 'First name (optional)',
    },
    lastName: {
      label: 'Last name (optional)',
    },
    email: {
      label: 'Email',
      error: 'invalid email address',
    },
    phone: {
      label: 'Phone',
    },
    location: {
      label: 'Location',
      locations: [
        {
          label: 'United States - California',
          value: 'United States - California',
        },
        {
          label: 'United States - Not in California',
          value: 'United States - Not in California',
        },
        {
          label: 'United Kingdom',
          value: 'United Kingdom',
        },
        {
          label: 'European Economic Area (EEA)',
          value: 'European Economic Area (EEA)',
        },
        {
          label: 'Canada',
          value: 'Canada',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    accountMask: {
      label: 'Last 4 digits of account number',
    },
    accountUsername: {
      label: 'Account username or login number (optional)',
    },
    accountNumber: {
      label: 'Account number (optional)',
    },
    applicationsConnected: {
      label: 'Apps or services connected via Plaid (optional)',
    },
    datesConnected: {
      label: 'Date(s) you connected via Plaid (optional)',
    },
    paymentInitiation: {
      label: "I've used Plaid to initiate payments from my account(s)",
    },
    requestType: {
      label: 'Select request type',
      options: [
        {
          label: 'Delete: erase ALL my stored data',
          value: 'Delete/Erase',
        },
        {
          label: 'Disconnect: erase ONLY specific connection(s)',
          value: DISCONNECT_REQUEST_TYPE_VALUE.en,
        },
        {
          label: 'Provide a copy of my stored data',
          value: 'Access',
        },
        {
          label: "Opt-out of Plaid's faster returning user experience",
          value: FORGET_REMEMBER_ME.en,
        },
        {
          label: 'Other privacy rights request',
          value: 'Other',
        },
      ],
    },
    requestTypeNote: {
      disconnectLabel:
        "Please provide information about the financial institution(s) that you'd like to disconnect from an app or service.",
      generalLabel:
        'Please provide information about your financial institution(s)',
    },
    financialInstitutions: {
      label: 'Search and select banks/financial institutions',
    },
    cannotFindFinancialInstitutions:
      'My financial institution(s) are not included in search results.',
    otherFIs: {
      label: 'Other financial institutions',
    },
    fiSearchLoading: 'Searching institutions...',
    fiSearchNoneFound: 'No institutions found',
    fiSearchInstruction:
      'Start typing to search for your banks/financial institution(s)',
    requestDetailsDisclaimer: {
      label:
        'In some cases, and often depending on your financial institution, full names, email addresses, and phone numbers are insufficient for locating information. Plaid may need additional information, like the username or account number associated with the account you linked with Plaid, in order to complete a full search and locate your information. This information will be securely transmitted, and we will not use it for any purpose other than locating your data to fulfill your request. Please note, the submission of this information is optional, but failure to submit this information may limit our ability to locate information about you.',
    },
    supportingInformation: 'Supporting information',
    appsAndServices: {
      label: 'Apps & Services',
      placeholder:
        'Please list any apps or services you’ve connected to using Plaid (optional)',
    },
    requestDetails: {
      label: 'Additional Information',
      placeholder: 'Please provide any additional details about your request',
    },
    submitText: 'Submit',
    formSuccessMessage:
      'Your request has been received. We will do our best to respond promptly. Please refresh this page if you would like to submit another request using this form.',
    formErrorMessage:
      'There was an error with sending your request. Please try again or contact us directly at ',
    disclaimerText:
      "By submitting this form, I confirm that I have read and understood Plaid's <a href='/legal/#privacy-statement'>privacy policies</a>.",
    additionalInfoHeader: 'Request Types: Additional Information',
    additionalInfoBullets: [
      'This form is related to the data Plaid has collected and stored about you. Plaid is unable to assist you with modifying, accessing or deleting any personal data held by the third party apps you use, therefore we suggest you contact the apps directly in regards to any personal information those apps may have collected.',
      'We have also developed an online portal at my.plaid.com, where some users with financial accounts in the US have the ability to manage the financial data they’ve shared using Plaid. If you’re interested in learning more, we encourage you to visit my.plaid.com and create an account.',
      'Deletion of data is not reversible, and if requested, this will permanently delete your personal data from Plaid’s systems which may impact your ability to use products or services powered by Plaid. Please note, however, that if you use Plaid in the future to connect one or more of your financial accounts to an app, then Plaid will once again obtain personal data about you related to that new connection.',
      'Plaid’s compliance with your request is subject to some limitations and exceptions as provided by law.  For example, as permitted, Plaid retains certain information about you for security and compliance purposes.',
    ],
  },
  es: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Formulario de solicitud de privacidad: Acceso/supresión y otros derechos de protección de datos',
      'meta-description': 'Ejerza sus derechos de protección de datos',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Formulario de solicitud de privacidad: Acceso/supresión y otros derechos de protección de datos',
    introHeader: 'Introducción',
    intro: `<p>Rellene el siguiente formulario para enviar su solicitud de privacidad. Tenga en cuenta que, para procesar su solicitud y, en caso de que sea necesario, verificar su identidad, puede que tenga que proporcionar información adicional. La información que nos facilite a continuación se utilizará únicamente con el fin de atender su solicitud.</p>
    <p>Para obtener más información o ejercer cualquier otro derecho de protección de datos que pueda serle aplicable como Usuario final, consulte nuestra Política de privacidad para usuarios finales en la sección titulada “Sus derechos de protección de datos” o envíenos un correo electrónico a <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si usted no es un Usuario final (no ha utilizado los productos o servicios de Plaid o no ha conectado una aplicación a través de Plaid), consulte nuestra Declaración de privacidad para obtener más información sobre sus derechos o envíenos un correo electrónico a <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si usted es un Candidato o Empleado de Plaid, por favor consulte nuestro Aviso de privacidad del candidato o el Aviso de privacidad del personal para obtener información o envíenos un correo electrónico a <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>. </p>
    <p><strong>No debe utilizar este formulario si tiene problemas para conectarse a una aplicación concreta.</strong> Si necesita más ayuda para conectarse a una aplicación, póngase en contacto directamente con ella. Puede encontrar información general sobre la conexión de cuentas financieras en nuestro centro de ayuda al consumidor. </p>`,
    formHeader: 'Formulario',
    firstName: {
      label: 'Nombre (opcional)',
    },
    lastName: {
      label: 'Apellidos (opcional)',
    },
    email: {
      label: 'Correo electrónico',
    },
    phone: {
      label: 'Teléfono',
    },
    accountMask: {
      label: 'Últimos 4 dígitos del número de cuenta',
    },
    accountUsername: {
      label:
        'Nombre de usuario de la cuenta o número de inicio de sesión (opcional)',
    },
    accountNumber: {
      label: 'Número de cuenta (opcional)',
    },
    applicationsConnected: {
      label: 'Aplicaciones o servicios conectados a través de Plaid (opcional)',
    },
    datesConnected: {
      label: 'Fecha(s) conectada(s) a través de Plaid (opcional)',
    },
    paymentInitiation: {
      label: 'He usado Plaid para iniciar pagos desde mi(s) cuenta(s)',
    },
    requestType: {
      label: 'Seleccione el tipo de solicitud',
      options: [
        {
          label: 'Eliminar: borrar TODOS mis datos almacenados',
          value: 'Supresión/eliminación',
        },
        {
          label: 'Desconectar: borrar SOLO conexiones específicas',
          value: DISCONNECT_REQUEST_TYPE_VALUE.es,
        },
        {
          label: 'Proporcionar una copia de mis datos almacenados',
          value: 'Acceso',
        },
        {
          label:
            'Excluirse de la opción rápida para usuarios habituales de Plaid',
          value: FORGET_REMEMBER_ME.es,
        },
        {
          label: 'Otra solicitud de derechos de privacidad',
          value: 'Otra',
        },
      ],
    },
    requestTypeNote: {
      disconnectLabel:
        'Proporcione información sobre las instituciones financieras que desea desconectar de una aplicación o servicio.',
      generalLabel:
        'Proporcione información sobre su(s) institución(es) financiera(s)',
    },
    financialInstitutions: {
      label: 'Buscar y seleccionar bancos/instituciones financieras',
    },
    fiSearchLoading: 'Buscando instituciones...',
    fiSearchNoneFound: 'No se han encontrado instituciones',
    fiSearchInstruction:
      'Comience a escribir para buscar bancos/instituciones financieras',
    cannotFindFinancialInstitutions:
      'Mis instituciones financieras no están incluidas en los resultados de búsqueda.',
    otherFIs: {
      label: 'Otras instituciones financieras',
    },
    requestDetailsDisclaimer: {
      label:
        'En algunos casos, y muchas veces dependiendo de su institución financiera, los nombres completos, las direcciones de correo electrónico y los números de teléfono no bastan para localizar la información. Si se solicita a continuación, Plaid podría necesitar información adicional como el nombre de usuario o el número de cuenta que asoció con la cuenta vinculada a Plaid para realizar una búsqueda completa y ubicar su información. Esta información se transmitirá de forma segura y no la utilizaremos para ningún otro fin que no sea localizar tus datos para cumplir con su solicitud. Tenga en cuenta que el envío de esta información es opcional, pero no enviarla podría limitar nuestra capacidad para localizar información sobre usted.',
    },
    supportingInformation: 'Información de apoyo',
    appsAndServices: {
      label: 'Aplicaciones y servicios',
      placeholder:
        'Enumere cualquier aplicación o servicio que haya conectado con Plaid (opcional)',
    },
    requestDetails: {
      label: 'Información adicional',
      placeholder: 'Aporte cualquier información adicional sobre su solicitud',
    },
    submitText: 'Enviar',
    disclaimerText:
      "Al enviar este formulario, confirmo que he leído y comprendido las <a href='/legal/#declaraci%C3%B3n-de-privacidad'>políticas de privacidad</a> de Plaid.",
    additionalInfoHeader: 'Tipos de solicitud: Información adicional',
    additionalInfoBullets: [
      'Este formulario está relacionado con los datos que Plaid ha recopilado y almacenado sobre usted. Plaid no puede ayudarle a modificar, acceder o eliminar los datos personales que posean las aplicaciones de terceros que utilice, por lo que le sugerimos que se ponga en contacto directamente con las aplicaciones en relación con cualquier información personal que dichas aplicaciones puedan haber recopilado.',
      'También hemos desarrollado un portal en línea en my.plaid.com, donde algunos usuarios con cuentas financieras en EE.•UU. podrán gestionar los datos financieros que han compartido con Plaid. Si le interesa saber más, le recomendamos que visite my.plaid.com y cree una cuenta.',
      'La eliminación de datos no es reversible, y si se solicita, esto eliminará permanentemente sus datos personales de los sistemas de Plaid, lo que puede afectar a su capacidad para utilizar productos o servicios de Plaid. Sin embargo, tenga en cuenta que si en el futuro utiliza Plaid para conectar una o varias de sus cuentas financieras a una aplicación, Plaid volverá a obtener datos personales sobre usted relacionados con esa nueva conexión.',
      'El cumplimiento de su solicitud por parte de Plaid está sujeto a algunas limitaciones y excepciones previstas por la ley. Por ejemplo, según lo permitido, Plaid conserva determinada información sobre usted por motivos de seguridad y cumplimiento de la normativa.',
    ],
  },
  fr: {
    metaData: {
      layout: 'docs',
      'meta-title':
        'Formulaire de demande en matière de confidentialité : Droits d’accès/de suppression et autres droits en matière de protection des données',
      'meta-description':
        'Exercer vos droits en matière de protection des données',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader:
      'Formulaire de demande en matière de confidentialité : Droits d’accès/de suppression et autres droits en matière de protection des données',
    introHeader: 'Introduction',
    intro: `Veuillez compléter le formulaire ci-dessous pour soumettre votre demande en matière de confidentialité. Remarque : afin de traiter votre demande, des informations supplémentaires peuvent vous être demandées pour vérifier votre identité. Les informations que vous fournissez ci-dessous seront uniquement utilisées aux fins de répondre à votre demande.
    Pour en savoir plus et/ou pour exercer d’autres droits en matière de protection des données pouvant s’appliquer à vous en tant qu’Utilisateur final, veuillez consulter notre Politique de confidentialité de l’Utilisateur final dans la section intitulée « Vos droits en matière de protection des données » ou veuillez nous envoyer un e-mail à l’adresse <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si vous n’êtes pas un Utilisateur final (vous n’avez pas utilisé de produits ou de services de Plaid et/ou vous ne vous êtes pas connecté(e) à une application via Plaid), veuillez consulter notre Déclaration de confidentialité pour plus d’informations sur vos droits ou veuillez envoyer un e-mail à l’adresse <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. Si vous êtes un(e) Candidat(e) ou un(e) Employé(e) de Plaid, veuillez consulter notre Avis de confidentialité pour les candidats ou notre Avis de confidentialité du personnel pour plus d’informations ou veuillez nous envoyer un e-mail à l’adresse <a href="mailto:privacy-people@plaid.com">privacy-people@plaid.com</a>. 
    <strong>Vous ne devez pas utiliser ce formulaire si vous rencontrez des difficultés pour vous connecter à une application particulière.</strong> Pour obtenir de l’aide concernant votre connexion à une application, veuillez contacter directement l’application. Vous pouvez trouver des informations générales sur la connexion des comptes financiers sur notre centre d’aide aux consommateurs.`,
    formHeader: 'Formulaire',
    firstName: {
      label: 'Prénom (facultatif)',
    },
    lastName: {
      label: 'Nom de famille (facultatif)',
    },
    email: {
      label: 'E-mail',
    },
    phone: {
      label: 'Téléphone',
    },
    accountMask: {
      label: '4 derniers chiffres du numéro de compte',
    },
    accountUsername: {
      label: "Nom d'utilisateur ou identifiant de connexion (facultatif)",
    },
    accountNumber: {
      label: 'Numéro de compte (facultatif)',
    },
    applicationsConnected: {
      label: 'Applications ou services connectés via Plaid (facultatif)',
    },
    datesConnected: {
      label: 'Date(s) de connexion via Plaid (facultatif)',
    },
    paymentInitiation: {
      label:
        "J'ai utilisé Plaid pour initier des paiements à partir de mon compte ou de mes comptes.",
    },
    requestType: {
      label: 'Sélectionnez votre type de demande',
      options: [
        {
          label: 'Supprimer : effacer TOUTES mes données stockées',
          value: 'Suppression / Effacement',
        },
        {
          label: 'Déconnecter : effacer UNIQUEMENT des connexions spécifiques',
          value: DISCONNECT_REQUEST_TYPE_VALUE.fr,
        },
        {
          label: 'Fournir une copie des données stockées à mon sujet',
          value: 'Accès',
        },
        {
          label: "Désactiver l'expérience utilisateur plus rapide de Plaid",
          value: FORGET_REMEMBER_ME.fr,
        },
        {
          label: 'Autre demande relative à la confidentialité',
          value: 'Autre',
        },
      ],
    },
    requestTypeNote: {
      disconnectLabel:
        "Veuillez fournir des informations sur les institutions financières que vous souhaitez déconnecter d'une application ou d'un service.",
      generalLabel:
        'Veuillez fournir des informations sur votre (vos) institution(s) financière(s)',
    },
    financialInstitutions: {
      label: 'Rechercher et sélectionner des banques/​institutions financières',
    },
    fiSearchLoading: "Recherche d'institutions...",
    fiSearchNoneFound: 'Aucune institution trouvée',
    fiSearchInstruction:
      'Saisissez le nom de votre banques/​institutions financières',
    cannotFindFinancialInstitutions:
      'Mon institution financière ne figure pas parmi les résultats de la recherche.',
    otherFIs: {
      label: 'Autres institutions financières',
    },
    requestDetailsDisclaimer: {
      label:
        'Dans certains cas, et généralement selon votre institution financière, le nom, le prénom, l’adresse électronique et le numéro de téléphone ne suffisent pas pour trouver les informations demandées. Plaid peut avoir besoin d’informations supplémentaires (à fournir ci-dessous, s’il y a lieu), comme le nom d’utilisateur ou le numéro de compte associé, pour procéder à une recherche avancée et trouver vos informations. Ces informations seront transmises de manière sécurisée et seront utilisées uniquement pour trouver vos données à la suite de votre demande. Veuillez noter que la soumission de ces informations est facultative, mais que le fait de ne pas les fournir peut limiter notre capacité à localiser les informations vous concernant.',
    },
    supportingInformation: 'Informations complémentaires',
    appsAndServices: {
      label: 'Applications et services',
      placeholder:
        "Listez les applications ou services que vous avez connectés à l'aide de Plaid (facultatif)",
    },
    requestDetails: {
      label: 'Informations additionnelles',
      placeholder:
        'Veuillez fournir des informations supplémentaires concernant votre demande',
    },
    submitText: 'Soumettre',
    disclaimerText:
      "En envoyant ce formulaire, je reconnais avoir pris connaissance des <a href='/legal/#notice-d-information-sur-le-traitement-des-donn%C3%A9es-personnelles'>politiques de confidentialité</a> de Plaid.",
    additionalInfoHeader: 'Types de demande : Informations supplémentaires',
    additionalInfoBullets: [
      'Ce formulaire est lié aux données que Plaid a collectées et stockées à votre sujet. Plaid n’est pas en mesure de vous aider à modifier, accéder ou supprimer les données à caractère personnel détenues par les applications tierces que vous utilisez, nous vous suggérons donc de contacter directement les applications en ce qui concerne les informations personnelles que ces applications peuvent avoir collectées.',
      'Nous avons également développé un portail en ligne sur my.plaid.com qui permet à certains utilisateurs titulaires d’un compte bancaire domicilié aux États-Unis de gérer les informations financières communiquées par l’intermédiaire de Plaid. Pour en savoir plus, veuillez consulter le site my.plaid.com et créer votre compte.',
      'La suppression des données n’est pas réversible et, sur demande, cela supprimera définitivement vos données à caractère personnel des systèmes de Plaid, ce qui peut avoir un impact sur votre capacité à utiliser les produits ou services fournis par Plaid. Veuillez noter, cependant, que si vous utilisez Plaid à l’avenir pour connecter un ou plusieurs de vos comptes financiers à une application, Plaid obtiendra à nouveau des données à caractère personnel vous concernant dans le cadre de cette nouvelle connexion.',
      'La conformité de Plaid à votre demande est soumise à certaines limitations et exceptions prévues par la loi. Par exemple, dans le cadre de ce qui est autorisé, Plaid conserve certaines informations vous concernant à des fins de sécurité et de conformité.',
    ],
  },
  nl: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Verzoek tot inzage/gegevenswissing',
      'meta-description': 'Uw gegevensbeschermingsrechten uitoefenen',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    pageHeader: 'Verzoek tot inzage/gegevenswissing',
    introHeader: 'Voorwoord',
    intro: `<p>In bepaalde rechtsgebieden en onder voorbehoud van beperkingen en uitsluitingen ingevolge de wet kunt u aanspraak maken op bepaalde rechten en keuzes ten aanzien van de gegevens die we over u verzamelen en hoe die worden gebruikt. Voor het verwerken van uw verzoek moet u mogelijk aanvullende informatie verstrekken om uw identiteit te verifiëren. NB Om uw verzoek in behandeling te kunnen nemen, kan u worden gevraagd om aanvullende informatie om uw identiteit te verifiëren. De gegevens die u hiervoor verstrekt, worden uitsluitend gebruikt om aan uw verzoek te kunnen voldoen.</p>
      <p>Klik <a href="#additional-info" data-smooth-scroll data-offset="80">hier</a> voor meer informatie over de in onderstaand formulier genoemde Soorten Verzoek. Voor aanvullende informatie over en/of het uitoefenen van andere gegevensbeschermingsrechten die op u als eindgebruiker van toepassing kunnen zijn, verwijzen wij u graag naar ons <a href="https://plaid.com/legal/#privacybeleid-voor-eindgebruikers">Privacybeleid voor eindgebruikers</a> dat u kunt vinden onder “Uw gegevensbeschermingsrechten”. U kunt ook een e-mail sturen naar <a href="mailto:privacy@plaid.com">privacy@plaid.com</a>. NB Als u een kandidaat bent voor een vacature, vindt u meer informatie in onze <a href="https://plaid.com/legal/#candidate-privacy-notice">Privacyverklaring voor kandidaten</a> en stuurt u eventuele verzoeken betreffende uitoefening van uw gegevensbeschermingsrechten per e-mail naar <a href="mailto:people-privacy@plaid.com">people-privacy@plaid.com</a>, naargelang van toepassing.</p>`,
    formHeader: 'Formulier',
    firstName: {
      label: 'Voornaam (optioneel)',
    },
    lastName: {
      label: 'Achternaam (optioneel)',
    },
    email: {
      label: 'E-mailadres',
    },
    phone: {
      label: 'Telefoon',
    },
    accountMask: {
      label: 'Laatste 4 cijfers van rekeningnummer',
    },
    accountUsername: {
      label: 'Gebruikersnaam of inlognummer account (optioneel)',
    },
    accountNumber: {
      label: 'Rekeningnummer (optioneel)',
    },
    applicationsConnected: {
      label: 'Apps of services die zijn gekoppeld via Plaid (optioneel)',
    },
    datesConnected: {
      label:
        'Datum(s) waarop u een koppeling hebt gemaakt via Plaid (optioneel)',
    },
    paymentInitiation: {
      label:
        'Ik heb Plaid gebruikt om betalingen vanaf mijn rekening(en) te doen',
    },
    requestType: {
      label: 'Selecteer het type verzoek',
      options: [
        {
          label: 'Verwijderen: al mijn opgeslagen gegevens wissen',
          value: 'Gegevenswissing',
        },
        {
          label:
            'Verbinding verbreken: alleen specifieke verbinding(en) wissen',
          value: DISCONNECT_REQUEST_TYPE_VALUE.nl,
        },
        {
          label: 'Een kopie van mijn opgeslagen gegevens verstrekken',
          value: 'Toegang',
        },
        {
          label:
            'Afmelden voor de snellere ervaring voor terugkerende gebruikers van Plaid',
          value: FORGET_REMEMBER_ME.nl,
        },
        {
          label: 'Ander verzoek m.b.t.privacyrechten',
          value: 'Inzage',
        },
      ],
    },
    requestTypeNote: {
      disconnectLabel:
        'Geef informatie over de financiële instelling(en) die u wilt ontkoppelen van een app of service.',
      generalLabel: 'Geef informatie over uw financiële instelling(en)',
    },
    financialInstitutions: {
      label: 'Zoek en selecteer banken/financiële instellingen',
    },
    fiSearchLoading: 'Instellingen zoeken...',
    fiSearchNoneFound: 'Geen instellingen gevonden',
    fiSearchInstruction:
      'Begin met typen om uw banken/financiële instelling(en) te zoeken',
    cannotFindFinancialInstitutions:
      'Mijn financiële instelling(en) komen niet voor in de zoekresultaten.',
    otherFIs: {
      label: 'Andere financiële instellingen',
    },
    requestDetailsDisclaimer: {
      label:
        'In sommige gevallen (vaak afhankelijk van uw financiële instelling) zijn een volledige naam, e-mailadres en telefoonnummer onvoldoende om informatie te vinden. Indien hieronder gevraagd, kan Plaid aanvullende informatie nodig hebben om een volledige zoekopdracht uit te voeren en de gegevens te vinden, zoals de gebruikersnaam of het rekeningnummer van het account dat u aan Plaid heeft gekoppeld. Deze informatie wordt veilig verzonden en zal niet worden gebruikt voor andere doeleinden dan het lokaliseren van uw gegevens om aan uw verzoek te voldoen. Het indienen van deze informatie is optioneel, maar als u dit niet doet, kunnen we mogelijk geen gegevens over u vinden.',
    },
    supportingInformation: 'Ondersteunende informatie',
    appsAndServices: {
      label: 'Apps en services',
      placeholder:
        'Datum(s) waarop u een koppeling hebt gemaakt via Plaid (optioneel)',
    },
    requestDetails: {
      label: 'Aanvullende informatie',
      placeholder: 'Geef eventuele aanvullende informatie op over uw verzoek',
    },
    submitText: 'Verzenden',
    disclaimerText:
      "Door dit formulier te verzenden, bevestig ik dat ik het <a href='/legal/#privacybeleid-voor-eindgebruikers'>privacybeleid</a> van Plaid heb gelezen en begrepen.",
    additionalInfoHeader: 'Soorten verzoek: Aanvullende informatie',
    accessHeader: 'Inzage',
    additionalInfoBullets: [
      'Dit formulier is gerelateerd aan de gegevens die Plaid over u heeft verzameld en opgeslagen. Plaid kan u niet helpen bij het wijzigen, bekijken of verwijderen van persoonsgegevens die worden bewaard door externe apps die u gebruikt. Daarom raden we u aan rechtstreeks contact op te nemen met deze apps voor alle persoonsgegevens die deze apps mogelijk hebben verzameld.',
      'We hebben ook een online portal op my.plaid.com. Hier kunnen bepaalde gebruikers met een rekening in de VS financiële gegevens beheren die ze met Plaid hebben gedeeld. Ga voor meer informatie naar my.plaid.com en maak een account aan.',
      'Het verwijderen van gegevens is niet omkeerbaar. Op uw verzoek worden uw persoonsgegevens permanent van de systemen van Plaid verwijderd, wat gevolgen kan hebben voor uw vermogen om producten of diensten van Plaid te gebruiken. Houd er rekening mee dat als u Plaid in de toekomst gebruikt om één of meer van uw financiële rekeningen met een app te koppelen, Plaid opnieuw persoonsgegevens over u verkrijgt met betrekking tot die nieuwe koppeling.',
      'Plaid voldoet aan uw verzoek met inachtneming van bepaalde wettelijke beperkingen en uitzonderingen. Voor zover toegestaan bewaart Plaid bijvoorbeeld bepaalde gegevens over u voor veiligheids- en nalevingsdoeleinden.',
    ],
    accessIntro:
      'Als u ervoor kiest om uw recht op <strong>inzage van uw gegevens</strong> uit te oefenen, naargelang van toepassing, wijzen wij u op het volgende:',
    accessBullets: [
      'U kunt dit recht uitoefenen om te vragen om inzage in of ontvangst van een kopie van de persoonsgegevens die Plaid van u bewaart (of die ten behoeve van Plaid worden bewaard). Aangezien Plaid u niet kan helpen met inzage in de persoonsgegevens die zijn opgeslagen door de apps die u gebruikt, raden wij u aan rechtstreeks contact op te nemen met de betreffende apps.',
      'Wij werken momenteel aan de ontwikkeling van een onlineportaal op my.plaid.com, waar bepaalde gebruikers met financiële accounts in de VS de financiële gegevens kunnen beheren die ze bij het gebruik van Plaid hebben verstrekt. Als u daar meer over wilt weten, ga dan naar <a href="https://my.plaid.com/">my.plaid.com</a> en maak daar een account aan.',
      'Dit formulier is <u>niet</u> bestemd voor melding van problemen met koppelingen naar bepaalde apps. Voor meer hulp met koppelingen naar apps wordt u verzocht om rechtstreeks contact te zoeken met de betrokken app of gaat u naar <a href="http://my.plaid.com/help">my.plaid.com/help</a> voor aanvullende informatie.',
    ],
    deleteHeader: 'Gegevenswissing',
    deleteIntro:
      'Als u ervoor kiest om uw recht op <strong>gegevenswissing</strong> uit te oefenen, naargelang van toepassing, wijzen wij u op het volgende:',
    deleteBullets: [
      'Deze actie is niet omkeerbaar en resulteert in de definitieve verwijdering van uw persoonsgegevens uit de systemen van Plaid. Wij wijzen u er echter op dat als u Plaid in de toekomst gebruikt om een of meer van uw financiële accounts te koppelen aan een app, Plaid op dat moment wederom persoonsgegevens van u verkrijgt, namelijk met betrekking tot die nieuwe koppeling.',
      'Ingevolge hetgeen is toegestaan onder toepasselijke wetgeving bewaart Plaid bepaalde gegevens van u in het kader van beveiligingsvereisten en om toepasselijke wet- en regelgeving na te komen.',
      'Met het uitoefenen van dit recht worden de persoonsgegevens niet van uw apps gewist. Voor verzoeken om verwijdering van persoonsgegevens die eventueel op die apps zijn opgeslagen, dient u rechtstreeks contact op te nemen met de betreffende apps.',
      'Deze actie kan leiden tot verlies van gegevens die van belang zijn voor de apps die u gebruikt en kan zelfs nadelige gevolgen hebben voor de mogelijkheid om van die apps gebruik te maken. Wij raden u dan ook aan om voordat u verdergaat eerst contact te zoeken met die apps als u vragen heeft over hoe de verwijdering van uw persoonsgegevens uw gebruik van de betreffende app zou kunnen beïnvloeden.',
    ],
  },
};
