import React, { FC, useState, useEffect } from 'react';
import Select, { SelectProps, Option } from './Select';
import { Controller, useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
interface Props extends SelectProps {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  controlClassname?: string;
  control: ReturnType<typeof useForm>['control'];
  required?: string;
  setValue?: ReturnType<typeof useForm>['setValue'];
  controlledValue?: Option;
  defaultValue?: Option;
  isMulti?: boolean;
  id: string;
}

const SelectWithController: FC<Props> = ({
  control,
  name,
  onInputChange,
  onChange,
  register,
  required,
  setValue,
  controlledValue,
  defaultValue,
  isMulti,
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(null);

  const updateValue = (arg, isMulti = false) => {
    setInputValue(arg);

    const newValue = isMulti
      ? arg.map((v) => {
          return v.value;
        })
      : arg.value;
    setValue(name, newValue, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    setInputValue(controlledValue);
  }, [controlledValue]);

  useEffect(() => {
    if (defaultValue) {
      updateValue([defaultValue]);
    }
  }, []); // eslint-disable-line

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      inputRef={register({
        ...(required && { required }),
      })}
      rules={{ required: required }}
      render={() => {
        return (
          <Select
            {...restProps}
            {...(restProps.id ? { id: `${restProps.id}-${nanoid()}` } : {})}
            name={name}
            onInputChange={onInputChange}
            onChange={(arg) => {
              if (onChange) {
                onChange(arg);
              }
              updateValue(arg, isMulti);
            }}
            value={inputValue}
            isMulti={isMulti}
          />
        );
      }}
    />
  );
};

SelectWithController.displayName = 'SelectWithController';
export default SelectWithController;
