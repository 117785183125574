import React from 'react';
import PropTypes from 'prop-types';

// components
import { Link } from 'src/components/Inputs';

const buttonLinksPropTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
};

const ButtonLinks = ({ links }) => (
  <ul className='button-links'>
    {links.map((item, i) => (
      <li key={`button-link-${i}`} className='button-links__item'>
        <Link href={item.href}>
          <a className='button-links__link'>{item.title}</a>
        </Link>
      </li>
    ))}
  </ul>
);

ButtonLinks.propTypes = buttonLinksPropTypes;

const buttonLinksSectionPropTypes = {
  buttonLinksHeading: PropTypes.string.isRequired,
  buttonLinks: buttonLinksPropTypes.links.isRequired,
};

const ButtonLinksSection = ({ buttonLinksHeading, buttonLinks }) => (
  <>
    <p className='button-links-heading'>{buttonLinksHeading}</p>
    <ButtonLinks links={buttonLinks} />
  </>
);

ButtonLinksSection.propTypes = buttonLinksSectionPropTypes;

export default ButtonLinksSection;
