import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import ThemeProvider from 'threads5/themes/theme-provider';

import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { Wrapper } from 'src/containers';
import { Column, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

import styles from './CmsLargeAssetCenteredHero.module.scss';
import {
  eyebrowStyles,
  headingStyles,
  paragraphStyles,
} from './CmsLargeAssetCenteredHero.styles';

const CmsLargeAssetCenteredHero = ({ fields, sys }) => {
  const mode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: fields?.backgroundColor,
  });
  const componentTheme = useComponentTheme(mode);
  const cmsTheme = useCmsTheme();
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const { asset } = fields || {};
  const background =
    componentTheme.palette[fields?.backgroundColor] ||
    fields.backgroundColor?.toLowerCase() ||
    'transparent';
  const fieldId = 'LargeImageBody';

  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper
        component='header'
        sx={{ background: background, ...fields?.styles }}
        {...inspectorProps({ fieldId: 'internalTitle' })}
      >
        <Column
          className={styles.headColumn}
          xs={24}
          sm={18}
          lg={16}
          lgOffset={4}
        >
          <Stack
            className={styles.headColumn__stack}
            sx={{
              '& .eyebrow-heading': {
                mb: '3.2rem',
              },
              '& h1:not(:last-child), & h2:not(:last-child), & h3:not(:last-child), & h4:not(:last-child), & h5:not(:last-child)':
                {
                  mb: { xs: '2.4rem', lg: '1.6rem' },
                },
              '& p': {
                mb: '2.4rem',
                'max-width': { sm: '50.3rem', lg: '80.7rem' },
              },
              '& .button-group': {
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
                '& a': {
                  letterSpacing: { lg: '-0.03rem' },
                },
              },
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
            }}
          >
            <RichTextSwitch
              sys={sys}
              content={fields?.centerColumn}
              nodes={{
                h1: {
                  component: 'h1',
                  variant: 'h1',
                  fieldId,
                },
                h2: {
                  component: 'h2',
                  variant: 'h1',
                  fieldId,
                  sx: {
                    ...headingStyles?.[cmsTheme],
                  },
                },
                h6: {
                  component: 'h1',
                  variant: 'h6',
                  className: 'eyebrow-heading',
                  fieldId,
                  sx: {
                    ...eyebrowStyles?.[cmsTheme],
                    fontSize: '2.0rem',
                  },
                },
                p: {
                  component: 'p',
                  variant: 'h5',
                  fieldId,
                  sx: {
                    ...paragraphStyles?.[cmsTheme],
                  },
                },
              }}
            />
          </Stack>
        </Column>

        <Column xs={24} sm={24} lg={20} lgOffset={2}>
          {asset && (
            <CmsMediaSwitch
              asset={{
                ...asset.fields,
                isLazy: false,
                styles: {
                  height: 'auto',
                  width: '100%',
                  ...asset.fields?.styles,
                },
              }}
              queryString={{
                xs: `?fm=webp&w=824&q=60`,
                sm: `?fm=webp&w=1226&q=60`,
                md: `?fm=webp&w=1886&q=60`,
                lg: `?fm=webp&w=2662&q=60`,
              }}
              width={677} // setting a default width in case it was not provided in CMS
              height={513} // setting a default height in case it was not provided in CMS
              sys={asset.sys}
            />
          )}
        </Column>
      </Wrapper>
    </ThemeProvider>
  );
};

export default CmsLargeAssetCenteredHero;
