const {
  assets,
  auth,
  balance,
  enrich,
  identity,
  investments,
  liabilities,
  transactions,
  income,
  identityVerification,
  monitor,
  signal,
  transfer,
} = {
  assets: {
    name: 'Assets',
    description: "Point-in-time snapshots of users' finances",
    url: '/products/assets/',
    icon: '/assets/img/navbar/icon-assets.png',
  },
  auth: {
    name: 'Auth',
    description: 'Account and routing numbers',
    url: '/products/auth/',
    icon: '/assets/img/navbar/icon-auth.png',
  },
  balance: {
    name: 'Balance',
    description: 'Real-time balance checks',
    url: '/products/balance/',
    icon: '/assets/img/navbar/icon-balance.png',
  },
  enrich: {
    name: 'Enrich',
    description: 'Cleanse and categorize internal transactions',
    url: '/products/enrich/',
    icon: '/assets/img/navbar/icon-enrich.png',
  },
  identity: {
    name: 'Identity',
    description: 'Bank account-holder information',
    url: '/products/identity/',
    icon: '/assets/img/navbar/icon-identity.png',
  },
  investments: {
    name: 'Investments',
    description: 'Retirement, brokerage, and crypto data',
    url: '/products/investments/',
    icon: '/assets/img/navbar/icon-investments.png',
  },
  liabilities: {
    name: 'Liabilities',
    description: 'Student loan, credit card, and mortgage data',
    url: '/products/liabilities/',
    icon: '/assets/img/navbar/icon-liabilities.png',
  },
  transactions: {
    name: 'Transactions',
    description: 'Up to 24 months of categorized data',
    url: '/products/transactions/',
    icon: '/assets/img/navbar/icon-transactions.png',
  },
  income: {
    name: 'Income',
    description: 'Income and employment verification',
    url: '/products/income/',
    icon: '/assets/img/navbar/products-income-48@2x.png',
  },
  identityVerification: {
    name: 'Identity Verification',
    description: 'Global KYC and anti-fraud',
    url: '/products/identity-verification/',
    icon: '/assets/img/navbar/icon-idverification-48.png',
  },
  monitor: {
    name: 'Monitor',
    description: 'AML and PEP screening',
    url: '/products/monitor/',
    icon: '/assets/img/navbar/icon-monitor-48.png',
  },
  signal: {
    name: 'Signal',
    description: 'ACH Risk Assessment & Scoring',
    url: '/products/signal/',
    icon: '/assets/img/navbar/icon-signal.png',
  },
  transfer: {
    name: 'Transfer',
    description: 'Flexible bank payment management',
    url: '/products/transfer/',
    icon: '/assets/img/navbar/icon-transfer.png',
  },
};

const columns = [
  {
    gridClasses: 'small-4',
    items: [auth, balance, identity, transactions],
  },
  {
    gridClasses: 'small-4',
    items: [transfer, assets, income, investments, liabilities],
  },
  {
    gridClasses: 'small-4',
    items: [signal, enrich, identityVerification, monitor],
  },
];

export const products = {
  label: 'Products',
  megaMenuClass: 'products',
  submenuClass: 'cell small-9',
  subtitle: 'Learn how you can make the most of financial data',
  menuLinks: [
    {
      name: 'Global coverage',
      url: '/global/',
    },
    {
      name: 'Payments',
      url: '/solutions/payments/',
    },
    {
      name: 'Fraud and Compliance',
      url: '/solutions/fraud-compliance/',
    },
  ],
  submenu: {
    columns,
  },
};

export default products;
