/* eslint-disable import/prefer-default-export */
import { Entry } from 'contentful';
import { TileGroup, EventTileGroup, AdaptedTileGroup } from './TileGroup.types';
import _get from 'lodash/get';
import { format } from 'fecha';

import { formatUrl } from 'src/lib/utils';
import { EventShape } from 'src/lib/ts-types';
import { Contentful, RESOURCES_PATH } from 'src/lib/constants';
import { Tile } from '../Tile/Tile.types';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

export const tileGroupAdapter = ({
  title,
  subtitle = '',
  label,
  tiles,
}: TileGroup): AdaptedTileGroup => {
  return {
    title,
    subtitle,
    label,
    tileGroup: tiles.map((item) => {
      const type = _get(item, Contentful.CONTENT_TYPE_ID);
      switch (type) {
        case 'article':
          return articleTileAdapter({ article: item });
        default:
          return {
            type,
            image: {
              src: item?.fields?.image?.fields?.file?.url,
              alt:
                item?.fields?.image?.fields?.description ||
                item?.fields?.title ||
                'Tile image',
            },

            target: item?.fields?.cta?.fields?.target,
            title: item?.fields?.title || item?.fields?.name,
            description: item?.fields?.description || item?.fields?.excerpt,
            cta: {
              href: item?.fields?.cta?.fields?.url,
              text: item?.fields?.cta?.fields?.text,
            },
          };
      }
    }),
  };
};

export const tileAdapter = (fields): Tile => {
  return {
    image: {
      src: fields?.image?.fields?.file?.url,
      alt: fields?.image?.fields?.description || fields?.title || 'Tile image',
    },
    target: fields?.cta?.fields?.target,
    title: fields?.title || fields?.name,
    description: fields?.description || fields?.excerpt,
    cta: {
      href: fields?.cta?.fields?.url,
      text: fields?.cta?.fields?.text,
    },
  };
};

const formatEvent = (event) => {
  const title = _get(event, 'fields.title');
  const date = _get(event, 'fields.startTime');
  const formattedDate = date
    ? format(new Date(date), 'mediumDate')
    : 'Date TBD';
  const location = _get(event, 'fields.location', '');

  return `
    ${title}<br/>
    ${formattedDate} ${location && '—'} ${location}
  `;
};

// Filter out past events
const filterEvents = (events) => {
  return events.filter((event: Entry<EventShape>) => {
    const eventDate = _get(event, 'fields.startTime', '9999-01-01');

    const notPastEvent =
      new Date(eventDate).getTime() - new Date().getTime() > 0;

    return notPastEvent;
  });
};

const sortEvents = (events) => {
  return events.sort((a, b) => {
    const aDate = new Date(_get(a, 'fields.startTime', '9999-01-01')).getTime();
    const bDate = new Date(_get(b, 'fields.startTime', '9999-01-01')).getTime();
    return aDate - bDate;
  });
};

export const eventTileGroupAdapter = ({
  fields,
  events,
}: EventTileGroup): AdaptedTileGroup => {
  const inherentEvents = fields?.events; // Specific events from the `events` field on EventList
  const eventsToDisplay = fields?.eventsToDisplay;

  // Passing specific events (if they're there) or all events
  const filteredEvents = filterEvents(inherentEvents || events);
  let sortedEvents = sortEvents(filteredEvents);

  if (!inherentEvents && eventsToDisplay)
    sortedEvents = sortedEvents.slice(0, eventsToDisplay);

  return {
    title: fields?.title || '',
    label: fields?.label || '',
    tileGroup: sortedEvents.map((event: Entry<EventShape>) => {
      const pageSlug = _get(event, 'fields.pageSlug', null);

      return {
        image: {
          src: event?.fields?.eventImage?.fields?.file?.url,
          alt:
            event?.fields?.eventImage?.fields?.description ||
            event?.fields?.title ||
            'Event image',
        },
        title: event?.fields?.title,
        description: formatEvent(event),
        cta: {
          href: pageSlug ? `/events/${formatUrl(event?.fields?.pageSlug)}` : '',
          text: 'Register',
        },
      };
    }),
  };
};

export const articleTileGroupAdapter = ({ articles }): AdaptedTileGroup => {
  return {
    tileGroup: articles.map((article) => {
      const image = article?.fields?.openGraphImage;
      const topics = article?.fields?.topics || [
        { fields: { pageSlug: 'All Topics' } },
      ];
      const topic = topics[0]?.fields?.pageSlug;

      return {
        description: article?.fields?.metaTitle,
        image: {
          src: image?.fields?.file?.url,
          alt: image?.fields?.title,
        },
        cta: {
          href: `${RESOURCES_PATH}/${topic}/${article?.fields?.pageSlug}`,
          text: 'Read now',
        },
      };
    }),
  };
};

export const articleTileAdapter = ({ article }): Tile => {
  const image = article?.fields?.openGraphImage;
  const topics = article?.fields?.topics;
  const topic = topics[0]?.fields?.pageSlug;

  return {
    title: article?.fields?.metaTitle,
    description: article?.fields?.metaDescription,
    image: {
      src: image?.fields?.file?.url,
      alt: image?.fields?.title,
    },
    cta: {
      href: `${RESOURCES_PATH}/${topic}/${article?.fields?.pageSlug}`,
      text: 'Read now',
    },
  };
};
