export const metaData = {
  'meta-title': 'PSD2 and Open Banking',
  'meta-description':
    "What is PSD2? What is Open Banking? We provide answers to common questions that we've received around AIS, PIS, registration requirements, and more.",
};

export const pageHeaderData = {
  'content-width': 'small-12',
  'content-width-classes': 'page-header-breakpoint-5 large-6',
  header: 'PSD2 and Open Banking',
  'header-class': 'h2',
  subheader:
    'Find answers to common questions that Plaid receives regarding PSD2 and Open Banking.',
  buttonLinksHeading: 'Tell me about...',
  buttonLinks: [
    { title: 'PSD2', href: '#psd2' },
    { title: 'Open Banking', href: '#open-banking' },
    { title: 'AIS', href: '#ais' },
    { title: 'PIS', href: '#pis' },
    { title: 'Registration requirements', href: '#registration-reqirements' },
    { title: 'eIDAS certification', href: '#eidas-certification' },
    { title: 'Integrating with Plaid', href: '#integrating-with-plaid' },
  ],
  video: true,
  'video-width': 1024,
  'video-height': 528,
  'video-poster-image':
    '/assets/img/open-banking/hero-open-banking-uk-en-poster@2x.png',
  'video-fallback-image':
    '/assets/img/open-banking/hero-open-banking-uk-en-fallback@2x.png',
  'video-formats': [
    {
      url: '/assets/video/hero-open-banking-uk-en.webm',
      type: 'webm',
    },
    {
      url: '/assets/video/hero-open-banking-uk-en.mp4',
      type: 'mp4',
    },
  ],
};

export const contentBlock1Data = [
  {
    id: 'psd2',
    title: 'UK AND EU REGULATORY FOUNDATION',
    image: {
      src: '/assets/img/open-banking/psd2@2x.png',
      alt: 'Security magnifying glass illustration',
    },
    heading: 'What is PSD2?',
    paragraph: [
      'The revised Payment Services Directive (PSD2) went into effect on 13 January 2018 across the UK and Europe. PSD2 introduces new rights for certain third-party providers (TPPs) to directly access payment service users’ online payment accounts with their explicit consent, and requires Account Servicing Payment Service Providers (ASPSPs), such as a bank, to permit access through a dedicated interface built on APIs. These PSD2 measures are designed to open up the banking industry to new players and promote the development and use of innovative online services, while ensuring consumer protection.',
      'PSD2 provides the legislative and regulatory foundation for Open Banking and other broader initiatives at a UK and European level relating to open access to payment accounts.',
    ],
  },
  {
    id: 'open-banking',
    title: 'OPEN ACCESS TO FINANCIAL DATA',
    image: {
      src: '/assets/img/open-banking/open-banking@2x.png',
      alt: 'Security shield illustration',
    },
    heading: 'What is Open Banking?',
    paragraph: [
      '“open banking” (lowercase O and B) refers to the practice of providing open access to financial data from financial institutions through the use of application programming interfaces (APIs). Open banking supports Plaid’s mission: to empower innovators by delivering access to the financial system.',
      `In the UK, an initiative led by the Competition and Markets Authority <a class="signup-attach-utm" href="https://www.gov.uk/government/organisations/competition-and-markets-authority">(CMA)</a> called <a class="signup-attach-utm" href="https://www.openbanking.org.uk/about-us/">“Open Banking”</a> (uppercase O and B) mandated that nine of the biggest banks in the UK (the CMA9) grant third parties access to customer payment account data in a secure, standardised form, provided that the third party accessing the account has the customer's explicit consent to do so.`,
    ],
  },
  {
    id: 'ais',
    title: 'ACCOUNT INFORMATION SERVICE',
    image: {
      src: '/assets/img/open-banking/aisp@2x.png',
      alt: 'Settings illustration',
    },
    heading: 'What is AIS?',
    paragraph: [
      'Under PSD2, an Account Information Service (AIS) is an online service that provides consolidated information to a user on one or more payment accounts held by that user with other payment service providers. Firms that are registered or authorised to provide account information services can, with the explicit consent of the end consumer, access their bank account to provide the end consumer with new products and services.',
      'If you’re providing AIS, you will need to be registered or authorised by the Financial Conduct Authority (FCA) in the UK, or an equivalent National Competent Authority (NCA) in Europe, to provide AIS in order to benefit from PSD2 open access measures. This is because PSD2 only mandates ASPSPs to enable access to firms that are registered or authorised to provide AIS by the FCA in the UK, or an equivalent NCA in Europe.',
    ],
  },
  {
    id: 'pis',
    title: 'REGISTRATION REQUIREMENTS',
    image: {
      src: '/assets/img/open-banking/payments@2x.png',
      alt: 'Payment transfer illiustration',
    },
    heading: 'What is PIS?',
    paragraph: [
      'Under PSD2, a Payment Initiation Service (PIS) is an online service which accesses a user’s payment account to initiate the transfer of funds on their behalf with the user’s explicit consent and authentication.',
      'Plaid is a Payment Initiation Service Provider, and offers <a class="signup-attach-utm" href="/uk/products/payment-initiation/">Payment Initiation </a> in the UK, enabling your users to make real-time payments without manually entering their account number and sort code, or leaving your app. These in-app payments allow your users to fund their accounts in a seamless and secure manner, and at a low cost. Payment Initiation is an easy way for users to fund their account, make online payments, and pay invoices all from your app or website.',
    ],
  },
  {
    id: 'registration-reqirements',
    title: 'REGISTRATION REQUIREMENTS',
    image: {
      src: '/assets/img/open-banking/aisp-cert@2x.png',
      alt: 'AISP certification illiustration',
    },
    heading:
      'Which businesses need to be registered or authorised to provide account information?',
    paragraph: [
      'In the UK, the FCA provides guidance on the type of companies that need to be registered or authorised to provide AIS:',
      {
        list: [
          'A business that provides consolidated account information, such as a personal finance management tool, will generally need to be registered or authorised for the provision of AIS.',
          'Other businesses, such as those engaged in internal fraud detection or credit decisions and therefore are not providing consolidated account information, generally will not need to be registered or authorised for the provision of AIS.',
        ],
      },
      'You should closely review PSD2 and local legislation with your legal counsel to determine whether you need to be registered or authorised to provide AIS depending on your product and the reasons for which you are seeking AIS.',
    ],
  },
];

export const ctaData = {
  title: 'Are there alternatives to AIS registration or authorisation?',
  'header-class': 'h4',
  'with-subtext': true,
  subtext:
    'For customers not yet ready to apply for licensing, it’s possible to provide AIS to your customers by becoming an agent of Plaid. You will need to complete our agent application process, and if approved, we will apply to the FCA to register you as our agent. Get in touch with us to discuss if you are eligible and we will take you through the process.',
  'subtext-class': 'regular-text',
  type: 'with-cta-buttons',
  'stacked-center': true,
  'cta-text': 'Contact sales',
  'cta-link': '//dashboard.plaid.com/contact',
  'with-background': true,
};

export const contentBlock2Data = {
  id: 'eidas-certification',
  title: 'IDENTITY CERTIFICATE',
  image: {
    src: '/assets/img/open-banking/eIDAS@2x.png',
    alt: 'Identity certification illustration',
  },
  heading: 'Who needs an eIDAS certificate or an Open Banking certificate?',
  paragraph: [
    'If you are accessing data from an ASPSP as a TPP, you need an eIDAS certificate to identify yourself. For example, when Plaid accesses an ASPSP using Open Banking APIs, we provide our eIDAS certificates to the ASPSP.',
    `However, the FCA <a class="signup-attach-utm" href="https://www.fca.org.uk/firms/strong-customer-authentication">issued a statement</a> on 17 January 2020 stating that “ASPSPs can also enable TPPs to use a certificate obtained from a provider of an API programme, so long as that provider only issues the alternative identification certificate to a TPP that has enrolled with the API programme using its eIDAS certificate to identify itself. The provider of the API programme should continue checking, on behalf of the ASPSP, the status of the TPP’s eIDAS certificate with the Qualified Trust Service Provider (QTSP).”`,
  ],
};

export const featuresData = {
  'feature-section-title': [
    {
      label: 'USE CASES',
      title: "Here's how Plaid can help you",
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/use-cases/pf-intl.png',
      title: 'Personal finances',
      'header-class': 'h4',
      content:
        'Help users improve their financial health with categorised transactions',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/personal-finances',
    },
    {
      img: '/assets/img/use-cases/bf-112.png',
      title: 'Business finances',
      'header-class': 'h4',
      content: 'Make it easy for everyone to manage business finances',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/business-finances',
    },
    {
      img: '/assets/img/use-cases/ls-112.png',
      title: 'Lending',
      'header-class': 'h4',
      content: 'A faster, more secure way for borrowers to access capital',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/lending',
    },
    {
      img: '/assets/img/use-cases/bbs-112.png',
      title: 'Banking and wealth',
      'header-class': 'h4',
      content:
        'Make it easy for people to interact with their bank accounts and investments',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/banking-and-wealth',
    },
    {
      img: '/assets/img/use-cases/cp-112.png',
      title: 'Payments',
      'header-class': 'h4',
      content:
        'Help users initiate payment transfers to fund their accounts, make online payments, and pay bills',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/payments',
    },
  ],
};

export const regulatoryData = {
  id: 'integrating-with-plaid',
  descriptionRow: {
    title: 'Plaid is regulated in the UK to provide access to financial data',
    body:
      'Plaid Financial Ltd. is authorised by the Financial Conduct Authority under the Payment Service Regulations 2017 [Firm Reference Number: <a class="signup-attach-utm" href="https://register.fca.org.uk/s/firm?id=0010X000049JuDPQA0"> <strong> 804718 </strong> </a>] for the provision of payment services.',
    image: '/assets/img/open-banking/aisp-scene.png',
    'standard-image': true,
    'row-reverse': false,
    'image-section-col-size': 4,
    'image-section-col-offset': 0,
    'text-section-col-size': 8,
  },
  supportingText: {
    'three-up': true,
    'six-up': true,
    header1: 'AIS',
    text1:
      'Account Information Service is an online service which provides consolidated information held by a payment service user with ASPSPs',
    header2: 'FCA',
    text2:
      'The Financial Conduct Authority is the conduct regulator for financial services firms and financial markets in the UK',
    header3: 'CMA9',
    text3:
      'The nine largest banks and building societies in the UK  and Northern Ireland, based on the volume of personal and business current accounts.',
    header4: 'TPP',
    text4:
      'Third Party Providers are firms that are regulated for, amongst other things, the provision of AIS  that use APIs developed to Standards to access customer’s accounts, in order to provide account information services and/or to initiate payments.',
    header5: 'NCA',
    text5:
      'National Competent Authorities are organisations that have the legally delegated or invested authority, or power to perform a designated function, normally monitoring compliance with the national statutes and regulations (e.g. the FCA in the UK).',
    header6: 'ASPSP',
    text6:
      'Account Servicing Payment Service Providers provide and maintain a payment account for a payment service users and, are entities that publish Read/Write APIs to permit, with customer consent, payments initiated by third party providers and/or make payment services users’’ account transaction data available to third party providers via their API endpoints e.g. a bank.',
  },
};
