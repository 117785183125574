import React from 'react';

const TwitterIcon = () => {
  return (
    <svg height={25} width={24} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.5 4.036a12 12 0 10-16.999 16.94 12 12 0 0017-16.94zm-3.625 6.045v.32a6.715 6.715 0 01-.165 1.5 7 7 0 01-7 5.54 7.225 7.225 0 01-3.854-1.11c.2.021.403.033.605.034a5.085 5.085 0 003.115-1.06A2.53 2.53 0 017.23 13.58c.154.036.312.055.47.055.232 0 .462-.034.685-.1a2.5 2.5 0 01-1.845-1.53 2.28 2.28 0 01-.175-.89v-.03a2.5 2.5 0 001.135.305 2.47 2.47 0 01-1.115-2.06 2.53 2.53 0 01.345-1.244 7.125 7.125 0 005.185 2.585 2.57 2.57 0 01-.075-.565 2.525 2.525 0 014.354-1.685 5.195 5.195 0 001.6-.605 2.5 2.5 0 01-1.11 1.375 5.24 5.24 0 001.44-.395 5.09 5.09 0 01-1.25 1.285z'
        fill='#9b9b9b'
      />
    </svg>
  );
};

export default TwitterIcon;
