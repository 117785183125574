import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'src/components/Layout/MediaObject/MediaObject.module.scss';

const MediaObjectPropTypes = {
  body: PropTypes.node,
  figure: PropTypes.node,
  canStackInDesktop: PropTypes.bool,
  canStackInMobile: PropTypes.bool,
};

const MediaObject = ({
  body,
  canStackInDesktop = false,
  canStackInMobile = true,
  figure,
}) => (
  <div
    className={cx(
      styles.mediaObject,
      {
        [styles.stackInDesktop]: canStackInDesktop,
        [styles.stackInMobile]: canStackInMobile,
      },
      'media-object',
    )}
  >
    <div className={styles.figure}>{figure}</div>
    <div className={styles.body}>{body}</div>
  </div>
);

MediaObject.propTypes = MediaObjectPropTypes;
export default MediaObject;
