/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the contentful response for diagram section content type to the view
 *
 * @param {Object} args
 * @param {string} args.heading
 * @param {Object[]} args.steps
 * @param {Object[]} args.flow
 * @param {string} args.brushStrokesColor
 * @returns {Object}
 */
export const diagramSectionAdapter = ({
  heading,
  steps,
  flow,
  brushStrokesColor: brushStrokesColorField,
}) => {
  const id = flow[0].sys.contentType.sys.id;
  const imageColor = flow[0].fields.color.toLowerCase();
  const largeBrushStrokes = ['pink'];
  const brushStrokesColor = brushStrokesColorField.toLowerCase();
  const isLargeBrushStroke = largeBrushStrokes.includes(brushStrokesColor);

  return {
    classes:
      'background background--color-black100 background--image-stroke-accents',
    'stroke-top-right': isLargeBrushStroke
      ? ''
      : `/assets/img/accents/diagram-brushstroke-${brushStrokesColor}-1@2x.png`,
    'stroke-bottom-left': isLargeBrushStroke
      ? ''
      : `/assets/img/accents/diagram-brushstroke-${brushStrokesColor}-2@2x.png`,
    'stroke-large-top-right': isLargeBrushStroke
      ? `/assets/img/accents/diagram-brushstroke-${brushStrokesColor}-1@2x.png`
      : '',
    'stroke-large-bottom-left': isLargeBrushStroke
      ? `/assets/img/accents/diagram-brushstroke-${brushStrokesColor}-2@2x.png`
      : '',
    heading,
    step: steps.map((item) => ({
      number: _get(item, 'fields.step'),
      copy: _get(item, 'fields.description'),
      delay: _get(item, 'fields.animationDelay'),
      'hr-color': 'black700',
    })),

    'icon-row-1': [
      {
        label:
          id === 'diagramLoanRequestFlow' ? 'LOAN REQUEST' : 'USER ONBOARDS',
        'label-delay': 1500,
        img:
          id === 'diagramLoanRequestFlow'
            ? `/assets/img/customer-stories/diagram-step-request-${imageColor}@2x.png`
            : `/assets/img/customer-stories/diagram-step-onboards-${imageColor}@2x.png`,
        'arrow-down': true,
        'arrow-down-delay': 300,
      },
    ],
    'icon-row-2': [
      {
        label: 'PLAID LINK',
        'label-delay': 1500,
        'icon-delay': 600,
        img:
          id === 'diagramLoanRequestFlow'
            ? `/assets/img/customer-stories/diagram-step-front-end-${imageColor}@2x.png`
            : `/assets/img/customer-stories/diagram-step-link-mobile-${imageColor}@2x.png`,
        'arrow-down': true,
        'arrow-down-delay': 900,
        'arrow-lateral': true,
        'arrow-lateral-delay': 600,
        'arrow-right': true,
        badge: 'lock',
      },
      {
        label: 'PLAID BACK-END',
        'label-delay': 1500,
        'icon-delay': 600,
        img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
        'arrow-lateral': true,
        'arrow-lateral-delay': 600,
        'arrow-left': true,
        'arrow-right': true,
      },
      {
        label: id === 'diagramLoanRequestFlow' ? 'PLAID BANK' : 'BANK',
        'label-delay': 1500,
        'icon-delay': 600,
        img: `/assets/img/customer-stories/diagram-step-bank-${imageColor}@2x.png`,
        badge: 'checkmark',
      },
    ],
    'icon-row-3': [
      {
        label:
          id === 'diagramLoanRequestFlow' ? 'LOAN DECISION' : 'BANK CONNECTED',
        'label-delay': 1500,
        'icon-delay': 1200,
        img:
          id === 'diagramLoanRequestFlow'
            ? `/assets/img/customer-stories/diagram-step-piggy-bank-${imageColor}@2x.png`
            : `/assets/img/customer-stories/diagram-step-piggy-bank-mobile-${imageColor}@2x.png`,
        badge: 'checkmark',
      },
    ],
  };
};
