import React from 'react';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import styles from './SocialProof.module.scss';

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const SocialProofLogo = ({ src, alt }) => (
  <div className='cell'>
    <div className={styles.logo}>
      <Image src={src} alt={alt} width={248} height={124} />
    </div>
  </div>
);

SocialProofLogo.propTypes = propTypes;
export default SocialProofLogo;
