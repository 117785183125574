import React from 'react';

import PropTypes, { string } from 'prop-types';

import DefaultTemplate from 'src/templates/default';

// helpers
import { Locales } from 'src/lib/constants';

const propTypes = {
  children: PropTypes.node.isRequired,
  metaData: PropTypes.shape({
    'meta-title': string.isRequired,
    'meta-description': string.isRequired,
  }),
};

const JobPostWrapper = ({ children, metaData }) => (
  <DefaultTemplate {...metaData} locale={Locales.EN_US}>
    <div className='job-post' data-id='job-post'>
      {children}
    </div>
  </DefaultTemplate>
);

JobPostWrapper.propTypes = propTypes;
export default JobPostWrapper;
