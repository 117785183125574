export const metaData = {
  metaTitle: 'Qapital',
  metaDescription:
    'By checking account balances before initiating money transfers, Plaid has helped Qapital members avoid more than $2.7 million in overdraft fees.',
};

export const pageHeaderData = {
  classes:
    'background background--color-yellow600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-multi-red-blue-2@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Qapital',
  header:
    'A path to money<br class="show-for-medium" /><span class="show-for-small-only"> </span>happiness',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/qapital-case-study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-qapital@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-qapital@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Connect user bank accounts,<br />prevent NSF/overdraft fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Personal financial management',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Qapital needed a swift, seamless way to connect to members’ bank accounts while protecting them from unwanted overdraft fees.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'By verifying account balances before initiating money transfers, Plaid has helped Qapital members avoid more than $2.7 million in overdraft fees.',
};

export const content1Data = {
  heading:
    'Once upon a time, saving toward specific goals like a vacation, home renovations, or a down payment meant opening an individual savings account for each goal.',
  paragraph: [
    'Qapital offers FDIC-insured checking and savings accounts, as well as savings tools rooted in behavioral psychology. The goal is that members spend confidently on things they love while still achieving their financial goals.',
    'It seems to be working. When members take advantage of Qapital’s full suite of tools (savings, checking, and investing), they save, on average, $5,000 per year. All together, Qapital has helped its 1.7 million members save more than $1 billion.',
    'Accomplishing that required an API that consistently connects to members’ bank accounts while protecting them from unwanted NSF/overdraft fees. From the beginning, Qapital has entrusted those functions to Plaid.',
  ],
};

export const supportingTextData = {
  classes:
    'background background--color-yellow200 supporting-text--constrained',
  'three-up': true,
  stat: true,
  stat1: '$1B',
  text1:
    'With Plaid, Qapital has helped its members save more than $1 billion.',
  stat2: '$2.7M',
  text2:
    'With Plaid, Qapital has helped its members avoid more than $2.7 million in overdraft fees.',
  stat3: '2Y',
  text3:
    "Plaid allows Qapital to access and analyze up to two years of users' transactions data.",
};

export const content2Data = {
  title: 'Don’t worry, spend happy',
  paragraph: [
    'Friedman and and technical co-founder Erik Akterin met Plaid’s co-founders when they were assigned adjacent booths at a tech conference in Ireland.',
    'Back then, both companies were still early-stage. Akterin took a break from the conference and quickly built an integration with Plaid’s API. In short order, he had used Plaid to transfer his personal banking data to the Qapital app. Qapital’s founders were so impressed that they became Plaid’s very first customers—a decision that has grown into a years-long partnership.',
    '“We worked closely with Plaid’s engineers to test and suggest patches early on,” said Nimrod Barnea, Vice President of Customer Experience at Qapital. “It allowed us to recognize the potential of Plaid as a tool and a partner.”',
    'To get started with Qapital, members enter basic identity information, connect a bank account, and choose the financial goals toward which they want to save. Then they create savings rules to fund those goals. For instance, they might choose to invest $200 in a retirement account every time the Giants win a baseball game. (More traditional options like direct deposit of wages are also available.)',
    '"Through Plaid, we can access up two years of data on how members are spending their money,” Barnea explained. “Many of our automated savings tools are triggered by certain kinds of spend, and they wouldn’t be possible without Plaid’s reliable data.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-yellow-1@2x.png',
  'stroke-bottom-left':
    '/assets/img/accents/diagram-brushstroke-yellow-2@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Qapital’s onboarding flow asks customers to link an outside bank account. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! Qapital’s customers can start funding their new accounts in a matter of seconds.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-onboards-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'Looking out for users',
    paragraph: [
      'Today, Qapital uses Plaid to do several things:',
      {
        list: [
          "Verify members' identities",
          'Instantly link members’ bank accounts',
          'Analyze transaction data to enable savings triggers',
          'Facilitate ACH transfers between accounts',
          'Check members’ account balances before initiating transfers',
        ],
      },
      'The need for that last item may not be obvious. Why spend money to check members’ account balances before initiating an ACH transfer?',
      'The answer is that when a member initiates a transfer without sufficient funds in the transferring account, it can result in one or more unappetizing fees. Qapital might be charged a non-sufficient funds (NSF) fee, while the member might be charged an overdraft fee. Beyond the monetary loss, an overdraft is a bad member experience, one that makes Qapital’s customers less likely to return.',
      'By instantly checking the balance in a members’ external account, Plaid helps Qapital bypass that problem. To date, Plaid has helped Qapital members avoid more than $2.7 million in overdraft fees.',
      'According to a leading research firm, more than a quarter of US consumers paid an overdraft fee in 2017, for a total of $34.3 billion. The average amount paid was $225. Those fees are damaging to members’ financial health, and helping to avoid them is an important part of Qapital’s mission.',
      '"Every person, regardless of their financial situation, can benefit from tools that reduce the mental effort of setting funds aside,” said Barnea. “One of our main goals is to remove the behavioral obstacles that make it difficult or painful to save.”',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-qapital@2x.png',
      alt:
        'Headshot of Nimrod Barnea, Vice President of Customer Experience at Qapital',
    },
    quote: [
      {
        copy:
          'Many of our automated savings tools are triggered by certain kinds of spend, and they wouldn’t be possible without Plaid.',
        source: 'Nimrod Barnea, Vice President of Customer Experience, Qapital',
      },
    ],
  },
  {
    title: 'The road ahead',
    paragraph: [
      'Barnea says Qapital has been pleased with the Plaid relationship, emphasizing that Plaid has been responsive, collaborative, and open to product suggestions.',
      '<strong>“We are always looking for more ways to get creative and help members reach their financial goals,” he said. “As we grow our product offering, Plaid will continue to have a very important supporting role in our innovation.”</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Brigit',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-brigit@2x.png',
        alt: 'Brigit - read full story',
      },
      excerpt: 'A responsible way to bridge through money shocks',
      url: '/customer-stories/brigit',
    },
    {
      name: 'Varo',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-varo@2x.png',
        alt: 'Varo - read full story',
      },
      excerpt: 'A bank that builds financial health',
      url: '/customer-stories/varo',
    },
    {
      name: 'Prosper',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-prosper@2x.png',
        alt: 'Prosper - read full story',
      },
      excerpt: 'Helping people invest in each other',
      url: '/customer-stories/prosper',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/qapital-case-study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
