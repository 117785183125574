import React from 'react';

const ArrowRight = () => {
  return (
    <svg height={13} width={17} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.149 7.894H.554v-2h6.647L4.214.85h2.29l3.552 6-3.552 6h-2.29zM10.214.85h2.29l3.552 6-3.552 6h-2.29l3.553-6z'
        fill='#9b9b9b'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default ArrowRight;
