import _get from 'lodash/get';

import { CmsComponents, Contentful } from 'src/lib/constants';
import { PageComponents } from 'src/lib/ts-types';

const hasPaywallEntry = (component) => {
  return _get(component, Contentful.CONTENT_TYPE_ID) === CmsComponents.PAYWALL;
};

type PaywallHook = {
  hasPaywall: boolean;
  visibleComponents: PageComponents[];
  hiddenComponents: PageComponents[];
  paywallComponent: PageComponents;
};

// we split the array of components into two buckets
// when the editorial gate content entry is present
// one bucket of components we display and the other we do not
export const usePaywall = (components: PageComponents[]): PaywallHook => {
  let hiddenComponents = [];
  let visibleComponents = components;
  let paywallComponent = null;
  const hasPaywall = components.some(hasPaywallEntry);
  if (hasPaywall) {
    const paywallIdx = components.findIndex(hasPaywallEntry);
    visibleComponents = components.slice(0, paywallIdx);
    hiddenComponents = components.slice(paywallIdx + 1);
    paywallComponent = components[paywallIdx];
  }

  return {
    hasPaywall,
    visibleComponents,
    hiddenComponents,
    paywallComponent,
  };
};
