import { Locales } from './constants';
import { mutateHardCodedLinks } from './utils';

/**
 * @deprecated Warning! Using this function will increase the bundle size for any pages that use it
 * (because of its usage of dynamic imports within, which will force Webpack
 * to include _all_ code in `src/store`).
 * Do not use this for new code, and instead use other techniques
 * for localizing content that does not rely on dynamic imports.
 * Grep `formPerLocale` or `mutateHardCodedLinks` for examples.
 */
export const dangerous_getStaticData = ({
  locale,
  filePath,
}: {
  locale: string;
  filePath: string;
}) => {
  let data;
  try {
    if (locale === Locales.EN_US) {
      data = require(`src/store/${filePath}`);
    } else if (locale) {
      data = require(`src/store/${locale}/${filePath}`);
    } else {
      // it's always safer to try and fallback to U.S. strings since this is the source of truth
      data = require(`src/store/${filePath}`);
    }
  } catch (error) {
    // it's always safer to try and fallback to U.S. strings
    data = require(`src/store/${filePath}`);
    return mutateHardCodedLinks({ data, locale: locale || Locales.EN_US });
  }
  return mutateHardCodedLinks({ data, locale });
};
