import React from 'react';
import { Stack } from 'src/components-v2/Layout';
import Link from 'src/components-v2/Inputs/Link/Link';
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { CmsMediaSwitch } from 'src/components-v2/Media';
import { removeHrefFromRichTextButtonContent } from 'src/lib/utils';
import { useIsDarkBackground } from 'src/contexts';

// TODO: https://plaidinc.atlassian.net/browse/SITE-5123
const CtaBlockWithImage = ({ node, href }) => {
  const fields = node?.data?.target?.fields;
  const contentWithHrefRemoved = removeHrefFromRichTextButtonContent(
    fields?.body?.content,
  );
  const isDarkBackground = useIsDarkBackground();
  return (
    <ConditionalWrapper
      condition={href}
      wrapper={(descendants) => {
        return (
          <Link href={href}>
            <a>{descendants}</a>
          </Link>
        );
      }}
    >
      <Stack
        className='cta-block'
        flexDirection='row'
        gap={3}
        sx={{
          padding: '2.0rem 0 0',
          borderTop: 'solid 1px',
          borderColor: isDarkBackground ? 'black900' : 'black400',
          '& img': {
            maxWidth: { xs: 'none', sm: 'none', md: '100%' },
          },
          ...fields?.styles,
        }}
      >
        {fields?.asset && (
          <CmsMediaSwitch
            asset={fields?.asset?.fields}
            width={fields?.asset?.fields?.width}
            height={fields?.assets?.fields?.height}
            queryString={{
              xs: '?fm=webp&w=108&q=60',
              sm: '?fm=webp&w=108&q=60',
              md: '?fm=webp&w=280&q=60',
              lg: '?fm=webp&w=280&q=60',
            }}
            sx={{
              width: {
                xs: '54px',
                md: fields?.asset?.fields?.width || '140px',
              },
            }}
            sys={fields?.asset?.sys}
          />
        )}
        <Stack alignItems='flex-start' justifyContent={'center'}>
          <RichTextSwitch
            sys={node?.data?.target?.sys}
            content={{ ...fields?.body, content: contentWithHrefRemoved }}
            nodes={{
              p: {
                variant: 'p2',
                fieldId: 'body',
              },
            }}
          />
        </Stack>
      </Stack>
    </ConditionalWrapper>
  );
};

export default CtaBlockWithImage;
