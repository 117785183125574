/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

import {
  NEXT_IS_SERVER,
  NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
  NEXT_PUBLIC_SENTRY_DSN,
  PLAID_COMMIT,
} from 'src/env';
import { isProduction } from 'src/lib/constants';

const makeIntegrations = () => {
  const integrations = [];
  if (NEXT_IS_SERVER === 'true' && NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///',
          );
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      }),
    );
  }
  return integrations;
};

export const init = (): void => {
  const integrations = makeIntegrations();
  if (NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      enabled: isProduction,
      integrations,
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      release: PLAID_COMMIT,
    });
  }
};
