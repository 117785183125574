import React from 'react';

const SidebarConsumersIcon = () => {
  return (
    <svg height={15} width={16} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 7.75a3.75 3.75 0 003.75-3.69V4a3.75 3.75 0 00-7.5-.06V4A3.75 3.75 0 008 7.75zM5.75 4a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm10 8v3h-1.5v-3c0-1.21-.24-2.14-3.18-2.21L8.55 12.5a.77.77 0 01-1.1 0L4.93 9.75c-2.94.07-3.18 1-3.18 2.21v3H.25V12c0-3.22 2.54-3.67 4.76-3.71a1.33 1.33 0 011 .41l2 2.22 2-2.22a1.28 1.28 0 011-.41c2.2 0 4.74.45 4.74 3.71z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default SidebarConsumersIcon;
