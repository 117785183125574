import React, { cloneElement, isValidElement, useState } from 'react';
import { string } from 'yup';

// components
import {
  SelectWithController,
  TextArea,
  TextInput,
} from 'src/components/Inputs';
import { Btn as NewButton } from 'src/components-v2/Inputs';
import { MarketoForm, GdprFields } from 'src/components/Forms';
import LeadFormWrapper from './LeadFormWrapper';

import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { Grid, Column } from 'src/components/Layout/Grid';

// constants
import { LeadForm as LeadFormProps } from './LeadForm.types';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getRequiredMessage, mutateHardCodedLinks } from 'src/lib/utils';

import { Locales } from 'src/lib/constants';

import * as enUsForm from 'src/store/form';
import * as enEuForm from 'src/store/en-eu/form';
import * as enGbForm from 'src/store/en-gb/form';

const formPerLocale = {
  [Locales.EN_US]: enUsForm,
  [Locales.EN_EU]: enEuForm,
  [Locales.EN_GB]: enGbForm,
};

const LeadForm = ({
  isDarkMode = false,
  id = 'contactForm',
  'include-phone': includePhone = false,
  'action-text': actionText = 'Contact sales',
  emailLabelOverride = 'Company email',
  companyLabelOverride = 'Company name',
  'marketo-key': marketoKey,
  includeCompany = true,
  includeJobTitle = false,
  includeCommentsField = false,
  includeDefaultFields = true,
  includeNames = true,
  includePxFieldset = false,
  children = null,
  disclaimer = '',
  trackingId = 'MARKETO_FORM',
  postSubmitContent = null,
  hasOnPagePostSubmitContent = false,
  isBodyForm = false,
  shouldForward = true,
  marketoFormBtnUrl,
  redirectOnSubmit,
  redirectUrl,
  formType,
  backgroundColor = 'black1000',
  body = null,
  backgroundImageColor = '',
  formTitle = '',
  backgroundImage = null,
  backgroundMono,
}: LeadFormProps): React.ReactElement => {
  const locale = usePageLocale();
  const { formData } = mutateHardCodedLinks({
    data: formPerLocale[locale] || formPerLocale[Locales.EN_US],
    locale,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const shouldRenderPostSubmitContent =
    hasOnPagePostSubmitContent && isFormSubmitted;
  // inject children with props for use in form
  const childrenWithProps = ({ control, errors, register, setValue }) => {
    return React.Children.map(children, (child) => {
      // checking if child is invalid for safety, also avoids a typescript error
      if (!isValidElement(child)) {
        return child;
      }
      const props = {
        register: register,
        required: child.props.errorMessage,
        errorMessage: errors[child.props.name]?.message,
        control: control,
        setValue: setValue,
        isDarkMode: isDarkMode,
      };
      // Note: this is will be deprecated with SITE-2278
      return cloneElement(child, props);
    });
  };

  return (
    <LeadFormWrapper
      {...{
        backgroundColor,
        body,
        backgroundImageColor,
        formTitle,
        backgroundImage,
        backgroundMono,
        id,
      }}
    >
      <>
        {shouldRenderPostSubmitContent ? (
          postSubmitContent && postSubmitContent
        ) : (
          <MarketoForm
            {...{
              actionText,
              companyLabelOverride,
              emailLabelOverride,
              children,
              includeDefaultFields,
              includePhone,
              isDarkMode,
              marketoKey,
              trackingId,
              locale,
            }}
            formType={formType}
            redirectUrl={
              // we don't pass a url if the form should stay on the page
              // otherwise we try to pass the redirect url from gated content
              // or the marketo form button so that the user gets a predictable experience
              hasOnPagePostSubmitContent
                ? undefined
                : redirectOnSubmit
                ? redirectUrl
                : marketoFormBtnUrl
            }
            shouldForward={shouldForward}
            onSubmit={() => {
              setIsFormSubmitted(true);
            }}
            render={({ control, register, errors, setValue }) => {
              return (
                <>
                  {includeDefaultFields && (
                    <>
                      {includeNames && (
                        <div className='grid-container full'>
                          <div className='grid-x form-group'>
                            <div className='cell small-12 medium-6'>
                              <TextInput
                                label={formData.firstName.label}
                                id={formData.firstName.name}
                                name={formData.firstName.name}
                                describedby={formData.firstName.describedby}
                                isDarkMode={isDarkMode}
                                errorMessage={
                                  errors?.[formData.firstName.name]?.message
                                }
                                register={register}
                                required={getRequiredMessage(
                                  formData.firstName.label,
                                )}
                                autoComplete={formData.firstName.autoComplete}
                              />
                            </div>
                            <div className='cell small-12 medium-6'>
                              <TextInput
                                label={formData.lastName.label}
                                id={formData.lastName.name}
                                name={formData.lastName.name}
                                describedby={formData.lastName.describedby}
                                isDarkMode={isDarkMode}
                                errorMessage={
                                  errors?.[formData.lastName.name]?.message
                                }
                                register={register}
                                required={getRequiredMessage(
                                  formData.lastName.label,
                                )}
                                autoComplete={formData.lastName.autoComplete}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <TextInput
                        label={emailLabelOverride}
                        id={formData.email.name}
                        name={formData.email.name}
                        describedby={formData.email.describedby}
                        isDarkMode={isDarkMode}
                        errorMessage={errors?.Email?.message}
                        register={register}
                        pattern={{
                          value: string().email().tests[0].OPTIONS.params.regex,
                          message: formData.email.invalidMessage,
                        }}
                        required={getRequiredMessage(formData.email.label)}
                        autoComplete={formData.email.autoComplete}
                      />
                      {includeCompany && (
                        <TextInput
                          label={companyLabelOverride}
                          id={formData.company.name}
                          name={formData.company.name}
                          describedby={formData.company.describedby}
                          isDarkMode={isDarkMode}
                          errorMessage={
                            errors?.[formData.company.name]?.message
                          }
                          register={register}
                          required={getRequiredMessage(formData.company.label)}
                          autoComplete={formData.company.autoComplete}
                        />
                      )}
                      {/*
                              TODO: Threads phone input. Currently bugged:
                              https://github.plaid.com/pages/plaid/threads/?path=/story/inputs-phone-input--basic-example
                            */}
                      {includePhone && (
                        <TextInput
                          label={formData.phone.label}
                          id={formData.phone.name}
                          name={formData.phone.name}
                          describedby={formData.phone.describedby}
                          isDarkMode={isDarkMode}
                          errorMessage={errors?.[formData.phone.name]?.message}
                          register={register}
                          autoComplete={formData.phone.autoComplete}
                        />
                      )}

                      {includePxFieldset && (
                        <fieldset>
                          <SelectWithController
                            {...formData.categoryInput}
                            errorMessage={
                              errors?.[formData.categoryInput?.name]?.message
                            }
                            required={getRequiredMessage(
                              formData.categoryInput?.label,
                            )}
                            isDarkMode={isDarkMode}
                            control={control}
                            register={register}
                            setValue={setValue}
                          />
                          <TextInput
                            {...formData.coreProviderInput}
                            errorMessage={
                              errors?.[formData.coreProviderInput?.name]
                                ?.message
                            }
                            required={getRequiredMessage(
                              formData.coreProviderInput?.label,
                            )}
                            isDarkMode={isDarkMode}
                            register={register}
                          />
                          <TextInput
                            {...formData.bankingProviderInput}
                            errorMessage={
                              errors?.[formData.bankingProviderInput?.name]
                                ?.message
                            }
                            required={getRequiredMessage(
                              formData.bankingProviderInput?.label,
                            )}
                            isDarkMode={isDarkMode}
                            register={register}
                          />
                        </fieldset>
                      )}

                      {includeJobTitle && (
                        <TextInput
                          label={formData.jobTitle?.label}
                          id={formData.jobTitle?.name}
                          name={formData.jobTitle?.name}
                          describedby={formData.jobTitle?.describedby}
                          errorMessage={
                            errors?.[formData.jobTitle?.name]?.message
                          }
                          register={register}
                          required={getRequiredMessage(
                            formData.jobTitle?.label,
                          )}
                          autoComplete={formData.jobTitle?.autoComplete}
                          isDarkMode={isDarkMode}
                        />
                      )}
                      {includeCommentsField && (
                        <TextArea
                          {...formData.comments}
                          isDarkMode={isDarkMode}
                          errorMessage={
                            errors?.[formData.comments?.name]?.message
                          }
                          required={getRequiredMessage(
                            formData.comments?.label,
                          )}
                          register={register}
                        />
                      )}
                    </>
                  )}
                  {childrenWithProps({
                    control,
                    register,
                    errors,
                    setValue,
                  })}
                  <GdprFields
                    control={control}
                    errors={errors}
                    isDarkMode={isDarkMode}
                    register={register}
                    setValue={setValue}
                  />
                  <Grid
                    noPadding
                    noMarginRightMedium
                    noMarginRightLarge
                    useThreadsGrid
                  >
                    <Column medium={isBodyForm ? 6 : 12} allowOverflow>
                      <NewButton
                        sx={{ width: '100%', mb: '3.4rem' }}
                        type='submit'
                        value={actionText}
                      >
                        {actionText}
                      </NewButton>
                    </Column>
                  </Grid>
                  {disclaimer ? (
                    typeof disclaimer === 'string' ? (
                      <p
                        data-testid='disclaimer'
                        className='contact-form__disclaimer'
                        dangerouslySetInnerHTML={{
                          __html: disclaimer,
                        }}
                      />
                    ) : (
                      <RichTextRenderer content={disclaimer} />
                    )
                  ) : // Don't use fallback disclaimer text on Body Form CTA instances.
                  !isBodyForm ? (
                    <p
                      data-testid='disclaimer'
                      className='contact-form__disclaimer'
                      dangerouslySetInnerHTML={{
                        __html: formData.disclaimer,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </>
              );
            }}
          />
        )}
      </>
    </LeadFormWrapper>
  );
};

export default LeadForm;
