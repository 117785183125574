export const noJSBannerData = {
  bannerTitle: 'FYI',
  bannerText: 'This site requires Javascript for full functionality',
  bannerName: 'NoJSBanner',
};

export const cclPromoBannerData = {
  bannerTitle: 'FOR CONSUMERS',
  bannerText: 'Learn how Plaid powers the apps in your financial life.',
  bannerLink: '/how-it-works-for-consumers',
  bannerName: 'CCLBanner',
  isClosable: true,
  isHiddenInEEA: true,
};
