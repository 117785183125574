import React, { FC } from 'react';
import styles from './ShareBtns.module.scss';
import cx from 'classnames';
import { Link } from 'src/components/Inputs';
import FacebookIcon from 'src/components/SVGs/Blog/FacebookIcon';
import TwitterIcon from 'src/components/SVGs/Blog/TwitterIcon';
import LinkedinIcon from 'src/components/SVGs/Blog/LinkedinIcon';

interface Props {
  title: string;
  location: string;
  showTitle?: boolean;
  className?: string;
}

const ShareBtns: FC<Props> = ({
  title,
  location,
  showTitle = true,
  className,
}) => {
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${location}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURI(
    title,
  )}%20${encodeURI(location)}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
    location,
  )}&title=${encodeURI(title)}`;

  return (
    <div className={cx(styles.shareBtns, className)}>
      {showTitle && <p className={styles.shareBtnsTitle}>SHARE</p>}
      <Link href={facebookShareUrl}>
        <a className={styles.shareBtn} target='_blank' rel='noreferrer'>
          <FacebookIcon />
        </a>
      </Link>
      <Link href={twitterShareUrl}>
        <a className={styles.shareBtn} target='_blank' rel='noreferrer'>
          <TwitterIcon />
        </a>
      </Link>
      <Link href={linkedInShareUrl}>
        <a className={styles.shareBtn} target='_blank' rel='noreferrer'>
          <LinkedinIcon />
        </a>
      </Link>
    </div>
  );
};

export default ShareBtns;
