import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { nanoid } from 'nanoid';
import { Stack } from 'src/components-v2/Layout';
import Icon from 'threads5/Icon';

import styles from './TextArea.module.scss';

import { InputProps } from '../TextInput/TextInput';

const TextArea: FC<InputProps> = ({
  className = styles.textArea,
  isDarkMode,
  pattern,
  register = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  required,
  value = '',
  onChange = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const id = restProps?.id ? `${restProps.id}-${nanoid()}` : undefined;
  return (
    <div style={{ position: 'relative' }}>
      <label
        htmlFor={id}
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          padding: '0',
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          whiteSpace: 'nowrap',
          border: '0',
        }}
      >
        {restProps?.label}
      </label>
      <textarea
        style={{ height: '143px' }}
        id={id}
        className={cx(
          className,
          isDarkMode && styles.textAreaDark,
          restProps.errorMessage && styles.error,
        )}
        ref={register({
          ...(required && { required }),
          ...(pattern && { pattern }),
        })}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e);
        }}
        name={restProps?.name}
        placeholder={restProps?.placeholder}
      >
        {inputValue}
      </textarea>
      {restProps.errorMessage && (
        <Stack gap={1} direction='row' alignItems='center' sx={{ mb: 3 }}>
          <Icon name='AlertError' color='#f44e66' />
          <p style={{ margin: 0 }}>{required}</p>
        </Stack>
      )}
    </div>
  );
};

TextArea.displayName = 'TextInput';
export default TextArea;
