import React from 'react';
import { Box } from 'src/components-v2/Layout';
import ThemeProvider from 'threads5/themes/theme-provider';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import {
  ModeContextProvider,
  useCmsMode,
} from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

export const BackgroundContainer = ({ fields, sys, ...props }) => {
  const mode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: fields?.backgroundColor,
  });

  const componentTheme = useComponentTheme(mode);

  // NOTE: we have to key into the theme because for whatever reason tokens are working on the background css property
  const background =
    componentTheme.palette[fields?.backgroundColor] ||
    fields.backgroundColor?.toLowerCase() ||
    'transparent';

  // we naively override child background color and mode
  // this will only update the first level of components
  // most children will/should override bg color and mode
  const components = fields?.components?.map((component) => {
    return {
      ...component,
      fields: {
        ...component.fields,
        backgroundColor: 'transparent',
        mode,
      },
    };
  });

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  return (
    <ModeContextProvider mode={mode}>
      <ThemeProvider theme={componentTheme}>
        <Box sx={{ background }} {...inspectorProps({ fieldId: 'components' })}>
          <CmsComponentSwitch {...props} components={components} />
        </Box>
      </ThemeProvider>
    </ModeContextProvider>
  );
};
