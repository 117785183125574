import React, { FC } from 'react';
import cx from 'classnames';
import CodeBlockHighlighted from 'plaid-threads/CodeBlockHighlighted';
import { Language } from 'prism-react-renderer';
import styles from './CodeSnippet.module.scss';
import { Section } from 'src/components-v2/Layout';

interface CodeSnippetComponentProps {
  syntax: Language | 'ruby' | 'java';
  children: string;
  className?: string;
  title?: string;
  noBottomMargin?: boolean;
  theme?: 'homepage';
}

const CodeSnippet: FC<CodeSnippetComponentProps> = ({
  className = '',
  syntax,
  children = '',
  title = '',
  noBottomMargin,
  theme = '',
}) => {
  return (
    <Section component='figure'>
      <CodeBlockHighlighted
        {...{ title }}
        className={cx(styles.overrides, className, {
          [styles.marginBottom]: !noBottomMargin,
          [styles[theme]]: !!theme,
        })}
        code={children}
        lang={syntax}
      />
    </Section>
  );
};

export default CodeSnippet;
