import React from 'react';
import { CmsCtaBanner } from './CmsCtaBanner';
import { CmsCtaAssetBanner } from './CmsCtaAssetBanner';
import { CmsCtaHeaderBanner } from './CmsCtaHeaderBanner';

export const CmsCtaSwitch = ({ fields, sys }) => {
  const appearance = fields?.appearance;
  switch (appearance) {
    case 'Asset variation':
      return <CmsCtaAssetBanner fields={fields} sys={sys} />;
    case 'Header variation':
      return <CmsCtaHeaderBanner fields={fields} sys={sys} />;
    default:
      return <CmsCtaBanner fields={fields} sys={sys} />;
  }
};
