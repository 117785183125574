import { useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';

// helper
import { useUserLocale } from 'src/hooks';
import { usePageLocale } from 'src/contexts';
import { fetchLocalizedEquivalentPage } from 'src/lib/utils';

// reducer
import { reducer, Actions } from './reducer';

const useForeignEquivalentPage = () => {
  const pageLocale = usePageLocale();
  const { asPath, basePath } = useRouter();
  const { locale: userLocale } = useUserLocale();
  const [state, dispatch] = useReducer(reducer, {
    localizedEquivalent: '',
  });

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const localizedEquivalent = await fetchLocalizedEquivalentPage({
        asPath,
        userLocale: userLocale?.localeCode,
        pageLocale,
        basePath,
      });

      // only update state if component is mounted
      if (isMounted) {
        dispatch({
          type: Actions.setLocalizedEquivalent,
          payload: localizedEquivalent,
        });
      }
    };
    fetchData();

    return () => {
      // when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [asPath, basePath, pageLocale, userLocale]);

  return state;
};

export default useForeignEquivalentPage;
