import * as React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Wrapper } from 'src/containers';
import { Column, Box, Stack } from 'src/components-v2/Layout';
import { ImageProps, VideoProps, LottieProps } from 'src/lib/ts-types';
import { MediaSwitch } from 'src/components-v2/Media';

interface ISolutionsHeroProps {
  bottom: ImageProps | VideoProps | LottieProps;
  left: React.ReactElement;
  right?: React.ReactElement;
  sx?: SxProps<Theme>;
}

// TODO: Delete this file when Solutions pages are migrated to CMS
const SolutionsHero: React.FunctionComponent<ISolutionsHeroProps> = ({
  bottom,
  left,
  right,
  sx,
}) => {
  const leftColSize = {
    lg: !!right ? 13 : 20,
  };
  return (
    <>
      <Wrapper component='header' sx={sx}>
        <Column {...leftColSize} lgOffset={2} xsOffset={1}>
          <Stack
            justifyContent='center'
            sx={{
              height: '100%',
              '& h1': {
                mb: { xs: '3.6rem', sm: '4.3rem', md: ' 2.8rem' },
              },
              '& h2': {
                mb: '0.8rem',
              },
            }}
          >
            {left}
          </Stack>
        </Column>
        {right && (
          <>
            <Column lg={1} sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Stack sx={{ height: '100%' }}>
                <Box
                  sx={{
                    width: '1px',
                    bgcolor: 'black600',
                    height: '100%',
                    margin: '0 0 0 37%',
                  }}
                />
              </Stack>
            </Column>
            <Column xsOffset={1} xs={22} lgOffset={0} lg={8} sx={{}}>
              <Stack
                flexDirection='column'
                justifyContent='center'
                sx={{
                  height: '100%',
                  '& p': {
                    mb: { xs: '4.6rem', md: '3.8rem' },
                  },
                }}
              >
                {right}
              </Stack>
            </Column>
          </>
        )}
      </Wrapper>
      <MediaSwitch asset={{ ...bottom }} />
    </>
  );
};

export default SolutionsHero;
