import React, { FC } from 'react';
import { Label, Props } from 'src/components/Surfaces/Label';

const BetaLabel: FC<Props> = () => (
  <Label attention small solid>
    Beta
  </Label>
);

export default BetaLabel;
