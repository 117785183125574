import React, { FC } from 'react';
import Image from 'src/components/Image';
import styles from './Header.module.scss';

interface Props {
  src?: string;
  alt?: string;
}

const Header: FC<Props> = ({ src, alt }) =>
  src ? (
    <Image className={styles.header} src={src} alt={alt} isLazy={false} />
  ) : (
    <div className={styles.default} />
  );

export default Header;
