import React, { useContext, useState } from 'react';
import Gear from 'plaid-threads/Icons/Gear';

// components
import { Button, IconButton, Checkbox } from 'src/components/Inputs';
import { Card, usePaywallProvider } from 'src/components/Surfaces';

// helpers
import { PreviewContext } from 'src/contexts';
import { useOutsideClick } from 'src/hooks';

import styles from './ContentfulToolbar.module.scss';

const ContentfulToolbar = () => {
  const previewContext = useContext(PreviewContext);
  const paywallContext = usePaywallProvider();
  const [state, setState] = useState({
    isOpen: false,
    settings: {},
  });

  const toggleSettings = () => {
    setState((state) => ({ ...state, isOpen: !state.isOpen }));
  };
  const outsideClickRef = useOutsideClick(toggleSettings);

  const handleCheckbox = (e) => {
    setState((state) => ({
      ...state,
      settings: {
        ...state.settings,
        [e.target.name]: e.target.checked,
      },
    }));
  };

  const applyChanges = () => {
    const { settings } = state;
    if (settings?.resetPaywall) {
      paywallContext?.resetPaywall();
    }
    if (settings?.clearPreview) {
      window.location.replace('/api/clear-preview-mode-cookies');
    }
    setState((state) => ({ ...state, isOpen: false }));
  };

  if (previewContext?.preview) {
    return (
      <>
        <IconButton
          className={styles.settingsBtn}
          accessibilityLabel='Contentful Settings'
          icon={<Gear />}
          onClick={toggleSettings}
        />
        {state.isOpen && (
          <div className={styles.cardWrapper} ref={outsideClickRef}>
            <Card
              className={styles.previewCard}
              leadingObject={<h4>Contentful Settings</h4>}
              body={
                <>
                  <Checkbox
                    id='clearPreview'
                    name='clearPreview'
                    onChange={(e) => handleCheckbox(e)}
                    value={state.settings?.['clearPreview'] || false}
                  >
                    Clear Preview
                  </Checkbox>
                  {paywallContext.hasPaywall && (
                    <Checkbox
                      id='resetPaywall'
                      name='resetPaywall'
                      value={state.settings?.['resetPaywall'] || false}
                      onChange={(e) => handleCheckbox(e)}
                    >
                      Reset Paywall
                    </Checkbox>
                  )}
                </>
              }
              footer={
                <div className={styles.buttonGroup}>
                  <Button
                    className={styles.closeBtn}
                    onClick={toggleSettings}
                    secondary
                    small
                  >
                    Close
                  </Button>
                  <Button
                    className={styles.applyBtn}
                    onClick={applyChanges}
                    primary
                    small
                  >
                    Apply
                  </Button>
                </div>
              }
            />
          </div>
        )}
      </>
    );
  }

  return null;
};

export default ContentfulToolbar;
