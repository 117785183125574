import { mutateHardCodedLinks } from 'src/lib/utils';
import { Locales } from 'src/lib/constants';

import * as enUsForm from 'src/store/form';
import * as enEuForm from 'src/store/en-eu/form';
import * as enGbForm from 'src/store/en-gb/form';

const formPerLocale = {
  [Locales.EN_US]: enUsForm,
  [Locales.EN_EU]: enEuForm,
  [Locales.EN_GB]: enGbForm,
};
export const getFormData = ({ locale }) => {
  const { partnerFormData } = mutateHardCodedLinks({
    data: formPerLocale[locale] || formPerLocale[Locales.EN_US],
    locale,
  });
  return Object.assign({}, partnerFormData, {
    marketoKey: 2007, // confirmed this new marketo id with Shawn Aguilar on 1/23/2023
    actionText: 'Submit',
  });
};
