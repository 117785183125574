import React from 'react';

const SidebarChangelogIcon = () => {
  return (
    <svg height={14} width={19} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 .25v1.5H9V.25zM9 7.75h10v-1.5H9zm0 6h10v-1.5H9zm-6 0h2v-1.5H3zm1.75-6.56V3c0-.69.56-1.25 1.25-1.25h.89V.25H6A2.75 2.75 0 003.25 3v4.19L1.53 5.47.47 6.53 4 10.06l3.53-3.53-1.06-1.06z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default SidebarChangelogIcon;
