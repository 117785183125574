import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'src/components/Inputs';

const propTypes = {
  'bg-color': PropTypes.string,
  'footer-title': PropTypes.string,
  'primary-cta-url': PropTypes.string,
  'primary-cta-text': PropTypes.string,
  primaryCtaTrackingId: PropTypes.string,
  'secondary-cta-url': PropTypes.string,
  'secondary-cta-text': PropTypes.string,
  secondaryCtaTrackingId: PropTypes.string,
};
const FooterExtension = ({
  primaryCtaTrackingId = '',
  secondaryCtaTrackingId = '',
  ...props
}) => (
  <React.Fragment>
    <section
      className={`footer-extension footer-extension--${
        props['bg-color'] ? props['bg-color'] : ''
      }`}
    >
      <div className='grid-container'>
        <section className='grid-x grid-margin-x footer-cta'>
          <div className='cell small-12 medium-4 large-6 grid-x grid-margin-x'>
            <h2 className='cell footer-extension__title'>
              {props['footer-title']
                ? props['footer-title']
                : 'What will you build?'}
            </h2>
          </div>
          <div className='cell medium-2 show-for-medium-only'></div>
          <div className='cell medium-6 small-12'>
            <div className='grid-x grid-margin-x button-container'>
              <div className='cell small-12 medium-12 large-6 footer-extension__button'>
                <Button
                  href={props['primary-cta-url']}
                  className='button signup-attach-utm'
                  invertedColors={props['bg-color'] === 'bg-black'}
                  trackingId={primaryCtaTrackingId}
                  fullWidth
                >
                  {props['primary-cta-text']}
                </Button>
              </div>
              <div className='cell small-12 medium-12 large-6 footer-extension__button'>
                <Button
                  href={props['secondary-cta-url']}
                  className='button signup-attach-utm'
                  invertedColors={props['bg-color'] === 'bg-black'}
                  trackingId={secondaryCtaTrackingId}
                  secondary
                  fullWidth
                >
                  {props['secondary-cta-text']}
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </React.Fragment>
);

FooterExtension.propTypes = propTypes;

export default FooterExtension;
