import { RichTextContent } from 'contentful';

import { ImageShape } from 'src/lib/ts-types';
import { FormTypes } from 'src/lib/constants';

export enum CtaTypes {
  WITH_BUTTONS = 'with-cta-buttons',
}

export interface Cta {
  'action-text'?: string; // form submit button label
  'cta-link'?: string; // @codeSmell SITE-1988 only visible when stacked-center is true
  'cta-text'?: string; // @codeSmell SITE-1988 only visible when stacked-center is true
  'header-class'?: string; // a hook for applying custom classes to the H2 element
  'include-phone'?: boolean; // toggles optional form element
  'marketo-key': number; // marketoKey to be passed to MarketoForm for submission
  'stacked-center'?: boolean; // centers the title and subtext, but without a form. TODO: https://jira.plaid.com/browse/SITE-1988
  'subtext-class'?: string; // @codeSmell when set to 'regular-text' will render a <p> element, otherwise falls back to <h4> with the custom classes applied
  'with-background'?: boolean; // @codeSmell enables setting a background image, though not as intuitive as one would expect TODO: https://jira.plaid.com/browse/SITE-1989
  'with-subtext'?: boolean; // toggles the subtext
  background?: boolean; // @codeSmell will render a background image, but it's not clear how.  Likely requires additional custom CSS
  bullets?: string[]; // optional bullet points beside form
  children?: React.ReactNode; // additional fields to include in the MarketoForm
  companyLabelOverride?: string; // override default field labels
  disclaimer?: string | RichTextContent;
  emailLabelOverride?: string; // override default field labels
  formType?: FormTypes;
  hasOnPagePostSubmitContent?: boolean;
  id?: string; // id for use as anchor
  image?: ImageShape;
  includeCommentsField?: boolean; // determines whether comments field will be included
  includeCompany?: boolean; // determines whether company field will be included
  includeDefaultFields?: boolean; // determines whether default set of fields will be included
  includeJobTitle?: boolean; // determines whether job title field will be included
  includeNames?: boolean; // determines name fields will be included
  includePxFieldset?: boolean; // determines whether Plaid Exchange fieldset will be included
  isBodyForm?: boolean;
  isDarkMode?: boolean; // sets background color to black1000 when true
  isNewGrid?: boolean; // will make the grid container wider
  marketoFormBtnUrl?: string;
  postSubmitContent?: React.ReactNode;
  redirectOnSubmit?: boolean;
  redirectUrl?: string;
  shouldForward?: boolean;
  subtext?: string;
  title: string;
  trackingId?: string;
  type?: CtaTypes; // @codeSmell only when set to 'with-cta-buttons' will this render CTA buttons, otherwise renders a form
}
