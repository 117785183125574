export const intro = {
  title: 'Developer Policy',
  effectiveDate: 'Effective Date: December 31, 2021',
  description: [
    `This Developer Policy ("Policy") provides rules and
  guidelines that govern access to or use by our developers (“you”
  or “your”) of the Plaid API, websites (“Site”), dashboards,
  related tools, and other products or services (collectively, the
  "Services") provided by Plaid Inc. and its
  subsidiaries, including Plaid Financial Ltd. and Plaid, B.V.
  (“Plaid”, “we”, “our”, and “us”). Any violation of this Policy
  may result in suspension or termination of your access to the
  Services and/or access to end users’ personal and financial
  information provided by Plaid ("End User Data").`,
    `By accessing and using the Services, you agree to comply with all
  the terms of this Policy. This Policy will apply each time you
  access or use the Services. If you are agreeing to the terms of
  this Policy on behalf of an organization or entity, you
  represent and warrant that you are so authorized to agree on
  behalf of that organization or entity. This Policy is important;
  please read it carefully.`,
    `We may update or change this Policy at any time in our
  discretion. If we make any changes to this Policy that we deem
  to be material, we will make a reasonable effort to inform you
  of such change. If you object to change, your exclusive remedy 
  is to cease any and all access and use of the Services.`,
  ],
};

export const tableOfContentsTitle = 'Jump to section:';

export const sections = [
  {
    title: 'Registration',
    description: [
      `To sign up for the Services, you must create an account 
      ("Account") by registering on our Site and providing true, accurate, 
      and complete information about yourself and your use of the Services.  
      You agree not to misrepresent your identity or any information that you 
      provide for your Account, and to keep your Account information up to 
      date at all times. It is your responsibility to maintain access to your 
      Account; you may never share your Account information, including your 
      Plaid Dashboard password, as well as your API authentication credentials, 
      including your Client Identification Number (“Client ID”) and secret, 
      with a third party or allow any other application or service to act as you.`,
    ],
  },
  {
    title: 'Compliance with Applicable Law',
    description: [
      `When using the Services, you agree to abide by 
      all applicable local, state, national, and international laws.  
      You also confirm that you, your business, your employees, your 
      service providers, and any others acting on your behalf adhere to 
      all applicable laws, especially those pertaining to financial data, 
      data protection, privacy and data security.`,
      `In addition, you certify that you, your officers, directors, shareholders, direct 
      and indirect parent entities, subsidiaries, and affiliates:`,
      [
        `are and will remain in compliance with all applicable import, re-import, sanctions, anti-boycott, export, and re-export control laws and regulations (including all such laws and regulations that apply to a U.S. company, such as the Export Administration Regulations, the International Traffic in Arms Regulations, and economic sanctions programs implemented by the Office of Foreign Assets Control (OFAC));`,
        `are not subject to, or owned by parties that are subject to, sanctions or otherwise identified on any sanctions-related list, including but not limited to lists maintained by the United States government (such as the List of Specially Designated Nationals and Blocked Persons, maintained by OFAC, the Entity List maintained by the U.S. Commerce Department’s Bureau of Industry and Security, and the CAATSA section 231(d) list maintained by the U.S. State Department), the United Nations Security Council, the United Kingdom, the European Union or its Member States, or other applicable government authority; and`,
        `are not engaging, and will not engage, in activities which may require or permit any applicable government authority to pursue an enforcement action against, or impose economic sanctions on you or us.`,
      ],
      `The certifications immediately above are not sought, and are not provided, if and to the extent such request or certification would constitute a violation of the EU Blocking Statute, of laws or regulations implementing the EU Blocking Statute in the EU Member States or in the United Kingdom, or any similar anti-boycott, non-discrimination, or blocking provisions foreseen in applicable local laws.`,
      `You are solely responsible for ensuring that your use of the Services is in compliance with all laws applicable to you, including without limitation, the rules and guidelines of any system or network that facilitates payments and any security requirements, including under the Payment Card Industry Data Security Standards (PCI-DSS), as may be applicable to you.`,
    ],
  },
  {
    title: 'Security',
    description: [
      `You are responsible for securely maintaining your Plaid Dashboard 
      username and password, as well as your API authentication credentials, 
      including your Client ID and secret.  You agree to notify us promptly 
      at security@plaid.com in the event of any breach of security or 
      unauthorized use of your Account or any End User Data. You must 
      never publish, distribute, or share your Client ID or secret, and 
      must encrypt this information in storage and during transit.`,
      `Your systems and application(s) must handle End User Data securely. With respect 
      to End User Data, you should follow industry best practices but, at a minimum, must perform the following:`,
      [
        `Maintain administrative, technical, and physical safeguards that are designed to ensure the security, privacy, and confidentiality of End User Data.`,
        `Use modern and industry standard cryptography when storing or transmitting any End User Data.`,
        `Maintain reasonable access controls to ensure that only authorized individuals that have a business need have access to any End User Data.`,
        `Monitor your systems for any unauthorized access.`,
        `Patch vulnerabilities in a timely fashion.`,
        `Log and review any events suggesting unauthorized access.`,
        `Plan for and respond to security incidents.`,
        `Comply with relevant rules and regulations with regard to the type of data you are handling, 
        such as the Safeguards Rule under the Graham-Leach-Bliley Act.`,
      ],
    ],
  },
  {
    title: 'Data Storage and Usage',
    description: `Any End User Data in your possession must be stored securely and in accordance with applicable laws.`,
  },
  {
    title: 'Account Deactivation',
    description: [
      `Once you stop using the Services in accordance with any applicable agreement you may have with us, 
       you may deactivate your Account by following the instructions on the Site.  We may also deactivate 
       your Account if you have ceased using the Services for three months; your applicable agreement with 
       us terminates or expires; or as reasonably necessary under applicable law.  After your Account 
       deactivation, we will deprovision your access to all End User Data associated with your integration.`,
      `Even after your Account deactivation, and to the extent permitted under applicable law, we may still 
      retain any information we collected about you for as long as necessary to fulfill the purposes outlined 
      in our privacy policy/statement, or for a longer retention period if required or permitted under applicable law.`,
    ],
  },
  {
    title: 'Prohibited Conduct',
    description: [
      `You agree not to, and agree not to assist or otherwise enable any third party to:`,
      [
        `sell or rent End User Data to marketers or any other third party;`,
        `access or use the Services or End User Data for any unlawful, infringing, 
        threatening, abusive, obscene, harassing, defamatory, deceptive, or fraudulent purpose;`,
        `collect and store end users’ bank credentials and/or End
        User Data other than as required to access or use the
        Services, as authorized by the end user, as permitted by
        Plaid, and as permitted under applicable law;`,
        `use, disclose, or retain any “nonpublic personal
        information” (as defined under the Gramm-Leach-Bliley Act)
        or “personal information” (as defined under the California
        Consumer Privacy Act) other than in strict compliance with
        applicable law;`,
        `use, disclose, or otherwise process any “personal data”
        (as defined in Regulation (EU) 2016/679 (General Data
        Protection Regulation)) other than in strict compliance
        with applicable law;`,
        `access or use the Services or access, transmit, process, or
        store End User Data in violation of any applicable privacy
        laws or in any manner that would be a breach of contract
        or agreement with the applicable end user;`,
        `access or use the Services to infringe any patent,
        trademark, trade secret, copyright, right of publicity, or
        other right of any person or entity;`,
        `access or use the Services for any purpose other than for
        which it is provided by us, including for competitive
        evaluation, spying, creating a substitute or similar
        services to any of the Services, or other nefarious purpose;`,
        `scan or test (manually or in an automated fashion) the
        vulnerability of any Plaid infrastructure without express
        prior written permission from Plaid;`,
        `breach, disable, interfere with, or otherwise circumvent
        any security or authentication measures or any other
        aspect of the Service;`,
        `overload, flood, or spam any part of the Services;`,
        `create developer accounts for the Services by any means
        other than our publicly-supported interfaces (e.g.,
        creating developer accounts in an automated fashion or
        otherwise in bulk);`,
        `transfer, syndicate, or otherwise distribute the Services
        or End User Data without express prior written permission
        from Plaid;`,
        `decipher, decompile, disassemble, copy, reverse engineer,
        or attempt to derive any source code or underlying ideas
        or algorithms of any part of the Services, except as
        permitted by applicable law;`,
        `modify, translate, or otherwise create derivative works of
        any part of the Services;`,
        `access or use the Services or End User Data in a manner
        that violates any agreement between you or the end user
        and Plaid; or`,
        `access or use the Services or End User Data in a manner
        that violates any applicable law, statute, ordinance, or
        regulation.`,
      ],
    ],
  },
  {
    title: 'Suspension and Termination',
    description: [
      `We reserve the right to withhold, refuse, or terminate access to the Services and/or End User Data in whole or in part 
      where we believe the Services is being accessed or used in violation of this Policy or any other Plaid agreement, including 
      Plaid’s agreements with any third party partners or data sources of Plaid (each, a “Partner”), or where use would pose a risk 
      of harm, including reputational harm, to Plaid, its infrastructure, its data, the Services, an end user, or a Partner.`,
      `We will use reasonable efforts to notify you via email or other method when deciding to withhold, refuse, or terminate 
      access to the Services and/or End User Data. We may immediately suspend or terminate access without notice if appropriate 
      under the circumstances, such as when we become aware of activity that is a violation of any applicable law or when we 
      determine, in our sole discretion, that harm is imminent.`,
      `Plaid will not be liable for any damages of any nature suffered by you or any third party resulting from Plaid’s exercise 
      of its rights under this Policy or under applicable law.`,
    ],
  },
  {
    title: 'Reporting Violations',
    description: [
      `If any person becomes aware of a violation of this Policy,
    we request that you immediately notify us via email to
    <a href='mailto:legalnotices@plaid.com'>legalnotices@plaid.com</a>. 
    We may take any appropriate action -- including reporting
    any activity or conduct that we suspect violates the law to
    appropriate law enforcement officials, regulators, or other
    appropriate third parties -- in our sole discretion in
    respect to such violations.`,
    ],
  },
  {
    title: 'Universal Data Access',
    description: [
      `Plaid promotes an Open Finance ecosystem in which end users own their data (e.g., financial data) 
      and may grant access to such data to third parties. To the extent that you host any such end user 
      data not already accessible by Plaid, upon Plaid’s reasonable request, the parties agree to cooperate 
      in good faith to negotiate and initiate an arrangement for Plaid to access such end user data on 
      behalf of end users.`,
    ],
  },
  {
    title: 'Miscellaneous',
    description: [
      `The failure by you or Plaid to exercise in any respect any right provided for herein or in any other agreement 
      you may have with us shall not be deemed a waiver of any further rights hereunder.`,
      `If any provision of this Policy is found to be unenforceable or invalid, that provision shall be limited or 
      eliminated to the minimum extent necessary so that this Policy shall otherwise remain in full force and effect and enforceable.`,
    ],
  },
];
