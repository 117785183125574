import React from 'react';
import { Box, Column } from 'src/components-v2/Layout';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import {
  Wrapper,
  RichTextSwitch,
  RichTextSwitchHighlights,
} from 'src/containers';

import { BackgroundContext } from 'src/contexts';

import cx from 'classnames';
import styles from './RichTextHighlightsSection.module.scss';

export const RichTextHighlightsSection = ({ fields, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  return (
    <Wrapper
      component='section'
      {...inspectorProps({ fieldId: 'internalTitle' })}
      sx={{
        backgroundColor: fields?.backgroundColor,
      }}
      className={cx(styles.RichTextHighlightsSection)}
    >
      <Column className={cx(styles.Main)} lg={20} lgOffset={2} sm={24}>
        <Column xs={24} sm={8} lg={6.2} xl={4.8} className={cx(styles.HighlightsWrapper)}>
          {
            fields?.highlightsSidebar && 
            <BackgroundContext.Provider // text inside this component should always be dark
              value='white'
            >
              <Box className={cx(styles.Highlights)}>
                <RichTextSwitchHighlights
                  sys={sys}
                  content={fields?.highlightsSidebar}
                  nodes={{
                    h3: {
                      variant: 'p2',
                      component: 'h3',
                      fieldId: 'body',
                    },
                    h4: {
                      variant: 'h4',
                      component: 'h4',
                      fieldId: 'body',
                    },
                    p: {
                      variant: 'p3',
                      component: 'p',
                      fieldId: 'body',
                    },
                  }}
                />
              </Box>
            </BackgroundContext.Provider>
          }
          
        </Column>
        <Column xs={24} sm={15} lg={10} xl={9.6} className={cx(styles.Body)}>
          <RichTextSwitch
            sys={sys}
            content={fields?.body}
            nodes={{
              h3: {
                variant: 'h4',
                component: 'h3',
                fieldId: 'body',
              },
              p: {
                variant: 'p2',
                component: 'p',
                fieldId: 'body',
              },
              a: {
                sx: {
                  fontWeight: 400,
                },
              },
            }}
          />
        </Column>
      </Column>
    </Wrapper>
  );
};
