/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { ButtonTypes } from 'src/lib/constants';
import { Sys } from 'contentful';

interface CtaFields {
  text: string;
  type: string;
  url: string;
}

export interface Cta {
  fields: CtaFields;
  sys: Sys;
}

interface CtaBlockWithButtonsCmsData {
  title: string;
  ctaButtons: Cta[];
}

interface CtaBlockWithButtonsComponentProps {
  'cta-title-wide': boolean;
  'cta-title': string;
  'footer-title': string;
  'primary-cta-url': string;
  'primary-cta-link': string;
  'primary-cta-text': string;
  'hide-secondary-cta': boolean;
  'secondary-cta-url'?: string;
  'secondary-cta-link'?: string;
  'secondary-cta-text'?: string;
}

/**
 * Maps the Contentful response for the CTA block with buttons to the view
 */

export const ctaBlockWithButtonsAdapter = ({
  title,
  ctaButtons = [],
}: CtaBlockWithButtonsCmsData): CtaBlockWithButtonsComponentProps => {
  const primaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );

  return {
    'cta-title-wide': true,
    'cta-title': title || '',
    'primary-cta-link': _get(primaryCta, 'fields.url'),
    'primary-cta-text': _get(primaryCta, 'fields.text'),
    'hide-secondary-cta': secondaryCta ? false : true,
    'secondary-cta-link': _get(secondaryCta, 'fields.url'),
    'secondary-cta-text': _get(secondaryCta, 'fields.text'),

    'footer-title': title || 'What will you build?',
    'primary-cta-url': _get(primaryCta, 'fields.url'),
    'secondary-cta-url': _get(secondaryCta, 'fields.url'),
  };
};
