import { FC } from 'react';
import useTheme from 'threads5/styles/useTheme';

import { S } from 'src/components-v2/StyledElements/StyledElements';
import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';

import { List } from '../List';
import { Columns as TColumns } from '../types';

import styles from './Columns.module.scss';

type ColumnsProps = { className?: string; items: TColumns['children'] };

export const Columns: FC<ColumnsProps> = ({ className, items }) => {
  const [firstColumn, secondColumn] = items;
  const theme = useTheme();

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        minHeight: { xs: '100%' },
        mx: { sm: '-16px' },
        [theme.breakpoints.up(1078)]: {
          mx: 0,
        },
      }}
    >
      <Box
        className={styles.Column}
        sx={{
          p: { xs: '20px 0', sm: '20px 0 20px 16px' },
          borderBottom: { xs: '1px solid #eaeff0', sm: 'none' },
          [theme.breakpoints.up(1078)]: {
            p: '24px 24px 5px',
          },
        }}
      >
        <Typography
          className={styles.Title}
          theme='main-menu-new'
          variant='h6'
          sx={{
            display: { xs: 'none', sm: 'block' },
            color: '#747677',
            mb: '8px',
            p: '15px 12px',
            [theme.breakpoints.up(1078)]: {
              p: 0,
            },
          }}
        >
          {firstColumn.title}
        </Typography>

        <List items={firstColumn.children} />
      </Box>

      <Box
        className={styles.Column}
        sx={{
          p: { xs: '20px 16px', sm: '20px 28px' },
          mx: { xs: '-16px', sm: 0 },
          [theme.breakpoints.up(1078)]: {
            p: '24px 24px 5px',
          },
        }}
      >
        <Typography
          className={styles.Title}
          theme='main-menu-new'
          variant='h6'
          sx={{
            display: { xs: 'none', sm: 'block' },
            color: '#747677',
            mb: '8px',
            p: '15px 0',
            [theme.breakpoints.up(1078)]: {
              p: 0,
            },
          }}
        >
          {secondColumn.title}
        </Typography>

        <Stack
          component='ul'
          sx={{
            gap: '8px',
            listStyle: 'none',
            margin: 0,
            padding: 0,
            [theme.breakpoints.up(1078)]: {
              gap: 0,
            },
          }}
        >
          {secondColumn.children.map((item, i) => {
            const { href, title } = item;

            return (
              <S.Li
                key={`main-menu-column-item-${i}`}
                sx={{ p: { xs: '15px 12px', sm: '15px 0' } }}
              >
                <Link href={href}>
                  <a className={styles.Link} role='link' tabIndex={0}>
                    <Typography
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                      sx={{
                        mb: 0,
                      }}
                    >
                      {title}
                    </Typography>
                  </a>
                </Link>
              </S.Li>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};
