import React, { FC } from 'react';
import { Icon } from 'src/components-v2/DataDisplay';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

const AccordionIcons: FC<{
  icon?: React.ReactNode;
  isActive: boolean;
  sx?: SxProps<Theme>;
}> = ({ icon, isActive, sx = {} }) => {
  return (
    <>
      {icon ? (
        icon
      ) : (
        <>
          {isActive ? (
            <Icon
              icon='Subtract'
              sx={{
                height: '18px',
                width: '18px',
                ml: 'auto',
                ...sx,
              }}
            />
          ) : (
            <Icon
              icon='Add'
              sx={{
                height: '18px',
                width: '18px',
                ml: 'auto',
                ...sx,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default AccordionIcons;
