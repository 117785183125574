import { string, bool } from 'prop-types';
import React from 'react';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

const propTypes = {
  data: string.isRequired,
  isDoubleStat: bool,
  isLast: bool,
  text: string.isRequired,
};

const CredibilityStat = ({ data, text, isDoubleStat, isLast, ...props }) => (
  <div className='grid-x credibility-stat'>
    <div className='cell small-12'>
      <p className='credibility-stat__data'>{data}</p>
      <p
        className='credibility-stat__text'
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
      {isDoubleStat && isLast && (
        <div className='credibility-stat__cta hide-for-small-only'>
          <Link href={props['cta-link']}>
            <a>
              <span className='feature-section-link-text'>
                {props['cta-text']} <ArrowRightSingleSmall />
              </span>
            </a>
          </Link>
        </div>
      )}
    </div>
  </div>
);

CredibilityStat.propTypes = propTypes;

export default CredibilityStat;
