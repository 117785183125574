import products from 'src/store/en-eu/navigation/products';
import useCases from 'src/store/en-eu/navigation/use-cases';
import forDevelopers from 'src/store/en-eu/navigation/for-developers';
import aboutUs from 'src/store/en-eu/navigation/about-us';

export const mainNavData = {
  skipLinkCta: 'Skip to main content',
  logoAlt: 'Plaid logo',
  menuItems: [products, useCases, forDevelopers, aboutUs],
  login: {
    name: 'Log in',
    url: 'https://dashboard.plaid.com/signin',
  },
  getStarted: {
    name: 'Get started',
    url: 'https://dashboard.plaid.com/signup',
  },
};

export const mainNavDataForTopLevelNavNewPricing = {
  ...mainNavData,
};
