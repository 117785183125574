import React, { FC } from 'react';
import { MenuHeadingProps, MenuHeadingVariant } from './MenuHeading.types';
import { Box } from 'src/components-v2/Layout';

// components
import { Typography } from 'src/components-v2/DataDisplay';

export const MenuHeading: FC<MenuHeadingProps> = ({ title, sx, variant }) => {
  const isTertiary = variant === MenuHeadingVariant.TERTIARY;
  return (
    <Box
      component='li'
      sx={{
        px: {
          xs: isTertiary ? '3.2rem' : '0.8rem',
          sm: isTertiary ? '6.4rem' : '4rem',
          lg: '3.2rem',
        },
        pb: { lg: '0.8rem' },
        mt: { xs: 0, lg: isTertiary ? 0 : '2.4rem' },
        width: '100%',
        ...sx,
      }}
    >
      <Box
        component='hr'
        sx={{
          m: 0,
          maxWidth: '100%',
          display: { xs: isTertiary ? 'none' : 'block', lg: 'none' },
        }}
      />
      <Typography
        data-testid='menu-heading'
        variant='h6'
        theme='main-menu'
        sx={{
          pt: { xs: isTertiary ? 0 : '2.4rem' },
          pb: { xs: '0rem', lg: '0.8rem' },
          mb: '0',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default MenuHeading;
