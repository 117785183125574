const codeBody = `http code 200
{
  "accounts": [{
     "account_id": "QKKzevvp33HxPWpoqn6rI13BxW4awNSjnw4xv",
     "balances": {
       "available": 100,
       "current": 110,
       "limit": null,
       "iso_currency_code": "USD",
       "unofficial_currency_code": null
     },
     "mask": "0000",
     "name": "Plaid Checking",
     "official_name": "Plaid Gold Checking",
     "subtype": "checking",
     "type": "depository"
  }],
  "item": {object},
  "request_id": "m8MDnv9okwxFNBV"
}`;

export const interactiveData = {
  'title-text': 'Make a Balance request',
  'description-text': 'Use the Balance endpoint to submit a POST request',
  'code-title': 'Retrieve Balance request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/balance/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Real-time Balance data',
  'post-run-description-text':
    'The <strong>/accounts/balance/get</strong> endpoint returns the real-time balance for linked accounts',
  'post-run-code-title': 'Retrieve Balance response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      title: 'available',
      description:
        'The amount of funds available to be withdrawn from an account',
      'code-line-start': 6,
      'code-highlight-length': 1,
    },
    {
      title: 'current',
      description: 'The total amount of funds in the account',
      'code-line-start': 7,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/#balance',
  'bottom-cta-text': 'View API docs',
};
