import React, { FC } from 'react';
import cx from 'classnames';
import { Button } from 'src/components/Inputs';
import { CtaBlockWithButtons as CtaBlockWithButtonsProps } from './CtaBlockWithButtons.types';
import { usePaywallProvider } from '../Surfaces';
import styles from './CtaBlockWithButton.module.scss';
import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

const CtaBlockWithButtons: FC<CtaBlockWithButtonsProps> = ({
  'cta-title-wide': ctaTitleWide = false,
  'cta-title': ctaTitle = '',
  'cta-subtitle': ctaSubtitle = '',
  'primary-cta-link': primaryCtaLink = '',
  'primary-cta-text': primaryCtaText = '',
  'hide-secondary-cta': hideSecondaryCta = false,
  'secondary-cta-link': secondaryCtaLink = '',
  'secondary-cta-text': secondaryCtaText = '',
  getStartedTrackingId = '',
  contactSalesTrackingId = '',
}) => {
  const { isContentUnlocked, hasPaywall } = usePaywallProvider();
  return (
    <>
      <div className='grid-container'>
        <section className='grid-x grid-margin-x footer-cta'>
          <div
            className={`cell small-12 medium-6 ${
              !ctaTitleWide ? 'large-3' : null
            }`}
          >
            <h2 className='footer-cta-title'>
              {ctaTitle ? ctaTitle : 'Ready to get started?'}
            </h2>
            {ctaSubtitle && <h4 className='cta-subtitle'>{ctaSubtitle}</h4>}
          </div>
          <div
            className={cx(`cell small-12 medium-6`, {
              'large-4 large-offset-2': ctaTitleWide,
              'large-4 large-offset-3': !ctaTitleWide,
              [styles.verticalCenter]: !isContentUnlocked && hasPaywall,
            })}
          >
            <Button
              href={`${
                primaryCtaLink ? primaryCtaLink : '//dashboard.plaid.com/signup'
              }`}
              className='signup-attach-utm button'
              invertedColors
              fullWidth
              trackingId={
                getStartedTrackingId ||
                NAVIGATION_EVENTS.FOOTER.GET_STARTED_BUTTON
              }
            >
              {primaryCtaText ? primaryCtaText : 'Get started'}
            </Button>
            {!hideSecondaryCta && (isContentUnlocked || !hasPaywall) && (
              <Button
                href={`${
                  secondaryCtaLink
                    ? secondaryCtaLink
                    : '//dashboard.plaid.com/contact'
                }`}
                secondary
                invertedColors
                fullWidth
                trackingId={
                  contactSalesTrackingId ||
                  NAVIGATION_EVENTS.FOOTER.CONTACT_SALES_BUTTON
                }
                noMarginBottom
              >
                {secondaryCtaText ? secondaryCtaText : 'Contact sales'}
              </Button>
            )}
          </div>
        </section>
      </div>
      <div className='footer-divider'></div>
    </>
  );
};

export default CtaBlockWithButtons;
