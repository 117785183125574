'use strict';

const DOCS_ANCHOR_CLICK = 'DOCS_ANCHOR_CLICK';
const DOCS_SIDEBAR_CLICK = 'DOCS_SIDEBAR_CLICK';
const DOCS_SEARCH_INPUT = 'DOCS_SEARCH_INPUT';
const DOCS_SEARCH_SELECT = 'DOCS_SEARCH_SELECT';
const DOCS_COPY_EXAMPLE_CODE_CLICK = 'DOCS_COPY_EXAMPLE_CODE_CLICK';
const DOCS_LANGUAGE_SELECT = 'DOCS_LANGUAGE_SELECT';
const DOCS_CSAT_FEEDBACK = 'DOCS_CSAT_FEEDBACK';
const DOCS_CSAT_FEEDBACK_ADDITIONAL = 'DOCS_CSAT_FEEDBACK_ADDITIONAL';

export default {
  DOCS_ANCHOR_CLICK,
  DOCS_SIDEBAR_CLICK,
  DOCS_SEARCH_INPUT,
  DOCS_SEARCH_SELECT,
  DOCS_COPY_EXAMPLE_CODE_CLICK,
  DOCS_LANGUAGE_SELECT,
  DOCS_CSAT_FEEDBACK,
  DOCS_CSAT_FEEDBACK_ADDITIONAL,
};
