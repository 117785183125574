import React from 'react';
import NavbarMenuItem from './NavbarMenuItem';
import { NavbarMenuProps } from './types';

const NavbarMenu: React.FC<NavbarMenuProps> = ({ menuItems = [] }) => {
  return (
    <ul className='Navbar__menu'>
      {menuItems.map((item, i) => {
        return <NavbarMenuItem {...item} key={`desktop-nav-menu-item-${i}`} />;
      })}
    </ul>
  );
};

export default NavbarMenu;
