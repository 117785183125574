import React, { FC, useState, useLayoutEffect, useCallback } from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { TextInput, Button, SelectWithController } from 'src/components/Inputs';
import { CareersSearch as CareersSearchProps } from './CareersSearch.types';
import styles from './CareersSearch.module.scss';
import { unKebab } from 'src/lib/utils';
import { CareersUrlSlugs, CAREERS_PATH } from 'src/lib/constants';
import { Grid, Column } from 'src/components/Layout/Grid';
import { unKebabLocation } from 'src/lib/careers-utils';

// TODO: Refactor to use new SearchBar (subject to name change) pending PR merge
// https://jira.plaid.com/browse/SITE-3224

const CareersSearch: FC<CareersSearchProps> = ({
  hasSearchButton = false,
  totalJobCount,
  jobLocations,
  jobDepartments,
  filterResults,
  role: roleFromParent,
}) => {
  const router = useRouter();
  const hasAutoSubmit = !hasSearchButton;
  const {
    query: {
      department: departmentFromUrl = CareersUrlSlugs.ALL_DEPARTMENTS,
      location: locationFromUrl = CareersUrlSlugs.ALL_LOCATIONS,
      role: roleFromUrl = '',
    },
  } = router;
  const { register, handleSubmit, control, setValue } = useForm();

  const [careersSearchState, setCareersSearchState] = useState({
    location: locationFromUrl,
    department: departmentFromUrl,
    role: roleFromParent || roleFromUrl,
  });

  useLayoutEffect(() => {
    setCareersSearchState((prevState) => {
      return {
        ...prevState,
        role: roleFromUrl,
        location: locationFromUrl,
        department: departmentFromUrl,
      };
    });
  }, [roleFromUrl, locationFromUrl, departmentFromUrl, setValue]);

  const submitJobSearch = useCallback(
    (state) => {
      const { department, location, role } = state;
      const basePath = `${CAREERS_PATH}/openings`;

      router.push(
        {
          pathname: `${basePath}/[department]/[location]/`,
          query: { role: role || null },
        },
        `${basePath}/${department || CareersUrlSlugs.ALL_DEPARTMENTS}/${
          location || CareersUrlSlugs.ALL_LOCATIONS
        }/${role ? CareersUrlSlugs.ROLE_QUERY + role : ''}`,
        {
          scroll: false,
        },
      );
    },
    [router],
  );

  return (
    <Grid noPadding noMarginRightLarge noMarginRightMedium useThreadsGrid>
      <Column
        medium={12}
        className={styles.searchBox}
        data-id='search-box'
        allowOverflow
      >
        <form
          data-testid='job-search-form'
          id='jobSearchForm'
          className={styles.form}
          onSubmit={handleSubmit(submitJobSearch)}
        >
          <Grid
            className={styles.formGroup}
            noPadding
            noMarginRightSmall
            noMarginRightMedium
            noMarginRightLarge
            useThreadsGrid
          >
            <Column medium={12}>
              <p>
                <span className={styles.jobCount} data-testid='totalJobCount'>
                  {`${totalJobCount.current} open position${
                    totalJobCount.current !== 1 ? 's' : ''
                  }${careersSearchState.role ? ' matching your search' : ''}`}
                </span>
              </p>
            </Column>
            <Column
              medium={12}
              large={hasSearchButton ? 4 : 6}
              className={cx(styles.cell, styles.fieldsContainer)}
              noMarginRight
              allowOverflow
            >
              <TextInput
                label='Find a role for you'
                id='role'
                name='role'
                describedby={'role'}
                isDarkMode={false}
                register={register}
                defaultValue={careersSearchState.role as string}
                onChange={(e) => {
                  const val = e.target.value;
                  setCareersSearchState((prevState) => {
                    return { ...prevState, role: val };
                  });
                  if (filterResults) {
                    filterResults(e);
                  }
                }}
              />
            </Column>
            <Column
              medium={12}
              large={hasSearchButton ? 'auto' : 3}
              className={cx(styles.cell, styles.departmentSelectColumn)}
              allowOverflow
            >
              <SelectWithController
                label='Select a department'
                name='department'
                describedby={'department'}
                isDarkMode={false}
                id='Department'
                register={register}
                setValue={setValue}
                defaultValue={{
                  label: unKebab(careersSearchState.department as string),
                  value: careersSearchState.department as string,
                }}
                controlledValue={{
                  label: unKebab(careersSearchState.department as string),
                  value: careersSearchState.department as string,
                }}
                control={control}
                options={jobDepartments}
                onChange={(selectedOption) => {
                  const val = selectedOption.value;
                  setCareersSearchState((prevState) => {
                    const newState = { ...prevState, department: val };
                    if (hasAutoSubmit) {
                      submitJobSearch(newState);
                    }
                    return newState;
                  });
                }}
              />
            </Column>
            <Column medium={12} large='auto' allowOverflow noMarginRight>
              <SelectWithController
                label='Select a location'
                name='location'
                describedby={'location'}
                isDarkMode={false}
                id='Location'
                register={register}
                setValue={setValue}
                defaultValue={{
                  label: unKebabLocation(careersSearchState.location as string),
                  value: careersSearchState.location as string,
                }}
                controlledValue={{
                  label: unKebabLocation(careersSearchState.location as string),
                  value: careersSearchState.location as string,
                }}
                control={control}
                options={jobLocations}
                onChange={(selectedOption) => {
                  const val = selectedOption.value;
                  setCareersSearchState((prevState) => {
                    const newState = { ...prevState, location: val };
                    if (hasAutoSubmit) {
                      submitJobSearch(newState);
                    }
                    return newState;
                  });
                }}
              />
            </Column>
            {!hasAutoSubmit && (
              <Column
                large={2}
                medium={12}
                className={cx(styles.cell, styles.careersSearchButtonContainer)}
              >
                <Button className={cx(styles.button)} type='submit'>
                  Search
                </Button>
              </Column>
            )}
          </Grid>
        </form>
      </Column>
    </Grid>
  );
};

export default CareersSearch;
