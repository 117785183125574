/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the contentful response for supporting-text content type to the view
 *
 * @param {Object} args
 * @param {Object[]} args.supportingText
 * @param {string} args.backgroundColor
 * @param {number=} args.customPaddingTop
 * @param {number=} args.customPaddingBottom
 * @param {string} args.internalTitle
 * @returns {Object}
 */

export const supportingTextAdapter = ({
  supportingText = [],
  backgroundColor,
  customPaddingTop,
  customPaddingBottom,
  internalTitle,
}) => {
  const statsInfo = {};
  let hasStat = false;

  supportingText.forEach((item, index) => {
    if (supportingText.length === 2) {
      statsInfo[`stat${index + 2}`] = _get(item, 'fields.metric');
      statsInfo[`header${index + 2}`] = _get(item, 'fields.label');
      statsInfo[`text${index + 2}`] = _get(item, 'fields.body');
    } else {
      if (item.fields.image) {
        statsInfo.withImage = true;
        statsInfo.img = {
          src: _get(item, 'fields.image.fields.file.url'),
          alt:
            _get(item, 'fields.image.fields.description') ||
            _get(item, 'fields.image.fields.title'),
        };
      } else {
        statsInfo['three-up'] = true;

        statsInfo[`stat${index + 1}`] = _get(item, 'fields.metric');
        statsInfo[`header${index + 1}`] = _get(item, 'fields.label');
        statsInfo[`text${index + 1}`] = _get(item, 'fields.body');
      }
    }

    if (_get(item, 'fields.metric')) {
      hasStat = true;
    }
  });
  return {
    stat: hasStat,
    ...statsInfo,
    classes:
      'background background--color-' +
      _get(backgroundColor, 'fields.color') +
      ' supporting-text--constrained',
    customPaddingTop: Number.isInteger(customPaddingTop)
      ? `${customPaddingTop}px`
      : '',
    customPaddingBottom: Number.isInteger(customPaddingBottom)
      ? `${customPaddingBottom}px`
      : '',
    uid: internalTitle.toLowerCase().replace(/\s/g, '-'),
    'single-stat': supportingText.length === 1,
  };
};
