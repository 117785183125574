export const enum TutorialKey {
  Prototype = 'prototype',
  Basics = 'basics',
}

const USER_STEP_KEY = 'step_num';
const SAVED_PROJECT_KEY = 'user_project';

const defaultProjectsForTutorials = new Map<TutorialKey, string>();
defaultProjectsForTutorials.set(TutorialKey.Prototype, 'kerp-tutorial-mvp');
defaultProjectsForTutorials.set(TutorialKey.Basics, 'plaid-basics-start');

/**
 * I was going back and forth around whether it's better to set a separate key
 * for each tutorial, or one big JSON object for the entire tutorial. I think
 * this is better.
 */
const getStorageKey = function (tutorial: TutorialKey) {
  return `tutorial_${tutorial}_prefs`;
};

/**
 * Get the currently stored tutorial object from localStorage or,
 * if one doesn't exist, create a new one
 * @param tutorialKey The tutorial we're loading
 */
const getTutorialObject = function (tutorialKey: TutorialKey): {} {
  const localStorageKey = getStorageKey(tutorialKey);
  let userStoreString = null;
  try {
    userStoreString = localStorage.getItem(localStorageKey);
  } catch (error) {
    console.error('Could not access localstorage.', error);
  }
  const thisTutorial = JSON.parse(userStoreString);

  if (thisTutorial == null) {
    const defaultTutorial = {};
    defaultTutorial[USER_STEP_KEY] = 0;
    defaultTutorial[SAVED_PROJECT_KEY] = defaultProjectsForTutorials.get(
      tutorialKey,
    );
    return defaultTutorial;
  } else {
    return thisTutorial;
  }
};

const saveTutorialObject = function (tutorialKey, objectToSave) {
  const localStorageKey = getStorageKey(tutorialKey);
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(objectToSave));
  } catch (error) {
    console.error('Could not save tutorial object', error);
  }
};

/**
 * Save the most recent step that a user is viewing so we can
 * remember where they were
 * @param tutorialKey The tutorial key
 * @param stepNum What step they're on
 */
export const saveLastStepForTutorial = function (
  tutorialKey: TutorialKey,
  stepNum: number,
) {
  const thisTutorial = getTutorialObject(tutorialKey);
  thisTutorial[USER_STEP_KEY] = stepNum;
  saveTutorialObject(tutorialKey, thisTutorial);
};

/**
 * Save the glitch project that the user has forked for their own version of
 * the tutorial
 * @param tutorialKey The tutorial key
 * @param projectName The glitch project that the user wants to come back to
 */
export const saveUserProjectForTutorial = function (
  tutorialKey: TutorialKey,
  projectName: string,
) {
  const thisTutorial = getTutorialObject(tutorialKey);
  thisTutorial[SAVED_PROJECT_KEY] = projectName;
  saveTutorialObject(tutorialKey, thisTutorial);
};

/**
 * Fetch the most recent step the user was on in a tutorial so we can continue
 * where they left off
 * @param tutorialKey The tutorial key
 * @returns The last step the user was one
 */
export const loadStepForTutorial = function (tutorialKey: TutorialKey) {
  const thisTutorial = getTutorialObject(tutorialKey);
  return thisTutorial[USER_STEP_KEY];
};

/**
 * Load the glitch project that the user has forked so we can load their
 * projects in progress
 * @param tutorialKey The tutorial key
 * @returns The last step the user was one
 */
export const loadUserProjectForTutorial = function (tutorialKey: TutorialKey) {
  const thisTutorial = getTutorialObject(tutorialKey);
  return thisTutorial[SAVED_PROJECT_KEY];
};

/**
 * Saves the "default" user project for the tutorial so they can reset their
 * progress
 * @param tutorialKey The tutorial key
 */
export const resetUserProjectForTutorial = function (tutorialKey: TutorialKey) {
  const thisTutorial = getTutorialObject(tutorialKey);
  const defaultProject = defaultProjectsForTutorials.get(tutorialKey);
  thisTutorial[SAVED_PROJECT_KEY] = defaultProject;
  saveTutorialObject(tutorialKey, thisTutorial);
};

/**
 * Is the project we have loaded the default one?
 * @param tutorialKey The tutorial key
 * @param projectName The project we have loaded
 * @returns
 */
export const isDefaultProject = function (
  tutorialKey: TutorialKey,
  projectName: String,
) {
  return defaultProjectsForTutorials.get(tutorialKey) === projectName;
};
