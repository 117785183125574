import React from 'react';

// styles
import styles from './Drawer.module.scss';

interface Props {
  children: React.ReactNode;
}

const Drawer: React.FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default Drawer;
