export const countriesData = [
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Africa (Region)',
    value: 'Africa (Region)',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Asia (Region)',
    value: 'Asia (Region)',
  },
  {
    label: 'Australia',
    value: 'Australia',
    requiresConsent: true,
  },
  {
    label: 'Austria',
    value: 'Austria',
    requiresConsent: true,
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    requiresConsent: true,
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'Brunei',
    value: 'Brunei',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    requiresConsent: true,
  },
  {
    label: 'Canada',
    value: 'Canada',
    requiresConsent: true,
    isActive: true,
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
    requiresConsent: true,
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    requiresConsent: true,
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    requiresConsent: true,
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
    requiresConsent: true,
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    requiresConsent: true,
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    requiresConsent: true,
  },
  {
    label: 'Finland',
    value: 'Finland',
    requiresConsent: true,
  },
  {
    label: 'France',
    value: 'France',
    requiresConsent: true,
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
    requiresConsent: true,
  },
  {
    label: 'Greece',
    value: 'Greece',
    requiresConsent: true,
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    requiresConsent: true,
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    requiresConsent: true,
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
    requiresConsent: true,
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
    requiresConsent: true,
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: 'Laos',
    value: 'Laos',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    requiresConsent: true,
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    requiresConsent: true,
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    requiresConsent: true,
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Malta',
    value: 'Malta',
    requiresConsent: true,
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    requiresConsent: true,
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    requiresConsent: true,
    isActive: true,
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    requiresConsent: true,
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Norway',
    value: 'Norway',
    requiresConsent: true,
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palestine',
    value: 'Palestine',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
  },
  {
    label: 'Poland',
    value: 'Poland',
    requiresConsent: true,
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    requiresConsent: true,
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Romania',
    value: 'Romania',
    requiresConsent: true,
  },
  {
    label: 'Russia',
    value: 'Russia',
    requiresConsent: true,
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    requiresConsent: true,
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    requiresConsent: true,
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    requiresConsent: true,
  },
  {
    label: 'South America (Region)',
    value: 'South America (Region)',
  },
  {
    label: 'South Korea',
    value: 'South Korea',
    requiresConsent: true,
  },
  {
    label: 'Spain',
    value: 'Spain',
    requiresConsent: true,
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    requiresConsent: true,
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    requiresConsent: true,
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    requiresConsent: true,
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
    requiresConsent: true,
    isActive: true,
  },
  {
    label: 'United States',
    value: 'United States',
    isActive: true,
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
    requiresConsent: true,
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
];
