import React from 'react';
import cx from 'classnames';

// components
import MobileNavbarSubmenuItem from './MobileNavbarSubmenuItem';
import { Link } from 'src/components/Inputs';

// helpers
import { MobileNavbarMenuItemProps } from './types';

const MobileNavbarMenuItem: React.FC<MobileNavbarMenuItemProps> = ({
  menuLinks,
  submenu,
  label,
  count,
  url,
}) => (
  <li
    className={cx(
      'MobileNavbar__menu-item',
      menuLinks && 'MobileNavbar__menu-item--has-submenu',
    )}
  >
    {submenu ? (
      <>
        <input
          type='checkbox'
          id={`mobile-navbar-submenu-toggle-${count}`}
          className='MobileNavbar__checkbox'
        />
        <label
          htmlFor={`mobile-navbar-submenu-toggle-${count}`}
          className='MobileNavbar__submenu-toggle'
        >
          {label}
        </label>
      </>
    ) : (
      <Link href={url}>
        <a className='MobileNavbar__menu-item-anchor'>{label}</a>
      </Link>
    )}
    {submenu && (
      <ul className={`MobileNavbar__submenu MobileNavbar__submenu--${count}`}>
        <>
          {submenu.columns.map((column, i) => (
            <React.Fragment key={`mobile-column-${i}`}>
              {column.items.map((item, i) => (
                <MobileNavbarSubmenuItem
                  {...item}
                  locale={item?.locale}
                  key={`mobile-submenu-item-${i}`}
                />
              ))}
            </React.Fragment>
          ))}
        </>
        {menuLinks && (
          <>
            {menuLinks.map((item, i) => (
              <MobileNavbarSubmenuItem
                {...item}
                locale={item?.locale}
                key={`mobile-menu-link-${i}`}
              />
            ))}
          </>
        )}
      </ul>
    )}
  </li>
);

export default MobileNavbarMenuItem;
