import React from 'react';
import Script from 'next/script';

import styles from './Faq.module.scss';

import {
  Accordion,
  AccordionItemProps as AccordionItem,
} from 'src/components/Surfaces';
import Grid from 'plaid-threads/Grid';
import Column from 'plaid-threads/Column';

export interface FaqProps {
  title?: string;
  items: Array<AccordionItem>;
}

export const generateStructuredData = (
  faqItems: Array<AccordionItem>,
): Record<string, unknown> => {
  const schemaItems = faqItems.map((item) => ({
    '@type': 'Question',
    name: item.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text:
        typeof item.content === 'string'
          ? item.content
          : item.content.content[0].content[0].value, // RichTextContent from CMS
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [...schemaItems],
  };
};

const Faq = ({ title = 'FAQ', items }: FaqProps): JSX.Element => {
  const structuredData = generateStructuredData(items);
  return (
    <>
      <Script
        id='faq-schema'
        strategy='lazyOnload'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <section className={styles.faqSectionContainer}>
        <Grid noMarginRightMedium>
          <Column>
            <h2 className={styles.faqSectionHeader}>{title}</h2>
            <ul
              aria-label='FAQ Accordion Control Group Buttons'
              className={styles.faqAccordionControls}
            >
              {items.map((content, i) => (
                <li key={`content-${i}`}>
                  <Accordion {...content} />
                </li>
              ))}
            </ul>
          </Column>
        </Grid>
      </section>
    </>
  );
};

export default Faq;
