export const metaData = {
  'meta-title': 'Why is Plaid involved?',
  'meta-description':
    'Money can be a significant source of stress. Plaid helps people connect their financial accounts to the apps they rely on to manage their money.',
  'exclude-footer': 'true',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-5 large-5',
  label: false,
  'label-text': null,
  header: 'Why is Plaid involved?',
  'header-class': 'h2',
  subheader:
    'Some people have asked us how we connect their financial accounts to apps like Venmo and Acorns. Can’t Venmo just do it? Or my bank?',
  image: true,
  'background-image': '/assets/img/accents/why-plaid-brushstroke-hero.png',
  'image-url': '/assets/img/hero/why-is-plaid-involved-hero.png',
};

export const introSectionData = {
  classes: 'background',
  'custom-titles': true,
  'problem-title': 'CHALLENGE',
  'problem-statement':
    'There are more than 12,000 financial institutions in the US, but they structure and manage their data in many different ways.',
  'problem-subtext':
    'For an app that wants to enable users to connect their financial accounts, building a digital connection to a single financial institution can take a lot of engineering time and expertise. Now imagine doing that thousands of times. For many companies, it’s not feasible.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'At Plaid, we work around the clock to build and maintain secure connections to more than 12,000 US financial institutions.',
  'solution-subtext':
    'Plaid lets you connect your financial accounts to apps and services to help them provide products and services to you. These apps can help you do things like manage your money and make progress toward your financial goals.',
};

export const supportingTextData = {
  classes:
    'background supporting-text--constrained why-plaid__supporting-text-background',
  'three-up': true,
  'hide-separators': true,
  stat: true,
  stat1: '>8,000',
  text1: 'Apps and services that are powered by Plaid',
  stat2: '',
  text2: '',
  stat3: '>12,000',
  text3: 'Financial institutions that Plaid connects to',
};

export const appsData = {
  classes: 'why-plaid__apps',
  type: 'icon-grid',
  'extra-padding': false,
  title: 'What that means for you',
  description:
    'Money is one of the biggest sources of stress in people’s lives. By enabling you to quickly and securely connect your financial institutions to your apps, Plaid can help you do the things you want to do.',
  'bg-color': 'white',
  'img-brushtroke': '/assets/img/accents/discover-apps/brushstrokes-05.png',
  'icon-grid': [
    {
      'img-src': '/assets/img/logos/app-icons/app-icon-varo.png',
      'alt-text': 'Varo',
      name: 'Varo',
      description: 'Online bank accounts with no minimum balance.',
      url: 'https://www.varomoney.com/',
    },
    {
      'img-src': '/assets/img/logos/app-icons/app-icon-venmo.png',
      'alt-text': 'Venmo',
      name: 'Venmo',
      description:
        'A digital wallet that lets you make and share payment with friends.',
      url: 'https://venmo.com/',
    },
    {
      'img-src': '/assets/img/logos/app-icons/app-icon-acorns.png',
      'alt-text': 'Acorns',
      name: 'Acorns',
      description: 'Invest spare change and save for retirement.',
      url: 'https://www.acorns.com/',
    },
    {
      'img-src': '/assets/img/logos/app-icons/app-icon-ynab.png',
      'alt-text': 'YNAB',
      name: 'YNAB',
      description: 'Stop living paycheck-to-paycheck. You need a budget.',
      url: 'https://www.youneedabudget.com/',
    },
  ],
  'has-cta': false,
  'has-text-cta': true,
  'text-cta-text': 'Discover apps powered by Plaid',
  'text-cta-url': '/discover-apps',
  reverse: true,
};

export const troubleConnectingData = {
  classes: 'showcase--is-trouble-connecting',
  type: 'single-image',
  title: 'Why some connections fail',
  description: [
    'Sometimes you may not be able to connect your account. The problem could be taking place anywhere along the chain between Plaid and your financial institution.',
    'Our goal is to restore the connection so you can use the apps and services you rely on.',
  ],
  'img-brushtroke': '/assets/img/accents/trouble-connecting-brushstroke.png',
  images: [
    {
      'img-src':
        '/assets/img/consumers/why-is-plaid-involved/connection-failed.png',
      'alt-text': 'Why some connections fail',
      'has-shadow': false,
    },
  ],
  'has-cta': false,
  'has-text-cta': true,
  'text-cta-text': 'Trouble connecting?',
  'text-cta-url': '/trouble-connecting',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'RESOURCES',
  'header-text': 'Take the next step',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/how-it-works.png',
      'feature-title': 'How it works',
      'feature-body': 'Plaid powers the apps in your financial life.',
      'feature-url': '/how-it-works-for-consumers',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/manage-connections.png',
      'feature-title': 'Discover apps',
      'feature-body': 'Meet a few of the apps that are powered by Plaid.',
      'feature-url': '/discover-apps',
      'feature-link-text': 'Learn more',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/why-is-plaid-involved.png',
      'feature-title': 'Manage connections',
      'feature-body':
        'See the types of data you’ve shared and manage your connections.',
      'feature-url': 'https://my.plaid.com',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
  ],
};

export const dataAccessData = {
  classes: 'showcase--is-data-access',
  type: 'single-image',
  title: 'Working together to build a better financial system',
  description:
    'We believe in a financial system that is more swift, secure, and connected. We’re committed to working together with financial institutions, apps—and you!—to make it a reality.',
  'img-brushtroke': '/assets/img/accents/data-access-brushstroke.png',
  images: [
    {
      'img-src':
        '/assets/img/consumers/why-is-plaid-involved/collaboration.png',
      'alt-text': 'Why some connections fail',
      'has-shadow': false,
    },
  ],
  reverse: true,
};

export const featureSectionData = {
  'allow-bottom-padding-mobile': true,
  'feature-section-title': [
    {
      title: 'Keeping your data secure',
      'title-size': 'small',
      description:
        'Your security is essential to everything we do. For that reason, our information security practices are designed to meet or exceed industry standards.',
      'medium-column-width': 12,
      'large-column-width': 8,
    },
  ],
  'feature-section-items': [
    {
      img:
        '/assets/img/consumers/why-is-plaid-involved/security-control@2x.png',
      title: 'Control',
      'header-class': 'h5',
      content:
        'You’re in control of which apps you share your data with and for how long.',
      'link-location': '//my.plaid.com',
      'link-text': 'Manage connections',
    },
    {
      img:
        '/assets/img/consumers/why-is-plaid-involved/security-permission@2x.png',
      title: 'Permission',
      'header-class': 'h5',
      content: 'We don’t share your data with anyone without your permission.',
      'link-location': '/how-it-works-for-consumers',
      'link-text': 'How it works',
    },
    {
      img:
        '/assets/img/consumers/why-is-plaid-involved/security-privacy@2x.png',
      title: 'Privacy',
      'header-class': 'h5',
      content:
        'We don’t sell or rent your personal information to outside companies.',
      'link-location': '/how-we-handle-data', // TODO: Get real URL
      'link-text': 'How we handle data',
    },
    {
      img:
        '/assets/img/consumers/why-is-plaid-involved/security-security@2x.png',
      title: 'Security',
      'header-class': 'h5',
      content:
        'We protect your data with encryption, strong authentication, and third-party audits.',
      'link-location': '/how-we-handle-data', // TODO: Get real URL
      'link-text': 'How we handle data',
    },
  ],
};

export const footerData = {
  'cta-title': 'Want to see your app here?',
  'cta-title-wide': true,
  'cta-subtitle':
    "Build with us. Tell us how we can help and we'll get in touch shortly.",
  'primary-cta-text': 'Contact Us',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'bg-color': 'bg-white',
  'hide-secondary-cta': true,
};
