export const metaData = {
  'meta-title': 'Auto Lending',
  'meta-description': 'The technology layer for financial services"',
  hasCanonicalTag: true,
};

export const headerData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'A better way to approve borrowers for auto loans',
  'header-size': 'h2',
  'header-image':
    '/assets/img/accents/description-section/lending-services-hero-no-bg.png',
  body:
    'Plaid’s lending solution drives efficiencies throughout the loan lifecycle from pre-approvals to servicing. Get in touch to learn more.',
  'action-text': "Let's talk",
  'privacy-policy': true,
  formData: {
    'marketo-key': 2007,
    includeCompany: true,
    includePhone: true,
    actionText: "Let's talk",
  },
};
export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/carvana-logo@2x.png',
      alt: 'Carvana',
    },
    {
      img: '/assets/img/logos/shift-logo@2x.png',
      alt: 'Shift',
    },
  ],
};
export const introSectionData = {
  'problem-statement':
    'Applying and getting approved for a car loan can be a cumbersome process that requires a considerable amount of paperwork and time from both lenders and borrowers.',
  'solution-statement':
    'Plaid provides auto-lenders with access to bank data to make informed loan decisions and reduce fraud risk—resulting in a faster, streamlined loan experience. Plus, bank accounts can be linked in seconds for loan repayments.',
};
export const quoteData = {
  quote:
    'Plaid enabled Shift to offer a fully integrated online purchasing experience. The financial aspects of the process are much more seamless.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/shift-logo.svg',
  'logo-alt': 'Shift logo',
  cite: 'James Wu, Software Engineer at Shift',
  'quote-link': 'https://plaid.com/documents/Shift-Case-Study.pdf',
  'quote-link-text': 'See the full case study',
  'with-background': false,
  'remove-default-design': false,
  dark: false,
};
export const featuresData = {
  'header-section': true,
  'header-subtext': 'PRODUCTS AND BENEFITS',
  'header-text': null,
  'feature-count': 'four',
  'feature-content': [
    {
      'feature-img': '/assets/img/products/assets-112.png',
      'feature-title': 'Verify borrower assets digitally',
      'feature-body':
        '<a href="/products/assets/">Assets</a> provides a snapshot of a borrower’s finances including account balances and transactions. The data is reported directly from the bank to help reduce fraud risk.',
    },
    {
      'feature-img': '/assets/img/products/auth-112@2x.png',
      'feature-title': 'Better loan servicing',
      'feature-body':
        '<a href="/products/auth/">Auth</a> pulls users’ account and routing information which can be used for ACH loan payments.',
    },
    {
      'feature-img': '/assets/img/products/balance-112.png',
      'feature-title': 'Check balances in real-time',
      'feature-body':
        '<a href="/products/balance/">Balance</a> instantly checks whether users have the funds to make a loan payment to reduce NSFs.',
    },
  ],
};
