import React from 'react';
import _get from 'lodash/get';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { Button, Link } from 'src/components/Inputs';
import { Image, imageAdapter } from 'src/components/Layout';
import { CodeSnippet } from 'src/components/Surfaces';
import { RawHtml } from 'src/components/helpers';
import { Tooltip } from '../DataDisplay';

// constants
import { RichTextComponents, ButtonTypes, Contentful } from 'src/lib/constants';

import hyperlinkStyles from './Hyperlink/Hyperlink.module.scss';

/**
 * Renders one of Primary|Secondary CTA button inside of a rich text component
 *
 * @param {Object} args
 * @param {Object} args.button - Contentful data model of the button
 * @param {Object} args.classes
 * @param {string=} args.trackingId
 * @returns {<Button/>}
 */
const buttonSwitch = ({ button, classes, trackingId = '' }) => {
  const buttonType = _get(button, 'fields.type');
  const buttonProps = {
    href: _get(button, 'fields.url'),
    trackingId,
  };
  const buttonLabel = _get(button, 'fields.text');
  switch (buttonType) {
    case ButtonTypes.HYPERLINK:
      return (
        <Link href={buttonProps.href}>
          <a {...buttonProps} className={hyperlinkStyles.hyperlink}>
            {buttonLabel}
          </a>
        </Link>
      );
    case ButtonTypes.PRIMARY_CTA:
      return (
        <Button {...buttonProps} classes={classes}>
          {buttonLabel}
        </Button>
      );
    case ButtonTypes.SECONDARY:
    case ButtonTypes.SECONDARY_CTA:
      return (
        <Button secondary {...buttonProps} classes={classes}>
          {buttonLabel}
        </Button>
      );
    case ButtonTypes.DEFAULT:
      return (
        <Button {...buttonProps} classes={classes}>
          {buttonLabel}
        </Button>
      );
    default:
      throw new Error(
        `Button type does not exist: "${buttonType}" @ EmbeddedEntrySwitch.jsx`,
      );
  }
};

const EmbeddedEntrySwitch = (classes = {}) => {
  return {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const componentType = _get(
        node,
        Contentful.EMBEDDED_ENTRY_CONTENT_TYPE_ID,
      );
      switch (componentType) {
        case RichTextComponents.BUTTON: {
          const button = node?.data?.target;
          return buttonSwitch({ button, classes });
        }
        case RichTextComponents.IMAGE: {
          return (
            <Image
              {...imageAdapter(node.data.target.fields)}
              classes={classes}
            />
          );
        }
        case RichTextComponents.RAW_HTML:
          return <RawHtml body={node?.data?.target?.fields?.body} />;
        case RichTextComponents.CODE_SNIPPET:
          const { syntax, body } = node?.data?.target?.fields;
          return <CodeSnippet syntax={syntax}>{body}</CodeSnippet>;
        case RichTextComponents.CTA_BUTTON_WITH_TRACKING: {
          const { trackingId, button } = node?.data?.target?.fields;
          return buttonSwitch({ button, classes, trackingId });
        }
        default:
          return null;
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const componentType = _get(
        node,
        Contentful.EMBEDDED_ENTRY_CONTENT_TYPE_ID,
      );
      switch (componentType) {
        case RichTextComponents.TOOLTIP: {
          const { description, title } = node?.data?.target?.fields;
          return <Tooltip description={description} title={title} />;
        }
        default:
          return null;
      }
    },
  };
};

export default EmbeddedEntrySwitch;
