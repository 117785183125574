import React from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';

export const sliderSettings = {
  infinite: true,
  interval: 8000,
  isPlaying: true,
  isIntrinsicHeight: true,
  trayProps: {
    style: {
      transition: 'transform 300ms ease-in-out',
    },
  },
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Carousel = ({ children }) => {
  return (
    <CarouselProvider
      className={styles.carousel}
      totalSlides={children.length}
      {...sliderSettings}
    >
      <Slider classNameTray={styles.tray}>
        {React.Children.map(children, (child, i) => {
          return (
            <Slide className={styles.slide} index={i}>
              {child}
            </Slide>
          );
        })}
      </Slider>
      <DotGroup className={styles.dotGroup} />
    </CarouselProvider>
  );
};

Carousel.propTypes = propTypes;

export default Carousel;
