import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './SocialProof.module.scss';

const propTypes = {
  alignment: PropTypes.oneOf(['center', 'right']),
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
};

const SocialProofWrapper = ({ alignment, fullWidth, children, bgColor }) => (
  <div
    className={cx(styles.noWrapper, { [`${styles[bgColor]}`]: bgColor })}
    data-testid='social-proof-wrapper'
  >
    <div className='grid-container'>
      <div
        className={cx(
          'grid-x',
          'grid-margin-x',
          alignment && `align-${alignment}`,
        )}
        data-testid='social-proof-wrapper-outer'
      >
        <div
          className={cx('cell', 'small-12', !fullWidth && 'medium-8')}
          data-testid='social-proof-wrapper-inner'
        >
          {children}
        </div>
      </div>
    </div>
  </div>
);

SocialProofWrapper.propTypes = propTypes;
export default SocialProofWrapper;
