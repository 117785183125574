import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'src/components/headers/page-header.jsx';

import { CUSTOMER_STORIES_EVENTS } from 'src/lib/trackingEvents';
import { isPdfUrl } from 'src/lib/url-helpers';

const propTypes = {
  className: PropTypes.string,
  'background-image': PropTypes.string,
  'content-width-classes': PropTypes.string,
  label: PropTypes.bool.isRequired,
  'label-classes': PropTypes.string,
  'label-text': PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  'header-class': PropTypes.string,
  'primary-cta': PropTypes.bool.isRequired,
  'primary-cta-text': PropTypes.string,
  'primary-cta-link': PropTypes.string,
  'secondary-cta': PropTypes.bool.isRequired,
  'secondary-cta-text': PropTypes.string,
  'secondary-cta-link': PropTypes.string,
  secondaryCtaTrackingId: PropTypes.string,
  image: PropTypes.bool.isRequired,
  'image-width': PropTypes.number.isRequired,
  'image-height': PropTypes.number.isRequired,
  'image-url': PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  'image-phone': PropTypes.bool,
  'image-classes': PropTypes.string,
  'popup-logo-url': PropTypes.string.isRequired,
  iconAltText: PropTypes.string.isRequired,
};

const CustomerStoryPageHeader = ({ className, ...props }) => {
  return (
    <PageHeader
      classes={className}
      secondaryCtaTrackingId={
        props['secondary-cta-link'] && isPdfUrl(props['secondary-cta-link'])
          ? CUSTOMER_STORIES_EVENTS.USER_CLICKED_CUSTOMER_STORIES_DOWNLOAD_PDF
          : ''
      }
      {...props}
    />
  );
};

CustomerStoryPageHeader.propTypes = propTypes;
export default CustomerStoryPageHeader;
