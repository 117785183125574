import React from 'react';

import PageHeader from 'src/components/headers/page-header';
import Showcase from 'src/components/showcase';
import { Diagram } from 'src/components/CustomerStories';
import FeatureSectionUsecases from 'src/components/features/feature-section-usecases';
import { SupportingText } from 'src/components/Layout/SupportingText';
import Features from 'src/components/features';
import DefaultTemplate from 'src/templates/default';

// helpers
import { Locales } from 'src/lib/constants';
import { dangerous_getStaticData } from 'src/lib/getStaticData';

const HowWeHandleData = ({ locale = Locales.EN_US }) => {
  const {
    metaData,
    pageHeaderData,
    dataSharingData,
    diagramData,
    featureSectionData,
    supportingTextData,
    featuresData,
  } = dangerous_getStaticData({ locale, filePath: 'how-we-handle-data' });

  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      <Diagram {...diagramData} />
      <Showcase {...dataSharingData} />
      <FeatureSectionUsecases {...featureSectionData} />
      <SupportingText {...supportingTextData} />
      <Features {...featuresData} />
    </DefaultTemplate>
  );
};

export default HowWeHandleData;
