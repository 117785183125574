import React, { FC } from 'react';

// components
import ConditionalWrapper from 'src/components/helpers/ConditionalWrapper';
import { Link } from 'src/components/Inputs';
import LazyImage from 'src/components/Image';

// helpers
import { optimizeImage } from 'src/lib/image-helpers';
import { Image as ImageProps } from './Image.types';

const Image: FC<ImageProps> = ({ caption, url, classes, src, ...props }) => {
  return (
    <figure className={classes?.figureClassName}>
      <ConditionalWrapper
        condition={url}
        wrapper={(children) => (
          <Link href={url}>
            <a>{children}</a>
          </Link>
        )}
      >
        <LazyImage
          alt={props.alt}
          className={classes?.imgClassName}
          src={optimizeImage(src)}
          {...props}
        />
      </ConditionalWrapper>
      {caption && <figcaption dangerouslySetInnerHTML={{ __html: caption }} />}
    </figure>
  );
};

export default Image;
