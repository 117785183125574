const holdingsCodeBody = `http code 200
{
  "accounts": [{object}],
  "securities": [{
    "id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "isin": "US85207UAE55",
    "cusip": "85207UAE5",
    "sedol": null,
    "institution_security_id": null,
    "institution_id": "ins_102937",
    "proxy_security_id": null,
    "name": "Sprint Corporation",
    "ticker_symbol": "S",
    "is_cash_equivalent": false,
    "type": "equity",
    "close_price": 6.10,
    "close_price_as_of": "2019-04-16",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }, {
    "id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "isin": null,
    "cusip": null,
    "sedol": null,
    "institution_security_id": "VGSLX",
    "institution_id": "ins_115616",
    "proxy_security_id": null,
    "name": "Vanguard Real Estate Index",
    "ticker_symbol": null,
    "is_cash_equivalent": false,
    "type": "mutual fund",
    "close_price": 26.44,
    "close_price_as_of": "2019-04-15",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }],
  "holdings": [{
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "cost_basis": 4.28,
    "quantity": 2104.07,
    "institution_price": 6.09,
    "institution_price_as_of": "2019-04-19",
    "institution_value": 12813.79,
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }, {
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cost_basis": 22.32,
    "quantity": 150.00,
    "institution_price": 26.44,
    "institution_price_as_of": "2019-04-19",
    "institution_value": 3348,
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }],
}
`;

const transactionsCodeBody = `http code 200
{
  "accounts": [{object}],
  "securities": [{
    "id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "isin": "US85207UAE55",
    "cusip": "85207UAE5",
    "sedol": null,
    "institution_security_id": null,
    "institution_id": "ins_102937",
    "proxy_security_id": null,
    "name": "Sprint Corporation",
    "ticker_symbol": "S",
    "is_cash_equivalent": false,
    "type": "equity",
    "close_price": 6.10,
    "close_price_as_of": "2019-04-16",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
   }, {
    "id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "isin": null,
    "cusip": null,
    "sedol": null,
    "institution_security_id": "VGSLX",
    "institution_id": "ins_115616",
    "proxy_security_id": null,
    "name": "Vanguard Real Estate Index",
    "ticker_symbol": null,
    "is_cash_equivalent": false,
    "type": "mutual fund",
    "close_price": 26.44,
    "close_price_as_of": "2019-04-15",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }],
  "investment_transactions": [{
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcY9hhs",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "date": "2019-01-12",
    "name": "Dividend VANGUARD FEDERAL MONEY MARKET FUND (Settlement Fund)",
    "quantity": 0.84,
    "amount": 0.84,
    "price": 1,
    "fees": 0,
    "type": "cash",
    "subtype": "dividend",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }, {
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcY9asd",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0D1o3Q",
    "date": "2018-12-18",
    "name": "VANGUARD WINDSOR INVESTOR CL",
    "quantity": 146.915,
    "amount": 3000,
    "price": 20.42,
    "fees": 0,
    "type": "buy",
    "subtype": "contribution",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }, {
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcH2xvv",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr1X8B2w5X",
    "date": "2018-10-30",
    "name": "SPRINT CORPORATION",
    "quantity": 372,
    "amount": 2118.91,
    "price": 5.68,
    "fees": 5.95,
    "type": "buy",
    "subtype": "buy",
    "iso_currency_code": "USD",
    "unofficial_currency_code": null
  }]
}
`;

export const investmentsHoldingData = {
  testId: 'products-investments',
  flipped: true,
  'bg-first-image': true,
  'title-text': 'Retrieve Investment Holdings data',
  'description-text':
    'The <strong>/investments/holdings/get</strong> endpoint returns a detailed breakdown of an account’s holdings, including quantity, price, total value, ticker, and common name',
  'code-title': 'Retrieve Holdings response',
  'code-lang': 'json',
  'code-body': holdingsCodeBody,
  properties: [
    {
      title: 'cost_basis',
      description:
        'Total cost of acquiring the holding—this is a summation of all purchases of the security, including fees',
      'code-line-start': 40,
      'code-highlight-length': 1,
    },
    {
      title: 'quantity',
      description: 'The count of the security purchased',
      'code-line-start': 41,
      'code-highlight-length': 1,
    },
    {
      title: 'institution_price',
      description: 'The price of the security, according to the institution',
      'code-line-start': 42,
      'code-highlight-length': 1,
    },
    {
      title: 'institution_value',
      description: 'The value of the holding, according to the institution',
      'code-line-start': 44,
      'code-highlight-length': 1,
    },
    {
      title: 'securities',
      description:
        'Definitions for the securities referenced by these holdings, including standardized identifiers, trading symbols, and classifications',
      'code-line-start': 4,
      'code-highlight-length': 33,
    },
  ],
  'bottom-cta-url': '/docs/investments',
  'bottom-cta-text': 'View API docs',
};

export const investmentsTransactionsData = {
  'title-text': 'Retrieve Investment Transactions data',
  'description-text':
    'The <strong>/investments/transactions/get</strong> endpoint returns details on transactions that create a change in one or more holdings in a portfolio, such as buys, sells, splits, or dividends. Cash transfers in and out of an investment account are also returned',
  'code-title': 'Retrieve Investment Transactions response',
  'code-lang': 'json',
  'code-body': transactionsCodeBody,
  properties: [
    {
      title: 'name',
      description: 'Description of the transaction, taken from the institution',
      'code-line-start': 43,
      'code-highlight-length': 1,
    },
    {
      title: 'quantity',
      description: 'Amount of the security that is moved in the transaction',
      'code-line-start': 44,
      'code-highlight-length': 1,
    },
    {
      title: 'amount',
      description: 'The value of the transaction = price * quantity',
      'code-line-start': 45,
      'code-highlight-length': 1,
    },
    {
      title: 'price',
      description: 'The price of the security for this transaction',
      'code-line-start': 46,
      'code-highlight-length': 1,
    },
    {
      title: 'type',
      description:
        'A broad transaction type that informs the treatment of the transaction',
      'code-line-start': 48,
      'code-highlight-length': 1,
    },
    {
      title: 'securities',
      description:
        'Definitions for the securities referenced by these holdings, including standardized identifiers, trading symbols, and classifications',
      'code-line-start': 4,
      'code-highlight-length': 33,
    },
  ],
  'bottom-cta-url': '/docs/investments',
  'bottom-cta-text': 'View API docs',
};
