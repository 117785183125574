export const metaData = {
  metaTitle: 'Investment API - retrieve investment data',
  metaDescription:
    "Plaid's Investments API allows companies to build a holistic view of a user's investment accounts, including account balances, holdings, and transactions.",
};

const investmentsHoldingsCodeBody = `http code 200
{
  "accounts": [{object}],
  "securities": [{
    "id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "isin": "GB0005603997",
    "cusip": null,
    "sedol": "0560399",
    "proxy_security_id": null,
    "name": "Legal & General Group",
    "ticker_symbol": "LGEN",
    "is_cash_equivalent": false,
    "type": "equity",
    "close_price": 311.40,
    "close_price_as_of": "2020-02-13",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  },{
    "id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "isin": "LU1781541096",
    "cusip": null,
    "sedol": "BFX0551",
    "proxy_security_id": null,
    "name": "Lyxor Core Morningstar UK UCITS ETF",
    "ticker_symbol": "LCUK",
    "is_cash_equivalent": false,
    "type": "etf",
    "close_price": 10.76,
    "close_price_as_of": "2020-02-13",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }],
  "holdings": [{
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "cost_basis": 4.28,
    "quantity": 100,
    "institution_price": 311.40,
    "institution_price_as_of": "2020-02-13",
    "institution_value": 31140,
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  },{
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cost_basis": 22.32,
    "quantity": 150.00,
    "institution_price": 10.76,
    "institution_price_as_of": "2020-02-13",
    "institution_value": 1614,
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }]
}`;

const investmentsTrxnsCodeBody = `http code 200
{
  "accounts": [{object}],
  "securities": [{
    "id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "isin": "GB0005603997",
    "cusip": null,
    "sedol": "0560399",
    "proxy_security_id": null,
    "name": "Legal & General Group",
    "ticker_symbol": "LGEN",
    "is_cash_equivalent": false,
    "type": "equity",
    "close_price": 311.40,
    "close_price_as_of": "2020-02-13",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
   },{
    "id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "isin": "LU1781541096",
    "cusip": null,
    "sedol": "BFX0551",
    "proxy_security_id": null,
    "name": "Lyxor Core Morningstar UK UCITS ETF",
    "ticker_symbol": "LCUK",
    "is_cash_equivalent": false,
    "type": "etf",
    "close_price": 10.76,
    "close_price_as_of": "2020-02-13",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }],
  "investment_transactions": [{
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcY9hhs",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "date": "2020-02-13",
    "name": "Dividend Lyxor Core Morningstar UK UCITS ETF",
    "quantity": 0.67,
    "amount": 0.67,
    "price": 1,
    "fees": 0,
    "type": "cash",
    "subtype": "dividend",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }, {
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcY9asd",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0A1w5X",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr3I0D1o3Q",
    "date": "2020-02-12",
    "name": "Lyxor Core Morningstar UK UCITS ETF",
    "quantity": 10,
    "amount": 1053,
    "price": 10.53,
    "fees": 0,
    "type": "buy",
    "subtype": "contribution",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }, {
    "investment_transaction_id": "VaYBeiw3H9AhAHw53vQYkmkn2s2qJOGcH2xvv",
    "account_id": "vzeNDwK7KQIm4yEog683uElbp9GRLEFXGK98D",
    "security_id": "EogDe9DwpZHd9YoYLbgdF0rowxo9XaIdxP9Y5",
    "cancel_transaction_id": "yNLYVbYWyAuzVnOnV6aOh0Qz1nzOr1X8B2w5X",
    "date": "2020-02-13",
    "name": "Legal & General Group",
    "quantity": 25,
    "amount": 7804.75,
    "price": 312.19,
    "fees": 4.95,
    "type": "buy",
    "subtype": "buy",
    "iso_currency_code": "GBP",
    "unofficial_currency_code": null
  }]
}`;

export const productHeaderData = {
  'hero-one-poster-url':
    '/assets/img/products/standalone/investments/hero-investments-1-poster-us-en.png',
  'hero-one-poster-fallback-url':
    '/assets/img/products/standalone/investments/hero-investments-1-fallback-us-en.png',
  'hero-one-video-url':
    '/assets/img/products/standalone/investments/hero-investments-1-us-en.webm',
  'hero-one-video-fallback-url':
    '/assets/img/products/standalone/investments/hero-investments-1-us-en.mp4',
  'hero-one-video-width': null,
  'hero-one-video-height': null,
  'hero-two-poster-url':
    '/assets/img/products/standalone/investments/hero-investments-2-poster-us-en.png',
  'hero-two-poster-fallback-url':
    '/assets/img/products/standalone/investments/hero-investments-2-fallback-us-en.png',
  'hero-two-video-url':
    '/assets/img/products/standalone/investments/hero-investments-2-us-en.webm',
  'hero-two-video-fallback-url':
    '/assets/img/products/standalone/investments/hero-investments-2-us-en.mp4',
  'hero-two-video-width': null,
  'hero-two-video-height': null,
  label: 'Investments',
  description: 'Build a holistic view of a user’s investments',
  'top-cta-text': 'Get started',
  'top-cta-url': 'http://dashboard.plaid.com/signup',
  'bottom-cta-text': 'View API docs',
  'bottom-cta-url': '/docs/investments',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'product features',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'wider-feature': true,
      'feature-img':
        '/assets/img/products/standalone/investments/feature-investments-1.png',
      'feature-title': 'Understand a user’s investments',
      'feature-body':
        'Gain insight into a user’s investment accounts, including account balances, holdings, and transactions that create a change in a holding',
    },
    {
      'wider-feature': true,
      'feature-img':
        '/assets/img/products/standalone/investments/feature-investments-2.png',
      'feature-title': 'Expansive institution coverage',
      'feature-body':
        'Retrieve investment data from a broad coverage network that includes banks, brokerages, and pension providers',
    },
    {
      'wider-feature': true,
      'feature-img':
        '/assets/img/products/standalone/investments/feature-investments-3.png',
      'feature-title': 'Full breadth of account types',
      'feature-body':
        'Connect to the full breadth of UK investment account types—from brokerage accounts to pensions and ISAs',
    },
  ],
};

export const investmentsHoldingsExampleData = {
  testId: 'uk-products-investments',
  flipped: true,
  'bg-first-image': true,
  'title-text': 'Retrieve Investment Holdings data',
  'description-text':
    'The <strong>/investments/holdings/get</strong> endpoint returns a detailed breakdown of an account’s holdings, including quantity, price, total value, ticker, and common name',
  'code-title': 'Retrieve Holdings response',
  'code-lang': 'json',
  'code-body': investmentsHoldingsCodeBody,
  properties: [
    {
      title: 'cost_basis',
      description:
        'Total cost of acquiring the holding—this is a summation of all purchases of the security, including fees',
      'code-line-start': 36,
      'code-highlight-length': 1,
    },
    {
      title: 'quantity',
      description: 'The count of the security purchased',
      'code-line-start': 37,
      'code-highlight-length': 1,
    },
    {
      title: 'institution_price',
      description: 'The price of the security, according to the institution',
      'code-line-start': 38,
      'code-highlight-length': 1,
    },
    {
      title: 'institution_value',
      description: 'The value of the holding, according to the institution',
      'code-line-start': 40,
      'code-highlight-length': 1,
    },
    {
      title: 'securities',
      description:
        'Definitions for the securities referenced by these holdings, including standardised identifiers, trading symbols, and classifications',
      'code-line-start': 4,
      'code-highlight-length': 29,
    },
  ],
  'bottom-cta-url': '/docs/investments',
  'bottom-cta-text': 'View API docs',
};

export const investmentsTrxnsExampleData = {
  'title-text': 'Retrieve Investment Transactions data',
  'description-text':
    'The <strong>/investments/transactions/get</strong> endpoint returns details on transactions that create a change in one or more holdings in a portfolio, such as buys, sells, splits, or dividends. Cash transfers in and out of an investment account are also returned',
  'code-title': 'Retrieve Investment Transactions response',
  'code-lang': 'json',
  'code-body': investmentsTrxnsCodeBody,
  properties: [
    {
      title: 'name',
      description: 'Description of the transaction, taken from the institution',
      'code-line-start': 39,
      'code-highlight-length': 1,
    },
    {
      title: 'quantity',
      description: 'Amount of the security that is moved in the transaction',
      'code-line-start': 40,
      'code-highlight-length': 1,
    },
    {
      title: 'amount',
      description: 'The value of the transaction = price * quantity',
      'code-line-start': 41,
      'code-highlight-length': 1,
    },
    {
      title: 'price',
      description: 'The price of the security for this transaction',
      'code-line-start': 42,
      'code-highlight-length': 1,
    },
    {
      title: 'type',
      description:
        'A broad transaction type that informs the treatment of the transaction',
      'code-line-start': 44,
      'code-highlight-length': 1,
    },
    {
      title: 'securities',
      description:
        'Definitions for the securities referenced by these holdings, including standardised identifiers, trading symbols, and classifications',
      'code-line-start': 4,
      'code-highlight-length': 29,
    },
  ],
  'bottom-cta-url': '/docs/investments',
  'bottom-cta-text': 'View API docs',
};

export const featuredUsesCasesData = {
  'feature-section-title': [
    {
      label: 'Use Cases',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/use-cases/bbs-112.png',
      title: 'Banking and wealth',
      'header-class': 'h5',
      content:
        'Create a holistic view of your users’ investments, including account balances, holdings, and transactions',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/banking-and-wealth',
    },
    {
      img: '/assets/img/use-cases/pf-intl.png',
      title: 'Personal finances',
      'header-class': 'h5',
      content: 'Guide users towards investments or better products',
      'link-text': 'Learn more',
      'link-location': '/uk/use-cases/personal-finances',
    },
  ],
};

export const quoteData = {
  quote:
    'Plaid enables our users to easily link their held-away investment accounts to our platform, and the /investments/holdings/get endpoint returns a detailed view of positions to support our in-depth analysis across a broad set of investment account types. Additionally, the Plaid team has been a joy to work with as we beta tested the product.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/icon-logo-haven@2x.png',
  'logo-alt': 'Haven logo',
  cite: 'Andrew Moreland, founder and CTO of Haven',
  'with-background': false,
  dark: false,
  'bg-color': 'bg-gray',
};

export const footerExtensionData = {
  'bg-color': 'bg-white',
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/investments',
};
