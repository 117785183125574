export const metaData = {
  'meta-title': 'Contact us',
  'meta-description':
    'We love hearing from you! Contact the Plaid team with sales, partnerships, press, and brand inquiries',
  hasCanonicalTag: true,
};

export const header = 'Contact Sales';
export const subheader =
  'Ready to build the future of digital finance? Reach out to our sales team and we’ll be in touch.';

export const ctas = {
  description: 'Have other questions?',
  links: [
    "Learn more about <a href='https://support-my.plaid.com/hc/en-us/categories/4405983222679-Connecting-Financial-Accounts '>connecting financial accounts</a>",
    "Visit our <a href='/press'>press page</a>",
    "For employment verification, <a href='https://www.truework.com/verifications/plaid-employment-verification/'>visit here</a>",
  ],
  press: `Visit our <a href='/press'>press page</a>`,
};

export const socialProofData = {
  description: `Join the thousands of innovators that are <span class='medium-and-up-line-break'></span>already building with Plaid`,
  items: [
    {
      src: '/assets/img/logos/contact/customer-logo-betterment@2x.png',
      alt: 'Betterment Logo',
    },
    {
      src: '/assets/img/logos/contact/customer-logo-dave@2x.png',
      alt: 'Dave Logo',
    },
    {
      src: '/assets/img/logos/contact/customer-logo-chime.png',
      alt: 'Chime Logo',
    },
    {
      src: '/assets/img/logos/contact/customer-logo-venmo@2x.png',
      alt: 'Venmo Logo',
    },
    {
      src: '/assets/img/logos/contact/customer-logo-wave@2x.png',
      alt: 'Wave Logo',
    },
  ],
};
