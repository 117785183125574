export const metaData = {
  'meta-title': 'Account funding - financial data solutions',
  'meta-description':
    'Plaid makes it easier for your customers to fund their accounts by initiating payments within your application’s flow or website.',
};

export const pageHeaderData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'Initiate payments for account funding',
  'header-size': 'h2',
  'header-image-vert-center': true,
  body:
    'Plaid makes it easy for your customers to fund their accounts within your application’s flow. Get in touch to learn more.',
  'privacy-policy': true,
  'exclude-phone': true,
  'secondary-cta-text':
    'Plaid is the easiest way for people to connect their bank accounts to an app. Developers get 500 free connections in Europe.',
  video: true,
  'video-height': 528,
  'video-poster-image':
    '/assets/img/products/standalone/payment-initiation/hero-payment-initiation-left-uk-en.png',
  'video-fallback-image':
    '/assets/img/products/standalone/payment-initiation/hero-payment-initiation-left-uk-en.png',
  'video-formats': [
    {
      url:
        '/assets/img/products/standalone/payment-initiation/hero-payment-initiation-left-uk-en.webm',
      type: 'webm',
    },
    {
      url:
        '/assets/img/products/standalone/payment-initiation/hero-payment-initiation-left-uk-en.mp4',
      type: 'mp4',
    },
  ],
  formData: {
    'marketo-key': 2007,
    includeCompany: true,
    actionText: 'Learn more',
  },
};

export const introSectionData = {
  'background-position': 'calc(100vw - 250px)',
  'background-size': '500px',
  classes: 'background background--color-black100',
  'problem-statement':
    'Account funding frequently requires your users to repeatedly switch between your application and their bank’s, manually enter their account information, and in some cases, pay additional fees.',
  'solution-statement':
    'Plaid makes it easier for your customers to fund their accounts by initiating payments within your application’s flow or website.',
};

export const supportingTextData = {
  classes: 'supporting-text--keep-padding',
  'with-background': false,
  'with-image': false,
  'three-up': true,
  'six-up': false,
  header1: 'User experience',
  text1:
    'Your users can initiate payments without leaving your app or website, improving their experience and increasing conversions',
  header2: 'Verify payment status',
  text2:
    'Reduce the possibility of misdirected payments by verifying the recipient account and confirming payment status',
  header3: 'Reduce costs',
  text3: 'Payment Initiation uses Faster Payments rails for low-cost payments',
};

export const simpleCarouselData = {
  'carousel-title': 'How It Works',
  'carousel-slides': [
    {
      header: 'Initiate a payment',
      description:
        'After deciding to pay using Plaid, your customer enters the Plaid flow to confirm the amount and payee information',
      'bg-color': 'bg-green',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-01.png',
    },
    {
      header: 'Select a bank',
      description:
        'Your customer selects the bank from which to initiate the payment. Plaid supports most traditional and digital banks in the UK',
      'bg-color': 'bg-pink',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-02.png',
    },
    {
      header: 'Continue to bank redirect',
      description:
        'Your customer is redirected to their bank to authenticate their account',
      'bg-color': 'bg-yellow',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-03.png',
    },
    {
      header: 'Authenticate bank account',
      description:
        'Your customer authenticates and completes the payment transfer. Funds are withdrawn and deposited into your account',
      'bg-color': 'bg-blue',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-04.png',
    },
    {
      header: 'Confirm payment transfer',
      description:
        'Upon completion, Plaid <a href="https://plaid.com/docs/#onevent-callback">returns the status</a> of the payment, and your customer seamlessly returns to your application',
      'bg-color': 'bg-purple',
      img:
        '/assets/img/products/standalone/payment-initiation/payment-initiation-product-shot-05.png',
    },
  ],
};

export const footerExtensionData = {
  heading: 'Get started with Plaid',
  'primary-cta-text': 'Get free API keys',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'Contact sales',
  'secondary-cta-url': '//dashboard.plaid.com/contact',
};
