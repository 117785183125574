import React from 'react';
import { RichTextSwitch } from 'src/containers';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { Logos } from 'src/sections';
import ThemeProvider from 'threads5/themes/theme-provider';

const CmsLogos = ({ fields, sys }) => {
  const logos = fields?.logos
    ? fields?.logos?.map((logo, i) => {
        return {
          src: 'https://' + logo?.fields?.file?.url,
          alt: logo?.fields?.description || logo?.fields?.title,
          width: logo?.fields?.file?.details?.image?.width / 2,
          height: logo?.fields?.file?.details?.image?.height / 2,
        };
      })
    : [];

  const bgColor = fields.backgroundColor?.toLowerCase() || 'transparent';
  const mode = useCmsMode({ mode: fields?.mode, backgroundColor: bgColor });
  const componentTheme = useComponentTheme(mode);

  // NOTE: we have to key into the theme because for whatever reason tokens are working on the background css property
  const background = componentTheme.palette[bgColor] || bgColor;

  return (
    <ThemeProvider theme={componentTheme}>
      <Logos
        heading={
          fields?.body ? (
            <RichTextSwitch
              sys={sys}
              content={fields?.body}
              nodes={{
                p: {
                  variant: 'h5',
                  component: 'h2',
                  fieldId: 'body',
                },
              }}
            />
          ) : (
            ''
          )
        }
        logos={logos}
        sx={{ background, ...fields?.styles }}
        sys={sys}
      />
    </ThemeProvider>
  );
};

export default CmsLogos;
