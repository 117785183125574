import React from 'react';
import PropTypes from 'prop-types';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

// helpers
import { ImageShape } from 'src/lib/prop-types';

const propTypes = {
  'cta-link': PropTypes.string.isRequired,
  'cta-text': PropTypes.string.isRequired,
  large: PropTypes.bool,
  'large-mobile-format': PropTypes.bool,
  'logo-src-small': ImageShape.isRequired,
};
/**
 * CredibilityCtaLogo component is shown only on small screens,
 * used in tandem with CredibilityQuote
 */
const CredibilityCtaLogo = ({
  'cta-link': ctaLink,
  'cta-text': ctaText,
  large = false,
  'large-mobile-format': largeMobileFormat = false,
  'logo-src-small': logo,
}) => (
  <div className='grid-x show-for-small-only'>
    <div className='cell small-12 medium-5'>
      <div className='credibility-cta'>
        <Link href={ctaLink}>
          <a>
            <span className='feature-section-link-text'>
              {ctaText}
              <ArrowRightSingleSmall />
            </span>
          </a>
        </Link>
      </div>
      <div className='credibility-logo'>
        <span className={large ? 'mobile' : 'format'}>
          <div
            className={`credibility-logo__asset ${
              largeMobileFormat ? 'credibility-logo__asset--large-mobile' : ''
            }`}
          >
            <Image src={logo.src} alt={logo.alt} />
          </div>
        </span>
      </div>
    </div>
  </div>
);

CredibilityCtaLogo.propTypes = propTypes;
export default CredibilityCtaLogo;
