import React from 'react';
import { stripStyleAttr } from 'src/components/job/utils';
import { ISalaryDescription } from './SalaryDescription.types';
import { useUserLocale } from 'src/hooks';
import { payIntervalMap } from 'src/store/careers';

const makeSalaryRange = ({ salaryRange, locale }): string => {
  const currency = new Intl.NumberFormat(locale?.localeCode || 'en-US', {
    style: 'currency',
    currency: salaryRange?.currency,
  });

  const hasRange = salaryRange?.max > salaryRange?.min;
  const salaryComponent = hasRange
    ? `${currency.format(salaryRange?.min)} - ${currency.format(
        salaryRange?.max,
      )}`
    : currency.format(salaryRange?.max) || currency.format(salaryRange?.min);
  const components = [salaryComponent, payIntervalMap[salaryRange.interval]];
  return components.join(' ');
};

const SalaryDescription: React.FunctionComponent<ISalaryDescription> = ({
  salaryDescription,
  salaryRange,
}) => {
  const locale = useUserLocale();
  const [salary, setSalary] = React.useState('');
  React.useEffect(() => {
    if (salaryRange) {
      setSalary(
        makeSalaryRange({
          salaryRange,
          locale,
        }),
      );
    }
  }, [locale, salary, salaryRange]);

  if (!salaryDescription || !salaryRange) {
    return null;
  }

  const descriptionWithMarkup = { __html: stripStyleAttr(salaryDescription) };
  return (
    <div className='job-post__lists'>
      <p>{salary}</p>
      <div dangerouslySetInnerHTML={descriptionWithMarkup} />
    </div>
  );
};

export default SalaryDescription;
