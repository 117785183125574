import React from 'react';
import cx from 'classnames';

import AccountTableList, { AccountTableListProps } from './AccountTableList';
import AccountTableItem from './AccountTableItem';

const PlainAccountTable: React.FC<AccountTableListProps> = ({ columns }) => (
  <div className='cell medium-12'>
    <div className='grid-x show-for-medium'>
      {columns.map((column, i) => (
        <div
          key={`column-table-header-${i}`}
          className={cx('cell', `medium-${12 / columns.length}`)}
        >
          <p className='account-type-section-header'>{column.header}</p>
        </div>
      ))}
    </div>
    <div className='account-table-list account-table-list-services stack-table-small show-for-medium'>
      <div className='grid-x'>
        {columns.map((column, i) => (
          <div
            className={cx('cell', `medium-${12 / columns.length}`)}
            key={`column-${i}`}
          >
            {column.rows.map((item, i) => (
              <AccountTableItem key={`account-item-${i}`} {...item} />
            ))}
          </div>
        ))}
      </div>
    </div>
    <div className='grid-x show-for-small-only'>
      {columns.map((column, i) => (
        <div
          className={cx('cell', `medium-${12 / columns.length}`)}
          key={`plain-account-table-${i}`}
        >
          <AccountTableList
            header={column.header}
            columns={[
              {
                rows: column.rows,
              },
            ]}
          />
        </div>
      ))}
    </div>
  </div>
);

export default PlainAccountTable;
