import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import { TemplateWrapper } from 'src/templates';
import { TemplateFieldShape } from 'src/lib/prop-types';
import { ContentfulTemplates } from 'src/lib/constants';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';

const propTypes = {
  fields: TemplateFieldShape.isRequired,
};

const CareersPageTemplateScripts = dynamic(
  () => {
    return import(
      'src/templates/CareersPageTemplate/CareersPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const CareersPageTemplate = ({
  fields,
  children,
  filterResults,
  role,
  totalJobCount,
  hasSearchButton = false,
  noIndex = false,
  ...props
}) => {
  const { route } = useRouter();
  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      excludeBrandFromMetaTitle={true}
      hasFooterCta={fields?.hasFooterCta}
      noIndex={noIndex}
    >
      <div data-testid={ContentfulTemplates.CAREERS_PAGE}>
        <CmsComponentSwitch
          templatePage={ContentfulTemplates.CAREERS_PAGE}
          filterResults={filterResults}
          role={role}
          totalJobCount={totalJobCount}
          hasSearchButton={hasSearchButton}
          {...fields}
        />
        {children && children}
      </div>
      <CareersPageTemplateScripts route={route} {...props} />
      <ContentfulToolbar />
    </TemplateWrapper>
  );
};
CareersPageTemplate.propTypes = propTypes;

export default CareersPageTemplate;
