import React from 'react';
import { Stack } from 'src/components-v2/Layout';
import { Btn, Button } from 'src/components-v2/Inputs';
import { targetMap } from 'src/lib/constants';
import { Link } from 'src/components/Inputs';

const ButtonGroup = ({ node }) => {
  const fields = node?.data?.target?.fields;
  const buttons = fields?.buttons || [];
  return (
    <Stack className='button-group' sx={{ ...fields?.styles }}>
      {buttons.map((button, i) => {
        const fields = button?.fields;
        switch (button?.sys?.contentType?.sys?.id) {
          case 'hyperlink':
            return (
              <Link
                href={fields?.url}
                variant={fields?.appearance === 'text with arrow' ? 'cta' : ''}
                color={fields?.color || 'black'}
                trackingId={fields?.trackingId || ''}
                sys={button?.sys}
                sx={{ alignSelf: 'center' }}
              >
                <a target={targetMap[fields.target] || '_self'}>
                  {fields?.text}
                </a>
              </Link>
            );
          case 'targetButton':
            const variant =
              fields?.appearance === 'filled'
                ? 'contained'
                : fields?.appearance;
            return (
              <Button
                key={`button-${i}`}
                variant={variant}
                href={fields?.url}
                target={targetMap[fields.target]}
                trackingId={fields?.trackingId || ''}
                sys={button?.sys}
                sx={{ alignSelf: 'center' }}
              >
                {fields?.text}
              </Button>
            );
          case 'button2': // new button
            return (
              <Btn
                key={`button-${i}`}
                variant={fields?.appearance?.toLowerCase()}
                href={fields?.url}
                target={targetMap[fields?.target]}
                trackingId={fields?.trackingId || ''}
                sys={button?.sys}
                sx={{ alignSelf: 'center' }}
              >
                {fields?.text}
              </Btn>
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
};
ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
