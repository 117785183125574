import React, { useCallback, useEffect, useReducer, ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { TemplateWrapper } from 'src/templates';
import { articleTileGroupAdapter } from 'src/components/Layout';
import {
  PageHeaderCms,
  mvpPageHeaderAdapter,
  Breadcrumb,
} from 'src/components/headers';
import SearchResultArticles from './SearchResultArticles';
import { Link } from 'src/components/Inputs';

import {
  CmsComponents,
  ContentfulTemplates,
  RESOURCES_PATH,
} from 'src/lib/constants';
import { findComponent } from 'src/lib/utils';
import {
  queryArticlesByTopic,
  generateQueryString,
  getTopicArticles,
  searchBasePath,
} from 'src/lib/resources-utils';
import { createSearchDropdown } from 'src/components/Inputs/SearchBar';

import { Tile as TileType } from 'src/components/Layout/Tile/Tile.types';

const ResourcesSearchTemplateScripts = dynamic(
  () => {
    return import(
      'src/templates/Resources/TopicPageTemplate/TopicPageTemplateScripts'
    );
  },
  {
    ssr: false,
  },
);

const Actions = Object.freeze({
  setResults: 'SET_RESULTS',
  setIsLoading: 'SET_LOADING',
  setSearchTopic: 'SET_SEARCH_TOPIC',
  setPagination: 'SET_PAGINATION',
  completeSearch: 'COMPLETE_SEARCH',
});

const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case Actions.setSearchTopic:
      return {
        ...state,
        searchTopic: payload,
      };
    case Actions.setIsLoading:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.completeSearch:
      return {
        ...state,
        searchComplete: true,
      };
    case Actions.setResults:
      return {
        ...state,
        results: payload,
      };
    case Actions.setPagination:
      return {
        ...state,
        pagination: payload,
      };
    default:
      throw new Error(`${action?.type} not a valid action`);
  }
};

const ResourcesSearchTemplate = ({
  fields,
  sys = { id: null }, // the entry id of the current page's topic (/resources/<topic>/search)
  allTopicArticles,
  totalArticles,
  topics = [],
  ...props
}): ReactElement => {
  // creates an object of all the topics ex. {ach:{topic:'ACH'}, api:{topic:'API'}, 'all topics': {topic:{..}}, banking:{topic:'Banking'}}
  const topicsObj = topics.reduce((obj, e) => {
    if (typeof e === 'object') {
      obj = {
        ...obj,
        [e.label.toLowerCase().split(' ').join('-')]: { topic: e },
      };
      return obj;
    } else {
      obj = { ...obj, [e.toLowerCase().split(' ').join('-')]: { topic: e } };
      return obj;
    }
  }, {});

  // Arranges the topics in alphabetical order based on the keys of the topicsObj ex. ['ACH',{..},'API','Banking']
  const sortedTopics = Object.keys(topicsObj)
    .sort()
    .map((e) => {
      return topicsObj[e]?.topic;
    });
  topics = sortedTopics;

  const currentTopic = fields?.topic;

  const [state, dispatch] = useReducer(reducer, {
    searchTopic: currentTopic,
    isLoading: true,
    searchComplete: false,
    results: [],
    pagination: {
      hasPagination: false,
      pageNumber: 1,
      totalPages: 1,
    },
  });
  const { route, query, isReady } = useRouter();
  const queryString = generateQueryString({
    queryObject: query,
    omit: ['page', 'topic'],
  });

  const concludeSearch = useCallback(
    ({
      results = [],
      pagination,
    }: {
      results: [] | TileType[];
      pagination?: {
        hasPagination: boolean;
        totalPages: number;
        pageNumber: string | number;
      };
    }) => {
      dispatch({
        type: Actions.setResults,
        payload: results,
      });

      if (state.isLoading) {
        dispatch({ type: Actions.setIsLoading });
        dispatch({ type: Actions.completeSearch });
      }

      pagination &&
        dispatch({
          type: Actions.setPagination,
          payload: pagination,
        });
    },
    [state.isLoading],
  );

  const handleSearchResults = useCallback(
    ({ results, pageNumber, pagesToSkip }) => {
      const res = results.length > 9 ? results.slice(0, 9) : results;
      concludeSearch({
        results: res,
        pagination: {
          hasPagination: results.length + pagesToSkip * 9 > 9,
          totalPages: Math.ceil(results.length / 9),
          pageNumber: pageNumber || 1,
        },
      });
    },
    [concludeSearch],
  );

  useEffect(() => {
    if (isReady && state.searchComplete === false) {
      const queryIsEmpty = query?.q === '' || query?.q === undefined;
      const pageNumber = !isNaN(Number(query?.page)) && Number(query.page);
      const pagesToSkip = pageNumber ? pageNumber - 1 : 0;

      if (queryIsEmpty) {
        const { articles } = getTopicArticles({
          articleEntries: allTopicArticles,
          pagesToSkip: pagesToSkip,
        });
        const { tileGroup } = articleTileGroupAdapter({
          articles,
        });

        concludeSearch({
          results: tileGroup.slice(0, 9),
          pagination: {
            hasPagination: allTopicArticles.length > 9,
            totalPages: Math.ceil(allTopicArticles.length / 9),
            pageNumber: pageNumber || 1,
          },
        });
      } else {
        queryArticlesByTopic(
          {
            topicId: sys?.id,
            query: query.q,
            skip: pagesToSkip,
            topic: currentTopic,
          },
          (results) => {
            return handleSearchResults({ results, pageNumber, pagesToSkip });
          },
        );
      }
    }
  }, [
    allTopicArticles,
    concludeSearch,
    currentTopic,
    handleSearchResults,
    isReady,
    query.page,
    query.q,
    state.searchComplete,
    sys.id,
  ]);

  // Search props
  const handleChange = (search) => {
    if (
      search?.selectedOption &&
      state.searchTopic !== search?.selectedOption
    ) {
      dispatch({
        type: Actions.setSearchTopic,
        payload: search?.selectedOption.toLowerCase(),
      });
    }
  };

  const topicsDropdown = createSearchDropdown({
    label: 'Topics',
    options: topics,
    defaultOption: currentTopic || '',
  });

  const search = {
    placeholder: 'Search',
    destination: searchBasePath({ topic: state.searchTopic?.toLowerCase() }),
    resultType: 'resource',
    onChange: handleChange,
    label: `${totalArticles} resource${
      totalArticles !== 1 ? 's' : ''
    } available`, // ex: 2 resources available
    dropdowns: [topicsDropdown],
  };

  const header = !sys?.id
    ? { fields: { heading: 'All Topics' } }
    : findComponent(fields?.components, CmsComponents.PAGE_HEADER);

  return (
    <TemplateWrapper
      meta-title={fields?.metaTitle}
      meta-description={fields?.metaDescription}
      hasFooterCta={false}
      noIndex={fields?.metaNoindex}
      className='topic-page'
    >
      <div data-testid={ContentfulTemplates.TOPIC}>
        {header && (
          <PageHeaderCms
            search={search}
            largeHeading={true}
            {...mvpPageHeaderAdapter(header?.fields)}
            breadcrumb={
              <Breadcrumb>
                <Link href={RESOURCES_PATH}>
                  <a>RESOURCES</a>
                </Link>
                {sys?.id ? currentTopic : 'All Topics'}
              </Breadcrumb>
            }
            labelText={header?.fields?.['label']}
          />
        )}
        <SearchResultArticles
          queryString={queryString}
          topic={currentTopic?.toLowerCase()}
          {...state}
        />
      </div>
      <ResourcesSearchTemplateScripts route={route} {...props} />
    </TemplateWrapper>
  );
};

export default ResourcesSearchTemplate;
