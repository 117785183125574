import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'src/components/Inputs';

const propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const CtaButton = ({ href, children, className = '', ...props }) => (
  <Button href={href} className={cx('button-cta', className)} {...props}>
    {children}
  </Button>
);

CtaButton.propTypes = propTypes;

export default CtaButton;
