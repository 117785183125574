import { DEPLOY_ROOT } from 'src/env';
import { Asset, Entry } from 'contentful';
import isPlainObject from 'lodash/isPlainObject';
import { assetIsImage } from './utils';
import {
  STATIC_IMAGE_QUALITY,
  CMS_IMAGE_QUALITY,
  isTesting,
  ContentfulTemplates,
  isProduction,
} from 'src/lib/constants';

export const hasQualityQuery = (url = ''): boolean => {
  const qualityRegex = new RegExp('q=[0-9]+');
  return qualityRegex.test(url);
};

export const optimizeCMSImage = (src: string): string => {
  if (!hasQualityQuery(src)) {
    if (src.includes('.png') || src.includes('.jpeg') || src.includes('jpg')) {
      return `${src}?fm=webp&q=${CMS_IMAGE_QUALITY}`;
    }
    return `${src}?q=${CMS_IMAGE_QUALITY}`;
  }
  return src;
};

const assetBasePath = isTesting && DEPLOY_ROOT ? `/${DEPLOY_ROOT}` : '';

export const optimizeImage = (src: string) => {
  if (src === '' || src === undefined) {
    return src;
  }

  // Check for CMS images
  if (
    src.includes('images.ctfassets.net') ||
    src.includes('videos.ctfassets.net')
  ) {
    return optimizeCMSImage(src);
  }

  // Check for non-image files to exclude from optimization
  if (/^(https?:)?\/\/(assets.ctfassets.net)?/.test(src)) {
    return src;
  }

  // Handle testing env images
  // Check for src with hash before adding one
  const url = src.startsWith(assetBasePath) ? src : `${assetBasePath}${src}`;

  // we set the width because it is a required field
  // 1920 will at least ensure legacy images are not too large
  if (isProduction) {
    return `/_next/image?url=${url}&w=1920&q=${STATIC_IMAGE_QUALITY}`;
  }

  return src;
};

export const isAsset = (item: Entry<unknown> | Asset): item is Asset => {
  return assetIsImage(item);
};

export const isEntry = (
  item: Entry<unknown> | Asset,
): item is Entry<unknown> => {
  return item?.sys?.type === 'Entry';
};

export const optimizeAsset = (item: Asset): Asset => {
  if (!item?.fields?.file?.url) {
    console.warn(`Image URL missing: `, item); // eslint-disable-line no-console
    return item;
  }
  return {
    ...item,
    fields: {
      ...item.fields,
      file: {
        ...item?.fields?.file,
        url: optimizeImage(item?.fields?.file?.url),
      },
    },
  };
};

export const optimizeContentfulImages = (
  entry: Entry<unknown> | Entry<unknown>[] | Asset,
) => {
  // customer stories cause an infinite loop
  // Page templates add their own query parameters
  // @ts-ignore
  const contentType = entry?.sys?.contentType?.sys?.id;
  if (
    contentType === ContentfulTemplates.CUSTOMER_STORY ||
    contentType === ContentfulTemplates.CUSTOMER_STORIES ||
    contentType === ContentfulTemplates.PAGE
  ) {
    return entry;
  }

  if (Array.isArray(entry)) {
    return entry.map(optimizeContentfulImages);
  }

  if (isAsset(entry)) {
    return optimizeAsset(entry);
  }

  const fields = isEntry(entry) ? entry.fields : entry;
  if (!isPlainObject(fields)) {
    return fields;
  }

  const newFields = Object.keys(fields).reduce((acc, key) => {
    const value = fields[key];
    let newValue = value;
    if (Array.isArray(value)) {
      newValue = value.map(optimizeContentfulImages);
    } else if (isEntry(value)) {
      newValue = optimizeContentfulImages(value);
    } else if (isAsset(value)) {
      newValue = optimizeAsset(value);
    }
    acc[key] = newValue;
    return acc;
  }, {});

  if (isEntry(entry)) {
    return { ...entry, fields: newFields };
  }

  return newFields;
};
