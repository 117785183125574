import React, { FC } from 'react';
import { ICards } from './CardsRow.types';
import { Section, Container, Row, Column } from 'src/components-v2/Layout';
import { Card } from 'src/components-v2/Surfaces';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { getColumn } from 'src/lib/utils';

const CardsRow: FC<ICards> = ({ header, cards, sys, sx }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Section {...inspectorProps({ fieldId: 'internalTitle' })} sx={{ ...sx }}>
      <Container>
        {(header?.props?.content || header) && ( // one is from cms and the other is from static page
          <Row>
            <Column xs={24} lg={20} lgOffset={2}>
              <Section
                component='header'
                sx={{
                  // we add this code to remove the trailing paragraph added by
                  // contentful's rich text editor
                  '& p:nth-last-of-type(1):empty': {
                    display: 'none',
                  },
                  '& h2': {
                    mb: { xs: '2.4rem', lg: '4rem' },
                  },
                }}
              >
                {header}
              </Section>
            </Column>
          </Row>
        )}
        {cards && (
          <Row>
            {cards.map((card, i) => {
              const columns = getColumn(cards, i);
              return (
                <Column {...columns} key={`card-column-${i}`}>
                  <Card sys={card?.props?.card?.sys}>{card}</Card>
                </Column>
              );
            })}
          </Row>
        )}
      </Container>
    </Section>
  );
};

export default CardsRow;
