import React from 'react';
import { Icon } from 'src/components-v2/DataDisplay';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import { useIsDarkBackground } from 'src/contexts';
import { ConditionalWrapper } from 'src/components-v2/Helpers';

const ImageMap = {
  Purple:
    'https://images.ctfassets.net/ss5kfr270og3/6R4GURZrb3arWOCgQVRk0q/be10fa7c7b948f830341d164f930912b/purple-pill.png?fm=webp&w=656&q=30',
  Blue:
    'https://images.ctfassets.net/ss5kfr270og3/7L8b9B9Q8cHyxqMKU4sX1P/9c76b1e707b7a1c4fe9f339a1e39559e/blue-pill.png?fm=webp&w=570&q=30',
  Green:
    'https://images.ctfassets.net/ss5kfr270og3/MWQgXn1hHuHLNduuuVYxj/0f36be27f6673d3f2326262fbb92c890/green-pill.png?fm=webp&w=504&q=30',
};

const Pill = ({ node }) => {
  const isDarkBackground = useIsDarkBackground();
  const inspectorProps = useContentfulInspectorMode({
    entryId: node?.data?.target?.sys?.id,
  });

  const handleScroll = (anchorId) => {
    const targetElement = document.querySelector(anchorId);
    if (targetElement) {
      const windowHeight = window.innerHeight;
      const targetHeight = targetElement.clientHeight;
      const targetPosition =
        targetElement.offsetTop - (windowHeight - targetHeight) / 2;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const fields = node?.data?.target?.fields;
  const icon = fields?.icon?.fields?.icon;
  const anchorId = fields?.anchor?.sys?.id || '';
  return (
    <ConditionalWrapper
      condition={anchorId}
      wrapper={(children) => {
        const anchor = anchorId.startsWith('#')
          ? 'id-' + anchorId
          : '#id-' + anchorId;
        return (
          <a
            onClick={(e) => {
              e.preventDefault();
              return handleScroll(anchor);
            }}
            href={anchor}
          >
            {children}
          </a>
        );
      }}
    >
      <S.Em
        sx={{
          alignItems: 'baseline',
          backgroundImage: `url(${ImageMap[fields?.color || 'Blue']})`,
          backgroundSize: 'cover',
          borderRadius: { xs: '60px', md: '100px' },
          color: isDarkBackground ? 'white' : 'black',
          display: 'inline-block',
          fontStyle: 'normal',
          p: { xs: '0 2rem 0.3rem', md: '0 3.2rem 1rem' },
          mt: { xs: '0.5rem', md: '1rem' },
          ...fields?.styles,
        }}
        {...inspectorProps({
          fieldId: 'text',
        })}
      >
        {icon && (
          <Icon
            icon={icon}
            sx={{
              color: 'inherit',
              height: { xs: '24px', md: '40px' },
              mr: { xs: '0.8rem', md: '1.6rem' },
              width: { xs: '24px', md: '40px' },
            }}
          />
        )}
        {fields?.text || 'replace me with text'}
      </S.Em>
    </ConditionalWrapper>
  );
};
Pill.displayName = 'Pill';

export default Pill;
