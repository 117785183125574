export const metaData = {
  metaTitle: 'Identity API - verify user identities',
  metaDescription:
    "Plaid's Identity API enables companies to verify user identities through bank accounts and reduce fraud, using secure Open Banking integrations.",
};

export const productHeaderData = {
  'hero-one-poster-url':
    '/assets/img/products/standalone/identity/hero-identity-1-poster-us-en.png',
  'hero-one-poster-fallback-url':
    '/assets/img/products/standalone/identity/hero-identity-1-fallback-us-en.png',
  'hero-one-video-url':
    '/assets/img/products/standalone/identity/hero-identity-1-us-en.webm',
  'hero-one-video-fallback-url':
    '/assets/img/products/standalone/identity/hero-identity-1-us-en.mp4',
  'hero-one-video-width': null,
  'hero-one-video-height': null,
  'hero-two-poster-url':
    '/assets/img/products/standalone/identity/hero-identity-2-poster-us-en.png',
  'hero-two-poster-fallback-url':
    '/assets/img/products/standalone/identity/hero-identity-2-fallback-us-en.png',
  'hero-two-video-url':
    '/assets/img/products/standalone/identity/hero-identity-2-us-en.webm',
  'hero-two-video-fallback-url':
    '/assets/img/products/standalone/identity/hero-identity-2-us-en.mp4',
  'hero-two-video-width': null,
  'hero-two-video-height': null,
  label: 'Identity',
  description: 'Verify users’ identities and reduce fraud',
  'top-cta-text': 'Get started',
  'top-cta-url': 'https://dashboard.plaid.com/signup',
  'bottom-cta-text': 'View API docs',
  'bottom-cta-url': '/docs/identity',
};
export const featuresData = {
  'header-section': true,
  'header-subtext': 'product features',
  'feature-count': 'two',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/products/standalone/identity/feature-identity-1.png',
      'feature-title': 'Verify user identities using bank data',
      'feature-body':
        'Retrieve the full name on the account—data can be used to complement KYC procedures',
    },
    {
      'feature-img': '/assets/img/products/features-open-banking-psd2.png',
      'feature-title': 'Open Banking/PSD2 integrations',
      'feature-body':
        'Plaid’s integrations are Open Banking and PSD2 compliant, delivering secure and reliable access to the UK and European financial ecosystem',
    },
  ],
};
export const interactiveData = {
  'title-text': 'Make an Identity request',
  'description-text': 'Use the Identity endpoint to submit a POST request',
  'code-title': 'Retrieve Identity request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/identity/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Identity data straight from the source',
  'post-run-description-text':
    'The <strong>/identity/get</strong> endpoint allows you to retrieve account holder information on file with the financial institution',
  'post-run-code-title': 'Retrieve Identity response',
  'post-run-code-lang': 'json',
  'post-run-code-body':
    '\nhttp code 200\n{\n  "accounts": [\n    {\n      ...,\n      "owners": [\n        {\n          "addresses": [\n            {\n              "data": {\n                "city": "null",\n                "region": "null",\n                "street": "null",\n                "postal_code": "null",\n                "country": "null"\n              },\n              "primary": true\n            },\n            {\n              "data": {\n                "city": "null",\n                "region": "null",\n                "street": "null",\n                "postal_code": "null",\n                "country": "null"\n              },\n              "primary": false\n            }\n          ],\n          "emails": [\n            {\n              "data": "null",\n              "primary": true,\n              "type": "primary"\n            }\n          ],\n          "names": [\n            "Jack Thomas Smith"\n          ],\n          "phone_numbers": [{\n            "primary": true,\n            "type": "null",\n            "data": "null"\n          }]\n        }\n      ]\n    }\n  ],\n  "item": {object},\n  "request_id": "m8MDnv9okwxFNBV"\n}\n',
  properties: [
    {
      title: 'names',
      description: 'A list of names associated with the account(s)',
      'code-line-start': 37,
      'code-highlight-length': 3,
    },
  ],
  'bottom-cta-url': '/docs/identity',
  'bottom-cta-text': 'View API docs',
};
export const credibilityData = {
  carousel: false,
  items: [
    {
      type: 'double-stat',
      'image-src-medium-up':
        '/assets/img/products/standalone/identity/credibility-identity-iphone-placid@2x.png',
      'bg-color': 'bg-blue',
      stats: [
        {
          data: '50%',
          text: '50% reduction in customer service queries',
        },
      ],
      'cta-text': 'View case study',
      'cta-link': '/documents/Placid-Express-Case-Study.pdf',
      'logo-src-small': {
        src: '/assets/img/logos/icon-logo-placid@2x.png',
        alt: 'Placid Express logo',
      },
    },
  ],
};

export const footerExtensionData = {
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/identity',
};
