import { CmsThemes } from 'src/lib/constants';

export const gridCardStyles = ({
  cmsTheme,
  isFeatured,
  isDark,
  backgroundColor,
}) => {
  const featuredBackgroundColor = isDark ? 'white' : 'black';
  const featuredImage = isDark
    ? 'https://images.ctfassets.net/ss5kfr270og3/YClDcTRUcE7M7os6YDFb4/f764e1a5ff47300cb91c7b887797c4cb/Brushstroke-light-featured-grid-card.png?fm=webp&w=844&q=10'
    : 'https://images.ctfassets.net/ss5kfr270og3/6r9hiLHWUjEUtz9d9d0qrH/8d02279e6498ae2057b00749e54bea47/Black_background.png?fm=webp&w=844&q=10';

  return (
    {
      [CmsThemes.WEB2]: {
        borderRadius: '20px',
        border: 1,
        borderColor: isDark ? 'white' : 'black',
        backgroundColor: isFeatured ? featuredBackgroundColor : 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: isFeatured ? `url(${featuredImage})` : 'none',
      },
      [CmsThemes.WEB3]: {
        borderRadius: '1.5rem',
        boxShadow: '0px 3px 18px 0px rgba(0, 0, 0, 0.10)',
        ...(isFeatured
          ? {
              backgroundColor: featuredBackgroundColor,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url(${featuredImage})`,
            }
          : {
              background: (theme) => {
                return theme.palette[backgroundColor] || backgroundColor;
              },
            }),
      },
    }[cmsTheme] || {}
  );
};
