/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the Contentful response for the Partner Card to the view
 *
 * @param {Object} args
 * @param {Array<Object>} args.partnerCards
 * @returns {Object}
 */

export const partnerCardsDiverseAdapter = ({ partnerCards }) => {
  return {
    partnerCards: partnerCards.map((card) => {
      return {
        partnerTag: _get(card, 'fields.partnerTag'),
        description: _get(card, 'fields.partnerDescription'),
        img: {
          src: _get(card, 'fields.partnerLogo.fields.file.url'),
          alt: _get(card, 'fields.pageSlug'),
          height: 48,
        },
        partnerName: _get(card, 'fields.partnerName'),
        pageSlug: _get(card, 'fields.pageSlug'),
        url: _get(card, 'fields.url'),
        isFeatured: _get(card, 'fields.isFeatured'),
      };
    }),
  };
};
