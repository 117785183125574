import utils from './utilities';
import {
  trackLinkedIn,
  linkedInConversionIds,
  trackTTD,
  TTDConversionIds,
} from 'public/assets/js/analytics';

export const trackingUtils = () => {
  window?.analytics?.ready != null &&
    window.analytics.ready(() => {
      // Wait max 500ms for LinkedIn network request to return conversion pixel
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#extending-timeout
      window.analytics.timeout(500);

      // Emitter event listener to run custom code,
      // useful for sending data to 3rd party service not integrated w/ Segment
      // i.e. LinkedIn conversion pixel request
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#emitter
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
      window.analytics.on(
        'track',
        (event, _, { integrations = {}, consentRequired = false } = {}) => {
          const shouldTrackLinkedIn =
            Object.keys(linkedInConversionIds).includes(event) &&
            (integrations['LinkedIn Insight Tag'] || !consentRequired);

          const shouldTrackTTD =
            Object.keys(TTDConversionIds).includes(event) &&
            (integrations['TTD'] || !consentRequired);

          if (shouldTrackLinkedIn) {
            trackLinkedIn({
              conversionId: linkedInConversionIds[event],
            });
          }

          if (shouldTrackTTD) {
            trackTTD({ conversionId: TTDConversionIds[event] });
          }
        },
      );
    });
};

/**
 * trackEvent is a thin implementation wrapper around Segment's Track method.
 * @param {Object} args
 * @param {string} args.event - name of event, required by Segment Track method
 * @param {Object} [args.payload] - optional properties for Segment Track method
 * @param {Function} [args.callback] - optional callback for Segment Track method to run
 */
const trackEvent = ({
  event,
  payload = {},
  callback = () => {
    return undefined;
  },
}) => {
  const defaultParams = utils.getDefaultParameters();

  // Normalize event name to SNAKE_CASE
  event = event.replace(/ /g, '_').toUpperCase();

  // Segment Track method API
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
  if (window?.analytics?.track !== undefined) {
    window.analytics.track(
      event,
      {
        ...defaultParams,
        ...payload,
      },
      {
        context: {
          ip: '0.0.0.0',
        },
        integrations: window?.analytics?.integrations || {},
      },
      callback,
    );
  }
};

export default trackEvent;
