import React from 'react';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

// helpers
import { CredibilityQuoteTypes } from 'src/lib/prop-types.js';

const CredibilityQuote = ({
  'quote-headline': headline = '',
  'quote-label': label = '',
  'quote-text': text,
  'show-quote-logo-asset': showLogo = false,
  'logo-src-small': logo,
  'quote-byline': byline,
  'cta-link': ctaLink,
  'cta-text': ctaText,
  trackingId = '',
}) => (
  <div className='grid-x credibility-quote'>
    <div className='cell medium-12'>
      {headline && <h3 className='credibility-quote__headline'>{headline}</h3>}
      {label && <h6 className='credibility-quote__label'>{label}</h6>}
      <span className='credibility-quote__icon'></span>
      <p className='credibility-quote__text'>{text}</p>
      <div
        className={`credibility-quote__attribution ${
          showLogo ? 'credibility-quote__attribution--grouped' : ''
        }`}
      >
        {showLogo && (
          <div className='credibility-logo credibility-logo--grouped hide-for-small-only'>
            <div className='credibility-logo__asset'>
              <Image src={logo.src} alt={logo.alt} />
            </div>
          </div>
        )}
        <div>
          <p
            className={`credibility-quote__byline ${
              showLogo ? 'credibility-quote__byline--constrained' : ''
            }`}
          >
            {byline}
          </p>
          <div className='credibility-quote__cta hide-for-small-only'>
            <Link href={ctaLink} trackingId={trackingId}>
              <a>
                <span className='feature-section-link-text'>
                  {ctaText} <ArrowRightSingleSmall />
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CredibilityQuote.propTypes = CredibilityQuoteTypes;
export default CredibilityQuote;
