// TODO: https://jira.plaid.com/browse/SITE-1994
// Optimize How it Works page/data-source with more descriptive, "self-documenting"
// and easier to reason about at-a-glance prop names

export const metaData = {
  'meta-title': 'Plaid helps you link your financial institutions',
  'meta-description':
    'Plaid’s technology provides connections to more than 11,000 US banks and credit unions. Learn how we power the apps in your financial life.',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-5 large-5',
  label: false,
  header: 'Plaid powers the apps in your financial life',
  'header-class': 'h2',
  subheader:
    'With Plaid, you can securely connect your financial accounts to an app in seconds',
  'primary-cta': true,
  'primary-cta-text': 'Discover apps',
  'primary-cta-link': '/discover-apps',
  'secondary-cta': false,
  video: true,
  'video-width': 740,
  'video-height': 592,
  'video-poster-image': '/assets/img/hero/consumer-hero-right-us-en.png',
  'video-fallback-image': '/assets/img/hero/consumer-hero-right-us-en.png',
  'video-formats': [
    {
      url: '/assets/video/consumer-hero-right-us-en.webm',
      type: 'webm',
    },
    {
      url: '/assets/video/consumer-hero-right-us-en.mp4',
      type: 'mp4',
    },
  ],
  'subheader-p': true,
};

export const featuresData = {
  'header-section': false,
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/consumers/how-it-works/feature-icon-connect.png',
      'feature-title': 'Connect effortlessly',
      'feature-body':
        'Plaid lets you connect your financial accounts to your apps in seconds.',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-it-works/feature-icon-share.png',
      'feature-title': 'Share your data securely',
      'feature-body':
        'When you want to share data from your financial accounts with an app, Plaid works to transfer your data securely.',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-it-works/feature-icon-control.png',
      'feature-title': "You're in control",
      'feature-body':
        'Plaid provides the tools to easily delete your data from our systems as well as connect, manage and disconnect your fintech apps.',
      'wider-feature': true,
    },
  ],
};

export const appsHeading = '';
export const appsHeading2 = 'How Plaid works';
export const categoriesHeading = 'Find and try a new financial app';
export const categoriesP =
  'Whether you’re building a budget or sending money to a friend, there are thousands of apps to choose from.';

export const categoryData = [
  {
    color: 'green600',
    title: 'Banking',
  },
  {
    color: 'green200',
    title: 'Wealth',
  },
  {
    color: 'yellow600',
    title: 'Personal finances',
  },
  {
    color: 'purple400',
    title: 'Business finances',
  },
  {
    color: 'blue600',
    title: 'Payment',
  },
  {
    color: 'pink400',
    title: 'Lending',
  },
];

export const iconColumnData = [
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'quarter',
    'push-down': 1,
    icon: [
      {
        name: 'M1 Finance',
        slug: 'm1-finance',
      },
      {
        name: 'Simple',
        slug: 'simple',
      },
      {
        name: 'Chime',
        slug: 'chime',
      },
    ],
  },
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'half',
    'push-down': 2,
    'highlight-column': true,
    icon: [
      {
        name: 'Oportun',
        slug: 'oportun',
        detail:
          'Oportun uses <strong>financial data</strong> to help you save a little bit every single day.',
      },
      {
        name: 'Pillar',
        slug: 'pillar',
        detail:
          'Pillar uses <strong>account data</strong> to give you insights that may help you pay off your loans faster.',
      },
      {
        name: 'Venmo',
        slug: 'venmo',
        detail:
          'Venmo uses your <strong>account information</strong> so you can set up a way to send and receive money.',
      },
    ],
  },
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'quarter',
    'push-down': 1,
    icon: [
      {
        name: 'Brigit',
        slug: 'brigit',
      },
      {
        name: 'Wise',
        slug: 'wise',
      },
      {
        name: 'Betterment',
        slug: 'betterment',
      },
    ],
  },
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'eighth',
    icon: [
      {
        name: 'Expensify',
        slug: 'expensify',
      },
      {
        name: 'Qapital',
        slug: 'qapital',
      },
      {
        name: 'MoneyLion',
        slug: 'moneylion',
      },
      {
        name: 'Albert',
        slug: 'albert',
      },
    ],
  },
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'quarter',
    'push-down': 1,
    icon: [
      {
        name: 'Drop',
        slug: 'drop',
      },
      {
        name: 'Rocket Money',
        slug: 'rocketmoney',
      },
      {
        name: 'Current',
        slug: 'current',
      },
    ],
  },
  {
    'grid-classes': 'show-for-medium',
    'slide-amount': 'half',
    'push-down': 2,
    icon: [
      {
        name: 'YNAB',
        slug: 'ynab',
      },
      {
        name: 'Varo',
        slug: 'varo',
      },
    ],
  },
  {
    'grid-classes': 'show-for-large',
    'slide-amount': 'eighth',
    icon: [
      {
        name: 'SoFi',
        slug: 'sofi',
      },
      {
        name: 'Earnin',
        slug: 'earnin',
      },
      {
        name: 'Prosper',
        slug: 'prosper',
      },
      {
        name: 'Acorns',
        slug: 'acorns',
      },
    ],
  },
  {
    'grid-classes': 'show-for-large',
    'slide-amount': 'quarter',
    'push-down': 1,
    icon: [
      {
        name: 'WorldRemit',
        slug: 'worldremit',
      },
      {
        name: 'Ellevest',
        slug: 'ellevest',
      },
      {
        name: 'Cleo',
        slug: 'cleo',
      },
    ],
  },
  {
    'grid-classes': 'show-for-small-only',
    'slide-amount': 'eighth',
    icon: [
      {
        name: 'M1 Finance',
        slug: 'm1-finance',
      },
      {
        name: 'Simple',
        slug: 'simple',
      },
      {
        name: 'Chime',
        slug: 'chime',
      },
      {
        name: 'Expensify',
        slug: 'expensify',
      },
      {
        name: 'SoFi',
        slug: 'sofi',
      },
    ],
  },
  {
    'grid-classes': 'show-for-small-only',
    'slide-amount': 'quarter',
    'push-down': 1,
    icon: [
      {
        name: 'Oportun',
        slug: 'oportun',
      },
      {
        name: 'Pillar',
        slug: 'pillar',
      },
      {
        name: 'Venmo',
        slug: 'venmo',
      },
      {
        name: 'Drop',
        slug: 'drop',
      },
      {
        name: 'Prosper',
        slug: 'prosper',
      },
    ],
  },
  {
    'grid-classes': 'show-for-small-only',
    'slide-amount': 'half',
    'push-down': 2,
    icon: [
      {
        name: 'SoFi',
        slug: 'sofi',
      },
      {
        name: 'Wise',
        slug: 'wise',
      },
      {
        name: 'Betterment',
        slug: 'betterment',
      },
      {
        name: 'YNAB',
        slug: 'ynab',
      },
    ],
  },
];

export const highlightHeading = 'Understand how Plaid powers your apps';
export const highlightP =
  'Plaid provides tools that enable you to share your financial data with apps that provide services to you.';
export const app1Heading = 'Connect your financial account using Plaid';
export const app1P =
  'When you choose to connect your financial accounts to an app using Plaid, you will be prompted to enter the username and password associated with those accounts. Plaid then links your accounts to the app you want to use so you can share your data.';
export const app1CTA = 'How we handle data';
export const app1Link = '/how-we-handle-data';
export const app1Alt = 'Venmo screen';
export const securityHeading = 'Plaid’s technology helps keep your data safe';
export const securityP =
  'To help keep your data safe, we don’t share the username and password for your financial institution with your apps. Once you give us permission to share your financial data, we securely transfer it from your financial institution to the app through our application programming interface (API).';
export const securityCTA = 'Learn more about security';
export const securityLink = '/safety';
export const securityAlt = 'Security screen';

export const securityScreenInfo = [
  {
    'hr-delay': 1200,
    'copy-delay': 1500,
    copy:
      'Advanced Encryption Standard (AES-256) and Transport Layer Security (TLS)',
  },
  {
    'hr-delay': 2400,
    'copy-delay': 2700,
    copy: 'Secure cloud infrastructure technologies',
  },
  {
    'hr-delay': 3600,
    'copy-delay': 3900,
    copy: 'Security audits by researchers and financial institutions',
  },
];

export const securityScreenIcon = [
  {
    delay: 600,
  },
  {
    delay: 600,
  },
  {
    delay: 600,
  },
  {
    delay: 600,
  },
  {
    delay: 900,
  },
  {
    delay: 600,
  },
  {
    delay: 900,
  },
  {
    delay: 1800,
  },
  {
    delay: 1800,
  },
  {
    delay: 2100,
  },
  {
    delay: 1800,
  },
  {
    delay: 2100,
  },
  {
    delay: 3000,
  },
  {
    delay: 3000,
  },
  {
    delay: 3000,
  },
  {
    delay: 3300,
  },
  {
    delay: 3000,
  },
  {
    delay: 3300,
  },
];

export const securityAppCategory = [
  {
    name: 'Traditional Banks',
    company: [
      {
        name: 'Wells Fargo',
        slug: 'wells-fargo',
      },
      {
        name: 'Chase',
        slug: 'chase',
      },
      {
        name: 'U.S. Bank',
        slug: 'usbank',
      },
    ],
  },
  {
    name: 'Credit Union',
    company: [
      {
        name: 'Navy Federal',
        slug: 'navy-federal',
      },
      {
        name: 'Suncoast',
        slug: 'suncoast',
      },
      {
        name: 'First Tech',
        slug: 'first-tech',
      },
    ],
  },
  {
    name: 'Investment Banks',
    company: [
      {
        name: 'Empower',
        slug: 'empower',
      },
      {
        name: 'E*TRADE',
        slug: 'etrade',
      },
      {
        name: 'Morgan Stanley',
        slug: 'morgan-stanley',
      },
    ],
  },
  {
    name: 'Digital Banks',
    company: [
      {
        name: 'Simple',
        slug: 'simple',
      },
      {
        name: 'Chime',
        slug: 'chime',
      },
      {
        name: 'Aspiration',
        slug: 'aspiration',
      },
    ],
  },
];

export const security2Heading =
  'Continue building your financial life with apps powered by Plaid';
export const security2P =
  'Money is one of the biggest sources of stress in people’s lives. We want to make it easier for you to share your financial data with the apps you want to use. Whether you have an account at a national bank, a local bank, a credit union, an investment bank, or a digital bank, Plaid helps you get connected.';
export const security2CTA = 'Why is Plaid involved?';
export const security2Link = '/why-is-plaid-involved';
export const security2Alt = 'Plaid logo';
export const app2Heading = 'And just like that, you’re enjoying your new app';
export const app2P =
  'After you securely connect with Plaid, you’re quickly and safely back to using your new financial app.';
export const app2Alt = 'Chase screen';

export const manageConnectionsData = {
  type: 'single-image',
  'large-image': true,
  'extra-padding': false,
  title: 'Plaid can help you control access to your data',
  description: [
    'When you connect your financial accounts to apps with Plaid, you have control over who you’re sharing your data with. To disconnect your financial accounts from apps, or to delete your data from Plaid’s systems, visit Plaid Portal.',
  ],
  'bg-color': 'white',
  'img-brushtroke': '/assets/img/accents/brushstroke-left-purple-2.png',
  images: [
    {
      'img-src':
        '/assets/img/consumers/how-it-works/mobile-web-page-myplaid.png',
      'alt-text': 'Manage your connections anytime',
      'has-shadow': false,
    },
  ],
  'has-cta': true,
  'cta-url': 'https://my.plaid.com/',
  'cta-text': 'Explore Plaid Portal',
  'has-text-cta': false,
  'text-cta-text': 'Get help',
  'text-cta-url': 'https://my.plaid.com/help',
  'cta-class': 'with-text-cta',
  reverse: true,
};

export const noCostData = {
  type: 'video',
  'extra-padding': false,
  title: 'Plaid doesn’t charge you for our services',
  description:
    'When you connect your financial accounts to an app like Venmo or Oportun, the app pays us a fee to enable that connection. We don’t charge you for connecting your accounts.',
  'bg-color': 'white',
  videos: [
    {
      'video-width': 740,
      'video-height': 592,
      'video-poster-image': '/assets/video/business-model-us-en.png',
      'video-fallback-image': '/assets/video/business-model-us-en.png',
      'video-formats': [
        {
          url: '/assets/video/business-model-us-en.png',
          type: 'webm',
        },
        {
          url: '/assets/video/business-model-us-en.mp4',
          type: 'mp4',
        },
      ],
    },
  ],
};

export const resourcesData = {
  'header-section': true,
  'header-subtext': 'RESOURCES',
  'header-text': 'Take the next step',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/discover-apps.png',
      'feature-title': 'Discover apps',
      'feature-body': 'Meet a few of the apps that are built with Plaid.',
      'feature-url': '/discover-apps',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/manage-connections.png',
      'feature-title': 'Manage connections',
      'feature-body':
        'See the types of data you’ve shared and manage your connections.',
      'feature-url': 'https://my.plaid.com',
      'feature-link-text': 'Learn more',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/why-is-plaid-involved.png',
      'feature-title': 'Why is Plaid involved?',
      'feature-body':
        'Connecting your financial accounts just got a whole lot easier.',
      'feature-url': '/why-is-plaid-involved',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
  ],
};

export const textBlockData = {
  'p-classes': 'disclaimer',
  content:
    'The inclusion of certain applications on this page does not constitute or imply an endorsement of the application by Plaid.',
};
