import * as React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import {
  Carousel,
  SimpleSlide,
  carouselWithSimpleSlideAdapter,
} from 'src/components/Carousel';
import {
  CredibilityCarousel,
  careersCredibilityCarouselAdapter,
  credibilityCarouselAdapter,
} from 'src/components/Credibility/CredibilityCarousel';
import { ContentfulTemplates, isProduction } from 'src/lib/constants';
import { FeatureCarousel } from 'src/sections';
import { RichTextSwitch } from 'src/containers';

export interface ICarouselSwitchProps {
  component: any;
  idx: number;
  templatePage: string;
}

const CarouselSwitch: React.FC<ICarouselSwitchProps> = ({
  component,
  idx,
  templatePage,
}) => {
  try {
    const type = component?.fields?.type;
    // we short-circuit to the new multi-select carousel if the appearance
    // is set to 'multi-select'. Otherwise we have logic below to render legacy carousels
    if (type === 'multi-select') {
      // during development we changed the carousel CMS from using the carouselSlides field
      // to using the multiSelectItems field. This bit of code below is for backwards compatibility
      // it's unlikely that it is needed, but 🤷🏻‍♂️
      const slides = _uniqBy(
        [
          ..._get(component, 'fields.carouselSlides', []),
          ..._get(component, 'fields.multiSelectItems', []),
        ].filter((slide) => {
          return _get(slide, 'sys.contentType.sys.id') === 'multiSelectItem';
        }),
        'sys.id',
      );

      return (
        <FeatureCarousel
          sys={component?.sys}
          sx={component?.fields?.styles}
          fieldId='internalTitle'
          interval={10000}
          items={slides.map((slide) => {
            return {
              content: (
                <RichTextSwitch
                  sys={slide?.sys}
                  content={slide?.fields?.body}
                  nodes={{
                    p: {
                      variant: 'p2',
                      fieldId: 'body',
                    },
                  }}
                />
              ),
              aria: {
                label: documentToPlainTextString(slide?.fields?.body),
              },
              image: {
                src: 'https:' + slide?.fields?.media?.fields?.file?.url,
                alt:
                  slide?.fields?.media?.fields?.description ||
                  slide?.fields?.media?.fields?.title,
                width: '670',
                height: '760',
                sys: slide?.sys,
                fieldId: 'media',
              },
              sx: slide?.fields?.styles,
            };
          })}
        >
          <RichTextSwitch
            sys={component?.sys}
            content={component?.fields?.body}
            nodes={{
              h2: {
                variant: 'h3',
                fieldId: 'body',
              },
              p: {
                variant: 'p',
                fieldId: 'body',
              },
            }}
          />
        </FeatureCarousel>
      );
    }

    // credibility carousel
    const slides = [
      ..._get(component, 'fields.carouselSlides', []),
      ..._get(component, 'fields.credibilitySlides', []),
    ];
    const hasCredibilityItems = slides.some((item) => {
      return item.sys.contentType.sys.id === 'credibilityItem';
    });
    if (hasCredibilityItems) {
      const adapterData =
        templatePage === ContentfulTemplates.PRODUCT_PAGE
          ? { ...credibilityCarouselAdapter(component.fields) }
          : { ...careersCredibilityCarouselAdapter(component.fields) };
      return <CredibilityCarousel key={`carousel-${idx}`} {...adapterData} />;
    } else {
      // standard carousel
      const { 'carousel-slides': carouselSlides } =
        carouselWithSimpleSlideAdapter(component?.fields);
      return (
        <Carousel key={`carousel-${idx}`}>
          {carouselSlides.map((slide, idx) => {
            return <SimpleSlide key={`scs-${idx}`} {...slide} />;
          })}
        </Carousel>
      );
    }
  } catch (e) {
    return !isProduction ? <p>Oops! Something went wrong: {e}</p> : null;
  }
};

export default CarouselSwitch;
