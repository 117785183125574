import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { BetaLabel } from 'src/components/Surfaces/Label';
import Image from 'src/components/Image';
import { Link } from 'src/components/Inputs';
import { ConditionalWrapper, Unless } from '../helpers';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

const propTypes = {
  'no-link': PropTypes.bool, // 😅 Wraps the markup in a hyperlink when false
  'link-text': PropTypes.string, // 😅 Required when when no-link is false
  'link-location': PropTypes.string, // 😅 The hypertext reference for the hyperlink
  isFullWidth: PropTypes.bool, // For adding full width to an item image and body container
  img: PropTypes.string.isRequired,
  mediumImgWidth: PropTypes.number, // For custom width of an img container
  'header-class': PropTypes.string, // For custom styling of the H4 element
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // body content
  isBetaFeature: PropTypes.bool,
};

const FeatureSectionItemBody = ({
  'no-link': noLink,
  'link-text': linkText,
  'header-class': headerClass,
  title,
  content,
  content2,
  isBetaFeature = false,
}) => (
  <>
    {isBetaFeature && <BetaLabel />}
    <h4
      className={`feature-section-item-title ${headerClass}`}
      dangerouslySetInnerHTML={{ __html: title }}
    ></h4>
    <p
      className='feature-section-item-description'
      dangerouslySetInnerHTML={{ __html: content }}
    />
    {content2 && content2}
    <Unless condition={noLink}>
      <span className='feature-section-link-text'>
        {linkText}&nbsp;
        <ArrowRightSingleSmall />
      </span>
    </Unless>
  </>
);

const FeatureSectionItemImageVariant = (props) => (
  <div className='grid-container full'>
    <div className='grid-x'>
      <div
        className={cx('cell', {
          'medium-4': !props.isFullWidth,
          [`medium-${props.mediumImgWidth}`]: props.mediumImgWidth,
        })}
      >
        <Image
          className={cx('feature-section-img', {
            'feature-section-img--wide': props.isFullWidth,
          })}
          src={props.img}
          alt=''
        />
      </div>
      <div
        className={cx('cell', {
          'medium-auto': !props.isFullWidth || props.mediumImgWidth,
          'feature-section-item-body': !props.isFullWidth,
        })}
      >
        <FeatureSectionItemBody {...props} />
      </div>
    </div>
  </div>
);

const FeatureSectionItem = (props) => {
  return (
    <div
      className={cx('cell', 'small-12', 'medium-6', 'feature-section-item', {
        isBetaFeature: props.isBetaFeature,
      })}
    >
      <ConditionalWrapper
        condition={!props['no-link']}
        wrapper={(children) => (
          <Link href={props['link-location']}>
            <a>{children}</a>
          </Link>
        )}
      >
        <FeatureSectionItemImageVariant
          isFullWidth={props.fullWidthImageContainer}
          {...props}
        />
      </ConditionalWrapper>
    </div>
  );
};

FeatureSectionItem.propTypes = propTypes;

export default FeatureSectionItem;
