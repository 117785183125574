import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// components
import Icon from './Icon';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

// helper
import { optimizeImage } from 'src/lib/image-helpers';

const propTypes = {
  classes: PropTypes.string,
  'with-story-header': PropTypes.bool,
  'remove-top-padding': PropTypes.bool,
  'remove-bottom-padding': PropTypes.bool,
  'left-label-1': PropTypes.string,
  'left-label-1-content': PropTypes.string,
  'right-label-1': PropTypes.string,
  'right-label-1-content': PropTypes.string,
  'right-label-2': PropTypes.string,
  'right-label-2-content': PropTypes.string,
  'custom-titles': PropTypes.bool,
  'problem-classes': PropTypes.string,
  'problem-title': PropTypes.string,
  'problem-statement': PropTypes.string.isRequired,
  'problem-subtext': PropTypes.string,
  'solution-classes': PropTypes.string,
  'solution-title': PropTypes.string,
  'solution-statement': PropTypes.string.isRequired,
  'solution-subtext': PropTypes.string,
  'problem-cta-text': PropTypes.bool,
  'problem-cta-link': PropTypes.string,
  problemIcon: PropTypes.string,
  solutionIcon: PropTypes.string,
  backgroundImageBottomLeft: PropTypes.string,
  backgroundImageTopRight: PropTypes.string,
};

const IntroSection = ({
  classes,
  'with-story-header': withStoryHeader,
  'remove-top-padding': removeTopPadding,
  'remove-bottom-padding': removeBottomPadding,
  'left-label-1': leftLabel1,
  'left-label-1-content': leftLabel1Content,
  'right-label-1': rightLabel1,
  'right-label-1-content': rightLabel1Content,
  'right-label-2': rightLabel2,
  'right-label-2-content': rightLabel2content,
  'custom-titles': customTitles,
  'problem-classes': problemClasses,
  'problem-title': problemTitle,
  'problem-statement': problemStatement,
  'problem-subtext': problemSubtext,
  'problem-cta-text': problemCTAText,
  'problem-cta-link': problemCTALink,
  'solution-classes': solutionClasses,
  'solution-title': solutionTitle,
  'solution-statement': solutionStatement,
  'solution-subtext': solutionSubtext,
  problemIcon,
  solutionIcon,
  backgroundImageBottomLeft = '',
  backgroundImageTopRight = '',
}) => (
  <div
    className={`intro-section ${classes}`}
    style={{
      backgroundImage:
        backgroundImageBottomLeft && backgroundImageTopRight
          ? `url('${optimizeImage(
              backgroundImageBottomLeft,
            )}'),url('${optimizeImage(backgroundImageTopRight)}')`
          : 'none',
    }}
  >
    <div className='grid-container'>
      {withStoryHeader && (
        <div
          className={cx('grid-x', 'intro-section__item', {
            'intro-section__item--remove-top-padding': removeTopPadding,
          })}
        >
          <div className='cell small-12 medium-8 medium-offset-4'>
            <div className='grid-x'>
              <div className='cell small-6'>
                <strong>{leftLabel1}:</strong>{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: leftLabel1Content,
                  }}
                />
              </div>
              <div className='cell small-6'>
                <div>
                  <strong>{rightLabel1}:</strong> {rightLabel1Content}
                </div>
                <div>
                  <strong>{rightLabel2}:</strong> {rightLabel2content}
                </div>
              </div>
              <div className='cell small-12'>
                <hr className='hr hr--margin-3' />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={cx('grid-x', 'intro-section__item', {
          'intro-section__item--remove-top-padding': removeTopPadding,
          'intro-section__item--remove-top-padding intro-section__item--remove-top-margin': withStoryHeader,
        })}
      >
        <div className='cell small-12 medium-3'>
          {customTitles ? (
            <h6 className='intro-section__label'>
              {problemIcon && <Icon type={problemIcon} />} {problemTitle}
            </h6>
          ) : (
            <h6 className='intro-section__label'>
              <Icon type={'problem'} /> The Problem
            </h6>
          )}
        </div>
        <div className='cell small-12 medium-8 medium-offset-1'>
          <h4
            className={cx('regular', 'intro-section__text', problemClasses)}
            dangerouslySetInnerHTML={{ __html: problemStatement }}
          />
          {problemSubtext && (
            <p
              className='intro-section__subtext'
              dangerouslySetInnerHTML={{ __html: problemSubtext }}
            />
          )}
          {problemCTAText && (
            <div className='intro-section__cta'>
              <Link href={problemCTALink}>
                <a>
                  <span className='feature-section-link-text'>
                    {problemCTAText} <ArrowRightSingleSmall />
                  </span>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx('grid-x', 'intro-section__item', {
          'intro-section__item--remove-bottom-padding': removeBottomPadding,
        })}
      >
        <div className='cell small-12 medium-3'>
          {customTitles ? (
            <h6 className='intro-section__label'>
              {solutionIcon && <Icon type={solutionIcon} />} {solutionTitle}
            </h6>
          ) : (
            <h6 className='intro-section__label'>
              <Icon type={'solution'} /> The solution
            </h6>
          )}
        </div>
        <div className='cell small-12 medium-8 medium-offset-1'>
          <h4 className={cx('regular', 'intro-section__text', solutionClasses)}>
            {solutionStatement}
          </h4>
          {solutionSubtext && (
            <p
              className='intro-section__subtext'
              dangerouslySetInnerHTML={{ __html: solutionSubtext }}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

IntroSection.propTypes = propTypes;
export default IntroSection;
