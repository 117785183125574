/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the contentful response for image content type to the view
 *
 * @param {Object} args
 * @param {Object} args.image
 * @param {String=} args.caption
 * @param {String=} args.url
 * @returns {Object}
 */
export const imageAdapter = ({ image, caption, url }) => {
  const imageUrl = _get(image, 'fields.file.url');
  const urlHasProtocol = /^(https?:)?\/\//.test(imageUrl);
  return {
    src: `${imageUrl && !urlHasProtocol ? 'https://' : ''}${_get(
      image,
      'fields.file.url',
    )}`,
    alt: _get(image, 'fields.description'),
    caption,
    url,
  };
};
