import React, { FC } from 'react';
import cx from 'classnames';

interface HeaderItem {
  label: string;
  content: string;
}

interface IntroSectionHeader {
  headerItems: HeaderItem[];
}

const IntroSectionHeader: FC<IntroSectionHeader> = ({ headerItems = [] }) => {
  const items = [...headerItems];
  const { label = '', content = '' } = items.shift();

  return (
    <div
      className={cx('grid-x', 'intro-section__item')}
      data-testid='intro-section-header'
    >
      <div className='cell small-12 medium-8 medium-offset-4'>
        <div className='grid-x'>
          <div className='cell small-6' data-testid='intro-section-header-item'>
            <strong>{label}:</strong>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
          <div className='cell small-6'>
            {items.map((item, i) => (
              <div
                key={`intro-section-header-item-${i}`}
                data-testid='intro-section-header-item'
              >
                <strong>{item?.label}:</strong> {item?.content}
              </div>
            ))}
          </div>
          <div className='cell small-12'>
            <hr className='hr hr--margin-3' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSectionHeader;
