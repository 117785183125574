import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'src/components/headers/page-header.jsx';

const CustomerStoriesPageHeaderPropTypes = {
  primaryHeading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

const CustomerStoriesPageHeader = ({ primaryHeading, subheading = '' }) => {
  // TODO: https://jira.plaid.com/browse/SITE-1926
  // Customer Stories version of page header uses the 'no-min-height-header' prop
  // for layout purposes. This implementation detail can be revisited when
  // we refactor the legacy page-header
  const props = { 'no-min-height-header': true };

  return (
    <div className='customer-stories__page-header'>
      <PageHeader header={primaryHeading} subheader={subheading} {...props} />
    </div>
  );
};

CustomerStoriesPageHeader.propTypes = CustomerStoriesPageHeaderPropTypes;
export default CustomerStoriesPageHeader;
