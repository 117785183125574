import React from 'react';
import cx from 'classnames';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';

export interface AccountTableItemProps {
  classes?: string;
  hasDivider?: boolean;
  hasFeature?: boolean;
  itemName: string;
  plainText?: boolean;
  tooltip?: string;
}

const AccountTableItem: React.FC<AccountTableItemProps> = ({
  classes = '',
  hasDivider = false,
  hasFeature = false,
  itemName,
  plainText = false,
  tooltip = '',
}) => (
  <div
    className={cx(
      'account-table-list-item',
      'account-column-one',
      hasFeature && 'has-feature',
      hasDivider && 'item-divider',
      plainText && 'plain-text',
    )}
  >
    {hasFeature && (
      <span className='check-icon' data-testid='check-icon'>
        <Checkmark />
      </span>
    )}
    <span
      {...(hasFeature && {
        'data-tooltip': true,
        'data-tooltip-class': cx(hasFeature && 'tooltip info-tooltip'),
        'data-testid': 'tooltip',
        title: tooltip,
      })}
      className={cx(
        'top',
        'account-table-list-item-text',
        tooltip && 'has-tip',
        classes && classes,
      )}
    >
      {itemName}
    </span>
  </div>
);

export default AccountTableItem;
