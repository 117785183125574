import { CmsThemes } from 'src/lib/constants';

export const eyebrowStyles = {
  [CmsThemes.WEB2]: {
    color: 'web2/text/eyebrow',
  },
  [CmsThemes.WEB3]: {
    color: 'web3/text/eyebrow',
  },
};

export const headingStyles = {
  [CmsThemes.WEB3]: {
    color: 'web3/text/heading',
  },
};

export const paragraphStyles = {
  [CmsThemes.WEB2]: {
    color: 'web2/text/paragraph',
  },
  [CmsThemes.WEB3]: {
    color: 'web3/text/paragraph',
  },
};
