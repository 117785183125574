export const payoutsRequest = {
  flipped: true,
  'bg-first-image': true,
  'title-text': 'Create a Payout',
  'description-text':
    'The /wallet/transaction/execute endpoints allows you to create a payout',
  'code-title': 'Create payout request',
  'code-classes': 'code-container--payout-create',
  'code-lang': 'json',
  'code-body': `
  http code 200
  {
    "client_id": String, Required,
    "secret": String, Required,
    "idempotency_key": String, Required,
    "wallet_id": String, Required,
    "reference": String, Required,
    "amount": {
      "iso_currency_code": String,
      "value": Number,
    }, Required,
    "counterparty": {
      "name": String, Required,
      "numbers": {
        "bacs": {
          "account_number": String,
          "sort_code": String,
        }, Required,
      }, Required,
    }, Required,
  }
  `,
  properties: [
    {
      title: 'idempotency_key',
      description: 'Provide a unique key to prevent duplicate payouts',
      'code-line-start': 5,
      'code-highlight-length': 1,
    },
    {
      title: 'wallet_id',
      description: 'e-money account from which to transfer money',
      'code-line-start': 6,
      'code-highlight-length': 1,
    },
    {
      title: 'amount',
      description: 'Value in specified currency',
      'code-line-start': 8,
      'code-highlight-length': 1,
    },
    {
      title: 'counterparty',
      description: 'The destination account',
      'code-line-start': 12,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/payouts',
  'bottom-cta-text': 'View API docs',
};

export const payoutsResponse = {
  flipped: true,
  'bg-first-image': true,
  'title-text': 'Payout response',
  'description-text':
    'The /wallet/transaction/execute endpoint returns a transaction_id, status, and request_id.',
  'code-title': 'Get payout response',
  'code-classes': 'code-container--payout-get',
  'code-lang': 'json',
  'code-body': `
  http code 200
  {
    "request_id": String,
    "transaction_id": String,
    "status": String, 
  }
  `,
  properties: [
    {
      title: 'transaction_id',
      description: 'The payout identifier which can later be retrieved',
      'code-line-start': 4,
      'code-highlight-length': 1,
    },
    {
      title: 'status',
      description: 'Returns the status of the payment',
      'code-line-start': 5,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/payouts',
  'bottom-cta-text': 'View API docs',
};
