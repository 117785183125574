import React from 'react';
import DescriptionRow from 'src/components/description-row';
import { SupportingText } from 'src/components/Layout/SupportingText';

const Regulatory = ({ id, descriptionRow, supportingText }) => {
  return (
    <div className='regulatory-section' id={id}>
      <DescriptionRow {...descriptionRow} />
      <SupportingText {...supportingText} />
    </div>
  );
};

export default Regulatory;
