import React from 'react';

import { Stack } from 'src/components-v2/Layout';
import { AnimatedVideo, Image } from 'src/components-v2/Media';
import { assetIsImage, assetIsVideo, byVideoType } from 'src/lib/utils';

const MediaColumn = ({ media }) => {
  const isImage = media?.every(assetIsImage);
  const imgSrc = media && 'https:' + media[0]?.fields?.file?.url;
  const isVideo = media?.every(assetIsVideo);
  const sortedVideos = media?.sort(byVideoType);
  return (
    <Stack sx={{ height: '100%', justifyContent: 'center' }}>
      {isImage && (
        <Image
          width={670}
          height={700}
          src={imgSrc + '?fm=webp&w=1340&q=70'}
          alt='document verification'
          sources={[
            {
              media: '(max-width: 639px)',
              srcSet: imgSrc + '?fm=webp&w=650&q=70',
            },
            {
              media: '(min-width: 640px)',
              srcSet: imgSrc + '?fm=webp&w=1340&q=70',
            },
          ]}
        />
      )}
      {isVideo && (
        <AnimatedVideo
          width={600}
          height={700}
          sx={{ mt: 'auto', height: 'auto', width: '100%' }}
          sources={sortedVideos?.map((asset) => {
            return {
              src: asset?.fields?.file?.url,
              type: asset?.fields?.file?.contentType,
            };
          })}
        />
      )}
    </Stack>
  );
};

export default MediaColumn;
