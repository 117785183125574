/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 *
 * An adapter allows two incompatible interfaces to work together.
 * This is the real-world definition for an adapter. Interfaces may be incompatible,
 * but the inner functionality should suit the need. The adapter design pattern allows
 * otherwise incompatible classes to work together by converting the interface of one
 * class into an interface expected by the clients.
 *
 * Convert the interface of a class into another interface clients expect.
 * Adapter lets classes work together that couldn’t otherwise because of incompatible
 * interfaces.
 */

/**
 * Maps the contentful response for mvp content block content type to the view
 *
 * @param {Object} args
 * @param {string} args.primaryHeading
 * @param {string} args.secondaryHeading
 * @param {Object} args.body
 * @param {Object} args.quoteWithImage
 * @returns {Object}
 */
export const mvpContentBlockAdapter = ({
  primaryHeading,
  secondaryHeading,
  body,
  quoteWithImage,
  cta,
}) => {
  // `attribution` is a required field, empty string only possible in drafts
  const attribution = _get(quoteWithImage, 'fields.attribution', '');
  return {
    title: primaryHeading,
    heading: secondaryHeading,
    paragraph: body,
    cta,
    image: {
      src: _get(quoteWithImage, 'fields.image.fields.file.url', ''),
      alt: _get(quoteWithImage, 'fields.image.fields.description', attribution),
    },
    quote: quoteWithImage
      ? [
          {
            copy: _get(quoteWithImage, 'fields.quote', ''),
            source: attribution,
          },
        ]
      : [],
  };
};
