import {
  investmentsHoldingData,
  investmentsTransactionsData,
} from './products/investments';
import {
  investmentsHoldingsExampleData,
  investmentsTrxnsExampleData,
} from './en-gb/products/investments';
import {
  apiCreateExampleData,
  apiGetExampleData,
} from './en-gb/products/payment-initiation';
import { payoutsRequest, payoutsResponse } from './en-gb/products/payouts';

export const staticData = {
  'Investments Holdings': investmentsHoldingData,
  'Investments Transactions': investmentsTransactionsData,
  'UK Investments Holdings': investmentsHoldingsExampleData,
  'UK Investments Transactions': investmentsTrxnsExampleData,
  'UK Payment Initiation Request': apiCreateExampleData,
  'UK Payment Initiation Status': apiGetExampleData,
  'UK Payouts Request': payoutsRequest,
  'UK Payouts Response': payoutsResponse,
};
