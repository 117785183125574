import products from 'src/store/navigation/products';
import useCases from 'src/store/navigation/use-cases';
import forDevelopers from 'src/store/navigation/for-developers';
import aboutUs from 'src/store/navigation/about-us';
import forConsumers from 'src/store/navigation/for-consumers';
import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

const pricing = {
  label: 'Pricing',
  url: '/pricing/',
  trackingId: NAVIGATION_EVENTS.PRIMARY.USER_CLICKED_PRIMARY_NAV_PRICING,
};

export const mainNavData = {
  skipLinkCta: 'Skip to main content',
  logoAlt: 'Plaid logo',
  menuItems: [
    products,
    useCases,
    pricing,
    forDevelopers,
    aboutUs,
    forConsumers,
  ],
  login: {
    name: 'Log in',
    url: 'https://dashboard.plaid.com/signin/',
  },
  getStarted: {
    name: 'Get started',
    url: 'https://dashboard.plaid.com/signup/',
  },
  getStartedTrackingId: NAVIGATION_EVENTS.PRIMARY.GET_API_KEYS_BUTTON,
};
