export const metaData = {
  'meta-title': 'How we handle your personal financial data',
  'meta-description':
    'At Plaid, we don’t share your personal information without your permission, and we don’t sell or rent it to outside companies.',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-8 large-8',
  label: false,
  'label-text': null,
  header: 'Plaid puts you in control of your financial data.',
  'header-class': 'h2',
  subheader:
    "We were founded on the principle that people have a right to their financial information. And, we are committed to enabling financial freedom by supporting your right to decide where, how, and with whom your data is shared. With Plaid, you don't have to choose between easy access to your financial data and keeping that financial data safe and protected. When you connect through Plaid, you can harness the power of your financial data on a network you can trust.",
  'background-image':
    '/assets/img/accents/how-we-handle-data-brushstroke-hero.png',
  'primary-cta': true,
  'primary-cta-text': 'Read our privacy policy',
  'primary-cta-link': '/legal/#end-user-privacy-policy',
};

export const dataSharingData = {
  type: 'single-image',
  title: 'We build products that limit the data we collect',
  description:
    'Because we believe in collecting only what is needed to deliver services people want, we are transparent about what we collect and we invest in tools that empower people to choose what information they want to share.',
  secondaryDescription:
    'Visit Plaid Portal to view the data types you’ve shared through Plaid and control ongoing access to that data.',
  'bg-color': 'blue200',
  'img-brushtroke': '/assets/img/accents/data-youre-sharing-brushstroke.png',
  images: [
    {
      'img-src': '/assets/img/consumers/how-we-handle-data/data-art.png',
      'alt-text': "The data you're sharing",
      'has-shadow': false,
    },
  ],
  'has-text-cta': true,
  'text-cta-text': 'Visit Plaid Portal',
  'text-cta-url': 'https://my.plaid.com', // TODO: Get real URL
};

export const diagramData = {
  classes: 'background background--image-stroke-accents',
  'stroke-bottom-right':
    '/assets/img/accents/how-we-handle-data-brushstroke.png',
  'medium-column-width': 12,
  heading: 'How we handle your financial data',
  'intro-text':
    'At Plaid, we enable you to share your data with the apps and services you want, protect against fraud and abuse, and enhance your overall digital finance experience. For example, when you use Plaid to connect your financial accounts to power an app it is simple and secure. Here’s what happens on our end.',
  step: [
    {
      number: 1,
      copy:
        'To link a financial account, first verify your ownership of the account by using your username and password. We will provide upfront clear information on the financial accounts being shared, so you can make the right choice for you.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy:
        'Once ownership of your accounts is verified, we securely retrieve your permissioned account information from your financial institution.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'We collect the data to power the services you’ve chosen and, when requested, securely share it with the app you’re using and establish a secure connection that you control.',
    },
  ],
  'icon-row-1': [
    {
      label: 'YOUR APP',
      'label-delay': 1500,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-blue@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
      badge: 'lock',
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      'arrow-down': true,
      'arrow-down-delay': 900,
    },
    {
      label: 'YOUR BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-blue@2x.png',
      'arrow-left': true,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'YOUR APP',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-blue@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const quoteData = {
  quote:
    '<h2>We only share your data when you give your consent</h2> We don’t share it without your permission, and we don’t sell it to outside companies.',
  'quote-link': '/legal',
  'quote-link-text': 'Read our privacy policy',
};

export const featureSectionData = {
  allowBottomPaddingMobile: true,
  'feature-section-title': [
    {
      title: 'We help keep data safe, every step of the way',
      'title-size': 'small',
      description:
        'Your financial information is both personal and powerful – that’s why security is a top priority when we’re designing our products, policies, and practices. Our information security program is designed to meet or exceed industry standards, and we use many different controls to keep your personal information safe.',
      'cta-url': '/safety',
      'cta-text': 'Learn more',
      'medium-column-width': 12,
    },
  ],
  'feature-section-items': [
    {
      img:
        '/assets/img/consumers/how-we-handle-data/security-icon-encryption.png',
      title: 'Data encryption',
      'header-class': 'h5',
      content:
        'The combination of the Advanced Encryption Standard (AES-256) and Transport Layer Security (TLS) help keep your personal information safe.',
      'no-link': true,
    },
    {
      img:
        '/assets/img/consumers/how-we-handle-data/security-icon-authentication.png',
      title: 'Strong authentication',
      'header-class': 'h5',
      content:
        'Plaid requires multi-factor authentication for added security to help protect your data in our systems.',
      'no-link': true,
    },
    {
      img: '/assets/img/consumers/how-we-handle-data/security-icon-cloud.png',
      title: 'Cloud infrastructure',
      'header-class': 'h5',
      content:
        'Plaid uses secure cloud infrastructure technologies to help enable you to connect quickly and securely.',
      'no-link': true,
    },
    {
      img:
        '/assets/img/consumers/how-we-handle-data/security-icon-monitoring.png',
      title: 'Robust monitoring',
      'header-class': 'h5',
      content:
        'The Plaid API and all related components are continuously monitored by our information security team.',
      'no-link': true,
    },
    {
      img: '/assets/img/consumers/how-we-handle-data/security-icon-testing.png',
      title: 'Independent security testing',
      'header-class': 'h5',
      content:
        'Security researchers and financial institutions regularly audit Plaid’s API and security controls.',
      'no-link': true,
    },
  ],
};

export const supportingTextData = {
  classes: 'background background--color-blue200 supporting-text--constrained',
  'three-up': true,
  'has-intro': true,
  'intro-header': "You're in good hands",
  'intro-text':
    'At Plaid, our business model—and our reputation—depend on keeping your data safe. That means working around the clock to help keep your data secure.',
  stat: true,
  stat1: '>8,000',
  text1:
    'There are more than 8,000 financial apps and services that are powered by Plaid.',
  stat2: '>12,000',
  text2:
    'Plaid connects to more than 12,000 financial institutions across the US, Canada, UK, and Europe.',
  stat3: '1 in 3',
  text3:
    '1 in 3 US adults has connected a financial account to an app with Plaid—and that number is growing every day.',
};

export const featuresData = {
  'header-section': true,
  'header-subtext': 'RESOURCES',
  'header-text': 'Take the next step',
  'items-in-columns': true,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/discover-apps.png',
      'feature-title': 'Discover apps',
      'feature-body': 'Meet a few of the apps that are powered by Plaid.',
      'feature-url': '/discover-apps',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/manage-connections.png',
      'feature-title': 'Manage connections',
      'feature-body':
        'See the types of data you’ve shared and manage your connections.',
      'feature-url': '//my.plaid.com', // TODO: Get real URL
      'feature-link-text': 'Learn more',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
    {
      'feature-img':
        '/assets/img/consumers/how-we-handle-data/why-is-plaid-involved.png',
      'feature-title': 'Why is Plaid involved?',
      'feature-body':
        'Connecting your financial accounts just got a whole lot easier.',
      'feature-url': '/why-is-plaid-involved',
      'max-width': 'features-img--48-width',
      'wider-feature': true,
    },
  ],
};
