import PropTypes from 'prop-types';

export const WithAnimatedResponseShape = PropTypes.shape({
  handleEvent: PropTypes.func.isRequired,
  scrollToHighlightedCode: PropTypes.func.isRequired,
});
export interface Property {
  title: string;
  description: string;
  'code-line-start': number;
  'code-highlight-length': number;
}
