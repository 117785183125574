import React from 'react';
import {
  Column,
  Container,
  Section,
  Stack,
  Row,
} from 'src/components-v2/Layout';
import { CmsSectionalHeader } from 'src/sections';
import { RichTextSwitch } from 'src/containers';
import { VideoPlayer } from 'src/components-v2/Media';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { PlayButton } from 'src/components-v2/Inputs';
import { useIsDarkBackground } from 'src/contexts';
import { Icon } from 'src/components-v2/DataDisplay';
import { colors } from 'src/components-v2/theme';

const CmsEmbeddedVideoCard = ({ fields, sys }) => {
  const isReversed = fields?.layout === 'Text left / Video right';
  const bgColor = fields?.backgroundColor || 'white';
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const isDarkBackground = useIsDarkBackground(bgColor);
  return (
    <Section
      sx={{
        backgroundColor: bgColor,
        ...fields?.styles,
      }}
    >
      {fields?.header && <CmsSectionalHeader {...fields.header} />}
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row>
          <Column xs={24} sm={24} lg={20} lgOffset={2}>
            <Row>
              <Stack
                sx={{
                  flexDirection: {
                    xs: isReversed ? 'column-reverse' : 'column',
                    md: 'row',
                  },
                  alignItems: { lg: 'center' },
                  border: isDarkBackground
                    ? '1px solid white'
                    : `1px solid ${colors.black600}`,
                  borderRadius: '8px',
                  py: '4rem',
                  width: '100%',
                }}
              >
                <LeftColumn
                  textSys={sys}
                  videoSys={fields?.videoPlayer?.sys}
                  isDarkBackground={isDarkBackground}
                  {...fields}
                />
                <RightColumn
                  textSys={sys}
                  videoSys={fields?.videoPlayer?.sys}
                  isDarkBackground={isDarkBackground}
                  {...fields}
                />
              </Stack>
            </Row>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const LeftColumn = ({
  layout,
  textSys,
  videoSys,
  isDarkBackground,
  ...fields
}) => {
  switch (layout) {
    case 'Video left / Text right':
      return (
        <VideoColumn
          fields={fields?.videoPlayer?.fields}
          sys={videoSys}
          isDarkBackground={isDarkBackground}
        />
      );
    case 'Text left / Video right':
      return <TextColumn fields={fields} sys={textSys} />;
    default:
      return null;
  }
};

const RightColumn = ({
  layout,
  textSys,
  videoSys,
  isDarkBackground,
  ...fields
}) => {
  switch (layout) {
    case 'Video left / Text right':
      return <TextColumn fields={fields} sys={textSys} />;
    case 'Text left / Video right':
      return (
        <VideoColumn
          fields={fields?.videoPlayer?.fields}
          sys={videoSys}
          isDarkBackground={isDarkBackground}
        />
      );
    default:
      return null;
  }
};

const TextColumn = ({ fields, sys }) => {
  return (
    <Column xs={20} xsOffset={1} md={9}>
      <Stack>
        <Section
          component='header'
          sx={{
            '& h3': {
              mb: '1.6rem',
            },
            '& p:has(+ a)': {
              mb: '3.2rem',
            },
            '& p:has(.decorative-text)': {
              mb: '1.6rem',
            },
            '& p:nth-last-of-type(1):empty': {
              display: 'none',
            },
            ...fields.styles,
          }}
          {...{
            bgColor: fields?.backgroundColor || 'white',
          }}
        >
          <RichTextSwitch
            sys={sys}
            content={fields?.body}
            nodes={{
              h2: {
                variant: 'h2',
                component: 'h3',
                fieldId: 'body',
              },
              h6: {
                variant: 'h6',
                component: 'h3',
                fieldId: 'body',
              },
              p: {
                variant: 'p',
                fieldId: 'body',
              },
            }}
          />
        </Section>
      </Stack>
    </Column>
  );
};

const VideoColumn = ({ fields, sys, isDarkBackground }) => {
  const url = fields?.url;
  const coverImg = fields?.coverImage?.fields?.file?.url;
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Column
      xs={24}
      sm={22}
      smOffset={1}
      md={12}
      sx={{ p: { xs: '0', md: '0.2rem' } }}
      {...inspectorProps({ fieldId: 'internalTitle' })}
    >
      <VideoPlayer
        sx={{
          mb: { xs: '2rem', sm: '2.5rem', lg: '0' },
          mx: '-1px',
          border: isDarkBackground ? '1px solid white' : '1px solid black',
          height: { xs: '245px', sm: '350px', md: '425px' },
          ...fields?.styles,
        }}
        url={url}
        playing={true}
        playIcon={
          <PlayButton
            playIcon={<Icon icon='ChevronRight' />}
            sx={{
              '& svg': {
                zoom: 2,
              },
              '&:hover, :focus': {
                '& svg': {
                  color: 'white',
                },
                backgroundColor: 'black',
              },
            }}
          />
        }
        light={coverImg ? `${coverImg + '?fm=webp&w=1364&q=60'}` : true}
      />
    </Column>
  );
};

export default CmsEmbeddedVideoCard;
