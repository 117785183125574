import React from 'react';
import PropTypes from 'prop-types';
import SocialProofWrapper from './SocialProofWrapper';
import SocialProofHeader from './SocialProofHeader';
import SocialProofText from './SocialProofText';
import SocialProofLogosContainer from './SocialProofLogosContainer';
import SocialProofLogo from './SocialProofLogo';

const propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  header: PropTypes.string,
  text: PropTypes.string,
  bgColor: PropTypes.string,
};

const SocialProofCms = ({ text, header, logos, bgColor = '', ...props }) => {
  return (
    <SocialProofWrapper bgColor={bgColor} {...props}>
      {text && <SocialProofText>{text}</SocialProofText>}
      <SocialProofHeader>{header}</SocialProofHeader>
      <SocialProofLogosContainer {...props}>
        {logos.map((logo, i) => (
          <SocialProofLogo src={logo.src} alt={logo.alt} key={`logos-${i}`} />
        ))}
      </SocialProofLogosContainer>
    </SocialProofWrapper>
  );
};

SocialProofCms.propTypes = propTypes;

export default SocialProofCms;
