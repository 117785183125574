import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  className: PropTypes.string,
  row: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.bool,
      label: PropTypes.string,
      content: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  footnote: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
    }),
  ),
};

const InfoChartSection = ({ className, row, footnote }) => (
  <section className={cx('info-chart-section', className)}>
    <div className='grid-container'>
      <div className='grid-x'>
        <div className='cell small-12 medium-8 medium-offset-4'>
          <div className='grid-x'>
            {row.map((item, i) => {
              const RowElement = item.header ? 'strong' : 'div';
              return (
                <React.Fragment key={`item-${i}`}>
                  <div
                    className='cell small-5 medium-4'
                    dangerouslySetInnerHTML={{ __html: item.label }}
                  />
                  <RowElement
                    className='cell small-7 medium-8 text-center'
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                  <div className='cell small-12'>
                    <hr
                      className={`
                  hr
                  ${item.header ? 'hr--thick' : ''}
                `}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {footnote.map((item, i) => (
            <div
              key={`item-${i}`}
              className='info-chart-section__footnote'
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);

InfoChartSection.propTypes = propTypes;
export default InfoChartSection;
