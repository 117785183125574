export const metaData = {
  'meta-title': 'Business finances - financial data solutions',
  'meta-description':
    'From bookkeeping to payroll, business finance processes are complex experiences for employees and customers alike. Plaid makes them easy for everyone.',
};

export const pageHeaderData = {
  'content-width-classes': 'page-header-breakpoint-5 large-5',
  label: true,
  'label-text': 'Business finances',
  header: 'Make it easy for everyone to manage business finances',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '#contactForm',
  'secondary-cta': false,
  'secondary-cta-text': null,
  'tertiary-cta':
    'Or, <a class="signup-attach-utm" href="//dashboard.plaid.com/signup">get started</a>',
  image: true,
  'image-width': 1140,
  'image-height': 530,
  'image-url': '/assets/img/hero/business-finance-hero-intl.png',
  'image-classes': 'page-header-bg__img-overflow-large',
};

export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-sage-black600.png',
      alt: 'Sage',
    },
    {
      img: '/assets/img/logos/logo-freshbooks-black600.png',
      alt: 'Freshbooks',
    },
    {
      img: '/assets/img/logos/logo-abacus-black600.png',
      alt: 'Abacus',
    },
    {
      img: '/assets/img/logos/logo-expensify-black600.png',
      alt: 'Expensify',
    },
  ],
};

export const introSectionData = {
  'problem-statement':
    'From bookkeeping to payroll, business finance and accounting processes are complex experiences for employees and customers alike.',
  'solution-statement':
    'Plaid makes it easier to manage business finances leveraging Open Banking integrations for account aggregation.',
};

export const quoteData = {
  quote:
    'Data cleaning is very difficult, and while others deliver basic functionality, Plaid has the foresight and resources to go above and beyond that basic responsibility.',
  'quote-logo': true,
  'quote-logo-icon': '/assets/img/logos/drop-logo.svg',
  'logo-alt': 'Drop logo',
  cite: 'Daniel Whiffing, Technical Lead at Drop',
  'quote-link': '/documents/Drop-Case-Study.pdf',
  'quote-link-text': 'See the full case study',
  'with-background': false,
  dark: false,
};

export const featureSectionData = {
  'feature-section-title': [
    {
      label: 'Products and benefits',
    },
  ],
  'feature-section-items': [
    {
      img: '/assets/img/products/intl-transactions-112@2x.png',
      title: 'Better bookkeeping',
      'header-class': 'h5',
      content:
        '<a href="/eu/products/transactions">Transactions</a> provides 2 years of clean, categorised data for personal and business accounts',
      'no-link': true,
    },
    {
      img: '/assets/img/products/identity-112.png',
      title: 'Verify account ownership',
      'header-class': 'h5',
      content:
        '<a href="/eu/products/identity">Identity</a> confirms users\' identities with what’s on file at the bank',
      'no-link': true,
    },
    {
      img: '/assets/img/products/auth-112@2x.png',
      title: 'Setup direct debits and payouts',
      'header-class': 'h5',
      content:
        '<a href="/eu/products/auth">Auth</a> pulls your user\'s IBAN and BIC to enable a seamless setup for direct debits and payouts',
      'no-link': true,
    },
  ],
};

export const simpleCarouselData = {
  'carousel-title': 'How It Works',
  'carousel-slides': [
    {
      header: 'Integrate with Plaid',
      description:
        'It takes just a few lines of code to drop our front-end module into your app or digital service. Your users will be prompted to connect their bank account, which will launch the Plaid module.',
      'bg-color': 'bg-green',
      img: '/assets/img/carousel/use-cases-carousel-0.png',
    },
    {
      header: 'Customise for conversion',
      description:
        'Customise the module to fit your brand or user preferences, including which financial institutions show up first.',
      'bg-color': 'bg-blue',
      img: '/assets/img/carousel/use-cases-carousel-link-demo-1.png',
    },
    {
      header: 'Get user-permissioned bank data',
      description:
        'Once a user has gone through the flow, we do the work and return a range of relevant bank account data depending on what fits your needs.',
      'bg-color': 'bg-pink',
      img: '/assets/img/carousel/use-cases-carousel-link-demo-2.png',
    },
  ],
};

export const ctaData = {
  'marketo-key': 2007,
  title: 'Questions about how Plaid can fit into your business?',
  'with-subtext': true,
  subtext: 'Tell us how we can help and we’ll get in touch shortly.',
  'subtext-class': 'h4 regular',
  'include-phone': true,
};
