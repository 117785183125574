const ConditionalWrapper = ({
  condition,
  wrapper,
  alternateWrapper = null,
  children,
}) =>
  condition
    ? wrapper(children)
    : alternateWrapper
    ? alternateWrapper(children)
    : children;

export default ConditionalWrapper;
