import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';

const propTypes = {
  ulClassName: PropTypes.string,
};

const UnorderedList = ({ ulClassName = '' }) => {
  return {
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className={ulClassName}>{children}</ul>;
    },
  };
};

UnorderedList.propTypes = propTypes;

export default UnorderedList;
