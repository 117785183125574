import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { Icon, Typography } from 'src/components-v2/DataDisplay';
import { Dropdown } from 'src/components-v2/Dropdown';

import { Container, Section, Row, Column, Box } from 'src/components-v2/Layout';
import { CardLinked } from '../Cards/CardLinked';

import { BackgroundContext } from 'src/contexts';

import cx from 'classnames';
import styles from './FilteredCardsSection.module.scss';

type FILTER = {
  id: number;
  name: string;
};

const isBrowser = typeof window !== 'undefined';

const FilteredCardsSection = ({ fields, sys }) => {
  const componentInit = useRef(false);
  const { id } = sys;
  const { query, replace } = useRouter();
  const { backgroundColor, styles: stylesProp, filteredCards: cards, filters } =
    fields || {};

  const dropdownButton = useMemo(() => {
    return (
      <button className={cx(styles.DropdownButton)}>
        <BackgroundContext.Provider value={backgroundColor}>
          <Typography sx={{ fontWeight: 600 }} variant='h5' component='span'>
            Filtered options
          </Typography>
          <Icon
            sx={{
              height: '20px',
              width: '20px',
              color: backgroundColor === 'black' ? 'white' : 'black',
            }}
            icon='ChevronDown'
          />
        </BackgroundContext.Provider>
      </button>
    );
  }, [backgroundColor]);

  const filterIcon = (
    <span className={cx(styles.FilterIcon)}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
      >
        <circle cx='7' cy='7.5' r='7' fill='currentColor' />
        <path
          d='M4.41992 9.97363L9.47255 4.921'
          stroke='white'
          stroke-width='1.2'
          stroke-linecap='round'
        />
        <path
          d='M4.41992 4.9209L9.47255 9.97353'
          stroke='white'
          stroke-width='1.2'
          stroke-linecap='round'
        />
      </svg>
    </span>
  );
  const [activeFilters, setActiveFilters] = useState<FILTER[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const allFilters = useMemo(() => {
    const mapped = [];
    filters.forEach((block, blockI) => {
      block.fields?.tags?.forEach((tag) => {
        mapped.push({ id: blockI, name: tag.fields.tag });
      });
    });

    return mapped;
  }, [filters]);

  const updateFilterQuery = useCallback(
    (filters) => {
      if (filters.length === 0) {
        delete query[id];
      }

      const filterQuery =
        filters.length > 0
          ? {
              [id]: `${filters.join('&')}`,
            }
          : {};

      replace({ query: { ...query, ...filterQuery } }, undefined, {
        shallow: true,
      });
    },
    [id, query, replace],
  );

  const onFilterClick = useCallback(
    (filter: { id: number; name: string }) => {
      setActiveFilters((prev) => {
        const isCurrentActive =
          prev.filter((tag) => {
            return tag.id === filter.id && tag.name === filter.name;
          }).length > 0;
        const prevRemoveCurrentGroup = prev.filter((tag) => {
          return tag.id !== filter.id;
        });
        const updatedMinus = prevRemoveCurrentGroup.filter((tag) => {
          return tag.name !== filter.name;
        });
        const updatedPlus = [...prevRemoveCurrentGroup, filter];
        const updatedFilters = isCurrentActive ? updatedMinus : updatedPlus;
        updateFilterQuery(
          updatedFilters.map((filter) => {
            return filter.name;
          }),
        );
        return updatedFilters;
      });
    },
    [updateFilterQuery],
  );

  const filtersRendered = (
    <Box className={cx(styles.Filters)}>
      {filters.map((filterBlock, blockI) => {
        return (
          <Box
            className={cx(styles.FilterBlock)}
            key={filterBlock?.fields?.title + blockI}
          >
            <Typography className={cx(styles.FilterTitle)} variant='h5'>
              {filterBlock?.fields?.title}
            </Typography>
            <Box>
              <ul className={cx(styles.FilterList)}>
                {filterBlock?.fields?.tags?.map((tag, tagI) => {
                  const isTagFiltered =
                    activeFilters.filter((activeFilter) => {
                      return activeFilter.name === tag?.fields?.tag;
                    }).length > 0;
                  return (
                    <li key={tagI + tag}>
                      <button
                        type='button'
                        className={cx(
                          styles.Filter,
                          isTagFiltered && styles.Filter_active,
                        )}
                        onClick={() => {
                          onFilterClick({ id: blockI, name: tag?.fields?.tag });
                        }}
                      >
                        {isTagFiltered && filterIcon}
                        <Typography component='span' variant='p4'>
                          {tag?.fields?.tag}
                        </Typography>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  const cardsFiltered = useMemo(() => {
    const filteredCards = [];

    if (cards) {
      const cardsTags = cards.map((card) => {
        if (card?.fields?.tags) {
          return card?.fields?.tags?.map((tag) => {
            return tag?.fields?.tag;
          });
        } else {
          return [];
        }
      });

      cardsTags.length > 0 &&
        cardsTags.forEach((cardTags, i) => {
          const cardTagsInFilter = cardTags.filter((tag) => {
            const activeFiltersInTags = [];
            activeFilters.forEach((activeFilter) => {
              activeFilter.name === tag &&
                activeFiltersInTags.push(activeFilter);
            });
            return activeFiltersInTags.length > 0 ? true : false;
          });

          if (cardTagsInFilter.length === activeFilters.length) {
            filteredCards.push(cards[i]);
          }
        });

      return filteredCards;
    } else {
      return [];
    }
  }, [activeFilters, cards]);

  const cardsRendered = useMemo(() => {
    if (activeFilters.length > 0 && cardsFiltered.length > 0) {
      return cardsFiltered;
    }

    return cards;
  }, [cardsFiltered, cards, activeFilters]);

  const resetButton = useMemo(() => {
    return (
      <button
        type='button'
        className={cx(styles.FilterReset)}
        onClick={() => {
          setActiveFilters([]);
          updateFilterQuery([]);
        }}
        style={{ color: 'inherit' }}
      >
        {filterIcon}
        <Typography variant='p4' sx={{ fontWeight: 600 }}>
          Reset filters
        </Typography>
      </button>
    );
  }, [filterIcon, updateFilterQuery]);

  useEffect(() => {
    const queryNotEmpty =
      query &&
      Object.keys(query).length > 0 &&
      typeof query[id] !== 'undefined';
    if (queryNotEmpty && isBrowser && typeof query[id] === 'string') {
      const filtersActive = (query[id] as string).split('&');

      const queryFiltersToActiveFilters = [];
      filtersActive.forEach((activeFilter) => {
        const mainMatch = allFilters.filter((filter) => {
          if (activeFilter === filter.name) {
            return true;
          }
        })[0];
        queryFiltersToActiveFilters.push(mainMatch);
      });

      setActiveFilters(queryFiltersToActiveFilters);
    }
  }, [allFilters, id, query]);

  useEffect(() => {
    componentInit.current = true;

    return () => {
      componentInit.current = false;
    };
  }, []);

  return (
    <Section
      sx={{ backgroundColor: backgroundColor || 'white', ...stylesProp }}
      className={cx(styles.FilteredCardsSection)}
    >
      <Container>
        <Row direction={'row'}>
          <Column xs={24} xsOffset={0} lg={4} lgOffset={2}>
            <Dropdown
              className={cx(styles.FilterDropdown)}
              activeClassName={cx(styles.FilterDropdown_active)}
              button={dropdownButton}
              opened={false}
              dispatchResize={true}
            >
              <Box className={cx(styles.FilterDropdown__content)}>
                {filtersRendered}

                {activeFilters.length > 0 && (
                  <Box className={cx(styles.FilterDropdown__contentreset)}>
                    {resetButton}
                  </Box>
                )}
              </Box>
            </Dropdown>

            <Box className={cx(styles.FilterSimple)}>
              <BackgroundContext.Provider value={backgroundColor}>
                {filtersRendered}

                {activeFilters.length > 0 && resetButton}
              </BackgroundContext.Provider>
            </Box>
          </Column>

          <Column xs={24} xsOffset={0} lg={16} lgOffset={0}>
            {cardsFiltered.length === 0 && (
              <Box className={cx(styles.FilterEmpty)}>
                <BackgroundContext.Provider value={backgroundColor}>
                  <Typography variant='p2'>
                    No results found, but here are some related searches
                  </Typography>
                  <Box className={cx(styles.FilterEmpty__button)}>
                    {resetButton}
                  </Box>
                </BackgroundContext.Provider>
              </Box>
            )}

            <Row
              className={cx(styles.Cards)}
              direction={'row'}
              sx={{ flexWrap: 'wrap', padding: 0 }}
            >
              {cardsRendered?.map((card, i) => {
                return (
                  <Column
                    key={i}
                    className={cx(
                      styles.Card,
                      hoveredIndex !== i &&
                        hoveredIndex !== -1 &&
                        styles.Card_inactive,
                    )}
                    xs={24}
                    sm={12}
                    lg={8}
                  >
                    {React.cloneElement(<CardLinked card={card} />, {
                      onMouseEnter: () => {
                        setHoveredIndex(i);
                      },
                      onMouseLeave: () => {
                        setHoveredIndex(-1);
                      },
                      onFocus: () => {
                        setHoveredIndex(i);
                      },
                      onBlur: () => {
                        setHoveredIndex(-1);
                      },
                    })}
                  </Column>
                );
              })}
            </Row>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default FilteredCardsSection;
