import React, { FC } from 'react';
import useMeasure from 'react-use-measure';
import { Image } from 'src/components-v2/Media';
import { RichTextSwitch } from 'src/containers';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import {
  Column,
  Section,
  Container,
  Row,
  Stack,
  Box,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { LeadFormWrapper as LeadFormWrapperProps } from './LeadFormWrapper.types';

const LeadFormWrapper: FC<LeadFormWrapperProps> = ({
  children,
  sys = '',
  backgroundColor,
  body,
  formTitle,
  backgroundImageColor,
  backgroundImage,
  backgroundMono,
  id,
}) => {
  const [imageRef, imageBounds] = useMeasure();
  const [formRef, formBounds] = useMeasure();
  const isDarkMode =
    backgroundColor === 'black' || backgroundColor === 'black1000';
  const formColor = backgroundColor !== 'white' ? 'white' : 'black200';
  let bgImageColor;

  if (backgroundImage) {
    if (!backgroundImageColor) {
      bgImageColor = 'black200';
    } else {
      bgImageColor = backgroundImageColor;
    }
  } else if (!backgroundImageColor) {
    bgImageColor = defaultImage?.color;
  } else {
    bgImageColor = backgroundImageColor;
  }

  return (
    <Section
      id={id}
      sx={{
        position: 'relative',
        backgroundColor,
        ...(!backgroundMono && {
          '&::before': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: {
              xs: `calc(${imageBounds?.height}px + 8px)`,
              sm: `calc(${imageBounds?.height}px + 40px)`,
              lg: 'calc(50% + 32px)',
            },
            backgroundColor,
            top: { xs: '-8px', sm: '-40px', lg: '-32px' },
          },
          '&::after': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: {
              xs: `calc(${formBounds?.height}px + 8px)`,
              sm: `calc(${formBounds?.height}px + 40px)`,
              lg: 'calc(50% + 32px)',
            },
            bottom: { xs: '-8px', sm: '-40px', lg: '-32px' },
            backgroundColor: isDarkMode ? 'white' : 'black1000',
          },
        }),
      }}
    >
      <Container>
        <Row>
          <Column
            xs={24}
            lg={12}
            sx={{
              p: '0',
            }}
            ref={imageRef}
          >
            <Box
              sx={{
                height: '100%',
                backgroundColor: bgImageColor,
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: { xs: '0', lg: '8px' },
                borderTopRightRadius: { xs: '8px', lg: '0' },
                position: 'relative',
                top: '8px',
                zIndex: 1,
                '& img': {
                  height: '100%',
                  minWidth: '100%',
                  position: 'absolute',
                  mixBlendMode: 'color-burn',
                },
              }}
            >
              {backgroundImage ? (
                <CmsMediaSwitch
                  asset={backgroundImage}
                  width={825}
                  height={903}
                  queryString={formImageQueryString}
                />
              ) : (
                <Image
                  src={defaultImage?.desktop}
                  alt={defaultImage?.alt}
                  width={defaultImage?.width}
                  height={defaultImage?.height}
                  sources={[
                    {
                      media: '(max-width: 412px)',
                      srcSet: defaultImage?.mobile + formImageQueryString?.xs,
                    },
                    {
                      media: '(min-width: 413px) and (max-width: 639px)',
                      srcSet: defaultImage?.mobile + formImageQueryString?.sm,
                    },
                    {
                      media: '(min-width: 640px) and (max-width: 1023px)',
                      srcSet: defaultImage?.tablet + formImageQueryString?.md,
                    },
                    {
                      media: '(min-width: 1024px)',
                      srcSet: defaultImage?.desktop + formImageQueryString?.lg,
                    },
                  ]}
                />
              )}
              <Row
                sx={{
                  height: '100%',
                }}
              >
                <Column
                  xs={20}
                  xsOffset={2}
                  sm={18}
                  smOffset={3}
                  lgOffset={4}
                  lg={16}
                  sx={{
                    p: '0',
                  }}
                >
                  <Stack
                    sx={{
                      height: '100%',
                      justifyContent: 'center',
                      py: { xs: '6.0rem', sm: '9.0rem', lg: '0' },
                      textAlign: { xs: 'center', lg: 'left' },
                      '& p:nth-last-of-type(1):empty': {
                        display: 'none',
                      },
                    }}
                  >
                    <RichTextSwitch
                      sys={sys}
                      content={body}
                      nodes={{
                        h2: { variant: 'h2' },
                        p: {
                          variant: 'p',
                        },
                      }}
                    />
                  </Stack>
                </Column>
              </Row>
            </Box>
          </Column>
          <Column
            xs={24}
            lg={12}
            ref={formRef}
            sx={{
              backgroundColor: formColor,
              borderTopRightRadius: { xs: '0', lg: '8px' },
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: { xs: '8px', lg: '0' },
              p: '0',
              zIndex: 1,
            }}
          >
            <Row
              sx={{
                height: '100%',
              }}
            >
              <Column
                xsOffset={2}
                xs={20}
                smOffset={3}
                sm={18}
                lgOffset={4}
                lg={16}
                sx={{
                  p: '0',
                }}
              >
                <Stack
                  sx={{
                    py: {
                      xs: '4.0rem',
                      sm: '7.0rem',
                      lg: '14.0rem',
                    },
                    '& input': {
                      borderRadius: '8px',
                    },
                    '& i + span': {
                      fontSize: '1.4rem',
                    },
                    '& fieldset': {
                      '& div:has(input)': {
                        borderRadius: '8px',
                      },
                    },
                  }}
                >
                  {formTitle && (
                    <Typography
                      variant='h4'
                      component='h3'
                      sx={{ mb: '4.0rem' }}
                    >
                      {formTitle}
                    </Typography>
                  )}
                  {children}
                </Stack>
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default LeadFormWrapper;

const BASE = '?fm=webp&q=60';
const formImageQueryString = {
  xs: `${BASE}&w=800`,
  sm: `${BASE}&w=1234`,
  md: `${BASE}&w=1902`,
  lg: `${BASE}&w=2430`,
};

// default brushstroke images
const defaultImage = {
  color: 'blue200',
  alt: 'background brushstroke',
  width: 825,
  height: 903,
  desktop:
    'https://images.ctfassets.net/ss5kfr270og3/1S7dVr6rPmtXlYVvoEQk54/4d0ff91917a5678e799ab8507998f092/Desktop_Graphic.png',
  tablet:
    'https://images.ctfassets.net/ss5kfr270og3/iQkllh256LJR09AtH75Ro/7bad9cb9cbea04996fb849a3223cdb30/tablet_graphic.png',
  mobile:
    'https://images.ctfassets.net/ss5kfr270og3/6OX468iVxXwcb7LR6XEvZP/9ae736fdb5bca3b9b116f9c76e046f1d/mobile_graphic.png',
};
