import { FC, useState } from 'react';
import Button from './Button';
import TextInput from './TextInput';
import styles from './SaveForm.module.scss';
import { saveUserProjectForTutorial, TutorialKey } from './TutorialUtils';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { TUTORIAL_EVENTS } from 'src/lib/trackingEvents';

export interface SaveFormProps {
  tutorialKey: TutorialKey;
  onComplete?: () => {};
  buttonOverride?: string;
  projectSetter?: (string) => {};
}

const SaveForm: FC<SaveFormProps> = ({
  tutorialKey,
  onComplete,
  buttonOverride,
  projectSetter,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [recentlySaved, setRecentlySaved] = useState(false);

  const track = useTrack();

  const trackTutorialProjectSaved = () => {
    track({
      type: TUTORIAL_EVENTS.TUTORIAL_PROJECT_SAVED,
      payload: {
        tutorial_id: tutorialKey,
      },
    });
  };

  const saveProject = (projectName) => {
    saveUserProjectForTutorial(tutorialKey, projectName);
    setRecentlySaved(true);
    trackTutorialProjectSaved();
    setInputValue('');
  };

  const buttonLabel = buttonOverride || 'Save';

  return (
    <div className={styles.wrapper}>
      <TextInput
        autoComplete='off'
        id='project-name-input'
        label='Project Name'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Button
        inline
        centered
        onClick={() => {
          saveProject(inputValue);
          if (projectSetter != null) {
            projectSetter(inputValue);
          }
          if (onComplete != null) {
            onComplete();
          }
        }}
      >
        {recentlySaved ? 'Saved!' : buttonLabel}
      </Button>
    </div>
  );
};

export default SaveForm;
