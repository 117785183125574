import React from 'react';

import {
  CODE_LINE_HEIGHT,
  LINE_HEIGHT_OFFSET,
} from '../with-animated-response-constants';
import { Property } from '../withAnimatedResponse.types';

interface Props {
  index: number;
  property: Property;
  isHidden?: boolean;
}

const LineHighlight: React.FC<Props> = ({
  index,
  property,
  isHidden = false,
}) => {
  if (index) {
    return null;
  }

  return (
    <div
      data-id='line-highlight'
      aria-hidden='true'
      data-range='0'
      className='line-highlight'
      style={{
        top: `${
          property['code-line-start'] * CODE_LINE_HEIGHT + LINE_HEIGHT_OFFSET
        }px`,
        height: `${property['code-highlight-length'] * CODE_LINE_HEIGHT}px`,
        display: isHidden ? 'none' : 'inline-block',
      }}
    />
  );
};

export default LineHighlight;
