import React, { FC, useEffect, useRef, useState } from 'react';
import { FocusScope } from '@react-aria/focus';
import { MenuListProps, MenuListVariant } from './MenuList.types';

import { Stack } from 'src/components-v2/Layout';

export const MenuList: FC<MenuListProps> = ({
  variant = 'primary',
  children,
  sx,
  isActive,
}) => {
  const ref = useRef<HTMLDivElement>();
  const isPrimary = variant === MenuListVariant.PRIMARY;
  const isTertiary = variant === MenuListVariant.TERTIARY;
  const [contain, setContain] = useState(false);

  useEffect(() => {
    // When a menu is active, prevent scrolling when arrow keys are pressed
    const stopScrolling = (e) => {
      if (isActive && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', stopScrolling);
    return () => {
      document.removeEventListener('keydown', stopScrolling);
    };
  }, [ref, isActive]);

  useEffect(() => {
    if (variant === 'primary') {
      return;
    }
    const el = ref.current;

    const containFocus = () => {
      return setContain(true);
    };
    const uncontainFocus = () => {
      return setContain(false);
    };

    // using native focusin/focusout to dynamically manage "contain" prop to support navigating via keynav
    el.addEventListener('focusin', containFocus);
    el.addEventListener('focusout', uncontainFocus);

    return () => {
      el.removeEventListener('focusin', containFocus);
      el.removeEventListener('focusout', uncontainFocus);
    };
  }, [ref, isActive, variant]);

  return (
    <Stack
      ref={ref}
      component='ul'
      tabIndex={-1}
      sx={{
        flexDirection: {
          xs: 'column',
          lg: isPrimary ? 'row' : 'column',
        },
        alignItems: { lg: 'center' },
        ml: '0',
        pb: { xs: '0', lg: isPrimary ? '0' : '2.4rem' },
        pt: { lg: isPrimary ? '0' : '2.4rem' },
        mb: { xs: '0' },
        backgroundColor: {
          xs: isPrimary ? 'white' : 'black200',
          lg: 'inherit',
        },
        listStyleType: 'none',
        borderLeft: { lg: isTertiary ? 1 : 0 },
        borderColor: { lg: 'black400' },
        '& *': {
          outlineOffset: '-2px',
        },
        pointerEvents: isTertiary && !isActive ? 'none' : 'all',
        opacity: isTertiary ? (isActive ? 1 : 0) : 1,
        transitionProperty: isTertiary ? 'opacity' : 'none',
        transitionDuration: isTertiary
          ? isActive
            ? '350ms'
            : '200ms'
          : 'initial',
        transitionTimingFunction: isTertiary
          ? 'cubic-bezier(0.23, 1.2, 0.32, 1)'
          : 'initial',
        ...sx,
      }}
      // @ts-expect-error - need to use "" to apply inert attribute. See https://github.com/WICG/inert/issues/58#issuecomment-1508967679
      inert={
        (variant === 'secondary' || variant === 'tertiary') &&
        isActive === false
          ? ''
          : undefined
      }
      onKeyDown={(e) => {
        // When ArrowLeft or Escape is pressed, put focus back on expanded secondary element
        if (
          isActive &&
          variant === 'tertiary' &&
          (e.key === 'ArrowLeft' || (e.key === 'Tab' && e.shiftKey))
        ) {
          const el = document.querySelector(
            '[data-variant="secondary"][aria-expanded="true"]',
          ) as HTMLElement;

          setContain(false);

          // once contain change is done, focus the element so it doesn't get stuck
          requestAnimationFrame(() => {
            return el.focus();
          });

          // prevent propagation to prevent all menus from closing
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <FocusScope contain={contain}>{children}</FocusScope>
    </Stack>
  );
};

export default MenuList;
