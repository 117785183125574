export const metaData = {
  'meta-title': '500',
  'meta-description': "You've reached this page unjustly",
  hasCanonicalTag: false,
};

export const body = {
  header: `Something <span class='medium-and-up-line-break'></span>went wrong`,
  description: 'Our team has been notified and is working on the issue',
};
