import React from 'react';

//components
import { MenuLinkProps, MenuLinkVariant } from './MenuLink.types';
import { Box, Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';
import { Typography, Icon } from 'src/components-v2/DataDisplay';
import { useFocusManager } from '@react-aria/focus';
import { S } from 'src/components-v2/StyledElements/StyledElements';

const MenuLink: React.FC<MenuLinkProps> = ({
  variant,
  title,
  description,
  href,
  sx,
  trackingId,
  onFocus,
}) => {
  const focusManager = useFocusManager();
  const isPrimary = variant === MenuLinkVariant.PRIMARY;
  const isSecondary = variant === MenuLinkVariant.SECONDARY;
  const isTertiary = variant === MenuLinkVariant.TERTIARY;
  return (
    <Box
      component='li'
      sx={{
        width: { lg: isPrimary ? 'auto' : '100%' },
        cursor: 'pointer',
        ...sx,
      }}
      onClick={(e) => {
        // stop event from propagating to keep parent menu open on mobile
        return e.stopPropagation();
      }}
      onFocus={onFocus}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            e.stopPropagation();
            focusManager.focusNext({ wrap: true });
            break;
          case 'ArrowUp':
            e.preventDefault();
            e.stopPropagation();
            focusManager.focusPrevious({ wrap: true });
            break;
          case ' ':
            // prevent scrolling
            e.preventDefault();
            // stop event from propagating to prevent parent menu from closing on desktop
            e.stopPropagation();
            window.location.assign((e.target as HTMLAnchorElement).href);
        }
      }}
    >
      <Link href={href} trackingId={trackingId}>
        <S.A
          data-name='StyledA'
          tabIndex={0}
          sx={{
            display: 'block',
            px: {
              xs: isTertiary ? '3.2rem' : '0.8rem',
              sm: isTertiary ? '6.4rem' : '4rem',
              lg: isPrimary ? '2rem' : '3.2rem',
            },
            py: {
              xs: isPrimary ? '2.03rem' : '2.0rem',
              sm: isPrimary ? '2.43rem' : '2.4rem',
              lg: isPrimary ? '0.8rem' : '1.2rem',
            },
            borderTop: isPrimary ? { xs: 1, lg: 'none' } : 'none',
            borderTopColor: isPrimary ? { xs: 'black400', lg: 'none' } : 'none',
            '&:hover, :focus': {
              color: 'blue900',
              '> div > p': { color: 'blue900' },
              // animate the caret
              '> div > span': {
                transform: { lg: !isPrimary ? 'translateX(4px)' : 'none' },
                transition: {
                  lg: !isPrimary
                    ? 'transform cubic-bezier(0.23, 1.2, 0.32, 1) 500ms'
                    : 'none',
                },
              },
            },
            '&:active': {
              color: 'blue1000',
              '> div > p': { color: 'blue1000' },
            },
          }}
        >
          <Stack
            direction='row'
            sx={{
              mb: 0,
              width: '100%',
              justifyContent: { xs: 'space-between' },
            }}
          >
            <Typography
              sx={{
                mb: 0,
                mr: { lg: isPrimary ? '0rem' : '0.8rem' },
              }}
              theme='main-menu'
              variant='p'
            >
              {title}
            </Typography>
            <Box
              component='span'
              sx={{
                display: {
                  xs: isSecondary ? 'block' : 'none',
                  lg: isPrimary ? 'none' : 'block',
                },
                height: '0px',
                mr: { xs: '0.5rem', lg: '0' },
              }}
            >
              <Icon
                icon='ChevronRightS1'
                sx={{
                  height: '18px',
                  width: '18px',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            </Box>
          </Stack>
          {description && (
            <Typography
              sx={{
                mb: 0,
                mt: { xs: '0.2rem', sm: '0.4rem' },
                color: 'black900',
                fontWeight: 400,
                pr: { xs: isSecondary ? '1.6rem' : '0', lg: '0' },
              }}
              variant='p'
              theme='main-menu'
            >
              {description}
            </Typography>
          )}
        </S.A>
      </Link>
    </Box>
  );
};

export default MenuLink;
