import React, { ReactElement } from 'react';
import Image from 'src/components/Image';

import DefaultTemplate from 'src/templates/default';
// handle pages that dont exist anymore in new docs instead of showing 404
import LegacyDocsRedirects from 'src/components/docs/DocsBeta/LegacyDocsRedirects';

import { Locales } from 'src/lib/constants';
import { mutateHardCodedLinks } from 'src/lib/utils';

import * as enUs404 from 'src/store/404';
import * as enEu404 from 'src/store/en-eu/404';
import * as enGb404 from 'src/store/en-gb/404';

const error404PerLocale = {
  [Locales.EN_US]: enUs404,
  [Locales.EN_EU]: enEu404,
  [Locales.EN_GB]: enGb404,
};

const Error404 = ({
  locale = Locales.EN_US,
}: {
  locale?: string;
}): ReactElement => {
  const {
    metaData,
    body: { header = '', description = '' },
  } = mutateHardCodedLinks({
    data: error404PerLocale[locale] || error404PerLocale[Locales.EN_US],
    locale,
  });

  return (
    <>
      <LegacyDocsRedirects />
      <DefaultTemplate {...metaData} noIndex={true} locale={locale}>
        <div className='grid-container section-container section-container--center-content-mobile'>
          <div className='grid-x grid-margin-x contact-form-grid'>
            <div className='cell medium-2'></div>
            <div className='cell medium-4'>
              <h2 className='half-section-padding-bottom--mobile-hide'>
                {header}
              </h2>
              <h4 className='regular'>{description}</h4>
            </div>
            <div className='cell medium-4'>
              <Image
                src='/assets/img/backgrounds/404-error-state.png'
                alt='404 error'
              />
            </div>
            <div className='cell medium-2'></div>
          </div>
        </div>
      </DefaultTemplate>
    </>
  );
};

export default Error404;
