import React from 'react';
import { RichTextSwitch } from 'src/containers';
import { targetMap } from 'src/lib/constants';
import Quote from '../../Quote';

const CmsQuote = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor || 'white';
  return (
    <Quote
      sx={{ backgroundColor: bgColor, ...fields?.styles }}
      sys={sys}
      cta={{
        href: fields?.ctaLink?.fields?.url,
        trackingId: fields?.ctaLink?.fields?.trackingId,
        target: targetMap[fields?.ctaLink?.fields?.target],
        sys: fields?.ctaLink?.sys,
        text: fields?.ctaLink?.fields?.text,
      }}
    >
      <RichTextSwitch
        sys={sys}
        content={fields?.body}
        nodes={{
          h3: {
            variant: 'h3',
            component: 'blockquote',
            fieldId: 'body',
          },
          h5: {
            variant: 'h5',
            component: 'cite',
            fieldId: 'body',
          },
          asset: {
            queryString: {
              xs: '?fm=webp&w=172&q=60',
              sm: '?fm=webp&w=228&q=60',
              md: '?fm=webp&w=264&q=60',
              lg: '?fm=webp&w=264&q=60',
            },
            fieldId: 'body',
          },
        }}
      />
    </Quote>
  );
};

export default CmsQuote;
