import React from 'react';
import cx from 'classnames';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

const FeatureSectionTitle = (props) => {
  const smallTitleStyles = (mediumColumnWidth, largeColumnWidth) => {
    return cx(
      'cell',
      'small-12',
      'align-center',
      mediumColumnWidth ? `medium-${mediumColumnWidth}` : 'medium-6',
      largeColumnWidth && `large-${largeColumnWidth}`,
    );
  };

  return (
    <div
      className={cx('grid-x', {
        'align-center': props['header-alignment'] === 'center',
      })}
      id={props['section-id']}
    >
      {props['title-size'] === 'small' ? (
        <div
          className={smallTitleStyles(
            props['medium-column-width'],
            props['large-column-width'],
          )}
        >
          <h4
            className={cx({
              'text-center': props['title-alignment'] === 'center',
            })}
          >
            {props.title}
          </h4>
          <br />
          <div
            className={cx('feature-section-title', {
              'text-center': props['title-alignment'] === 'center',
            })}
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
          {props['cta-url'] && props['cta-text'] && (
            <Link href={props['cta-url']}>
              <a>
                <span className='feature-section-title feature-section-link-text'>
                  {props['cta-text']} <ArrowRightSingleSmall />
                </span>
              </a>
            </Link>
          )}
          <br />
        </div>
      ) : (
        <div className='cell small-12'>
          <h6 className='feature-section-label'>{props.label}</h6>
          <h2
            className='feature-section-title'
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
        </div>
      )}
      <br />
    </div>
  );
};

export default FeatureSectionTitle;
