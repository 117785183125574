export const getColumn = (items: Array<unknown>, index: number) => {
  return {
    lgOffset: index === 0 ? 2 : 0,
    lg: 20 / 3,

    smOffset: 0,
    sm: 8,

    xsOffset: index === 0 ? 0 : 1,
    xs: 21,
  };
};
