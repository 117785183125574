import React from 'react';
import isDarkColor from 'is-dark-color';
import { colors } from 'src/components-v2/theme';

export const BackgroundContext = React.createContext('');

const useIsDarkBackground = (
  color: string = undefined,
): boolean | undefined => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const backgroundColor = color || React.useContext(BackgroundContext);
  if (backgroundColor) {
    try {
      return isDarkColor(colors[backgroundColor] || backgroundColor);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export default useIsDarkBackground;
