import React, { FC } from 'react';
import Image from 'src/components/Image';
import { Button } from 'src/components/Inputs';
import { BetaLabel } from 'src/components/Surfaces/Label';
// import { Video } from '../MvpPageHeader/Video';
import { optimizeImage } from 'src/lib/image-helpers';

interface Props {
  label: string;
  description: string;
  hasPrimaryCta: boolean;
  hasSecondaryCta: boolean;
  isBetaFeature?: boolean;
  hasImagePrimaryOnly?: boolean;
  hasImageSecondaryOnly?: boolean;
  // CTAs
  'top-cta-url'?: string;
  'top-cta-text'?: string;
  'bottom-cta-url'?: string;
  'bottom-cta-text'?: string;
  // hero one
  heroImageOneWidth?: number;
  heroImageOneHeight?: number;
  'hero-one-video-url'?: string;
  'hero-one-video-fallback-url'?: string;
  'hero-one-video-width'?: string;
  'hero-one-video-height'?: string;
  'hero-one-poster-url': string;
  'hero-one-poster-fallback-url': string;
  // hero two
  'hero-two-video-url'?: string;
  'hero-two-video-fallback-url'?: string;
  'hero-two-video-width'?: string;
  'hero-two-video-height'?: string;
  'hero-two-poster-url': string;
  'hero-two-poster-fallback-url': string;
}

const ProductPageHeader: FC<Props> = ({
  hasImagePrimaryOnly = false,
  hasImageSecondaryOnly = false,
  hasPrimaryCta = false,
  hasSecondaryCta = false,
  heroImageOneHeight = null,
  heroImageOneWidth = null,
  isBetaFeature = false,
  ...props
}) => (
  <div className='page-header product-page'>
    <div className='grid-container product-header-offset'>
      <div className='grid-x align-justify'>
        <div className='cell small-12 small-order-2 medium-6 medium-order-1 large-6'>
          <div className='page-header-bg'>
            {props['hero-one-video-url'] && (
              <>
                <video
                  className='page-header-video show-for-medium'
                  autoPlay
                  loop
                  muted
                  preload='true'
                  poster={optimizeImage(props['hero-one-poster-url'])}
                  data-testid='hero-one-video'
                >
                  <source src={props['hero-one-video-url']} type='video/webm' />
                  <source
                    src={props['hero-one-poster-fallback-url']}
                    type='video/mp4'
                  />
                  <Image
                    src={props['hero-one-poster-url']}
                    alt={props.description}
                    isLazy={false}
                  />
                </video>
                <Image
                  className='hide-for-medium'
                  src={props['hero-one-poster-fallback-url']}
                  alt={props.description}
                  isLazy={false}
                />
              </>
            )}
            {hasImagePrimaryOnly && (
              <Image
                width={heroImageOneWidth}
                height={heroImageOneHeight}
                src={props['hero-one-poster-fallback-url']}
                className='page-header-image'
                alt={props.description}
                data-testid='product-header-image'
                isLazy={false}
              />
            )}
          </div>
        </div>
        <div className='cell small-12 small-order-1 medium-6 medium-order-2 large-5 page-header-content'>
          <div className='grid-x'>
            <div className='cell'>
              {isBetaFeature ? (
                <div className='beta-subheader'>
                  <BetaLabel />
                  <h6
                    className='regular page-header-subheader'
                    dangerouslySetInnerHTML={{ __html: props.label }}
                  />
                </div>
              ) : (
                <h6
                  className='regular page-header-subheader'
                  dangerouslySetInnerHTML={{ __html: props.label }}
                />
              )}
            </div>
            <div className='cell small-10 page-header-breakpoint-12'>
              <h1 className='page-header-header h2'>{props.description}</h1>
            </div>
            <div className='cell medium-12 large-6 page-header-breakpoint-12'>
              {!!props['top-cta-url'] && (
                <div className='button-group button-group--margin-bottom'>
                  <Button
                    href={props['top-cta-url']}
                    className='signup-attach-utm header-button'
                  >
                    {props['top-cta-text']}
                  </Button>
                </div>
              )}
              {!!props['bottom-cta-url'] && (
                <div className='button-group button-group--margin-bottom'>
                  <Button
                    href={props['bottom-cta-url']}
                    className='header-button'
                    secondary
                  >
                    {props['bottom-cta-text']}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='cell large-1 medium-order-3 hide-for-small-only hide-for-medium-only'>
          <div className='grid-x product-header-offset-image-container'>
            {props['hero-two-video-url'] && (
              <video
                className='product-header-right-offset-image'
                autoPlay
                loop
                muted
                preload='true'
                poster={optimizeImage(props['hero-two-poster-url'])}
                data-testid='hero-two-video'
              >
                <source src={props['hero-two-video-url']} type='video/webm' />
                <source src={props['hero-two-']} type='video/mp4' />
                <Image
                  src={props['hero-two-poster-fallback-url']}
                  alt={props.description}
                  isLazy={false}
                />
              </video>
            )}
            {hasImageSecondaryOnly && (
              <Image
                className='product-header-right-offset-image'
                src={props['hero-two-poster-fallback-url']}
                alt={props.description}
                data-testid='product-header-image-secondary'
                isLazy={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProductPageHeader;
