/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

/**
 * Maps the contentful response for carousel with simple slide
 *
 * @param {Object} args
 * @param {Array<Object>} args.carouselSlides
 * @returns {Object}
 */
export const carouselWithSimpleSlideAdapter = ({ carouselSlides = [] }) => {
  return {
    'carousel-slides': _uniqBy(carouselSlides, 'sys.id')
      .filter((slide) => {
        return _get(slide, 'sys.contentType.sys.id') === 'carouselItem';
      })
      .map((slide) => {
        return {
          imageShouldCoverRightHalf: _get(
            slide,
            'fields.imageShouldCoverRightHalf',
            false,
          ),
          carouselTitle: _get(slide, 'fields.label'),
          body: _get(slide, 'fields.body'),
          'bg-color': _get(
            slide,
            'fields.backgroundColor.fields.color',
            'bg-green',
          ),
          img: {
            src: _get(
              slide,
              'fields.image.fields.file.url',
              '/assets/img/carousel/use-cases-carousel-0.png',
            ),
            alt: _get(slide, 'fields.image.fields.title'),
          },
          verticalAlignment: _get(slide, 'fields.verticalAlignment'),
        };
      }),
  };
};

export const simpleSlideAdapter = ({ fields }) => {
  return {
    imageShouldCoverRightHalf: _get(fields, 'imageShouldCoverRightHalf', false),
    carouselTitle: _get(fields, 'label'),
    body: _get(fields, 'body'),
    'bg-color': _get(fields, 'backgroundColor.fields.color', 'bg-green'),
    img: {
      src: _get(
        fields,
        'image.fields.file.url',
        '/assets/img/carousel/use-cases-carousel-0.png',
      ),
      alt: _get(fields, 'image.fields.title'),
    },
    verticalAlignment: _get(fields, 'verticalAlignment'),
  };
};
