/**
 * Maps the Contentful response for the Partner Page Footer to the view
 *
 * @param {String} args.title
 * @param {String} args.description
 * @param {Object} args.cta
 * @returns {Object}
 */

const ctaRowWithBrushstrokeAdapter = ({
  title,
  description,
  ctaButtons,
} = {}) => {
  const cta = ctaButtons[0];
  return {
    title,
    description: description?.fields?.body,
    cta: {
      text: cta?.fields?.text,
      url: cta?.fields?.url,
    },
  };
};

export default ctaRowWithBrushstrokeAdapter;
