import React, { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import ThreadsSelect from 'plaid-threads/Select';
import cx from 'classnames';

import styles from './Select.module.scss';

import { InputProps } from '../TextInput/TextInput';

export interface Option {
  label: string;
  value: string | boolean | number;
  [key: string]: string | boolean | number;
}

export interface SelectProps extends InputProps {
  controlClassName?: string;
  onInputChange?: (newValue: string, actionMeta) => void;
  onChange?: (arg) => void;
  options?: Option[];
  defaultValue?: Option;
  autoComplete?: string;
  isMulti?: boolean;
  isLoading?: boolean;
  noOptionsMessage?: (inputObj: { inputValue: string }) => string;
  loadingMessage?: (inputObj: { inputValue: string }) => string;
}

const Select: FC<SelectProps> = ({
  className,
  controlClassName,
  id,
  isMulti,
  required,
  ...restProps
}) => {
  return (
    <ThreadsSelect
      {...restProps}
      required={!!required}
      onFocus={(e) => {
        e.target.autocomplete = 'nope';
      }}
      className={cx(styles.select, className)}
      controlClassName={cx(styles.selectControl, controlClassName)}
      classNamePrefix='customSelect'
      id={id}
      instanceId={`${id}_instance`}
      isMulti={isMulti}
    />
  );
};

Select.displayName = 'Select';
export default Select;
