import React from 'react';

const LinkedinIcon = () => {
  return (
    <svg height={25} width={24} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5 17.505h-2.2v-3.57c0-.857-.087-1.95-1.268-1.95-1.174 0-1.363.925-1.363 1.883v3.637h-2.14V10.17h2.045v.992h.034c.31-.587 1.052-.992 2.153-.992 1.876 0 2.51.864 2.679 2.254.04.324.06.675.06 1.052zM7.783 9.07a1.282 1.282 0 11-.002-2.564 1.282 1.282 0 01.002 2.564zm1.282 2.611v5.824H6.5V10.17h2.565zm11.42-7.66A11.955 11.955 0 0012.002.504 11.954 11.954 0 003.516 4.02C1.343 6.193 0 9.19 0 12.505s1.343 6.313 3.516 8.485A11.956 11.956 0 0012 24.505c3.315 0 6.312-1.342 8.485-3.515C22.658 18.818 24 15.82 24 12.505s-1.342-6.312-3.514-8.485z'
        fill='#9b9b9b'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default LinkedinIcon;
