import { createContext, useContext } from 'react';

import { Locales } from 'src/lib/constants';

type Locales = string;

const LocaleContext = createContext<Locales>(Locales.EN_US);

export const usePageLocale = (): string => {
  const locale = useContext(LocaleContext);
  return locale;
};

export default LocaleContext;
