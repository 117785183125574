export const metaData = {
  layout: 'docs',
  'meta-title': 'Information security',
  'meta-description':
    'Our information security program is designed to meet or exceed industry standards. Discover a few of the ways that Plaid is working to help keep you safe.',
  'article-header': 'true',
  'hide-search': 'true',
  'article-title': 'Security',
  'article-subtitle': 'Information security is essential to everything we do.',
};

export const pageHeadingData = {
  title: 'Security',
  subtitle: 'Information security is essential to everything we do.',
  paragraph:
    'Our information security program is designed to meet or exceed industry standards, and we use many different controls to help keep your personal information safe. Here are some important things to note about our information security program.',
};

export const sidenavData = [
  {
    title: 'Overview',
    id: 'overview',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Data encryption',
    id: 'data-encryption',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Strong authentication',
    id: 'strong-authentication',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Cloud infrastructure',
    id: 'cloud-infrastructure',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Robust monitoring',
    id: 'robust-monitoring',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Independent security testing',
    id: 'independent-security-testing',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
  {
    title: 'Collaboration',
    id: 'collaboration',
    icon: 'arrow-right-caret',
    alt: 'Arrow Icon',
    'has-subnav': false,
  },
];

export const sectionsWithIconData = [
  {
    title: 'Data encryption',
    id: 'data-encryption',
    paragraph:
      'The combination of the Advanced Encryption Standard (AES 256) and Transport Layer Security (TLS) help keep sensitive data safe. Modern web and mobile applications use a technology called Transport Layer Security (TLS) to help protect data in-transit from unauthorized third-parties. Plaid uses TLS for all information exchanges between the Plaid API, financial institutions, and Plaid customers. We also encrypt sensitive data-at-rest whenever we store it using AES 256 encryption at object and volume levels, which helps sensitive data remain protected even in the event of a database compromise.',
    iconSlug: 'encryption',
  },
  {
    title: 'Strong authentication',
    id: 'strong-authentication',
    paragraph:
      'Plaid uses Multi-Factor Authentication (MFA) as an additional layer of authentication beyond usernames and passwords for all critical internal systems. This helps prevent unauthorized users from gaining access to your data.',
    iconSlug: 'authentication',
  },
  {
    title: 'Cloud infrastructure',
    id: 'cloud-infrastructure',
    paragraph:
      'Plaid uses secure cloud infrastructure technologies to host the Plaid API and all related components. Cloud service providers used by Plaid invest heavily in security and resilience-enhancing technologies that have been developed and hardened over time. By using modern cloud infrastructure, Plaid is able to leverage years of security enhancements to better protect against security incidents.',
    iconSlug: 'cloud',
  },
  {
    title: 'Robust monitoring',
    id: 'robust-monitoring',
    paragraph:
      'Robust monitoring, automated alerts, and a 24/7 on-call team helps Plaid quickly respond and resolve adverse events. This is a key component of Plaid’s information security program.',
    iconSlug: 'monitoring',
  },
  {
    title: 'Independent security testing',
    id: 'independent-security-testing',
    paragraph:
      'Security researchers, app developers, and financial institutions regularly audit and stress test the Plaid API and our security controls against industry standards to help us maintain a resilient information security program. We also operate a <a href="https://hackerone.com/plaid">bug bounty program</a> that is open to the public. If you have discovered a bug or a security vulnerability with any Plaid API endpoints, please use our bug bounty program to responsibly disclose your findings for a bounty.',
    iconSlug: 'security-testing',
  },
];

export const collaborationSectionData = {
  title: 'Collaborating for a safe digital financial ecosystem',
  id: 'collaboration',
  paragraphs: [
    'As the infrastructure connecting thousands of apps and services to over 10,000 financial institutions, we take our role seriously. We share our security practices, learnings, and technologies with partners, app developers, and financial institutions to make sure we are working toward a more secure digital financial system together.',
    'If you have questions, please contact our security team at <a href="mailto:security@plaid.com">security@plaid.com</a>',
  ],
};
