import React from 'react';

const ArrowDownBlack = () => {
  return (
    <svg height={6} width={8} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 3.388L0 .387v2l4 3.001 4.001-3.001v-2z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default ArrowDownBlack;
