import React from 'react';
import PropTypes from 'prop-types';
import { INLINES } from '@contentful/rich-text-types';
import _get from 'lodash/get';

import { Link } from 'src/components/Inputs';

const propTypes = {
  aClassName: PropTypes.string,
};

const HyperLink = ({ aClassName = '' }) => {
  return {
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const uri = _get(node, 'data.target.fields.file.url', '');
      return (
        <Link href={uri}>
          <a className={aClassName}>{children}</a>
        </Link>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const newUrl = node.data.target.fields.url;
      const oldUrl = node.data.target.fields.pageSlug;

      const uri = newUrl ? newUrl.join('/') : oldUrl;
      return (
        <Link href={`/${uri}`}>
          <a className={aClassName}>{children}</a>
        </Link>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const uri = _get(node, 'data.uri', '');
      const urlRegex = new RegExp(
        /^((https?:\/\/(www\.|)plaid\.com)\/?.+|#\w+)\w*/,
      );
      return (
        <Link href={uri}>
          <a
            className={aClassName}
            target={urlRegex.test(uri) ? null : '_blank'}
          >
            {children}
          </a>
        </Link>
      );
    },
  };
};

HyperLink.propTypes = propTypes;

export default HyperLink;
