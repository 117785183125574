import React, { FC } from 'react';
import ThreadsModal, { Props } from 'plaid-threads/deprecated/Modal';

/**
 * A simple wrapped component around the standard Threads Modal component
 */
const Modal: FC<Props> = ({ children, ...props }) => {
  return <ThreadsModal {...props}> {children} </ThreadsModal>;
};

Modal.displayName = 'Modal';
export default Modal;
