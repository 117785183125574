import React from 'react';

import { useIsDarkBackground } from 'src/contexts';

import { BLOCKS } from '@contentful/rich-text-types';
import { S } from 'src/components-v2/StyledElements/StyledElements';

const UnorderedList = ({ ul }) => {
  const isDarkBackground = useIsDarkBackground();
  return {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <S.Ul
          {...ul}
          sx={
            isDarkBackground && {
              '& li::marker': {
                color: 'white',
              },
            }
          }
        >
          {children}
        </S.Ul>
      );
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <S.Li>{children}</S.Li>;
    },
  };
};

export default UnorderedList;
