import React, { FC } from 'react';
import { CardProps } from './Card.types';
import { Box } from 'src/components-v2/Layout';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const Card: FC<CardProps> = ({ children, sx, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Box
      {...inspectorProps({ fieldId: 'internalTitle' })}
      sx={{
        backgroundColor: 'white',
        border: 1,
        borderColor: 'black500',
        borderRadius: '16px',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
