import React from 'react';
import PropTypes from 'prop-types';

import { Feature } from '../';

const propTypes = {
  categories: PropTypes.shape({
    location: PropTypes.string.isRequired,
  }),
  allLocationsData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
};

const FeaturedLocation = ({ categories: { location }, allLocationsData }) => {
  const supportedLocations = Object.keys(allLocationsData);
  const matchedLocation = supportedLocations.includes(location)
    ? location
    : 'San Francisco';
  const { content, image, linkUrl } = allLocationsData[matchedLocation];
  const featureProps = {
    heading: `${matchedLocation} Office`,
    altText: `image of ${matchedLocation} office`,
    linkText: 'View Open Positions',
    image,
    content,
    linkUrl,
  };

  return (
    <article className='job-post-feature job-post-feature--office'>
      <Feature {...featureProps} />
    </article>
  );
};

FeaturedLocation.propTypes = propTypes;
export default FeaturedLocation;
