import { Author as AuthorProps, AuthorFields } from './Author.types';

const authorAdapter = (fields: AuthorFields): AuthorProps => {
  return {
    name: fields?.name,
    image: {
      alt: fields?.image?.fields?.title,
      src: fields?.image?.fields?.file?.url,
    },
    blurb: fields?.blurb,
  };
};

export default authorAdapter;
