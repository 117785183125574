import React from 'react';

import SelectGroup from 'plaid-threads/SelectGroup';
import { CodeSnippet } from 'src/components/Surfaces';
import styles from './ProductsCodeDemo.module.scss';

const ProductsCodeDemo = ({
  data,
  products,
}: {
  data: Record<string, unknown>;
  products: Array<string>;
}): JSX.Element => {
  // code demo state control
  const [selectedProduct, toggleProduct] = React.useState({
    label: products[0],
    value: products[0],
  });

  const [endpointDemoData, setEndpointDemoData] = React.useState(
    data[products[0]],
  );

  const [endpointPropsPrefix, setEndpointPropsPrefix] = React.useState(
    'post-run-',
  );

  React.useEffect(() => {
    let product = data[selectedProduct.value];
    let prefix = 'post-run-';
    if (selectedProduct.value === 'Investments') {
      prefix = '';
      product = data['Investments Holdings'];
    }
    setEndpointDemoData(product);
    setEndpointPropsPrefix(prefix);
  }, [data, selectedProduct.value]);
  return (
    <div className={styles.codeDemoWrapper} data-testid='products-code-demo'>
      <div>
        <div className='grid-container'>
          <div className='grid-x align-justify'>
            <div className='cell small-12'>
              <h4>Sample product data</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    endpointDemoData[`${endpointPropsPrefix}description-text`],
                }}
              />
              <div className={styles.selectGroupWrapper}>
                <SelectGroup
                  onChange={toggleProduct}
                  options={products.map((product) => {
                    return {
                      label: product,
                      value: product,
                    };
                  })}
                  value={selectedProduct}
                  buttonClassName={styles.selectButton}
                />
              </div>
              <div className={styles.codeSnippetWrapper}>
                <CodeSnippet
                  syntax={`json`}
                  title={endpointDemoData[`${endpointPropsPrefix}code-title`]}
                  className={styles.codeSnippetStyle}
                >
                  {endpointDemoData[`${endpointPropsPrefix}code-body`]}
                </CodeSnippet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCodeDemo;
