/* eslint-disable import/prefer-default-export */
import { Asset, RichTextContent, Sys } from 'contentful';
import { pageHeaderWithFormAdapter } from 'src/components/headers/PageHeaderWithForm/';
import { FormFields, formCtaAdapter } from 'src/components/FormCta/adapters';
import { CmsComponents } from 'src/lib/constants';

export const PAGE_HEADER = 'pageHeaderWithForm';

interface FormTypeSys {
  contentType: {
    sys: {
      id: string;
    };
  };
}

export interface PageHeaderFormType {
  fields: PageHeaderFormTypeFields;
  sys: FormTypeSys;
}

export interface PageHeaderFormTypeFields {
  heading: string;
  form: {
    fields: {
      marketoId: number;
    };
  };
  image: Asset;
}

interface FormCtaFormType {
  fields: FormCtaFormTypeFields;
  sys: Sys;
}

interface FormCtaFormTypeFields extends FormFields {
  darkMode: boolean;
  primaryHeading: string;
  secondaryHeading?: string;
  facePile: boolean;
  form: FormCtaFormType;
  image: Asset;
}

type GateTypeIsFormCta = boolean;
type GateTypeIsPageHeader = boolean;

interface GateTypeDerivedData {
  gateTypeIsFormCta: GateTypeIsFormCta;
  gateTypeIsPageHeader: GateTypeIsPageHeader;
  gateComponentProps: {
    formData?: {
      shouldForward?: boolean;
      trackingId?: string;
    };
  };
}

export interface GatedContentCmsData {
  informationGateFormType: PageHeaderFormType;
  internalTitle: string;
  useRedirect: boolean;
  unlockedContentAsset?: Asset[];
  unlockedContentConfirmation?: {
    fields: {
      body: RichTextContent;
    };
  };
  unlockedContentRedirect?: {
    fields: {
      file: {
        url: string;
      };
    };
  };
}

interface VideoAsset {
  src: string;
  type: string;
}

interface GatedContentProps {
  confirmationContent?: RichTextContent;
  formData: {
    shouldForward?: boolean;
    trackingId?: string;
  };
  gateTypeIsFormCta: GateTypeIsFormCta;
  gateTypeIsPageHeader: GateTypeIsPageHeader;
  hasOnPagePostSubmitContent: boolean;
  redirectOnSubmit: boolean;
  redirectUrl: string;
  videoContent: {
    formats: VideoAsset[];
  };
}

// @TODO: Support generalized Form CTA as variant gate type
// preliminary plumbing in place, i.e `gateTypeIsFormCta` et al references
// https://jira.plaid.com/browse/SITE-2583

// @TODO: replace the below switch with main component switch https://jira.plaid.com/browse/SITE-4617
const deriveGateType = (
  informationGateFormType: FormCtaFormType | PageHeaderFormType,
): GateTypeDerivedData => {
  const gateFormType = informationGateFormType.sys.contentType.sys.id;
  switch (gateFormType) {
    case CmsComponents.PAGE_HEADER_WITH_FORM:
      return {
        gateTypeIsPageHeader: true,
        gateTypeIsFormCta: false,
        gateComponentProps: pageHeaderWithFormAdapter(
          informationGateFormType.fields as PageHeaderFormTypeFields,
        ),
      };
    case CmsComponents.FORM_CTA:
      return {
        gateTypeIsPageHeader: false,
        gateTypeIsFormCta: true,
        gateComponentProps: formCtaAdapter({
          fields: informationGateFormType.fields as FormCtaFormTypeFields,
          template: 'landingPage', // we pass legacy here so the switch will adapt the form to legacy templates
        }),
      };
  }
};

export const toGatedContentProps = ({
  informationGateFormType,
  internalTitle,
  useRedirect,
  unlockedContentAsset,
  unlockedContentConfirmation,
  unlockedContentRedirect,
}: GatedContentCmsData): GatedContentProps => {
  const {
    gateTypeIsPageHeader,
    gateTypeIsFormCta,
    gateComponentProps: { formData, ...gateComponentProps },
  } = deriveGateType(informationGateFormType);

  formData.shouldForward = useRedirect; // override MarketoForm control
  formData.trackingId = `Unlock ${internalTitle || 'Gated Content'}`;
  return {
    confirmationContent: unlockedContentConfirmation?.fields?.body,
    formData,
    gateTypeIsFormCta,
    gateTypeIsPageHeader,
    hasOnPagePostSubmitContent: !useRedirect,
    redirectOnSubmit: useRedirect,
    redirectUrl: unlockedContentRedirect?.fields?.file?.url,
    videoContent: {
      formats: unlockedContentAsset?.map((item) => {
        return {
          src: 'https://' + item.fields.file.url,
          type: item.fields.file.contentType,
        };
      }),
    },
    ...gateComponentProps,
  };
};
