import React from 'react';
import { Carousel } from 'src/components/Carousel';

const CarouselIterator = ({ items, isCarousel, ...props }) => {
  const renderSlide = (item, index) => (
    <div className='content-carousel-slide' key={index}>
      {props.render(item)}
    </div>
  );

  if (isCarousel && items.length > 1) {
    return <Carousel>{items.map(renderSlide)}</Carousel>;
  }

  return <>{props.render(items[0])}</>;
};

export default CarouselIterator;
