import { useEffect, useState } from 'react';
import { getCookie } from 'public/assets/js/analytics/cookie';

interface ISegment {
  segmentId?: string;
  userTraits: string;
}

const useSegmentId = () => {
  const [state, setState] = useState<ISegment>({
    segmentId: '',
    userTraits: '',
  });

  useEffect(() => {
    const getSegmentUserTraits = () => {
      const trackingPreferencesCookie = decodeURIComponent(
        getCookie('OptanonConsent'),
      );
      if (!trackingPreferencesCookie) {
        return;
      }
      setState((prevState) => {
        return {
          ...prevState,
          segmentId: getCookie('ajs_anonymous_id'),
          userTraits: trackingPreferencesCookie,
        };
      });
    };

    // Segment is loaded async and does not notify this hook when it is ready
    // therefore we must keep trying every 300ms until we have a response from Segment
    const interval = setInterval(getSegmentUserTraits, 100);

    // we clear the interval once we have a response from Segment
    if (state.segmentId) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [state.segmentId]);

  const performance = state.userTraits?.includes('C0002:1'); // this is performance category
  return performance ? state.segmentId : '';
};

export default useSegmentId;
