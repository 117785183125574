import { isDevelopment, isTesting } from 'src/lib/constants';

export const fetchMetrics = async () => {
  try {
    const metricsResp = await fetch('https://metrics.plaid.com/init');
    return await metricsResp.json();
  } catch (e) {
    throw new Error(`Error fetching metrics: ${e}`);
  }
};

export const getLinkDemoHost = () => {
  if (isDevelopment) {
    return 'http://localhost:8000';
  }
  if (isTesting) {
    return 'https://link-demo.testing.ue1.plaid.io';
  }
  return 'https://demo-backend.plaid.com';
};

export const getLinkTokenEndpoint = () => {
  return getLinkDemoHost() + '/link-token';
};

const makeLinkTokenEndpoint = ({
  metrics,
  selectedCountryCode,
  selectedLanguage,
  selectedProduct,
  isAuthTypeSelectEnabled,
}) => {
  const linkTokenEndpoint = new URL(getLinkTokenEndpoint());
  linkTokenEndpoint.searchParams.append('metrics_id', metrics.id);
  linkTokenEndpoint.searchParams.append('country_codes', selectedCountryCode);
  linkTokenEndpoint.searchParams.append('language', selectedLanguage);
  linkTokenEndpoint.searchParams.append('products', selectedProduct);
  linkTokenEndpoint.searchParams.append(
    'auth_type_select_enabled',
    selectedProduct === 'auth' &&
      selectedCountryCode === 'US' &&
      isAuthTypeSelectEnabled,
  );
  return linkTokenEndpoint;
};

export const fetchLinkToken = async (
  {
    selectedCountryCode,
    selectedLanguage,
    selectedProduct,
    isAuthTypeSelectEnabled,
  },
  {
    signal,
  }:
    | {
        signal: AbortSignal;
      }
    | undefined,
) => {
  const metrics = await fetchMetrics();
  const linkTokenEndpoint = makeLinkTokenEndpoint({
    metrics,
    selectedCountryCode,
    selectedLanguage,
    selectedProduct,
    isAuthTypeSelectEnabled,
  });
  try {
    const linkTokenResp = await fetch(linkTokenEndpoint.href, { signal });
    const linkToken = await linkTokenResp.json();
    return linkToken;
  } catch (e) {
    if (e.name === 'AbortError') return;
    throw new Error(`Error fetching link token: ${e}`);
  }
};
