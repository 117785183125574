import { Locales, StaticTemplates } from 'src/lib/constants';

// Example page object
// {
//   params: {
//     id: Locales.UK, // locale code
//     index: 'company', // page slug
//     template: 'localizedCompanyPage', // template, probably should be define in constants
//   },
// },

export const enGbRootPages = [
  {
    params: {
      id: Locales.EN_GB,
      index: 'what-is-plaid',
      template: StaticTemplates.WHAT_IS_PLAID,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'why-is-plaid-involved',
      template: StaticTemplates.WHY_PLAID_IS_INVOLVED,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'contact-thanks',
      template: StaticTemplates.CONTACT_THANKS,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'contact',
      template: StaticTemplates.CONTACT,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'developer-policy',
      template: StaticTemplates.DEVELOPER_POLICY,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'discover-apps',
      template: StaticTemplates.DISCOVER_APPS,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'global',
      template: StaticTemplates.GLOBAL,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'how-it-works-for-consumers',
      template: StaticTemplates.HOW_IT_WORKS,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'open-banking',
      template: StaticTemplates.OPEN_BANKING,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'security',
      template: StaticTemplates.SECURITY,
    },
  },
  {
    params: {
      id: Locales.EN_GB,
      index: 'how-we-handle-data',
      template: StaticTemplates.HOW_WE_HANDLE_DATA,
    },
  },
];
