import React, { FC } from 'react';
import ThreadsNote from 'plaid-threads/Note';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  solid?: boolean;
  attention?: boolean;
  hasIcon?: boolean;
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  small?: boolean;
}

const Label: FC<Props> = ({
  children,
  className = '',
  solid = false,
  attention = false,
  hasIcon = false,
  warning = false,
  error = false,
  success = false,
  small = false,
}) => {
  return (
    <ThreadsNote
      solid={solid}
      attention={attention}
      hasIcon={hasIcon}
      warning={warning}
      error={error}
      success={success}
      small={small}
      className={className}
    >
      {children}
    </ThreadsNote>
  );
};

export default Label;
