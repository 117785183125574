export const metaData = {
  metaTitle: 'Shift',
  metaDescription:
    'Shift was collecting payments the old way—and it was harming their business. With Plaid, they saw a 60% increase in on-the-spot conversions.',
};

export const pageHeaderData = {
  classes:
    'background background--color-pink600 background--size-contain background--position-bottom-right',
  'background-image': '/assets/img/backgrounds/stroke-pink-1@2x.png',
  slideUpContent: true,
  slideUpImage: true,
  'content-width-classes': 'page-header-breakpoint-6 large-6',
  'right-cell-classes': null,
  label: true,
  'label-classes': 'page-header-label--black',
  'label-text': 'Customer story: Shift',
  header: 'Shifting the used car landscape',
  'header-class': 'h2',
  subheader: null,
  'primary-cta': true,
  'primary-cta-text': 'Contact sales',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'secondary-cta': true,
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Shift-Case-Study.pdf',
  video: false,
  image: true,
  'image-width': 352,
  'image-height': 440,
  'image-url': '/assets/img/customer-stories/header-hero-shift@2x.png',
  'image-phone': true,
  'image-classes': 'page-header-bg__img-overflow-large',
  'popup-logo-url': '/assets/img/customer-stories/logo-shift@2x.png',
};

export const introSectionData = {
  classes: 'background background--color-black100',
  'with-story-header': true,
  'left-label-1': 'Goal',
  'left-label-1-content':
    'Enable ACH payments,<br />prevent NSF/overdraft fees',
  'right-label-1': 'Region',
  'right-label-1-content': 'United States',
  'right-label-2': 'Industry',
  'right-label-2-content': 'Automotive',
  'custom-titles': true,
  'problem-title': 'PROBLEM',
  'problem-statement':
    'Shift was collecting payments the old way—and it was harming their business.',
  'solution-title': 'SOLUTION',
  'solution-statement':
    'With Plaid, Shift’s customers can instantly link a bank account and execute an ACH transfer. As a result, they saw their on-the-spot purchases increase by 60%.',
};

export const content1Data = {
  heading:
    'Since its launch in 2014, Shift has prided itself on bringing trust and simplicity to the peer-to-peer used car market, turning what can be a stressful life event into a gratifying experience.',
  paragraph: [
    'So, when Shift’s first-generation payment collection processes threatened the integrity of its three guiding principles—convenience, value, and trust—the Shift executive team turned to Plaid to help the company regain its edge.',
  ],
};

export const supportingTextData = {
  classes: 'background background--color-pink200 supporting-text--constrained',
  'two-up': true,
  stat: true,
  stat2: '60%',
  text2:
    'After integrating with Plaid, Shift saw a 60% increase in the conversion rate of its on-the-spot car buyers.',
  stat3: '10%',
  text3: 'After integrating with Plaid, Shift saw a 10% increase in its NPS.',
};

export const content2Data = {
  title: 'Rethinking the status quo',
  paragraph: [
    'When it comes to car ownership, Shift is rethinking the status quo.',
    'Founded in San Francisco and currently operating in California, Shift is a 24/7, digital marketplace and end-to-end car concierge service with a trusted advisor team that takes the hassle and guesswork out of buying and selling a used car.',
    'For sellers, Shift’s services include no-obligation instant price quotes, car pickup and detailing, registration transfers, professional photography, and optimized digital listings on both shift.com and automotive sites.',
    'For buyers, it offers transparent pricing, on-demand test drives, on-the-spot purchasing, competitive financing, protection plans, and the peace of mind that comes with a rigorous, 150+ point vehicle inspection through the Shift Certified process.',
    'Despite its attractive business model and early successes, outdated technology was holding Shift back at the beginning. The company originally relied on paper payments—cash and cashier’s checks—and manual processing, which was at odds with its tech-first platform and tech-savvy consumers.',
    '“It was difficult to both collect payments from buyers and to pay out sellers,” said Eva Wei, growth and analytics manager at Shift. “Facing difficulties on both sides of the platform, we weren’t able to facilitate a transfer in an efficient way.”',
  ],
};

export const diagramData = {
  classes:
    'background background--color-black100 background--image-stroke-accents',
  'stroke-top-right': '/assets/img/accents/diagram-brushstroke-pink-3@2x.png',
  'stroke-bottom-left': '/assets/img/accents/diagram-brushstroke-pink-4@2x.png',
  heading: 'Where Plaid fits in',
  step: [
    {
      number: 1,
      copy:
        'Shift’s checkout flow allows users to link their bank accounts. That triggers Link, Plaid’s simple front-end module.',
    },
    {
      delay: 600,
      number: 2,
      'hr-color': 'black700',
      copy: 'Users link accounts by entering their bank username and password.',
    },
    {
      delay: 1200,
      number: 3,
      'hr-color': 'black700',
      copy:
        'That’s it! In a matter of seconds, Shift’s users can start paying with ACH.',
    },
  ],
  'icon-row-1': [
    {
      label: 'USER ONBOARDS',
      'label-delay': 1500,
      img:
        '/assets/img/customer-stories/diagram-step-onboards-mobile-pink@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 300,
    },
  ],
  'icon-row-2': [
    {
      label: 'PLAID LINK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-link-mobile-pink@2x.png',
      'arrow-down': true,
      'arrow-down-delay': 900,
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-right': true,
      badge: 'lock',
    },
    {
      label: 'PLAID BACK-END',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-back-end@2x.png',
      'arrow-lateral': true,
      'arrow-lateral-delay': 600,
      'arrow-left': true,
      'arrow-right': true,
    },
    {
      label: 'BANK',
      'label-delay': 1500,
      'icon-delay': 600,
      img: '/assets/img/customer-stories/diagram-step-bank-pink@2x.png',
      badge: 'checkmark',
    },
  ],
  'icon-row-3': [
    {
      label: 'BANK CONNECTED',
      'label-delay': 1500,
      'icon-delay': 1200,
      img:
        '/assets/img/customer-stories/diagram-step-piggy-bank-mobile-pink@2x.png',
      badge: 'checkmark',
    },
  ],
};

export const content3Data = [
  {
    title: 'A payments problem',
    paragraph: [
      'In practice, those difficulties manifested in buyers having to pay at vehicle dropoff with hard-to-reconcile cashier’s checks or cash—a major inconvenience, not to mention a trust and safety issue.',
      'To further complicate things, buyers often purchased add-ons such as a protection plan or gap insurance on the spot, making it difficult to arrive at a drive-away-with price in advance. That meant Shift concierges would sometimes have to drive the car with the customer to a bank to get payment.',
      'If this happened on Sundays or bank holidays, concierges had to schedule follow-up test drives, which sometimes resulted in lost sales. “The buyers were there, and they wanted to buy the car. We were there with the car, and we wanted to sell them the car. But the transaction couldn’t occur because of payment,” said Wei.',
      'On the seller’s side, Shift paid out by check, which came with its own set of headaches. Change-of-address and ACH requests resulted in unnecessary paperwork for the company’s accounting department and costly processing fees.',
    ],
  },
  {
    image: {
      src: '/assets/img/customer-stories/headshot-shift@2x.png',
      alt: 'Headshot of James Wu, Software Engineer at Shift',
    },
    quote: [
      {
        copy:
          'Plaid enabled Shift to offer a fully integrated online purchasing experience. The financial aspects of the process are much more seamless.',
        source: 'James Wu, Software Engineer, Shift',
      },
    ],
  },
  {
    title: 'A Plaid solution',
    paragraph: [
      'It wasn’t long before Shift sought a new online payment solution.',
      'That solution took the form of Plaid + Stripe. Because Shift chose Stripe as its online payments platform, it needed a verification tool that would easily integrate. Plaid’s Balance and Auth products work well with Stripe to quickly and securely check account balances in realtime and authenticate transfers without waiting for tedious microdeposits to clear.',
      'By eliminating cash and check payments, Plaid saved Shift countless man-hours, all while protecting the company against fraud and improving the customer experience, as evidenced by a 10% increase in Shift’s buyer NPS after integration.',
      '“Plaid enabled Shift to offer a fully integrated online purchasing experience,” said James Wu, the software engineer at Shift who led the integration. “The financial aspects of the process are much more seamless.”',
      '“We’ve come a long way from envelopes of cash, thanks to Plaid,” added Wei.',
    ],
  },
  {
    title: 'Better conversion rates',
    paragraph: [
      'Plaid has also directly served Shift’s bottom line.',
      'According to Wei, in the month after integration, buyer on-the-spot conversions increased by approximately 60% for non-financing customers—an important KPI for Shift.',
      'In reducing the number of secondary and tertiary in-person appointments, Plaid also reduced a lot of complex operational costs associated with concierge transportation, canceled checks, and express check mailings.',
      'With its transaction technology in efficient order, Shift became better positioned to redirect its energies and resources to accelerate company innovation and growth. Expanding to new markets outside of California is at the top of the list.',
      '<strong>“Now Shift can focus on what it does best,” said Wu.</strong>',
    ],
  },
];

export const relatedStoriesData = {
  title: 'Related customer stories',
  'cta-text': 'See all customer stories',
  'cta-url': '/customer-stories',
  items: [
    {
      name: 'Alto',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-alto@2x.png',
        alt: 'Alto - read full story',
      },
      excerpt: 'A pharmacy for the digital age',
      url: '/customer-stories/alto',
    },
    {
      name: 'Carvana',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-carvana@2x.png',
        alt: 'Carvana - read full story',
      },
      excerpt: 'Accelerating the future of auto payments',
      url: '/customer-stories/carvana',
    },
    {
      name: 'Qapital',
      cardImage: {
        src: '/assets/img/logos/card-company-logo-qapital@2x.png',
        alt: 'Qapital - read full story',
      },
      excerpt: 'A path to money happiness',
      url: '/customer-stories/qapital',
    },
  ],
};

export const footerData = {
  'cta-title-wide': true,
  'cta-title': 'Questions about how Plaid fits into your business?',
  'primary-cta-link': '//dashboard.plaid.com/contact',
  'primary-cta-text': 'Contact sales',
  'secondary-cta-text': 'Download PDF',
  'secondary-cta-link': '/documents/Shift-Case-Study.pdf',
};

export const components = new Map([
  ['pageHeaderData', pageHeaderData],
  ['introSectionData', introSectionData],
  ['content1Data', content1Data],
  ['supportingTextData', supportingTextData],
  ['content2Data', content2Data],
  ['diagramData', diagramData],
  ['content3Data', content3Data],
  ['relatedStoriesData', relatedStoriesData],
  ['footerData', footerData],
]);
