import { useEffect } from 'react';

import animate from 'src/lib/animate';
import { animateCSSProperties, fadeIn } from 'src/lib/animation-utils';

const makeFairyBits = () => {
  const img = document.createElement('img');
  img.style.position = 'absolute';
  img.style.top = '20px';
  img.style.left = '75px';
  img.width = 150;
  img.src = `/assets/img/products/fairy-bits.gif?q${Math.random()}`;
  return img;
};

export const makeAnimationChain = ({ stayTallHeight = 624 }) => {
  const animationChain = [
    ({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    }) => {
      const runButtonGradient = parentContainer.querySelector(
        '[data-id="run-button-gradient"]',
      );
      animate({
        draw: animateCSSProperties(runButtonGradient, { left: '0px' }),
        duration: 1000,
        start: () => {
          runButtonGradient.style.display = 'block';
          const runInteractiveData = parentContainer.querySelector(
            '[data-id="run-interactive-data"]',
          );
          runInteractiveData.appendChild(makeFairyBits());
        },
        done: () => {
          const runInteractiveData = parentContainer.querySelector(
            '[data-id="run-interactive-data"]',
          );
          animate({
            draw: animateCSSProperties(runInteractiveData, { opacity: '0' }),
          });
        },
        complete: () => {
          progressAnimation({
            parentContainer,
            withAnimatedResponse,
            hasAnimated,
            animationChain,
          });
        },
      });
    },

    ({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    }) => {
      const postRunData = parentContainer.querySelector(
        '[data-id="post-run-data"]',
      ).dataset;
      const textContainer = parentContainer.querySelector(
        '[data-id="interactive-data"] [data-id="overview-text-container"]',
      );
      animate({
        draw: animateCSSProperties(textContainer, {
          opacity: '0',
          marginTop: '-200px',
        }),
        duration: 500,
        start: () => {
          const codeContainerHeaderTitle = parentContainer.querySelector(
            '[data-id="code-container-header-title"]',
          );
          codeContainerHeaderTitle.innerText = postRunData.codeTitle;
          const codeBlock = parentContainer.querySelector(
            '[data-id="code-block"]',
          );
          codeBlock.innerText = '';
          const codeBody = document.createTextNode(postRunData.codeBody);
          codeBlock.appendChild(codeBody);
          codeBlock.className = '';
          codeBlock.classList.add(postRunData.codeLang);
          // TODO: this is not needed once replaced with threads
          window.Prism.highlightAll();
        },
        done: () => {
          // Setting up the overview container to come in from below and with updated text
          const codePre = parentContainer.querySelector(
            '[data-id="interactive-data"] [data-id="code-pre"]',
          );
          codePre.classList.remove('remove-line-numbers');
          const overviewTextTitle = parentContainer.querySelector(
            '[data-id="interactive-data"] [data-id="overview-text-title"]',
          );
          overviewTextTitle.innerText = postRunData.title;
          const overviewTextDescription = parentContainer.querySelector(
            '[data-id="interactive-data"] [data-id="overview-text-description"]',
          );
          overviewTextDescription.innerHTML = postRunData.text;
          const overviewTextContainer = parentContainer.querySelector(
            '[data-id="interactive-data"] [data-id="overview-text-container"]',
          );
          overviewTextContainer.style.marginTop = '100px';
        },
        complete: () => {
          progressAnimation({
            parentContainer,
            withAnimatedResponse,
            hasAnimated,
            animationChain,
          });
        },
      });
    },

    ({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    }) => {
      const codeContainer = parentContainer.querySelector(
        '[data-id="interactive-data"] [data-id="code-container"]',
      );
      animate({
        draw: animateCSSProperties(codeContainer, {
          height: `${stayTallHeight}px`,
        }),
        duration: 500,
        complete: () => {
          codeContainer.classList.add('code-container--stay-tall');
          codeContainer.removeAttribute('style');
          progressAnimation({
            parentContainer,
            withAnimatedResponse,
            hasAnimated,
            animationChain,
          });
        },
      });
    },

    ({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    }) => {
      const overviewTextContainer = parentContainer.querySelector(
        '[data-id="interactive-data"] [data-id="overview-text-container"]',
      );
      animate({
        draw: animateCSSProperties(overviewTextContainer, {
          marginTop: '0px',
          opacity: '1',
        }),
        duration: 500,
        complete: () => {
          progressAnimation({
            parentContainer,
            withAnimatedResponse,
            hasAnimated,
            animationChain,
          });
        },
      });
    },

    ({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    }) => {
      let baseDelay = 100;
      const zeroIndexedLength =
        parentContainer.querySelectorAll(
          '[data-id="interactive-data"] [data-id^="object-property-"]',
        ).length - 1;
      // carrot ^ here denotes that the data-id must BEGIN with 'object-property-' which is
      // distinctly different than on mobile which looks like 'mobile-object-property-'
      const objectProperties = parentContainer.querySelectorAll(
        '[data-id="interactive-data"] [data-id^="object-property-"]',
      );
      objectProperties.forEach((node, index) => {
        node.style.display = 'block';
        if (index < zeroIndexedLength) {
          setTimeout(() => {
            animate({
              draw: animateCSSProperties(node, { opacity: '1' }),
              duration: 250,
            });
          }, baseDelay);
          baseDelay += 100;
        } else {
          setTimeout(() => {
            animate({
              draw: animateCSSProperties(node, { opacity: '1' }),
              duration: 1000,
              done: () => {
                // add class after 100ms delay
                const firstProp = parentContainer.querySelectorAll(
                  '[data-id="interactive-data"] [data-id^="object-property-"]',
                )[0];
                setTimeout(() => {
                  firstProp.classList.add(
                    'interactive-data-property--is-selected',
                  );
                }, 100);

                // fade in the button
                const buttonCTAContainer = parentContainer.querySelector(
                  '[data-id="interactive-data"] [data-id="button-cta-container"]',
                );
                if (buttonCTAContainer) {
                  animate({
                    draw: animateCSSProperties(buttonCTAContainer, {
                      opacity: '1',
                    }),
                  });
                }

                // make the line highlights visible
                const lineHighlights = parentContainer.querySelectorAll(
                  '[data-id="line-highlight"]',
                );
                lineHighlights.forEach((node) => {
                  animate({ draw: fadeIn(node) });
                });

                // force the code container to scroll by clicking the first object property
                parentContainer
                  .querySelectorAll(
                    '[data-id="interactive-data"] [data-id^="object-property-"]',
                  )[0]
                  .click();
              },
              complete: () => {
                progressAnimation({
                  parentContainer,
                  withAnimatedResponse,
                  hasAnimated,
                  animationChain,
                });
              },
            });
          }, baseDelay);
        }
      });
    },
  ];
  return animationChain;
};

/**
 * Callback function to progress the animation
 *
 * @param {HTMLDivElement} parentContainer
 */
const progressAnimation = ({
  withAnimatedResponse,
  parentContainer,
  hasAnimated,
  animationChain,
}): void => {
  if (Array.isArray(animationChain.current) && animationChain.current.length) {
    animationChain.current.shift()({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    });
  } else {
    hasAnimated.current = true;
  }
};

// The beginning of it all, the eventListener attached to the Run button
export const handleEvent = ({
  e,
  parentContainer,
  withAnimatedResponse,
  hasAnimated,
  animationChain,
}) => {
  const { key } = e;
  if (key && key === 'Enter') {
    progressAnimation({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    });
  }
  if (!key) {
    progressAnimation({
      parentContainer,
      withAnimatedResponse,
      hasAnimated,
      animationChain,
    });
  }
};

const isDesktopAndAbove = () => {
  return window.innerWidth >= 1024;
};

const useAnimationChain = ({
  withAnimatedResponse,
  interactiveDataNode,
  parentContainer,
  mobileParentContainer,
  hasAnimated,
  animationChain,
}): void => {
  useEffect(() => {
    // this really only seems necessary for mobile since on desktop the code is hidden
    // initial animation event provided by the withAnimatedResponse higher order component
    withAnimatedResponse.scrollToHighlightedCode(mobileParentContainer.current);

    /**
     * Begin functionality unique to the interactive data component
     */

    const scrollEvent = () => {
      if (hasAnimated.current) {
        window.removeEventListener('scroll', scrollEvent);
        return false;
      }

      if (
        interactiveDataNode.current
          .querySelector('[data-id="interactive-data-container"]')
          .getBoundingClientRect().y -
          interactiveDataNode.current.getBoundingClientRect().y >
        600
      ) {
        if (
          Array.isArray(animationChain.current) &&
          animationChain.current.length
        ) {
          animationChain.current.shift()({
            withAnimatedResponse,
            parentContainer: interactiveDataNode.current,
            hasAnimated,
            animationChain,
          });
        }
        window.removeEventListener('scroll', scrollEvent);
        return false;
      }
    };

    const beginAnimationOnScroll = (interactiveDataNode) => {
      window.addEventListener('scroll', scrollEvent);
    };

    // However, if the user doesn't click the run button, let's activate
    // the animation sequence if they scroll past but only on Desktop size
    const handleAnimationOnScroll = (): void => {
      if (hasAnimated.current === false && isDesktopAndAbove()) {
        beginAnimationOnScroll(interactiveDataNode.current);
      } else {
        window.removeEventListener(
          'scroll',
          beginAnimationOnScroll.bind(null, interactiveDataNode.current),
        );
      }
    };
    window.addEventListener('resize', handleAnimationOnScroll);

    if (hasAnimated.current === false && isDesktopAndAbove()) {
      beginAnimationOnScroll(interactiveDataNode.current);
    }

    // "freeze" screen on the animation section for a bit before activating the
    // automatic animation
    const handleScreenFreeze = (node: HTMLDivElement) => {
      if (isDesktopAndAbove()) {
        const dataCmpInitialOffset =
          node.getBoundingClientRect().y + window.scrollY;
        const contentContainer = node.querySelector(
          '[data-id="interactive-data-container"]',
        );
        const sectionAfterDataCmp = node.nextElementSibling;
        if (
          window.scrollY + window.innerHeight >=
          sectionAfterDataCmp.getBoundingClientRect().y + window.scrollY
        ) {
          contentContainer.classList.remove(
            'interactive-data-container--fixate',
          );
          contentContainer.classList.add(
            'interactive-data-container--bottom-hold',
          );
        } else if (
          window.scrollY > dataCmpInitialOffset &&
          window.scrollY + window.innerHeight <=
            sectionAfterDataCmp.getBoundingClientRect().y + window.scrollY
        ) {
          contentContainer.classList.add('interactive-data-container--fixate');
          contentContainer.classList.remove(
            'interactive-data-container--bottom-hold',
          );
        } else {
          contentContainer.classList.remove(
            'interactive-data-container--bottom-hold',
          );
          contentContainer.classList.remove(
            'interactive-data-container--fixate',
          );
        }
      }
    };
    window.addEventListener(
      'scroll',
      handleScreenFreeze.bind(null, interactiveDataNode.current),
    );

    // Let's reset state if the user resizes the viewport to below Desktop size
    const handleResetState = (node) => {
      const contentContainer = node.querySelector(
        '[data-id="interactive-data-container"]',
      );
      if (isDesktopAndAbove() === false) {
        contentContainer.classList.remove(
          'interactive-data-container--fixate',
          'interactive-data-container--bottom-hold',
        );
      }
    };
    window.addEventListener(
      'resize',
      handleResetState.bind(null, interactiveDataNode.current),
    );

    return () => {
      window.removeEventListener('resize', handleAnimationOnScroll);
      window.removeEventListener(
        'scroll',
        handleScreenFreeze.bind(null, interactiveDataNode.current),
      );
      window.removeEventListener('scroll', beginAnimationOnScroll);
      window.addEventListener(
        'resize',
        handleResetState.bind(null, interactiveDataNode.current),
      );
      window.removeEventListener('scroll', scrollEvent);
    };
  });

  return null;
};

export default useAnimationChain;
