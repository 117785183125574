import { CSSProperties, FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import useTheme from 'threads5/styles/useTheme';
import BaseIcon from 'threads5/Icon';

import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Stack } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';

import { isCardWIcon, isCardWImage, CardWIcon, CardWImage } from '../types';
import { ChevronRightBold, Logo, MenuImages } from '../assets';

import styles from './Card.module.scss';

type CardProps = {
  className?: string;
  sx?: CSSProperties;
  iconSize?: 18 | 20;
  iconBoxSize?: 32 | 40;
  tabIndex?: number;
  isLarge?: boolean;
} & (CardWIcon | CardWImage);

export const Card: FC<CardProps> = (props) => {
  const {
    className,
    href,
    title,
    description,
    iconSize = 18,
    iconBoxSize = 32,
    sx,
    tabIndex,
    isLarge = false,
  } = props;

  const theme = useTheme();

  const Chevron = description ? (
    <ChevronRightBold sx={{ width: 14 }} />
  ) : (
    <BaseIcon name='ChevronRight' size={16} />
  );

  const textPart = (
    <>
      <Stack
        component='span'
        direction='row'
        alignItems='center'
        gap={description ? '2px' : '4px'}
      >
        <Typography
          theme='main-menu-new'
          variant='p'
          component='span'
          sx={{ mb: 0 }}
        >
          {title}
        </Typography>

        {Chevron}
      </Stack>

      {description && (
        <Typography
          className={styles.Card__description}
          theme='main-menu-new'
          variant='p'
          component='span'
          sx={{ fontSize: '1.6rem', fontWeight: 400, mb: 0 }}
        >
          {description}
        </Typography>
      )}
    </>
  );

  // SVG Unique ID for clip-path
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const svgElement = svgRef.current;

      if (svgElement) {
        const clipId = `${href}-clip-${Date.now()}`;
        const clipPaths = svgElement.querySelectorAll('clipPath');
        const g = svgElement.querySelectorAll('[clip-path]');

        clipPaths.forEach((clipPath) => {
          clipPath.setAttribute('id', `${clipId}`);
        });

        g.forEach((group, i) => {
          group.setAttribute('clip-path', `url(#${clipId})`);
        });
      }
    }, 500);
  }, [href]);

  if (isCardWImage(props)) {
    const Image = MenuImages[props.image];

    return (
      <Link href={href}>
        <a
          className={cx(styles.Card, styles.Card_image, className)}
          role='link'
          tabIndex={tabIndex}
          style={sx}
        >
          <Box
            className={cx(
              styles.Card__image,
              styles[`Card__image_${props.imagePos}`],
            )}
            component='span'
            sx={{
              position: 'relative',
              display: 'inline-flex',
              mb: '8px',
              justifyContent: 'center',
              width: '100%',
              height: '200px',
              borderRadius: '12px',
              overflow: 'hidden',
              [theme.breakpoints.up(1078)]: {
                mb: '24px',
              },
            }}
          >
            <Box
              className={styles.Image}
              component='span'
              style={{
                position: 'absolute',
                left: '50%',
              }}
            >
              <Image />
            </Box>
          </Box>

          <Box className={styles.Card__body} component='span'>
            {textPart}
          </Box>
        </a>
      </Link>
    );
  }

  if (isCardWIcon(props)) {
    const Icon =
      props.icon === 'PlaidLogo' ? (
        <Logo sx={{ width: `${iconSize}px` }} />
      ) : (
        <BaseIcon
          ref={svgRef}
          data-name={props.icon}
          name={props.icon}
          size={iconSize}
          sx={{ color: 'inherit' }}
        />
      );

    return (
      <Link href={href}>
        <a
          className={cx(
            styles.Card,
            styles.Card_icon,
            { [styles.Card_description]: !!description },
            className,
          )}
          role='link'
          tabIndex={tabIndex}
          style={sx}
        >
          <Box
            className={styles.Card__icon}
            component='span'
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: `${iconBoxSize}px`,
              height: `${iconBoxSize}px`,
              borderRadius: '8px',
              marginTop: description ? '4px' : 0,
            }}
          >
            {Icon}
          </Box>

          <Box
            className={styles.Card__body}
            component='span'
            sx={{
              flex: '1 1 0%',
              [theme.breakpoints.up(1078)]: {
                minHeight: !isLarge && description ? '72px' : 0,
              },
            }}
          >
            {textPart}
          </Box>
        </a>
      </Link>
    );
  }

  return (
    <Link href={href}>
      <a className={cx(styles.Card, className)} role='link' tabIndex={tabIndex}>
        {textPart}
      </a>
    </Link>
  );
};
