import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './MarkdownRenderer.module.scss';

interface Props {
  body: string;
}

const MarkdownRenderer: FC<Props> = ({ body }) => {
  return (
    <div className={styles.markdown}>
      <ReactMarkdown linkTarget='_blank' children={body} />
    </div>
  );
};

export default MarkdownRenderer;
