import React from 'react';
import Script from 'next/script';
import cx from 'classnames';

// components
import { usePaywallProvider, ModalPane } from 'src/components/Surfaces';
import { Button } from 'src/components/Inputs';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';

// helpers
import {
  FormTypes,
  PaywallRenderOptions,
  PAYWALL_SELECTOR,
} from 'src/lib/constants';

// styles
import styles from './paywall.module.scss';

type ButtonT = {
  buttonText?: string;
};

interface Props {
  button?: ButtonT;
  children: React.ReactNode;
  fields: Record<string, unknown>;
  trackingId: string;
  hasGradient?: boolean;
}

const Paywall: React.FC<Props> = ({
  button,
  children,
  trackingId,
  fields,
  hasGradient = true,
}) => {
  const {
    setIsModalOpen,
    isModalOpen,
    handleUnlockContent,
    isContentUnlocked,
    renderOption,
  } = usePaywallProvider();
  return (
    <>
      {!isContentUnlocked && (
        <div
          className={cx(styles.paywall, {
            [styles.noBgGradient]: !hasGradient,
          })}
        >
          {renderOption === PaywallRenderOptions.MODAL && (
            <ModalPane
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              trackingId={trackingId}
            >
              <div className={styles.formContainer}>
                <CmsComponentSwitch
                  components={[fields?.components]}
                  handleFormSubmit={handleUnlockContent}
                  formType={FormTypes.CMS}
                  trackingId={trackingId?.toUpperCase()}
                />
              </div>
            </ModalPane>
          )}

          {button && (
            <div className={styles.btnContainer}>
              <Button
                onClick={() => {
                  return setIsModalOpen(true);
                }}
                className={styles.btn}
              >
                {button.buttonText}
              </Button>
            </div>
          )}
        </div>
      )}
      {/*
         We have to make the content visible to search engines so we don't get penalized for cloaking.
         This is why we only apply a class to hide the content instead of not rendering it
       */}
      <div
        data-render-option={fields?.renderOption}
        className={cx(PAYWALL_SELECTOR.replace('.', ''), {
          [styles.hiddenContent]: !isContentUnlocked,
        })}
      >
        {children}
      </div>
      <Script
        id={`${trackingId}_SCHEMA` || 'customer-story-paywall-schema'}
        type='application/ld+json'
        strategy='beforeInteractive'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            isAccessibleForFree: 'False',
            hasPart: {
              '@type': 'WebPageElement',
              isAccessibleForFree: 'False',
              cssSelector: PAYWALL_SELECTOR,
            },
          }),
        }}
      />
    </>
  );
};

export default Paywall;
