import React from 'react';
import PropTypes from 'prop-types';
import ProblemLabelIcon from 'src/components/SVGs/Icons/ProblemLabelIcon';
import SolutonLabelIcon from 'src/components/SVGs/Icons/SolutionLabelIcon';

const srcMap = {
  problem: <ProblemLabelIcon data-testid='problem-icon' />,
  solution: <SolutonLabelIcon data-testid='solution-icon' />,
};

const propTypes = {
  type: PropTypes.string.isRequired,
};

const Icon = ({ type }) => {
  return (
    <span
      data-testid='intro-section-label-icon'
      className='intro-section__label-icon'
      aria-hidden='true'
      alt={type}
    >
      {srcMap[type]}
    </span>
  );
};

Icon.propTypes = propTypes;
export default Icon;
