import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import useMeasure from 'react-use-measure';
import { RenderPropSticky } from 'react-sticky-el';
import {
  Section,
  Container,
  Row,
  Spacer,
  Box,
  Column,
} from 'src/components-v2/Layout';
import TimelineSwitch from './TimelineSwitch';
import { ITimelineProps, Asset } from './Timeline.types';
import { useDeviceSize } from 'src/hooks';
import TimelineItem from './TimelineItem';

const Timeline: React.FunctionComponent<ITimelineProps> = ({
  heading,
  items,
  sx,
  sys,
}) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  const device = useDeviceSize();
  const isMobile = device.isSmall;
  const initialState = items.map(
    ({ id, isVisible, asset: { src, type } }): Asset => {
      return {
        id,
        src,
        isVisible,
        type,
      };
    },
  );
  const [assets, updateAssets] = React.useState(initialState);
  const [isMeasuredRef, isMeasuredRefBounds] = useMeasure();
  const [timelineRef, timelineRefBounds] = useMeasure();
  const refs = React.useRef([]);
  const [spacerRef, spacerRefBounds] = useMeasure();
  const [headingRef, headingRefBounds] = useMeasure();

  const updateState = (id) => {
    updateAssets((state) => {
      return state.map((asset) => {
        if (asset.id === id) {
          return { ...asset, isVisible: true };
        }
        return { ...asset, isVisible: false };
      });
    });
  };

  const onObserve = (entries) => {
    if (!isMobile) {
      entries.forEach((entry) => {
        const asset = assets.find((asset) => {
          return asset.id === entry.target.id;
        });
        if (entry.isIntersecting && !asset.isVisible) {
          updateState(entry.target.id);
        }
      });
    }
  };

  React.useEffect(() => {
    const nodes = refs.current;
    const observer = new IntersectionObserver(onObserve, {
      rootMargin: `0px 0px 0px 0px`,
      threshold: 1.0,
    });
    nodes.forEach((node) => {
      observer.observe(node);
    });

    if (isMobile) {
      observer.disconnect();
    }

    return () => {
      observer.disconnect();
    };
  });

  return (
    <Section
      sx={{
        '& h2': { mb: { xs: '6.6rem', sm: '5.4rem', md: '.8rem' } },
        ...sx,
      }}
      className='stepper'
      {...sx}
    >
      {React.Children.map(heading, (child: React.ReactElement) => {
        // I'm leaving this here and all of the breakpoints in the event
        // we need to tweak these values some more
        // _clamp() will come in handy here
        // const headingHeight = +headingRefBounds.height;
        return React.cloneElement(child, {
          ...child.props,
          ref: headingRef,
          sx: {
            mb: {
              xl: `-${spacerRefBounds.height / 2}px`,
              lg: `-${spacerRefBounds.height / 2}px`,
              md: `-${spacerRefBounds.height / 2}px`,
              sm: `-${spacerRefBounds.height / 2}px`,
              xs: `-${spacerRefBounds.height / 2}px`,
            },
          },
        });
      })}
      <Container
        {...inspectorProps({
          fieldId: 'internalTitle',
        })}
      >
        <Row>
          <Column
            ref={timelineRef}
            lgOffset={2}
            lg={1}
            smOffset={1}
            sx={{
              borderRight: 'solid 1px',
              borderImage: makeGradient(
                // @ts-ignore
                sx?.backgroundColor || sx?.bgColor || 'black',
              ),
              display: { md: 'block', xs: 'none' },
            }}
          />
          <Column lg={7} mdOffset={1} md={8} xsOffset={1} xs={22}>
            {items.map((item, index) => {
              return (
                <TimelineItem
                  index={index}
                  item={item}
                  items={items}
                  refs={refs}
                  timelineRefBounds={timelineRefBounds}
                />
              );
            })}
          </Column>
          <Column
            lgOffset={1}
            lg={10}
            mdOffset={1}
            md={11}
            xsOffset={1}
            xs={22}
            sx={{
              display: { md: 'block', xs: 'none' },
            }}
          >
            <RenderPropSticky
              boundaryElement='.stepper'
              positionRecheckInterval={100}
              disabled={isMobile}
            >
              {({
                isFixed,
                wrapperStyles,
                wrapperRef,
                holderStyles,
                holderRef,
              }) => {
                return (
                  <div ref={holderRef} style={holderStyles}>
                    <div style={wrapperStyles} ref={wrapperRef}>
                      <Spacer
                        ref={spacerRef}
                        sx={{
                          height: `calc((100vh - ${isMeasuredRefBounds.height}px) / 2)`,
                        }}
                      />
                      <Box
                        ref={isMeasuredRef}
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                        }}
                      >
                        {items.map((item, index) => {
                          return (
                            <TimelineSwitch
                              asset={item}
                              assets={assets}
                              index={index}
                              key={`desktop-asset-${index}`}
                              sx={sx}
                            />
                          );
                        })}
                      </Box>
                    </div>
                  </div>
                );
              }}
            </RenderPropSticky>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default Timeline;

const makeGradient = (color) => {
  switch (color) {
    case 'gray':
    case 'Gray':
      return 'linear-gradient(to bottom, #f6f6f6, #C9C9C9, #f6f6f6) 7 100%';
    case 'white':
    case 'White':
      return 'linear-gradient(to bottom, #ffffff, #C9C9C9, #ffffff) 7 100%';
    default:
      return 'linear-gradient(to bottom, #111111, #383838, #111111) 7 100%';
  }
};
