import React, { FC } from 'react';

// components
import { TextInput, SelectWithController } from 'src/components/Inputs';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

import { FormSectionPxFieldsProps } from './FormSectionPxFields.types';

const FormSectionPxFields: FC<FormSectionPxFieldsProps> = ({
  formData,
  errors,
  control,
  register,
  setValue,
}) => {
  return (
    <fieldset>
      <SelectWithController
        {...formData.categoryInput}
        errorMessage={errors?.[formData.categoryInput.name]?.message}
        required={getRequiredMessage(formData.categoryInput.label)}
        control={control}
        register={register}
        setValue={setValue}
      />
      <TextInput
        {...formData.coreProviderInput}
        errorMessage={errors?.[formData.coreProviderInput.name]?.message}
        required={getRequiredMessage(formData.coreProviderInput.label)}
        register={register}
      />
      <TextInput
        {...formData.bankingProviderInput}
        errorMessage={errors?.[formData.bankingProviderInput.name]?.message}
        required={getRequiredMessage(formData.bankingProviderInput.label)}
        register={register}
      />
    </fieldset>
  );
};

export default FormSectionPxFields;
