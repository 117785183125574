import * as React from 'react';
import { Sys } from 'contentful';
import StandardLeftRightSection from './StandardLeftRightSection';
import { MultiStateCarousel } from '../MultiStateCarousel';
import { RichTextSwitch } from 'src/containers';
import { Section } from 'src/components-v2/Layout';
import { CmsSectionalHeader } from 'src/sections';
import { byVideoType } from 'src/lib/utils';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Image } from 'src/components-v2/Media';
import { isProduction } from 'src/lib/constants';
import AccordionLeftRightSection from './AccordionLeftRightSection';

export interface ILeftRightSectionProps {
  fields: {
    styles: SxProps<Theme>;
    accordionItems?: any; // TODO: maybe type this
    appearance: string;
    asset: {
      height: number;
      width: number;
      sys: Sys;
    };
    backgroundColor: string;
    body: any; // TODO: maybe type this
    header: any; // TODO: maybe type this
    layout: string;
  };
  sys: Sys;
  heading?: React.ReactNode;
}

const LeftRightSection: React.FunctionComponent<ILeftRightSectionProps> = ({
  fields,
  sys,
}) => {
  const appearance = fields?.appearance;
  const sx = {
    backgroundColor: fields?.backgroundColor || 'inherit',
    ...fields?.styles,
  };
  switch (appearance) {
    case 'Standard':
      return (
        <StandardLeftRightSection
          fields={fields}
          heading={
            fields?.header ? <CmsSectionalHeader {...fields.header} /> : null
          }
          sys={sys}
        />
      );
    case 'Accordion':
      return <AccordionLeftRightSection fields={fields} sys={sys} />;
    case 'Hover':
      return (
        <MultiStateCarousel
          fieldId='internalTitle'
          appearance='hover'
          isReversed={fields?.layout === 'Asset left / Text right'}
          items={HoverAdapter({ fields, sys })}
          sx={sx}
          sys={sys}
          heading={
            fields?.header ? <CmsSectionalHeader {...fields.header} /> : null
          }
        >
          {fields?.body && (
            <Section
              component='header'
              sx={{
                pb: { xs: '2.9rem', sm: '2.6rem', md: '4.8rem' },
                '& p:nth-last-of-type(1):empty': {
                  display: 'none',
                },
              }}
              {...sx}
            >
              <RichTextSwitch
                sys={sys}
                content={fields?.body}
                nodes={{
                  h2: {
                    variant: 'h3',
                    component: 'h3',
                    fieldId: 'body',
                  },
                  h3: {
                    fieldId: 'body',
                    variant: 'h3',
                  },
                  p: {
                    fieldId: 'body',
                    variant: 'p',
                  },
                }}
              />
            </Section>
          )}
        </MultiStateCarousel>
      );
    default:
      return null;
  }
};

export default LeftRightSection;

export const LeftRightAssetAdapter = (item) => {
  const asset = item?.fields?.asset;
  switch (asset?.fields?.appearance) {
    case 'Image':
      // TODO: this source set needs to be formulaic since it will not work for other aspect ratios
      // TODO: these asset sizes may no longer be correct either...
      const BASE = '?fm=webp&q=60';
      const XS_IMAGE = BASE + '&w=1214';
      const SM_IMAGE = BASE + '&w=836';
      const MD_IMAGE = BASE + '&w=1072';
      const LG_IMAGE = BASE + `&w=${Math.ceil(asset?.fields?.width * 2)}`;
      const lgImgSrc = 'https:' + asset?.fields?.largeImage?.fields?.file?.url;
      const mdImgSrc = asset?.fields?.mediumImage
        ? 'https:' + asset?.fields?.mediumImage?.fields?.file?.url
        : lgImgSrc;
      const smImgSrc = asset?.fields?.smallImage
        ? 'https:' + asset?.fields?.smallImage?.fields?.file?.url
        : mdImgSrc;
      return {
        alt: asset?.fields?.description || '',
        height: asset?.fields?.height, // TODO: ideally this is required in the asset content type: https://plaidinc.atlassian.net/browse/SITE-5109
        sources: [
          {
            media: '(max-width: 412px)',
            srcSet: smImgSrc + XS_IMAGE,
          },
          {
            media: '(min-width: 413px) and (max-width: 639px)',
            srcSet: smImgSrc + SM_IMAGE,
          },
          {
            media: '(min-width: 640px) and (max-width: 1023px)',
            srcSet: mdImgSrc + MD_IMAGE,
          },
          {
            media: '(min-width: 1024px)',
            srcSet: lgImgSrc + LG_IMAGE,
          },
        ],
        src: 'https:' + asset?.fields?.largeImage?.fields?.file?.url,
        sx: asset?.fields?.styles || {},
        type: 'image' as const,
        width: asset?.fields?.width, // TODO: ideally this is required in the asset content type: https://plaidinc.atlassian.net/browse/SITE-5109
        sys: asset?.sys,
        fieldId: 'internalTitle',
      };
    case 'Video':
      const sortedVideos = asset?.fields?.video?.sort(byVideoType);
      return {
        ariaLabel: asset?.fields?.description || '',
        fieldId: 'internalTitle',
        height: asset?.fields?.height, // TODO: ideally this is required in the asset content type: https://plaidinc.atlassian.net/browse/SITE-5109
        sources: sortedVideos?.map((asset) => {
          return {
            src: asset?.fields?.file?.url,
            type: asset?.fields?.file?.contentType,
          };
        }),
        sx: {
          width: '100%',
          height: 'auto',
          ...(asset?.fields?.styles || {}),
        },
        sys: asset?.sys,
        type: 'video' as const,
        width: asset?.fields?.width, // TODO: ideally this is required in the asset content type: https://plaidinc.atlassian.net/browse/SITE-5109
      };
    case 'Lottie':
      const src = 'https:' + asset?.fields?.lottie?.fields?.file?.url;
      return {
        src: src,
        sx: {
          width: '100%',
          height: 'auto',
          ...(asset?.fields?.styles || {}),
        },
        type: 'lottie',
        sys: asset?.sys,
        fieldId: 'internalTitle',
      };
    default:
      if (!isProduction) {
        return (
          <>
            <Image
              width={600}
              height={400}
              src='https://placehold.co/600x400'
              alt='placeholder'
            />
          </>
        );
      } else {
        return null;
      }
  }
};

const HoverAdapter = ({ fields, sys }) => {
  return fields?.hoverItems?.map((item) => {
    const smallImage = item?.fields?.smallImage
      ? {
          src: 'https://' + item?.fields?.smallImage?.fields?.file?.url,
          alt:
            item?.fields?.smallImage?.fields?.description ||
            item?.fields?.smallImage?.fields?.title,
        }
      : null;
    return {
      title: (
        <RichTextSwitch
          sys={item.sys}
          content={item?.fields?.body}
          nodes={{
            p: {
              variant: 'h5',
              fieldId: 'body',
              component: 'h4',
              sx: {
                fontWeight: '600',
              },
            },
          }}
        />
      ),
      smallImage: smallImage,
      asset: LeftRightAssetAdapter(item),
      sys: item?.sys,
      sx: item?.fields?.styles,
    };
  });
};
