export const metaData = {
  'meta-title': 'Insurance',
  'meta-description': 'The technology layer for financial services"',
  hasCanonicalTag: true,
};
export const pageHeaderData = {
  'flip-horizontal': true,
  'image-based': true,
  'form-header': true,
  header: 'A faster, simpler way to set up ACH payments for insurance',
  'header-size': 'h2',
  'header-image': '/assets/img/accents/description-section/insurance-hero.png',
  body:
    'Plaid enables insurers to instantly verify bank accounts and seamlessly accept premium payments via ACH. Get in touch to learn more.',
  'privacy-policy': true,
  formData: {
    'marketo-key': 1146,
    includeCompany: true,
    includePhone: true,
    actionText: "Let's talk",
  },
};
export const socialProofData = {
  header: 'Customers using this solution',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-current-black600.png',
      alt: 'Current',
    },
    {
      img: '/assets/img/logos/logo-paysafe-black600.png',
      alt: 'Paysafe',
    },
    {
      img: '/assets/img/logos/logo-wise-black600.png',
      alt: 'Wise',
    },
    {
      img: '/assets/img/logos/logo-venmo-black600.png',
      alt: 'Venmo',
    },
  ],
};
export const introSectionData = {
  'problem-statement':
    'Receiving premium payments via ACH or EFT is significantly less expensive than using credit cards, but getting users to manually input their bank account information to verify account ownership is a cumbersome process—resulting in user drop-off.',
  'solution-statement':
    'Plaid makes it faster and easier to set up premium payments via ACH through a flow designed with the user experience and security in mind. Users enter their online credentials and Plaid instantly links their bank account, enabling payments to be set up in moments. For insurers, this improved payment workflow can increase ACH adoption and reduce credit card interchange fees.',
};
export const featuresData = {
  'header-section': true,
  'header-subtext': 'PRODUCTS AND BENEFITS',
  'header-text': null,
  'feature-count': 'three',
  'feature-content': [
    {
      'feature-img': '/assets/img/products/auth-112@2x.png',
      'feature-title': 'Get account and routing numbers for payments',
      'feature-body':
        '<a href="/products/auth/">Auth</a> instantly returns users\' account and routing numbers, which can be used for more seamless ACH or EFT premium or claim payments',
    },
    {
      'feature-img': '/assets/img/products/balance-112.png',
      'feature-title': 'Reduce NSF fees and overdrafts',
      'feature-body':
        '<a href="/products/balance/">Balance</a> instantly checks whether users have sufficient funds in their accounts to cover a premium payment',
    },
    {
      'feature-img': '/assets/img/products/identity-112.png',
      'feature-title': 'Lower risk and reduce fraud',
      'feature-body':
        '<a href="/products/identity/">Identity</a> confirms users\' identities with what\'s on file at the bank—in other words, their name, phone number, address, and email',
    },
  ],
};
