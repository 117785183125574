import React from 'react';
import cx from 'classnames';

// components
import { Link } from 'src/components/Inputs';
import Image from 'src/components/Image';

// types
import { Column as SubmenuColumnProps } from './types';

// contexts
import { usePageLocale } from 'src/contexts';

const SubmenuColumn: React.FC<SubmenuColumnProps> = ({
  gridClasses = '',
  heading = '',
  items = [],
}) => {
  const pageLocale = usePageLocale();
  return (
    <div className={cx('cell', gridClasses)}>
      {heading && <h6>{heading}</h6>}
      <ul>
        {items.map((item, i) => (
          <li key={`submenu-item-${i}`}>
            <Link
              href={item.url}
              locale={item.locale || pageLocale}
              trackingId={item?.trackingId}
            >
              <a className='Navbar__mega-menu-item'>
                {item.icon && (
                  <Image
                    className='Navbar__mega-menu-item-icon'
                    src={item.icon}
                    aria-hidden='true'
                    alt=''
                    isDesktopOnly={true}
                    isLazy={false}
                  />
                )}
                <span className='Navbar__mega-menu-item-content'>
                  <span
                    className={cx('Navbar__mega-menu-item-title', item.class)}
                  >
                    {item.name}
                  </span>
                  {item.description && (
                    <span className='Navbar__mega-menu-item-description'>
                      {item.description}
                    </span>
                  )}
                </span>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubmenuColumn;
