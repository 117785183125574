import { FC } from 'react';

import { useDeviceSize } from 'src/hooks';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import { Box } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';

import { SubMenuData } from '../types';
import { Tabs } from '../Tabs';
import { Columns } from '../Columns';
import { List } from '../List';

type SubMenuProps = {
  data: SubMenuData;
  onClick: (id: string) => () => void;
};

export const SubMenu: FC<SubMenuProps> = ({ data, onClick }) => {
  const { type, children } = data;

  const device = useDeviceSize();
  const { isSmall } = device;

  switch (type) {
    case 'list': {
      return (
        <Box sx={{ p: '20px 0' }}>
          <List items={children} />
        </Box>
      );
    }

    case 'columns': {
      return <Columns items={children} />;
    }

    case 'tabs': {
      return isSmall ? (
        <S.Ul
          sx={{
            listStyle: 'none',
            p: '20px 0 0 0',
            m: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          {children.map(({ id, title }, i) => {
            return (
              <S.Li key={`main-menu-tabs-item-${id}`}>
                <Box
                  component='button'
                  role='button'
                  tabIndex={0}
                  sx={{
                    textAlign: 'left',
                    position: 'relative',
                    display: 'block',
                    width: '100%',
                    p: { xs: '15px 12px', sm: '15px 28px' },
                  }}
                  onClick={onClick(id)}
                >
                  <Typography
                    theme='main-menu-new'
                    component='span'
                    variant='p'
                    sx={{
                      mb: 0,
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </S.Li>
            );
          })}
        </S.Ul>
      ) : (
        <Tabs items={children} sx={{ m: '0 -16px' }} />
      );
    }

    default: {
      return null;
    }
  }
};
