import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'src/components/headers/MvpPageHeader/PageHeader--cms.module.scss';

const HeadingTextPropTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const HeadingText = ({ children, className }) => (
  <h1
    className={cx(styles.heading, className)}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

HeadingText.propTypes = HeadingTextPropTypes;
export default HeadingText;
