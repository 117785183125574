import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ConsentManager } from 'src/containers/ConsentManager';
import PropTypes from 'prop-types';

// helpers
import { AnalyticsProvider } from 'src/contexts';
import { MetaTags } from 'src/components/MetaTags';

import PlaidLogo from 'src/components-v2/SVGs/PlaidLogo';
import { Link } from 'src/components/Inputs';
import { PrefsMenu, TutorialFeedback } from 'src/components/docs/Tutorial';
import { TutorialKey } from 'src/components/docs/Tutorial/TutorialUtils';
import OneTrustButton from 'src/components-v3/Footer/OneTrustButton';
import styles from './TutorialTemplate.module.scss';

// dynamically import the scripts component which invokes all of our js dependencies
const TutorialScripts = dynamic(
  () => {
    return import('./TutorialScripts');
  },
  {
    ssr: false,
  },
);

const TutorialTemplate = ({
  children,
  tutorialKey = TutorialKey.Basics,
  title = 'Plaid Tutorial',
  projectSetter = null,
  ...props
}) => {
  const { route } = useRouter();

  return (
    <AnalyticsProvider>
      <MetaTags {...props} />
      <div className={styles.topBar}>
        <span className={styles.logoWrapper}>
          <Link href='/'>
            <a className='Navbar__logo'>
              <PlaidLogo />
            </a>
          </Link>
        </span>
        <span className={styles.titleWrapper}>
          <h3 className={styles.title}>{title}</h3>
        </span>
        <span className={styles.menuWrapper}>
          <TutorialFeedback tutorialKey={tutorialKey} />
          <PrefsMenu tutorialKey={tutorialKey} projectSetter={projectSetter} />
        </span>
      </div>
      <div id='main-content' className={styles.mainContent}>
        {children}
      </div>
      <footer id='main-footer' className='m-2'>
        <OneTrustButton
          sx={{
            fontSize: '1.5rem',
            fontWeight: 600,
            color: '#000',
            '&:hover': {
              color: '#0072cf',
            },
          }}
        />
      </footer>
      <ConsentManager />
      <TutorialScripts route={route} />
    </AnalyticsProvider>
  );
};
export default TutorialTemplate;

TutorialTemplate.propTypes = {
  children: PropTypes.any,
  tutorialKey: PropTypes.string,
  title: PropTypes.string,
  projectSetter: PropTypes.func,
};
