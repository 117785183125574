/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import cx from 'classnames';

const IntroSectionIcons = Object.freeze({
  PROBLEM_ICON: '❓ Problem',
  SOLUTION_ICON: '✅ Solution',
});

const getIcon = (icon) => {
  switch (icon) {
    case IntroSectionIcons.PROBLEM_ICON:
      return 'problem';
    case IntroSectionIcons.SOLUTION_ICON:
      return 'solution';
    default:
      return null;
  }
};

const getHeadingSize = (size) => {
  if (size === 'Large') {
    return 'h3';
  } else {
    return '';
  }
};

/**
 * Maps the contentful response for intro section content type to the view
 *
 * @param {Object} args
 * @param {Object[]} args.header
 * @param {Object[]} args.items
 * @returns {Object}
 */
export const introSectionAdapter = ({
  backgroundStyling = null,
  classes = null,
  header = [],
  items = [],
}) => {
  const backgroundColor = _get(
    backgroundStyling,
    'fields.backgroundColor.fields.color',
  );

  return {
    backgroundImageBottomLeft: _get(
      backgroundStyling,
      'fields.backgroundImageBottomLeft.fields.file.url',
    ),
    backgroundImageTopRight: _get(
      backgroundStyling,
      'fields.backgroundImageTopRight.fields.file.url',
    ),
    classes: cx(
      {
        [`background background--color-${backgroundColor}`]: backgroundColor,
      },
      classes,
    ),
    items: items.map((item) => {
      const icon = getIcon(item.fields?.icon);

      return {
        description: item.fields?.description,
        descriptionSize: getHeadingSize(item?.fields?.descriptionSize),
        icon,
        label: item.fields?.label,
        paddingBottom: item.fields?.paddingBottom,
        paddingTop: item.fields?.paddingTop,
      };
    }),
    header: {
      hasHeader: header.length > 0,
      items: header.map((headerItem) => {
        return {
          label: headerItem.fields?.label,
          content: headerItem.fields?.content,
        };
      }),
    },
  };
};
