import React, { FC } from 'react';
import { string } from 'yup';

// components
import { MarketoForm, GdprFields } from 'src/components/Forms';
import { TextInput, Button } from 'src/components/Inputs';
import { Grid, Column } from 'src/components/Layout';
// data
import { getFormData } from 'src/components/Forms/PartnerContactForm';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

import styles from './PartnerContactForm.module.scss';

const PartnerContactForm: FC = () => {
  const locale = usePageLocale();
  const contactFormData = getFormData({ locale });
  return (
    <MarketoForm
      marketoKey={contactFormData.marketoKey}
      trackingId='PARTNER_CONTACT_FORM'
      locale={locale}
      render={({ control, register, errors, setValue }) => (
        <>
          <Grid useThreadsGrid containerClassName={styles.namesContainer}>
            <Column small={12} medium={6}>
              <TextInput
                label={contactFormData.firstName.label}
                id={contactFormData.firstName.name}
                name={contactFormData.firstName.name}
                describedby={contactFormData.firstName.describedby}
                errorMessage={errors?.[contactFormData.firstName.name]?.message}
                register={register}
                required={getRequiredMessage(contactFormData.firstName.label)}
                autoComplete={contactFormData.firstName.autoComplete}
              />
            </Column>
            <Column small={12} medium={6}>
              <TextInput
                label={contactFormData.lastName.label}
                id={contactFormData.lastName.name}
                name={contactFormData.lastName.name}
                describedby={contactFormData.lastName.describedby}
                errorMessage={errors?.[contactFormData.lastName.name]?.message}
                register={register}
                required={getRequiredMessage(contactFormData.lastName.label)}
                autoComplete={contactFormData.lastName.autoComplete}
              />
            </Column>
          </Grid>

          <TextInput
            label={contactFormData.email.label}
            id={contactFormData.email.name}
            name={contactFormData.email.name}
            describedby={contactFormData.email.describedby}
            errorMessage={errors?.Email?.message}
            register={register}
            required={getRequiredMessage(contactFormData.email.label)}
            pattern={{
              value: string().email().tests[0].OPTIONS.params.regex,
              message: contactFormData.email.invalidMessage,
            }}
            autoComplete={contactFormData.email.autoComplete}
          />
          <TextInput
            label={contactFormData.company.label}
            id={contactFormData.company.name}
            name={contactFormData.company.name}
            describedby={contactFormData.company.describedby}
            errorMessage={errors?.[contactFormData.company.name]?.message}
            register={register}
            required={getRequiredMessage(contactFormData.company.label)}
            autoComplete={contactFormData.company.autoComplete}
          />
          <TextInput
            label={contactFormData.phone.label}
            id={contactFormData.phone.name}
            name={contactFormData.phone.name}
            describedby={contactFormData.phone.describedby}
            errorMessage={errors?.[contactFormData.phone.name]?.message}
            register={register}
            autoComplete={contactFormData.phone.autoComplete}
          />
          <GdprFields
            control={control}
            errors={errors}
            register={register}
            setValue={setValue}
          />
          <Button
            className='mb1'
            type='submit'
            value={contactFormData.actionText}
            fullWidth
          >
            {contactFormData.actionText}
          </Button>
          <p
            className='contact-form__disclaimer'
            dangerouslySetInnerHTML={{
              __html: contactFormData.disclaimer,
            }}
          />
        </>
      )}
    />
  );
};

PartnerContactForm.displayName = 'PartnerContactForm';
export default PartnerContactForm;
