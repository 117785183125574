import React, { FC } from 'react';
import { Sys } from 'contentful';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Box, Column, Stack } from 'src/components-v2/Layout';
import { Wrapper } from 'src/containers';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { targetMap } from 'src/lib/constants';
import { Btn as Button } from 'src/components-v2/Inputs';

interface ICta {
  href: string;
  trackingId?: string;
  target?: '_blank' | '_parent' | '_self' | '_top';
  sys?: Sys;
  text: string;
  sx?: {};
}
interface IQuote {
  children: React.ReactNode;
  cta?: ICta;
  sx?: SxProps<Theme>;
  sys?: Sys;
  wrapperClass?: string;
}

const Quote: FC<IQuote> = ({ children, cta, sx, sys, wrapperClass }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Wrapper
      className={wrapperClass}
      component='section'
      sx={{
        ...sx,
      }}
      {...inspectorProps({ fieldId: 'internalTitle' })}
    >
      <Column xs={24} lg={20} lgOffset={2}>
        <Stack
          sx={{
            pb: {
              xs: cta?.text ? '6rem' : '0',
              sm: '0',
            },
          }}
        >
          <Box
            sx={{
              p: '0.9rem 2rem 0rem 1.9rem',
              position: 'relative',
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
              '& blockquote': {
                mb: { xs: '4.2rem', sm: '4rem', md: '6.4rem' },
                p: '0',
                '&:before': {
                  content: '"\\201C"',
                  position: 'absolute',
                  left: { xs: '0.8rem', md: '0.1rem' },
                },
                '&:after': {
                  content: '"\\201D"',
                },
              },
              '& cite:last-of-type': {
                mb: { xs: '2rem', md: '2.4rem' },
              },
              '& .lazyload-wrapper img': {
                width: { xs: '86px', sm: '114px', lg: '132px' },
                position: 'relative',
              },
              '& a': {
                position: 'absolute',
                left: { xs: '2rem', sm: 'initial' },
                bottom: {
                  xs: '-6.0rem',
                  sm: '-0.5rem',
                  md: '-0.3rem',
                  lg: '0',
                },
                right: { sm: '0' },
                ...cta?.sx,
              },
            }}
          >
            {children}
            {cta?.text && (
              <Button
                variant='tertiary'
                href={cta?.href || ''}
                trackingId={cta?.trackingId || ''}
                target={targetMap[cta?.target] || '_self'}
                sys={cta?.sys}
              >
                {cta.text}
              </Button>
            )}
          </Box>
        </Stack>
      </Column>
    </Wrapper>
  );
};

export default Quote;
