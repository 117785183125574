import React from 'react';

const SidebarJobCandidatesIcon = () => {
  return (
    <svg height={16} width={18} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 3.25h-3.1L11.77.81a1.24 1.24 0 00-1-.56h-3.5a1.24 1.24 0 00-1 .56L4.6 3.25H1.5C.81 3.25.25 3.81.25 4.5v10c0 .69.56 1.25 1.25 1.25h15c.69 0 1.25-.56 1.25-1.25v-10c0-.69-.56-1.25-1.25-1.25zm-9.1-1.5h3.2l1 1.5H6.4zm-3.8 3h12.65v2.78a16.28 16.28 0 01-6.5 1.7V8h-1.5v1.22a16.45 16.45 0 01-6.5-1.68V4.75zm-1.85 9.5V9.19a17.7 17.7 0 006.5 1.53V12h1.5v-1.27a17.71 17.71 0 006.5-1.53v5.05z'
        fill='#111'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default SidebarJobCandidatesIcon;
