import React, { FC } from 'react';
import { animated, useSpring, easings } from '@react-spring/web';
import { colors } from 'src/components-v2/theme';
import { IFeatureListItemProps } from './FeatureListItem.types';
import { Box as VBox } from 'src/components-v2/Layout';
const Box = animated(VBox);

const makeAriaAttributes = (aria) => {
  if (aria) {
    const attributes = Object.keys(aria).reduce((obj, attribute) => {
      obj['aria-' + attribute] = aria[attribute];
      return obj;
    }, {});
    return attributes;
  }
  return {};
};

const FeatureListItem: FC<IFeatureListItemProps> = ({
  aria,
  children,
  handleClick,
  index,
  interval = 10000,
  isActive,
  isLast,
  setNextItem,
  sx = {},
}) => {
  const [timer, animation] = useSpring(() => {
    return {
      from: {
        transform: 'translateX(100%)',
      },
      to: {
        transform: isActive ? 'translateX(0%)' : 'translateX(100%)',
      },
      config: {
        duration: interval,
        easing: easings.easeInOutSine,
      },
      reset: true, // resets the spring so it plays from the start again when true
      onRest: () => {
        if (isActive) {
          setNextItem();
        }
      },
    };
  }, [isActive]);

  return (
    <Box
      component='li'
      sx={{
        padding: '2.4rem',
        position: 'relative',
        width: '100%',
        bgcolor: isActive ? 'white' : 'black200',
        borderBottom: isLast ? 'none' : `1px ${colors.black500} solid`,
        cursor: 'pointer',
        ...sx,
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        handleClick(index);
      }}
      tabIndex={0}
      aria-current={isActive}
      {...makeAriaAttributes(aria)}
      onMouseEnter={() => {
        animation.pause();
      }}
      onMouseLeave={() => {
        animation.resume();
      }}
      onFocus={() => {
        handleClick(index);
        animation.pause();
      }}
      onBlur={() => {
        animation.resume();
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: 0,
          height: '100%',
          width: '100%',
          bgcolor: 'black200',
        }}
        style={timer}
      />
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FeatureListItem;
