import React from 'react';
import { ContainerProps } from './Container.types';
import styled from 'threads5/styles/styled';

const StyledDiv = styled('div')(({ theme }) => {
  return {
    margin: '0 auto',
    maxWidth: '1730px',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('xs')]: {
      // TODO: this needs match Figma
      paddingLeft: '13px',
      paddingRight: '13px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  };
});

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className = '', sx, ...props }, ref) => {
    return (
      <StyledDiv ref={ref} className={className} {...props} sx={sx}>
        {children}
      </StyledDiv>
    );
  },
);

Container.displayName = 'Container';

export default Container;
