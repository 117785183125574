import React from 'react';

// components
import { Alert } from 'src/components/Feedback';

// styles
import styles from './EmbeddedForeignContentBanner.module.scss';

// helpers
import { useUserLocale } from 'src/hooks';

interface Props {
  ctaText: string;
  href: string;
  children: React.ReactNode;
  contentLocale: string;
}

const EmbeddedForeignContentBanner: React.FC<Props> = ({
  ctaText,
  href,
  children,
  contentLocale,
}) => {
  const { locale: userLocale } = useUserLocale();

  if (userLocale?.localeCode !== contentLocale) {
    return (
      <Alert
        title={false}
        href={href}
        ctaText={ctaText ? ctaText : `View ${userLocale?.localeCode} content`}
        className={styles.banner}
      >
        {children
          ? children
          : `You're currently viewing ${contentLocale} content.`}
      </Alert>
    );
  }
  return null;
};

export default EmbeddedForeignContentBanner;
