/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';

/**
 * Maps the Contentful response for the Spacer
 *
 * @param {Object} fields
 * @returns {Object}
 */
export const spacerAdapter = ({ fields, sys }) => {
  return {
    backgroundColor: _get(fields, 'backgroundColor.fields.color'),
    backgroundImage: _get(fields, 'backgroundImage', undefined),
    height: _get(fields, 'height', 0),
    sx: {
      ...(fields?.styles || {}),
    },
    sys,
  };
};
