import React from 'react';
import PropTypes from 'prop-types';
import { MARKS } from '@contentful/rich-text-types';

const propTypes = {
  uClassName: PropTypes.string,
};

const Underline = ({ uClassName = '' }) => {
  return {
    [MARKS.UNDERLINE]: (text) => {
      // https://www.npmjs.com/package/@contentful/rich-text-react-renderer#note-on-adding-a-key-prop-in-custom-renderers
      return (
        <u key={`${text}-key`} className={uClassName}>
          {text}
        </u>
      );
    },
  };
};

Underline.propTypes = propTypes;
export default Underline;
