export const metaData = {
  metaTitle: 'Balance API - verify real-time account balances',
  metaDescription:
    "Plaid's Balance API allows companies to verify real-time account balances, using secure Open Banking integrations.",
};
export const productHeaderData = {
  'hero-one-poster-url':
    '/assets/img/products/standalone/balance/hero-balance-1-poster-us-en.png',
  'hero-one-poster-fallback-url':
    '/assets/img/products/standalone/balance/hero-balance-1-fallback-us-en.png',
  'hero-one-video-url':
    '/assets/img/products/standalone/balance/hero-balance-1-us-en.webm',
  'hero-one-video-fallback-url':
    '/assets/img/products/standalone/balance/hero-balance-1-us-en.mp4',
  'hero-one-video-width': null,
  'hero-one-video-height': null,
  'hero-two-poster-url':
    '/assets/img/products/standalone/balance/hero-balance-2-poster-us-en.png',
  'hero-two-poster-fallback-url':
    '/assets/img/products/standalone/balance/hero-balance-2-fallback-us-en.png',
  'hero-two-video-url':
    '/assets/img/products/standalone/balance/hero-balance-2-us-en.webm',
  'hero-two-video-fallback-url':
    '/assets/img/products/standalone/balance/hero-balance-2-us-en.mp4',
  'hero-two-video-width': null,
  'hero-two-video-height': null,
  label: 'Balance',
  description: 'Verify real-time account balances',
  'top-cta-text': 'Get started',
  'top-cta-url': 'https://dashboard.plaid.com/signup',
  'bottom-cta-text': 'View API docs',
  'bottom-cta-url': '/docs/balance',
};
export const featuresData = {
  'header-section': true,
  'header-subtext': 'product features',
  'items-in-columns': true,
  'feature-count': 'two',
  'feature-content': [
    {
      'feature-img':
        '/assets/img/products/standalone/transactions/feature-transactions-2.png',
      'feature-title': 'Real-time data',
      'feature-body': 'Verify real-time account balances',
    },
    {
      'feature-img':
        '/assets/img/products/standalone/payment-initiation/features-payment-initiation_uk_3.png',
      'feature-title': 'Open Banking/PSD2 integrations',
      'feature-body':
        'Plaid’s integrations are Open Banking and PSD2 compliant, delivering secure and reliable access to the UK and European financial ecosystems.',
    },
  ],
};
export const interactiveData = {
  'title-text': 'Make a Balance request',
  'description-text': 'Use the Balance endpoint to submit a POST request',
  'code-title': 'Retrieve Balance request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/balance/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String\n}\'\n',
  'post-run-title-text': 'Real-time Balance data',
  'post-run-description-text':
    'The <strong>/accounts/balance/get</strong> endpoint returns the real-time balance for linked accounts',
  'post-run-code-title': 'Retrieve Balance response',
  'post-run-code-lang': 'json',
  'post-run-code-body':
    '\nhttp code 200\n{\n  "accounts": [{\n     "account_id": "QKKzevvp33HxPWpoqn6rI13BxW4awNSjnw4xv",\n     "balances": {\n       "available": 100,\n       "current": 110,\n       "limit": null,\n       "iso_currency_code": "GBP",\n       "unofficial_currency_code": null\n     },\n     "mask": "0000",\n     "name": "Plaid Current Account",\n     "official_name": "Plaid Gold Current Account",\n     "subtype": "checking",\n     "type": "depository"\n  }],\n  "item": {object},\n  "request_id": "m8MDnv9okwxFNBV"\n}\n',
  properties: [
    {
      title: 'available',
      description:
        'The amount of funds available to be withdrawn from an account',
      'code-line-start': 6,
      'code-highlight-length': 1,
    },
    {
      title: 'current',
      description: 'The total amount of funds in the account',
      'code-line-start': 7,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/balance',
  'bottom-cta-text': 'View API docs',
};
export const footerExtensionData = {
  'primary-cta-text': 'Get started',
  'primary-cta-url': '//dashboard.plaid.com/signup',
  'secondary-cta-text': 'View API docs',
  'secondary-cta-url': '/docs/balance',
  'bg-color': 'bg-white',
};
