import React from 'react';
import { Stack, Box } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';

// TODO: rename this
// TODO: make it more composable since it will not work with rich text yet
// TODO: should this have card baked in?

interface IFeatureStack {
  icon?: {
    component: React.ReactNode;
    color: string;
  };
  // TODO: could this better? does it need to be better?
  image?: {
    src: string;
    alt: string;
    width: string | number;
    height: string | number;
  };
  title: string;
  description: string;
  backgroundImage?: string;
  children?: React.ReactNode;
}

const FeatureStack: React.FC<IFeatureStack> = ({
  icon,
  title,
  description,
  backgroundImage,
  children,
}) => {
  const backgroundImageStyles = backgroundImage && {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  };
  return (
    <Stack
      sx={{
        height: '100%',
        ...backgroundImageStyles,
        '& h3': {
          mb: '0.8rem',
        },
        '& p': {
          mb: '1.2rem',
        },
      }}
    >
      <Box
        sx={{
          px: { xs: '3.2rem', lg: '4.4rem' },
          py: { xs: '3.2rem', lg: '4rem' },
          mb: 'auto',
        }}
      >
        {icon?.component && (
          <Box
            sx={{
              mb: '1.6rem',
              color: icon?.color || 'none',
            }}
          >
            {icon.component}
          </Box>
        )}
        <Typography variant='h5' component='h3'>
          <b>{title}</b>
        </Typography>
        <Typography variant='p2' sx={{ color: 'black900' }}>
          {description}
        </Typography>
      </Box>
      {children != null && children}
    </Stack>
  );
};

export default FeatureStack;
