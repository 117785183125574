import React from 'react';
import cx from 'classnames';

import AccountTableItem, { AccountTableItemProps } from './AccountTableItem';

interface AccountTableColumn {
  rows: AccountTableItemProps[];
  header?: string;
}

export interface AccountTableListProps {
  accountTableSmall?: boolean;
  columns: AccountTableColumn[];
  header?: string;
}

export const AccountTableList: React.FC<AccountTableListProps> = ({
  accountTableSmall = false,
  columns,
  header = '',
}) => (
  <div
    className={`
      account-table-list
      account-table-list-services
      stack-table${accountTableSmall ? '-small' : ''}
    `}
  >
    {header && (
      <h6 className='account-table-list-header'>{header.toUpperCase()}</h6>
    )}
    <div className='grid-x'>
      {columns.map((column, i) => (
        <div
          key={`column-${i}`}
          className={cx('cell', `medium-${12 / columns.length}`)}
        >
          {column.rows.map((item, i) => (
            <AccountTableItem key={`item-${i}`} {...item} />
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default AccountTableList;
