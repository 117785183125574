/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { ButtonTypes } from 'src/lib/constants';

/**s
 * Maps the Contentful response for the Partner Page Footer to the view
 *
 * @param {Object} args.ctaButtons
 * @param {Object} args.backgroundStyling
 * @param {string} args.text
 * @param {Object} args.tertiaryCta
 * @returns {Object}
 */

export const partnerPageFooterAdapter = ({
  ctaButtons = [],
  backgroundStyling,
  text,
  tertiaryCta,
}) => {
  const backgroundColor = _get(
    backgroundStyling,
    'fields.backgroundColor.fields.color',
  );
  const primaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.PRIMARY_CTA,
  );
  const secondaryCta = ctaButtons.find(
    (btn) => btn?.fields?.type === ButtonTypes.SECONDARY_CTA,
  );

  return {
    cta: {
      hasPrimaryCta: !!primaryCta,
      primaryCtaText: _get(primaryCta, 'fields.text'),
      primaryCtaLink: _get(primaryCta, 'fields.url'),
      hasSecondaryCta: !!secondaryCta,
      secondaryCtaText: _get(secondaryCta, 'fields.text'),
      secondaryCtaLink: _get(secondaryCta, 'fields.url'),
      hasTertiaryCta: !!tertiaryCta,
      tertiaryCta,
    },
    className: backgroundColor
      ? `background background--color-${backgroundColor}`
      : null,
    backgroundImageBottomLeft: _get(
      backgroundStyling,
      'fields.backgroundImageBottomLeft.fields.file.url',
    ),
    backgroundImageTopRight: _get(
      backgroundStyling,
      'fields.backgroundImageTopRight.fields.file.url',
    ),
    footerTitle: text,
  };
};
