/* eslint-disable import/prefer-default-export */
import _get from 'lodash/get';
import { ButtonTypes, ButtonVersion } from 'src/lib/constants';

/**
 * Maps the contentful response for Customer Story Page Header content type to the view
 *
 * @param {Object} args
 * @param {string} args.label
 * @param {object} args.labelColor
 * @param {string} args.heading
 * @param {Object[]} args.ctaButtons
 * @param {Object} args.backgroundColor
 * @param {Object} args.backgroundImage
 * @param {Object} args.iconImage
 * @param {Object} args.productImage
 * @param {boolean} args.mobilePhoneProductImage
 * @returns {Object}
 */

export const customerStoryPageHeaderAdapter = ({
  label,
  labelColor,
  heading,
  ctaButtons = [],
  backgroundColor,
  backgroundImage,
  iconImage,
  productImage,
  mobilePhoneProductImage = false,
}) => {
  const primaryCta = ctaButtons.find((btn) => {
    return btn?.fields?.type === ButtonTypes.PRIMARY_CTA;
  });
  const secondaryCta = ctaButtons.find((btn) => {
    return btn?.fields?.type === ButtonTypes.SECONDARY_CTA;
  });
  const targetButton = ctaButtons.find((btn) => {
    return btn?.sys?.contentType?.sys?.id === ButtonVersion.TARGET;
  });

  return {
    className:
      (backgroundColor &&
        'background background--color-' +
          _get(backgroundColor, 'fields.color')) +
      ' background--size-cover background--position-bottom-right',
    'background-image':
      backgroundImage && _get(backgroundImage, 'fields.file.url'),
    'content-width-classes': 'page-header-breakpoint-6 large-6',
    label: true,
    'label-classes':
      labelColor && 'page-header-label--' + _get(labelColor, 'fields.color'),
    'label-text': label,
    header: heading,
    'header-class': 'h2',
    'primary-cta': !!primaryCta,
    'primary-cta-text': _get(primaryCta, 'fields.text'),
    'primary-cta-link': _get(primaryCta, 'fields.url'),
    'secondary-cta': !!secondaryCta,
    'secondary-cta-text': _get(secondaryCta, 'fields.text'),
    'secondary-cta-link': _get(secondaryCta, 'fields.url'),
    ...(!!targetButton && {
      targetButton: {
        text: _get(targetButton, 'fields.text', ''),
        target: _get(targetButton, 'fields.target', ''),
        href: _get(targetButton, 'fields.url', ''),
      },
    }),
    image: true,
    'image-width': mobilePhoneProductImage ? 352 : 669,
    'image-height': mobilePhoneProductImage ? 440 : 389,
    'image-url': _get(productImage, 'fields.file.url'),
    imageAltText:
      _get(productImage, 'fields.description') ||
      _get(productImage, 'fields.title'),
    'image-phone': mobilePhoneProductImage,
    'image-classes': 'page-header-bg__img-overflow-large',
    'popup-logo-url': _get(iconImage, 'fields.file.url'),
    iconAltText: '',
    slideUpContent: true,
    slideUpImage: true,
  };
};
