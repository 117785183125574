import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Headings } from './Headings/';
import { EmbeddedEntrySwitch } from './EmbeddedEntrySwitch';
import { Paragraph } from './Paragraph/';
import { EmbeddedAssetSwitch } from './EmbeddedAssetSwitch';

// import { Bold } from './Bold/';
// import { Italic } from './Italic/';
// import { Underline } from './Underline/';
import { UnorderedList } from './UnorderedList/';
// import { OrderedList } from './OrderedList/';
import { ListItem } from './ListItem/';
import { HorizontalRule } from './HorizontalRule/';

// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
const RichTextRenderer = ({ content, nodes, theme = null, sys }) => {
  return (
    <>
      {documentToReactComponents(content, {
        renderMark: {
          // ...Bold(classes),
          // ...Italic(classes),
          // ...Underline(classes),
        },
        renderNode: {
          // ...Hyperlink(nodes, sys),
          ...Paragraph(nodes, sys),
          // ...OrderedList(classes),
          ...UnorderedList(nodes),
          ...ListItem(nodes),
          ...Headings(nodes, sys),
          ...EmbeddedEntrySwitch(nodes, sys),
          ...EmbeddedAssetSwitch(nodes),
          ...HorizontalRule(nodes),
        },
        renderText: (text) => {
          return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
          }, []);
        },
      })}
    </>
  );
};

export default RichTextRenderer;
