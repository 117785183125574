import React from 'react';

const Checkmark = () => {
  return (
    <svg height={12} width={12} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 0L5 6.999 3 4H0l5 8 7-12z'
        fill='#63daff'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Checkmark;
