import React from 'react';

const ArrowRightSingleSmallGary = () => {
  return (
    <svg viewBox='0 0 8 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5 8l-5 8h3l5-8-5-8H0z' fillRule='evenodd' />
    </svg>
  );
};

export default ArrowRightSingleSmallGary;
