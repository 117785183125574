import React, { Component } from 'react';

import animate from 'src/lib/animate';
import {
  CODE_LINE_HEIGHT,
  LINE_HEIGHT_OFFSET,
} from './with-animated-response-constants';
import { DeviceProvider } from 'src/contexts';

const withAnimatedResponse = (WrappedComponent) =>
  class Enliven extends Component {
    // A simple abstraction from the original @animatedResponse function
    // makes the code a little easier to reason about
    static getAssociatedElements = ({
      currentElement: { current: currentElement },
      parentContainer,
      desktopAndAbove,
    }) => {
      let associatedHotSpot;
      let associatedProperty;
      if (currentElement.classList.contains('interactive-hotspot-outer')) {
        associatedHotSpot = currentElement;
        associatedProperty = parentContainer.querySelector(
          `[data-id="${currentElement.dataset.id.replace('-hotspot', '')}"]`,
        );
      } else if (desktopAndAbove) {
        associatedHotSpot = parentContainer.querySelector(
          `[data-id="${currentElement.dataset.id}-hotspot"]`,
        );
        associatedProperty = currentElement;
      } else if (desktopAndAbove === false) {
        associatedHotSpot = parentContainer.querySelector(
          `[data-id="${currentElement.dataset.id}-hotspot"]`,
        );
        associatedProperty = currentElement;
      }
      return [associatedHotSpot, associatedProperty];
    };

    // Animates the code block line highlight
    static animateCodeHighlight = ({ lineHighlight, associatedProperty }) => {
      return (progress) => {
        const initialHeight = lineHighlight.offsetHeight;
        const nextHeight =
          associatedProperty.dataset.codeLength * CODE_LINE_HEIGHT;
        const isHeightPositive = nextHeight > initialHeight;
        const heightDelta = Math.abs(initialHeight - nextHeight);
        if (isHeightPositive) {
          lineHighlight.style.height =
            initialHeight + progress * heightDelta + 'px';
        } else {
          lineHighlight.style.height =
            initialHeight - progress * heightDelta + 'px';
        }

        const initialTop = lineHighlight.offsetTop;
        const nextTop =
          associatedProperty.dataset.codeLine * CODE_LINE_HEIGHT +
          LINE_HEIGHT_OFFSET;
        const isTopPositive = nextTop > initialTop;
        const topDelta = Math.abs(initialTop - nextTop);
        if (isTopPositive) {
          lineHighlight.style.top = initialTop + progress * topDelta + 'px';
        } else {
          lineHighlight.style.top = initialTop - progress * topDelta + 'px';
        }
      };
    };

    // Applies animations to the card container
    animateCardContainer = ({ cardContainer, parentContainer }) => {
      return (progress) => {
        const selectedPropertyOffset = parentContainer
          .querySelector(
            '.mobile-object-property-description-container .interactive-data-description--is-selected',
          )
          .getBoundingClientRect().x;
        const initialValue = cardContainer.scrollLeft;
        const nextValue =
          selectedPropertyOffset -
          cardContainer.getBoundingClientRect().x +
          cardContainer.scrollLeft -
          20;
        const isPositive = nextValue > initialValue;
        const delta = Math.abs(nextValue - initialValue);
        if (isPositive) {
          cardContainer.scrollLeft = initialValue + progress * delta;
        } else {
          cardContainer.scrollLeft = initialValue - progress * delta;
        }
      };
    };

    // Horizontally scrolls the mobile version of the components
    // object property cards
    scrollToSelectedMobilePropertyCard = (parentContainer) => {
      const cardContainers = parentContainer.querySelectorAll(
        '.mobile-object-property-description-container',
      );
      cardContainers.forEach((cardContainer) => {
        animate({
          draw: this.animateCardContainer({
            cardContainer,
            parentContainer,
          }),
        });
      });
    };

    // Scrolls the code container
    animateCodeContainer = ({ preCodeContainer, lineHighlightContainer }) => {
      const initialScrollTop = preCodeContainer.scrollTop;
      const nextScrollTop =
        lineHighlightContainer.getBoundingClientRect().y -
        preCodeContainer.getBoundingClientRect().y +
        preCodeContainer.scrollTop -
        120;
      const isScrollTopPositive = nextScrollTop > initialScrollTop;
      const scrollTopDelta = Math.abs(initialScrollTop - nextScrollTop);
      return (progress) => {
        if (isScrollTopPositive) {
          preCodeContainer.scrollTop =
            initialScrollTop + progress * scrollTopDelta;
        } else {
          preCodeContainer.scrollTop =
            initialScrollTop - progress * scrollTopDelta;
        }
      };
    };

    scrollToHighlightedCode = (parentContainer) => {
      const preCodeContainer = parentContainer.querySelectorAll(
        '[data-id$=code-pre]',
      )[0]; // this *might* cause a problem though it's identical to static data which works
      const lineHighlightContainers = parentContainer.querySelectorAll(
        '[data-id="line-highlight"]',
      );
      lineHighlightContainers.forEach((lineHighlightContainer) => {
        animate({
          draw: this.animateCodeContainer({
            preCodeContainer,
            lineHighlightContainer,
          }),
        });
      });
    };

    // Animates the response/code block for both static and interactive
    // data components
    animateResponse = ({
      currentElement,
      parentContainer: { current: parentContainer },
      device,
    }) => {
      const desktopAndAbove = device.isLarge;
      const selectClass = desktopAndAbove
        ? 'interactive-data-property--is-selected'
        : 'interactive-data-description--is-selected';

      const [
        associatedHotSpot,
        associatedProperty,
      ] = Enliven.getAssociatedElements({
        currentElement,
        parentContainer,
        desktopAndAbove,
      });

      // toggle is selected class
      const objectProperties = parentContainer.querySelectorAll(
        '[data-id^="object-property-"], [data-id^="mobile-object-property-"]',
      );
      objectProperties.forEach((objectProperty) => {
        objectProperty.classList.remove(selectClass);
      });
      associatedProperty.classList.add(selectClass);

      // begin animations
      const lineHighlights = parentContainer.querySelectorAll(
        '[data-id="line-highlight"]',
      );
      lineHighlights.forEach((lineHighlight) => {
        animate({
          draw: Enliven.animateCodeHighlight({
            lineHighlight,
            associatedProperty,
          }),
          start: () => {
            const hotSpots = parentContainer.querySelectorAll(
              '.interactive-hotspot-outer',
            );
            hotSpots.forEach((hotSpot) => {
              hotSpot.classList.remove('interactive-hotspot-outer--hidden');
            });
            if (desktopAndAbove === false) {
              this.scrollToSelectedMobilePropertyCard(parentContainer);
            }

            associatedHotSpot?.classList.add(
              'interactive-hotspot-outer--hidden',
            );
          },
          done: () => {
            this.scrollToHighlightedCode(parentContainer);
          },
          duration: 250,
        });
      });
    };

    handleEvent = ({ e, currentElement, parentContainer, device }) => {
      const { key } = e;
      if (key && key === 'Enter') {
        this.animateResponse({ currentElement, parentContainer, device });
      }
      if (!key) {
        this.animateResponse({ currentElement, parentContainer, device });
      }
    };

    render() {
      return (
        <DeviceProvider>
          <WrappedComponent
            withAnimatedResponse={{
              handleEvent: this.handleEvent,
              scrollToHighlightedCode: this.scrollToHighlightedCode,
            }}
            {...this.props}
          />
        </DeviceProvider>
      );
    }
  };

export default withAnimatedResponse;
