import React from 'react';

const ArrowRightSingleSmall = () => {
  return (
    <svg
      height={10}
      viewBox='0 0 8 16'
      width={8}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5 8l-5 8h3l5-8-5-8H0z' fill='#fff' fillRule='evenodd' />
    </svg>
  );
};

export default ArrowRightSingleSmall;
