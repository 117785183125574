import React from 'react';

const NavigationCloseIcon = () => {
  return (
    <svg height={13} width={13} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.5 8.305l-4.343 4.342c-.481.482-1.276.469-1.774-.03-.498-.498-.512-1.293-.03-1.774L4.695 6.5.353 2.157C-.13 1.676-.116.881.383.383.88-.116 1.676-.13 2.157.353L6.5 4.694 10.817.378c.482-.482 1.277-.468 1.775.03.498.499.512 1.293.03 1.775L8.305 6.5l4.317 4.317c.482.482.468 1.276-.03 1.775-.499.498-1.293.512-1.775.03z'
        fill='#fff'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default NavigationCloseIcon;
