import React, { useRef } from 'react';

import { useAnimations, ANIMATION_TRIGGERS } from 'src/hooks/useAnimations';
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  trigger?: string;
  testId?: string;
}

const AnimatedGroup: React.FC<Props> = ({
  children,
  trigger = ANIMATION_TRIGGERS.default,
  testId = '',
  className = '',
}) => {
  const container = useRef(null);
  const animatedElements = [
    {
      node: container,
      activeClass: animationStyles['animated-group--active'],
      trigger,
    },
  ];
  useAnimations(animatedElements);

  return (
    <div
      ref={container}
      {...(testId ? { 'data-testid': testId } : {})}
      {...{ className }}
    >
      {children}
    </div>
  );
};

export default AnimatedGroup;
