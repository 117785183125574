import React from 'react';
import { AnimatedVideoProps } from './AnimatedVideo.types';
import styled from 'threads5/styles/styled';

// helpers
import { Lazy } from 'src/components-v2/Helpers';

const AnimatedVideo = React.forwardRef<HTMLVideoElement, AnimatedVideoProps>(
  (
    {
      isLazy = false,
      className = '',
      width = '',
      height = '',
      sources = [],
      ariaLabel = '',
      ...props
    },
    ref,
  ) => {
    return (
      <Lazy isLazy={isLazy} height={height}>
        <video
          // we add a key to the video element re-renders
          // this is easier than having to pass a ref to the component
          key={sources[0]?.src}
          data-testid='video'
          className={className}
          autoPlay
          loop
          muted
          playsInline
          width={width}
          height={height}
          ref={ref}
          aria-label={ariaLabel}
          {...props}
        >
          {sources?.map((item, i) => {
            return <source {...item} key={`video-source-${i}`} />;
          })}
        </video>
      </Lazy>
    );
  },
);

export default styled(AnimatedVideo)({});
