/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import Image from 'src/components/Image';
import React from 'react';

interface Props {
  title: string;
  description: string;
}

const Tooltip: React.FC<Props> = ({ title, description }) => {
  return (
    <span
      data-tooltip
      data-tooltip-class='tooltip use-case-tooltip'
      className='top'
      tabIndex={-1}
      title={description}
    >
      {title}&nbsp;
      <Image
        className='info-icon inject-me'
        src='/assets/img/icons/info-icon.svg'
        aria-describedby='learn more'
        alt='information icon'
        isLazy={false}
      />
    </span>
  );
};

export default Tooltip;
