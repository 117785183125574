import React from 'react';
import findLast from 'lodash/findLast';

import { Box, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { isRichTextNodeImage, isRichTextNodeVideo } from 'src/lib/utils';

const splitCardContent = (card) => {
  const body = card?.fields?.body;
  const content = body?.content;
  const lastImageItem = findLast(content, (item) => {
    return isRichTextNodeImage(item);
  });
  const lastVideoItem = findLast(content, (item) => {
    return isRichTextNodeVideo(item);
  });

  const imgSrc = lastImageItem
    ? lastImageItem?.data?.target?.fields?.file?.url
    : '';
  const topContent = {
    ...body,
    content:
      body?.content?.filter((item) => {
        return (
          item.nodeType !==
          (lastVideoItem ? 'embedded-entry-block' : 'embedded-asset-block')
        );
      }) || [],
  };
  const bottomContent = {
    ...body,
    content:
      body?.content?.filter((item) => {
        return (
          item.nodeType ===
          (lastVideoItem ? 'embedded-entry-block' : 'embedded-asset-block')
        );
      }) || [],
  };
  return { topContent, bottomContent, imgSrc };
};

const DefaultCard = ({ card, hasTwoCards }) => {
  const { topContent, bottomContent, imgSrc } = splitCardContent(card);
  const backgroundImage = card?.fields?.backgroundImage?.fields?.file?.url;
  const backgroundImageStyles = backgroundImage && {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  };
  return (
    <Stack
      sx={{
        height: '100%',
        ...backgroundImageStyles,
        ...card?.fields?.styles,
      }}
    >
      <Box
        sx={{
          px: { xs: '3.2rem', lg: '4.4rem' },
          py: { xs: '3.2rem', lg: '4rem' },
          mb: 'auto',
          '& p:nth-last-of-type(1):empty': {
            display: 'none',
          },
          '& h3': {
            mb: '0.8rem',
          },
          '& h3:has(span)': {
            mb: { xs: '0.8rem', lg: '1.6rem' },
          },
          '& p': {
            mb: '1.2rem',
            pb: '0',
          },
        }}
      >
        <RichTextSwitch
          sys={card.sys}
          content={topContent}
          theme={card.context}
          nodes={{
            h3: {
              variant: 'h5',
              component: 'h3',
              fieldId: 'body',
              sx: {
                fontWeight: '600',
              },
            },
            p: {
              variant: 'p2',
              fieldId: 'body',
              sx: { color: 'black900' },
            },
          }}
        />
      </Box>
      {bottomContent && (
        <RichTextSwitch
          sys={card.sys}
          content={bottomContent}
          theme={card.context}
          nodes={{
            asset: {
              width: hasTwoCards ? 603 : 398,
              height: hasTwoCards ? 547 : 492,
              imgSrc:
                imgSrc +
                (hasTwoCards ? '?fm=webp&w=1210&q=60' : '?fm=webp&w=796&q=60'),
              sources: [
                {
                  media: '(max-width: 398px)',
                  srcSet: imgSrc + '?fm=webp&w=728&q=60',
                },
                {
                  media: '(min-width: 399px) and (max-width: 639px)',
                  srcSet: imgSrc + '?fm=webp&w=1210&q=60',
                },
                {
                  media: '(min-width: 640px)',
                  srcSet:
                    imgSrc +
                    (hasTwoCards
                      ? '?fm=webp&w=1210&q=60'
                      : '?fm=webp&w=796&q=60'),
                },
              ],
              sx: {
                mt: 'auto',
                width: '100%',
              },
            },
            video: {
              width: hasTwoCards ? 603 : 398,
              height: hasTwoCards ? 560 : 467,
              sx: {
                mt: 'auto',
                width: '100%',
                height: 'auto',
              },
            },
          }}
        />
      )}
    </Stack>
  );
};

export default DefaultCard;
