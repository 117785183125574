import React, { useEffect, FC } from 'react';
import { string } from 'yup';

// components
import { MarketoForm, GdprFields } from 'src/components/Forms';
import { TextInput, TextArea, Button } from 'src/components/Inputs';

// data
import { getFormData } from 'src/components/Forms/ContactForm/contactFormData';

// contexts
import { usePageLocale } from 'src/contexts';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

import styles from './ContactForm.module.scss';

const ContactForm: FC = () => {
  // TODO: autofill with homepageReferralEmail https://jira.plaid.com/browse/SITE-2281
  // Threads bug: fields take a prefilled value without moving the placeholder label
  const homepageReferralEmail = '';
  const locale = usePageLocale();
  const contactFormData = getFormData({ locale });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const demoParam = params.get('from-demo');
    if (demoParam) {
      localStorage.setItem('from-demo', demoParam);
    }
  }, []);

  return (
    <MarketoForm
      marketoKey={contactFormData.marketoKey}
      trackingId='CONTACT_FORM'
      locale={locale}
      render={({ control, register, errors, setValue }) => {
        return (
          <>
            <div className='grid-container full'>
              <div className='grid-x form-group'>
                <div className='cell small-12 medium-6'>
                  <TextInput
                    label={contactFormData.firstName.label}
                    id={contactFormData.firstName.name}
                    name={contactFormData.firstName.name}
                    describedby={contactFormData.firstName.describedby}
                    errorMessage={
                      errors?.[contactFormData.firstName.name]?.message
                    }
                    register={register}
                    required={getRequiredMessage(
                      contactFormData.firstName.label,
                    )}
                    autoComplete={contactFormData.firstName.autoComplete}
                  />
                </div>
                <div className='cell small-12 medium-6'>
                  <TextInput
                    label={contactFormData.lastName.label}
                    id={contactFormData.lastName.name}
                    name={contactFormData.lastName.name}
                    describedby={contactFormData.lastName.describedby}
                    errorMessage={
                      errors?.[contactFormData.lastName.name]?.message
                    }
                    register={register}
                    required={getRequiredMessage(
                      contactFormData.lastName.label,
                    )}
                    autoComplete={contactFormData.lastName.autoComplete}
                  />
                </div>
              </div>
            </div>
            <TextInput
              label={contactFormData.email.label}
              id={contactFormData.email.name}
              name={contactFormData.email.name}
              describedby={contactFormData.email.describedby}
              errorMessage={errors?.Email?.message}
              register={register}
              required={getRequiredMessage(contactFormData.email.label)}
              pattern={{
                value: string().email().tests[0].OPTIONS.params.regex,
                message: contactFormData.email.invalidMessage,
              }}
              value={homepageReferralEmail}
              autoComplete={contactFormData.email.autoComplete}
            />
            <TextInput
              label={contactFormData.company.label}
              id={contactFormData.company.name}
              name={contactFormData.company.name}
              describedby={contactFormData.company.describedby}
              errorMessage={errors?.[contactFormData.company.name]?.message}
              register={register}
              required={getRequiredMessage(contactFormData.company.label)}
              autoComplete={contactFormData.company.autoComplete}
            />
            <TextArea
              {...contactFormData.comments}
              errorMessage={errors?.[contactFormData.comments.name]?.message}
              register={register}
              required={getRequiredMessage(contactFormData.comments.label)}
              className={styles.textarea}
            />
            {/*
          TODO: Threads phone input. Currently bugged:
          https://github.plaid.com/pages/plaid/threads/?path=/story/inputs-phone-input--basic-example
          */}
            <TextInput
              label={contactFormData.phone.label}
              id={contactFormData.phone.name}
              name={contactFormData.phone.name}
              describedby={contactFormData.phone.describedby}
              errorMessage={errors?.[contactFormData.phone.name]?.message}
              register={register}
              autoComplete={contactFormData.phone.autoComplete}
            />
            <GdprFields
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
            />
            <Button
              className='mb1'
              type='submit'
              value={contactFormData.actionText}
              fullWidth
            >
              {contactFormData.actionText}
            </Button>
            <p
              className='contact-form__disclaimer'
              dangerouslySetInnerHTML={{
                __html: contactFormData.disclaimer,
              }}
            />
          </>
        );
      }}
    />
  );
};

ContactForm.displayName = 'ContactForm';
export default ContactForm;
