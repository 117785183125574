import {
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithChildren,
  RefObject,
} from 'react';
import cx from 'classnames';

import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

import { Box } from 'src/components-v2/Layout';

import styles from './Dropdown.module.scss';

type DropdownProps = PropsWithChildren<
  ComponentPropsWithoutRef<'div'> & {
    className?: string;
    sx: SxProps<Theme>;
  }
>;

export const Dropdown = forwardRef(
  (props: DropdownProps, ref: RefObject<HTMLDivElement>) => {
    const { children, className, sx, ...rest } = props;

    return (
      <Box
        ref={ref}
        className={cx(styles.Dropdown, className)}
        sx={{
          position: 'absolute',
          top: '100%',
          left: '-20px',
          p: '16px 0 0',
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  },
);
