const forConsumers = {
  label: 'For consumers',
  megaMenuClass: 'for-consumers',
  subtitleColSize: 4,
  subtitle: 'Safely connect your accounts and control access to them',
  menuLinks: [
    {
      name: 'Trouble connecting?',
      description: '',
      url: '/trouble-connecting',
    },
    {
      name: 'Control access with Plaid Portal',
      description: '',
      url: '//my.plaid.com/',
    },
    {
      name: 'Access or dispute your Plaid Consumer Report',
      description: '',
      url: '/check/consumer-service-center',
    },
  ],
  submenu: {
    columns: [
      {
        heading: 'GET STARTED',
        gridClasses: 'small-5',
        items: [
          {
            name: 'How Plaid works',
            description:
              'Plaid builds connections that enable you to securely share your data',
            url: '/how-it-works-for-consumers/',
            icon: '/assets/img/navbar/icon-how-it-works.png',
          },
          {
            name: 'Discover apps',
            description: 'Discover apps that are powered by Plaid',
            url: '/discover-apps/',
            icon: '/assets/img/navbar/icon-discover-apps.png',
          },
        ],
      },
      {
        heading: '\u00A0', // @codeSmell adding a nbsp to keep heading space reserved for layout purposes
        gridClasses: 'small-6 small-push-2',
        items: [
          {
            name: 'How we handle data',
            description:
              'Plaid lets you securely share data from your financial institution with the apps you choose',
            url: '/how-we-handle-data/',
            icon: '/assets/img/navbar/icon-how-we-handle-data.png',
          },
          {
            name: 'Why is Plaid involved?',
            description: 'Plaid powers the apps in your financial life',
            url: '/why-is-plaid-involved/',
            icon: '/assets/img/navbar/icon-why-is-plaid-involved.png',
          },
        ],
      },
    ],
  },
};

export default forConsumers;
