// TODO: Refactor, implement / support features from legacy page-header
// https://jira.plaid.com/browse/SITE-1926
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { Label } from './shared/Label';
import { CtaSection } from './CtaSection';
import { Image } from './shared/Image';
import { HeadingText } from './shared/HeadingText';
import { Video } from 'src/components/Video';
import { SearchBar } from 'src/components/Inputs/';
import InlineLink from 'plaid-threads/InlineLink';

// helpers
import { ImageShape, VideoShape, TargetButtonShape } from 'src/lib/prop-types';
import { CtaShape } from './CtaSection/CtaSection';

// styles
import styles from './PageHeader--cms.module.scss';

const PageHeaderProps = {
  heading: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
  }).isRequired,
  subheading: PropTypes.shape({
    text: PropTypes.string,
  }),
  largeHeading: PropTypes.bool,
  image: ImageShape.isRequired,
  labelText: PropTypes.string,
  bgColor: PropTypes.string,
  cta: CtaShape,
  video: VideoShape,
  targetButton: TargetButtonShape,
  link: PropTypes.shape({
    href: PropTypes.string,
    displayName: PropTypes.string,
  }),
  search: PropTypes.object,
  description: PropTypes.string,
  removeHeightRestriction: PropTypes.bool,
  breadcrumb: PropTypes.element,
  isDesktopOnly: PropTypes.bool,
};

// TODO: https://jira.plaid.com/browse/SITE-2057
// .page-header legacy classname retained due to use in
// contextual targeting (leaky scope) of legacy selectors for
// .button-group, a Foundation class used in CtaSection.
// Decoupling CtaSection from Foundation .button-group class implementation
// would enable removing the .page-header classname here.
const PageHeader = ({
  cta,
  heading,
  largeHeading = false,
  subheading = {},
  description = '',
  image = {},
  labelText = '',
  bgColor = '',
  video = {},
  link = {},
  search,
  removeHeightRestriction = false,
  breadcrumb = null,
  isDesktopOnly = true,
  targetButton = {},
}) => {
  return (
    <header
      className={cx('page-header', styles.pageHeader, {
        [`${styles[bgColor]}`]: bgColor,
        [styles.pageHeaderWithSearch]: search,
        [styles.pageHeaderWithoutHeightRestriction]: removeHeightRestriction,
        [styles.pageHeaderWithVideo]: video.formats?.length > 0,
      })}
      role='banner'
    >
      <div className='grid-container'>
        <div className={cx('grid-x', 'align-justify', styles.gridWrapper)}>
          <div
            className={`cell small-12 ${
              search ? 'medium-8' : 'medium-6'
            } large-6`}
          >
            <div className={styles.contentWrapper}>
              <div className='grid-x'>
                <div
                  className={`cell ${
                    search ? 'small-10' : 'small-12'
                  } large-12`}
                >
                  {breadcrumb && breadcrumb}
                  {!breadcrumb && labelText && (
                    <Label>
                      <span dangerouslySetInnerHTML={{ __html: labelText }} />
                    </Label>
                  )}
                  <HeadingText
                    className={cx(heading?.className, {
                      [styles.largeHeading]: largeHeading,
                    })}
                  >
                    {heading.text}
                  </HeadingText>
                </div>
                {subheading.text && (
                  <div className='cell small-12 large-12'>
                    <h4 className='regular page-header-subheader'>
                      {subheading.text}
                    </h4>
                  </div>
                )}
                {description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                )}
                {link.href && (
                  <div className='cell small-10 large-12'>
                    <p className={styles.inlineLink}>
                      <InlineLink href={link.href}>
                        {link.displayName}
                      </InlineLink>
                    </p>
                  </div>
                )}
                <div className='cell small-12 medium-11 large-9'>
                  <CtaSection cta={cta} targetButton={targetButton} />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'cell small-12 medium-6 large-6',
              styles.imageWrapper,
            )}
          >
            <div className={styles.imageContainer}>
              {video?.formats?.length ? (
                <Video {...video} />
              ) : (
                image &&
                !!image.src && (
                  <Image isDesktopOnly={isDesktopOnly} {...image} />
                )
              )}
            </div>
          </div>
          {search && (
            <div className={cx('cell', 'small-12', styles.search)}>
              <SearchBar {...search} />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

PageHeader.propTypes = PageHeaderProps;

export default PageHeader;
