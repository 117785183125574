/* eslint-disable import/prefer-default-export */
const codeBody = `http code 200
{
  "transactions": [{
    "account_id": "vokyE5Rn6vHKqDLRXEn5fne7LwbKPLIXGK98d",
    "amount": 2307.21,
    "iso_currency_code": "USD",
    "unofficial_currency_code": null,
    "category": [
      "Shops",
      "Computers and Electronics"
    ],
    "category_id": "19013000",
    "check_number": null,
    "date": "2017-01-29",
    "authorized_date": "2017-01-27",
    "location": {
     "address": "300 Post St",
     "city": "San Francisco",
     "region": "CA",
     "postal_code": "94108",
     "country": "US",
     "lat": null,
     "lon": null,
     "store_number": "1235"
    },
    "name": "Apple Store",
    "merchant_name": "Apple",
    "payment_meta": {
      "by_order_of": null,
      "payee": null,
      "payer": null,
      "payment_method": null,
      "payment_processor": null,
      "ppd_id": null,
      "reason": null,
      "reference_number": null
    },
    "payment_channel": "in store",
    "pending": false,
    "pending_transaction_id": null,
    "account_owner": null,
    "transaction_id": "lPNjeW1nR6CDn5okmGQ6hEpMo4lLNoSrzqDje",
    "transaction_code": null,
    "transaction_type": "place"
   }, {
    "account_id": "XA96y1wW3xS7wKyEdbRzFkpZov6x1ohxMXwep",
    "amount": 78.5,
    "iso_currency_code": "USD",
    "unofficial_currency_code": null,
    "category": [
      "Food and Drink",
      "Restaurants"
    ],
    "category_id": "13005000",
    "check_number": null,
    "date": "2017-01-29",
    "authorized_date": "2017-01-28",
    "location": {
      "address": "262 W 15th St",
      "city": "New York",
      "region": "NY",
      "postal_code": "10011",
      "country": "US",
      "lat": 40.740352,
      "lon": -74.001761,
      "store_number": "455"
    },
    "name": "Golden Crepes",
    "merchant_name": "Golden Crepes",
    "payment_meta": {
      "by_order_of": null,
      "payee": null,
      "payer": null,
      "payment_method": null,
      "payment_processor": "Square",
      "ppd_id": null,
      "reason": null,
      "reference_number": null
    },
    "payment_channel": "in store",
    "pending": false,
    "pending_transaction_id": null,
    "account_owner": null,
    "transaction_id": "4WPD9vV5A1cogJwyQ5kVFB3vPEmpXPS3qvjXQ",
    "transaction_code": null,
    "transaction_type": "place"
  }],
  "accounts": [{
    "account_id": "vokyE5Rn6vHKqDLRXEn5fne7LwbKPLIXGK98d",
    "balances": {
        "available": 110,
        "current": 110,
        "iso_currency_code": "USD",
        "limit": null,
        "unofficial_currency_code": null
    },
    "mask": "3333",
    "name": "Plaid Credit Card",
    "official_name": "Plaid Diamond 12.5% APR Interest Credit Card",
    "subtype": "credit card",
    "type": "credit"
  }],
  "item": {
    "available_products": [
      "balance",
      "identity",
      "investments",
      "assets",
      "auth",
      "liabilities"
    ],
    "billed_products": [
      "transactions"
    ],
    "consent_expiration_time": null,
    "error": null,
    "institution_id": "ins_3",
    "item_id": "eVBnVMp7zdTJLkRNr33Rs6zr7KNJqBFL9DrE6",
    "update_type": "background",
    "webhook": "https://www.genericwebhookurl.com/webhook"
  },
  "total_transactions": 2,
  "request_id": "45QSn"
}
`;

export const interactiveData = {
  'title-text': 'Make a Transactions request',
  'description-text':
    'The request includes start and end dates, a specific date range to pull transactions data from. Use the Transactions endpoint to submit a POST request',
  'code-title': 'Retrieve Transactions request',
  'code-lang': 'bash',
  'code-body':
    '\ncurl -X POST https://sandbox.plaid.com/transactions/get \\\n-H \'Content-Type: application/json\' \\\n-d \'{\n  "client_id": String,\n  "secret": String,\n  "access_token": String,\n  "start_date": "2018-01-01",\n  "end_date": "2018-02-01",\n  "options": {\n    "count": 250,\n    "offset": 100\n  }\n}\'\n',
  'post-run-title-text': 'Transactions data',
  'post-run-description-text':
    'The <strong>/transactions/get</strong> endpoint provides standardized transactions for credit and deposit accounts from thousands of financial institutions',
  'post-run-code-title': 'Retrieve Transactions response',
  'post-run-code-lang': 'json',
  'post-run-code-body': codeBody,
  properties: [
    {
      title: 'amount',
      description:
        'Settled value in specified currency. Debits are positive; credits are negative',
      'code-line-start': 5,
      'code-highlight-length': 1,
    },
    {
      title: 'category',
      description:
        'See a list of all transaction categories by posting to the /categories/get endpoint',
      'code-line-start': 8,
      'code-highlight-length': 4,
    },
    {
      title: 'date',
      description:
        'For pending transactions, the date the transaction occurred is returned',
      'code-line-start': 14,
      'code-highlight-length': 1,
    },
    {
      title: 'location',
      description:
        'Includes address, city, state/region, zip/postal code, as well as latitude and longitude',
      'code-line-start': 16,
      'code-highlight-length': 10,
    },
    {
      title: 'name',
      description: 'The merchant name or transaction description',
      'code-line-start': 26,
      'code-highlight-length': 1,
    },
  ],
  'bottom-cta-url': '/docs/transactions/',
  'bottom-cta-text': 'View API docs',
  testId: 'products-transactions',
};
