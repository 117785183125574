import Gear from 'plaid-threads/Icons/Gear';
import Modal from './Modal';
import Button from './Button';
import React, { useEffect, useState } from 'react';
import styles from './PrefsMenu.module.scss';
import SaveForm from './SaveForm';
import cx from 'classnames';
import {
  isDefaultProject,
  loadUserProjectForTutorial,
  resetUserProjectForTutorial,
  TutorialKey,
} from './TutorialUtils';
import { TUTORIAL_EVENTS } from 'src/lib/trackingEvents';
import { useTrack } from 'src/contexts/AnalyticsContext';
interface Props {
  tutorialKey: TutorialKey;
  projectSetter: (newVal: String) => {};
}

const enum ModalStep {
  ConfirmReset,
  ResetDone,
  GetProjectName,
  LoadProjectDone,
}

const PrefsMenu: React.FC<Props> = ({ tutorialKey, projectSetter }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalStep, setModalStep] = useState(ModalStep.ConfirmReset);
  const [glitchProject, setGlitchProject] = useState('Loading...');
  const track = useTrack();

  useEffect(() => {
    const savedGlitchProject = loadUserProjectForTutorial(tutorialKey);
    setGlitchProject(savedGlitchProject);
  });

  const resetProject = function () {
    resetUserProjectForTutorial(tutorialKey);
    track({
      type: TUTORIAL_EVENTS.TUTORIAL_PROJECT_RESET,
      payload: {
        tutorial_id: tutorialKey,
      },
    });
    projectSetter(loadUserProjectForTutorial(tutorialKey));
  };

  return (
    <>
      <div className={styles.containerDiv}>
        <Gear className={styles.gearButton} />
        <div className={cx(styles.disappearingItem, styles.menuWrapper)}>
          <div>
            {isDefaultProject(tutorialKey, glitchProject)
              ? 'You have the starter project loaded.'
              : `Your saved glitch project is ${glitchProject}`}
          </div>
          <div
            className={styles.fakeButton}
            onClick={() => {
              setModalStep(ModalStep.ConfirmReset);
              setShowModal(true);
            }}
          >
            Reset Saved Project
          </div>
          <div
            className={styles.fakeButton}
            onClick={() => {
              setModalStep(ModalStep.GetProjectName);
              setShowModal(true);
            }}
          >
            Load Existing Project
          </div>
        </div>
      </div>

      <Modal
        onRequestClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
      >
        {modalStep === ModalStep.ConfirmReset && (
          <React.Fragment key='.0'>
            <div className={styles.dialogBox}>
              <div>
                This will reset your saved project to the default tutorial and
                you will start over from scratch. Is this what you want to do?
              </div>
              <div className={styles.dialogButtonBar}>
                <Button
                  centered
                  inline
                  onClick={() => {
                    resetProject();
                    setModalStep(ModalStep.ResetDone);
                  }}
                >
                  Yes
                </Button>
                <Button
                  centered
                  secondary
                  inline
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
        {modalStep === ModalStep.ResetDone && (
          <React.Fragment key='.1'>
            <div className={styles.dialogBox}>
              <div>
                Your project has been reset. If you want to load another
                project, select the 'Load Existing Project' option.
              </div>
              <div className={styles.dialogButtonBar}>
                <Button
                  centered
                  inline
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Okay
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}

        {modalStep === ModalStep.GetProjectName && (
          <React.Fragment key='.2'>
            <div className={styles.dialogBox}>
              <div className={styles.fromIntro}>
                Already have a project in Glitch? Load it up by entering the
                name below. (You can find all of your currently open Glitch
                projects at{' '}
                <a href='https://glitch.com/dashboard' target='_blank'>
                  https://glitch.com/dashboard
                </a>
                )
              </div>
              <SaveForm
                tutorialKey={tutorialKey}
                onComplete={async () => setModalStep(ModalStep.LoadProjectDone)}
                buttonOverride='Update'
                projectSetter={projectSetter}
              />
            </div>
          </React.Fragment>
        )}
        {modalStep === ModalStep.LoadProjectDone && (
          <React.Fragment key='.3'>
            <div className={styles.dialogBox}>
              <div>Your project has been updated.</div>
              <div className={styles.dialogButtonBar}>
                <Button
                  centered
                  inline
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Okay
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </Modal>
    </>
  );
};

PrefsMenu.displayName = 'PrefsMenu';

export default PrefsMenu;
