import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';

export enum MenuHeadingVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}
export interface MenuHeadingProps {
  title: string;
  sx?: SxProps<Theme>;
  variant?: 'primary' | 'secondary' | 'tertiary';
}
