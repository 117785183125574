import styled from 'threads5/styles/styled';

const A = styled('a')({});
const Blockquote = styled('blockquote')({});
const Br = styled('br')({});
const Button = styled('button')({});
const Code = styled('code')({});
const Dd = styled('dd')({});
const Dl = styled('dl')({});
const Dt = styled('dt')({});
const Em = styled('em')({});
const Form = styled('form')({});
const Hr = styled('hr')({});
const I = styled('i')({});
const Input = styled('input')({});
const Label = styled('label')({});
const Li = styled('li')({});
const Ol = styled('ol')({});
const Option = styled('option')({});
const Pre = styled('pre')({});
const Select = styled('select')({});
const Span = styled('span')({});
const Strong = styled('strong')({});
const Sup = styled('sup')({});
const Table = styled('table')({});
const Td = styled('td')({});
const Textarea = styled('textarea')({});
const Th = styled('th')({});
const Tr = styled('tr')({});
const Ul = styled('ul')({});

export const S = {
  A,
  Blockquote,
  Br,
  Button,
  Code,
  Dd,
  Dl,
  Dt,
  Em,
  Form,
  Hr,
  I,
  Input,
  Label,
  Li,
  Ol,
  Option,
  Pre,
  Select,
  Span,
  Strong,
  Sup,
  Table,
  Td,
  Textarea,
  Th,
  Tr,
  Ul,
};
