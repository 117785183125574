export const metaData = {
  'meta-title': 'Contact us',
  hasCanonicalTag: false,
};

export const heading = 'Thank you!';

export const subheading =
  'Your message has been sent, and a member of our team will be in touch soon';

export const cta = {
  url: '/docs/',
  text: 'Check out our implementation guide',
};
