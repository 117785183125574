import React from 'react';

import { Stat, RichTextSwitch } from 'src/containers';
import { DefaultCard } from './index';

const getStatsBgImagePosition = (cards, hasTwoCards, index) => {
  if (hasTwoCards) {
    return index === 0 ? 'left' : 'right';
  } else {
    return index === 0
      ? 'left'
      : index === cards?.length - 1
      ? 'right'
      : 'center';
  }
};

export const createCards = (cards) => {
  const hasTwoCards = cards?.length === 2;
  return cards?.map((card, index) => {
    const type = card?.fields?.type;
    switch (type) {
      case 'Stats':
        return (
          <Stat
            backgroundImagePosition={getStatsBgImagePosition(
              cards,
              hasTwoCards,
              index,
            )}
            sx={card?.fields?.styles}
          >
            <RichTextSwitch
              sys={card.sys}
              content={card.fields.body}
              theme='default'
              nodes={{
                h3: {
                  variant: 'h2',
                  component: 'h3',
                  fieldId: 'body',
                  sx: {
                    textAlign: 'center',
                  },
                },
                p: {
                  variant: 'p2',
                  fieldId: 'body',
                  sx: { textAlign: 'center' },
                },
              }}
            />
          </Stat>
        );
      case 'Default':
      default:
        return <DefaultCard card={card} hasTwoCards={hasTwoCards} />;
    }
  });
};
