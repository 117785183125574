import React, { FC } from 'react';
import { RichTextContent } from 'contentful';
import cx from 'classnames';
import styles from './CtaRowWithBrushstroke.module.scss';
import { Button } from 'src/components/Inputs';
import { RichTextRenderer } from 'src/components/RichTextRenderer';

interface Props {
  title: string;
  description: RichTextContent;
  cta: Record<string, string>;
}

const CtaRowWithBrushstroke: FC<Props> = ({ title, description, cta }) => {
  return (
    <div className={styles.footer}>
      <div className={cx(styles.content, 'grid-container')}>
        <div className={styles.body}>
          <h4 className={styles.title}>{title}</h4>
          <RichTextRenderer
            content={description}
            classes={{
              pClassName: styles.description,
            }}
          />
        </div>
        <div className={styles.ctaContainer}>
          <div className={styles.accent} />
          <Button href={cta.url} classes={{ aClassName: styles.cta }}>
            {cta.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CtaRowWithBrushstroke;
