import React, { FC } from 'react';
import { Date, UpdatedDate } from 'src/components/Date/Date';
import { Author } from 'src/components/Blog/Heading';
import ShareBtns from 'src/components/ShareBtns/ShareBtns';
import styles from './Heading.module.scss';

interface Props {
  date: string;
  updated: string;
  title: string;
  authors: string[];
  location: string;
}

const Heading: FC<Props> = ({ date, updated, title, authors, location }) => (
  <>
    <ShareBtns title={title} location={`https://plaid.com/blog/${location}/`} />
    {date && <Date date={date} />}
    <h1 className={styles.title}>{title}</h1>
    <div className={styles.divider} />
    <Author authors={authors} />
    {updated && updated > date && <UpdatedDate date={updated} />}
  </>
);

export default Heading;
