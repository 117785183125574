// import analytics listeners / methods
import pageView from './page-view';
import docs from './docs';
import { patchWindowDotPlaid } from './cookie';
import { trackingUtils } from './trackEvent';

const initAnalytics = () => {
  patchWindowDotPlaid();
  pageView.trackPageView();
  docs.setupDocsPathListener();
  trackingUtils();
};

export default initAnalytics;

export { default as trackEvent } from './trackEvent';
export {
  default as trackLinkedIn,
  linkedInConversionIds,
} from './trackLinkedIn';
export { default as trackTTD, TTDConversionIds } from './trackTTD';
