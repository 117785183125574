import React, { FC } from 'react';

// components
import { SelectWithController } from 'src/components/Inputs';

// helpers
import { getRequiredMessage } from 'src/lib/utils';

import { FormSectionTicketTypeProps } from './FormSectionTicketType.types';

const FormSectionTicketType: FC<FormSectionTicketTypeProps> = ({
  formData,
  register,
  cmsProps,
  errors,
  setValue,
  control,
  handleTicketTypeChange,
}) => {
  return (
    <SelectWithController
      label={formData.plaidForumTicketType?.label}
      name={formData.plaidForumTicketType?.name}
      describedby={formData.plaidForumTicketType?.describedby}
      isDarkMode={false}
      id={formData.plaidForumTicketType?.name}
      register={register}
      {...(!cmsProps.formData?.isWaitListSoldOut
        ? {
            required: getRequiredMessage(formData.plaidForumTicketType?.label),
          }
        : {})}
      errorMessage={errors?.[formData.plaidForumTicketType?.name]?.['message']}
      setValue={setValue}
      control={control}
      options={
        cmsProps.formData?.isWaitListSoldOut
          ? formData.plaidForumTicketType.options.filter(
              (e) => e.label === 'Virtual',
            )
          : formData.plaidForumTicketType.options
      }
      onChange={handleTicketTypeChange}
    />
  );
};

export default FormSectionTicketType;
