import React from 'react';
import { CustomerStoryTile } from 'src/components/CustomerStories';

const CustomerAppCollection = (props) => (
  <div className='customer-stories feature-section-wrapper--no-top-padding'>
    <div className='customer-stories__content'>
      <div className='grid-container'>
        {props['app-section-title'].map((title, i) => (
          <div className='grid-x feature-section-title align-center' key={i}>
            <div className='cell small-12'>
              <h4
                className={`${
                  title['title-alignment'] === 'center' ? 'text-center' : ''
                }`}
              >
                {title.title}
              </h4>
              <br />
            </div>
            <div className='cell small-12 medium-6'>
              <div
                className={`${
                  title['desc-alignment'] === 'center' ? 'text-center' : ''
                }`}
              >
                {title.description}
              </div>
            </div>
          </div>
        ))}
        <div className='customer-stories__collection feature-section-title'>
          {props.items.map((item, i) => (
            <article
              className='customer-stories__item'
              data-id={item.name}
              data-tags={`[${item.goals && item.goals.join(',')}]`}
              key={i}
            >
              <CustomerStoryTile {...item} />
            </article>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default CustomerAppCollection;
