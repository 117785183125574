import * as React from 'react';
import { useSpring, animated, easings } from '@react-spring/web';
import { IBtnProps } from './Btn.types';
import { Icon } from 'src/components-v2/DataDisplay';
import { Stack as VStack, Box as VBox } from 'src/components-v2/Layout';
import StyledButton from './Btn.styled';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import { useIsDarkBackground } from 'src/contexts';
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import Link from '../Link/Link';
import { colors } from 'src/components-v2/theme';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';
import { useTheme } from 'threads5/styles/useTheme';

const Box = animated(VBox);
const Stack = animated(VStack);

const Btn = ({
  children,
  isInverted = false,
  trackingId = '',
  variant = 'primary',
  sys,
  backgroundColor,
  iconName = 'ArrowRight',
  iconSize = 20,
  defaultGap,
  ...props
}: IBtnProps): JSX.Element => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const cmsTheme = useCmsTheme();
  const theme = useTheme();
  const track = useTrack();
  const isDarkBackground = useIsDarkBackground(backgroundColor);
  const [isHovered, setIsHovered] = React.useState(false);

  const isPrimary = variant === 'primary' && cmsTheme === CmsThemes.WEB2;
  const isTertiary = variant === 'tertiary';
  const isQuinary = variant === 'quinary';
  const isOnDarkBackground =
    isDarkBackground !== undefined ? isDarkBackground : isInverted;

  const animatedTextFrom = {
    [CmsThemes.WEB2]: {
      left: '0px',
    },
    [CmsThemes.WEB3]: {
      left: '0px',
    },
  };
  const animatedTextTo = {
    [CmsThemes.WEB2]: {
      left: isHovered && isTertiary ? '-8px' : '0px',
    },
    [CmsThemes.WEB3]: {
      left: isHovered && isTertiary ? '0px' : '0px',
    },
  };
  const animatedText = useSpring({
    from: animatedTextFrom[cmsTheme],
    to: animatedTextTo[cmsTheme],
    config: {
      duration: 300,
      easing: easings.easeOutBack,
    },
  });

  const animatedIconFrom = {
    [CmsThemes.WEB2]: {
      left: '0%',
      background: colors.black400,
    },
    [CmsThemes.WEB3]: {
      left: '0%',
      background:
        theme.palette[
          'web3/component/button/tertiary/gradient/background-resting'
        ],
    },
  };
  const animatedIconTo = {
    [CmsThemes.WEB2]: {
      left: isHovered ? '100%' : '0%',
      background: isHovered ? colors.transparent : colors.black400,
    },
    [CmsThemes.WEB3]: {
      left: '0%',
      background: isHovered
        ? theme.palette[
            'web3/component/button/tertiary/gradient/background-interacted'
          ]
        : theme.palette[
            'web3/component/button/tertiary/gradient/background-resting'
          ],
    },
  };

  const animatedIcon = useSpring({
    from: animatedIconFrom[cmsTheme],
    to: animatedIconTo[cmsTheme],
    config: {
      duration: 300,
      easing: easings.easeOutBack,
    },
  });

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
    if (props.onClick && !props.href) {
      props.onClick(event);
    }
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = (e) => {
    setIsHovered(false);
    e.target.blur();
  };

  return (
    <ConditionalWrapper
      condition={props.href}
      wrapper={(children) => {
        // we render the button as a hyperlink when href prop is present
        // we also need to wrap the anchor in our specialized Link component
        // The Link component wrapper will handle localization and tracking
        return <Link href={props.href}>{children}</Link>;
      }}
    >
      <StyledButton
        {...props}
        isInverted={isOnDarkBackground}
        onBlur={handleMouseOut}
        onClick={handleClick}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        variant={variant}
        {...inspectorProps({ fieldId: 'text' })}
      >
        <Stack
          alignItems='center'
          component='span'
          flexDirection='row'
          gap={isTertiary ? 1 : isQuinary ? 0.5 : 1.5}
          justifyContent={isQuinary ? 'left' : 'center'}
          sx={defaultGap && { gap: defaultGap }}
        >
          {isTertiary && (
            <Stack
              alignItems={'center'}
              className='movingIcon'
              component={'span'}
              justifyContent={'center'}
              style={animatedIcon}
              sx={{
                borderRadius: '50%',
                height: '20px',
                position: 'relative',
                width: '20px',
                willChange: isHovered ? 'left, background' : 'auto',
                zIndex: 0,
              }}
            >
              <Icon
                sx={{
                  height: '14px',
                  width: '14px',
                }}
                icon={iconName}
              />
            </Stack>
          )}
          <Box
            className='movingText'
            component='span'
            sx={{
              backgroundColor: 'inherit',
              position: 'relative',
              willChange: isHovered ? 'left' : 'auto',
              zIndex: 1,
            }}
            style={animatedText}
          >
            {children}
          </Box>
          {isPrimary && (
            <Icon
              sx={{
                color: (isOnDarkBackground ? 'black' : 'white') + ' !important',
                width: `${iconSize}px`,
                // height: `${iconSize}px`,
              }}
              icon={iconName}
            />
          )}
          {isQuinary && (
            <Icon
              sx={{
                color: (isOnDarkBackground ? 'black' : 'white') + ' !important',
                minHeight: '24px',
                minWidth: '24px',
                maxHeight: '24px',
                maxWidth: '24px',
                marginRight: '16px',
              }}
              icon={iconName}
            />
          )}
        </Stack>
      </StyledButton>
    </ConditionalWrapper>
  );
};

export default Btn;
